export const solarEnergyBenefitsTexts = {
  label: `Beneficis de l'energia solar`,
  description:
    `Els panells solars són dispositius que converteixen l'energia solar en electricitat. L'ús de panells té un impacte positiu en l'economia i el medi ambient. Poden reduir els costos d'electricitat, disminuir la petjada de carboni i augmentar el valor de la propietat. Tot i que alguns propietaris tenen dubtes sobre el cost i el manteniment, els beneficis superen els inconvenients.`,
};

export const benefitsList = {
  firstLabel: 'No emet gasos nocius.',
  secondLabel: 'Llarga vida útil dels equips',
  thirdLabel: 'Millora de la seguretat energètica.',
  fourthLabel: `Font inesgotable d'energia.`,
};
