import { en } from './languages/en';
import { es } from './languages/es';
import { ua } from './languages/ua';
import { fr } from './languages/fr';
import { ca } from './languages/ca';
export const resources = {
  en,
  es,
  ua,
  fr,
  ca,
};
