import { RoutePaths } from '../../../../shared/helpers/routes';

export const TopNavbarTitles = {
  // login: 'Connexion',
  call_to_action: 'Commencez votre projet',
};

export const navBarItemTitlesArray = [
  {
    itemName: 'Prestations de service',
    routePath: RoutePaths.services,
    storeItemName: 'Magasin',
  },
  {
    itemName: 'À propos de nous',
    routePath: RoutePaths.aboutUsPage,
  },
  {
    itemName: 'Contacts',
    routePath: RoutePaths.contactsPage,
  },
  {
    itemName: 'Préféré',
    routePath: RoutePaths.favourites,
  },
  // {
  //   itemName: 'Connexion',
  //   routePath: RoutePaths.loginPage,
  // },
  {
    itemName: 'Démarrez votre projet',
    routePath: RoutePaths.leadForm,
  },
];



export const mobileNavBarItemTitlesArray = {
  itemNameLeadForm: 'Démarrez votre projet',
    routePathLeadForm: RoutePaths.leadForm,
  
  itemNameServices: 'Prestations de service',
  routePathServices: RoutePaths.services,
  
  itemNameAbout: 'À propos de nous',
  routePathAbout: RoutePaths.aboutUsPage,

  itemNameContacts: 'Contacts',
  routePathContacts: RoutePaths.contactsPage,

  itemNameFavourite: 'Préféré',
  routePathFavourite: RoutePaths.favourites,
};