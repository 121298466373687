export const realEstateProcessTexts = {
  label: 'послуги',
  description:
    "Наша мета - допомогти вам знайти свій домівку мрії, незалежно від того, чи шукаєте ви купити, чи орендувати. Ми пропонуємо широкий вибір нерухомості в різних місцях та стилях, що дозволить вам знайти щось, що підходить вам за потребами та бюджетом. Подивіться на наші рекомендовані об'єкти нижче або використовуйте рядок пошуку, щоб знайти певний тип нерухомості чи місцезнаходження. Якщо у вас виникли запитання, зв'яжіться з нами.",
};

export const processList = {
labelFirst: 'Управління нерухомістю',
labelSecond: 'Оцінка майна',
labelThird: 'Маркетинг та реклама',
labelFourth: 'Купівля та продаж',
labelFifth: 'Оренда та лізинг',
labelSixth: 'Правова допомога',
};

export const servicesTexts = {
    label: 'Наші послуги у сфері нерухомості',
    description:
      `Ми пропонуємо широкий спектр послуг з дизайну інтер'єру, щоб допомогти вам створити простір вашої мрії. Від початкової концепції та планування простору до вибору кольору, підбору меблів та аксесуарів - наша команда може впоратися з кожним аспектом вашого проекту. Наші послуги включають:`,
  };
export const servicesList = {
  textFirst: 'Визначення ваших вимог',

  textSecond: 'Дослідження місця розташування',

  textThird: 'Оцінка придатності ділянки',

  textFourth: 'Перевірка правових та регуляторних аспектів',

  textFifth: 'Розглянемо інфраструктуру та інженерні комунікації',

  textSixth: 'Оцініть майбутній потенціал і вартість перепродажу',
};
