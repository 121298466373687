import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import bgImage from '../../shared/assets/images/slider-images/slider-img-01.jpg';
export const StubProjectsPageWrapper = styled.div`
  position: relative;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colorVariables.tabletOpacityBackgroundColor};
  }
`;

export const Wrapper = styled.div`
  z-index: 10;
  padding: 25rem 2.5rem;
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 16.25rem 4.375rem;
  }
`;
export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const BottomWrapper = styled(TopWrapper)`
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 1rem;
  margin-top: 10px;
`;
export const Img = styled.img`
  width: 13.437rem;
  height: 6.875rem;
  margin: 0 1.25rem;

  @media (min-width: ${Breakpoints.xlg}px) {
    width: 25rem;
    height: 12.5rem;
    margin: 0 1.875rem;
  }
`;
export const Title = styled.p`
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;

  .translated & {
    font-size: 20px;
  }
  @media (min-width: ${Breakpoints.md}px) {
    font-size: 52px;
    line-height: 63px;

    .translated & {
      font-size: 25px;
    }
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 100px;
    line-height: 122px;

    .translated & {
      font-size: 50px;
    }
  }
`;
