import React, { FC } from 'react';
import {
  ProcessWrapper,
  List,
  Item,
  Img,
  TextWrapper,
  Title,
  Text,
} from './ProcessSection.styled';
import { useTranslation } from 'react-i18next';
import { getProcessList } from '../ArchitectPage.constants';

export const ProcessSection: FC = () => {
  const { t } = useTranslation();
  const processList = getProcessList();

  return (
    <ProcessWrapper>
      <List>
        {processList.map(({ number, label, description, img }) => {
          return (
            <Item key={number}>
              <Img src={img} loading="lazy" />
              <TextWrapper>
                <Title>{label}</Title>
                <Text>{description}</Text>
              </TextWrapper>
            </Item>
          );
        })}
      </List>
    </ProcessWrapper>
  );
};
