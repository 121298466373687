import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';

export const ColorsSectionWrapper = styled.div`
  width: 100%;
  z-index: 10;
  border-top: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    margin: 5rem 0;
  }
`;

export const ColorsInfoBlockWrapper = styled.div`
  padding: 0;
  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0 ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 ${paddings.desktopSidePadding};
  }
`;

export const ColorsInfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-right: 1px solid ${colorVariables.blockBorderColor};
    border-left: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const ColorsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.75rem;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  text-transform: uppercase;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    border-right: 1px solid ${colorVariables.blockBorderColor};
    border-bottom: none;
    font-size: 3.125rem;
    line-height: 3.75rem;
  }
`;

export const ColorsDescription = styled.div`
  font-family: 'Overpass';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 1rem 1.25rem;
  background: ${colorVariables.alternativeBackground};
  backdrop-filter: blur(10px);
  color: rgba(255, 255, 255, 0.7);

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 2.812rem 6.875rem;
  }
`;

export const ColorsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  padding: 2.5rem 0;
  border-top: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem ${paddings.tabletSidePadding};
    gap: 0.625rem 0;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 3.75rem ${paddings.desktopSidePadding};
    gap: 1.25rem 0;
  }
`;

export const ColorBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.75rem;
  background-image: url(${props => props.backgroundColor});
  background-size: contain;


  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: 25%;
    height: 10.75rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc(100% / 6);
    height: 13rem;
  }
`;

export const ColorTitle = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  background: rgba(23, 23, 25, 0.2);
  border: 1px solid rgba(23, 23, 25, 0.04);

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0.5rem 1rem;
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
    padding: 0.5rem 1.25rem;
  }
`;
