export const navItems = {
  preConstruction: 'Preconstrucción',
  process: 'Proceso',
};

export const description =
  'Gracias a nuestra rica experiencia y la realización de alta calidad de los proyectos de construcción de capital, garantizamos a cada uno de nuestros clientes un enfoque profesional y la mejor política de precios. Compramos materiales de construcción probados a precios competitivos, proporcionamos informes para cada etapa, realizamos de forma competente toda la gama de trabajos en la construcción de casas de ladrillo, hormigón celular, hormigón armado, bloques, utilizando estructuras metálicas y otros elementos portantes del edificio. El palacio de Buckingham, la torre de Rapunzel o el castillo ambulante de Howl: podemos hacer realidad cualquiera de sus deseos arquitectónicos y construir una casa llave en mano.';

export const processList = {
  labelFirst: 'Atención al medio ambiente',
  descriptionFirst:
    'Nos esforzamos por utilizar materiales orgánicos que no dañen la naturaleza. Los sustitutos baratos y los productos químicos son tabú para nosotros. No sólo nos preocupamos por el medio ambiente, sino también por nuestros clientes, que vivirán, crearán, trabajarán, dormirán y recibirán invitados en la casa.',

  labelSecond: 'Experiencia y profesionalidad',
  descriptionSecond:
    'Nuestro equipo está formado por profesionales altamente cualificados y experimentados en diversos tipos y estilos de construcción. Sabemos qué materiales y tecnologías son los más adecuados para cada proyecto específico.',

  labelThird: 'Obra compleja',
  descriptionThird:
    'Ofrecemos servicios de construcción de casas ""llave en mano"", lo que significa que nos encargamos de todas las fases del trabajo, desde el diseño hasta la puesta en marcha. Esto nos permite asegurar un alto nivel de calidad de las obras y garantizar el cumplimiento de los plazos.',

  labelFourth: 'La calidad de los materiales',
  descriptionFourth:
    'Sólo utilizamos materiales y componentes de alta calidad de proveedores de confianza, lo que garantiza la durabilidad y fiabilidad de su casa. Sólo trabajamos con proveedores de confianza para garantizar la durabilidad de cada metro cuadrado de su casa.',

  labelFifth: 'Enfoque individual',
  descriptionFifth:
    'Queremos entender las necesidades de cada uno de nuestros clientes y satisfacerlas. Ya se trate de una casa de fiesta, un adosado para una pareja de ancianos o un chalet para una familia joven con niños, cada cliente tiene necesidades individuales. Tenemos en cuenta sus deseos y requisitos, y le ayudamos a elegir las mejores soluciones para su proyecto. Trabajamos con cada cliente de forma individual para crear un hogar que satisfaga todas sus necesidades y preferencias.',
  labelSixth: 'Control de calidad',
  descriptionSixth:
    'Controlamos estrictamente la calidad de todas las fases del trabajo, desde la selección de los materiales hasta la instalación de los equipos. Esto nos permite ofrecer un alto nivel de calidad del trabajo y garantizar el cumplimiento de todas las normas y requisitos. Proporcionamos informes sobre cada etapa y garantizamos la transparencia.',
};

export const aboutTexts = {
  textFirst: 'Establecemos nuevos estándares en el mercado.',

  textSecond:
    'Para nosotros, cada casa no son sólo metros cuadrados, sino un espacio acogedor, <br/> pensado hasta el más mínimo detalle, en el que quieres vivir, disfrutar y llamar legítimamente a este lugar ""hogar"".',

  textThird:
    'Construimos casas que se asocian a los mejores momentos de la vida: <br/> la primera palabra de tu hijo, la proposición de matrimonio, la velada más cálida con tu familia.',

  textFourth: 'Construimos casas que se convierten en tu vida.',

  textFifth:
    'El principal valor para nosotros es su sonrisa, la principal recompensa es la confianza.',
};

export const preConstructionTitle = 'Etapas de la construcción';

export const preConstructionText =
  'Nos esforzamos por crear no sólo un hogar, sino un verdadero rincón de felicidad y confort, donde disfrutar de cada minuto. No nos limitamos a crear casas estándar, sino que ayudamos a hacer realidad su estilo de vida único en su propio terreno. Por eso, asumimos cualquier proyecto y sabemos que nada es imposible. Su fantasía arquitectónica puede hacerse realidad hoy mismo, sólo tiene que ponerse en contacto con nosotros. Encontraremos las mejores soluciones para su futuro hogar, y junto con usted desarrollaremos la opción ideal que se convertirá en la encarnación de sus sueños y deseos.';

  export const constructionStepBlockTitle = ' Etapas de la construcción';
export const constructionStepBlockDescription = 'Consulta inicial';

export const constructionStepSection = {
  blockTitle: ' Etapas de la construcción',
  descriptionTitle: ' Consulta inicial',
  stepOne: {
    title: 'Paso uno',
    p1: `La planificación. La primera fase de la construcción es el momento en que nace la idea de la futura casa. Aquí nos hacemos preguntas sobre cómo debe ser la casa, cómo debe ubicarse en el solar y qué materiales se utilizarán. Durante el proceso de diseño, nuestros expertos mejoran cada detalle de la casa, desde los cimientos y los muros de carga hasta el tejado y los conductos de ventilación.`,
    p2: `El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier inconveniente, durará alrededor de 12 meses.`,
  },
  stepTwo: {
    title: 'Paso dos',
    p1: `Trabajos preparatorios y cimentación. Antes de empezar a construir una casa, hay que realizar toda una serie de trabajos preparatorios. Esto incluye la limpieza del sitio de los escombros, la eliminación de la vegetación innecesaria, la nivelación del suelo, el desmantelamiento de edificios antiguos. Pero esto es sólo el principio: después hay que preparar los futuros cimientos de la casa. Una de las etapas más importantes es el desarrollo de los cimientos. Antes de empezar a cavar un hoyo, es necesario llevar todas las comunicaciones y marcar la futura estructura.`,
    p2: `Sólo después podrá empezar a construir los cimientos. La elección del tipo de cimentación depende de muchos factores, como las características del suelo, la superficie y el número de plantas de la casa. Cada tipo de cimentación tiene sus propias características y requisitos de calidad de los materiales. Por ejemplo, cuando se utiliza hormigón, es necesario tener en cuenta su contracción, que se produce durante el proceso de secado. Por lo tanto, confiando en nuestro equipo, puede estar seguro al 100% de que su casa tendrá unos cimientos sólidos.`,
  },
  stepThree: {
    title: 'Paso tres',
    p1: `Paredes y suelos. Una vez fijados los cimientos, llega el momento de construir las paredes. Es importante elegir un material que proporcione solidez, resistencia a las heladas y fiabilidad al futuro diseño. Hoy en día, uno de los materiales más comunes para la construcción de edificios bajos es el ladrillo, el bloque de hormigón de gas y el bloque de espuma. El ladrillo es un material bello, respetuoso con el medio ambiente y duradero que se utiliza para la construcción de muros de carga y tabiques interiores. Gracias a la posibilidad de combinar distintos tipos de ladrillos, se puede conseguir un edificio atractivo en estilo español o cualquier otro que no requiera decoración adicional. Sin embargo, el ladrillo es pesado y el constructor lo coloca lentamente.
Los bloques de hormigón celular son un material ligero, duradero y respetuoso con el medio ambiente, con altas propiedades de aislamiento térmico. Gracias a su forma y dimensiones precisas, la colocación de los bloques de hormigón celular es rápida. Los bloques de hormigón celular no se desmoronan ni siquiera al cortarlos con una sierra convencional.`,
    p2: `Sin embargo, el hormigón celular puede absorber la humedad del mar y no es el material más agradable estéticamente, lo que puede corregirse con un acabado adicional de la fachada.
En el proceso de levantamiento de muros, se lleva a cabo la instalación de techos y tabiques interiores. Parámetros importantes del futuro diseño son sus prestaciones de aislamiento térmico, acústico y de vapor, que mejoran notablemente el rendimiento del edificio.`,
  },
  stepFour: {
    title: 'Paso cuatro',
    p1: `Selección e instalación del tejado El tejado no es un elemento menos importante del edificio que los cimientos y las paredes. No sólo protege el edificio de las inclemencias del tiempo, sino que también contribuye a su atractivo estético. Uno de los tipos de tejado más comunes son los tejados a cuatro aguas, que se distinguen por su rigidez y su atractivo aspecto. Proporcionan una protección adicional a las paredes del edificio frente a las influencias externas, pero al mismo tiempo reducen la superficie del ático y requieren un diseño y una instalación profesionales, que nuestra empresa puede proporcionar. Los tejados piramidales, también están muy solicitados.`,
    p2: `Se caracterizan por una mínima susceptibilidad al viento, lo que puede ser una ventaja adicional para los residentes en España, que conocen bien el frío viento del norte Tramuntana. Es una solución ideal para edificios cuadrados y también más barata que los tejados a cuatro aguas. Los tejados planos son otra opción que se caracteriza por una gran rapidez de instalación y un bajo coste de colocación.`,
  },
  stepFive: {
    title: 'Paso cinco',
    p1: `Acabado.Los trabajos de acabado, que no sólo dan al edificio un aspecto final, sino que también desempeñan un papel importante en la creación de un espacio confortable para los residentes. Para mantener un clima interior agradable, se utilizan revocos modernos. En los casos en que es necesario, se instalan fachadas ventiladas. A continuación comienza la instalación de ventanas, puertas, escaleras, así como la alineación de las pendientes y la conexión de las comunicaciones.`,
    p2: `Las paredes interiores se enlucen y se cubren con un revestimiento de acabado (pintura, papel pintado o yeso decorativo), y también se instalan los accesorios de fontanería y las lámparas. Por último, sólo queda colocar los muebles, y su nuevo hogar con alma española estará listo para entrar a vivir.`,
  },
};
