import styled from "styled-components";
import { ItemTitle } from "../../MenuListBlock/MenuListBlock.styled";
import { Breakpoints, colorVariables, transitions } from "../../../theme/Theme.constants";
import { MenuItem, Select } from "@mui/material";

export const FAQBlock = styled.div`
    position: relative !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1.25rem;
    width: 100%;

    .MuiSvgIcon-root {
        color: white !important;
    }

    .MuiInputBase-root {
        border: 1px solid ${colorVariables.blockBorderColor};
        border-radius: 0;

        .menu-icon {
            fill: ${colorVariables.iconYellow};
        }
    }

    .MuiSelect-outlined {
        outline: red !important;
    }

    .MuiFormControl-root {
        margin: 0;
    }
`;

export const FAQMenuTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 0 1.5rem;
    height: 4rem;
    background-color: ${colorVariables.opacityWhiteBackgroundColor};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    border: 1px solid ${colorVariables.blockBorderColor};
    border-left: none;
    border-right: none;

    @media only screen and (min-width: ${Breakpoints.md}px) {
        width: calc(100vw - 80px);
        border: 1px solid ${colorVariables.blockBorderColor};
    }
`;

export const SelectComponent = styled(Select)`
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    gap: 0.875rem;
    width: 100vw;
    height: 3rem;
    cursor: pointer;
    transition: ${transitions.backgroundHoverTransition};
    border-left: none;
    border-right: none;

    @media only screen and (min-width: ${Breakpoints.md}px) {
        width: calc(100vw - 80px);
        border: 1px solid ${colorVariables.blockBorderColor};
    }

    .MuiSelect-select {
        display: flex;
        align-items: center;
        gap: 0.875rem;
        font-family: "Montserrat", sans-serif;
        color: ${colorVariables.mainTextColor};
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
        padding-left: 0;
    }

    div {
        padding: 0 !important;
    }

    &:nth-child(2n - 1) {
        border-right: 1px solid ${colorVariables.blockBorderColor};
    }

    .menu-icon {
        fill: ${colorVariables.mainIconColor};
        width: 1.75rem;
        width: 1.75rem;
        transition: 0.3s all ease;
    }

    &:hover {
        background-color: ${colorVariables.opacityWhiteBackgroundColor};
        backdrop-filter: blur(2px);
        opacity: 1;
    }
`;

export const MenuItemComponent = styled(MenuItem)`
    display: flex;
    gap: 0.875rem;
    width: 100%;
    padding: 0 1.75rem !important;
    border: none;
    border-bottom: 1px solid ${colorVariables.blockBorderColor} !important;
    background-color: #1e1e1e !important;
    color: ${colorVariables.mainTextColor} !important;
    height: 3rem;
    transition: 0.3s background-color ease;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #262627 !important;
    }
`;

export const FAQAnswersTable = styled.div`
    display: flex;
    max-height: 627px;
    flex-direction: column;
    width: 100%;
`;

export const FAQAnswersTitle = styled(ItemTitle)`
  0.875rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const FAQAnswers = styled.ul`
  overflow-y: scroll;
  0.875rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  border: 1px solid ${colorVariables.blockBorderColor};

  
  &::-webkit-scrollbar {
    width: 0;
  }
`;
