export const SmartHomeStepSection = {
  blockTitle: 'Etapes de la llar intel·ligent',
  descriptionTitle: 'Mudar-se',
  stepOne: {
    title: 'Pas u',
    p1: `Planificació d'un sistema ""smart home"" abans de començar a instal·lar un sistema domèstic intel·ligent, cal planificar-lo acuradament. Això inclou identificar els dispositius i tecnologies adequats, la seva ubicació, el cablejat i altres detalls.`,
    p2: 'També ha de determinar el pressupost que està disposat a gastar-se a instal·lar i configurar el sistema.',
  },
  stepTwo: {
    title: 'Pas dos',
    p1: 'Compra de dispositius i materials. Un cop planificat el sistema domèstic intel·ligent, cal adquirir tots els dispositius i materials necessaris.',
    p2: `Pot tractar-se de sensors de moviment, panys de portes, termòstats, endolls intel·ligents, dispositius d'il·luminació o sistemes de videovigilància, entre d'altres.`,
  },
  stepThree: {
    title: 'Pas tres',
    p1: `Instal·lació de sensors i dispositius. En aquesta fase s'instal·len tots els sensors i dispositius. S' han d' instal·lar d' acord amb un pla prèviament determinat i tenint en compte la necessitat de cablejat. El nostre equip d'especialistes privats s'encarregarà de la instal·lació: electricistes, reparadors. `,
    p2: `Després d'instal·lar els dispositius i sensors, cal connectar-los al sistema de control. Pot ser un controlador domèstic intel·ligent que connectarà tots els dispositius entre si.`,
  },
  stepFour: {
    title: 'Pas quatre',
    p1: `Programació i configuració. Un cop connectats tots els dispositius al sistema de control, cal configurar-los perquè funcionin junts. Això pot incloure la creació de guions d'automatització, la instal·lació del control per veu i la configuració d'aplicacions per controlar el sistema.`,
    p2: `Una solució integral ha de reconèixer d'oïda el propietari de l'habitatge, conèixer els seus principals interessos i preferències.`,
  },
  stepFive: {
    title: 'Pas cinc',
    p1: `Proves. Un cop configurat un sistema domèstic intel·ligent, cal provar-ho per garantir que la innovació s'integra correctament. Això inclou comprovar l'estat de tots els dispositius i sensors, així com verificar els escenaris d'automatització i control. Ja en aquesta fase, el mecanisme ha après a adaptar-se als hàbits. El programa apagarà automàticament els llums de tots els racons de la casa quan tothom estigui ja adormit, desconnectarà els endolls innecessaris, posarà la calefacció per terra radiant i el sistema de climatització en mode estalvi, tancarà les portes i activarà el sistema de seguretat, que protegirà de forma fiable el perímetre de la casa.`,
    p2: `El sistema prepararà les habitacions per al matí, li servirà cafè aromàtic, el despertarà amb la seva melodia favorita i s'ocuparà de la seguretat dels seus fills.`,
  },
  stepSix: {
    title: 'Pas sis',
    p1: `El gaudi. L'etapa final, quan el mecanisme està llest per funcionar. Haurà de supervisar el funcionament del sistema, mantenir-lo i actualitzar-lo si és necessari. Aquesta etapa és la més esperada, ja que a partir d'aquest moment és capaç de dividir la vida en ""abans"" i ""després"" Casa seva es converteix en el guardià perfecte, que vigila acuradament cada finestra i porta, i també rep els convidats com el majordom més perfecte. Fins i tot t'avisa quan arriben convidats retornant-te la trucada al teu número, cosa que et permet estar en contacte amb ells encara que estiguis fora de casa.`,
    p2: `Amb una casa així, pots controlar-la mitjançant senzills comandaments al telèfon. A més, ara és possible oblidar-se de la foscor o la calor: amb només pulsar un botó s'encendrà la llum, es tancaran les cortines i es posarà la música. El mecanisme està dissenyat per fer més còmoda la vida a la casa, optimitzar el temps del resident i automatitzar processos clau.`,
  },
  
};
