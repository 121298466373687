export const blockTitle = 'Les étapes de la maison modulaire';

export const blockDescription = 'Consultation initiale';


export const modularHousesStepSection = {
  blockTitle: 'Les étapes de la maison modulaire',
  descriptionTitle: 'Consultation initiale',
  stepOne: {
    title: 'Conception et planification',
    p1: `Au cours de cette première étape, la taille, la disposition et le style de la maison modulaire sont déterminés. En collaboration avec un architecte ou un fabricant de maisons modulaires, une conception détaillée est créée, en tenant compte des exigences spécifiques et en obtenant les permis nécessaires.`,
    p2: ``,
  },
  stepTwo: {
    title: 'Préparation des fondations',
    p1: `Le déblaiement et le nivellement du site de construction constituent la première tâche de cette étape. L'excavation suit, avec la construction d'une fondation solide conforme aux codes de construction locaux. La fondation doit être correctement nivelée et aplanie pour supporter les unités modulaires.`,
    p2: ``,
  },
  stepThree: {
    title: "Fabrication d'unités modulaires",
    p1: `En collaboration avec un fabricant de maisons modulaires réputé, les modules individuels sont construits dans un environnement contrôlé en usine. Ce processus comprend la construction des murs, des planchers et des plafonds, ainsi que l'installation des systèmes électriques et de plomberie dans chaque module.`,
    p2: ``,
  },
  stepFour: {
    title: 'Transport et préparation du site',
    p1: `Une fois les modules terminés, ils sont transportés sur le site de construction à l'aide de camions spécialisés. Pendant ce temps, le site est préparé en installant les raccordements aux services publics et en réalisant les travaux nécessaires, tels que le nivellement et l'aménagement paysager.`,
    p2: ``,
  },
  stepFive: {
    title: 'Assemblage et installation',
    p1: `Les modules sont placés sur les fondations à l'aide de grues ou d'équipements de levage. Des techniciens qualifiés ou des équipes de construction fixent les modules ensemble, en veillant à ce qu'ils soient correctement alignés et étanches. Les connexions électriques et de plomberie entre les modules sont effectuées, et les finitions sont ajoutées.`,
    p2: ``,
  },
  stepSix: {
    title: 'Finition intérieure et extérieure',
    p1: `À cette étape, l'accent est mis sur l'achèvement des travaux intérieurs et extérieurs. Les travaux intérieurs comprennent l'installation des revêtements de sol, des finitions murales, des armoires et des accessoires, tandis que les travaux extérieurs comprennent le bardage, la toiture, les fenêtres et les portes. Ces finitions améliorent la fonctionnalité et l'esthétique de la maison modulaire.`,
    p2: ``,
  },
  stepSeven: {
    title: 'Utilités et intégration des systèmes',
    p1: `L'objectif de cette étape est de raccorder la maison modulaire aux services publics locaux, tels que l'eau, l'électricité et le gaz. Les systèmes électriques, de plomberie, de chauffage et de refroidissement sont testés minutieusement pour s'assurer de leur bon fonctionnement.`,
    p2: ``,
  },
  stepEight: {
    title: 'Inspections finales et certification',
    p1: `Pour garantir la conformité avec les codes et réglementations du bâtiment, une inspection finale par les autorités locales est prévue. Cette inspection couvre tous les aspects de la construction de la maison modulaire. Une fois approuvée, les certificats d'occupation ou de conformité nécessaires sont obtenus, ce qui signifie que la maison modulaire est légalement habitable.`,
    p2: ``,
  },
};
