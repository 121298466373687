export const ColorsSection = {
  title: 'Colores',
  description: `Experimente una impresionante colección de nuevos chips de color y agradables mezclas de colores primarios. Prepárese para quedar hipnotizado por el efecto holográfico translúcido que confiere un brillo distintivo a nuestros productos.`,
  
  first: 'Anthracite',
  second: 'Nova Blue',
  third: 'Nova Grey',
  fourth: 'Nova Navy',
  fifth: 'Nova Pearl',
  sixth: 'Nova Stone',
};
