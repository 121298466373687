import React, { FC } from 'react';
import {
  ProcessTopTextsWrapper,
  ProcessTitleWrapper,
  ProcessTitle,
  ProcessAfterTitleWrapper,
  ProcessAfterTitle,
} from './ProcessBlockHeader.styled';

interface ProcessHeaderProps {
  currentTexts: { label: string; description: string };
  titleSize?: string;
  isTabletPadding?: boolean;
  descriptionMaxWidth?: string;
   titleMaxWidth?: string;
  isTitleBorder?: boolean;
  mobileTitleSize?: string;
  isBackgroundTextColor?: boolean;
  isTextAlignCenter?: boolean;
  withoutDescription?: boolean;
  isColumnText?: boolean;
  withoutPadding?: boolean;
  titlePadding?: string;
  descriptionColor?: string;
  descriptionPadding?: string;
  titleWrapperWidth?: string;
  componentMarginBottom?: string;
}
export const ProcessBlockHeader: FC<ProcessHeaderProps> = ({
  currentTexts,
  titleSize,
  mobileTitleSize,
  isTabletPadding = false,
  descriptionMaxWidth,
  isTitleBorder = true,
  isBackgroundTextColor,
  titleMaxWidth,
  isTextAlignCenter,
  withoutDescription = false,
  isColumnText,
  withoutPadding,
  titlePadding,
  descriptionColor,
  descriptionPadding,
  titleWrapperWidth,
  componentMarginBottom
}) => {
  return (
    <ProcessTopTextsWrapper
      isTabletPadding={isTabletPadding}
      withoutPadding={withoutPadding}
      isColumnText={isColumnText}
    componentMarginBottom={componentMarginBottom}>
      <ProcessTitleWrapper
        isTitleBorder={isTitleBorder}
        isBackgroundTextColor={isBackgroundTextColor}
        isColumnText={isColumnText}
        titlePadding={titlePadding}
        titleWrapperWidth={titleWrapperWidth}
        withoutDescription={withoutDescription}>
        <ProcessTitle
          titleSize={titleSize}
          mobileTitleSize={mobileTitleSize}
          titleMaxWidth={titleMaxWidth}
          isTextAlignCenter={isTextAlignCenter}
          withoutDescription={withoutDescription}
          isBackgroundTextColor={isBackgroundTextColor}>
          {currentTexts.label}
        </ProcessTitle>
      </ProcessTitleWrapper>
      {!withoutDescription && (
        <ProcessAfterTitleWrapper
          isTitleBorder={isTitleBorder}
          isBackgroundTextColor={isBackgroundTextColor}
          descriptionPadding={descriptionPadding}
          isTextAlignCenter={isTextAlignCenter}>
          <ProcessAfterTitle
            descriptionMaxWidth={descriptionMaxWidth}
            isBackgroundTextColor={isBackgroundTextColor}
            descriptionColor={descriptionColor}
            isTextAlignCenter={isTextAlignCenter}>
            {currentTexts.description}
          </ProcessAfterTitle>
        </ProcessAfterTitleWrapper>
      )}
    </ProcessTopTextsWrapper>
  );
};
