export const solarEnergyBenefitsTexts = {
  label: "Avantages de l'énergie solaire",
  description:
    "Les panneaux solaires sont des dispositifs qui convertissent l'énergie solaire en électricité. L'utilisation de panneaux a un impact positif sur l'économie et l'environnement. Ils permettent de réduire les coûts d'électricité, de diminuer l'empreinte carbone et d'augmenter la valeur de la propriété. Bien que certains propriétaires hésitent quant au coût et à l'entretien, les avantages l'emportent sur les inconvénients.",
};
export const benefitsList = {
  firstLabel: `Pas d'émissions de gaz nocifs`,
  secondLabel: 'Longue durée de vie des équipements.',
  thirdLabel: `Amélioration de la sécurité énergétique`,
  fourthLabel: `Source d'énergie inépuisable`,
};
