
export const philosophyTextsList = [
  {
    id: 1,
    label: 'Gestió del risc',
    description:
      'Adoptem un enfocament disciplinat de la gestió del risc, centrat a preservar el capital i evitar pèrdues permanents.',
  },

  {
    id: 2,
    label: 'Inversió en valor',
    description:
      `Busquem invertir en empreses d'alta qualitat amb sòlids fonaments que cotitzin amb descompte respecte al seu valor intrínsec.`,
  },

  {
    id: 3,
    label: 'Perspectiva a llarg termini',
    description:
      `Adoptem un enfocament d'inversió pacient i a llarg termini, centrant-nos en els fonaments de les empreses en les quals invertim.`,
  },
];

export const philosophyTitle =
  `La nostra filosofia d'inversió es basa en tres principis fonamentals:`;