import React, { FC } from 'react';
import { InfoBlock, SliderSectionWrapper } from './SliderSection.styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import firstSlide from '../../shared/assets/images/slider-images/slider-img-01.jpg';
import secondSlide from '../../shared/assets/images/slider-images/slider-img-02.jpg';
import thirdSlide from '../../shared/assets/images/slider-images/slider-img-03.jpg';
import fourthSlide from '../../shared/assets/images/slider-images/slider-img-4.jpg';
import 'swiper/css';
import 'swiper/css/pagination';
import { BluredButton } from '../../shared/components/BluredButton';
import { Breakpoints } from '../../theme/Theme.constants';
import { useMediaQuery } from 'react-responsive';
import { MobileSlider } from './MobileBlock';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const SliderSection: FC = () => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const slides = [
    { id: 1, img: firstSlide, titles: t('mainPage.sliderTitles.CATALUNYA') },
    { id: 2, img: secondSlide, titles: t('mainPage.sliderTitles.CATALUNYA') },
    { id: 3, img: thirdSlide, titles: t('mainPage.sliderTitles.CATALUNYA') },
    { id: 4, img: fourthSlide, titles: t('mainPage.sliderTitles.CATALUNYA') },
  ];

  return (
    <>
      {isDesktop ? (
        <SliderSectionWrapper>
          <Swiper
            pagination={pagination}
            modules={[Pagination]}
            className="mySwiper">
            {slides.map(({ id, img, titles }) => {
              return (
                <SwiperSlide key={id}>
                  <LazyLoadImage
                    loading="lazy"
                    src={img}
                    alt=""
                    effect="blur"
                    visibleByDefault
                  />
                  <InfoBlock>{titles}</InfoBlock>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <BluredButton
            title={t('mainPage.sliderTitles.LANDSCAPES_BTN')}
            handleClick={() => {}}
            className="main-btn"
          />
          {/* <BluredButton
            title={t('mainPage.sliderTitles.LEARN_MORE')}
            handleClick={() => {}}
            className="learn-more-btn"      // LOGIC FOR FUTURE FEATURES
          /> */}
        </SliderSectionWrapper>
      ) : (
        <MobileSlider />
      )}
    </>
  );
};
