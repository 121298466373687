import React, { FC, useEffect } from 'react';
import { SwimmingPoolsWrapper } from './SwimmingPoolsPage.styled';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { useTranslation } from 'react-i18next';
import { PoolsSection } from './PoolsSection/PoolsSection';
import { StepsBlockComponent } from '../../shared/components/StepsBlock/StepsBlock';
import { ColorsSection } from './ColorsSection';
import { TechnologySection } from './TechnologySection';
// import { AccessoriesSection } from './AccessoriesSection';
import { SliderSection } from '../../shared/components/PagesSliderSection';
import firstSliderImage from '../../shared/assets/images/swiming-pools/slider-image-1.jpg';
import secondSliderImage from '../../shared/assets/images/swiming-pools/slider-image-2.jpg';
import thirdSliderImage from '../../shared/assets/images/swiming-pools/slider-image-3.jpg';
import fourthSliderImage from '../../shared/assets/images/swiming-pools/slider-image-4.jpg';
import Video from '../../shared/assets/video/pools.mp4';
import { Breakpoints } from '../../theme/Theme.constants';
import { VideoBlock } from '../../shared/components/VideoBlock';
export const SwimmingPoolsPage: FC = () => {
  const menuItems = getMenuItems();
  const swimmingPoolsInfo = menuItems[3];
  const { t } = useTranslation();

  useEffect(() => {
    const images = [firstSliderImage, secondSliderImage, thirdSliderImage];

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);
  const navItems = [
    {
      title: t('swimmingPools.HeaderSection.pools'),
      sectionId: 'pools-section',
    },
    {
      title: t('swimmingPools.HeaderSection.colors'),
      sectionId: 'colors-section',
    },
    // {
    //   title: t('swimmingPools.HeaderSection.technology'),
    //   sectionId: 'technologies-section',        For future features
    // },
    // {
    //   title: t('swimmingPools.HeaderSection.accessories'),
    //   sectionId: 'accessories-section',        For future features
    // },
    {
      title: t('swimmingPools.HeaderSection.stages'),
      sectionId: 'stages-section',
    },
  ];

  const slides = [
    { id: 1, img: firstSliderImage },
    { id: 2, img: secondSliderImage },
    { id: 3, img: thirdSliderImage },
    { id: 4, img: fourthSliderImage },
  ];

  const smartHomeStepsData = [
    {
      id: 1,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepOne.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepOne.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepOne.p2')}</p>
        </>
      ),
    },
    {
      id: 2,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepTwo.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepTwo.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepTwo.p2')}</p>
        </>
      ),
    },
    {
      id: 3,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepThree.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepThree.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepThree.p2')}</p>
        </>
      ),
    },
    {
      id: 4,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepFour.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepFour.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepFour.p2')}</p>
        </>
      ),
    },
    {
      id: 5,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepFive.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepFive.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepFive.p2')}</p>
        </>
      ),
    },
    {
      id: 6,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepSix.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepSix.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepSix.p2')}</p>
        </>
      ),
    },

    {
      id: 7,
      title: t('swimmingPools.SwimmingPoolsStepsSection.stepSeven.title'),
      description: (
        <>
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepSeven.p1')}</p>
          <br />
          <p>{t('swimmingPools.SwimmingPoolsStepsSection.stepSeven.p2')}</p>
        </>
      ),
    },
  ];

  return (
    <SwimmingPoolsWrapper>
      <VideoBlock video={Video} />
      <PageHeaderSection
        title={swimmingPoolsInfo.label}
        logoImg={swimmingPoolsInfo.icon}
        navItems={navItems}
        description={t('swimmingPools.HeaderSection.description')}
        id={swimmingPoolsInfo.id}
        backgroundColor={'none'}
      />
      <PoolsSection />
      <ColorsSection />

      <TechnologySection />
      {/* <TechnologySection />      //////  For future features
      <AccessoriesSection /> */}
      <StepsBlockComponent
        stepsData={smartHomeStepsData}
        blockTitle={t('swimmingPools.SwimmingPoolsStepsSection.blockTitle')}
        description={t(
          'swimmingPools.SwimmingPoolsStepsSection.descriptionTitle',
        )}
        sectionId="stages-section"
      />
      <SliderSection slides={slides} />
    </SwimmingPoolsWrapper>
  );
};
