import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';

import { ReactComponent as ViewIcon } from '../../assets/icons/view-icon.svg';
import {
  HeaderSection,
  HeaderWrapper,
  TextWrapper,
  Title,
  InfoList,
  InfoItem,
  InfoText,
  MainInfoWrapper,
  MainTitle,
  VillaInfoText,
  MainText,
  MoreBtn,
  ViewBtn,
  ViewBtnText,
} from './CaseHeaderSection.styled';
interface CaseHeaderProps {
  title: string;
  aboutList: { id: number; aboutInfo: string }[];
  infoText: string;
  mainText: string;
  btnText: string;
  isViewBtn: boolean;
  bgImage: string;
}
export const CaseHeaderSection: FC<CaseHeaderProps> = ({
  title,
  aboutList,
  infoText,
  mainText,
  btnText,
  isViewBtn,
  bgImage,
}) => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  return (
    <HeaderSection bgImage={bgImage}>
      <HeaderWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <InfoList>
            {aboutList.map(({ id, aboutInfo }) => {
              return (
                <InfoItem key={id}>
                  <InfoText>{aboutInfo}</InfoText>
                </InfoItem>
              );
            })}
          </InfoList>
        </TextWrapper>
        {isTablet && <MoreBtn>{btnText}</MoreBtn>}
      </HeaderWrapper>

      <MainInfoWrapper>
        <MainTitle>{title}</MainTitle>
        <VillaInfoText>{infoText}</VillaInfoText>
        <MainText>{mainText}</MainText>
      </MainInfoWrapper>

      {isViewBtn && (
        <ViewBtn>
          <ViewIcon />
          <ViewBtnText>3D {t('interiorCasePage.viewBtnText')}</ViewBtnText>
        </ViewBtn>
      )}
    </HeaderSection>
  );
};
