
export const title = {
   services: 'Servicios',
   top: 'Top',
}

export const allServices = ' Todos los servicios';












export const description = "En Moderna Casa, ofrecemos a nuestros clientes soluciones de vanguardia para terrenos e inmuebles, teniendo en cuenta los deseos individuales de cada uno. Nuestro servicio está relacionado con la prestación de una gama completa de servicios - desde el solar hasta la casa de ensueño: diseño y construcción, equipamiento del territorio con piscinas, sistemas inteligentes y paneles solares. Nuestro equipo altamente cualificado presta servicios de consultoría, sigue las tendencias, mejora constantemente la calidad del trabajo y garantiza resultados excepcionales que superan las expectativas de nuestros clientes. Avanzamos con confianza hacia el título de líder del mercado, construyendo una base estratégica a través de la confianza y los estándares profesionales."