export const architectNavItems = {
  portfolio: 'Portfolio',

  process: 'Process',

 
};

export const architectSecondDescription =
  'The relocation boom in Spain encourages people to create unique homes and villas to stand out from others. Densely populated places can limit the opportunities for the realization of their talents and skills, as well as negatively affect health and the environment. In turn, a private cottage or environmentally friendly housing, located away from the bustle of the city, will be an ideal place where the city dweller can relax and enjoy nature. If you have such a dream, we can help make it come true. Just take a piece of paper and a pencil, sketch out the visualization of your dream, which will soon become a reality in the form of a unique architectural solution.';
