import i18next from 'i18next';

export const investmentsProcess = () => {
  const processItems = i18next.t('investmentsPage.investmentsProcess', {
    returnObjects: true,
  }) as Array<{ number: number; label: string; description: string }>;


  return processItems;
};
