export const LandscapeDesign = [
  {
    id: 1,
    title: 'Paisajismo: ¿elemento importante o capricho?',
    description: `Las casas de estilo español, especialmente en el clima mediterráneo de Cataluña, van de la mano de la creación de jardines únicos. Aquí se formaron ciertos rasgos del paisajismo local, que aún influyen en el diseño de los jardines de la zona. Uno de los rasgos característicos es el uso de flores frescas para decorar la fachada de la casa. A menudo se utilizan plantas en maceta o trepadoras, que confieren a la casa un encanto y una belleza especiales. Un elemento importante es la creación de un rincón para pasar un rato agradable con amigos y familiares. Puede ser un lugar decorado para una fiesta o para relajarse, donde disfrutar del ambiente y la comunicación. Un elemento tradicional popular es el patio: un patio acogedor con asientos a la sombra y muebles cómodos. Es un lugar donde se puede disfrutar del aire fresco y la intimidad dentro del propio jardín. Se agradece la abundancia y variedad de plantas de todo tipo en los jardines catalanes, desde musgos hasta trepadoras. Aquí podrá ver plantas en macetas y contenedores que añaden acentos brillantes y crean composiciones interesantes. El diseño paisajístico es una parte integral del mercado inmobiliario en España. El exterior ayuda a aumentar el precio de la propiedad y a demostrar la individualidad del propietario.`,
  },
  {
    id: 2,
    title:
      '¿Cuánto tiempo suele llevar desarrollar y ejecutar un proyecto de paisajismo? ',
    description: `Diseñar y ejecutar un proyecto paisajístico es un proceso creativo y polifacético que requiere tiempo y atención al detalle. El plazo para completar un proyecto puede variar en función del alcance, la complejidad y los requisitos específicos del cliente.
Los pequeños proyectos de paisajismo, como un pequeño jardín o terraza, pueden diseñarse y ejecutarse en unas pocas semanas o meses. En estos casos, el proceso incluye:
* Elaboración de un plan de diseño.
* Selección de plantas y materiales.
* Realización de los trabajos de plantación y decoración.
Los proyectos medianos, como ajardinar una zona suburbana o una propiedad comunitaria, pueden requerir varios meses de trabajo. En este caso, el proceso incluye una planificación más detallada, consultas con el cliente, desarrollo del concepto y visualización en 3D, así como la ejecución del paisajismo, los elementos arquitectónicos y la infraestructura.
Los proyectos más grandes y complejos, como el paisajismo de parques o complejos públicos, pueden requerir años de trabajo. En estos casos, el proceso incluye:
* Planificación a largo plazo.
* Diseño del plan maestro.
* Participación en las aprobaciones con organismos gubernamentales y otras partes interesadas.
* Ejecución del proyecto por fases.
Nuestros expertos intentarán crear el interior perfecto para usted lo antes posible. Por lo tanto, no lo deje para mañana; solicite el servicio hoy mismo e infórmese de los detalles de las condiciones de trabajo.
`,
  },
  {
    id: 3,
    title:
      'Paisajismo: ¿lo hace usted mismo o recurre a los servicios de profesionales?',
    description: `La imagen que tiene en la cabeza a veces coincide con la realidad. El paisajismo es el arte de crear espacios bellos y funcionales que aporten alegría y confort. A la hora de hacer realidad su sueño de un jardín o parcela ideal, surge la pregunta: ¿hacerlo todo usted mismo o recurrir a la ayuda de profesionales?
Hacer usted mismo el paisajismo puede ser un proceso emocionante y creativo que le permita dar vida a sus propias ideas y preferencias. Al hacerlo todo usted mismo, puede ahorrar algo de dinero y tener pleno control sobre el proceso de ejecución. Esto puede ser especialmente interesante para personas con ciertas habilidades y conocimientos en paisajismo y diseño.
Pero el diseño paisajístico es un proceso complejo y polifacético que requiere un profundo conocimiento de las plantas, los suelos, las condiciones climáticas y experiencia en planificación y composición. Una planificación o selección de plantas inadecuada puede dar lugar a resultados insatisfactorios y a pérdidas de tiempo y dinero. Nuestros profesionales tienen los conocimientos y la experiencia necesarios para crear un diseño único y funcional, teniendo en cuenta sus deseos y las características del lugar. Serán capaces de multiplicar las ventajas y ocultar los inconvenientes del territorio, utilizar cada metro cuadrado de la forma más competente posible y centrarse en la comodidad de los residentes. También podrán ahorrarle tiempo y esfuerzo utilizando los recursos de forma eficiente.`,
  },
  {
    id: 4,
    title:
      '¿Te ocupas tú mismo del exterior o recurres a los servicios de especialistas?',
    description: `Una vez finalizado el proyecto, nuestros paisajistas pueden ofrecerle su asistencia y consejos de mantenimiento para ayudarle a conservar y mantener la belleza y salud de su jardín durante años. Pueden aconsejarle sobre el riego adecuado, el abono, la poda y el cuidado general de las plantas. Al fin y al cabo, cada planta requiere su propio enfoque, y los profesionales le ayudarán a determinar el régimen de cuidados óptimo.
Además, los paisajistas pueden asesorarle sobre el mantenimiento y cuidado de otros elementos de su jardín o exterior, como caminos, fuentes y estanques artificiales. Compartirán con usted sus conocimientos sobre la limpieza y el mantenimiento adecuados de estos elementos para que siempre tengan un aspecto atractivo y seguro. Además, los paisajistas pueden ayudarle a determinar los trabajos y el mantenimiento estacionales que requiere su jardín. Le indicarán cómo preparar su jardín para el invierno o el verano, eliminar adecuadamente el follaje, podar las plantas o realizar los trabajos de mantenimiento necesarios.
Si quiere ahorrar tiempo, puede seguir colaborando con nosotros y le ayudaremos a mantener su jardín.
`,
  },
  {
    id: 5,
    title: 'Césped enrollado o sembrado, ¿qué es mejor para el lugar?',
    description: `He aquí algunas preguntas que pueden ayudarle a decidir:
1. ¿Cuál es el tamaño de la parcela? Los céspedes en rollo suelen ser preferibles para superficies pequeñas o medianas, ya que pueden instalarse rápidamente y dan al jardín un aspecto completo de inmediato. Los céspedes sembrados son más adecuados para zonas más grandes, donde puede justificarse un proceso de siembra más largo.
2. ¿Cuánto tiempo está dispuesto a dedicar a crear un césped? Los céspedes de siembra necesitan más tiempo. Los céspedes en rollo ofrecen resultados rápidos, listos para usar tras la colocación.
3. ¿Cuál es su presupuesto? Los céspedes de siembra pueden ser más económicos, sobre todo para superficies grandes. Los céspedes en rollo suelen ser más caros que los sembrados, pero proporcionan resultados más inmediatos.
4. ¿Cuál es el clima de su zona? Algunos tipos de césped pueden adaptarse mejor a determinadas condiciones climáticas, como las regiones secas o húmedas. Los céspedes de siembra pueden ser más flexibles a la hora de seleccionar las variedades de césped mejor adaptadas a su clima.
5. ¿Qué efecto estético desea conseguir? Los céspedes en rollo suelen tener un aspecto más uniforme e instantáneo, mientras que los céspedes sembrados pueden crear un paisaje más natural y progresivo.
`,
  },

  {
    id: 6,
    title: '¿Qué elegir, riego automático o riego manual de las plantas?',
    description: `Riego automático:
        1. El riego automático proporciona comodidad, especialmente si tiene un jardín grande o muchas plantas. Puede establecer el programa de riego, y el sistema controlará de forma independiente el riego regular y uniforme de las plantas sin su presencia.
        2. El riego automático ahorra tiempo que emplearía regando manualmente cada planta.
        3. Los sistemas de riego automático suelen estar equipados con sensores de humedad que pueden detectar cuándo las plantas necesitan riego y sólo suministran agua cuando es necesario. Esto ayuda a evitar el derroche de agua y a conservarla.
        Riego manual:
        1. El riego manual te permite controlar el proceso de riego e interactuar directamente con las plantas. Puedes observar mejor su estado y responder a cualquier problema o necesidad.
        2. El riego manual le permite elegir los lugares y la cantidad de riego para cada planta por separado. Puede adaptar el riego a las características de cada planta, sus necesidades de humedad y las condiciones ambientales.
        3. El riego manual no requiere la instalación de equipos y sistemas especiales, por lo que puede resultar más rentable que el riego automático.
        A la hora de elegir entre el riego automático y el manual, tenga en cuenta el tamaño de su jardín, el número de plantas, la disponibilidad de agua y las preferencias de cuidado de las plantas. Para consultas adicionales y análisis detallados, puede ponerse en contacto con nuestros especialistas.

`,
  },
];

export const SolarEnergy = [
  {
    id: 1,
    title: '¿Es rentable utilizar paneles solares? ',
    description: `Sí, en España instalar paneles solares es una opción cada vez más atractiva e inteligente. A la hora de evaluar la eficiencia de un sistema para el consumo privado de electricidad, hay que tener en cuenta que la vida útil de los paneles solares es de unos 25 años, y la inversión suele amortizarse en unos 7 años. Esto significa que los propietarios de sistemas solares en España no sólo tienen acceso a una energía limpia y renovable, sino que también tienen la oportunidad de reducir significativamente sus costes energéticos durante un largo periodo de tiempo. Es más, los paneles solares pueden reducir la exposición a las fluctuaciones de los precios de la energía y mitigar el impacto de los cambios en el coste de la electricidad.`,
  },

  {
    id: 2,
    title: '¿Se pueden evitar los altos precios de la electricidad?',
    description: `El sol es un recurso inestimable. En España, los precios de la energía son ahora casi tres veces superiores a la media de los últimos cinco años. Gracias a su abundancia de sol, España apuesta por los paneles solares como fuente alternativa de energía. La amortización es mucho más rápida que en el Reino Unido o Suecia.`,
  },

  {
    id: 3,
    title: '¿Cuál será el futuro de la energía solar en España?',
    description: `Para 2030, España prevé que casi el 75% de la producción energética del país proceda de fuentes alternativas, mientras que la cifra actual ronda el 47%. España apuesta por un futuro sostenible basado en fuentes de energía limpias.`,
  },

  {
    id: 4,
    title: '¿Es difícil mantener los paneles solares?',
    description: `El uso de paneles solares implica un proceso sencillo y no especialmente costoso para su mantenimiento. Por supuesto, esto es un poco más difícil que no hacer nada cuando su casa está conectada a las redes eléctricas públicas, pero tampoco es más difícil que con cualquier otro electrodoméstico - lavadora, frigorífico, que requieren mantenimiento. Si su instalación ha sido adquirida a proveedores de confianza, a usted, como cliente, se le ofrece un servicio completo de mantenimiento y reparación de la energía solar. Por su cuenta, puede limpiar los paneles de la suciedad y prolongar así su vida útil.`,
  },

  {
    id: 5,
    title: '¿Dónde más se demandan paneles solares?',
    description: `En China, más de 40 millones de hogares ya están equipados con calentadores solares de agua, lo que indica una gran demanda de esta tecnología. Pekín también ha instalado la impresionante cifra de 300 millones de metros cuadrados de paneles solares para 2020. España también está tomando medidas activas en el desarrollo de la energía solar. Desde marzo de 2006, todas las viviendas nuevas en España están obligadas por ley a estar equipadas con paneles solares. Sin embargo, el líder mundial en el uso de energía solar per cápita es Israel. Este país está demostrando su progreso instalando más paneles solares por persona que ningún otro.`,
  },

  {
    id: 6,
    title:
      '¿Es relevante la energía solar para el agua caliente y la calefacción?',
    description: `La energía solar permite utilizarla tanto para la calefacción de espacios como para la producción de agua caliente. La energía solar para producir agua caliente es una de las formas más eficientes de utilizar esta fuente de energía. Sin embargo, la calefacción de espacios requiere una superficie mucho mayor de paneles solares que el calentamiento de agua. Es importante señalar que la calefacción solar es ventajosa durante el invierno, cuando se necesita calor adicional. Al mismo tiempo, durante el verano, los grandes paneles solares deben protegerse del sobrecalentamiento. El agua caliente calentada por energía solar puede circular en sistemas de calefacción por suelo radiante o alimentar radiadores para mantener una temperatura interior confortable. Para lograr la máxima eficiencia, el sistema debe diseñarse para funcionar a bajas temperaturas del fluido para maximizar la energía solar disponible.`,
  },

  {
    id: 7,
    title: '¿Cuánto duran los paneles solares?',
    description: `Los paneles solares tienen una vida útil impresionante, comparable a la de otros dispositivos electrónicos. Con un uso y un cuidado adecuados, pueden seguir funcionando durante décadas.
    El coeficiente de degradación de los módulos solares fotovoltaicos, que depende de la calidad y la fabricabilidad de los productos, desempeña un papel clave en la determinación de la vida útil. El escenario óptimo supone que en 20-25 años la instalación solar producirá en torno al 80-85% de su capacidad original. Es decir, seguirá contribuyendo de forma significativa a la generación de electricidad.
    Es importante tener en cuenta que, tras este periodo, los paneles solares no fallan instantáneamente. Pueden seguir funcionando, pero con cierta degradación de su rendimiento. Incluso después de que haya expirado la vida útil garantizada, la instalación solar puede seguir funcionando y proporcionando beneficios durante años adicionales.
    Las garantías de los fabricantes también desempeñan un papel importante. Suelen ofrecer una garantía por daños mecánicos de 5 años para los productos de gama media y de 10-12 años para los de gama alta. Esto significa que el comprador puede estar seguro de la fiabilidad y calidad de los paneles solares durante un determinado periodo de tiempo.`,
  },
];

export const Inmobiliaria = [
  {
    id: 1,
    title: '¿Por qué es importante analizar el suelo y el terreno?',
    description: `
      El terreno y el suelo determinan las propiedades fundamentales de un emplazamiento, como su estabilidad y su capacidad para soportar una estructura.
Los estudios geológicos y geotécnicos realizados por especialistas ayudan a determinar si el suelo es apto para la construcción. Nuestros expertos comprueban la densidad del suelo, sus propiedades portantes y su resistencia a la deformación. Esto es importante para garantizar que su edificio no corre peligro de hundirse, derrumbarse o desplazarse.
Los expertos de Moderna Casa afirman que la topografía y el suelo también influyen en el drenaje y el suministro de agua del solar. Una planificación y ubicación adecuadas de la casa en el solar evitan problemas de agua estancada y drenaje inadecuado de aguas residuales. Así, es posible determinar los lugares óptimos para colocar los sistemas de drenaje y saneamiento.
El terreno y el suelo pueden influir en la disposición y la arquitectura de un edificio. Las irregularidades del terreno pueden requerir excavaciones y aterrazamientos adicionales del solar para crear un terreno llano para la construcción. Y habiéndolo analizado previamente a través de nuestro servicio inmobiliario, podemos indicarle un coste de servicios más exacto.
El conocimiento del suelo permite elegir los mejores métodos de cimentación adaptados a las condiciones específicas del lugar. Por ejemplo, una zona llana puede ofrecer oportunidades para seguir construyendo o crear un espacio ajardinado.

      `,
  },

  {
    id: 2,
    title:
      '¿Cómo realizar un análisis comparativo de varias parcelas para seleccionar la adecuada?',
    description: `
     Elabore una lista de criterios que se utilizarán para comparar las parcelas. Estos criterios pueden incluir la accesibilidad, el valor del terreno, las infraestructuras, los factores medioambientales, el potencial de desarrollo futuro, etc. Determine la importancia de cada criterio para destacar los aspectos más significativos.
Recopilar todos los datos disponibles de cada emplazamiento, incluidos planos, mapas, infraestructuras, evaluación medioambiental y precios y permisos del suelo.
Analice cada sitio utilizando criterios de evaluación predefinidos. Evalúe cada criterio en una escala o sistema de clasificación. Puede utilizar números o clasificaciones para que las comparaciones sean más objetivas.
Teniendo en cuenta la importancia de cada criterio, asígneles coeficientes de significación. Por ejemplo, si la accesibilidad es el criterio más importante, puedes darle más peso que a otros factores.
Analice las puntuaciones de cada sitio y tenga en cuenta la ponderación de los criterios. Compare los resultados y determine qué sección tiene la puntuación global más alta. Éste será el lugar más adecuado para la construcción.
Además de la evaluación comparativa, hay que tener en cuenta el presupuesto del proyecto, las restricciones legales, la normativa local y los permisos. El servicio inmobiliario también implica este tipo de análisis. Nuestros expertos, especializados en el mercado inmobiliario, le ayudarán con el análisis y recopilarán todo el material necesario.

      `,
  },

  {
    id: 3,
    title: '¿Cómo analizamos las infraestructuras?',
    description: `
     Este servicio también incluye el análisis de la infraestructura. Prestamos atención a:
Instituciones educativas para niños, como escuelas y guarderías.
Farmacia, preferiblemente abierta 24 horas al día, 7 días a la semana.
Hospital o sala de urgencias, al menos en las inmediaciones.
Clínica veterinaria, imprescindible para agricultores que quieran una casa en España para desarrollar cultivos y ganadería.
La presencia de una carretera asfaltada que facilita la accesibilidad del lugar.
Distancia al asentamiento más cercano.
La proximidad de estaciones de automóvil y ferrocarril facilita las comunicaciones.
Tiendas de comestibles, mercados y centros comerciales para la comodidad de las compras diarias.
Instalaciones de ocio, parques y plazas de recreo y esparcimiento.
Disponibilidad de estructuras de protección contra posibles inundaciones en caso de crecidas.
El número y tipo de carreteras adyacentes al emplazamiento y la congestión de la ruta en diferentes días y estaciones. Por ejemplo, si el hospital más cercano está a 15 kilómetros del solar y hay que conducir por una carretera sin asfaltar, esto puede ser una desagradable desventaja. El servicio "Inmobiliaria" ayuda a analizar el posible nivel de comodidad que se puede proporcionar al comprador del solar.

      `,
  },

  {
    id: 4,
    title: '¿Por qué es necesario analizar las aguas subterráneas?',
    description: `
     El agua es un peligro potencial para las estructuras de los edificios, especialmente para los cimientos. Las casas construidas en lugares con niveles freáticos altos están expuestas a daños diarios por el agua, a diferencia de las casas con niveles freáticos bajos. Esto puede provocar el hundimiento de los cimientos, grietas en las paredes, inundación de los sótanos, moho y hongos. El impacto en los cimientos no sólo lo causan las propias aguas subterráneas. Las sustancias químicas disueltas en el agua (por ejemplo, sulfatos, sales) también pueden tener graves consecuencias. Pueden provocar el desprendimiento y la disolución del hormigón, que se manifiesta en forma de capa blanca y delaminación de los cimientos. El drenaje de un terreno con un alto nivel de aguas subterráneas ayudará a prevenir todas estas consecuencias negativas. Y el servicio inmobiliario ayudará a evaluar exhaustivamente la situación incluso antes de la cimentación.

      `,
  },

  {
    id: 5,
    title: '¿Por qué es imposible prescindir del análisis del terreno?',
    description: `
    A la hora de elegir un terreno para construir, preste siempre atención al relieve. Los expertos de Moderna Casa afirman que los terrenos accidentados pueden ser bellos en el diseño paisajístico, pero crear dificultades a la hora de construir una casa. Es aconsejable elegir una zona llana, sin curvas ni acantilados. Es preferible un terreno elevado, ya que ofrecerá protección contra las inundaciones y suele tener un precio más elevado.

      `,
  },

  {
    id: 6,
    title: '¿Por qué no prescindir del servicio "Inmobiliario"?',
    description: `
  Siempre hay muchas dificultades en las fases preparatorias, y los escollos más peligrosos suelen surgir al cabo de un tiempo. La tarea de nuestros expertos es ayudarle a reconocer estos riesgos. Si en el futuro decide vender el terreno, debe tener en cuenta los factores que afectan a su valor:
La presencia de edificios de varios pisos en un radio de 150 metros. La altura de los edificios permite a los residentes observar los alrededores, lo que puede causar cierta inquietud.
La proximidad de hangares y granjas. Esto puede provocar ruidos innecesarios y olores desagradables asociados a la cría de animales.
Edificios abandonados. Pueden convertirse en lugares de residencia de personas con escasa responsabilidad social, lo que crea posibles problemas de seguridad y con el entorno del barrio.
La presencia de cementerios. Algunos compradores pueden percibirlos como un elemento indeseable que afecta a la estética y la ecología del entorno.
La proximidad de líneas eléctricas (tendidos eléctricos). Algunas investigaciones las han relacionado con posibles efectos sobre la salud, lo que puede echar para atrás a los compradores.
La presencia de cooperativas de garajes. Pueden afectar al ambiente general y al aspecto visual de la urbanización circundante. Tener en cuenta todos estos factores a la hora de comprar una propiedad reducirá la probabilidad de que surjan problemas durante una venta posterior. Y nuestros expertos le ayudarán a recorrer este camino con usted.

      `,
  },
];

export const Consulting = [
  {
    id: 1,
    title:
      '¿Qué son los servicios de consultoría en el mercado inmobiliario en España?',
    description: `
     Se trata de una oportunidad para consultar a personas competentes y confiar a expertos un análisis de la situación actual del mercado y de otras cuestiones relacionadas directa o indirectamente con el sector inmobiliario en España. Este análisis es una evaluación profesional de la infraestructura de un edificio existente o en proyecto. Su objetivo principal es identificar las vulnerabilidades y el funcionamiento inadecuado de la instalación que pueden conducir a una rentabilidad insuficiente. Los expertos también analizan edificios que sólo están en fase de planificación. Como resultado de este proceso, se identifican las deficiencias que, tras su corrección, harán que el objeto sea más atractivo para residentes, clientes e inquilinos.

      `,
  },

  {
    id: 2,
    title: '¿Qué incluye el asesoramiento integral?',
    description: `
    La consultoría de proyectos incluye varios componentes importantes. En primer lugar, se evalúa el mercado, el público objetivo y el poder adquisitivo de los clientes potenciales para los que está prevista la construcción de la instalación. Esto ayuda a determinar la pertinencia del proyecto y su viabilidad comercial. En segundo lugar, se evalúa la alfabetización estética y urbanística del local y se analiza la demanda de una solución arquitectónica de este tipo entre los clientes potenciales. Esto es importante para crear un espacio atractivo y cómodo que satisfaga las necesidades de los futuros usuarios. El tercer aspecto incluye la comprobación de que la documentación técnica cumple las normas y estándares para garantizar la seguridad y calidad de la construcción. También se investigan las expectativas de la población que vive en las inmediaciones del proyecto para tener en cuenta sus necesidades y ofrecer los servicios y comodidades más adecuados. Un paso importante es comprobar el cumplimiento de las leyes y normativas para que el proyecto cuente con todos los permisos necesarios. Tras recibir nuestros servicios de consultoría, se puede pasar a las fases de diseño y construcción de la casa, que también corren a cargo de nuestros expertos. Por último, se forma el concepto del edificio y se desarrolla un plan de marketing. Esto incluye la identificación de las características y ventajas exclusivas del proyecto, así como estrategias de marketing y promoción para atraer a los clientes interesados y sacar la propiedad al mercado con éxito.

      `,
  },

  {
    id: 3,
    title: '¿Qué incluye el asesoramiento jurídico?',
    description: `
   El sector inmobiliario es un área compleja y regulada con diversas leyes, normas y procedimientos que deben seguirse. El asesoramiento jurídico en el ámbito inmobiliario es de gran importancia para proteger los derechos e intereses de los clientes, así como para garantizar el éxito de las transacciones y operaciones. Si, en su país de origen, es más fácil para un comprador o inversor entender las normas y leyes por sí mismo, entonces los inversores extranjeros en el sector inmobiliario en España deben informarse sobre los escollos de la mano de expertos. Una de las principales razones para buscar asesoramiento jurídico es garantizar la seguridad jurídica. Los abogados analizan documentos y transacciones para asegurarse de que son legales y advierten de posibles riesgos jurídicos. Ayudan a los clientes a evitar problemas y litigios inesperados relacionados con los bienes inmuebles y ofrecen asesoramiento y soluciones para resolver cuestiones legales. El examen legal de los documentos requiere asesoramiento jurídico. Esto incluye la comprobación de contratos de compraventa, arrendamientos, títulos de propiedad y otros documentos legales. La corrección e integridad de la documentación son esenciales para el éxito de una transacción inmobiliaria, y los abogados ayudan a los clientes a evitar posibles errores y problemas asociados a documentos ejecutados incorrectamente. Nuestros expertos explican términos y procedimientos legales complejos, las normas y leyes que rigen la propiedad inmobiliaria, y ayudan a los clientes a comprender sus derechos y obligaciones. Recomiendan las mejores prácticas y estrategias para lograr los resultados deseados y advierten de los riesgos potenciales.

      `,
  },

  {
    id: 4,
    title: '¿Cuáles son las peticiones más comunes que recibimos?',
    description: `
  Consultas orales por horas en las que nuestros expertos responden a sus preguntas y le asesoran sobre diversos aspectos de la propiedad.
Una opinión consultiva sobre inversiones para ayudarle a tomar decisiones informadas sobre inversiones inmobiliarias y evaluar el rendimiento potencial de su inversión.
El estudio de mercado de los mercados inmobiliarios incluye los segmentos residencial, de oficinas, comercial y de ocio, hotelero e industrial y de almacenes. Analizamos la demanda, la oferta y la competencia en estos mercados, ayudándole a comprender el potencial y las perspectivas de desarrollo de diversos segmentos inmobiliarios.
Servicios de planificación conceptual que ayudan a desarrollar conceptos de construcción y desarrollo inmobiliario eficaces e innovadores, teniendo en cuenta factores de mercado, económicos, necesidades de los clientes y requisitos legales.
Análisis expreso de la situación en el formato de opinión profesional, que proporciona una evaluación rápida y objetiva de la situación actual y recomendaciones para nuevas acciones.
Prepare un memorándum de inversión que describa el proyecto, su potencial, riesgos y resultados esperados para atraer a posibles inversores.
Análisis de la documentación urbanística y perspectivas de desarrollo del proyecto, que incluye el estudio de los planes urbanísticos, la coordinación con los requisitos de los organismos estatales y la evaluación de las perspectivas de ejecución del proyecto.

      `,
  },
];

export const SwimmingPools = [
  {
    id: 1,
    title: '¿Qué se puede añadir a la piscina?',
    description: `Instalación de un "flujo de agua artificial". Una gran opción para los amantes de la actividad física. Incluso en piscinas pequeñas, este aparato permite disfrutar al mismo tiempo de la natación deportiva y del hidromasaje. El géiser del fondo ofrece a cada miembro de la familia un masaje de aire que crea una sensación de ingravidez y enriquece el agua con oxígeno. El complemento ayudará a los miembros más jóvenes de la familia a empezar a flotar más rápido y con más confianza. Y los familiares adultos estarán encantados de que después de este masaje desaparezcan el cansancio, el insomnio y el mal humor. Una cascada no es sólo estética y belleza exterior, sino también un eficaz masaje para la cintura escapular. También es el complemento perfecto para crear un ambiente de relajación y disfrute en la piscina. Y la opción de retroiluminación creará una sensación de confort en la oscuridad. Un tobogán acuático es el sueño de todos los niños. Pueden pasarse horas bajando incansablemente por el tobogán, disfrutando de la diversión y el entretenimiento. En el catálogo de nuestra empresa encontrará una amplia gama de accesorios para piscinas, y nuestros experimentados especialistas le ayudarán a elegir la opción perfecta que cumpla sus requisitos y deseos. Sólo ofrecemos las atracciones más funcionales y de mayor calidad. Nuestros expertos saben cómo hacer de su piscina una solución no trivial y funcional.

     `,
  },
  {
    id: 2,
    title: 'Elegir el tipo de piscina: ¿skimmer o desbordante?',
    description: `
      Piscina con skimmer: el agua se filtra a través de skimmers de superficie y se devuelve a través de boquillas de pared.
Piscina desbordante: el agua se vierte en el tanque de almacenamiento, pasa por los sistemas de filtración, calefacción y desinfección, y vuelve por las boquillas del fondo. Elegir el tipo de piscina depende de usted, pero si tiene la capacidad y el presupuesto, la opción preferida es una piscina infinita. Sin embargo, una piscina de skimmer también puede ser de gran calidad si se siguen todos los cálculos y tecnologías necesarios. Estamos dispuestos a ayudarle a instalar cualquier piscina.

      
      `,
  },
  {
    id: 3,
    title:
      '¿Qué oportunidades ofrece la automatización y el control de piscinas?',
    description: `
      Si echa un vistazo más de cerca a nuestros servicios, verá un servicio como "casa inteligente". Está diseñado no solo para su uso dentro de la casa, sino también para elementos externos.
El mecanismo permite automatizar los procesos de filtrado y mantenimiento de la calidad del agua de la piscina. Puedes controlar y ajustar el funcionamiento de los filtros, las bombas, la desinfección y los sistemas de calentamiento del agua mediante una aplicación en tu smartphone o un panel de control.
El sistema "casa inteligente" permite gestionar la temperatura del agua de la piscina de forma inteligente. Puedes fijar la temperatura deseada con el móvil y el sistema la mantendrá automáticamente, incluido el encendido y apagado de los calentadores de agua.
Cada persona tiene la oportunidad de controlar la iluminación de la piscina, incluidas las luces subacuáticas, mediante el sistema "smart home". De este modo se crean diferentes atmósferas y ambientes alrededor de la piscina.
La seguridad y la alarma son otra ventaja. Puede recibir notificaciones sobre el estado de la piscina, como un cambio en el nivel del agua o la detección de movimiento alrededor de la piscina, para evitar un accidente.
Establece un horario para el sistema de la piscina y crea guiones automáticos. Por ejemplo, puede programar el encendido y apagado de los sistemas de filtración y calefacción, y crear escenas que enciendan las luces y la música de fondo cuando se utilice la piscina. Estos son sólo algunos ejemplos de lo que los sistemas domésticos inteligentes pueden hacer para automatizar y gestionar su piscina. Le ayudaremos a obtener el máximo beneficio de la integración del mecanismo de automatización poniéndose en contacto con nosotros.

      `,
  },

  {
    id: 4,
    title:
      '¿Qué materiales son los más adecuados para el acabado de la superficie interior de la piscina?',
    description: `
      Los materiales más adecuados para la decoración interior de piscinas son:
La baldosa cerámica es un material popular y duradero para la decoración de piscinas. Tiene buena resistencia al agua, a los productos químicos y a los rayos UV, y es fácil de limpiar.
La fibra de vidrio se considera un material duradero. Crea una superficie lisa y proporciona una buena protección contra la corrosión y los rayos UV.
La resina epoxi garantiza un revestimiento duradero e impermeable para piscinas. Tiene una gran resistencia química y soporta cargas pesadas.
La membrana de PVC es un material flexible que puede cubrir la superficie interior de la piscina. Son muy resistentes a los productos químicos y duran mucho tiempo.
La pintura de poliuretano se utiliza mucho en la decoración de piscinas. Proporciona una superficie lisa y duradera resistente a los productos químicos y a la radiación UV. Le recomendamos que consulte con nuestros profesionales para seleccionar el material adecuado a sus necesidades y a las condiciones de su piscina.

      `,
  },

  {
    id: 5,
    title: '¿Qué tipo de servicio y mantenimiento requiere la piscina?',
    description: `Los siguientes servicios y mantenimiento son necesarios para garantizar el buen funcionamiento y la calidad de la piscina:
Retirar escombros, hojas y otros restos de la piscina utilizando una red o un aspirador de piscinas.
Comprobar y corregir el nivel de cloro, el equilibrio del pH y otros indicadores químicos del agua. Garantizar la seguridad del agua y evitar la proliferación de bacterias y algas nocivas.
Comprobación y limpieza del filtro de la piscina. El filtro elimina las partículas finas y las impurezas del agua, y limpiarlo con regularidad ayuda a mantener el sistema de filtración funcionando con eficacia.
Mantenimiento del sistema de calentamiento de agua (si el sistema está instalado). Puede incluir la revisión y el mantenimiento de equipos de calefacción como calderas, bombas de calor o colectores solares.
Inspección y mantenimiento de la bomba de la piscina. La bomba hace circular el agua de la piscina y debe mantenerse para filtrar y tratar el agua con eficacia.
Control de todos los sistemas de seguridad, incluyendo la presencia y operatividad de la boya salvavidas, interruptores y dispositivos de señalización. Estos servicios y el mantenimiento son importantes para mantener la piscina segura, limpia y funcionando correctamente. Por lo tanto, estamos dispuestos a ser sus ayudantes de confianza en este camino y proporcionar servicios sistemáticos de calidad.

      `,
  },

  {
    id: 6,
    title:
      '¿Cómo garantizar la seguridad en la piscina, especialmente de los niños?',
    description: `Para nosotros es esencial no sólo venderle una piscina, sino también garantizar una elevada seguridad para cada miembro de la familia. Para ello, hay que tomar las siguientes medidas:
Instale una valla fiable alrededor de la piscina, que impida el acceso no autorizado de los niños al agua.
Si no es posible observar la piscina, utiliza cubiertas protectoras como boyas salvavidas o cubiertas de piscina para evitar que los niños caigan accidentalmente al agua.
Los adultos deben estar siempre cerca de los niños cerca de la piscina. La supervisión regular y atenta de un adulto puede evitar situaciones peligrosas.
Eduque a los niños sobre las normas de seguridad en la piscina. Es importante explicarles que no deben jugar cerca de la piscina sin adultos ni tirarse al agua sin permiso.
Cerca de la piscina debe haber siempre equipos de salvamento, como boyas o balizas salvavidas. En caso de emergencia, pueden servir de ayuda. Escríbanos y le mostraremos cómo hacer que su piscina sea práctica, bonita y pensada, y segura para todos.

      `,
  },

  {
    id: 7,
    title: '¿Puedo elegir yo mismo el equipamiento de la piscina?',
    description: `La autoselección del equipo para la piscina es posible, pero hay que resolver serios problemas. Requiere estudiar muchos libros y documentos, comprender los principios del tratamiento del agua, calcular los diámetros de las tuberías y la velocidad de movimiento del agua, elegir el equipo adecuado y mucho más. No se puede decir que sea una oportunidad para ahorrar dinero, ya que hay que invertir mucho tiempo. Tratar con Moderna Casa, especializada en la mejora del rendimiento de su vivienda y en la venta de equipos para piscinas, le garantiza precios competitivos y productos de calidad.

      `,
  },
];

export const Architect = [
  {
    id: 1,
    title: '¿Cuánto dura un proyecto arquitectónico?',
    description: `
      Las condiciones óptimas para diseñar un edificio residencial individual dependen de la interacción entre el arquitecto y el cliente. Cuanto más eficaz sea la retroalimentación y la aprobación de las soluciones de diseño propuestas, más rápido se completará el diseño. Normalmente, el proceso dura de 1 a 3 meses, pero en el caso de objetos complejos, puede llevar hasta seis meses desarrollar un proyecto completo.
      
      `,
  },
  {
    id: 2,
    title:
      'Proyecto de diseño y proyecto de trabajo: ¿qué características tienen?',
    description: `
     El proyecto de diseño y el proyecto de obra difieren en sus características y funcionalidad. El proyecto de diseño son datos abstractos que dan una idea del aspecto y el concepto de la futura vivienda. Al mismo tiempo, el proyecto de ejecución son los planos detallados y desarrollados y la documentación necesaria para la construcción propiamente dicha. Estos documentos sirven de guía directa y de base para todos los trabajos de construcción.

     
      `,
  },
  {
    id: 3,
    title: '¿Qué proyecto es más barato: el estándar o el individual?',
    description: `
      Los diseños originales y estándar tienen costes diferentes. Un proyecto estándar suele ser más asequible, puesto que ya se ha desarrollado y revendido una vez cubiertos los costes iniciales. Sin embargo, el proyecto estándar tiene un inconveniente importante, ya que se creó teniendo en cuenta la zona específica, los servicios públicos, la orientación del solar y las condiciones climáticas. Una solución estándar sólo puede satisfacer parcialmente sus deseos y requisitos, por lo que las modificaciones pueden acarrear costes adicionales y retrasar la construcción. Por eso, acudiendo a nuestros especialistas, puede estar seguro de que se elaborará un proyecto individual para usted, teniendo en cuenta las recomendaciones.

      
      `,
  },
  {
    id: 4,
    title: '¿Cuáles son las etapas del diseño y la construcción de una casa?',
    description: `La secuencia de construcción de una casa particular suele ser la siguiente: Movimiento de tierras, si existe licencia de obras y proyecto de casa terminada.
Construcción de los cimientos.
Construcción de paredes y techos.
Realización de trabajos de techado.
Instalación de ventanas.
Tendido de comunicaciones internas y tendido de redes externas de ingeniería.
Trabajos de acabado interior, incluidos techos y suelos.
Instalación de puertas y escaleras.
Instalación y conexión de equipos de ingeniería.
Realización de los acabados exteriores. Esta secuencia permite construir una casa por etapas, desde los cimientos hasta el exterior, proporcionando un enfoque sistemático y estructurado de la construcción.

      
      `,
  },

  {
    id: 5,
    title:
      '¿Qué estilo arquitectónico es el más común en los inmuebles en España?',
    description: `El estilo mediterráneo de casas es popular en las provincias del sur de España. Las fachadas suelen estar acabadas con yeso blanco, se utilizan ventanas y puertas arqueadas, y también hay galerías abiertas y terrazas. Los tejados se cubren con tejas marrones o naranjas. Este estilo se adapta bien al clima cálido del sur de España, ya que protege del sol y crea una sensación increíble. A medida que se acerca al norte, la arquitectura de las casas empieza a cambiar. Puede encontrar casas de estilo colonial o con fachada de piedra natural. En el estilo colonial, las paredes suelen estar acabadas con yeso rugoso o materias primas, los suelos son de madera y se instalan persianas de madera en las ventanas. Las casas modernas de alta tecnología son cada vez más populares. El predominio del cristal y el metal en el diseño caracteriza las formas y líneas rectilíneas. Estas casas, sobre todo las que tienen vistas al mar, tienen espacio, visibilidad y mucha luz.

      `,
  },

  {
    id: 6,
    title:
      '¿Qué factores deben tenerse en cuenta al planificar el espacio interior de una casa?',
    description: `
      Determine los principales objetivos y necesidades de su hogar. Organiza las habitaciones de modo que cada zona sea utilizable y exista una secuencia lógica de movimiento entre ellas.
Tenga en cuenta la circulación natural dentro de la casa. Cree recorridos cómodos de una habitación a otra, evitando pasillos estrechos y congestionados.
Aproveche al máximo la luz natural: coloque las ventanas de forma que aprovechen al máximo la luz natural y creen un ambiente interior agradable.
Ten en cuenta la necesidad de intimidad en las distintas zonas de la casa. Coloca los dormitorios y los cuartos de baño en zonas alejadas de la casa, y deja las zonas públicas más abiertas.
La relación entre el tamaño y las proporciones de las habitaciones es esencial para crear una impresión visual armoniosa y agradable. Tenga en cuenta las proporciones correctas a la hora de colocar muebles y accesorios.
Considere la posibilidad de crear espacios multifuncionales que puedan utilizarse para diversos fines. Por ejemplo, un salón-comedor combinado o un estudio, que también pueda utilizarse como dormitorio de invitados.
Proporcione suficiente espacio de almacenamiento. Coloca armarios o armarios empotrados para aprovechar eficientemente el espacio y mantener las cosas organizadas.
Tenga en cuenta su estilo y preferencias a la hora de elegir acabados, combinaciones de colores y decoración. Cree un ambiente que refleje su personalidad y genere una impresión agradable. Los expertos de Moderna Casa pueden ofrecerle consultas y recomendaciones más detalladas sobre el desarrollo de un proyecto arquitectónico. Haremos que su casa sea cómoda para todos los miembros de la familia.

      `,
  },
];

export const SmartHome = [
  {
    id: 1,
    title: 'Hogar inteligente: ¿instalar el sistema o no?',
    description: `
      Una casa inteligente no es sólo una novedad tecnológica, sino un estilo de vida moderno que se ha convertido en la nueva norma en el mundo inmobiliario en España. El hogar inteligente hace que su vida sea cómoda, eficiente y agradable y muestra la vanguardia de la tecnología justo en su casa. El sistema de domótica puede reducir significativamente el consumo de energía en el apartamento. Gracias a la videovigilancia, podrá controlar fácilmente la seguridad de sus hijos y de su hogar, incluso cuando esté fuera de casa. Y qué miradas de sorpresa esperan a tus amigos cuando cierras las persianas con una sola frase, por ejemplo, "Siri, programa de cine", apagas las luces y el reproductor enciende tu serie de televisión favorita: es pura magia. Una tendencia que salva vidas y ayuda a optimizar diversos procesos domésticos.
      `,
  },
  {
    id: 2,
    title: '¿Puedo entender el sistema por mí mismo?',
    description: `
      Desde luego que sí. Porque la "casa inteligente" se ha creado para simplificar la vida de cualquier persona. Nuestros expertos ayudarán al cliente a comprender los principios de funcionamiento de los distintos sistemas en la fase de selección. Tras la prueba de contacto de los equipos en la sala de exposiciones, todas las dudas sobre el posible propietario desaparecen por completo. En una semana, se acostumbrará a su nuevo asistente y no podrá imaginar la vida sin él.

      
      `,
  },
  {
    id: 3,
    title:
      '¿Habrá problemas con las reparaciones si tienes un sistema doméstico inteligente?',
    description: `
      Todo depende del tratamiento y de la fase de reparación. Es aconsejable notificar la disponibilidad del sistema antes de que comiencen las obras de acabado para tener en cuenta la necesidad de cableado. Nuestros expertos le ayudarán a instalar el sistema tanto en la fase inicial de construcción de una casa, como después de instalar el sistema en una casa terminada. Los especialistas tendrán en cuenta las características de la instalación del sistema y evitarán instalar elementos innecesarios en la casa.

      
      `,
  },

  {
    id: 4,
    title:
      '¿Cuáles son las etapas de la instalación de un sistema doméstico inteligente?',
    description: `
      La instalación se lleva a cabo en dos etapas: la primera se completa una vez terminados los trabajos preliminares de construcción, incluido el cableado; la segunda etapa tiene lugar una vez terminados los trabajos e incluye la conexión y configuración del sistema. Por lo general, todo el proceso de instalación dura aproximadamente una semana, pero el tiempo puede variar en función de la complejidad y la cantidad de funciones necesarias.

      
      `,
  },

  {
    id: 5,
    title: '¿Cuánto dura el proceso de instalación de una casa inteligente?',
    description: `
     El proceso de instalación del sistema no durará más de dos semanas, siempre que se cumplan los siguientes requisitos:
La finalización del acabado fino de los locales y la ausencia de otros trabajos que puedan interferir con la instalación.
Suministro de alcantarillado, electricidad y agua en toda regla en la habitación.
Acceso libre a los cuadros eléctricos que permiten desconectar las líneas de alimentación a las que está conectado el equipo.
Disponibilidad de acceso sin obstáculos a todos los puntos de instalación de los componentes del sistema.
Mantener una temperatura estable en la habitación de +18° a +30°. Teniendo en cuenta estas condiciones, los especialistas instalarán el sistema en el plazo especificado, garantizando la eficiencia y la alta calidad del trabajo.

      
      `,
  },

  {
    id: 6,
    title: '¿Qué pasará con el consumo de electricidad?',
    description: `
    Para sorpresa de muchos de nuestros clientes, los sistemas domésticos inteligentes consumen muy poca electricidad, e incluso ayudan a reducir las facturas (aproximadamente un 30%) al alertarles de gastos innecesarios. Por ejemplo, los sensores de movimiento ayudan a evitar el consumo innecesario de electricidad. Pero donde más se ahorra es en la gestión del sistema de climatización. Además del sistema doméstico inteligente, puede encargarnos paneles solares para obtener amplios beneficios, ser respetuoso con el medio ambiente, ahorrar su propio dinero y maximizar el potencial de su hogar.


      
      `,
  },

  {
    id: 7,
    title: '¿Qué le pasa a la casa cuando se apaga Internet?',
    description: `Las casas inteligentes tienen sistemas cableados que funcionan independientemente de la conexión a Internet. Si se pierde la conexión a la red, la "casa inteligente" seguirá funcionando como siempre gracias a la ingeniería suministrada, coordinada con el funcionamiento de los electrodomésticos. Los únicos sistemas que pueden experimentar algunas limitaciones son los inalámbricos. Sin embargo, merece la pena considerar la compra de un sistema de alimentación ininterrumpida para casos de cortes de electricidad, que también es útil para todos los electrodomésticos.

      
      `,
  },

  {
    id: 8,
    title: '¿Se puede controlar manualmente el sistema?',
    description: `Sí, es posible dejar el control del sistema en modo manual, especialmente si un niño pequeño o una persona mayor necesitan garantizar la seguridad. De este modo, se garantiza que la estufa de la cocina no se encenderá por error. Sin embargo, gracias a la interfaz intuitiva, podrá dominar fácilmente las funciones de control a lo largo del día. Puede elegir una forma más sencilla de utilizar el sistema y utilizar la aplicación en su teléfono.

      `,
  },

  {
    id: 9,
    title: 'Hogar inteligente: ¿es un sistema sólo apto para viviendas?',
    description: `
      Esta innovadora tecnología es ideal para edificios residenciales, oficinas, edificios corporativos y centros de negocios. El sistema puede ofrecer muchas ventajas para su negocio, desde un sistema de seguridad fiable hasta el control a distancia de la iluminación, la calefacción, las cortinas y las persianas. Lo mejor es planificar e instalar este tipo de sistemas antes de que empiecen las reparaciones o lo antes posible. No obstante, tanto si se trata de una nueva reforma como de un local ya existente, los experimentados especialistas de Moderna Casa, versados en la instalación de tecnologías inteligentes, harán todo lo posible por minimizar cualquier consecuencia negativa del edificio y ofrecerle la mejor solución teniendo en cuenta las particularidades de su situación.

      `,
  },
];

export const Interior = [
  {
    id: 1,
    title: '¿Por qué no prescindir de nuestros servicios de interior?',
    description: `
      Un proyecto de diseño de interiores es una herramienta clave que le ayuda a crear un espacio armonioso y funcional que se adapte a su estilo de vida. Estas son algunas de las razones por las que un proyecto de diseño es esencial para el éxito de una renovación y organización de interiores.
El proyecto de diseño ayuda a aprovechar al máximo el espacio disponible. Ofrece una colocación inteligente de muebles, accesorios y áreas funcionales para crear un espacio cómodo y práctico que satisfaga sus necesidades.
Es importante que el interior refleje su estilo de vida y satisfaga sus necesidades. Los diseñadores tienen en cuenta sus preferencias, aficiones y rutinas diarias para crear un interior funcional y agradable para usted y su familia.
El proyecto de diseño te permite evaluar por adelantado todos los elementos del interior e imaginar cómo quedarán en la realidad. Con la ayuda de visualizaciones y planos, puedes ver varias opciones de colores, materiales, muebles y decoración y tomar decisiones con conocimiento de causa.
Elaborar un proyecto de diseño te permite determinar el coste de la obra y planificar un presupuesto de reparación con mayor precisión. Podrás ver de antemano todos los costes asociados a materiales, mobiliario, acabados y servicios de diseño y controlar los costes durante el proyecto.
El proyecto de diseño ayuda a evitar errores durante las reparaciones y permite a los profesionales tener en cuenta todos los aspectos técnicos y detalles. Esto minimiza la posibilidad de problemas inesperados y malas decisiones, y reduce el tiempo y el coste de la reparación de errores. Y la cooperación con Moderna Casa hará que todos los procesos sean más cómodos y agradables.

      `,
  },
  {
    id: 2,
    title: '¿Es caro el diseño de interiores?',
    description: `
      Por supuesto, a la hora de desarrollar un proyecto de diseño, tenemos en cuenta su presupuesto. Entendemos que cada cliente tiene sus propias capacidades y limitaciones financieras, por lo que nuestro objetivo es crear un interior que refleje su gusto y estilo de vida y se ajuste a sus capacidades financieras. Nos esforzamos por equilibrar estilo, funcionalidad y asequibilidad para crear un interior que no sólo sea estéticamente agradable, sino también económicamente viable. Nuestro equipo tiene experiencia trabajando con distintos presupuestos, por lo que estamos preparados para ofrecer opciones que satisfagan cualquier expectativa financiera. Mientras trabajamos en el interior y la visualización, también podemos facilitarle información sobre el coste aproximado de los materiales, el mobiliario y la obra necesarios para que se haga una idea de los posibles costes.

      
      `,
  },
  {
    id: 3,
    title: '¿Qué es el servicio de control de la fase de diseño?',
    description: `
     
     La supervisión arquitectónica es una etapa esencial en la ejecución de un proyecto de diseño, que garantiza la conformidad de las obras de reparación y construcción con el proyecto aprobado. Como parte de la supervisión del autor, el diseñador y el arquitecto controlan activamente la ejecución de todos los trabajos y garantizan que cada paso se corresponda con la idea del proyecto. Esto incluye: Supervisar constantemente el proceso de construcción. La comprobación de la calidad de los materiales utilizados. La precisión de la ejecución de las soluciones arquitectónicas. Los expertos de Moderna Casa también responden puntualmente a cualquier pregunta o problema que surja y asesoran al cliente en cuestiones relacionadas con el diseño y la arquitectura.

     
     `,
  },

  {
    id: 4,
    title:
      '¿Cuáles son los 3 componentes principales del proceso de diseño de interiores?',
    description: `
     El proyecto de diseño incluye varios componentes importantes que ayudan a crear un concepto completo y detallado.
Uno de los elementos clave es la visualización en 3D, que te permite visualizar cada detalle de tu espacio. Gracias a esta técnica, podemos elegir los mejores materiales y colores y evaluar la funcionalidad de la colocación de muebles y otros elementos, teniendo en cuenta sus deseos.
La documentación técnica incluye numerosos planos y diagramas para la correcta ejecución del proyecto. Se trata de un plano de medidas, un plano de disposición del mobiliario y equipamiento, un plano de instalación y desmontaje, un plano de planta indicando el tipo de pavimento, un plano de techo, un plano de ubicación de enchufes e interruptores, un plano de conexión de instalaciones con un esquema de conmutación, un programa de acabado de paredes, un método de conexión de aparatos sanitarios, barridos de paredes en baños y cocina, indicando la disposición de los azulejos, así como planos de calefacción por suelo radiante y radiadores. A la hora de aplicar este apartado, también tenemos en cuenta el marco legislativo vigente en España.
Un aspecto esencial del proyecto de diseño es la selección de materiales. Todos los productos presentados en nuestro sitio web pueden presumir de alta calidad y durabilidad. Proporcionamos recomendaciones e indicamos materiales específicos de acabado, mobiliario y accesorios de fontanería e iluminación. Además, le invitamos a utilizar nuestra tienda online, donde podrá adquirir estos artículos y materiales. Todos estos elementos del proyecto de diseño trabajan juntos para crear un concepto de interior completo y detallado, desde visualizaciones en 3D hasta documentación técnica y selección de materiales, para hacer realidad sus deseos y crear el espacio perfecto que refleje su estilo y sus preferencias.

     
     `,
  },

  {
    id: 5,
    title: 'Interior en estilo español: ¿cómo es?',
    description: `Muebles de diseño sencillo en ébano o caoba, sillones y sillas de cuero. El estilo español en el interior se distingue por los colores claros y cálidos y el uso de materiales naturales y elementos de piedra, madera y cerámica. Los cojines decorativos y las velas crean un ambiente acogedor. Los elementos incluyen muebles de época, antigüedades, fotos familiares y pinturas murales. No hay papel pintado ni alfombras, sino paredes pintadas, baldosas y suelos de madera. Son populares las vigas de madera en el techo y los armarios en nichos. Hay una gran mesa de comedor para las cenas familiares. La decoración incluye cuadros con marcos de madera, platos de cerámica, figuras, jarrones y velas. Se utilizan azulejos de cerámica pintados a mano. Puede consultarnos y le contaremos más cosas sobre las características del interior de los países mediterráneos. También creamos proyectos de diseño en otros estilos y escuchamos sus consejos y preferencias.

     
     `,
  },

  {
    id: 6,
    title: '¿Cuánto dura el proceso de desarrollo de un concepto de interior?',
    description: `Desarrollar un proyecto de diseño para una casa particular en España lleva entre 25 y 45 días y se realiza en paralelo a la construcción. El diseñador proyectista realiza no sólo el desarrollo del concepto interior, sino que también resuelve cuestiones técnicas y de ingeniería. Durante este tiempo, el especialista realiza las siguientes tareas:
Preparación de planos dimensionales y diagramas de sistemas de ingeniería.
Creación de una visualización interior en un ordenador y realización de collages.
Desarrollo de esquemas de diseño para el techo, las paredes y el suelo.
Disposición de la ubicación de puertas, enchufes y lámparas.
Organización de calefacción por suelo radiante y aire acondicionado.
Buscar y comprar los materiales especificados en el proyecto de diseño de la casa.
Registro de documentación técnica y jurídica.
Coordinación de la construcción, control de plazos y calidad del trabajo.

     `,
  },
];

export const Construction = [
  {
    id: 1,
    title: '¿Qué tipos de casas particulares nos encargan con más frecuencia?',
    description: `En España, las viviendas particulares llaman la atención por sus características, que difieren de los objetos típicos de esta categoría en otros países de la UE. Los siguientes tipos de casas son populares aquí:
1. Las villas son casas espaciosas de más de 100 m², con amplias parcelas, piscinas y zonas de relax.
2. Las mansiones son casas compactas de hasta 110 m² con pequeñas zonas circundantes.
En España se suele optar por el estilo mediterráneo tradicional o el estilo de alta tecnología a la hora de diseñar casas particulares. Estos estilos presentan elementos de la arquitectura española, como ventanas arqueadas, bóvedas, mosaicos, azulejos de colores, etc.`,
  },
  {
    id: 2,
    title: '¿Hay restricciones de construcción?',
    description: `Cuando se construyen viviendas privadas en España, se tienen en cuenta dos parámetros principales: la altura máxima permitida del edificio y sus dimensiones. Aunque la legislación define las fases de construcción, las normas se revisan a menudo. Un terreno destinado a la construcción debe tener un documento especial - Ficha Urbanística, que contiene las principales características permisibles de los objetos. Cada región tiene también su propia normativa local que regula aspectos como:
1. Altura máxima de los edificios privados;
2. Diseño de las fachadas;
3. Estilo del tejado y otras características.
Estas normas se fijan en el permiso de construcción, sin el cual cualquier construcción es ilegal.
`,
  },
  {
    id: 3,
    title:
      '¿Qué factores deben tenerse en cuenta al planificar el espacio interior de una casa?',
    description: `Para planificar el espacio a la perfección, es importante analizar todas las características potenciales de la futura vivienda. Nuestros expertos han preparado una lista de recomendaciones para usted:
1. Los locales de la casa deben dividirse en áreas funcionales (residencial, utilitaria, pública, privada).
2. La disposición racional de las zonas y las conexiones convenientes ayudarán a aumentar la comodidad y reducir el uso de pasillos.
3. Las zonas de ocio y de trabajo deben estar separadas entre sí.
4. Las zonas auxiliares deben estar ocultas pero accesibles en cualquier momento.
5. Las zonas privadas, como los dormitorios, se recomienda que estén aisladas y ocultas del espacio público en rincones alejados.
6. Tenga en cuenta la composición de la familia, los grupos de edad y el estilo de vida a la hora de planificar su vivienda.
7. Evite cruzar zonas sucias y limpias para prevenir la propagación de la suciedad. Por ejemplo, un garaje debe tener una salida independiente.
8. Combine estancias de funcionalidad similar y zonifíquelas con ayuda de elementos interiores.
9. Se recomiendan dos entradas a la casa: principal y adicional.
10. Tenga en cuenta las comunicaciones futuras a la hora de desarrollar un proyecto. La distribución tiene realmente muchos detalles, y sólo especialistas experimentados podrán abordar de forma competente la zonificación de la futura vivienda. Recurriendo a nosotros, puede estar seguro de que tendremos en cuenta sus detalles y haremos que cada metro cuadrado de su espacio sea funcional, pensado, seguro y ergonómico.`,
  },
  {
    id: 4,
    title: '¿Cuáles son las categorías de suelo?',
    description: `Cuando haya seleccionado un terreno que se adapte a su ubicación, tamaño y preferencias paisajísticas, es recomendable que se ponga en contacto con un arquitecto profesional y solicite un informe sobre la categoría y las características del terreno seleccionado.
Hay que distinguir tres tipos de terrenos:
1. Suelo Urbano: Estos terrenos se encuentran dentro de los límites de la ciudad y son aptos para un rápido desarrollo residencial. Ya existen carreteras y las comunicaciones necesarias (electricidad, gas, agua).
2. Suelo urbanizable: la construcción en estos terrenos sólo es posible tras la preparación preliminar y la aprobación de un plan detallado por parte del ayuntamiento. A menudo, en estas zonas existen requisitos especiales para la protección del medio ambiente.
3. Suelo no urbanizable: se encuentra en una zona protegida donde está prohibida la construcción de viviendas, y sólo se concede licencia para ello en casos excepcionales.
Al realizar un análisis, el arquitecto debe describir claramente todas las restricciones que se aplican a un emplazamiento concreto. Por ejemplo, puede haber requisitos estrictos de distancia entre casas vecinas, la prohibición de talar algunos árboles viejos y dificultades para conectarse a la electricidad, el gas y el agua corriente.
`,
  },
  {
    id: 5,
    title: '¿Cuánto dura el proceso de construcción?',
    description: `Según la experiencia de nuestros expertos, la construcción de una casa en España suele durar aproximadamente 18 meses y pasa por varias fases clave. Al principio del proceso, es necesario seleccionar y comprar una parcela que cumpla los requisitos para la construcción. A continuación, se elabora el proyecto principal y se preparan presupuestos detallados para la ejecución de las obras. La obtención de los permisos necesarios de las autoridades locales y el pago de impuestos en el municipio son también pasos importantes. La firma de un contrato con una empresa contratista y la construcción directa incluyen las comunicaciones, la construcción de los cimientos y la decoración interior. Al final del proceso, se lleva a cabo una inspección final por parte del ayuntamiento para obtener el permiso de funcionamiento, el registro de los documentos de propiedad y la conexión de electricidad, agua y alumbrado en la nueva vivienda. Recurriendo a nosotros, le ayudaremos a que el proceso sea lo más cómodo y rápido posible.
`,
  },

  {
    id: 6,
    title: '¿Qué documentos se necesitan para iniciar la construcción?',
    description: `Cuando el ayuntamiento apruebe la construcción de su vivienda particular, su arquitecto podrá empezar a elaborar un plan detallado y estimar el coste de los materiales y la obra (PEM - El Presupuesto de Ejecución Material).
A continuación, tendrá que encontrar una empresa constructora de confianza con la que celebrar un contrato, para lo que necesitará los siguientes documentos:
1. Licencia de obra mayor del Ayuntamiento.
2. Documentos que acrediten la propiedad del terreno.
3. Comprobación de la viabilidad técnica de la conexión a las comunicaciones centralizadas.
4. Diseño detallado del edificio.
5. Fotocopias del plan general de ordenación de la zona.
6. Un acta en la que se indiquen los límites del solar y los edificios existentes.
Tras recibir los presupuestos y permisos oficiales, puede plantearse obtener financiación de un banco. Sin embargo, hay que recordar que la obtención de un gran préstamo que cubra todos los costes de construcción sólo está al alcance de los particulares en algunas ocasiones. Basándose en su experiencia en la construcción de una casa en España, muchas personas advierten de que los préstamos para este tipo de proyectos a particulares pueden concederse con ciertas restricciones.
`,
  },
];

export const Investments = [
  {
    id: 1,
    title: '¿Merece la pena invertir en una segunda vivienda?',
    description: `
      Los datos de precios de la segunda vivienda en España para enero de 2023, proporcionados por el índice de precios inmobiliarios Idealista, nos permiten analizar la situación más de cerca. De media, los precios de la vivienda secundaria subieron un 5,4%, alcanzando el nivel de 1.922 euros por metro cuadrado. A pesar de ello, el crecimiento del precio de la vivienda a escala nacional se mantuvo en enero por debajo de la tasa de inflación, que fue del 5,8%. Si nos fijamos en las capitales de España, vemos que en 16 ciudades la subida de los precios de la segunda vivienda ha superado la tasa de inflación. Los crecimientos más significativos se registraron en Santa Cruz de Tenerife (+18,7%), Alicante (+13,2%), Valencia (+11,4%), Guadalajara (+11,3%), Cuenca (+10,8%), Palma (+9,9%), Sevilla (+8.1%), Málaga (+8%), Segovia (+9,9%), Pontevedra (+8,3%), Badajoz (+7,3%), Huelva (+7,2%), La Coruña (+6,8%), Tarragona (+6,6%), Granada (+6,3%) y Pamplona (+5,8%). Además, otras 30 capitales experimentaron subidas del precio de la vivienda, pero se mantuvieron por debajo de la tasa de inflación. Entre estas ciudades, cabe destacar los grandes mercados de Madrid (+4,9%) y Barcelona (+3%). Enero de 2023 trajo cambios en los precios de las segundas residencias en España; hay un aumento de los precios. Por lo tanto, decidir comprar una propiedad de segunda mano hoy y revenderla más tarde puede proporcionar un excelente colchón financiero para el inversor.
      `,
  },

  {
    id: 2,
    title: '¿Qué ventajas adicionales obtiene un inversor en España?',
    description: `
     España ofrece uno de los programas más atractivos para obtener un permiso de residencia (Permiso de Residencia) y, posteriormente, la nacionalidad. Para acogerse al programa, es necesario adquirir una propiedad por valor de al menos 500.000 euros y, tras vivir en el país durante 10 años, podrá solicitar la ciudadanía de la UE con todos los beneficios asociados. España es un destino atractivo para la inversión inmobiliaria, especialmente para países con economías inestables. Este país ocupa el cuarto lugar en términos económicos en la Eurozona y el duodécimo en la economía mundial. Invertir aquí en bienes inmuebles es rentable y fiable. Diversos expertos estiman que la rentabilidad de estas inversiones oscila entre el 5 y el 15% anual. Especialmente atractivos para los inversores son los apartamentos o casas adosadas en centros turísticos populares. Esto se debe a la enorme popularidad de España entre los turistas de todo el mundo. Aquí se han sincronizado con éxito un clima excelente, una hermosa costa mediterránea, un alto desarrollo económico y social y un rico patrimonio cultural. Todos estos factores hacen que España sea muy atractiva para la inversión inmobiliaria.

      `,
  },
  {
    id: 3,
    title: '¿Por qué elegirnos para invertir?',
    description: `
    Muchas personas deciden invertir de forma independiente, pero necesitan más conocimientos para hacerlo con seguridad y competencia. Por ello, es indispensable contar con un asistente de confianza. Nos especializamos en desarrollar e implementar soluciones óptimas para inversores en España. Nuestro objetivo es ayudarle a obtener altos ingresos pasivos. Analizamos las perspectivas económicas de la inversión inmobiliaria en España y ayudamos a nuestros clientes a invertir su dinero de forma rentable. Nos esforzamos por crear una cartera de inversión con una alta precisión estratégica, teniendo en cuenta todos los riesgos potenciales para el inversor. Proporcionamos una gama completa de servicios para garantizar unos altos ingresos en el mercado inmobiliario en España. Más de la mitad de nuestros clientes nos confían la gestión y administración compleja tras la finalización de la transacción. Y supervisamos regularmente las innovaciones legislativas para garantizar la seguridad de sus fondos y el cumplimiento de las leyes.
      `,
  },

  {
    id: 4,
    title: '¿Cuáles son las características de los inmuebles más rentables?',
    description: `
   A la hora de elegir una propiedad, hay criterios clave que deben tenerse en cuenta. Uno de estos criterios son las hermosas vistas panorámicas del mar o la naturaleza desde la ventana, que no deben subestimarse. Un estudio de TripAdvisor reveló que para el 82% de los viajeros, una vista inspiradora desde su ventana es la parte más importante de su viaje. Por lo tanto, atraer visitantes y compradores de viviendas vacacionales requiere un esfuerzo importante. Además, los apartamentos urbanos españoles en otras zonas se benefician de servicios y de una excelente infraestructura de transporte en las cercanías. Considere otros criterios a la hora de elegir una segunda vivienda como inversión inmobiliaria. Tanto si alquila una propiedad como si planea venderla, es esencial contar con las características y comodidades adecuadas. Añadir elementos esenciales modernos, como Wi-Fi rápido, televisores de alta definición y baños y espacios comunes limpios y elegantes, puede aumentar enormemente el atractivo de las casas de vacaciones. En climas cálidos, las terrazas con comedor, la iluminación ambiental o las habitaciones adicionales suelen añadir valor. Además, es importante calcular con precisión el coste de mantenimiento de una casa para garantizar la rentabilidad de la inversión. Esto incluye contabilizar los gastos de limpieza, mantenimiento, jardinería y reparaciones.

      `,
  },

  {
    id: 5,
    title: 'Inversiones y Golden Visa: ¿qué perspectivas abren?',
    description: `El Golden Visa es un visado que permite a los inversores de fuera de la Unión Europea y a sus familiares convertirse en residentes en España. Sus familiares directos, incluidos cónyuges e hijos menores de edad, obtendrán la residencia en España si usted obtiene un Golden Visa. Esto también se aplica a los hijos adultos dependientes. Obtener un Golden Visa en España le da la oportunidad de vivir en el país y acceder a muchos beneficios de los que sólo pueden disfrutar los residentes en España. ¿Quién puede solicitar un Golden Visa en España? Puede solicitar este visado cualquier persona que compre una vivienda por un valor superior a 500.000 euros, siempre que el solicitante aporte al menos esta cantidad con fondos propios, haya invertido en bonos del Estado o tenga acciones o depósitos bancarios por un valor superior a 1.000.000 de euros. También pueden solicitar este visado las personas que lleven a cabo proyectos empresariales en España que sean de interés público. Esto se aplica a todos los proyectos que creen puestos de trabajo y tengan un impacto socioeconómico en el área geográfica donde se ejecutan, así como a todos los proyectos que contribuyan a la innovación científica o tecnológica en el país. ¿Durante cuánto tiempo es válido el Golden Visa en España? Con este visado, puede residir en España durante al menos un año. Tras este periodo, puede solicitar un permiso de residencia válido durante 2 años. Sujeto a la inversión y a otros requisitos, el periodo máximo de validez de un permiso de residencia es de 5 años.

      `,
  },
];

export const ModularHouses = [
  {
    id: 1,
    title: '¿Cuánto se tarda en montar una casa modular?',
    description: `
      La construcción rápida y cómoda de las casas modulares atrae a mucha gente. Estas casas se montan a partir de grandes piezas de paredes y techos, que se entregan en la obra. El montaje dura entre dos semanas y un mes y medio, tras lo cual comienza el acabado y la instalación de los equipos de ingeniería. Los módulos prefabricados que contienen media habitación o varias habitaciones aisladas se entregan en plataformas de carga especiales y se instalan sobre unos cimientos ya preparados. La instalación exprés dura entre dos y diez días, tras los cuales la casa está lista para ser ocupada. Las casas modulares son ideales para quienes quieren estar abiertos a más de un lugar y prefieren desplazarse. Por ejemplo, si tiene que viajar con frecuencia por motivos de trabajo, puede mudarse sin preocuparse de encontrar un nuevo hogar. Su casa siempre estará ahí, como una concha de caracol. El montaje de una casa modular suele empezar colocando el primer módulo sobre los cimientos y asegurándolo. A continuación se deja caer el siguiente módulo y se fija al anterior, y así sucesivamente. Tras el montaje, se sellan las juntas y se procede a la instalación del tejado.

      `,
  },

  {
    id: 2,
    title: '¿Cuál es la resistencia y durabilidad de las casas modulares?',
    description: `La durabilidad de las casas modulares depende de varios factores, como la tecnología, los materiales y el control de calidad utilizados. Al elegirnos, puede estar seguro de que solo utilizamos materiales de alta calidad y trabajamos con integridad. Las estructuras principales de una casa pueden durar más de 50 años, una cantidad de tiempo impresionante. La vida útil del aislamiento es un poco más difícil de determinar, ya que no existe una práctica a largo plazo de uso de materiales modernos de aislamiento térmico. Sin embargo, siempre que no haya humedades, cabe suponer que el aislamiento durará unos 20-25 años. Estas estadísticas se pueden seguir en los territorios de España, donde no hay mucha humedad ni lluvia. Es importante tener en cuenta que el desgaste físico rara vez provoca que una casa quede inutilizable. Por el contrario, una casa de este tipo puede quedar moralmente obsoleta, y los propietarios pueden decidir venderla o reconstruirla para adaptarla a las necesidades y gustos modernos. Por lo tanto, puede ponerse en contacto con nosotros en cualquier momento para renovar sus metros cuadrados.
      `,
  },

  {
    id: 3,
    title: '¿Qué popularidad tienen las casas modulares en España?',
    description: `
    Las casas modulares han ganado popularidad en Europa en los últimos años, y su atractivo no sólo se debe a la reducción de costes, sino también a la oportunidad de ofrecer una solución para superar la crisis económica provocada por la pandemia del Covid-19. España es el país europeo con mayor uso de casas modulares. Sin embargo, la historia de las casas modulares se remonta a la posguerra. Fue entonces cuando apareció este tipo de vivienda, que se construía de forma rápida y barata para satisfacer la enorme demanda de espacio habitable. El término "modular" significa que la casa se construye a partir de módulos individuales. Cada módulo se crea de forma independiente. La mayor parte del trabajo se realiza en la fábrica, donde los módulos individuales se fabrican con diversos materiales. A continuación, estos módulos se entregan en el terreno del comprador y finalmente se ensamblan para crear una casa in situ. Esta tecnología es muy popular en España, ya que el clima benigno y las características del suelo, las condiciones meteorológicas y otros factores permiten despreocuparse de la necesidad de cimientos y fortificaciones adicionales.
      `,
  },
  {
    id: 4,
    title: '¿Qué mitos sobre las casas modulares hay que desmentir?',
    description: `"Las casas modulares parecen de fórmula". Cuando se construye una casa nueva desde cero, es posible diseñarla por completo, teniendo en cuenta todos los deseos del futuro propietario. Sin embargo, en el caso de las casas modulares, la respuesta no está tan clara. La complejidad de la ejecución de la idea, la calidad de los acabados y la superficie afectarán al coste de la vivienda. Aunque el acabado según los deseos del cliente es posible, las empresas suelen ofrecer modelos ya hechos. Si se desvía del diseño clásico, el precio puede aumentar, y el tiempo de creación también.
"Es imposible ampliar la casa". Tanto las casas modulares como las tradicionales pueden ampliarse si la estructura, las leyes y el emplazamiento lo permiten. Muchas casas modulares están diseñadas de tal forma que se pueden añadir y ampliar módulos. Esto facilita añadir espacio extra. Ampliar una casa convencional es un proceso más complejo y largo.
"Las casas modulares no tienen que ver con la resistencia y fiabilidad". Con todos los requisitos y un mantenimiento regular, las casas modulares pueden durar de 50 a 70 años, igual que las casas construidas tradicionalmente.
"Una simple caja sin rasgos distintivos". Antes, las casas modulares se consideraban demasiado simples en apariencia. Hoy, sin embargo, representan un ejemplo de estilo moderno. Las casas tradicionales pueden tener cualquier aspecto, dependiendo del gusto y las preferencias estilísticas de sus propietarios.
 `,
  },

  {
    id: 5,
    title:
      '¿Cuáles son las principales ventajas de las casas modulares en España?',
    description: `
     Una de las grandes ventajas de las casas modulares es la ausencia de tierra de obra. La mayor parte del trabajo se realiza fuera de la obra. Cuando los módulos se entregan en la obra, simplemente se ensamblan entre sí, y esto no implica una gran cantidad de escombros o suciedad. Esto significa que usted no tiene que preocuparse por los residuos de construcción dispersos y la limpieza posterior a la construcción. En segundo lugar, las casas modulares suelen atraer por su respeto al medio ambiente y su eficiencia energética. Esto se debe a la optimización de la producción y el uso de materiales, teniendo en cuenta factores medioambientales. Gracias a ello, las casas modulares pueden ser más eficientes desde el punto de vista energético y más respetuosas con el medio ambiente. Además, este enfoque de construcción reduce los residuos y utiliza los recursos de forma más eficiente. Las casas modulares ofrecen una gran variedad y calidad de los materiales utilizados. Por ejemplo, el metal y el hormigón son los materiales más populares para la construcción de casas modulares en España. Las casas modulares en España se consideran estructuras temporales que no afectan a la geodesia del lugar. Otra ventaja de las casas modulares es la mínima burocracia durante su construcción. Para construir una casa de este tipo no es necesario obtener permisos ni licencias, como ocurre con las casas tradicionales. Esto significa que puede construir una casa modular en casi cualquier solar, aunque no tenga permiso de construcción. Esto le da más flexibilidad y simplifica la elección del emplazamiento de su futura casa.


     `,
  },
];

// export const Projects = [
//   {
//     id: 1,
//     title: '¿Qué tipos de servicios ofrecemos?',
//     description:
//       'Ofrecemos una amplia gama de servicios de construcción e inmuebles, incluyendo pero no limitados a: construcción, diseño de interiores, viviendas modulares, piscinas, energía solar, hogares inteligentes, Inmobiliaria, inversiones y consultoría.',
//   },
// ];
