export const ourValuesTitles = {
  title: 'Els nostres Valors',
  quality: 'Qualitat',
};

export const ourValuesList = [
  {
    id: 1,
    title: 'Eficàcia',
    text: 'Valorem el temps dels nostres clients, per això invertim en terminis i no els fem esperar.',
  },
  {
    id: 2,
    title: 'Qualitat',
    text: 'Sempre donem prioritat a la qualitat i fem tot el possible per garantir que els nostres serveis compleixin les normes més estrictes.',
  },
  {
    id: 3,
    title: 'Sempre a prop',
    text: 'Realitzem consultes abans, durant i després de prestar el servei, responem a les preguntes dels clients i oferim recomanacions.',
  },
  {
    id: 4,
    title: 'Innovació i desenvolupament',
    text: 'Sempre ens esforcem per buscar noves tecnologies per garantir els millors resultats als nostres clients.',
  },
  {
    id: 5,
    title: 'Responsabilitat mediambientall',
    text: 'Utilitzem materials respectuosos amb el medi ambient en la construcció, cuidem la natura.',
  },
  {
    id: 6,
    title: 'Transparència i franquesa ',
    text: `L'honestedat és la principal divisa, per la qual cosa ens adherim a la franquesa en totes les relacions amb els nostres clients.`,
  },
  {
    id: 7,
    title: 'Socis fiables',
    text: 'Cooperem amb empreses provades i fiables que ens subministren matèries primeres i equips.',
  },
  {
    id: 8,
    title: 'Enfocament individual',
    text: 'Fem tot el possible per satisfer al 100% les expectatives del client.',
  },
];
