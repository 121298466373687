export const CardsSection = {
  basic: {
    type: 'Basic',
    homePackage: 'Home guardian system',
    description: `This setup includes a smart thermostat, smart lighting, and a smart security system. It allows for remote control of your home's temperature, lighting, and security from your smartphone.`,
  },
  standart: {
    type: 'Standart',
    homePackage: 'Eco-Smart Solution',
    description: `This setup includes all features of the Basic Setup, plus smart locks, a smart irrigation system, and voice control integration. It allows for seamless automation and control of your home's various systems.`,
  },
  premium: {
    type: 'Premium',
    homePackage: 'Intelligent Oasis Setup',
    description: `Upgrade to our AI-powered setup for enhanced energy management, entertainment, and home automation. Perfect for larger households or commercial properties looking for the ultimate in convenience and efficiency.`,
  },
};
