import React, { FC, useState } from 'react';
import {
  AccessoriesSectionWrapper,
  AccessoriesBlock,
  AccessoriesHeader,
  AccessoriesIcons,
  AccessoriesIconBlock,
  AccessoriesIconTitle,
  AccessoriesTitle,
  AccessoriesDescription,
  AccessoriesIcon,
  ShowMoreButton,
} from './AccessoriesSection.styled';
import { useTranslation } from 'react-i18next';
import { getAccessoriesIcons } from '../SmartHomePage.constatnts';
import { AccesoriesIconProps } from '../SmartHomePage.types';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';

export const AccessoriesSection: FC = () => {
  const { t } = useTranslation();
  const [allIconsIsShow, setAllIconsIsShow] = useState(false);
  const accessoriesIcons = getAccessoriesIcons()
  const isTablet = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  return (
    <AccessoriesSectionWrapper id="accessories-section">
      <AccessoriesBlock>
        {!isTablet && (
          <>
            <AccessoriesHeader>
              <AccessoriesTitle>
                {t('smartHome.AccessoriesSection.title')}
              </AccessoriesTitle>
              <AccessoriesDescription>
                {t('smartHome.AccessoriesSection.description')}
              </AccessoriesDescription>
            </AccessoriesHeader>
            <AccessoriesIcons>
              {accessoriesIcons.map((accessoriesIcon: AccesoriesIconProps) => (
                <AccessoriesIconBlock key={accessoriesIcon.title}>
                  <AccessoriesIcon>{accessoriesIcon.icon}</AccessoriesIcon>
                  <AccessoriesIconTitle>
                    {accessoriesIcon.title}
                  </AccessoriesIconTitle>
                </AccessoriesIconBlock>
              ))}
            </AccessoriesIcons>
          </>
        )}
        <AccessoriesIcons>
          {isTablet &&
            (allIconsIsShow ? (
              <>
                {accessoriesIcons.map(
                  (accessoriesIcon: AccesoriesIconProps) => (
                    <AccessoriesIconBlock>
                      <AccessoriesIcon>{accessoriesIcon.icon}</AccessoriesIcon>
                      <AccessoriesIconTitle>
                        {accessoriesIcon.title}
                      </AccessoriesIconTitle>
                    </AccessoriesIconBlock>
                  ),
                )}
                <ShowMoreButton onClick={() => setAllIconsIsShow(false)}>
                  Hide
                </ShowMoreButton>
              </>
            ) : (
              <>
                {accessoriesIcons
                  .slice(0, 7)
                  .map((accessoriesIcon: AccesoriesIconProps) => (
                    <AccessoriesIconBlock>
                      <AccessoriesIcon>{accessoriesIcon.icon}</AccessoriesIcon>
                      <AccessoriesIconTitle>
                        {accessoriesIcon.title}
                      </AccessoriesIconTitle>
                    </AccessoriesIconBlock>
                  ))}
                <ShowMoreButton onClick={() => setAllIconsIsShow(true)}>
                  Show more
                </ShowMoreButton>
              </>
            ))}
        </AccessoriesIcons>
      </AccessoriesBlock>
    </AccessoriesSectionWrapper>
  );
};
