 export const philosophyTextsList = [
  {
    id: 1,
    label: 'Risk management',
    description:
      'We take a disciplined approach to risk management, with a focus on preserving capital and avoiding permanent losses.',
  },

  {
    id: 2,
    label: 'Value investing',
    description:
      'We seek to invest in high-quality companies with strong fundamentals that are trading at a discount to their intrinsic value.',
  },

  {
    id: 3,
    label: 'Long-term perspective',
    description:
      'We take a patient and long-term approach to investing, with a focus on the fundamentals of the businesses we invest in.',
  },
];

export const philosophyTitle =
  'Our investment philosophy is based on three key principles:';