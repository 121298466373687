export const headMenu = {
  style: 'Estil',
  typology: 'Tipologia',
  homes: 'Cases Noves',
};

export const typeHouseStyle = {
  pools: 'Piscines',
  classic: 'Clàssic',
  design: 'De disseny',
  minimalist: 'Minimalista',
  modern: 'Modern',
  original: 'Original',
  rustic: 'Rústic',
  traditional: 'Tradicional',
  wood: 'De fusta',
};

export const inputsTexts = {
  numOfBedrooms: `N° d'habitacions:`,

  bedrooms: 'habitacions',

  surfaceM: 'Superfície m2:',

  surface: 'superfície',

  numOfParking: `N° de places d'aparcament:`,

  pàrquing: 'aparcament',

  numOfBudget: 'N° de pressupostos:',

  budget: 'pressupost',
};

export const searchBtn = 'Buscar';
export const removeAll = 'Eliminar tot';