import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const ArchitectPageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: ${colorVariables.alternativeBackground};
`;
export const ProcessSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};

  @media (min-width: ${Breakpoints.md}px) {
    // padding: 3.75rem 0px;  /// for future feat

    padding: 3.75rem 0px 0rem;
  }
`;

export const PortfolioSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};

  padding-bottom: 5rem;

  @media (min-width: ${Breakpoints.xlg}px) {
    padding-bottom: 12.5rem;
  }
`;

export const PortfolioTabletHeader = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colorVariables.blockBorderColor};

  @media (min-width: ${Breakpoints.md}px) {
    margin: 0 2.5rem 3.75rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin: 0 6.25rem 3.75rem 6.25rem;
  }
`;

export const PortfolioLogoWrapper = styled.div`
  padding: 3.75rem;
  border-right: 1px solid ${colorVariables.blockBorderColor};
  @media (max-width: ${Breakpoints.xxl}px) {
    background: ${colorVariables.opacityWhiteBackgroundColor};
  }
  @media (min-width: ${Breakpoints.xxl}px) {
    padding: 7.187rem;
  }
`;
export const PortfolioLogo = styled.img`
  @media (min-width: ${Breakpoints.xxl}px) {
    width: 4.68rem;
    height: 4.375rem;
  }
`;
export const PortfolioTitle = styled.p`
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 4.56rem;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  width: 100%;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 7.5rem;
    line-height: 9.125rem;
  }
`;
