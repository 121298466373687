export const ColorsSection = {
  title: 'Кольори',
  description: `Відкрийте для себе приголомшливу колекцію нових кольорових фішок і приємних поєднань основних кольорів! Приготуйтеся бути зачарованими напівпрозорим голографічним ефектом, який надає нашій продукції особливого блиску.`,
  first: 'Anthracite',
  second: 'Nova Blue',
  third: 'Nova Grey',
  fourth: 'Nova Navy',
  fifth: 'Nova Pearl',
  sixth: 'Nova Stone',
};
