export const solarEnergySetupsTexts = {
  label: ' Des solutions énergétiques pour répondre à vos besoins',
  description:
    'Les accessoires intelligents pour la maison OSmart sont conçus pour rendre votre maison plus pratique, efficace et agréable. Des thermostats intelligents et des systèmes d’éclairage aux assistants activés par la voix et aux caméras de sécurité, il existe une variété d’accessoires disponibles pour vous aider à automatiser et à contrôler différents aspects.',
};

export const setupList = {
  typeBasic: 'Basique',
  nameBasic: ' Configuration de base',

  descriptionBasic:
    "Notre configuration de base comprend des panneaux solaires, une batterie et un onduleur pour convertir l'énergie collectée par les panneaux en électricité utilisable. Cette configuration est idéale pour les petits ménages avec des besoins de consommation d'énergie plus faibles.",

  typeStandart: 'Standard',
  nameStandard: ' Configuration standard',

  descriptionStandard:
    "Notre configuration standard comprend des panneaux et des batteries supplémentaires pour un stockage et une production d'énergie accrus. Cette configuration est idéale pour les ménages de taille moyenne avec des besoins de consommation d'énergie moyens.",

  typePremium: ' Prime ',
  namePremium: ' Configuration premium',

  descriptionPremium:
    "Notre configuration haut de gamme comprend des panneaux, des batteries et des onduleurs de premier ordre avec des systèmes de surveillance et de contrôle avancés. Idéal pour les grands ménages et les propriétés commerciales à forte consommation d'énergie.",
};
