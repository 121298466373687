import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import { TextField } from '../../../shared/components/TextFieldInput/TextFieldInput.styled';

export const FooterTabletWrapper = styled.div`
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const TopPartWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 8.12rem;

  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const NavBarWrapper = styled.div`
  width: 6.68rem;
  padding: 0.93rem 1.25rem 0.93rem 1.25rem;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const MenuNameLinkWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.62rem;
  }
`;
export const MenuName = styled.p`
  font-weight: 500;
  font-size: 0.87rem;
  line-height: 1.06rem;
  cursor: pointer;

  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
`;

export const EmptyWrapper = styled.div`
  width: 7.56rem;
  height: 100%;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const MenuBlockWrapper = styled.div`
  display: flex;
  padding: 1.25rem 1rem;
`;

export const MenuNameWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 1.68rem;
  }
`;

export const IndentedMenuName = styled.div`
  margin-bottom: 0.62rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const CenterPartWrapper = styled.div``;

export const TopCenterPartWrapper = styled.div`
  display: flex;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const SocialBlockWrapper = styled.div`
  width: 14.25rem;
  padding: 1.25rem 1.43rem 1.25rem 1.43rem;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const NetworkIconWrapper = styled.a`
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
`;

export const NetworkIcon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
`;

export const ContactsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 38px 0.75rem 38px;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const ContactsName = styled.a`
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 1.06rem;
  text-align: center;

  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
`;

export const PrivacyBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 3.31rem 0.75rem 3.31rem;
  cursor: pointer;
`;

export const PrivacyText = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;

  text-align: center;
  text-decoration-line: underline;
  &:first-child {
    margin-bottom: 0.625rem;
  }
  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
`;

export const CenterFormWrapper = styled.div`
  display: flex;

  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;
export const FormTitle = styled.p`
  padding: 1.18rem 3.81rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;

  color: ${colorVariables.footerTitleColor};

  text-shadow: 0px 0px 0.6rem rgba(0, 0, 0, 0.2);
`;

export const FormWrapper = styled.div`
  display: flex;
  margin-left: auto;
  width: 50%;

  .form {
    width: 100%;
    display: flex;
  }
`;

export const FormInput = styled.input`
  width: 334px;
  background: #171719;
  height: 3.37rem;
  border: none;
  padding-left: 1.25rem;
  color: rgba(255, 255, 255, 0.3);
  outline: none;
`;

export const SendBtn = styled.button`
  width: 3.31rem;
  height: 3.37rem;

  background: ${colorVariables.contactsPageButtonColor};
  border: none;
`;

export const SendIcon = styled.img``;

export const BottomPartWrapper = styled.div`
  padding: 1.37rem 0px 1.37rem 0px;
  width: 100%;
`;

export const ReservedBlock = styled.div``;

export const ReservedTitle = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.93rem;

  color: rgba(255, 255, 255, 0.7);
`;

export const TextFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  & ${TextField} {
    height: 100%;
    border-left: 1px solid ${colorVariables.navBarBorderColor};
    border-bottom: none;
  }
`;

export const ErrorMesage = styled.div`
  position: absolute;
  bottom: 0.25rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${colorVariables.errorColor};
  left: 1.25rem;
  width: 100%;
  height: 100%
  z-index: 20;
`;

export const PopupWrapper = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
`;

export const Popup = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid
    ${props =>
      props.isError ? colorVariables.errorColor : colorVariables.succesColor};
  width: 90%;
  height: 14.062rem;
  background: ${colorVariables.alternativeBackground};
  padding: 0 6.75rem;

  @media (min-width: ${Breakpoints.md}px) {
    width: 36.25rem;
  }

  & button {
    margin-top: 1.25rem;
    width: fit-content;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    background: rgba(255, 230, 98, 0.1);
    border: 1px solid ${colorVariables.yellowButtonBorder};
    color: ${colorVariables.alternativeTextColor};
  }
`;

export const PopupText = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 1.25rem;
`;
