import React, { FC } from 'react';
import { Video } from './VideoBlock.styled';

interface VideoProps {
   video: string;
}

export const VideoBlock: FC<VideoProps> = ({video}) => {
   return (
      <Video
         autoPlay
      muted
      loop
      playsInline>
         <source src={video} type="video/mp4" />
   </Video>
   )
}