export const ColorsSection = {
  title: 'Colors',
  description:
    'Experience a stunning collection of new color chips and enjoyable primary color blends! Prepare to be mesmerized by the translucent holographic effect that lends a distinctive brilliance to our products.',
  first: 'Anthracite',
  second: 'Nova Blue',
  third: 'Nova Grey',
  fourth: 'Nova Navy',
  fifth: 'Nova Pearl',
  sixth: 'Nova Stone',
  
};
