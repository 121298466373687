import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppWrapper } from './App.styled';
import { RoutePaths } from './shared/helpers/routes';
import { MainPage } from './scenes/MainPage';
import { useTranslation } from 'react-i18next';
import { TopNavBar } from './components/TopNavBar';
import { ContactsPage } from './scenes/ContactsPage';
import { FooterSection } from './components/FooterSection';
import { SmartHomePage } from './scenes/SmartHomePage';
import { ServicesPage } from './scenes/ServicesPage';
import { InvestmentsPage } from './scenes/InvestmentsPage';
import { RealEstatePage } from './scenes/RealEstatePage';
import { ConsultingPage } from './scenes/ConsultingPage';
import { AboutUsPage } from './scenes/AboutUsPage';
// import { ProjectsPage } from './scenes/ProjectsPage';
import { SolarEnergyPage } from './scenes/SolarEnergyPage';
import { PreBuildHousesPage } from './scenes/PreBuildHousesPage';
import { ArchitectPage } from './scenes/ArchitectPage';
import { InteriorCasePage } from './scenes/InteriorCasePage';
import { InteriorPage } from './scenes/InteriorPage';
import { SwimmingPoolsPage } from './scenes/SwimmingPoolsPage';
import { ConstructionPage } from './scenes/ConstructionPage';
import { LandscapeDesignPage } from './scenes/LandscapeDesignPage';
import { BuildingMaterialsPage } from './scenes/BuildingMaterialsPage';

import { LoginPage } from './scenes/LoginPage';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from './theme/Theme.constants';

import { FavouritesPage } from './scenes/FavoritesPage/FavouritesPage';
import { ModularHousesPage } from './scenes/ModularHousesPage/ModularHousesPage';
import { LeadForm } from './scenes/LeadForm';
import { PrivacyConditionsPage } from './scenes/PrivacyConditionsPage/PrivacyConditionsPage';
import { StubProjectsPage } from './scenes/StubProjectsPage';
import { CookiesPopup } from './shared/components/CookiesPopup';
import { getConditions, getPrivacy } from './shared/helpers/globalConstants';

const App = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const privacyText = getPrivacy();
  const conditionsText = getConditions();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });

  useEffect(() => {
    localStorage.setItem('language', i18n.language);
    if (i18n.language !== 'en') {
      document.body.classList.add('translated');
    } else {
      document.body.classList.remove('translated');
    }
  }, [i18n.language]);

  useEffect(() => {
    const isOpen = localStorage.getItem('cookiesPopup');
    if (isOpen !== 'isOpen') {
      setIsPopupOpen(true);
      localStorage.setItem('cookiesPopup', 'isOpen');
    }
  }, []);

  return (
    <AppWrapper>

      {isPopupOpen && (
        <CookiesPopup
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
      <TopNavBar />
      <Routes location={location}>
        <Route path={RoutePaths.mainPage} element={<MainPage />} />
        <Route path={RoutePaths.smartHome} element={<SmartHomePage />} />
        <Route path={RoutePaths.contactsPage} element={<ContactsPage />} />
        <Route path={RoutePaths.services} element={<ServicesPage />} />
        <Route
          path={RoutePaths.investmentsPage}
          element={<InvestmentsPage />}
        />
        <Route path={RoutePaths.realEstatePage} element={<RealEstatePage />} />
        <Route path={RoutePaths.consultingPage} element={<ConsultingPage />} />
        <Route path={RoutePaths.aboutUsPage} element={<AboutUsPage />} />
        {/* <Route path={RoutePaths.projectsPage} element={<ProjectsPage />} /> for future feat*/}
        <Route path={RoutePaths.projectsPage} element={<StubProjectsPage />} />
        <Route
          path={RoutePaths.solarEnergyPage}
          element={<SolarEnergyPage />}
        />
        <Route
          path={RoutePaths.preBuildHousesPage}
          element={<PreBuildHousesPage />}
        />
        <Route path={RoutePaths.architectPage} element={<ArchitectPage />} />

        <Route
          path={RoutePaths.interiorCasePage}
          element={<InteriorCasePage />}
        />

        <Route path={RoutePaths.interiorPage} element={<InteriorPage />} />

        <Route
          path={RoutePaths.swimmingPools}
          element={<SwimmingPoolsPage />}
        />

        <Route
          path={RoutePaths.constructionPage}
          element={<ConstructionPage />}
        />

        <Route
          path={RoutePaths.landscapeDesignPage}
          element={<LandscapeDesignPage />}
        />

        <Route
          path={RoutePaths.buildingMaterialsPage}
          element={<BuildingMaterialsPage />}
        />

        <Route path={RoutePaths.loginPage} element={<LoginPage />} />

        <Route path={RoutePaths.favourites} element={<FavouritesPage />} />

        <Route
          path={RoutePaths.modularHousesPage}
          element={<ModularHousesPage />}
        />

        <Route path={`/:word/${RoutePaths.leadForm}`} element={<LeadForm />} />
        <Route path={RoutePaths.leadForm} element={<LeadForm />} />
        <Route
          path={RoutePaths.privacyPolicyPage}
          element={
            <PrivacyConditionsPage
              textList={privacyText}
              title={'Privacy policy'}
            />
          }
        />
        <Route
          path={RoutePaths.conditionsPage}
          element={
            <PrivacyConditionsPage
              textList={conditionsText}
              title={'TERMS & CONDITIONS'}
            />
          }
        />
      </Routes>
      {location.pathname === RoutePaths.loginPage ? (
        <>{!isDesktop && <FooterSection />}</>
      ) : (
        <FooterSection />
      )}
    </AppWrapper>
  );
};

export default App;
