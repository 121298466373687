import { RoutePaths } from '../../../../shared/helpers/routes';

export const TopNavbarTitles = {
  // login: 'Login',
  call_to_action: 'Start your project',
};

export const navBarItemTitlesArray = [
  {
    itemName: 'Services',
    routePath: RoutePaths.services,
    storeItemName: 'Store',
  },
  {
    itemName: 'About us',
    routePath: RoutePaths.aboutUsPage,
  },
  {
    itemName: 'Contacts',
    routePath: RoutePaths.contactsPage,
  },
  {
    itemName: 'Favourite',
    routePath: RoutePaths.favourites,
  },
  // {
  //   itemName: 'Login',
  //   routePath: RoutePaths.loginPage,
  // },
  {
    itemName: 'Start your project',
    routePath: RoutePaths.leadForm,
  },
];


export const mobileNavBarItemTitlesArray = {
  itemNameLeadForm: 'Start your project',
    routePathLeadForm: RoutePaths.leadForm,
  
  itemNameServices: 'Services',
  routePathServices: RoutePaths.services,
  
  itemNameAbout: 'About us',
  routePathAbout: RoutePaths.aboutUsPage,

  itemNameContacts: 'Contacts',
  routePathContacts: RoutePaths.contactsPage,
  
  itemNameFavourite: 'Favourite',
  routePathFavourite: RoutePaths.favourites,
};