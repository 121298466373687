import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';
import { ItemTitle, MenuListItem } from '../MenuListBlock/MenuListBlock.styled';

export const MenuSectionWrapper = styled.div`
  width: 100%;
  height: fit-content;
  border-top: 1px solid #3D3F42;

  ${MenuListItem} {
    width: 100%;
    padding: 0 1.25rem;
  }

  ${ItemTitle} {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    opacity: 0.6;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    height: 100vh;
  }
`;

export const Menu = styled.ul<{ isHovered: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  list-style-type: none;
  margin: 0;
  padding: ${paddings.tabletPagePadding};
  transition: 0.5s background-color ease;
  ${props =>
    !props.isHovered &&
    `
   background: ${colorVariables.mainBackgroundColor};
  `}

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
  }
`;

export const MenuItem = styled.li<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc((100% - 80px) / 12);
  opacity: ${props => (props.isActive ? 1 : 0)};
  cursor: pointer;
  border-right: 1px solid ${colorVariables.lightOpacityBorderColor};
  

  &:hover {
    background-color: ${colorVariables.opacityBackgroundColor};
    backdrop-filter: blur(2px);
    opacity: 1;
    border: none;
    & ~ li {
      opacity: 0;
    }
  }

  &:nth-of-type(1) {
    border-left: 1px solid ${colorVariables.lightOpacityBorderColor} !important;

    &:hover {
      border: none;
    }
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc((100% - 200px) / 12);
  }
`;

export const MenuItemTitle = styled.span`
  transform: rotate(270deg);
  white-space: nowrap;
  color: ${colorVariables.mainTextColor};
  font-size: 1rem;
  line-height: 2rem;
  text-transform: uppercase;

  @media only screen and (min-width: ${Breakpoints.sm}px) {
    font-size: 1.175rem;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 1.5;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 1.625rem;
  }
`;


export const MenuImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
