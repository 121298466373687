 export const aboutUsNavItems = {
  values: 'Цінності',
  story: 'Історія',
  team: 'Команда',
};

export const aboutUsSecondDescription =
  'Наша компанія спеціалізується на наданні передових будівельних та нерухомість рішень, які пріоритезують задоволення клієнтів та якість. Наша комплексна ​​послугова гамма охоплює все, починаючи від будівництва та дизайну, закінчуючи житлом, басейнами, енергетикою, нерухомістю, інвестиціями та консультуванням. Завдяки нашій експертній команді ми постійно досягаємо відмінних результатів, які відповідають та перевищують очікування наших клієнтів, дотримуючись при цьому їх бюджетів та графіків. Пріоритезуючи довіру та будуючи довгострокові взаємовідносини з нашими клієнтами, ми постійно можемо досягати виняткових результатів та стати надійним партнером у їх успіху.';

  export const aboutUsTitle = {
    about: 'Про',
    us: 'нас',
  };