import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import bgImage from '../../shared/assets/images/investments/philosophySectionBg.png';
import bgImageTablet from '../../shared/assets/images/investments/philosophySectionTabletBg.png';
export const InvestmentsPhilosophyBgWrapper = styled.div`
  
  position: relative;
  background-image: url(${bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    background-image: url(${bgImageTablet});
  }

  

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      ${colorVariables.alternativeBackground} 0%,
      rgba(23, 23, 25, 0) 150%
    );
    width: 100%;
    height: 100%;
  }
`;

export const InvestmentsPhilosophyWrapper = styled.div`
  padding: 7.5rem 1.25rem;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 8.75rem 6.25rem;
  }
`;

export const InvestmentsTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  max-width: 20rem;
  color: ${colorVariables.mainTextColor};

  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px) !important;
  margin-bottom: 2.5rem;

  @media (min-width: ${Breakpoints.md}px) {
    max-width: 23.75rem;
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    max-width: 700px;
    font-size: 2.5rem;
    line-height: 49px;
    margin-bottom: 7.8125rem;
  }
`;

export const InvestmentsList = styled.ul``;
export const InvestmentsItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
  backdrop-filter: blur(2px) !important;
  max-width: 550px;
`;
export const InvestmentsItemTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: ${colorVariables.footerTitleColor};

  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  margin-bottom: 0.3125rem;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 30px;
    line-height: 37px;
  }
`;

export const InvestmentsItemText = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  max-width: 20rem;
  color: ${colorVariables.mainTextColorWitOpacity};

  @media (min-width: ${Breakpoints.md}px) {
    max-width: 26.25rem;
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    max-width: 29.6875rem;
    font-size: 16px;
    line-height: 30px;
  }
`;
