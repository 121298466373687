import { icon, LatLngTuple, LatLngBounds } from 'leaflet';
import popupIcon from '../../shared/assets/icons/map-icons/popup.svg';

export interface SimpleMapProps {
  position: LatLngTuple;
  zoom: number;
}

export type Location = {
  name: string;
  coordinates: LatLngTuple;
};
export const bounds = new LatLngBounds([-90, -180], [90, 180]);

export const locations: Location[] = [
  {
    name: 'Av. de les Drassanes, 6, Planta 23, 08001 Barcelona, Spain',
    coordinates: [41.376618985764765, 2.175226396784986],
  },
];

export const customIcon = icon({
  iconUrl: popupIcon,
  iconSize: [20, 20],
});

export const url =
  'https://api.mapbox.com/styles/v1/s2ava11/clgnmbodx00e501qm62v14648/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiczJhdmExMSIsImEiOiJjbGdubWFlY3IwMjJ4M2VxY3luZ3Z5d256In0.nBBM0T_BnIEGGIAbfCKMig';
