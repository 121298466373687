export const AccessoriesSection = {
  title: 'Accesorios',
  description:
    'Los accesorios inteligentes para el hogar están diseñados para hacer que su casa sea más cómoda, eficiente y agradable. Desde termostatos inteligentes y sistemas de iluminación hasta asistentes activados por voz y cámaras de seguridad, hay una gran variedad de accesorios disponibles para ayudarle a automatizar y controlar diferentes aspectos de su hogar.',
  stages: 'Etapas',
  order: 'Solicitar',
};

export const accessoriesIcons = {
  conditioners: 'Acondicionadores de aire',

  purifiers: 'Purificadores de aire',

  bridges: 'Puentes',

  cameras: 'Cámaras',

  doorbells: 'Timbres',

  fans: 'Ventiladores',

  faucets: 'Grifos',

  garageDoors: 'Puertas de garaje',

  humidifiers: 'Humidificadores',

  lights: 'Luces',

  locks: 'Cerraduras',

  outlets: 'Tomas de corriente',

  receivers: 'Receptores',

  routers: 'Enrutadores',

  security: 'Seguridad',

  sensors: 'Sensores',

  speakers: 'Altavoces',

  sprinklers: 'Rociadores',

  switches: 'Interruptores',

  thermostats: 'Termostatos',

  tvs: 'Televisoress',

  windows: 'Ventanas',
};
  

