export const navItems = {
  preConstruction: 'Pre-Construction',
  process: 'Process',
};

export const description =
  "Thanks to our rich experience and high-quality implementation of capital construction projects, we guarantee each of our clients a professional approach and the best pricing policy. We purchase proven building materials at competitive prices, provide reports for each stage, competently perform the entire range of works on the construction of houses made of brick, foam concrete, aerated concrete, reinforced concrete, blocks, using metal structures and other load-bearing elements of the building. Buckingham Palace, Rapunzel's Tower or Howl's Moving Castle - we can realize any of your architectural wishes and build a turnkey house.";

export const processList = {
  labelFirst: 'Attention to the environment',
  descriptionFirst:
    'We strive to use organic materials that do not harm nature. Cheap substitutes and chemical products are taboo for us. We care not only about the environment, but also about our customers who will live, create, work, sleep and receive guests in the house.',

  labelSecond: 'Experience and professionalism',
  descriptionSecond:
    'Our team is made up of highly qualified professionals who are experienced with various types and styles of construction. We know which materials and technologies are best used for each specific project.',

  labelThird: 'Complex work',
  descriptionThird:
    'We provide turnkey house construction services, which means that we take care of all stages of work - from design to commissioning. This allows us to ensure a high level of work quality and guarantee compliance with deadlines.',

  labelFourth: 'The quality of materials',
  descriptionFourth:
    'We use only high quality materials and components from trusted suppliers, which guarantees the durability and reliability of your home. We only work with trusted suppliers to guarantee the durability of every square meter of your home.',
  labelFifth: 'Individual approach',
  descriptionFifth:
    'We want to understand the needs of each of our clients and satisfy them. Party house, townhouse for an elderly couple or a villa for a young family with children - each customer has individual needs. We take into account your wishes and requirements, as well as help you choose the best solutions for your project. We work with each client individually to create a home that meets all their needs and preferences.',
  labelSixth: 'Quality control',
  descriptionSixth:
    'We strictly control the quality of all stages of work, from the selection of materials to the installation of equipment. This allows us to provide a high level of work quality and ensure compliance with all norms and requirements. We provide reporting on each stage and guarantee transparency.',
};

export const aboutTexts = {
  textFirst: 'We set new standards in the market.',

  textSecond:
    'For us, each house is not just square meters, but a cozy space, <br/> thought out to the smallest detail, where you want to live, enjoy <br/> and rightfully call this place “home”.',

  textThird:
    'We build houses that are associated with the best moments of <br/> life - the first word of your child, marriage proposal, the warmest <br/> evening with your family.',

  textFourth: 'We build homes that become your life.',

  textFifth: 'The main value for us is your smile, the main reward is trust.',
};

export const preConstructionTitle = 'Pre-Construction Planning';
export const preConstructionText =
  'We strive to create not just a home, but a real corner of happiness and comfort, where you can enjoy every minute. We are not limited to creating standard houses, we help to make your unique lifestyle on your own land. Therefore, we take on any project and know that nothing is impossible. Your architectural fantasy can become a reality today - just contact us. We will find the best solutions for your future home, and together with you we will develop the ideal option that will become the embodiment of your dreams and desires.';

export const constructionStepBlockTitle = 'Stages of construction';
export const constructionStepBlockDescription = 'Initial consultation';

export const constructionStepSection = {
  blockTitle: 'Stages of smart home',
  descriptionTitle: 'Move in',
  stepOne: {
    title: 'Step one',
    p1: `Planning. The first stage of construction is the moment when the idea of a future home is born. Here we ask questions about how the house should be, how it should be located on the site and what materials will be used. During the design process, our experts improve every detail of the house, from the foundation and load-bearing walls to the roof and ventilation ducts.`,
    p2: `We take care of the selection of all necessary materials, from the grade of concrete for pouring the foundation to the specification of door and window blocks and the implementation of the "smart house" system. If you need a more detailed design, we can calculate all communications and select materials for exterior and interior finishes.`,
  },
  stepTwo: {
    title: 'Step two',
    p1: `Preparatory work and foundation. Before you start building a house, you need to carry out a whole range of preparatory work. This includes cleaning the site of debris, removing unnecessary vegetation, leveling the ground, dismantling old buildings. But this is only the beginning - then it is necessary to prepare the future foundation for the house. One of the most important stages is the development of the foundation. Before you start digging a pit, you need to bring all communications and markup the future structure. `,
    p2: `Only after that you can start building the foundation. The choice of the type of foundation depends on many factors, such as the characteristics of the soil, area and number of storeys of the house. Each type of foundation has its own characteristics and requirements for the quality of materials. For example, when using concrete, it is necessary to take into account its shrinkage, which occurs during the drying process. Therefore, by trusting our team, you can be 100% sure that your home will have a solid foundation. `,
  },
  stepThree: {
    title: 'Step three',
    p1: `Walls and floors. After the foundation is securely fixed, it is time to build walls. It is important to choose a material that will provide strength, frost resistance and reliability of the future design. Today, one of the most common materials for low-rise construction is brick, gas block and foam block. Brick is a beautiful, environmentally friendly and durable material that is used for the construction of load-bearing walls and interior partitions. Thanks to the ability to combine different types of bricks, you can get an attractive building in Spanish or any other style that does not require additional decoration. However, the brick is heavy and is slowly laid out by the builder.
Aerated concrete blocks are lightweight, durable and environmentally friendly material with high thermal insulation properties. Due to the correct shape and precise dimensions, the laying of aerated concrete blocks is fast. Gas blocks do not crumble even when cutting with a conventional hacksaw.`,
    p2: `However, aerated concrete can absorb sea moisture and is not the most aesthetically pleasing material, which can be corrected with additional facade finishing.
In the process of erecting walls, the installation of interfloor and ceiling ceilings, interior partitions takes place. Important parameters of the future design are its heat, steam and sound insulation performance, which significantly improve the performance of the building.`,
  },
  stepFour: {
    title: 'Step four',
    p1: `Selection and installation of the roof. The roof is no less important element of the building than the foundation and walls. It not only protects the building from adverse weather conditions, but also adds to its aesthetic appeal. One of the common types of roofs are hip roofs, which are distinguished by their rigidity and attractive appearance. They provide additional protection for the walls of the building from external influences, but at the same time reduce the attic area and require professional design and installation, which our company can provide. Hip roofs, or pyramidal, are also in demand.`,
    p2: `They are characterized by minimal susceptibility to wind, which can be an additional advantage for residents of Spain, who are well aware of the cold north wind of Tramontana. Ideal solution for square buildings and also cheaper than hip roofs. Flat roofs are another option that is characterized by high installation speed and low cost of arrangement. `,
  },
  stepFive: {
    title: 'Step five',
    p1: `Finishing.Finishing works, which not only give the building a final look, but also play an important role in creating a comfortable space for residents. To maintain a comfortable indoor climate, modern plasters are used. In cases where it is necessary, ventilated facades are installed. Then the installation of windows, doors, stairs begins, as well as the alignment of the slopes and the connection of communications.`,
    p2: `The internal walls are plastered and covered with a finishing coating (paint, wallpaper or decorative plaster), and plumbing fixtures and lamps are also installed. Finally, it remains only to arrange the furniture, and your new home with a Spanish soul is ready to move in.`,
  },
  
};
