import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { Breakpoints } from '../../theme/Theme.constants';
import {
  BuildingMaterialsPageWrapper,
  MaterialsSectionWrapper,
  BenefitsSectionWrapper,
} from './BuildingMaterialsPage.styled';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { MaterialsSection } from './MaterialsSection';
import { BenefitsSection } from './BenefitsSection';
import sliderMobile from '../../shared/assets/images/building-materials/slider-mobile.jpg';
import sliderTablet from '../../shared/assets/images/building-materials/slider-tablet.jpg';
import sliderDesktop from '../../shared/assets/images/building-materials/slider-desktop.jpg';
import { SliderSection } from '../../shared/components/PagesSliderSection';
export const BuildingMaterialsPage: FC = () => {
  const menuItems = getMenuItems();
  const buildingMaterialsInfo = menuItems[11];

  const { t } = useTranslation();

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });
  const isTablet = useMediaQuery({
    query: ` (min-width: ${Breakpoints.md}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  const currentSlideImg = isMobile
    ? sliderMobile
    : isTablet
    ? sliderTablet
    : isDesktop
    ? sliderDesktop
    : '';
  const slides = [
    { id: 1, img: currentSlideImg },
   
  ];
  const navItems = [
    {
      title: '',
      sectionId: 'our-materials',
    },
    {
      title: '',
      sectionId: '',
    },
    {
      title: '',
      sectionId: '',
    },
  ];
  const currentTexts = {
    label: t('buildingMaterialsPage.buildingCurrentTexts.label'),
    description: t('buildingMaterialsPage.buildingCurrentTexts.description'),
  };
  
  return (
    <BuildingMaterialsPageWrapper>
      <PageHeaderSection
        logoImg={buildingMaterialsInfo.icon}
        title={buildingMaterialsInfo.label}
        bgImage={buildingMaterialsInfo.backgroundImg}
        withoutBorder
        navItems={navItems}
        description={buildingMaterialsInfo.description}
        descriptionColor={isDesktop ? '#F1F2F6' : ''}
        titleSize={isDesktop ? '80px' : isTablet ? '50px' : ''}
        id={buildingMaterialsInfo.id}
      />

      <MaterialsSectionWrapper id="our-materials">
        <ProcessBlockHeader
          currentTexts={currentTexts}
          isTabletPadding
          isTextAlignCenter
          descriptionPadding={isDesktop ? '60px' : isTablet ? '20px 80px' : ''}
          descriptionColor={'rgba(255, 255, 255, 0.7)'}
          descriptionMaxWidth={isDesktop ? '540px' : ''}
          titleSize={isDesktop ? '40px' : isTablet ? '24px' : ''}
          titlePadding={isDesktop ? '65px 0px' : ''}
          titleWrapperWidth={'75%'}
        />

        <MaterialsSection />
      </MaterialsSectionWrapper>
      <BenefitsSectionWrapper>
        <BenefitsSection />
      </BenefitsSectionWrapper>

      <SliderSection slides={slides} />
    </BuildingMaterialsPageWrapper>
  );
};
