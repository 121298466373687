import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const ContactsMapWrapper = styled.div`
  padding: 0rem 1.25rem 0px 1.25rem;
  background: ${colorVariables.alternativeBackground};
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0rem 2.5rem 0px 2.5rem;
  }
  @media (max-width: ${Breakpoints.xlg}px) {
    height: 25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0;

    margin-bottom: 0px;
  }
`;
