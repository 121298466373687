import React, { FC } from 'react';
import { Button } from './BluredButton.styled';
import { ButtonType } from './BluredButton.types';

export const BluredButton: FC<ButtonType> = ({
  title,
  handleClick,
  className,
  spanClassName,
}) => {
  return (
    <Button className={className} onClick={handleClick}>
      <span className={spanClassName ? spanClassName : ''}>{title}</span>
    </Button>
  );
};
