export const title = { 
  top: 'Top',
  services: 'Services'
}



export const allServices = 'All Services'


export const description = "At Moderna Casa, we offer our clients state-of-the-art solutions for land plots and real estate, taking into account the individual wishes of everyone. Our service is related to the provision of a full range of services - from the pit to the dream home: design and construction, equipping the territory with swimming pools, smart systems and solar panels. Our highly qualified team provides consulting services, follows trends, constantly improves the quality of work and guarantees exceptional results that exceed the expectations of our clients. We confidently step towards the title of market leader, building a strategic foundation through trust and professional standards."