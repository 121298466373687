import styled, { keyframes } from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions
} from '../../../theme/Theme.constants';


const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

export const PopupWrapper = styled.div<{ isPopupOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 4.375rem;
  right: 1.25rem;
  left: 1.25rem;
  z-index: 15;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(22px);
  padding: 1.25rem 1.875rem;
  
  animation: ${props => (props.isPopupOpen ? slideUp : fadeOut)} 0.5s
    ease-in-out forwards;
  transition: ${transitions.opacityTransitions};
`;
export const Text = styled.p`
   font-family: 'Overpass';
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
`

export const Accept = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: ${colorVariables.alternativeTextColor};
  opacity: 1;
  transition: ${transitions.opacityTransitions};
  &:hover {
    opacity: 0.7;
  }
`;