import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import { Button } from '../../shared/components/BluredButton/BluredButton.styled';

export const ClientLeadPageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(23, 23, 25, 0.8);
  padding: 11.625rem 1.25rem 2.5rem;
  max-width: ${Breakpoints.maxWidth}px;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 6.625rem 1.25rem 2.5rem;
  }

  & .MuiBottomNavigation-root {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  & .MuiBottomNavigationAction-label {
    font-size: 0.875rem !important;
    line-height: 1rem;
  }

  & .MuiButtonBase-root {
    padding: 0.375rem 0.5rem !important;
    backdrop-filter: blur(2px) !important;
    max-width: 45% !important;
    min-width: 45% !important;

    @media only screen and (min-width: ${Breakpoints.sm}px) {
      min-width: fit-content !important;
      max-width: fit-content !important;
    }
  }

  & div > .Mui-selected {
    border: 1px solid rgba(255, 230, 98, 0.7) !important;
  }
`;

export const ClientPartnerSwitcherBlock = styled.div`
  position: absolute;
  top: 5.625rem;
  left: 1.875rem;
  width: 85%;

  @media (min-width: ${Breakpoints.md}px) {
    top: 4.625rem;
    left: 1.875rem;
    width: fit-content;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    top: 6.625rem;
    left: 3.75rem;
  }

  & .MuiBottomNavigation-root {
    height: 1.75rem;
    gap: 0 !important;

    @media only screen and (min-width: ${Breakpoints.md}px) {
    }
  }

  & .MuiButtonBase-root {
    &:first-child {
      border-right: none !important;
    }
  }

  & .MuiButtonBase-root {
    max-width: 45% !important;
    min-width: 45% !important;

    @media only screen and (min-width: ${Breakpoints.sm}px) {
      min-width: 6rem !important;
      max-width: 6rem !important;
    }
  }

  & div > .Mui-selected {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background-color: ${colorVariables.blackBackgroundColor} !important;
  }
`;

export const PopupWrapper = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
`;

export const Popup = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid
    ${props =>
      props.isError ? colorVariables.errorColor : colorVariables.succesColor};
  width: 90%;
  height: 14.062rem;
  background: ${colorVariables.alternativeBackground};
  padding: 1rem;

  @media (min-width: ${Breakpoints.md}px) {
    width: 36.25rem;
    padding: 1.5rem 6.75rem;
  }

  & button {
    margin-top: 1.25rem;
    width: fit-content;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    background: rgba(255, 230, 98, 0.1);
    border: 1px solid ${colorVariables.yellowButtonBorder};
    color: ${colorVariables.alternativeTextColor};
  }
`;

export const PopupText = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 1.25rem;
`;

export const ControlsBlock = styled.div<{ isOneButton?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${props =>
    props.isOneButton ? 'flex-end' : 'space-between'};

  & ${Button} {
    width: 8.125rem;
    padding: 0.625rem 0;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const AcceptButton = styled.button`
  width: 8.125rem;
  background: rgba(255, 230, 98, 0.1);
  border: 1px solid ${colorVariables.yellowButtonBorder};
  padding: 0.625rem 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  cursor: pointer;
  color: ${colorVariables.alternativeTextColor};

  &:disabled {
    cursor: default;
    filter: grayscale(100%);
  }
`;
