import styled, { css } from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';
import heart from '../../shared/assets/icons/about-us-icons/heart.svg';
import book from '../../shared/assets/icons/about-us-icons/book.svg';
import rightArrow from '../../shared/assets/icons/about-us-icons/right-arrow.svg';

type AboutUsProps = {
  hovered: boolean;
};
export const AboutUsBlocksWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
export const AboutUsWrapper = styled.div<AboutUsProps>`
  position: relative;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  background-color: ${({ hovered }) =>
    hovered
      ? colorVariables.blackBackgroundColor
      : colorVariables.mainBackgroundColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    border-top: 1px solid ${colorVariables.blockBorderColor};
  }
  transition: ${transitions.backgroundHoverTransition};
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    border-top: ${({ hovered }) =>
      !hovered ? `1px solid ${colorVariables.blockBorderColor} ` : 'none'};
    height: 100vh;
  }

  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: 130vh;
  }
`;

export const ContentWrapper = styled.div<{ hovered: boolean }>`
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    border-top: ${({ hovered }) =>
      hovered ? `1px solid ${colorVariables.blockBorderColor} ` : 'none'};
    height: 100vh;
  }

  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: 130vh;
  }
  ${({ hovered }) =>
    hovered &&
    css`
      filter: blur(5px) !important;
    `}
`;

export const TopBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: ${paddings.tabletPagePadding};
  height: calc(100% / 3);
  width: 100%;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: calc(100% / 5);
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
  }
`;

export const MiddleBlock = styled(TopBlock)`
  grid-template-columns: 3fr 1fr;

  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: calc(100% / 2);
  }
`;

export const BottomBlock = styled(TopBlock)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: calc(100% / 5);
  }
`;

export const Block = styled.div<{ hovered: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  border-right: ${({ hovered }) =>
    !hovered ? `1px solid ${colorVariables.blockBorderColor}` : 'none'};

  &:first-child {
    border-left: ${({ hovered }) =>
      !hovered ? `1px solid ${colorVariables.blockBorderColor}` : 'none'};
  }
`;

export const IconBlock = styled(Block)`
  background-color: ${colorVariables.buttonGreyBackground};
  cursor: pointer;
  transition: ${transitions.backgroundHoverTransition};
  padding: 1rem;

  &:hover {
    background-color: inherit;
  }
`;

export const HeartBlock = styled(IconBlock)``;

export const BookBlock = styled(IconBlock)``;

export const ArrowBlock = styled(IconBlock)``;

export const Icon = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const HeartIcon = styled(Icon)`
  width: 3.75rem;
  height: 3.25rem;
  background-image: url(${heart});

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    width: 4.625rem;
    height: 4rem;
  }
`;

export const BookIcon = styled(Icon)`
  width: 3.675rem;
  height: 2.875rem;
  background-image: url(${book});

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    width: 4.875rem;
    height: 3.875rem;
  }
`;

export const ArrowIcon = styled(Icon)`
  width: 1.5rem;
  height: 2.875rem;
  background-image: url(${rightArrow});

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    width: 2rem;
    height: 3.875rem;
  }
`;

export const TitleBlock = styled(Block)<{
  hoveredHeartBlock: boolean;
  hovered: boolean;
}>`
  font-weight: 700;
  font-size: 4rem;
  line-height: 100%;
  text-transform: uppercase;

  .opacity-part {
    color: ${colorVariables.aboutUsOpacityPart};
  }
  .translated & {
    font-size: 3rem;

    @media only screen and (min-width: ${Breakpoints.xlg}px) {
      font-size: 4rem;
    }
  }
  
  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    font-size: 8rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 9.25rem;
    line-height: 11.25rem;
  }
`;

export const DescriptionBlock = styled(Block)`
  padding: 2.25rem;
  font-size: 0.75rem;
  line-height: 1.5rem;

  @media only screen and (min-width: ${Breakpoints.sm}px) {
    padding: 1.5rem;
    font-size: 0.625rem;
    line-height: 1rem;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 1.5rem;
    font-size: 0.625rem;
    line-height: 1.3rem;

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    font-size: 1rem;
    line-height: 2rem;
  }
`;
