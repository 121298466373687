import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const LandscapeDesignPageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const ServicesSectionWrapper = styled.div`
  background-color: ${colorVariables.alternativeBackground};
  padding: 3.75rem 0px 0px;
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 6.25rem 0px 8.125rem;
  }
`;

export const BenefitsSectionWrapper = styled.div`
  background-color: ${colorVariables.alternativeBackground};
  padding: 0px 0px 3.75rem;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 0px 7.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0px 0px 8.75rem;
  }
`;
