import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContactsFormWrapper,
  ContactsWrapper,
  Contacts,
  MainTitle,
  IconsWrapper,
  NetworkIcon,
  FormBlock,
  ContactsBlockWrapper,
  VerticalBlockWrapper,
  NetworkIconWrapper,
} from './ContactsFormSection.styled';

import { Form, Formik } from 'formik';
import { TextFieldInput } from '../../shared/components/TextFieldInput';
import { BluredButton } from '../../shared/components/BluredButton';
import inst from '../../shared/assets/icons/contacts/instagram.svg';
import pint from '../../shared/assets/icons/contacts/pinterest.svg';
import youtube from '../../shared/assets/icons/contacts/youtube.svg';
import whatsUp from '../../shared/assets/icons/contacts/whats-up.svg';

export interface FormValuesProps {
  name: string;
  email: string;
  phone: string;
  description: string;
}

export const ContactsFormSection: FC = () => {
  const handleChange = (formValues: FormValuesProps) => {};
  const { t } = useTranslation();
  const formInitialValues = {
    name: '',
    email: '',
    phone: '',
    description: '',
  };

  return (
    <ContactsFormWrapper>
      <ContactsBlockWrapper>
        <MainTitle>{t('mainPage.ContactsTitles.contacts')}</MainTitle>
        <ContactsWrapper>
          <Contacts
            href={`tel:${t('mainPage.ContactsTitles.telNumber')}`}
            target="_blank"
            rel="noreferrer">
            {' '}
            {t('mainPage.ContactsTitles.telNumber')}
          </Contacts>
          <Contacts
            href="mailto:info@modernacasa.es"
            target="_blank"
            rel="noreferrer">
            {t('mainPage.ContactsTitles.email')}
          </Contacts>
        </ContactsWrapper>
        <MainTitle>{t('mainPage.ContactsTitles.socialMedia')}</MainTitle>
        <IconsWrapper>
          <NetworkIconWrapper
            href="https://wa.me/+34600592007"
            target="_blank"
            rel="noreferrer">
            <NetworkIcon src={whatsUp} alt="whats-up-icon" />
          </NetworkIconWrapper>
          <NetworkIconWrapper
            href="https://www.instagram.com/modernacasa.es/"
            target="_blank"
            rel="noreferrer">
            <NetworkIcon src={inst} alt="instagram-icon" />
          </NetworkIconWrapper>

          <NetworkIconWrapper
            href="https://pin.it/2iuHA5D"
            target="_blank"
            rel="noreferrer">
            <NetworkIcon src={pint} alt="pint-icon" />
          </NetworkIconWrapper>
          <NetworkIconWrapper
            href="https://www.youtube.com/channel/UCI7kAr6wujbQCErOuHvpcYw"
            target="_blank"
            rel="noreferrer">
            <NetworkIcon src={youtube} alt="youtube-icon" />
          </NetworkIconWrapper>
        </IconsWrapper>

        <Formik initialValues={formInitialValues} onSubmit={handleChange}>
          {({ isSubmitting, handleSubmit }) => (
            <Form
              className="form"
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}>
              <FormBlock>
                <VerticalBlockWrapper>
                  <TextFieldInput
                    type="text"
                    name="name"
                    placeholder={t('mainPage.ContactsTitles.name_placeholder')}
                  />
                  <TextFieldInput
                    type="text"
                    name="email"
                    placeholder={t('mainPage.ContactsTitles.email_placeholder')}
                  />
                  <TextFieldInput
                    type="text"
                    name="phone"
                    placeholder={t('mainPage.ContactsTitles.phone_placeholder')}
                  />
                  <TextFieldInput
                    type="text"
                    name="description"
                    placeholder={t(
                      'mainPage.ContactsTitles.description_placeholder',
                    )}
                    addClassname="bottom-field"
                  />
                </VerticalBlockWrapper>
                <BluredButton
                  title={t('mainPage.ContactsTitles.send')}
                  handleClick={() => {}}
                  spanClassName={'rotated-text'}
                />
              </FormBlock>
            </Form>
          )}
        </Formik>
      </ContactsBlockWrapper>
    </ContactsFormWrapper>
  );
};
