import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';
export const RadioSwitcherWrapper = styled.div`
  & .Mui-selected {
    color: ${colorVariables.alternativeTextColor};
    transform: scale(1);
    font-size: 0.75rem !important;
  }
  & .MuiBottomNavigation-root {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    background-color: inherit;

    @media only screen and (min-width: ${Breakpoints.md}px) {
      flex-wrap: wrap;
      flex-direction: row;
      height: fit-content;
      padding: 0.625px 1.25px;
      gap: 1.875rem;
    }

    & .MuiButtonBase-root {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      text-transform: capitalize;
      font-family: 'Montserrat' !important;
      white-space: nowrap;
      color: ${colorVariables.whiteOpacityColor};
      font-weight: 500;
      line-height: 1.375rem;
      background: ${colorVariables.alternativeBackground};
      border: 1px solid rgba(255, 255, 255, 0.2);
      max-width: 100%;
      min-width: 100%;
      height: fit-content;
      padding: 0.375rem 1.25rem;

      @media only screen and (min-width: ${Breakpoints.md}px) {
        min-width: fit-content;
        max-width: fit-content;
        line-height: 1.375rem;
      }
    }

    & .MuiBottomNavigationAction-label {
      font-family: Montserrat;
      font-size: 0.75rem !important;
    }
  }
`;
