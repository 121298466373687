export const servicesTexts = {
  label: 'Nuestros servicios del uso de energía solar',
  description: `Ofrecemos una amplia gama de servicios de diseño de interiores para ayudarle a conseguir el espacio de sus sueños. Desde el concepto inicial y la planificación del espacio hasta la selección de colores, la búsqueda de muebles y los accesorios, nuestro equipo puede encargarse de todos los aspectos de su proyecto. Nuestros servicios incluyen`,
};
export const servicesList = {
  textFirst: 'Consulta y evaluación',

  textSecond: 'Diseño y propuesta',

  textThird: 'Financiación e incentivos',

  textFourth: 'Permisos y aprobaciones',

  textFifth: 'Instalación',

  textSixth: 'Activación y mantenimiento del sistema',
};