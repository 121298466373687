
import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';

export const SliderSectionWrapper = styled.div`
  position: relative;
  width: 100%;
z-index: 10;
  background: ${colorVariables.mainBackgroundColor};

  @media (max-width: ${Breakpoints.md}px) {
    height: calc(100vh - 3.75rem);
  }
  @media (min-width: ${Breakpoints.md}px) {
    height: 100vh;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-pagination {
    display: flex;
    justify-content: center;
    bottom: 1.875rem;

    @media (min-width: ${Breakpoints.md}px) {
      justify-content: flex-end;
      padding-right: 2.5rem;
    }
  }
  & .swiper-pagination-bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 3.437rem;
    opacity: 1;
    background: rgba(0, 0, 0, 0.1);
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: ${colorVariables.buttonBorder};
    backdrop-filter: blur(2px) !important;
    border-radius: 0;
    margin: 0 !important;

    &:last-child {
      border-width: 1px 1px 1px 1px;
    }
  }

  & .swiper-pagination-bullet-active {
    background: rgba(0, 0, 0, 0.3);

    backdrop-filter: blur(2px) !important;
  }
`;

export const SlideImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const ViewBtn = styled.button`
  position: absolute;
  bottom: 83px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorVariables.opacityBackgroundColor};
  border: 1px solid ${colorVariables.secondaryIconColor};
  padding: 1rem 2.5rem;
  backdrop-filter: blur(2px) !important;
  z-index: 10;
  cursor: pointer;
  width: 20rem;
  @media (max-width: ${Breakpoints.md - 1}px) {
    transform: translate(-50%, 0);
  }

  @media (min-width: ${Breakpoints.md}px) {
    left: 2.5rem;
    bottom: 1.875rem;
    width: 11.25rem;
    padding: 1rem 0;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    left: 6.25rem;
  }
`;
export const ViewBtnText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 0.625rem;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  color: ${colorVariables.mainTextColor};
`;

export const NameProjectWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1.25rem;

  padding: 0.625rem 1.25rem;
  background: ${colorVariables.opacityBackgroundColor};
  border: 1px solid ${colorVariables.secondaryIconColor};

  backdrop-filter: blur(2px);

  @media (min-width: ${Breakpoints.md}px) {
    top: 1.25rem;
    left: 2.5rem;
    padding: 1rem 1.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    top: 1.875rem;
    left: 6.25rem;
    padding: 1rem 2.5rem;
  }
`;

export const NameProject = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export const PageName = styled(NameProject)`
  font-weight: 400;
  margin-left: 0.625rem;

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
