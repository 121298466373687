import React, { FC } from 'react';
import {
  StubProjectsPageWrapper,
  Wrapper,
  Title,
  TopWrapper,
  Img,
  BottomWrapper,
} from './StubProjectsPage.styled';
import firstImg from '../../shared/assets/images/stub-projects/first-img.jpg';
import secondImg from '../../shared/assets/images/stub-projects/second-img.jpg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { useTranslation } from 'react-i18next';
export const StubProjectsPage: FC = () => {
  const {t} = useTranslation()
   const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  return (
    <StubProjectsPageWrapper>
      <Wrapper>
        {!isTablet && (
          <>
            <Title>
              {t('stubProjectsPage.stubProjectsPageTexts.thisPage')}
            </Title>
            <Title>{`${t('stubProjectsPage.stubProjectsPageTexts.will')} ${t(
              'stubProjectsPage.stubProjectsPageTexts.be',
            )}`}</Title>
            <Title>
              {t('stubProjectsPage.stubProjectsPageTexts.readySoon')}
            </Title>
          </>
        )}
        {isTablet && (
          <>
            <TopWrapper>
              <Title>
                {t('stubProjectsPage.stubProjectsPageTexts.thisPage')}
              </Title>
              {isTablet && <Img src={firstImg} />}
              <Title>{t('stubProjectsPage.stubProjectsPageTexts.will')}</Title>
            </TopWrapper>
            <BottomWrapper>
              <Title>{t('stubProjectsPage.stubProjectsPageTexts.be')}</Title>
              <Img src={secondImg} />
              <Title>
                {t('stubProjectsPage.stubProjectsPageTexts.readySoon')}
              </Title>
            </BottomWrapper>
          </>
        )}
      </Wrapper>
    </StubProjectsPageWrapper>
  );
};
