import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../../theme/Theme.constants';
import partners from '../../../../shared/assets/icons/smart-home/partners.svg';
import apple from '../../../../shared/assets/icons/smart-home/Apple.svg';
import mi from '../../../../shared/assets/icons/smart-home/MI.svg';
import ajax from '../../../../shared/assets/icons/smart-home/ajax.svg';
import smartLife from '../../../../shared/assets/icons/smart-home/smart-life.svg';
import lifeSmart from '../../../../shared/assets/icons/smart-home/life-smart.svg';

export const AnotherStyleWrapper = styled.div<{ isAnotherStyle?: boolean }>`
position: relative;
  z-index: 15;
  ${props =>
    props.isAnotherStyle &&
    `
   
    padding: 0rem 2.5rem;

    @media only screen and (min-width: ${Breakpoints.xlg}px){
        padding: 0px 6.25rem;
    }
`}
`;

export const PartnersWrapper = styled.div<{ isAnotherStyle?: boolean }>`
    
  height: 100%;
  border-top: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.lg}px) and (min-height: 650px) {
    height: 75vh;
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) and (min-height: 650px) {
    height: 100vh;
  }

  ${props =>
    props.isAnotherStyle &&
    `
   
   background: rgba(23, 23, 25, 0.1);

    backdrop-filter: blur(10px);
   `}
`;

export const TopBlock = styled.div<{ isAnotherStyle?: boolean }>`
  display: grid;
  ${props => !props.isAnotherStyle ? `grid-template-columns: 1fr 3fr; ` : 'grid-template-columns: 1fr 1fr;'}

  ${props => !props.isAnotherStyle && `padding: ${paddings.tabletPagePadding};`}
  

  height: calc(100% / 3);
  width: 100%;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  @media (min-width: ${Breakpoints.xlg}px) {
    grid-template-columns: ${props =>
      props.isAnotherStyle ? '1fr 0fr' : '1fr 3fr'};
  }
`;

export const MiddleBlock = styled(TopBlock)<{ isAnotherStyle?: boolean }>`
  ${props => !props.isAnotherStyle ? ` grid-template-columns: 2fr 1fr 1fr;` : 'grid-template-columns:none;'}
  @media (min-width: ${Breakpoints.xlg}px) {
    grid-template-columns: ${props =>
      props.isAnotherStyle ? '1fr 0fr' : '2fr 1fr 1fr'};
  }
  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: calc(100% / 2);
  }
`;

export const BottomBlock = styled(TopBlock)<{ isAnotherStyle?: boolean }>`
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${colorVariables.blockBorderColor};

  &:first-child {
    border-left: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const IconBlock = styled(Block)<{ isAnotherStyle?: boolean }>`
  padding: 1rem;

  @media only screen and (max-width: ${Breakpoints.lg}px) {
    padding: ${props => (props.isAnotherStyle ? '70px 30px' : '3rem')};
  }

  & .apple-icon {
    fill: ${colorVariables.mainIconColor};
    width: 2.125rem;
    height: 2.5rem;

    @media only screen and (min-width: ${Breakpoints.lg}px) {
      width: 3.125rem;
      height: 3.75rem;
    }
  }

  @media only screen and (max-width: ${Breakpoints.xlg}px) {
    & .other-icon {
      width: 110px;
      height: 20px;
    }

    & .sun-power {
      width: 140px;
      height: 45px;
    }

    & .lg {
      width: 80px;
      height: 40px;
    }
  }
`;

export const TitleBlock = styled(Block)`
  font-weight: 700;
  font-size: 5rem;
  line-height: 100%;
  text-transform: uppercase;

  @media only screen and (max-width: ${Breakpoints.lg}px) {
    padding: 35px 30px;
  }
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 7rem;
    padding: 35px;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    padding: 0px 125px;
    .translated & {
      font-size: 7rem;
    }
  }
`;

export const DescriptionBlock = styled(Block)<{ isAnotherStyle?: boolean }>`
  font-family: 'Overpass';
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 1rem;
  background: inherit;

  ${props =>
    props.isAnotherStyle
      ? `
     color: ${colorVariables.mainTextColor};
      opacity: 0.7;
      backdrop-filter: blur(10px);
     
        @media only screen and (max-width: ${Breakpoints.md}px){
       text-align: center;
    }
    @media only screen and (min-width: ${Breakpoints.md}px){
      font-size: 12px;
      line-height: 20px;
      padding: 1.25rem 4.68rem;
     
     
      
    }

  
    @media only screen and (min-width: ${Breakpoints.lg}px){
      font-size: 1.25rem;
     
      padding: 0px 4.68rem;
      line-height: 30px;

      
    }
    @media only screen and (min-width: ${Breakpoints.xlg}px){
      font-size: 1.25rem;
      line-height: 2.5rem;
     
      padding: 0px 6.875rem;
     
     

      
    }
  `
      : `
  
  
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 0.7rem;
    line-height: 1.5rem;
   
    .translated &{
        font-size: 0.6rem;
        line-height: 1.2rem;
      }
    background: ${
      !props.isAnotherStyle
        ? `${colorVariables.alternativeBackground}`
        : `${colorVariables.partnersSectionBackground};`
    };
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 1rem;
    line-height: 2.5rem;
    // padding: 3.75rem;
    background: ${
      !props.isAnotherStyle
        ? `${colorVariables.alternativeBackground}`
        : `${colorVariables.partnersSectionBackground};`
    };
    color: rgba(255, 255, 255, 0.7);

    .translated &{
      font-size: 0.9rem;
      line-height: 1.8rem;
    }
  }
  
  `}
`;

export const Icon = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const PartnersIcon = styled(Icon)`
  width: 40px;
  height: 32px;
  background-image: url(${partners});

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 75px;
    height: 60px;
  }
`;

export const AppleIcon = styled(Icon)`
  width: 34px;
  height: 40px;
  background-image: url(${apple});

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 50px;
    height: 60px;
  }
`;

export const MIIcon = styled(Icon)`
  width: 35px;
  height: 35px;
  background-image: url(${mi});

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 54px;
    height: 54px;
  }
`;

export const AjaxIcon = styled(Icon)`
  width: 74px;
  height: 18px;
  background-image: url(${ajax});
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 132px;
    height: 34px;
  }
`;

export const SmartLifeIcon = styled(Icon)`
  width: 90px;
  height: 52px;

  background-image: url(${smartLife});
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 140px;
    height: 80px;
  }
`;

export const LifeSmartIcon = styled(Icon)`
  width: 74px;
  height: 14px;
  background-image: url(${lifeSmart});

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 145px;
    height: 28px;
  }
`;
