export const architectStepBlockTitle = 'Etapas de la Arquitectura';
export const architectStepBlockDescription = 'Consulta inicial';

export const architectStepSection = {
   blockTitle: 'Etapas de la Arquitectura',
  descriptionTitle: ' Consulta inicial',
  stepOne: {
    title: 'Paso uno',
    p1: `Conocimiento. El arquitecto conoce más de cerca al cliente y la propiedad o terreno. En esta fase inicial, el arquitecto se comunica con el futuro cliente, averigua sus necesidades, requisitos y expectativas del futuro edificio.`,
    p2: `Todos los matices más sutiles son importantes: el estilo de vida del cliente, la presencia de niños o animales, las preferencias individuales. También estudia la ubicación del objeto, analiza el contexto local y el entorno para determinar los factores que afectan al diseño del edificio.`,
  },

  stepTwo: {
    title: 'Paso dos',
    p1: `Finalidad del objeto. El arquitecto determina las principales preferencias del cliente en cuanto al diseño y la funcionalidad del edificio. Esto puede incluir la definición del tipo de edificio (residencial, comercial, público) y el estilo de diseño (clásico, moderno, minimalista).`,
    p2: `Si el objeto se está construyendo para alquiler diario, probablemente, no habrá que prestar tanta atención a la colocación y funcionalidad de la cocina.`,
  },
  stepThree: {
    title: 'Paso tres',
    p1: `Estudio del lugar. Aquí el arquitecto conoce el territorio para comprender sus limitaciones y posibilidades.`,
    p2: `Esto puede incluir el análisis de la topografía, la composición del suelo y las condiciones climáticas, así como la comprobación de la disponibilidad de comunicaciones e infraestructuras.`,
  },
  stepFour: {
    title: 'Paso cuatro',
    p1: `Análisis del componente jurídico. Determinación de la posibilidad de resolver problemas de construcción o reconstrucción en el marco de la legislación y las normas de diseño vigentes.`,
    p2: `En esta fase, el arquitecto determina hasta qué punto el proyecto es realista y viable, teniendo en cuenta los requisitos de la legislación y las normas de diseño. También determina el coste y la cantidad de recursos necesarios para su realización.`,
  },
  stepFive: {
    title: 'Paso cinco',
    p1: `Trabajo con documentación. Presentación de documentos y obtención de permisos de construcción o reconstrucción, obtención de especificaciones técnicas de los propietarios de redes externas de suministro de agua, gasoductos y redes eléctricas.`,
    p2: ``,
  },
  stepSix: {
    title: 'Paso seis',
    p1: `Soluciones funcionales. El desarrollo de un concepto arquitectónico es el siguiente paso tras obtener los permisos y deseos del cliente. En esta fase, el arquitecto crea una idea general del objeto que se va a diseñar, lo que incluye determinar la zonificación funcional y crear un esquema espacial.`,
    p2: `Crear un concepto permite evaluar el potencial del objeto y comprender cómo satisfará los deseos del cliente en el aspecto funcional de la cuestión. ¿Se sentirá cómodo viviendo en una casa con su familia?, ¿Cómo de popular será el objeto para alquilarlo?`,
  },
  stepSeven: {
    title: 'Paso siete',
    p1: `Desarrollo de un diseño preliminar. El arquitecto determina las soluciones arquitectónicas, constructivas, funcionales y artísticas para el objeto de construcción o reconstrucción.`,
    p2: `Un boceto de diseño permite al cliente ver el aspecto general del objeto, evaluar la conformidad visual y si se corresponde con sus deseos.`,
  },
  stepEight: {
    title: 'Paso ocho',
    p1: `Desarrollo de un borrador de trabajo. Se trata de la fase final del diseño arquitectónico, que incluye un paquete completo de documentación del proyecto. Como parte del desarrollo del borrador de trabajo, el especialista tiene en cuenta la sección arquitectónica, la sección estructural, el alcantarillado, la fontanería, el equipamiento eléctrico, la ventilación, el aire acondicionado, la calefacción y otros componentes.`,
    p2: ` El proyecto de trabajo contiene una descripción detallada de todas las soluciones constructivas, técnicas y de ingeniería necesarias para la construcción o reconstrucción de la instalación.`,
  },
};
