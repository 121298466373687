export const PoolsSection = {
  title: 'басейни',
  description: `Відкрийте для себе наші керамічні басейни Compass: 24 моделі, що ідеально підходять для сучасних або класичних будинків. Підходять для садів, терас або для використання в приміщенні. Прикрасьте свій житловий простір стилем та універсальністю. Насолоджуйтеся красою і функціональністю наших керамічних басейнів Compass, що пропонують гармонійне поєднання стилю і універсальності для вашого дозвілля і релаксації. Ознайомтеся з нашою колекцією зараз і зробіть свій вибір на користь басейну, який дійсно покращить ваше житлове середовище. Пориньте вже сьогодні!`,
  premium: 'Преміум',
  selection: 'Вибір',
  small: 'Малий',
  length: 'Довжина',
  width: 'Ширина',
  depth: 'Глибина',
  capacity: "Об'єм",
};
