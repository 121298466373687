export const discoverList = {
labelFirst: 'Назва',

labelSecond: 'Назва',

labelThird: 'Назва',
};

export const realEstateDiscoverTexts = {
  label: 'Відкрийте для себе свій власний земельний ділянку сьогодні',
  description:
    'Ми пропонуємо широкий спектр земельних ділянок для будь-яких потреб та бюджету. Якщо ви мріяли про власну земельну ділянку, не шукайте далі. Наш вибір включає все, від невеликих міських ділянок до великих сільськогосподарських просторів. Перегляньте нашу колекцію та знайдіть ідеальну ділянку для своїх мрій.',
};

export const aboutDiscoverList = [
  {
    id: 1,
    label: 'Про нас',
    text: 'Це компанія з більш ніж 50-річним досвідом у сфері нерухомості. Ми спеціалізуємося на розробці, будівництві та продажу нерухомості в Іспанії. Нашою метою є допомогти нашим клієнтам знайти ідеальну власність для своїх потреб.',
  },
  {
    id: 2,
    label: 'Продаж нерухомості',
    text: 'Ми пропонуємо широкий спектр нерухомості для продажу по всій Іспанії. Наші послуги включають пошук ідеальної власності, проведення оглядів та надання порад щодо купівлі нерухомості. Ми гарантуємо нашим клієнтам, що знайдемо найкращу власність за найкращою ціною.',
  },
];

export const aboutText =
  "Якщо ви готові купити, продати або орендувати нерухомість, або якщо вас цікавить дізнатися більше про наші послуги, зв'яжіться з нами сьогодні. Наша команда експертів готова допомогти вам на кожному кроці.";