import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { Breakpoints } from '../../theme/Theme.constants';
import {
  LandscapeDesignPageWrapper,
  ServicesSectionWrapper,
  BenefitsSectionWrapper,
} from './LandscapeDesignPage.styled';
import { ReactComponent as Analyse } from '../../shared/assets/icons/landscape-design/analyse.svg';
import { ReactComponent as Lightbulb } from '../../shared/assets/icons/landscape-design/lightbulb.svg';
import { ReactComponent as Screwdriver } from '../../shared/assets/icons/landscape-design/screwdriver.svg';
import { ReactComponent as Wrench } from '../../shared/assets/icons/landscape-design/wrench.svg';
import { ProcessBlock } from '../../shared/components/ProcessBlock';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { BenefitsSection } from './BenefitsSection/BenefitsSection';


import { SliderSection } from '../../shared/components/PagesSliderSection';
import firstSliderImg from '../../shared/assets/images/landscape-design/slider-img-1.jpg';
import secondSliderImg from '../../shared/assets/images/landscape-design/slider-img-2.jpg';
import thirdSliderImg from '../../shared/assets/images/landscape-design/slider-img-3.jpg';
export const LandscapeDesignPage: FC = () => {
  const menuItems = getMenuItems();
  const landscapeInfo = menuItems[10];
  const { t } = useTranslation();

  const navItems = [
    {
      title: t('landscapeDesignPage.landscapeDesignNavItems.services'),
      sectionId: 'services-section',
    },
    {
      title: t('landscapeDesignPage.landscapeDesignNavItems.benefits'),
      sectionId: 'benefits-section',
    },
    {
      title: t('landscapeDesignPage.landscapeDesignNavItems.stages'),
      sectionId: 'stages-section',
    },
  ];

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });

  const isTablet = useMediaQuery({
    query: ` (min-width: ${Breakpoints.md}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

 

  const slides = [
    { id: 1, img: firstSliderImg },
    { id: 2, img: secondSliderImg },
    { id: 3, img: thirdSliderImg },
    // { id: 4, img: currentSlideImg },
  ];

  const processList = [
    {
      number: 1,
      label: t('landscapeDesignPage.landscapeProcessList.labelFirst'),
      Icon: isTablet ? Analyse : null,
      description: t(
        'landscapeDesignPage.landscapeProcessList.descriptionFirst',
      ),
    },
    {
      number: 2,
      label: t('landscapeDesignPage.landscapeProcessList.labelSecond'),
      Icon: isTablet ? Lightbulb : null,
      description: t(
        'landscapeDesignPage.landscapeProcessList.descriptionSecond',
      ),
    },
    {
      number: 3,
      label: t('landscapeDesignPage.landscapeProcessList.labelThird'),
      Icon: isTablet ? Screwdriver : null,
      description: t(
        'landscapeDesignPage.landscapeProcessList.descriptionThird',
      ),
    },
    {
      number: 4,
      label: t('landscapeDesignPage.landscapeProcessList.labelFourth'),
      Icon: isTablet ? Wrench : null,
      description: t(
        'landscapeDesignPage.landscapeProcessList.descriptionFourth',
      ),
    },
  ];
  const currentTexts = {
    label: t('landscapeDesignPage.landscapeCurrentTexts.label'),
    description: t('landscapeDesignPage.landscapeCurrentTexts.description'),
  };
  const currentTextsBenefits = {
    label: t('landscapeDesignPage.landscapeDesignNavItems.benefits'),
    description: t('landscapeDesignPage.currentTextsBenefits.description'),
  };
  return (
    <LandscapeDesignPageWrapper>
      <PageHeaderSection
        logoImg={landscapeInfo.icon}
        title={landscapeInfo.label}
        navItems={navItems}
        bgImage={landscapeInfo.backgroundImg}
        description={t('landscapeDesignPage.landscapeDesignDescription')}
        descriptionColor={isDesktop ? '#F1F2F6' : ''}
        id={landscapeInfo.id}
      />

      <ServicesSectionWrapper id="services-section">
        <ProcessBlockHeader
          currentTexts={currentTexts}
          isTabletPadding
          isTextAlignCenter={isMobile}
          descriptionPadding={isDesktop ? '60px' : isTablet ? '20px 85px' : ''}
          descriptionColor={'rgba(255, 255, 255, 0.7)'}
          descriptionMaxWidth={isDesktop ? '545px' : ''}
          titleSize={isDesktop ? '50px' : ''}
        />
        <ProcessBlock processList={processList} />
      </ServicesSectionWrapper>

      <BenefitsSectionWrapper id="benefits-section">
        {isTablet && (
          <ProcessBlockHeader
            currentTexts={currentTextsBenefits}
            isTabletPadding
            isTextAlignCenter
            descriptionPadding={
              isDesktop ? '60px' : isTablet ? '20px 85px' : ''
            }
            descriptionColor={'rgba(255, 255, 255, 0.7)'}
            descriptionMaxWidth={isDesktop ? '340px' : ''}
            titleSize={isDesktop ? '50px' : ''}
            titlePadding={isDesktop ? '60px 100px' : ''}
          />
        )}
        <BenefitsSection />
      </BenefitsSectionWrapper>
      <SliderSection slides={slides} />
    </LandscapeDesignPageWrapper>
  );
};
