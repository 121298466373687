import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const InvestmentsPageWrapper = styled.div`
  width: 100vw;
  max-width: 1920px;
  margin: 0 auto;
  background: ${colorVariables.alternativeBackground};
`;

export const InvestmentsPhilosophyWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    margin-bottom: 4rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin-bottom: 13.4375rem;
  }
`;
