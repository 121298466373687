import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';

export const ProcessBlockWrapper = styled.div`
  padding-bottom: 3.75rem;
  background: ${colorVariables.alternativeBackground};

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0rem 2.5rem 2.5rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0rem 0rem 7.5rem 0rem;
  }
`;

export const ProcessListWrapper = styled.div`
  @media (min-width: ${Breakpoints.xlg}px) {
    margin: 0rem 6.25rem;
  }
`;

export const ProcessList = styled.ul`
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem 1.25rem;
  }
`;

export const ProcessItem = styled.li`
  border-bottom: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};

  @media (min-width: ${Breakpoints.md}px) {
    border-width: 0rem 0.0625rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: ${colorVariables.aboutUsOpacityPart};
  }

  @media (max-width: ${Breakpoints.xlg}px) {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    flex-basis: calc(50% - 0.625rem);
  }
`;

export const ProcessItemTopWrapper = styled.div`
  display: flex;
  border-width: 0.0625rem 0rem;
  border-style: solid;
  border-color: ${colorVariables.aboutUsOpacityPart};
`;

export const ProcessNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;

  background: ${colorVariables.processNumberBackground};
  border-right: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
  margin-right: 1.25rem;
`;

export const ProcessNumber = styled.span`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;

  color: ${colorVariables.mainTextColor};
`;

export const ProcessItemTitle = styled.p`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  padding: 1.25rem 0rem 1.25rem 0rem;

  color: rgba(255, 255, 255, 0.9);
`;

export const ProcessItemDescription = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;

  color: ${colorVariables.footerReservedTitle};
  opacity: 0.8;

  padding: 1.25rem;

  @media (max-width: ${Breakpoints.md}px) {
    text-align: center;
  }

  @media (min-width: ${Breakpoints.md}px) {
    max-width: 43.125rem;
  }
`;
