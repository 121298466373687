export const servicesTexts = {
  label: `Nos services d'énergie solaire `,
  description: `Nous proposons une large gamme de services de décoration d'intérieur pour vous aider à créer l'espace de vos rêves. Du concept initial et de l'aménagement de l'espace à la sélection des couleurs, en passant par la recherche de meubles et l'accessoirisation, notre équipe peut prendre en charge tous les aspects de votre projet. Nos services comprennent`,
};
export const servicesList = {
  textFirst: 'Consultation et évaluation ',

  textSecond: 'Conception et proposition ',

  textThird: 'Financement et mesures incitatives ',

  textFourth: 'Permis et approbations ',

  textFifth: "l'installation",

  textSixth: 'Activation et maintenance du système',
};
