export const currentTexts = {
  label: 'Additional Options',
  description:
    'Until recently, modular houses were used mainly for situational needs, such as temporary housing, country houses. But modern technologies do not stand still and continue to develop. Currently, ready-made and affordable houses made from block containers have enough characteristics to provide comfortable living. And we are ready to offer you additional services that will help improve the territory of the modular house.',
};

export const options = {
  titleSwimmingPools: 'Swimming pools',
  describtionSwimmingPools:
    'For many of us, the sea has always been a source of dreams and relaxation. However, being able to build your own modular backyard pool was an amazing opportunity to make those dreams come true, even if the Atlantic Ocean is far away. Thanks to us, the construction of swimming pools turns from an unattainable dream into an accessible reality. The presence of an artificial reservoir next to a country house has become a common and natural addition to the surrounding landscape.',

  titleSmartHome: 'Smart home',
  describtionSmartHome:
    "A smart home is your personal assistant who takes care of your comfort and coziness. The system can be installed even in a modular home. To control the system, all you need is a tablet, phone or computer. Using a unique secure protocol and mobile application, the complex gives you full control over your space at any time. You don't even have to get out of bed to put the kettle on.",

  titleSolarEnergy: 'Solar energy',
  describtionSolarEnergy:
    'The sun is our main source of energy and life on Earth. The use of solar energy is the first step towards awareness of environmental problems. We are happy to be with you on this journey, to help with advice and installation of panels on the roofs of your modular house.',
};
