import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';

export const PoolsSectionWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${colorVariables.blockBorderColor};
  z-index: 10;
  @media only screen and (min-width: ${Breakpoints.md}px) {
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    margin: 5rem 0;
  }
`;

export const PoolsInfoBlockWrapper = styled.div`
  padding: 0;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0 ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 ${paddings.desktopSidePadding};
  }
`;

export const PoolsInfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-right: 1px solid ${colorVariables.blockBorderColor};
    border-left: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const PoolsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.75rem;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  text-transform: uppercase;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    border-right: 1px solid ${colorVariables.blockBorderColor};
    border-bottom: none;
    font-size: 3.125rem;
    line-height: 3.75rem;
  }
`;

export const PoolsDescription = styled.div`
  ont-family: 'Overpass';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 1rem 1.25rem;
  backdrop-filter: blur(10px);
  color: rgba(255, 255, 255, 0.7);

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 1rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 1rem;
  }
`;

export const PoolsCardsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${colorVariables.blockBorderColor};
  padding: 0 ${paddings.mobileSidePadding};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0 ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 ${paddings.desktopSidePadding};
  }
`;

export const PoolsNavigation = styled.div`
  padding: 3.75rem 0;
   
`;

export const PoolsCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 1.25rem;
  margin-bottom: 1.875rem;
`;

export const PoolCard = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 2.5rem) / 3);
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  width: 100%;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: calc((100% - 1.25rem) / 2);
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    width: calc((100% - 2.5rem) / 3);
  }
`;

export const PoolImageBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 55px 35px;
  backdrop-filter: blur(10px);
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const PoolInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 1.25rem;
  height: 4.06rem;
  
  background: ${colorVariables.alternativeBackground};
  border-top: 1px solid ${colorVariables.processBlockHeaderBorderColor};

`;

export const PoolTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
`;

export const PoolInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
`;

export const PoolInfoItemsBlock = styled.div`
  display: flex;
  gap: 1.375rem;
`;

export const PoolInfoItem = styled.div``;
