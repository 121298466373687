export const aboutListLocation = 'Catalonia, Spain';

export const infoText = 'Stunning Sunny Spanish Villa';

export const mainText =
  'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.';

export const headerBtnText = 'More about Interior';

export const titleAbout = 'About the project';

export const textsAbout = {
  text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
};

export const textPhoto =
  'Smart home accessories are designed to make your home more convenient, efficient, and enjoyable. From smart thermostats and lighting systems to voice-activated assistants and security cameras, there are a variety of accessories available to help you automate and control different aspects of.';

export const view = 'view';


export const housesDetailsMobile = [
  {
    number: 1,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 1',
  },
  {
    number: 2,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 2',
  },
  {
    number: 3,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 3',
  },
  {
    number: 4,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 4',
  },
  {
    number: 5,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 5',
  },
];

export const housesDetailsDesktop = [
  {
    number: 1,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 1',
  },
  {
    number: 2,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 2',
  },
  {
    number: 3,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 3',
  },
  {
    number: 4,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 4',
  },
  {
    number: 5,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 5',
  },

  {
    number: 6,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 1',
  },
  {
    number: 7,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 2',
  },
  {
    number: 8,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 3',
  },
  {
    number: 9,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 4',
  },
  {
    number: 10,
    text: 'Once the house has been granted a completion certificate by the council and approved by your agent, you can lie back on your carefully chosen sofa and admire your wonderful new home. The defects period - where the contractor is obliged to make good any snags - will normally last 12 months. 5',
  },
];


export const photoTitle = 'Photo'