import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from './api.constants';

export const leadFormQuestionsApi = createApi({
  reducerPath: 'leadFormQuestionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: builder => ({
    getQuestions: builder.query({
      query: language => ({
        url: `lead_forms/questions/?lang=${language}`,
      }),
    }),
  }),
});

export const { useGetQuestionsQuery } = leadFormQuestionsApi;
