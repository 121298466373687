import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from './api.constants';

export const formsApi = createApi({
  reducerPath: 'formsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: builder => ({
    sendClientForm: builder.mutation({
      query: body => ({
        url: `lead_forms/client/`,
        method: 'POST',
        body,
      }),
    }),
    sendPartnerForm: builder.mutation({
      query: body => ({
        url: `lead_forms/partner/`,
        method: 'POST',
        body,
      }),
    }),
    sendMainPageForm: builder.mutation({
      query: body => ({
        url: `lead_forms/leadform/`,
        method: 'POST',
        body,
      }),
    }),
    sendSubscribe: builder.mutation({
      query: body => ({
        url: `lead_forms/subscribe/`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSendClientFormMutation,
  useSendPartnerFormMutation,
  useSendMainPageFormMutation,
  useSendSubscribeMutation,
} = formsApi;
