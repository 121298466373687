import React, { FC } from 'react';
import {
  InfoBlock,
  Service,
  ServicesBlock,
  ServicesPageWrapper,
} from './ServicesPage.styled';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Services } from '../../shared/assets/icons/services/services.svg';
import { MenuItemProps } from '../../components/MenuSection/MenuSection.types';
import { useNavigate } from 'react-router-dom';
import { mainSection } from '../../shared/helpers/globalConstants';
import { RoutePaths } from '../../shared/helpers/routes';

export const ServicesPage: FC = () => {
  const menuItems = getMenuItems();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navItems = [
    {
      title: t('services.MainSection.сonstruction'),
      link: RoutePaths.constructionPage,
    },
    // {
    //   title: t('services.MainSection.projects'),
    //   link: RoutePaths.projectsPage,
    // },
    {
      title: t('services.MainSection.interior'),
      link: RoutePaths.interiorPage,
    },
    {
      title: t('services.MainSection.modularHouses'),
      link: RoutePaths.modularHousesPage,
    },
    {
      title: t('services.MainSection.architect'),
      link: RoutePaths.architectPage,
    },
    {
      title: t('services.MainSection.consulting'),
      link: RoutePaths.consultingPage,
    },
    {
      title: t('services.MainSection.solarEnergy'),
      link: RoutePaths.solarEnergyPage,
    },
    {
      title: t('services.MainSection.inmobiliaria'),
      link: RoutePaths.realEstatePage,
    },
    {
      title: t('services.MainSection.investments'),
      link: RoutePaths.investmentsPage,
    },
    {
      title: t('services.MainSection.swimmingPools'),
      link: RoutePaths.swimmingPools,
    },
    {
      title: t('services.MainSection.smartHome'),
      link: RoutePaths.smartHome,
    },
    {
      title: t('services.MainSection.landscapeDesign'),
      link: RoutePaths.landscapeDesignPage,
    },
    {
      title: t('services.MainSection.buildingMaterials'),
      link: RoutePaths.buildingMaterialsPage,
    },
  ];

  const handleServiceClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <ServicesPageWrapper>
      <PageHeaderSection
        title={t('services.MainSection.services')}
        logoImg={<Services />}
        navItems={navItems}
        withoutTitleBlock
        isServices
      />
      <ServicesBlock>
        {menuItems.map((menuItem: MenuItemProps) => (
          <Service
            backgroundImage={menuItem.backgroundImg}
            key={menuItem.id}
            onClick={() => handleServiceClick(menuItem.routePath)}>
            <InfoBlock>{menuItem.label}</InfoBlock>
          </Service>
        ))}
      </ServicesBlock>
    </ServicesPageWrapper>
  );
};
