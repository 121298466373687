import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { ProcessBlock } from '../../shared/components/ProcessBlock';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import {
  ArchitectPageWrapper,
  ProcessSectionWrapper,
  PortfolioSectionWrapper,
  PortfolioTabletHeader,
  PortfolioLogoWrapper,
  PortfolioLogo,
  PortfolioTitle,
} from './ArchitectPage.styled';
import portfolioLogo from '../../shared/assets/icons/architect/portfolio-logo.svg';
import { PortfolioSection } from './PortfolioSection';
import architectSliderImg from '../../shared/assets/images/architect/architect-slider.jpg';
import { SliderSection } from '../../shared/components/PagesSliderSection';
import { useTranslation } from 'react-i18next';
import { StepsBlockComponent } from '../../shared/components/StepsBlock/StepsBlock';
import architectBg from '../../shared/assets/images/architect/architect-bg.jpg';
import { ProcessSection } from './ProcessSection';

export const ArchitectPage: FC = () => {
  const menuItems = getMenuItems();
  const architectInfo = menuItems[9];
  const { t } = useTranslation();
  const navItems = [
    // for future feat*/
    // {
    //   title: t('architectPage.architectNavItems.portfolio'),
    //   sectionId: 'portfolio-section',
    // },
    {
      title: t('architectPage.architectNavItems.process'),
      sectionId: 'process-section',
    },
  ];
  const currentTexts = {
    label: t('architectPage.currentTexts.label'),
    description: t('architectPage.currentTexts.description'),
  };
  const currentPortfolioTexts = {
    label: t('architectPage.currentPortfolioTexts.label'),
    description: '',
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTabletMaxWidth = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px) and (max-width: ${
      Breakpoints.lg - 1
    }px)`,
  });

  const slides = [
    { id: 1, img: architectSliderImg },
    // { id: 2, img: architectSliderImg },
    // { id: 3, img: architectSliderImg },
    // { id: 4, img: architectSliderImg }, for future features
  ];
  const constructionStepsData = [
    {
      id: 1,
      title: t('architectPage.architectStepSection.stepOne.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepOne.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepOne.p2')}</p>
        </>
      ),
    },
    {
      id: 2,
      title: t('architectPage.architectStepSection.stepTwo.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepTwo.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepTwo.p2')}</p>
        </>
      ),
    },
    {
      id: 3,
      title: t('architectPage.architectStepSection.stepThree.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepThree.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepThree.p2')}</p>
        </>
      ),
    },
    {
      id: 4,
      title: t('architectPage.architectStepSection.stepFour.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepFour.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepFour.p2')}</p>
        </>
      ),
    },
    {
      id: 5,
      title: t('architectPage.architectStepSection.stepFive.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepFive.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepFive.p2')}</p>
        </>
      ),
    },
    {
      id: 6,
      title: t('architectPage.architectStepSection.stepSix.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepSix.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepSix.p2')}</p>
        </>
      ),
    },
    {
      id: 7,
      title: t('architectPage.architectStepSection.stepSeven.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepSeven.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepSeven.p2')}</p>
        </>
      ),
    },
    {
      id: 8,
      title: t('architectPage.architectStepSection.stepEight.title'),
      description: (
        <>
          <p>{t('architectPage.architectStepSection.stepEight.p1')}</p>
          <br />
          <p>{t('architectPage.architectStepSection.stepEight.p2')}</p>
        </>
      ),
    },
  ];
  return (
    <ArchitectPageWrapper>
      <PageHeaderSection
        title={architectInfo.label}
        logoImg={architectInfo.icon}
        navItems={navItems}
        description={t('architectPage.architectSecondDescription')}
        bgImage={architectBg}
      />

      <ProcessSectionWrapper id="process-section">
        <ProcessBlockHeader
          currentTexts={currentTexts}
          isTabletPadding
          titleSize={isTabletMaxWidth ? '30px' : ''}
        />
        <ProcessSection />
      </ProcessSectionWrapper>

      {/* <PortfolioSectionWrapper id="portfolio-section">
        {isMobile ? (
          <ProcessBlockHeader
            currentTexts={currentPortfolioTexts}
            withoutDescription
          />
        ) : (
          <PortfolioTabletHeader>
            <PortfolioLogoWrapper>
              <PortfolioLogo src={portfolioLogo} />
            </PortfolioLogoWrapper>
            <PortfolioTitle>
              {t('architectPage.currentPortfolioTexts.label')}
            </PortfolioTitle>
          </PortfolioTabletHeader>
        )}
        <PortfolioSection />
      </PortfolioSectionWrapper>    /// for future feat*/}

      <StepsBlockComponent
        stepsData={constructionStepsData}
        blockTitle={t('architectPage.architectStepSection.blockTitle')}
        description={t('architectPage.architectStepSection.descriptionTitle')}
      />
      <SliderSection slides={slides} />
    </ArchitectPageWrapper>
  );
};
