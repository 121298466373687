import React, { FC } from 'react';
import {
  AboutSectionBgWrapper,
  AboutElWrapper,
  AboutItem,
  AboutIcon,
  AboutText,
  AboutSectionWrapper,
} from './RealEstateAboutSection.styled';
import people from '../../shared/assets/icons/real-estate/people.svg';
import handshake from '../../shared/assets/icons/real-estate/handshake.svg';
import microchip from '../../shared/assets/icons/real-estate/microchip.svg';
import clipboard from '../../shared/assets/icons/real-estate/clipboard.svg';
import book from '../../shared/assets/icons/real-estate/book.svg';
import { useTranslation } from 'react-i18next';

export const RealEstateAboutSection: FC = () => {
  const {t} = useTranslation()
  const aboutList = [
    {
      id: 1,
      icon: people,
      text: t('realEstatePage.aboutListText.peopleText'),
    },

    {
      id: 2,
      icon: book,
      text: t('realEstatePage.aboutListText.bookText'),
    },

    {
      id: 3,
      icon: clipboard,
      text: t('realEstatePage.aboutListText.clipboardText'),
    },

    {
      id: 4,
      icon: microchip,
      text: t('realEstatePage.aboutListText.microchipText'),
    },

    {
      id: 5,
      icon: handshake,
      text: t('realEstatePage.aboutListText.handshakeText'),
    },
  ];

  return (
    <AboutSectionWrapper>
      <AboutSectionBgWrapper>
        <AboutElWrapper>
          {aboutList.map(({ id, text, icon }) => {
            return (
              <AboutItem key={id}>
                <AboutIcon src={icon} />
                <AboutText className="about-text" isFourth={id === 4}>
                  {text}
                </AboutText>
              </AboutItem>
            );
          })}
        </AboutElWrapper>
      </AboutSectionBgWrapper>
    </AboutSectionWrapper>
  );
};
