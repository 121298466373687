export const discoverList = {
  labelFirst: 'Nom',

  labelSecond: 'Nom',

  labelThird: 'Nom',
};

export const realEstateDiscoverTexts = {
  label: 'Descobreix la teva pròpia parcel·la de terreny avui',
  description:
    'Oferim una àmplia gamma de parcel·les de terreny per a qualsevol necessitat i pressupost. Si has estat somiant amb ser amo del teu propi terreny, no busquis més. La nostra selecció inclou des de petites parcel·les urbanes fins a vastes extensions rurals. Explora la nostra col·lecció i troba la parcel·la perfecta per als teus somnis.',
};

export const aboutDiscoverList = [
  {
    id: 1,
    label: 'Aprop de',
    text: `Som una empresa amb més de 50 anys d'experiència en la indústria immobiliària. Ens especialitzem en el desenvolupament, construcció i venda d'immobles a Espanya. El nostre objectiu és ajudar els nostres clients a trobar la propietat perfecta per a les seves necessitats.`,
  },
  {
    id: 2,
    label: `Venda d' immobles`,
    text: `Oferim una àmplia gamma de propietats en venda a tot Espanya. Els nostres serveis inclouen la recerca de la propietat perfecta, la realització d'inspeccions i la provisió d'assessorament sobre la compra d'immobles. Garantim als nostres clients que trobarem la millor propietat al millor preu.`,
  },
];

export const aboutText =
  `Si està llest per comprar, vendre o llogar una propietat, o si està interessat a saber més sobre els nostres serveis, poseu-vos en contacte amb nosaltres avui mateix. El nostre equip d'experts està llest per ajudar-lo en cada pas del camí.`;