export const teamTexts = {
  label: `El nostre equip d'inversió`,
  description: `A Modernacasa creiem que la nostra gent és el nostre major actiu. El nostre equip d'inversió està format per professionals experimentats amb una trajectòria d'èxit en el sector.`,
};

export const teamBlocks = {
  labelFirst: `Analistes d'inversió`,
  descriptionFirst: `Els nostres analistes d'inversions duen a terme una investigació i una anàlisi en profunditat de les possibles oportunitats d'inversió`,

  labelSecond: 'Gestors de carteres',

  descriptionSecond: `Els nostres gestors de cartera s'encarreguen de construir i gestionar les nostres carteres d'inversió.`,

  labelThird: 'Gestors de risc',

  descriptionThird: `Els nostres gestors de riscos col·laboren estretament amb el nostre equip d'inversió per identificar i gestionar eficaçment els riscos.`,
};
