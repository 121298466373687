export const interiorNavItems = {
  services: 'Послуги',
  portfolio: 'Портфоліо',
};
export const description =
  "Дизайн інтер'єру - це не тільки творчість, а й цілий комплекс послуг, який перетворює звичайні квадратні метри на затишок і гармонію. Якісний інтер'єр з продуманими малюнками, технічними компонентами, кольорами та екологічними меблями допоможе створити комфортний і функціональний простір, який буде радувати вас і ваших близьких довгі роки. Це інвестиція у ваше благополуччя і зручність, яка здатна підвищити настрій і працездатність, зменшити стрес і поліпшити якість життя. І ми знаємо ідеальну формулу.";
export const currentText = {
  label: `Наші послуги з дизайну інтер'єру`,
  description:
    "Розробка концепції інтер'єру - це не тільки творчий процес, але й багатогранний, який включає в себе безліч етапів. Ми готові стати для вас надійним провідником і супроводжувати на кожному етапі реалізації: від красивої картинки в голові до втілення ваших найсміливіших мрій в реальність. Етапи включають в себе:",
};

export const currentPortfolioText = {
  description:
    'Погляньте на деякі з наших минулих проектів, щоб побачити діапазон стилів і дизайнерських рішень, які ми створили для наших клієнтів. Якщо ви шукаєте сучасний, традиційний або щось середнє між ними, ми допоможемо вам досягти бажаного вигляду та відчуття.',
};

export const servicesList = {
  planningText: 'Розробка концепції дизайну',
  colorText: 'Створення планувальних рішень та ескізів',
  furnitureText: 'Підбір матеріалів та елементів оздоблення',
  designText: 'Розробка електричних та освітлювальних схем',
  windowText: ' Створення 3D візуалізації',
  artText: 'Комплектація меблями, фурнітурою та декором',
};
