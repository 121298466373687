export const architectStepBlockTitle = `Etapes de l' Arquitectura`;
export const architectStepBlockDescription = 'Consulta inicial';

export const architectStepSection = {
  blockTitle: `Etapes de l' Arquitectura`,
  descriptionTitle: 'Consulta inicial',
  stepOne: {
    title: 'Pas u',
    p1: `Coneixement. L' arquitecte coneix més de prop el client i la propietat o terreny. En aquesta fase inicial, l' arquitecte es comunica amb el futur client, esbrina les seves necessitats, requisits i expectatives del futur edifici.`,
    p2: `Tots els matisos més subtils són importants: l'estil de vida del client, la presència de nens o animals, les preferències individuals. També estudia la ubicació de l' objecte, analitza el context local i l' entorn per determinar els factors que afecten el disseny de l' edifici.`,
  },

  stepTwo: {
    title: 'Pas dos',
    p1: `Finalitat de l' objecte. L' arquitecte determina les principals preferències del client quant al disseny i la funcionalitat de l' edifici. Això pot incloure la definició del tipus d'edifici (residencial, comercial, públic) i l'estil de disseny (clàssic, modern, minimalista).`,
    p2: `Si l'objecte s'està construint per a lloguer diari, probablement, no caldrà prestar tanta atenció a la col·locació i funcionalitat de la cuina.`,
  },
  stepThree: {
    title: 'Pas tres',
    p1: `Estudi del lloc. Aquí l'arquitecte coneix el territori per comprendre les seves limitacions i possibilitats.`,
    p2: `Això pot incloure l'anàlisi de la topografia, la composició del sòl i les condicions climàtiques, així com la comprovació de la disponibilitat de comunicacions i infraestructures.`,
  },
  stepFour: {
    title: 'Pas quatre',
    p1: `Anàlisi del component jurídic. Determinació de la possibilitat de resoldre problemes de construcció o reconstrucció en el marc de la legislació i les normes de disseny vigents.`,
    p2: `En aquesta fase, l'arquitecte determina fins a quin punt el projecte és realista i viable, tenint en compte els requisits de la legislació i les normes de disseny. També determina el cost i la quantitat de recursos necessaris per a la seva realització.`,
  },
  stepFive: {
    title: 'Pas cinc',
    p1: `Treball amb documentació. Presentació de documents i obtenció de permisos de construcció o reconstrucció, obtenció d' especificacions tècniques dels propietaris de xarxes externes de subministrament d' aigua, gasoductes i xarxes elèctriques.`,
    p2: ``,
  },
  stepSix: {
    title: 'Pas sis',
    p1: `Solucions funcionals. El desenvolupament d' un concepte arquitectònic és el següent pas després d' obtenir els permisos i desitjos del client. En aquesta fase, l' arquitecte crea una idea general de l' objecte que es dissenyarà, la qual cosa inclou determinar la zonificació funcional i crear un esquema espacial.`,
    p2: `Crear un concepte permet avaluar el potencial de l'objecte i comprendre com satisfarà els desitjos del client en l'aspecte funcional de la qüestió. Se sentirà còmode vivint en una casa amb la seva família?, Com de popular serà l'objecte per llogar-lo?`,
  },
  stepSeven: {
    title: 'Pas set',
    p1: `Desenvolupament d' un disseny preliminar. L' arquitecte determina les solucions arquitectòniques, constructives, funcionals i artístiques per a l' objecte de construcció o reconstrucció.`,
    p2: `Un esbós de disseny permet al client veure l'aspecte general de l'objecte, avaluar la conformitat visual i si es correspon amb els seus desitjos.`,
  },
  stepEight: {
    title: 'Pas vuit',
    p1: `Desenvolupament d' un esborrany de treball. Es tracta de la fase final del disseny arquitectònic, que inclou un paquet complet de documentació del projecte. Com a part del desenvolupament de l' esborrany de treball, l' especialista té en compte la secció arquitectònica, la secció estructural, el clavegueram, la fontaneria, l' equipament elèctric, la ventilació, l' aire condicionat, la calefacció i altres components.`,
    p2: `El projecte de treball conté una descripció detallada de totes les solucions constructives, tècniques i d'enginyeria necessàries per a la construcció o reconstrucció de la instal·lació.`,
  },
};
