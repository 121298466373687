import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';
// import bgImage from '../../../shared/assets/images/landscape-design/benefits-mobile-img.jpg';
// import bgImageTablet from '../../../shared/assets/images/landscape-design/benefits.tablet.jpg';
import bgImage from '../../../shared/assets/images/landscape-design/slider-img-2.jpg';
export const BenefitsWrapper = styled.div`
  @media (max-width: ${Breakpoints.xlg}px) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3.75rem 0;
    @media (min-width: ${Breakpoints.md}px) {
      background-image: url(${bgImage});
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(23, 23, 25, 0.3);
    }
    @media (min-width: ${Breakpoints.md}px) {
      padding: 2.5rem 0;
    }
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
  }
`;

export const List = styled.ul`
  z-index: 10;

  @media (max-width: ${Breakpoints.md}px) {
    width: 100%;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin-left: 6.25rem;
    margin-right: 2.5rem;
  }
`;

export const Item = styled.li`
  background: rgba(23, 23, 25, 0.1);
  padding: 1.875rem 0.625rem;
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  backdrop-filter: blur(0.625rem) !important;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem 3.125rem;

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
    justify-content: center;
    padding: 1.875rem 0;
    width: 27.812rem;
  }
`;

export const Text = styled.p`
  font-family: 'Overpass';
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 25px;
  text-align: center;

  text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);

  @media (min-width: ${Breakpoints.xlg}px) {
    max-width: 18.75rem;
  }
`;

export const Img = styled.div`
  position: relative;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

 
`;
