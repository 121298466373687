export const HeaderSection = {
  pools: 'Piscinas',
  colors: 'Colores',
  technology: 'Tecnología',
  accessories: 'Accesorios',
  stages: 'Etapas',
  order: 'Solicitar',
   description: "¿Desea añadir un toque de lujo a su hogar? Considere las ventajas de una piscina instalada. Las piscinas residenciales están disponibles en una gran variedad de estilos y tamaños, lo que las convierte en una gran opción para cualquier hogar. Pueden proporcionar un espacio divertido y relajante para que se reúnan familiares y amigos, al tiempo que aumentan el valor de su propiedad. Tanto si busca una piscina pequeña y sencilla como una grande y elaborada, una piscina instalada puede transformar su jardín en un impresionante oasis.",
};

