import React, { FC, useState } from 'react';
import {
  NavBarWrapper,
  MenuBlockWrapper,
  LeftPartWrapper,
  SocialBlockWrapper,
  NetworkIcon,
  ContactsBlockWrapper,
  ContactsName,
  PrivacyBlockWrapper,
  PrivacyText,
  TopPartWrapper,
  BottomPartWrapper,
  FormWrapper,
  FormTitle,
  SendIcon,
  SendBtn,
  ReservedBlock,
  ReservedTitle,
  MenuName,
  MenuNameLinkWrapper,
  NetworkIconWrapper,
  TextFieldWrapper,
  ErrorMesage,
  PopupWrapper,
  Popup,
  PopupText,
} from './FooterMobileBlock.styled';

import { FooterSectionProps } from '../Footer.types';
import { getMenuItems } from '../../MenuSection/MenuSection.constatnts';
import { getFooterNavBar } from '../FooterSection.constatnts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mainSection } from '../../../shared/helpers/globalConstants';
import { RoutePaths } from '../../../shared/helpers/routes';
import { useSendSubscribeMutation } from '../../../api';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { TextFieldInput } from '../../../shared/components/TextFieldInput';
import { ReactComponent as IncorrectIcon } from '../../../shared/assets/icons/lead-forms/incorrect-icon.svg';
import { ReactComponent as CorrectIcon } from '../../../shared/assets/icons/lead-forms/correct-icon.svg';
import { BluredButton } from '../../../shared/components/BluredButton';

export interface FormValuesProps {
  email: string;
}

export const FooterMobileBlock: FC<FooterSectionProps> = ({
  instagram,
  pinterest,
  whatsUp,
  youtube,
  send,
}) => {
  const { t } = useTranslation();
  const menuItems = getMenuItems();
  const footerNavBar = getFooterNavBar(false);
  const navigate = useNavigate();
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const [sendSubscribeMutation, { isError: subscribeError }] =
    useSendSubscribeMutation();

  const formInitialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(`${t('leadForm.leadFormPage.mainEmailError')}`)
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        `${t('leadForm.leadFormPage.secondaryEmailError')}`,
      )
      .matches(
        /^[a-zA-Z0-9.@]+$/,
        `${t('leadForm.leadFormPage.thirdEmailError')}`,
      ),
  });

  const handleMenuItemClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  const handleSubmit = async (
    formValues: FormValuesProps,
    { resetForm, setErrors }: any,
  ) => {
    try {
      await sendSubscribeMutation({
        email: formValues.email,
      });

      setIsSubmitForm(true);

      resetForm({
        values: formInitialValues,
      });
    } catch (error: any) {
      const apiErrors = error?.response?.data?.errors || {};
      setErrors(apiErrors);
    }
  };

  const handleGoBack = () => {
    setIsSubmitForm(false);
  };

  return (
    <>
      <TopPartWrapper>
        <LeftPartWrapper>
          <NavBarWrapper>
            {footerNavBar.map(({ id, label, link }) => {
              return (
                <MenuNameLinkWrapper key={id}>
                  <MenuName onClick={() => handleMenuItemClick(link)}>
                    {label}
                  </MenuName>
                </MenuNameLinkWrapper>
              );
            })}
          </NavBarWrapper>

          <SocialBlockWrapper>
            <NetworkIconWrapper
              href="https://wa.me/+34600592007"
              target="_blank"
              rel="noreferrer">
              <NetworkIcon src={whatsUp} alt="whats-up-icon" />
            </NetworkIconWrapper>
            <NetworkIconWrapper
              href="https://www.instagram.com/modernacasa.es/"
              target="_blank"
              rel="noreferrer">
              <NetworkIcon src={instagram} alt="instagram-icon" />
            </NetworkIconWrapper>

            <NetworkIconWrapper
              href="https://pin.it/2iuHA5D"
              target="_blank"
              rel="noreferrer">
              <NetworkIcon src={pinterest} alt="pinterest-icon" />
            </NetworkIconWrapper>
            <NetworkIconWrapper
              href="https://www.youtube.com/channel/UCI7kAr6wujbQCErOuHvpcYw"
              target="_blank"
              rel="noreferrer">
              <NetworkIcon src={youtube} alt="youtube-icon" />
            </NetworkIconWrapper>
          </SocialBlockWrapper>
          <ContactsBlockWrapper>
            <ContactsName
              href="mailto:info@modernacasa.es"
              target="_blank"
              rel="noreferrer">
              {t('mainPage.ContactsTitles.email')}
            </ContactsName>
            <ContactsName
              href={`tel:${t('mainPage.ContactsTitles.telNumber')}`}
              target="_blank"
              rel="noreferrer">
              {t('mainPage.ContactsTitles.telNumber')}
            </ContactsName>
          </ContactsBlockWrapper>

          <PrivacyBlockWrapper>
            <PrivacyText
              onClick={() => handleMenuItemClick(RoutePaths.conditionsPage)}>
              {t('mainPage.footerTitles.conditions')}
            </PrivacyText>
            <PrivacyText
              onClick={() => handleMenuItemClick(RoutePaths.privacyPolicyPage)}>
              {t('mainPage.footerTitles.privacy')}
            </PrivacyText>
          </PrivacyBlockWrapper>
        </LeftPartWrapper>
        <MenuBlockWrapper>
          {menuItems.slice(0, -1).map(({ id, label, routePath }) => {
            return (
              <MenuNameLinkWrapper key={id}>
                <MenuName onClick={() => handleMenuItemClick(routePath)}>
                  {label}
                </MenuName>
              </MenuNameLinkWrapper>
            );
          })}
        </MenuBlockWrapper>
      </TopPartWrapper>

      <BottomPartWrapper>
        <FormTitle>{t('mainPage.footerTitles.updated')}</FormTitle>
        <FormWrapper>
          <Formik
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            {({
              isSubmitting,
              resetForm,
              errors,
              values,
              handleChange,
              touched,
            }) => {
              return (
                <Form className="form">
                  <TextFieldWrapper>
                    <TextFieldInput
                      isError={errors.email}
                      onChange={handleChange}
                      value={values.email}
                      type="text"
                      name="email"
                      placeholder={
                        (t('mainPage.ContactsTitles.email_placeholder') ||
                          'Email*') as string
                      }
                    />

                    <ErrorMesage>{errors.email}</ErrorMesage>
                  </TextFieldWrapper>
                  <SendBtn
                    type="button"
                    onClick={() => {
                      handleSubmit(values, {
                        resetForm,
                        setErrors: null,
                      });
                      resetForm();
                    }}>
                    <SendIcon src={send} alt="send-icon" />
                  </SendBtn>
                </Form>
              );
            }}
          </Formik>
        </FormWrapper>
        <ReservedBlock>
          <ReservedTitle>{t('mainPage.footerTitles.reserved')}</ReservedTitle>
        </ReservedBlock>
        {isSubmitForm && (
          <PopupWrapper>
            <Popup isError={subscribeError}>
              {subscribeError ? <IncorrectIcon /> : <CorrectIcon />}
              <PopupText>
                {subscribeError
                  ? t('leadForm.leadFormPage.errorMessage')
                  : t('leadForm.leadFormPage.accessMessage')}
              </PopupText>
              <BluredButton
                title={t('leadForm.leadFormPage.returnButton')}
                handleClick={() => handleGoBack()}
              />
            </Popup>
          </PopupWrapper>
        )}
      </BottomPartWrapper>
    </>
  );
};
