export const HeaderSection = {
  pools: 'Piscines',
  colors: 'Couleurs',
  technology: 'Technologie',
  accessories: 'Accessories',
  stages: 'Stages',
  order: 'Order',
  description:
    "Vous souhaitez ajouter une touche de luxe à votre maison ? Pensez aux avantages d'une piscine installée. Les piscines résidentielles sont disponibles dans une grande variété de styles et de tailles, ce qui en fait une option idéale pour n'importe quelle maison. Elles peuvent constituer un espace de divertissement et de détente pour la famille et les amis, tout en augmentant la valeur de votre propriété. Qu'il s'agisse d'une petite piscine simple ou d'une grande piscine élaborée, l'installation d'une piscine peut transformer votre arrière-cour en une oasis magnifique.",
};
