import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../theme/Theme.constants';
import logo from '../../../shared/assets/icons/logo.svg';

export const NavBarDesktopBlocks = styled.div`
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  // grid-template-columns: 2.01fr 1fr 1fr 1fr 1fr 1fr 1fr; with login

  grid-template-columns: 2.01fr 1fr 1fr 1fr 1fr 1fr 0fr;
  justify-items: center;
  height: 100%;
  width: 100%;
  color: ${colorVariables.mainTextColor};

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    // grid-template-columns: 2.005fr 1fr 1fr 1fr 1fr 1fr 1fr; with login
    grid-template-columns: 2.01fr 1fr 1fr 1fr 1fr 1fr 0fr;
    padding: ${paddings.desktopPagePadding};
  }
`;

export const FullBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  color: ${colorVariables.mainTextColorWitOpacity};
  cursor: pointer;
  transition: ${transitions.textHoverTransition};
  font-size: 0.625rem;

  // &:first-child {
  //   border-left: 1px solid ${colorVariables.navBarBorderColor};
  // }

  &:hover {
    color: ${colorVariables.mainTextColor};
  }

  @media only screen and (min-width: ${Breakpoints.md}px) and (min-width: ${Breakpoints.xlg}px) {
    font-size: 0.875rem;
  }
  @media only screen and (min-width: ${Breakpoints.lg}px) and (max-width: ${Breakpoints.xlg}px) {
    font-size: 0.7rem;
  }

  &:nth-child(5) {
    position: relative;
    & span {
      display: flex;
      align-items: center;

      &:after {
        position: absolute;
        right: -1px;
        content: '';
        width: 1px;
        height: 0.562rem;
        background-color: ${colorVariables.navBarBorderColor};
      }
    }
  }
`;
export const FirstFullBlock = styled(FullBlock)`
  span:hover {
    transition: ${transitions.textHoverTransition};
    color: ${colorVariables.mainTextColor};
  }
  &:hover {
    color: ${colorVariables.mainTextColorWitOpacity};
  }
`;
export const StoreWrapper = styled.span`
  margin-left: 0.625rem;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 1px;
    height: 0.562rem;
    background-color: ${colorVariables.navBarBorderColor};
    margin-right: 0.625rem;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 1.25rem;
  cursor: pointer;
`;

export const LogoImg = styled.div`
  width: 1.5rem;
  height: 1.25rem;
  background-image: url(${logo});
  background-size: cover;
  background-position: center;
`;

export const LogoTitle = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${colorVariables.mainTextColor};
`;
