export const currentTexts = {
  label: 'Els nostres materials',
  description: `En els nostres serveis de disseny paisatgístic, oferim una àmplia gamma de serveis per ajudar-lo a transformar el seu espai exterior. Els nostres serveis inclouen:`,
};

export const textsBenefits = [
  {
    id: 1,
    text: `Àmplia selecció de materials de construcció d' alta qualitat`,
  },
  {
    id: 2,
    text: 'Preus competitius'
  },
  {
    id: 3,
    text: 'Personal expert amb amplis coneixements dels productes',
  },
  { id: 4, text: `Còmodes opcions d' entrega` },
  {
    id: 5,
    text: `Excepcional servei d' atenció al client`,
  },
];

export const benefitsTitle = 'Per què triar-nos?';
export const materialsList = {
  titleFirst: 'Formigó i maçoneria',
  textFirst:
    'Oferim una àmplia gamma de materials de formigó i maçoneria, com maons, blocs, llambordes i ciment. Aquests materials són perfectes per construir fonaments, murs de contenció, patis i molt més.',

  titleSecond: 'Fusta i estructures',
  textSecond:
    `La nostra fusta i materials d'entramat inclouen fusta dimensional, contraxapat, OSB i molt més. Aquests materials són essencials per emmarcar parets, sostres i terres.`,

  titleThird: 'Teulades i revestiments',
  textThird:
    'Oferim una àmplia gamma de materials per a teulades i revestiments, com teules, teulades metàl·liques, revestiments de vinil i molt més. Aquests materials són crucials per protegir la seva estructura de les inclemències del temps',

  titleFourth: 'Finestres i portes',
  textFourth:
    `La nostra selecció de finestres i portes inclou una varietat d'estils i materials, com fusta, vinil i fibra de vidre. Aquests materials proporcionen tant funcionalitat com estètica a la seva estructura.`,

  titleFifth: 'Ferreteria i eines',
  textFifth:
    'També oferim una gamma de ferreteria i eines, com fixacions, adhesius, serres i trepants. Aquestes eines i accessoris són essencials per completar el seu projecte de construcció amb eficiència i eficàcia.',
};