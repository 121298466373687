import React, { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { languages } from './LanguageDropdown.constatnts';
import {
  DropdownArrow,
  DropdownButton,
  DropdownContent,
  DropdownOption,
  DropdownWrapper,
} from './LanguageDropdown.styled';
import { LanguageItemProps } from './LanguageDropdown.types';
import dropdownArrow from '../../shared/assets/icons/language-dropdown-arrow.svg';
import { useTranslation } from 'react-i18next';

export const LanguageDropdown: FC = () => {
  const currentLanguage = navigator.language;
  const currentClientLanguage = useMemo(() => {
    const languages = ['en', 'es', 'fr', 'ca'];
    const isInclude = languages.includes(currentLanguage);
    if (isInclude) {
      return navigator.language.toUpperCase();
    } else if (navigator.language === 'uk') {
      return 'UA';
    } else {
      return 'EN';
    }
  }, [currentLanguage]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('language')?.toUpperCase() || currentClientLanguage,
  );
  const { i18n } = useTranslation();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${Breakpoints.lg - 1}px)`,
  });

  const handleOptionClick = (language: LanguageItemProps) => {
    isOpen && setSelectedLanguage(language.value);

    setIsOpen(false);
    i18n.changeLanguage(language.value.toLowerCase());
  };
  
  return (
    <DropdownWrapper>
      <DropdownButton
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}>
        {selectedLanguage}
        <DropdownArrow
          isOpen={isOpen}
          src={dropdownArrow}
          alt="dropdown-arrow"
        />
      </DropdownButton>
      <DropdownContent
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        isOpen={isOpen}>
        {languages.map((language, index) => (
          <div key={language.value + index}>
            {isDesktop && (
              <DropdownOption
                isOpen={isOpen}
                onClick={() => handleOptionClick(language)}>
                {language.label}
              </DropdownOption>
            )}
            {isTablet && (
              <DropdownOption
                onClick={() => handleOptionClick(language)}
                isOpen={isOpen}>
                {language.value}
              </DropdownOption>
            )}
          </div>
        ))}
      </DropdownContent>
    </DropdownWrapper>
  );
};
