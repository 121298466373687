export const aboutUsNavItems = {
  values: 'Valores',
  story: 'Historia',
  team: 'Equipo',
};

export const aboutUsSecondDescription =
  'Nuestra empresa se especializa en proporcionar soluciones de construcción e inmobiliarias de última generación que priorizan la felicidad del cliente y la calidad. Nuestra amplia gama de servicios abarca desde la construcción y el diseño hasta viviendas, piscinas, energía, inmobiliarias, inversiones y consultoría. Gracias a nuestro equipo de expertos, ofrecemos constantemente resultados excepcionales que cumplen y superan las expectativas de nuestros clientes, al mismo tiempo que nos ajustamos a sus presupuestos y plazos. Priorizando la confianza y construyendo relaciones duraderas con nuestros clientes, podemos ofrecer constantemente resultados excepcionales y convertirnos en un socio de confianza en su éxito.';

export const aboutUsTitle = {
  about: 'Acerca de',
  us: 'nosotros',
};