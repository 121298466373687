export const interiorNavItems = {
  services: 'Prestations de service',
  portfolio: 'Portefeuille',
};
export const description =
  "L'architecture d'intérieur, ce n'est pas seulement de la créativité, c'est aussi toute une gamme de services qui transforment des mètres carrés ordinaires en confort et en harmonie. Un intérieur de qualité, avec des dessins, des composants techniques, des couleurs et des meubles écologiques bien pensés, contribuera à créer un espace confortable et fonctionnel qui vous ravira, vous et vos proches, pendant de nombreuses années. C'est un investissement dans votre bien-être et votre commodité qui peut améliorer votre humeur et votre efficacité, réduire le stress et améliorer votre qualité de vie. Et nous connaissons la formule parfaite.";
export const currentText = {
  label: "Nos services d'architecture d'intérieur",
  description:
    "L'élaboration d'un concept d'intérieur n'est pas seulement un processus créatif, c'est aussi un processus à multiples facettes qui comprend de nombreuses étapes. Nous sommes prêts à devenir un guide fiable pour vous et à vous accompagner à chaque étape de la mise en œuvre : d'une belle image dans votre tête à l'incarnation de vos rêves les plus fous dans la vie réelle. Les étapes comprennent:",
};

export const currentPortfolioText = {
  description:
    "Jetez un coup d'œil à certains de nos projets antérieurs pour découvrir la gamme de styles et de solutions de conception que nous avons créés pour nos clients. Que vous recherchiez un style moderne ou traditionnel, ou quelque chose entre les deux, nous pouvons vous aider à obtenir l'aspect et l'ambiance que vous souhaitez.",
};

export const servicesList = {
  planningText: 'Développement du concept de design',
  colorText: `Création de décisions de planification et d'esquisses`,
  furnitureText: `Sélection des matériaux et des éléments de finition`,
  designText: "Développement des circuits électriques et d'éclairage",
  windowText: `Création d'une visualisation en 3D`,
  artText: `Réalisation d'un ensemble complet de meubles, d'accessoires et de décorations`,
};
