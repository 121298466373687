export const aboutUsNavItems = {
  values: 'Values',

  story: 'Story',

  team: 'Team',
};

export const aboutUsSecondDescription =
  "Our company specializes in providing cutting-edge construction and real estate solutions that prioritize customer happiness and quality. Our comprehensive range of services encompasses everything from construction and design to housing, pools, energy, real estate, investments, and consulting. Thanks to our expert team, we consistently deliver outstanding results that meet and exceed our client's expectations while staying within their budgets and timelines. By prioritizing trust and building lasting relationships with our clients, we can consistently deliver exceptional outcomes and become a trusted partner in their success.";

export const aboutUsTitle = {
  about: 'About',
  us: 'us'
};;
  
