export const headMenu = {
  style: 'Стиль',
  typology: 'Типологія',
  homes: 'Нові будинки',
};

export const typeHouseStyle = {
  pools: 'Басейни',
  classic: 'Класичний',
  design: 'Дизайнерський',
  minimalist: 'Мінімалістичний',
  modern: 'Сучасний',
  original: 'Оригінальний',
  rustic: 'Сільський',
  traditional: 'Традиційний',
  wood: 'Дерев`яний',
};

export const inputsTexts = {
  numOfBedrooms: 'Кількість спалень:',

  bedrooms: 'спальні',

  surfaceM: 'Площа м2:',

  surface: 'площа',

  numOfParking: 'Кількість парковок:',

  parking: 'парковка',

  numOfBudget: 'Бюджет:',

  budget: 'бюджет',
};

export const searchBtn = 'Пошук';
export const removeAll = 'Видалити все';
