import React, { FC } from 'react';
import {
  PartnersWrapper,
  // AppleIcon,
  Block,
  BottomBlock,
  MiddleBlock,
  PartnersIcon,
  TopBlock,
  IconBlock,
  TitleBlock,
  MIIcon,
  AjaxIcon,
  SmartLifeIcon,
  LifeSmartIcon,
  DescriptionBlock,
  AnotherStyleWrapper,
} from './PartnersSection.styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppleIcon } from '../../../../shared/assets/icons/smart-home/Apple.svg';
interface PartnersSectionProps {
  description: string;
  isAnotherStyle?: boolean;
  icons?: any;
  withoutCenterIcon?: boolean;
}

export const PartnersTabletSection: FC<PartnersSectionProps> = ({
  description,
  isAnotherStyle,
  icons,
  withoutCenterIcon,
}) => {
  const { t } = useTranslation();

  return (
    <AnotherStyleWrapper isAnotherStyle={isAnotherStyle}>
      <PartnersWrapper id="partners-section" isAnotherStyle={isAnotherStyle}>
        <TopBlock isAnotherStyle={isAnotherStyle}>
          <IconBlock isAnotherStyle={isAnotherStyle}>
            <PartnersIcon />
          </IconBlock>
          <TitleBlock>
            <span>{t('smartHome.PartnersSection.title')}</span>
          </TitleBlock>
        </TopBlock>
        <MiddleBlock isAnotherStyle={isAnotherStyle}>
          <DescriptionBlock isAnotherStyle={isAnotherStyle}>
            <span> {description}</span>
          </DescriptionBlock>
          {!withoutCenterIcon && (
            <>
              <IconBlock isAnotherStyle={isAnotherStyle}>
                {icons ? icons.firstIcon : <AppleIcon className="apple-icon" />}
              </IconBlock>
              <IconBlock isAnotherStyle={isAnotherStyle}>
                <MIIcon />
              </IconBlock>
            </>
          )}
        </MiddleBlock>
        <BottomBlock isAnotherStyle={isAnotherStyle}>
          {!withoutCenterIcon ? (
            <Block />
          ) : (
            <IconBlock isAnotherStyle={isAnotherStyle}>
              {icons ? icons.firstIcon : <AppleIcon className="apple-icon" />}
            </IconBlock>
          )}
          <IconBlock isAnotherStyle={isAnotherStyle}>
            {icons ? icons.secondIcon : <AjaxIcon />}
          </IconBlock>
          <IconBlock isAnotherStyle={isAnotherStyle}>
            {icons ? icons.thirdIcon : <SmartLifeIcon />}
          </IconBlock>
          <IconBlock isAnotherStyle={isAnotherStyle}>
            {icons ? icons.fourthIcon : <LifeSmartIcon />}
          </IconBlock>
        </BottomBlock>
      </PartnersWrapper>
    </AnotherStyleWrapper>
  );
};
