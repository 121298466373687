export const ourValuesTitles = {
  title: 'Nuestros Valores',
  quality: 'Calidad',
};

export const ourValuesList = [
  {
    id: 1,
    title: 'Eficacia',
    text: 'Valoramos el tiempo de nuestros clientes, por eso invertimos en plazos y no les hacemos esperar.',
  },
  {
    id: 2,
    title: 'Calidad',
    text: 'Siempre damos prioridad a la calidad y hacemos todo lo posible para garantizar que nuestros servicios cumplan las normas más estrictas.',
  },
  {
    id: 3,
    title: 'Siempre cerca',
    text: 'Realizamos consultas antes, durante y después de prestar el servicio, respondemos a las preguntas de los clientes y ofrecemos recomendaciones.',
  },
  {
    id: 4,
    title: 'Innovación y desarrollo',
    text: 'Siempre nos esforzamos por buscar nuevas tecnologías para garantizar los mejores resultados a nuestros clientes.',
  },
  {
    id: 5,
    title: 'Responsabilidad medioambiental',
    text: 'Utilizamos materiales respetuosos con el medio ambiente en la construcción, cuidamos la naturaleza.',
  },
  {
    id: 6,
    title: 'Transparencia y franqueza ',
    text: 'La honestidad es la principal divisa, por lo que nos adherimos a la franqueza en todas las relaciones con nuestros clientes.',
  },
  {
    id: 7,
    title: 'Socios fiables',
    text: 'Cooperamos con empresas probadas y fiables que nos suministran materias primas y equipos.',
  },
  {
    id: 8,
    title: 'Enfoque individual',
    text: 'Hacemos todo lo posible por satisfacer al 100% las expectativas del cliente.',
  },
];
