import design from '../../../../shared/assets/images/architect/4d-design.jpg';
import tour from '../../../../shared/assets/images/architect/3d-tour.jpg';
import consultations from '../../../../shared/assets/images/architect/consultations.jpg';
import foundation from '../../../../shared/assets/images/architect/foundation.jpg';
import support from '../../../../shared/assets/images/architect/support.jpg';
import busing from '../../../../shared/assets/images/architect/hannah-busing.jpg';
import model from '../../../../shared/assets/images/architect/model-house.jpg';
export const currentTexts = {
  label: 'Наш процес',
  description:
    'В Архітектурі ми дотримуємося комплексного процесу проектування, який гарантує, що кожен проект виконується з точністю та увагою до деталей. Наш процес включає в себе:',
};

export const currentPortfolioTexts = {
  label: 'Портфоліо',
  description: '',
};

export const processList = [
  {
    number: 1,
    label: '4D дизайн',
    description:
      "Завдяки інноваційним технологіям ми зможемо показати нашим клієнтам, на якому етапі знаходиться процес роботи над проектом. А також візуалізувати в об'ємній формі, як приміщення буде виглядати сьогодні, завтра, через тиждень, щоб бути впевненими у дотриманні дедлайнів",
    img: design,
  },
  {
    number: 2,
    label: '3D тур',
    description:
      'Ще до того, як будинок буде збудовано, ви зможете прогулятися його коридорами, завітати до свого кабінету чи дитячої кімнати. Здається, це фантастика? За допомогою 3D-туру можна відчути себе в грі Minecraft, де можна ходити по квартирах, відкривати двері і насолоджуватися кожним квадратним метром. Головною перевагою є те, що ця 3D-візуалізація незабаром перетвориться на вашу реальну оселю',
    img: tour,
  },
  {
    number: 3,
    label: 'До, під час та після',
    description:
      'Ми супроводжуємо наших клієнтів на кожному етапі створення ідеального архітектурного рішення. Наша місія - перетворити будь-який простір на місце, яке ви називаєте домом. А це можливо, лише якщо приділяти увагу деталям на кожному етапі',
    img: busing,
  },
  {
    number: 4,
    label: 'Консультації',
    description:
      'Ми даємо поради, заохочуємо ваші грандіозні раціональні ідеї та відмовляємо від ризикованих ініціатив, спираючись на власний досвід. Підкажемо, де краще розмістити вікна, щоб було багато сонячного світла. Застережемо від неправильного розміщення труб тощо',
    img: consultations,
  },
  {
    number: 5,
    label: 'Гнучкі планування',
    description:
      "Ви змінили концепцію в останній момент? Нічого страшного. Завдяки комп'ютерному моделюванню створюється тривимірна модель майбутньої будівлі, яка дає можливість вносити зміни в проект або пропонувати нові ідеї. За допомогою професійних програм архітектори легко створюють реалістичні візуалізації майбутньої будівлі на основі креслень і розрахунків. Це дозволяє швидко вносити зміни в проект, уникати помилок і зайвих витрат на переробки вже під час будівництва",
    img: model,
  },
  {
    number: 6,
    label: 'Фундамент без ризиків',
    description:
      'Ми маємо все необхідне обладнання для проведення геодезичних вишукувань та дослідження ґрунтів і ґрунтових вод. На цьому етапі ми попередньо розраховуємо вартість проекту та аналізуємо його інвестиційну привабливість. Оцінити рентабельність будівництва до кінця можна тільки після розробки ескізного проекту',
    img: foundation,
  },
  {
    number: 7,
    label: 'Юридичний супровід',
    description:
      "Фахівці розроблять необхідну технічну документацію (включаючи ескізний проект, робочий проект з усіма схемами і кресленнями), яка буде відповідати особливостям експлуатації об'єкта, чинним будівельним нормам і правилам, спеціальним вимогам, а також екологічним і санітарним нормам відповідно до Вашого замовлення.",
    img: support,
  },
];
