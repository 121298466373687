export const servicesTexts = {
  label: `Els nostres serveis de l'ús d'energia solar`,
  description: `Oferim una àmplia gamma de serveis de disseny d'interiors per ajudar-lo a aconseguir l'espai dels seus somnis. Des del concepte inicial i la planificació de l' espai fins a la selecció de colors, la recerca de mobles i els accessoris, el nostre equip pot encarregar-se de tots els aspectes del seu projecte. Els nostres serveis inclouen`,
};
export const servicesList = {
  textFirst: 'Consulta i avaluació',

  textSecond: 'Disseny i proposta',

  textThird: 'Finançament i incentius',

  textFourth: 'Permisos i aprovacions',

  textFifth: 'Instal·lació',

  textSixth: 'Activació i manteniment del sistema',
};


