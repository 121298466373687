export const discoverList = {
  labelFirst: 'Name',

  labelSecond: 'Name',

  labelThird: 'Name',
};

export const realEstateDiscoverTexts = {
  label: 'Discover Your Own Land Plot Today',
  description:
    'We offer a wide range of land plots for any need and budget. If you have been dreaming of owning your own land, look no further. Our selection includes everything from small, urban lots to vast rural expanses. Browse our collection and find the perfect plot for your dreams.',
};

export const aboutDiscoverList = [
  {
    id: 1,
    label: 'About',
    text: 'It is a company with over 50 years of experience in the real estate industry. We specialize in the development, construction and sale of real estate in Spain. Our goal is to help our clients find the perfect property for their needs.',
  },
  {
    id: 2,
    label: 'Sale of real estate',
    text: 'We offer a wide range of properties for sale throughout Spain. Our services include searching for the perfect property, conducting inspections and providing advice on the purchase of real estate. We guarantee our clients that we will find the best property at the best price.',
  },
];

export const aboutText = "If you're ready to buy, sell, or rent a property, or if you're interested in learning more about our services, contact us today. Our team of experts is ready to help you every step of the way."