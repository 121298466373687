export const conditionsText = [
  {
    id: 1,
    title: 'TÉRMINOS Y CONDICIONES',
    text: `
    Estamos comprometidos a proteger su privacidad. Esta Política de Privacidad describe cómo y por qué tratamos sus datos personales cuando accede a nuestro sitio web en modernacasa.es (en adelante, “el sitio web”), en el curso de nuestras actividades de marketing y contratación, para comunicar nuestros servicios y desarrollo comercial. Esta política también establece sus derechos de privacidad y explica cómo ponerse en contacto con nosotros si es necesario.
    `,
  },

  {
    id: 2,
    title: 'QUIENES SOMOS',
    text: `
    Modernacasa (en adelante, ""nosotros"", ""nos"", ""nuestro"" o ""MC"") ha creado este sitio web para comunicarse con visitantes ocasionales, clientes potenciales y empresas interesadas en nuestros servicios, así como para proporcionar información sobre MC y la TI. industria de la subcontratación. Damos la bienvenida a los visitantes para que vean información en nuestro sitio web y lo usen para comunicarse directamente con MC. Controlamos todos los datos personales procesados ​​de acuerdo con esta política. Al visitar este sitio web, acepta nuestra Política de privacidad y acepta que podemos recopilar datos personales durante su visita al sitio web. Si no está de acuerdo con esta Política de privacidad, no dude en abandonar el sitio web. Este sitio web no pretende ofrecer ningún servicio directamente a los niños. No recopilamos a sabiendas información de niños. Sin embargo, si es menor de 16 años y desea ponerse en contacto con nosotros, envíenos un consentimiento por escrito de sus padres o tutores para procesar su información personal.
    `,
  },

  {
    id: 3,
    title: '¿QUÉ DATOS RECOPILAMOS?',
    text: `
   Recopilamos, almacenamos y usamos datos que son esenciales para brindar a los visitantes el máximo beneficio de nuestro sitio web. Recopilamos los siguientes datos durante su sesión en el sitio web:

Información que proporciona directamente a través de formularios de contacto o mensajes de correo electrónico, incluida su dirección de correo electrónico, número de teléfono, nombre, apellido, nombre de la empresa, cargo, dirección postal y otra información que está dispuesto a proporcionarnos.
Datos sobre su comportamiento en las páginas web durante su visita e información sobre cómo navega por el sitio web.
Estadísticas, como las páginas particulares que visita, la cantidad de entradas únicas al sitio web, el navegador desde el que visita el sitio web, la resolución de su pantalla y la plataforma que utiliza.
Además, recopilamos sus datos personales en el curso de nuestra actividad de marketing y comunicación de nuestros servicios de la manera más conveniente. Además, podemos utilizar sus datos personales para fines secundarios estrechamente relacionados con los fines mencionados anteriormente, en circunstancias en las que razonablemente esperaría dicho uso. En el curso de nuestras actividades de marketing y contratación, para comunicar nuestros servicios y desarrollo comercial, podemos recopilar los siguientes datos:

Información que proporciona directamente a través de formularios de contacto o mensajes de correo electrónico, incluida su dirección de correo electrónico, número de teléfono, nombre, apellido, nombre de la empresa, cargo, dirección postal y otra información que está dispuesto a proporcionarnos.
Datos sobre su comportamiento en las páginas web durante su visita e información sobre cómo navega por el sitio web.
Estadísticas, como las páginas particulares que visita, la cantidad de entradas únicas al sitio web, el navegador desde el que visita el sitio web, la resolución de su pantalla y la plataforma que utiliza.
Los datos mencionados anteriormente se recopilan sobre la base de su consentimiento u otra base legal que se presenta a continuación y de conformidad con las leyes aplicables, junto con las presentaciones requeridas ante las autoridades de protección de datos, cuando sea necesario. Puede enviar su consentimiento por correo electrónico a info@modernacasa.es. Recopilamos la mayor parte de la información personal no pública directamente de usted o sus agentes, ya sea en persona, por teléfono o electrónicamente. En algunas circunstancias, es posible que terceros nos proporcionen su información personal (por ejemplo, alguien lo recomendó para un posible empleo). En tales casos, tomamos medidas razonables para asegurarnos de que esté al tanto de los datos que nos proporciona el tercero. MC no recopila intencionalmente ninguna información confidencial sobre usted.

    `,
  },

  {
    id: 4,
    title: '¿CÓMO UTILIZAMOS ESTOS DATOS?',
    text: `
  Los datos personales que se nos envían a través de este sitio web y los datos personales que recopilamos de usted, de terceros o de fuentes públicas se utilizarán (procesarán) para los fines especificados en esta Política.
    `,
  },
  {
    id: 5,
    title: 'UTILIZAREMOS SUS DATOS PERSONALES SOBRE LA SIGUIENTE BASE LEGAL:',
    text: `
  Interés legítimo (RGPD)
Consentimiento (RGPD)
Otra base legal disponible bajo GDPR
Otra base legal disponible bajo GDPR
Por regla general, sus datos personales se utilizarán para:

Interés legítimo (RGPD)
Consentimiento (RGPD)
Otra base legal disponible bajo GDPR
Otra base legal disponible bajo GDPR
Los datos personales tratados por MC no están sujetos a la elaboración de perfiles ni a la toma de decisiones automatizada.
    `,
  },
  {
    id: 6,
    title: '¿CUÁLES SON TUS DERECHOS?',
    text: `
   Nuestro objetivo es ser completamente transparentes en la forma en que usamos sus datos personales y tomar medidas razonables para permitirle corregir, modificar, eliminar o limitar el uso de sus datos personales. Tiene los siguientes derechos de protección de datos:

El derecho a ser informado sobre la recopilación y el uso de sus datos personales. En cualquier momento, puede presentarnos una solicitud de acceso y solicitar una copia de sus datos personales en un formato electrónico de uso común.
Derecho a solicitar información sobre la finalidad del tratamiento de datos e información sobre terceros que hayan recibido sus datos personales.
Derecho a solicitarnos la rectificación de los datos personales que sean incorrectos o estén desactualizados.
El derecho a restringir el procesamiento, lo que significa que puede solicitar la eliminación de sus datos personales o pedirnos que dejemos de procesar sus datos en cualquier momento.
Derecho a solicitar la portabilidad de sus datos personales.
El derecho a oponerse justificadamente al tratamiento de sus datos personales.
El derecho a retirar el consentimiento para el procesamiento de datos en cualquier momento si previamente nos dio su consentimiento para el tratamiento de sus datos personales. MC no cobrará ninguna tarifa por su solicitud de acceso, pero puede cobrar una tarifa administrativa por proporcionar una copia de su información personal. Para proteger sus datos personales, es posible que le pidamos que se identifique antes de divulgar la información solicitada. Consideramos de inmediato cada una de sus solicitudes y brindamos una respuesta sustantiva, ya sea tomando la medida solicitada o brindando una explicación de por qué su solicitud no puede ejecutarse de nuestra parte. Puede ejercer sus derechos enviando una solicitud a los contactos que figuran en la sección de detalles de contacto a continuación. Si tiene alguna queja sobre cómo procesamos sus datos, comience por presentar una queja en info@modernacasa.es. Intentaremos hacer todo lo posible para resolver su solicitud. En caso de que no esté satisfecho con nuestra respuesta, tiene derecho a presentar una queja ante la autoridad de protección de datos correspondiente en el lugar de su residencia. Tenga en cuenta que si nos comunicamos con usted por correo electrónico o correspondencia, siempre puede darse de baja de nuestras listas de correo/mercadeo en cualquier momento comunicándose con el correo electrónico indicado en esta Política o usando el botón (enlace) ""cancelar suscripción"" en nuestro correo electrónico.

    `,
  },
  {
    id: 7,
    title: '¿DÓNDE SE PROCESAN SUS DATOS?',
    text: `
   Sus datos personales pueden procesarse en varios lugares fuera de la Unión Europea y los Estados Unidos, cada uno con diferentes estándares de protección de datos. Sin embargo, tomaremos todas las medidas razonables para garantizar que sus datos se manejen de forma segura y de acuerdo con los más altos estándares previstos en el Reglamento General de Protección de Datos (RGPD, Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo) , esta Política de privacidad y los estándares de la industria.
    `,
  },
  {
    id: 8,
    title: 'TRATAMIENTO DE DATOS PERSONALES DE SOLICITANTES DE EMPLEO',
    text: `
  Como parte de cualquier proceso de contratación, MC recopila y procesa datos personales proporcionados directamente por los solicitantes de empleo, sus referencias y las empresas de contratación que trabajan en nuestro nombre. Nos comprometemos a ser transparentes sobre cómo se recopilan y utilizan los datos de conformidad con nuestras obligaciones de protección de datos. Tipos de datos personales que recopilamos MC recopila una variedad de información sobre usted que incluye:

Número de teléfono, skype y enlaces a perfiles de redes sociales.
Detalles de calificaciones, habilidades, experiencia e historial de empleo.
Información sobre el nivel actual de remuneración, incluidos los derechos a prestaciones.
Información sobre su derecho a trabajar en la UE, si así lo requieren nuestros clientes.
MC puede recopilar datos personales de varias maneras. Por ejemplo, los datos pueden extraerse de formularios de solicitud, CV (currículum vitae), o mediante entrevistas u otros medios de evaluación. Los datos personales se almacenan en nuestro Sistema de seguimiento de candidatos y se exportan a otros sistemas de TI. Si lo invitan a MC para una entrevista, tenga en cuenta que, por razones de seguridad, nuestras instalaciones están bajo vigilancia por video. Podemos recopilar imágenes captadas por la cámara.
    `,
  },

  {
    id: 9,
    title: 'FINALIDAD DEL PROCESAMIENTO',
    text: `
 Dada la naturaleza de nuestras actividades de recursos humanos y contratación, necesitamos procesar datos para poder colaborar con usted en posibles oportunidades laborales. MC tiene un interés legítimo en procesar datos personales durante el proceso de contratación y con fines de mantenimiento de registros. El procesamiento de los datos de los solicitantes de empleo nos permite gestionar el proceso de contratación, evaluar y confirmar la idoneidad para el empleo. Siempre que su solicitud no tenga éxito, MC le informará y eliminará sus datos personales de todos los almacenamientos de MC. Sin embargo, si otorga un consentimiento por separado, MC puede mantener sus datos personales en el archivo para futuras oportunidades de empleo. Puede retirar su consentimiento en cualquier momento.
    `,
  },

  {
    id: 10,
    title: 'DESTINATARIOS DE DATOS PERSONALES',
    text: `
 A los efectos de la contratación, podemos compartir sus datos personales, incluidos los datos almacenados en nuestro Sistema de seguimiento de candidatos, con afiliados, clientes actuales y potenciales de MC. En este caso, se le informará por separado. Además, podemos divulgar sus datos personales a otros terceros mencionados en esta Política de privacidad.
    `,
  },

  {
    id: 11,
    title: 'PERIODO DE RETENCIÓN',
    text: `
  MC conserva los datos personales de los solicitantes de empleo hasta que se cierre la vacante (no más de dos (2) años). En caso de rechazo de la candidatura, sus datos personales podrán conservarse durante el próximo (1) año siempre que dé su consentimiento por separado y MC lo considere como un candidato potencial para otras vacantes.
    `,
  },

  {
    id: 12,
    title: 'COOCKIES',
    text: `
  Como es práctica general, nuestro sitio web utiliza cookies para recopilar datos y brindar a los visitantes una experiencia de navegación más fácil de usar. Las cookies son piezas seleccionadas de datos que se almacenan en su computadora para ayudar a que un sitio web lo reconozca en su próxima visita. Las cookies recopilan automáticamente datos que no son de identificación personal, como el navegador de Internet preferido, el sistema operativo, el nombre de dominio, las interacciones del sitio web, el tiempo de visita, la ubicación y las páginas más vistas por visita. Al aceptar nuestra Política de privacidad y permanecer en el sitio web de MC, acepta el uso de cookies de nuestro sitio web. Puede limpiar o deshabilitar las cookies en la configuración de su navegador de Internet para eliminar estos datos de su dispositivo y detener la recopilación de datos. Por lo general, los navegadores aceptan automáticamente las cookies. Si desea rechazar las cookies, bloquear el uso de cookies, eliminar cookies o recibir una notificación cuando se envíe una cookie, cambie la configuración adecuada en su navegador. Para obtener información más detallada sobre este asunto, consulte nuestra Política de Cookies.
    `,
  },
  {
    id: 13,
    title: 'ENLACES EXTERNOS EN EL SITIO WEB',
    text: `
  Nuestro sitio web puede contener enlaces externos que lo lleven a sitios web de terceros. Estos sitios web funcionan independientemente de MC y no están cubiertos por nuestra Política de privacidad. Los datos que comparte en un sitio web de terceros que está vinculado desde el sitio web de MC están cubiertos por la propia política de privacidad del sitio web de ese tercero y pueden ser recopilados para uso de dicho tercero. MC no es responsable de los datos procesados ​​por sitios web de terceros y no puede garantizar su seguridad al interactuar con ellos.
    `,
  },
  {
    id: 14,
    title: 'REVISIONES A LA POLÍTICA DE PRIVACIDAD',
    text: `
  MC se reserva el derecho de realizar cambios en esta Política de Privacidad en cualquier momento. Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.
    `,
  },

  {
    id: 15,
    title: 'DETALLES DE CONTACTO',
    text: `
 Si tiene alguna pregunta o comentario con respecto a esta Política de Privacidad, envíe un correo electrónico a info@modernacasa.es con el asunto ""Solicitud de Política de Privacidad""
    `,
  },
];
