export const architectStepSection = {
  blockTitle: `Étapes d'un architecte`,
  descriptionTitle: 'Emménager',
  stepOne: {
    title: 'Étape un',
    p1: `Faire connaissance. L'architecte fait connaissance avec le client et la propriété ou le terrain de plus près. À cette première étape, l'architecte communique avec le futur client, découvre ses besoins, exigences et attentes concernant le futur bâtiment.`,
    p2: `Tous les détails les plus subtils sont importants : le mode de vie du client, la présence d'enfants ou d'animaux, les préférences individuelles. Il étudie également l'emplacement de l'objet, analyse le contexte local et l'environnement pour déterminer les facteurs qui influent sur la conception du bâtiment.`,
  },
  stepTwo: {
    title: 'Étape deux',
    p1: `Objectif de l'objet. L'architecte détermine les préférences principales du client en ce qui concerne la conception et la fonctionnalité du bâtiment. Cela peut inclure la définition du type de bâtiment (résidentiel, commercial, public) et le style de conception (classique, moderne, minimaliste).`,
    p2: `Si l'objet est destiné à être loué quotidiennement, il ne sera probablement pas nécessaire de consacrer trop d'attention à l'emplacement et à la fonctionnalité de la cuisine.`,
  },
  stepThree: {
    title: 'Étape trois',
    p1: `Étude du site. Ici, l'architecte apprend à connaître le territoire afin de comprendre ses limitations et ses possibilités.`,
    p2: `Cela peut inclure l'analyse de la topographie, de la composition du sol et des conditions climatiques, ainsi que la vérification de la disponibilité des communications et des infrastructures.`,
  },
  stepFour: {
    title: 'Étape quatre',
    p1: `Analyse de la composante juridique. Détermination de la possibilité de résoudre les problèmes de construction ou de rénovation dans le cadre de la législation et des normes de conception en vigueur.`,
    p2: `À cette étape, l'architecte détermine à quel point le projet est réaliste et réalisable, en tenant compte des exigences de la législation et des normes de conception. Il détermine également le coût et l'intensité des ressources du projet.`,
  },
  stepFive: {
    title: 'Étape cinq',
    p1: ` Travail avec la documentation. Présentation des documents et obtention d'un permis de construction ou de rénovation, obtention de spécifications techniques auprès des propriétaires des réseaux d'approvisionnement en eau extérieurs, des conduites de gaz et des réseaux électriques.`,
    p2: ``,
  },
  stepSix: {
    title: 'Étape six',
    p1: `Solutions fonctionnelles. Le développement d'un concept architectural est l'étape suivante après l'obtention des autorisations et des souhaits du client. À cette étape, l'architecte crée une idée générale de l'objet en cours de conception, y compris la détermination de la zonage fonctionnelle et la création d'un schéma spatial.`,
    p2: ` La création d'un concept permet d'évaluer le potentiel de l'objet et de comprendre dans quelle mesure il répondra aux souhaits du client sur le plan fonctionnel. Sera-t-il à l'aise de vivre dans une maison avec sa famille ou quel sera le niveau de popularité de l'objet en tant que location.`,
  },
  stepSeven: {
    title: 'Étape sept',
    p1: `Développement d'un projet préliminaire. L'architecte détermine les solutions architecturales, constructives, fonctionnelles et artistiques pour la construction ou la rénovation de l'objet.`,
    p2: `Un projet préliminaire permet au client de visualiser l'apparence générale de l'objet et d'évaluer sa conformité visuelle à ses souhaits.`,
  },
  stepEight: {
    title: 'Étape huit',
    p1: `Développement d'un projet de travail. Il s'agit de la dernière étape de la conception architecturale, qui comprend un ensemble complet de documents de projet. Dans le cadre du développement du projet de travail, le spécialiste tient compte de la section architecturale, de la section structurelle, de l'assainissement, de la plomberie, de l'équipement électrique, de la ventilation, de la climatisation, du chauffage et d'autres composants.`,
    p2: `Le projet de travail contient une description détaillée de toutes les solutions constructives, techniques et d'ingénierie nécessaires à la construction ou à la rénovation de l'installation.`,
  },
};
