export const navItems = {
  services: 'Services',
};

export const consultingProcessTexts = {
  label: 'Consulting services',
  description:
    'Over the past few years, the use of solar energy has grown significantly worldwide. Solar panels are now an efficient and affordable way to generate energy for homes and businesses. Our company offers solutions for those who want to switch to solar energy and reduce electricity costs. Our projects have a great impact on the environment and help to reduce the carbon footprint.',
};

export const description =
  'Our consulting services are a powerful tool for successful investment and property management. This helps clients make informed decisions based on fundamental market analysis and professional real estate expertise. This option includes a wide range of services - from assessing investment opportunities and developing investment strategies to optimizing asset management and increasing profitability. We use innovative technologies and data analysis tools in our work to provide high-quality and accurate results. To use this service, we can invite you to our office in Barcelona or offer an online call.';

export const processList = [
  {
    number: 1,
    label: 'Market research',
    description:
      'Conducting a comprehensive analysis of the real estate market, including the study of trends, demand, supply, competitive environment and forecasting future trends. Real estate market analysis is a tool that helps us collect information to know whether it is worth investing in real estate or to determine the rental potential of a city or area. This is an opportunity to understand which houses are trending today and what type of property will be in demand in the long term, over the coming years.',
  },
  {
    number: 2,
    label: 'Investment consulting',
    description:
      'A separate option of our service. Assistance to investors in making informed decisions on real estate investments, including assessment of the potential of objects, financial analysis, strategic planning and advice on optimizing profitability. The service also includes determining the level of risk for lenders associated with financing the purchase of real estate. The analysis helps to assess the stability of the market, possible risks and the impact on the return of loan funds.',
  },
  {
    number: 3,
    label: 'Asset management',
    description:
      'Assisting property owners in effectively managing and optimizing their portfolio, including developing a management strategy, marketing, leasing, managing operating expenses and monitoring results. In the course of consultations on asset management, an analysis of the current state of real estate objects, an assessment of their market value, an analysis of income and expense flows, an assessment of operational efficiency and risks are carried out. Consultants also help clients develop and implement strategies to improve profitability, reduce costs, optimize management, and attract and retain tenants.',
  },
  {
    number: 4,
    label: 'Commercial consulting',
    description:
      'Providing advice on commercial real estate, including the lease, sale, purchase and development of commercial properties such as offices, retail premises, industrial complexes and hotels. An ideal service for those who have planned to acquire their own hotel on the shores of the Mediterranean or the Atlantic Ocean, but do not know where to start and how to assess their financial prospects.',
  },
  {
    number: 5,
    label: 'Consulting on project development',
    description:
      'Assistance in the development, planning and management of real estate projects, including land selection, concept development, construction coordination, budget management and quality control. This is an individual support of the customer at all stages, who wants to make sure that his actions are correct and secure his investments.',
  },
  {
    number: 6,
    label: 'Legal advice',
    description:
      'Providing legal advice and support for real estate transactions, including checking the legal purity of objects, preparing and analyzing contracts, settling disputes and protecting the interests of clients. Our experts monitor all changes in the legislative framework and inform you about potential pitfalls that may emerge over time',
  },
  {
    number: 7,
    label: 'Consultations on sustainability and energy efficiency',
    description:
      'Assistance in the development and implementation of measures to reduce the environmental impact of real estate, including energy efficiency assessment. Together with a consultant, a decision can be made on the use of wind energy or solar energy and assess the relevance of such an initiative. Thus, customers are able to reduce operating costs, increase the value of their facilities, and comply with environmental standards and requirements.',
  },
  {
    number: 8,
    label: 'Consulting on interior and exterior issues',
    description:
      'Helping property owners and developers create attractive and functional indoor and outdoor spaces. The purpose of such consultations is to ensure the optimal use of space and create aesthetically pleasing interiors and exteriors that meet the needs and expectations of clients, as well as reflect the uniqueness and character of the property. Interior and exterior consultants work on the design and layout of premises, the choice of materials and finishing elements, furniture, lighting, color schemes and other aspects that affect the overall atmosphere and visual appearance of the property. They provide a comprehensive service to bring colorful life to cold concrete walls.',
  },
];
