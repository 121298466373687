export const navItems = {
  services: 'Послуги',
};

export const consultingProcessTexts = {
  label: 'Консультаційні послуги',
  description:
    `Консалтингові послуги у сфері нерухомості надаються для того, щоб ми могли відчувати впевненість у своєму виборі і не турбуватися про потенційні ризики. Ми відповімо на всі ваші запитання, пов'язані з юридичними нюансами та технічними питаннями, щоб ви могли побудувати будинок своєї мрії без "сюрпризів".`,
};

export const description =
  'Наші консалтингові послуги є потужним інструментом для успішного інвестування та управління нерухомістю. Це допомагає клієнтам приймати обґрунтовані рішення, що базуються на фундаментальному аналізі ринку та професійній експертизі у сфері нерухомості. Ця опція включає широкий спектр послуг - від оцінки інвестиційних можливостей і розробки інвестиційних стратегій до оптимізації управління активами та підвищення прибутковості. У своїй роботі ми використовуємо інноваційні технології та інструменти аналізу даних для надання якісних і точних результатів. Щоб скористатися цією послугою, ми можемо запросити вас до нашого офісу в Барселоні або запропонувати онлайн-дзвінок.';

export const processList = [
  
  {
    number: 1,
    label: 'Дослідження ринку',
    description:
      'Проведення комплексного аналізу ринку нерухомості, що включає вивчення тенденцій, попиту, пропозиції, конкурентного середовища та прогнозування майбутніх тенденцій. Аналіз ринку нерухомості - це інструмент, який допомагає нам зібрати інформацію, щоб дізнатися, чи варто інвестувати в нерухомість або визначити орендний потенціал міста чи району. Це можливість зрозуміти, які будинки сьогодні в тренді, і який тип нерухомості буде користуватися попитом у довгостроковій перспективі, протягом найближчих років',
  },
  {
    number: 2,
    label: 'Інвестиційний консалтинг',
    description:
      "Окрема опція нашого сервісу. Допомога інвесторам у прийнятті обґрунтованих рішень щодо інвестицій в нерухомість, включаючи оцінку потенціалу об'єктів, фінансовий аналіз, стратегічне планування та поради щодо оптимізації прибутковості. Послуга також включає визначення рівня ризику для кредиторів, пов'язаного з фінансуванням купівлі нерухомості. Аналіз допомагає оцінити стабільність ринку, можливі ризики та вплив на повернення кредитних коштів",
  },
  {
    number: 3,
    label: 'Управління активами',
    description:
      "Допомога власникам нерухомості в ефективному управлінні та оптимізації свого портфеля, включаючи розробку стратегії управління, маркетинг, здачу в оренду, управління операційними витратами та моніторинг результатів. В ході консультацій з управління активами проводиться аналіз поточного стану об'єктів нерухомості, оцінка їх ринкової вартості, аналіз потоків доходів і витрат, оцінка операційної ефективності та ризиків. Консультанти також допомагають клієнтам розробляти та впроваджувати стратегії підвищення прибутковості, скорочення витрат, оптимізації управління, залучення та утримання орендарів",
  },
  {
    number: 4,
    label: 'Комерційний консалтинг',
    description:
      `Надання консультацій з питань комерційної нерухомості, включаючи оренду, продаж, купівлю та девелопмент комерційних об'єктів, таких як офіси, торгові приміщення, промислові комплекси та готелі. Ідеальна послуга для тих, хто запланував придбати власний готель на березі Середземного або Атлантичного океану, але не знає, з чого почати і як оцінити свої фінансові перспективи.`,
  },
  {
    number: 5,
    label: 'Консультування з розвитку проекту',
    description:
      "Допомога в розробці, плануванні та управлінні проектами нерухомості, включаючи вибір земельної ділянки, розробку концепції, координацію будівництва, управління бюджетом та контроль якості. Це індивідуальний супровід клієнта на всіх етапах, який хоче переконатися у правильності своїх дій та убезпечити свої інвестиції.",
  },
  
 

  {
    number: 6,
    label: 'Юридична консультація',
    description:
      "Надання юридичних консультацій та супровід угод з нерухомістю, включаючи перевірку юридичної чистоти об'єктів, підготовку та аналіз договорів, врегулювання спорів та захист інтересів клієнтів. Наші фахівці відстежують всі зміни в законодавчій базі та інформують про потенційні підводні камені, які можуть виникнути з часом",
  },
  {
    number: 7,
    label: 'Консультації з питань сталого розвитку та енергоефективності',
    description:
      "Допомога в розробці та впровадженні заходів щодо зменшення впливу об'єктів нерухомості на навколишнє середовище, включаючи оцінку енергоефективності. Разом з консультантом можна прийняти рішення про використання вітрової або сонячної енергії та оцінити доцільність такої ініціативи. Таким чином, клієнти отримують можливість зменшити операційні витрати, підвищити вартість своїх об'єктів та відповідати екологічним нормам і вимогам",
  },
  {
    number: 8,
    label: "Консультування з питань інтер'єру та екстер'єру",
    description:
      "Допомагаємо власникам нерухомості та девелоперам створювати привабливі та функціональні внутрішні та зовнішні простори. Мета таких консультацій - забезпечити оптимальне використання простору і створити естетично привабливі інтер'єри та екстер'єри, які відповідають потребам і очікуванням клієнтів, а також відображають унікальність і характер об'єкта нерухомості. Консультанти з інтер'єру та екстер'єру працюють над дизайном і плануванням приміщень, вибором матеріалів і оздоблювальних елементів, меблів, освітлення, колірних рішень та інших аспектів, які впливають на загальну атмосферу і візуальний вигляд об'єкта нерухомості. Вони надають комплексну послугу, щоб наповнити барвистим життям холодні бетонні стіни",
  },
];
