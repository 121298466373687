import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';

export const MaterialWrapper = styled.div`
  padding: 0 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0 6.25rem;
  }
`;

export const List = styled.ul`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
  }
`;

export const Item = styled.li`
  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    flex-basis: calc(50% - 0.625rem);
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    flex-basis: calc(100% / 3 - 0.837rem);
  }
`;

export const Img = styled.img`
  display: block;
  width: 100%;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: ${colorVariables.processBlockHeaderBorderColor};
`;

export const TextWrapper = styled.div`
  padding: 1rem;
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  @media (min-width: ${Breakpoints.md}px) {
    height: 9.062rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 1.25rem;
    height: 9.687rem;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  margin-bottom: 0.625rem;
  color: rgba(255, 255, 255, 0.9);
`;

export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25rem;

  color: ${colorVariables.whiteOpacityColor};
`;
