import design from '../../../../shared/assets/images/architect/4d-design.jpg';
import tour from '../../../../shared/assets/images/architect/3d-tour.jpg';
import consultations from '../../../../shared/assets/images/architect/consultations.jpg';
import foundation from '../../../../shared/assets/images/architect/foundation.jpg';
import support from '../../../../shared/assets/images/architect/support.jpg';
import busing from '../../../../shared/assets/images/architect/hannah-busing.jpg';
import model from '../../../../shared/assets/images/architect/model-house.jpg';

export const currentTexts = {
  label: 'Notre Processus',
  description:
    'Chez Arquitecto, nous suivons un processus de conception complet qui garantit que chaque projet est exécuté avec précision et attention aux détails. Notre processus comprend:',
};

export const currentPortfolioTexts = {
  label: 'Portefeuille',
  description: '',
};

export const processList = [
  {
    number: 1,
    label: 'Pré-conception ',
    description:
      "À ce stade, l'architecte rencontre le client pour comprendre ses besoins et ses préférences, ainsi que les exigences ou les contraintes spécifiques au site. L'architecte procède également à une analyse du site afin d'évaluer des facteurs tels que la topographie, l'orientation et les vues",
    img: design,
  },

  {
    number: 2,
    label: 'Conception schématique',
    description:
      "Sur la base des informations recueillies au cours de la phase de préconception, l'architecte élabore un concept préliminaire qui décrit l'agencement général, la taille et la forme de la villa. Il peut s'agir de croquis ou de rendus pour aider le client à visualiser la conception proposée.",
    img: tour,
  },
  {
    number: 3,
    label: 'Développement de la conception',
    description:
      "À ce stade, l'architecte s'emploie à affiner le concept et à élaborer des dessins et des plans plus détaillés. Il peut s'agir de plans d'étage, d'élévations et de coupes, ainsi que de modèles ou de rendus en 3D.",
    img: busing,
  },
  {
    number: 4,
    label: 'Permis et approbations',
    description:
      "Avant que la construction ne puisse commencer, l'architecte devra obtenir tous les permis ou approbations nécessaires auprès des autorités locales, tels que les permis de construire, les permis environnementaux ou les approbations de zonage",
    img: consultations,
  },
  {
    number: 5,
    label: 'Documentation relative à la construction',
    description:
      "Une fois la conception finalisée et les permis obtenus, l'architecte prépare des documents de construction détaillés qui fournissent des instructions aux constructeurs, y compris des plans, des spécifications et des calendriers",
    img: model,
  },
  {
    number: 6,
    label: 'Administration de la construction',
    description:
      "Pendant la phase de construction, l'architecte peut assurer la supervision du chantier et la gestion du projet afin de s'assurer que la construction se déroule conformément au plan et que les problèmes ou les changements éventuels sont réglés en temps voulu et de manière efficace.",
    img: foundation,
  },
  {
    number: 7,
    label: 'Après la construction',
    description:
      "Une fois la villa achevée, l'architecte peut fournir des services post-construction tels qu'une inspection finale, des dessins conformes à l'exécution et un manuel d'entretien",
    img: support,
  },
];
