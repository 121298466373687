import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import {
  ModularHousesWrapper,
  OptionsSectionWrapper,
  OptionsWrapper,
  OptionsTitle,
  OptionsText,
  OptionsTextsWrapper,
} from './ModularHousesPage.styled';
import solarEnergyBg from '../../shared/assets/images/solar-energy/setup-section/premium.jpg';
import smartHomeDesktop from '../../shared/assets/images/modular-houses/smart-home-desktop-img.jpg';
import solarEnergyDesktop from '../../shared/assets/images/modular-houses/solar-energy-desktop-img.jpg';
import swimmingPoolsDesktop from '../../shared/assets/images/modular-houses/swimming-pools-desktop-img.jpg';
import { StepsBlockComponent } from '../../shared/components/StepsBlock/StepsBlock';
import { SliderSection } from '../../shared/components/PagesSliderSection';
import sliderImg from '../../shared/assets/images/modular-houses/slider-img.jpg';
import sliderImgSecond from '../../shared/assets/images/modular-houses/modular-house-slide-2.jpg';
import sliderImgThird from '../../shared/assets/images/modular-houses/modular-house-slide-3.jpg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import Video from '../../shared/assets/video/modular-houses.mp4';
import { VideoBlock } from '../../shared/components/VideoBlock';
export const ModularHousesPage: FC = () => {
  const menuItems = getMenuItems();
  const modularInfo = menuItems[2];
  const smartHomeImg = menuItems[5].backgroundImg;
  const poolsImg = menuItems[3].backgroundImg;
  const { t } = useTranslation();
  useEffect(() => {
    const images = [solarEnergyDesktop, sliderImg];

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);
  const navItems = [
    // {
    //   title: t('modularHousesPage.modularHousesNavItems.model'),   For future features
    //   sectionId: 'model-section',
    // },
    {
      title: t('modularHousesPage.modularHousesNavItems.options'),
      sectionId: 'options-section',
    },
    {
      title: t('landscapeDesignPage.landscapeDesignNavItems.stages'),
      sectionId: 'stages-section',
    },
  ];
  const slides = [
    { id: 1, img: sliderImg },
    { id: 2, img: sliderImgSecond },
    { id: 3, img: sliderImgThird },
    // { id: 4, img: sliderImg },For future features
  ];

  const modularHouseStepsData = [
    {
      id: 1,
      title: t('modularHousesPage.modularHousesStepSection.stepOne.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepOne.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepOne.p2')}</p>
        </>
      ),
    },
    {
      id: 2,
      title: t('modularHousesPage.modularHousesStepSection.stepTwo.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepTwo.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepTwo.p2')}</p>
        </>
      ),
    },
    {
      id: 3,
      title: t('modularHousesPage.modularHousesStepSection.stepThree.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepThree.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepThree.p2')}</p>
        </>
      ),
    },
    {
      id: 4,
      title: t('modularHousesPage.modularHousesStepSection.stepFour.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepFour.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepFour.p2')}</p>
        </>
      ),
    },
    {
      id: 5,
      title: t('modularHousesPage.modularHousesStepSection.stepFive.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepFive.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepFive.p2')}</p>
        </>
      ),
    },
    {
      id: 6,
      title: t('modularHousesPage.modularHousesStepSection.stepSix.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepSix.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepSix.p2')}</p>
        </>
      ),
    },

    {
      id: 7,
      title: t('modularHousesPage.modularHousesStepSection.stepSeven.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepSeven.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepSeven.p2')}</p>
        </>
      ),
    },

    {
      id: 8,
      title: t('modularHousesPage.modularHousesStepSection.stepEight.title'),
      description: (
        <>
          <p>{t('modularHousesPage.modularHousesStepSection.stepEight.p1')}</p>
          <br />
          <p>{t('modularHousesPage.modularHousesStepSection.stepEight.p2')}</p>
        </>
      ),
    },
  ];
  const currentTexts = {
    label: t('modularHousesPage.currentTexts.label'),
    description: t('modularHousesPage.currentTexts.description'),
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const options = [
    {
      id: 1,
      title: t('modularHousesPage.options.titleSwimmingPools'),
      describtion: t('modularHousesPage.options.describtionSwimmingPools'),
      img: isDesktop ? swimmingPoolsDesktop : poolsImg,
    },
    {
      id: 2,
      title: t('modularHousesPage.options.titleSmartHome'),
      describtion: t('modularHousesPage.options.describtionSmartHome'),
      img: isDesktop ? smartHomeDesktop : smartHomeImg,
    },
    {
      id: 3,
      title: t('modularHousesPage.options.titleSolarEnergy'),
      describtion: t('modularHousesPage.options.describtionSolarEnergy'),
      img: isDesktop ? solarEnergyDesktop : solarEnergyBg,
    },
  ];
  return (
    <ModularHousesWrapper>
       <VideoBlock video={Video} />
      <PageHeaderSection
        logoImg={modularInfo.icon}
        title={modularInfo.label}
        navItems={navItems}
        description={t('modularHousesPage.description')}
        titleSize={isDesktop ? '90px' : isTablet ? '50px' : ''}
        id={modularInfo.id}
        withoutBgColor
      />
      <StepsBlockComponent
        stepsData={modularHouseStepsData}
        blockTitle={t('modularHousesPage.blockTitle')}
        description={t('modularHousesPage.blockDescription')}
        sectionId="stages-section"
      />
      {/* <ModelSectionWrapper id="model-section">
        <ModelSection />
      </ModelSectionWrapper>  For future features*/}
      {/* <ProjectsBlock />   For future features*/}

      {isDesktop && (
        <ProcessBlockHeader
          currentTexts={currentTexts}
          componentMarginBottom={'60px'}
          isBackgroundTextColor
          descriptionPadding={'45px 35px'}
        />
      )}
      <OptionsSectionWrapper id="options-section">
        {options.map(({ id, title, describtion, img }) => {
          return (
            <OptionsWrapper bgImage={img} key={id}>
              <OptionsTextsWrapper>
                <OptionsTitle>{title}</OptionsTitle>
                <OptionsText>{describtion}</OptionsText>
              </OptionsTextsWrapper>
            </OptionsWrapper>
          );
        })}
      </OptionsSectionWrapper>

      <SliderSection slides={slides} />
    </ModularHousesWrapper>
  );
};
