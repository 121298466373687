export const navItems = {
  services: 'Servicios',
};

export const consultingProcessTexts = {
  label: 'Servicios de consultoría',
  description:
    'Los servicios de consultoría inmobiliaria se prestan para que podamos sentirnos seguros de nuestra elección y despreocuparnos de posibles riesgos. Responderemos a todas sus preguntas relacionadas con matices legales y cuestiones técnicas para que pueda construir la casa de sus sueños sin ""sorpresas"".',
};
export const description =
  'Nuestros servicios de consultoría son una poderosa herramienta para el éxito de la inversión y la gestión inmobiliaria. Esto ayuda a los clientes a tomar decisiones informadas basadas en el análisis fundamental del mercado y en la experiencia inmobiliaria profesional. Esta opción incluye una amplia gama de servicios: desde la evaluación de oportunidades de inversión y el desarrollo de estrategias de inversión hasta la optimización de la gestión de activos y el aumento de la rentabilidad. Utilizamos tecnologías innovadoras y herramientas de análisis de datos en nuestro trabajo para ofrecer resultados precisos y de alta calidad. Para utilizar este servicio, podemos invitarle a nuestra oficina de Barcelona u ofrecerle una llamada en línea.';

export const processList = [
  {
    number: 1,
    label: 'Investigación de mercado',
    description:
      'Realización de un análisis exhaustivo del mercado inmobiliario, incluyendo el estudio de las tendencias, la demanda, la oferta, el entorno competitivo y la predicción de futuras tendencias. El análisis del mercado inmobiliario es una herramienta que nos ayuda a recopilar información para saber si vale la pena invertir en bienes inmuebles o para determinar el potencial de alquiler de una ciudad o área. Esta es una oportunidad para comprender qué casas están en tendencia hoy en día y qué tipo de propiedad tendrá demanda a largo plazo, en los próximos años.',
  },
  {
    number: 2,
    label: 'Asesoramiento en inversiones',
    description:
      'Una opción separada de nuestro servicio. Asistencia a los inversores en la toma de decisiones informadas sobre inversiones inmobiliarias, incluyendo la evaluación del potencial de los objetos, el análisis financiero, la planificación estratégica y el asesoramiento sobre la optimización de la rentabilidad. El servicio también incluye la determinación del nivel de riesgo para los prestamistas asociado con la financiación de la compra de bienes inmuebles. El análisis ayuda a evaluar la estabilidad del mercado, los posibles riesgos y el impacto en el retorno de los fondos del préstamo.',
  },
  {
    number: 3,
    label: 'Gestión de activos',
    description:
      'Asistencia a los propietarios en la gestión y optimización efectiva de su cartera, incluyendo el desarrollo de una estrategia de gestión, marketing, arrendamiento, gestión de gastos operativos y seguimiento de resultados. En el curso de las consultas sobre la gestión de activos, se lleva a cabo un análisis del estado actual de los objetos inmobiliarios, una evaluación de su valor de mercado, un análisis de los flujos de ingresos y gastos, una evaluación de la eficiencia operativa y los riesgos. Los consultores también ayudan a los clientes a desarrollar e implementar estrategias para mejorar la rentabilidad, reducir costos, optimizar la gestión y atraer y retener inquilinos.',
  },
  {
    number: 4,
    label: 'Asesoramiento comercial',
    description:
      'Asesoramiento en asuntos inmobiliarios comerciales, incluyendo el arrendamiento, la venta, la compra y el desarrollo de propiedades comerciales como oficinas, locales comerciales, complejos industriales y hoteles. Un servicio ideal para aquellos que han planeado adquirir su propio hotel en las costas del Mediterráneo o del océano Atlántico, pero no saben por dónde empezar y cómo evaluar sus perspectivas financieras.',
  },
  {
    number: 5,
    label: 'Asesoramiento en el desarrollo de proyectos',
    description:
      'Asistencia en el desarrollo, planificación y gestión de proyectos inmobiliarios, incluyendo la selección de terrenos, desarrollo de conceptos, coordinación de construcción, gestión presupuestaria y control de calidad. Se trata de un apoyo individual al cliente en todas las etapas, quien desea asegurarse de que sus acciones sean correctas y asegurar sus inversiones.',
  },
  {
    number: 6,
    label: 'Asesoramiento legal',
    description:
      'Brindar asesoramiento legal y apoyo para transacciones inmobiliarias, incluyendo la verificación de la legalidad de los objetos, la preparación y análisis de contratos, la resolución de disputas y la protección de los intereses de los clientes. Nuestros expertos monitorean todos los cambios en el marco legislativo y le informan sobre posibles obstáculos que puedan surgir con el tiempo.',
  },
  {
    number: 7,
    label: 'Asesoramiento en sostenibilidad y eficiencia energética',
    description:
      'Asistencia en el desarrollo e implementación de medidas para reducir el impacto ambiental de los bienes inmuebles, incluyendo la evaluación de la eficiencia energética. Junto con un consultor, se puede tomar una decisión sobre el uso de energía eólica o energía solar y evaluar la pertinencia de dicha iniciativa. De esta manera, los clientes pueden reducir los costos operativos, aumentar el valor de sus instalaciones y cumplir con las normas y requisitos ambientales.',
  },
  {
    number: 8,
    label: 'Asesoramiento en cuestiones de interior y exterior',
    description:
      'Ayudar a los propietarios de bienes inmuebles y desarrolladores a crear espacios interiores y exteriores atractivos y funcionales. El objetivo de estas consultas es asegurar el uso óptimo del espacio y crear interiores y exteriores estéticamente agradables que satisfagan las necesidades y expectativas de los clientes, así como reflejen la singularidad y el carácter de la propiedad. Los consultores de interior y exterior trabajan en el diseño y distribución de locales, la elección de materiales y elementos de acabado, los muebles, la iluminación, los esquemas de color y otros aspectos que afectan la atmósfera general y la apariencia visual de la propiedad. Proporcionan un servicio integral para dar vida y color a las frías paredes de hormigón.',
  },
];
