import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';

export const FAQSectionWrapper = styled.div`
  display: flex;
  padding: 3rem 0 2.375rem;
  justify-content: center;
  align-items: start;
  width: 100%;

  background: ${colorVariables.mainBackgroundColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0.625rem ${paddings.tabletSidePadding} 4.375rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
    padding-top: 10rem;
    padding-bottom: 7.125rem;
  }
`;
