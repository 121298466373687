

export const investmentsNavItems = {
  philosophy: 'Filosofia',

  process: 'Procés',

  packages: 'Paquets',

  team: 'Equip',
};
export const investmentsSecondDescription =
  `Espanya és un lloc on la cultura mediterrània es combina amb una rica història i una arquitectura única, atraient turistes i inversors de tot el món. No és d'estranyar, perquè el mercat immobiliari espanyol és un dels més prometedors i rendibles d'Europa. Espanya ofereix una àmplia varietat: des d'apartaments al centre de la ciutat fins a viles de luxe a la costa. A causa dels moderats preus de l'habitatge, la compra d'immobles està a l'abast d'un ampli ventall d'inversors de diferents països. Les ciutats espanyoles són famoses per la seva infraestructura i qualitat de vida, cosa que atrau persones disposades a comprar propietats per a residència permanent o lloguer. Cada inversor que es posi en contacte amb nosaltres pot rebre ofertes individuals, una entrada financera estable, serveis jurídics i avaluació de riscos.`;
