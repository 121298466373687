export const MainSection = {
  services: 'Services',
  сonstruction: 'Construction',
  projects: 'Projects',
  interior: 'Interior',
  modularHouses: 'Modular houses',
  architect: 'Architect',
  consulting: 'Consulting',
  solarEnergy: 'Solar energy',
  inmobiliaria: 'Real estate',
  investments: 'Investments',
  swimmingPools: 'Swimming pools',
  smartHome: 'Smart home',
  landscapeDesign: 'Landscape design',
  buildingMaterials: 'Building Materials',
};
