export const ClientPartnerNavbarItems = {
  client: 'Client',
  partner: 'Partner',
};

export const MainNavbarItems = {
  favourite: 'Favourite',
  questions: 'Questions',
  information: 'Information',
  contacted: 'Contacted',
};

export const LeadFormPage = {
  // favourite block
  favouriteTitle: 'Your favorite services',
  favouriteDescription: 'We look forward to working with you!',
  fullCycleButton: 'A full-cycle project',
  clearList: 'Clear list of services',
  addService: 'Add a service',
  hide: 'Hide',
  otherServices: 'Other services',
  comentsOrQuestions: 'Comments or questions',
  backButton: 'Back',
  nextButton: 'Next',
  sendButton: 'Send',
  // question block
  questionTitle: 'Answer a few questions',
  questionDescription:
    'This will help you find the best solution for your project',
  projectStatus: 'What is your project status right now?',
  otherButton: 'Other',
  commentsQuestionsPlaceholder: 'Comments or questions',
  uploadFile: 'Upload file',
  // information block
  informationTitle: 'Write contact information',
  informationDescription:
    'This is so that we can contact you in a way that is convenient for you',
  name: 'Name*',
  phoneNumber: 'Phone number*',
  email: 'Email*',
  organization: 'Organization',
  position: 'Employment Position',
  checkboxText:
    'By checking this box, I confirm that I have read and agree to the processing of my personal data in accordance with the',
  privacyPolicy: 'privacy policy.',
  mainNameError: '* Please enter your name',
  secondaryNameError: '* Name cannot start with whitespace',
  mainPhoneError: '* Please enter your number',
  secondaryPhoneError: '* Invalid phone number',
  mainEmailError: '* Please enter correct email',
  secondaryEmailError: '* Invalid email format',
  thirdEmailError: '* Use only English characters',
  // contacted block
  contactedTitle: 'How do you prefer to be contacted?',
  contactedDescription: 'Choose the most convenient way for us to contact you',
  whatsapp: 'Whatsapp',
  edit: 'Edit',
  phoneCall: 'Phone call',
  emailType: 'Email',
  bookMeeting: 'Book a meeting',
  schedule: 'Schedule a call',
  errorMessage: 'Ooops something went wrong...',
  accessMessage: `Thank you for your submission. We'll get back to you as soon as possible.`,
  returnButton: 'Return to Moderna Casa',
};
