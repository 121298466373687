export const Breakpoints = {
  xs: 320,
  sm: 480,
  md: 768,
  lg: 1025,
  xlg: 1200,
  xxl: 1440,
  maxWidth: 1920,
  height: 500,
};

export const colorVariables = {
  // Main background colors
  blackBackgroundColor: '#000000;',
  mainBackgroundColor: '#1e1e1e;',
  opacityBackgroundColor: 'rgba(0, 0, 0, 0.5)',
  tabletOpacityBackgroundColor: 'rgba(0, 0, 0, 0.7)',
  mobileOpacityBackgroundColor: 'rgba(0, 0, 0, 0.8)',
  opacityWhiteBackgroundColor: 'rgba(255, 255, 255, 0.05)',
  popupMenuBackground: 'rgba(23, 23, 25, 0.95);',
  backgroundMenuItemColor: '#262627',
  footerFormBtn: 'rgba(255, 255, 255, 0.1)',
  ourStoryBlockBackground: 'rgba(0, 0, 0, 0.9)',
  contactsHeaderIconBackground: 'rgba(255, 230, 98, 0.1)',
  alternativeBackground: '#171719',
  processNumberBackground: '#212123',
  opacityConstructionBackground: 'rgba(23, 23, 25, 0.8)',
  dropDownBackground: '#0c0c0d',

  partnersSectionBackground: 'rgba(23, 23, 25, 0.6)',

  // Menu icons colors
  mainMenuIconColor: '#fff',

  // Text
  mainTextColor: '#fff',
  secondaryTextColor: '#BBBBBB',
  mainTextColorWitOpacity: 'rgba(255, 255, 255, 0.6)',
  alternativeTextColor: '#FFE662',
  secondAlternativeColor: '#5AC8FA',
  whiteOpacityColor: 'rgba(255, 255, 255, 0.5)',
  footerReservedTitle: 'rgba(255, 255, 255, 0.7)',
  aboutUsOpacityPart: 'rgba(255, 255, 255, 0.2)',
  footerTitleColor: '#ffffff',
  popupText: '#fbfbfb',
  dropDownTitleColor: '#eaeaea',
  errorColor: '#FF4D14',
  succesColor: '#57EF3F',

  // Icons
  mainIconColor: '#fff',
  secondaryIconColor: 'rgba(255, 255, 255, 0.4)',
  iconYellow: '#FFE662',

  // Buttons
  buttonGreyBackground: 'rgba(255, 255, 255, 0.08)',
  menuBackgroundColor: '#3D3F42',
  yellowButtonBackground: 'rgba(255, 230, 98, 0.1)',
  contactsPageButtonColor: 'rgba(255, 255, 255, 0.15)',

  // Borders
  navBarBorderColor: '#3D3F42',
  blockBorderColor: '#65696D',
  buttonBorder: '#BBBBBB',
  additionalBorder: '#D9D9D9',
  lightOpacityBorderColor: 'rgba(101, 105, 109, 0.2)',
  hoverHeaderMenuBorder: '#CFCFCF',
  footerBorderColor: '#1e2021',
  yellowButtonBorder: 'rgba(255, 230, 98, 0.5)',
  borderBookBlockColor: '#36383b',
  ourValuesBlockBorderColor: 'rgba(101, 105, 109, 0.4)',
  contactsHeaderBorderColor: 'rgba(101, 105, 109, 0.5)',
  contactsHeaderBtnBorderColor: 'rgba(255, 230, 98, 0.5)',
  popupBorderColor: 'rgba(255, 230, 98, 1)',
  processBlockHeaderBorderColor: 'rgba(255, 255, 255, 0.2)',
  // errors
};

export const transitions = {
  allHoverTransition: '0.3s all ease',
  textHoverTransition: '0.3s color ease',
  opacityTransitions: '0.3s opacity ease',
  backgroundHoverTransition: '0.3s background-color ease',
  buttonBlurHoverTransition: '0.3s backdrop-filter ease',
};

export const paddings = {
  desktopPagePadding: '0 6.25rem',
  tabletPagePadding: '0 2.5rem',
  mobilePagePadding: '0 1.25rem',
  desktopSidePadding: '6.25rem',
  tabletSidePadding: '2.5rem',
  mobileSidePadding: '1.25rem',
};
