export const CardsSection = {
  basic: {
    type: 'Bàsic',
    homePackage: 'Sistema de guardià de la llar',
    description: `Aquesta configuració inclou un termòstat intel·ligent, il·luminació intel·ligent i un sistema de seguretat intel·ligent. Permet el control remot de la temperatura, la il·luminació i la seguretat de la seva llar des del seu telèfon intel·ligent.`,
  },
  standart: {
    type: ' Estàndard',
    homePackage: 'Solució Eco-Intel·ligent',
    description: `Aquesta configuració inclou totes les característiques de la configuració bàsica, a més de panys intel·ligents, un sistema de reg intel·ligent i integració de control per veu. Permet l' automatització i el control continus dels diversos sistemes de la seva llar.`,
  },
  premium: {
    type: 'De primera qualitat',
    homePackage: `Configuració intel·ligent d'Oasis`,
    description: `Actualitzi a la nostra configuració impulsada per IA per millorar l'administració d'energia, l'entreteniment i l'automatització de la llar. Perfecte per a llars més grans o propietats comercials que busquen l'últim en comoditat i eficiència.`,
  },
};
