import styled from 'styled-components';
import { colorVariables, transitions } from '../../../theme/Theme.constants';
import { HeaderMenuBlock } from '../../HeaderMenuComponent/HeaderMenuComponent.styled';

export const NavBurgerWrapper = styled.div``;

export const BurgerMenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none;
  cursor: pointer;
  padding: 0.75rem 0.25rem;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${colorVariables.blockBorderColor};
`;

export const BurgerMenuLine = styled.div<{ isOpen: boolean }>`
  width: 25px;
  height: 1px;
  background: ${colorVariables.mainTextColor};
  transition: all 0.2s ease-in-out;

  &:nth-child(1) {
    top: 0;
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(45deg) translate(4.5px, 5px)' : 'none'};
  }

  &:nth-child(2) {
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
  }

  &:nth-child(3) {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(-45deg) translate(4px, -5px)' : 'none'};
  }
`;
export const BurgerMenuContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  overflow-y: auto;
  top: 3.75rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  z-index: 10;
  height: calc(100vh - 3.75rem);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: all 0.3s ease-in-out;
  background: ${colorVariables.popupMenuBackground};
  backdrop-filter: blur(14px) !important;

  

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const BurgerMenuWrapper = styled.div`
  width: 100vw;
 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const NavbarMenuItems = styled.div`
  
  width: 100%;
  margin: 2rem 0;
  border-top: 1px solid ${colorVariables.blockBorderColor};
  
`;
export const NavbarMenuItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  
`
export const NavbarMenuItem = styled.div`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  background-color: ${colorVariables.backgroundMenuItemColor};
  cursor: pointer;
  transition: ${transitions.backgroundHoverTransition};
  width: 100%;
  &:first-child {
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }

  &:hover {
    background-color: inherit;
  }
`;

export const ServicesList = styled.ul`
  width: 100%;
  background: ${colorVariables.alternativeBackground};
  border-width: 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
  margin-bottom: 8.125rem;
`

export const ServicesItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.25rem 0px 1.25rem 1.562rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${colorVariables.navBarBorderColor};
  }
  svg {
    fill: ${colorVariables.iconYellow};
  }
`;
export const ServicesName = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25rem;

  margin-left: 1rem;
`