import React, { FC } from 'react';
import {
  ServicesWrapper,
  List,
  Item,
  Img,
  TitleWrapper,
  Title,
} from './PageServicesSection.styled';

interface ServicesProps {
  servicesList: { id: number; text: string; img: string }[];
}

export const PageServicesSection: FC<ServicesProps> = ({ servicesList }) => {
  return (
    <ServicesWrapper>
      <List>
        {servicesList.map(({ id, text, img }) => {
          return (
            <Item key={id}>
              <Img src={img} loading="lazy" />
              <TitleWrapper>
                <Title>{text}</Title>
              </TitleWrapper>
            </Item>
          );
        })}
      </List>
    </ServicesWrapper>
  );
};
