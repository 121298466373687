import React, { FC } from 'react';
import {
  HeaderWrapper,
  LeftBlockWrapper,
  LeftBlockIcon,
  HeaderTitle,
  RightBlockWrapper,
  RightBlockIcon,
  RightIconWrapper,
  OrderBtn,
} from './ContactsHeaderSection.styled';
import envelopesIcon from '../../shared/assets/icons/contacts/envelopes.svg';
import envelopesRegular from '../../shared/assets/icons/contacts/envelope-regular.svg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { useTranslation } from 'react-i18next';
export const ContactsHeaderSection: FC = () => {
  const { t } = useTranslation();

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  return (
    <HeaderWrapper>
      <LeftBlockWrapper>
        <LeftBlockIcon src={envelopesIcon} />
        <HeaderTitle>
          {t('contactsPage.contactsHeaderTitles.title')}
        </HeaderTitle>
      </LeftBlockWrapper>

      <RightBlockWrapper>
        {!isTablet ? (
          <RightIconWrapper>
            <RightBlockIcon src={envelopesRegular} />
          </RightIconWrapper>
        ) : (
          <OrderBtn>{t('contactsPage.contactsHeaderTitles.orderBtn')}</OrderBtn>
        )}
      </RightBlockWrapper>
    </HeaderWrapper>
  );
};
