export const currentTexts = {
  label: 'Our Materials',
  description: `Our landscaping services offer a wide range of services to help you transform your outdoor space. Our services include:`,
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Wide selection of high-quality building materials',
  },
  { id: 2, text: 'Competitive pricing' },
  { id: 3, text: 'Expert staff with extensive product knowledge' },
  { id: 4, text: 'Convenient delivery options' },
  {
    id: 5,
    text: 'Exceptional customer service',
  },
];

export const benefitsTitle = 'Why Choose Us';
export const materialsList = {
  titleFirst: 'Concrete and Masonry',
  textFirst:
    'We offer a range of concrete and masonry materials, including bricks, blocks, pavers, and cement. These materials are perfect for building foundations, retaining walls, patios, and more.',

  titleSecond: 'Lumber and Framing',
  textSecond:
    'Our lumber and framing materials include dimensional lumber, plywood, OSB, and more. These materials are essential for framing walls, roofs, and floors.',

  titleThird: 'Roofing and Siding',
  textThird:
    'We offer a range of roofing and siding materials, including shingles, metal roofing, vinyl siding, and more. These materials are crucial for protecting your structure from the elements.',

  titleFourth: 'Windows and Doors',
  textFourth:
    'Our selection of windows and doors includes a variety of styles and materials, such as wood, vinyl, and fiberglass. These materials provide both function and aesthetics to your structure.',

  titleFifth: 'Hardware and Tools',
  textFifth:
    'We also offer a range of hardware and tools, including fasteners, adhesives, saws, and drills. These tools and accessories are essential for completing your construction project efficiently and effectively.',
};
