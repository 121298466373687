export const AccessoriesSection = {
  title: 'аксесуари',
  description:
    ' Аксесуари для розумного дому покликані зробити ваш дім зручнішим, ефективнішим і приємнішим. Від розумних термостатів і систем освітлення до голосових асистентів і камер спостереження - існує безліч аксесуарів, які допоможуть вам автоматизувати та контролювати різні аспекти.',
  stages: 'Stages',
  order: 'Order',
};
export const accessoriesIcons = {
  conditioners: 'Кондиціонери',

  purifiers: 'Очищувачі повітря',

  bridges: 'Мости',

  cameras: 'Камери',

  doorbells: 'Дверні дзвінки',

  fans: 'Вентилятори',

  faucets: 'Змішувачі',

  garageDoors: 'Гаражні двері',

  humidifiers: 'Зволожувачі повітря',

  lights: 'Світильники',

  locks: 'Замки',

  outlets: 'Розетки',

  receivers: 'Приймачі',

  routers: 'Роутери',

  security: 'Безпека',

  sensors: 'Датчики',

  speakers: 'Динаміки',

  sprinklers: 'Спринклери',

  switches: 'Вимикачі',

  thermostats: 'Терморегулятори',

  tvs: 'телевізори',

  windows: 'вікна',
};
  

