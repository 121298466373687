export const blockTitle = 'Stages of Modular houses';

export const blockDescription = 'Initial consultation';

export const modularHousesStepSection = {
  blockTitle: 'Stages of Modular house',
  descriptionTitle: 'Move in',
  stepOne: {
    title: 'Design and Planning',
     p1: `During this initial step, the size, layout, and style of the modular house are determined. Working with an architect or modular home manufacturer, a detailed design is created, taking into account specific requirements and obtaining necessary permits.`,
     p2: ``,
  },
  stepTwo: {
    title: 'Foundation Preparation',
    p1: `Clearing and leveling the construction site is the first task in this step. Excavation follows, with the construction of a solid foundation that meets local building codes. The foundation must be properly graded and leveled to support the modular units.`,
    p2: ``,
  },
  stepThree: {
    title: 'Manufacturing of Modular Units',
    p1: `Collaborating with a reputable modular home manufacturer, the individual modules are constructed in a controlled factory environment. This process includes building walls, floors, ceilings, and installing electrical and plumbing systems within each module.`,
    p2: ``,
  },
  stepFour: {
    title: 'Transportation and Site Preparation',
    p1: `Once the modules are complete, they are transported to the construction site using specialized trucks. Meanwhile, the site is prepared by installing utility connections and completing necessary site work, such as grading and landscaping.`,
    p2: ``,
  },
  stepFive: {
    title: 'Assembly and Installation',
    p1: `Using cranes or lifting equipment, the modules are placed on the foundation. Skilled technicians or construction crews secure the modules together, ensuring proper alignment and sealing. Electrical and plumbing connections between the modules are made, and finishing touches are added.`,
    p2: ``,
  },
  stepSix: {
    title: 'Interior and Exterior Finishing',
    p1: `In this step, the focus shifts to completing the interior and exterior work. The interior work involves installing flooring, wall finishes, cabinetry, and fixtures, while the exterior work includes siding, roofing, windows, and doors. These finishing touches enhance the functionality and aesthetics of the modular house.`,
    p2: ``,
  },
  stepSeven: {
    title: 'Utilities and Systems Integration',
    p1: `Connecting the modular house to local utility services, such as water, electricity, and gas, is the objective of this step. The electrical, plumbing, heating, and cooling systems are thoroughly tested to ensure their proper functionality.`,
    p2: ``,
  },
  stepEight: {
    title: 'Final Inspections and Certification',
    p1: `To ensure compliance with building codes and regulations, a final inspection by local authorities is scheduled. This inspection covers all aspects of the modular house construction. Once approved, the necessary certificates of occupancy or compliance are obtained, signifying that the modular house is legally habitable.`,
    p2: ``,
  },
};
