import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';
import { transitions } from '../../../theme/Theme.constants';
import { Button } from '../BluredButton/BluredButton.styled';

export const PageHeaderSectionWrapper = styled.div<{
  bgImage?: string;
  isDropDown?: boolean;
  withoutBgColor?: boolean;
  headerHeight?: string;
  backgroundColor?: string;
}>`
  ${props =>
    props.bgImage
      ? `
      background-image: url(${props.bgImage});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `
      : ''}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => (props.isDropDown ? 'flex-direction: column;' : '')}

  ${props =>
    !props.withoutBgColor
      ? `&::before { 
        content: '';
        background: ${
          props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.7)'
        };
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } `
      : ''}
  height: ${props =>
    props.headerHeight?.length !== 0 ? `${props.headerHeight};` : '100%;'};
  padding: ${paddings.mobilePagePadding};

  @media only screen and (min-width: ${Breakpoints.sm}px) {
    flex-direction: column;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: ${paddings.tabletPagePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
  }
`;

export const HeaderSectionNavbarWrapper = styled.div<{
  isDropDown?: boolean;
  isServices?: boolean;
}>`
  position: ${props => (props.isDropDown ? 'static;' : 'absolute;')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => (props.isDropDown ? 'margin-top: 3.75rem;' : ' top: 3.75rem;')}
  padding: ${paddings.mobilePagePadding};
  border-top: 1px solid ${colorVariables.navBarBorderColor};
  background: ${props =>
    props.isDropDown || props.isServices
      ? `${colorVariables.blackBackgroundColor};`
      : `${colorVariables.opacityBackgroundColor}`};
  width: 100vw;
  max-width: ${props => (props.isServices ? '' : `${Breakpoints.maxWidth}px`)};
  height: 4.125rem;
  z-index: 15;
  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: ${paddings.tabletPagePadding};
  }

  @media only screen and (min-width: ${Breakpoints.sm}px) {
    position: static;
    z-index: 15;
    margin-top: 3.75rem;
    padding: ${paddings.tabletPagePadding};
  }

  ${props =>
    props.isDropDown
      ? `@media only screen and (min-width: ${Breakpoints.lg}px){margin-top: 2.75rem;
     padding: ${paddings.desktopPagePadding};  
    }`
      : `@media only screen and (min-width: ${Breakpoints.lg}px) {
    position: absolute;
    margin-top: 0px;
    padding: ${paddings.desktopPagePadding};
    top: 2.75rem;
  }
  @media only screen and (min-width: 2000px){
     padding: ${
       props.isServices
         ? '0px calc((100vw - 1920px) / 2)'
         : paddings.desktopPagePadding
     };
  }
  `}
`;

export const NavigationBlock = styled.div<{ withoutTitleBlock?: boolean }>`
  display: flex;
  align-items: center;

  ${props => props.withoutTitleBlock && 'width: 100%;'}
`;

export const LogoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding-right: 1rem;
  padding: 0.25rem 0;
`;

interface LogoTitleProps {
  bigNavigationLength?: boolean;
  withoutTitleBlock?: boolean;
  isDropDown?: boolean;
  withoutBorder?: boolean;
  isServices?: boolean;
}
export const TitleServicesWrapper = styled.div<LogoTitleProps>`
  position: relative;
  padding-right: 1.25rem;

  ${props =>
    !props.withoutTitleBlock &&
    !props.withoutBorder &&
    ` @media only screen and (min-width: ${Breakpoints.md}px) {
    

  &:after{
      content: '';
      position: absolute;
      width: 1px;
      height: 17px;
      right: 0;
      top: 10px;
      background: ${colorVariables.additionalBorder};
    }

   
  }
${
  props.isDropDown &&
  `

 border-right: ${
   !props.bigNavigationLength && `1px solid ${colorVariables.additionalBorder}`
 };
   `
}
  
  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    border-right: 1px solid ${colorVariables.additionalBorder};
  }`}
`;

export const ServicesTitle = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  text-align: center;

  color: ${colorVariables.alternativeTextColor};

  opacity: 0.8;
  width: max-content;

  transition: ${transitions.textHoverTransition};
  &:hover {
    opacity: 1;
  }
`;
export const LogoTitle = styled.div<LogoTitleProps>`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 100%;

  ${props =>
    props.isServices
      ? `
    padding-right: 1.25rem;
  width: max-content;

    ${
      !props.withoutTitleBlock &&
      !props.withoutBorder &&
      ` @media only screen and (min-width: ${Breakpoints.md}px) {
    border-right: ${
      !props.bigNavigationLength &&
      `1px solid ${colorVariables.additionalBorder}`
    };

   
  }
${
  props.isDropDown &&
  `

 border-right: ${
   !props.bigNavigationLength && `1px solid ${colorVariables.additionalBorder}`
 };
   `
}
  
  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    border-right: 1px solid ${colorVariables.additionalBorder};
  }`
    }

  `
      : `margin-top: 2px;`}
`;

export const Navigation = styled.div<{ withoutTitleBlock?: boolean }>`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 0.625rem 1.875rem;
  padding: 0 1.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  max-width: 100%;
  color: ${colorVariables.mainTextColor};

  ${props => props.withoutTitleBlock && 'margin-left: auto;'}

  @media only screen and (min-width: 1600px) {
    max-width: 75%;
  }
`;

export const NavItem = styled.a`
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};

  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
`;

export const ControlsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  ${Button} {
    padding: 0.5rem 0.675rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${colorVariables.alternativeTextColor};
    background-color: ${colorVariables.yellowButtonBackground};
    border: 1px solid ${colorVariables.yellowButtonBorder};
    transition: ${transitions.allHoverTransition};

    &:hover {
      @media only screen and (min-width: ${Breakpoints.md}px) {
        background: none;
        border: 1px solid ${colorVariables.buttonBorder};
        color: ${colorVariables.mainTextColor};
      }
    }

    @media only screen and (min-width: ${Breakpoints.md}px) {
      padding: 0.5rem 2.5rem;
    }
  }
`;

export const HeartIcon = styled.div<{ isFavourite: boolean }>`
  display: block;
  min-width: 20px:
  min-height: 20px;
  cursor: pointer;

  & svg {
    transition: 0.3s fill ease;
    fill: ${props =>
      props.isFavourite ? colorVariables.alternativeTextColor : 'inherit'};
  }

  & path {
    stroke: ${props =>
      props.isFavourite
        ? colorVariables.alternativeTextColor
        : colorVariables.mainTextColor};
  }
`;

export const TextBlock = styled.div<{ withoutTitleBlock?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
  align-items: center;
  max-width: 100vw;
  height: 100vh;

  @media (min-height: 1100px) {
    height: 50vh;
  }
  ${props =>
    !props.withoutTitleBlock &&
    `@media only screen and (min-width: ${Breakpoints.xxl}px) {max-width: 60vw;}`}
`;

export const SloganBlock = styled.div<{ titleSize?: string }>`
  font-weight: 700;
  font-size: 1.87rem;
  text-align: center;
  width: 100%;
  line-height: 100%;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: ${props => (props.titleSize ? props.titleSize : '5.625rem')};
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: ${props => (props.titleSize ? props.titleSize : '5.625rem')};
  }
`;

export const DescriptionBlock = styled.div<{ descriptionColor?: string }>`
  display: block;
  text-align: center;
  font-size: 0.875rem;
  line-height: 157.6%;
  color: ${props =>
    props.descriptionColor
      ? props.descriptionColor
      : colorVariables.mainTextColorWitOpacity};
  @media only screen and (min-width: ${Breakpoints.md}px) {
    max-width: 45.625rem;
  }
`;

export const ArrowBlock = styled.div`
  position: absolute;
  bottom: 0;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 2.75rem;

  .arrow-icon {
    fill: red !important;
  }

  svg {
    cursor: pointer;
  }

  &:hover {
  }
`;
export const BgImageWrapper = styled.div<{ bgImage?: string }>`
  z-index: 10;
`;

///////another header styles

export const TextBlockWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    align-items: flex-start;
  }
  @media only screen and (max-width: ${Breakpoints.xlg}px) {
    border: 1px solid rgb(101, 105, 109);
  }
`;
export const TitleBlockWrapper = styled.div``;

export const TitleBlock = styled.p`
  font-weight: 700;
  font-size: 2.3125rem;
  text-align: center;
  width: 100%;
  line-height: 100%;
  text-transform: uppercase;
  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 3.75rem;
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    font-size: 5rem;
    line-height: 98px;

    .translated & {
      font-size: 3rem;
    }
  }

  .us {
    color: rgba(101, 105, 109, 1);
  }
`;

export const DescriptionBlockWrapper = styled.div`
  padding: 1rem 0.3125rem 1.875rem 0.3125rem;
  max-width: 20rem;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    max-width: 35.625rem;
  }
  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    max-width: 81.25rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${colorVariables.blockBorderColor};
    padding: 2.5rem;
  }
`;

export const Description = styled.p`
  font-family: 'Overpass';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colorVariables.footerReservedTitle};
  @media only screen and (max-width: ${Breakpoints.md}px) {
    text-align: center;
  }
  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

export const TextTopBlockWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 5rem;
  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0px 75px 0px 0px;
  }
  @media only screen and (max-width: ${Breakpoints.xlg}px) {
    border-bottom: 1px solid ${colorVariables.blockBorderColor};
  }
  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: ${colorVariables.blockBorderColor};
  }
`;

export const SecondLogoImgWrapper = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-right: 1px solid ${colorVariables.blockBorderColor};
  backdrop-filter: blur(2px);
  padding: 2.5rem 5rem;
  margin-right: 4.375rem;

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    padding: 3.75rem 6.25rem;
  }
`;

export const SecondLogoImg = styled.img`
  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    width: 5.9375rem;
    height: 5rem;
  }
`;

export const DropDownWrapperText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .drop-down-icon {
    fill: #ffffff;
    opacity: 0.6;
  }
`;
export const DropDownText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #eaeaea;
  margin-right: 5px;
`;

export const DropDownIcon = styled.img``;
