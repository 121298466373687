export const navItems = {
  services: 'Послуги',
  benefits: 'Переваги',
  stages: 'Етапи',
};

export const description =
  'Ви хочете перетворити свій відкритий простір на красивий і функціональний оазис? Зверніться до наших експертів з ландшафтного дизайну. Наша команда досвідчених дизайнерів та архітекторів допоможе вам втілити ваше бачення в реальність.';

export const currentTexts = {
  label: 'Послуги',
  description:
    'У нашій службі ландшафтного дизайну ми пропонуємо широкий спектр послуг, які допоможуть вам перетворити ваш відкритий простір. Наші послуги включають:',
};
export const processList = {
  labelFirst: 'Консультація та аналіз сайту',
  descriptionFirst:
    'Перш ніж розпочати будь-який проект ландшафтного дизайну, ми починаємо з консультації та аналізу ділянки. Це включає в себе виїзд на місце, щоб оцінити існуючі особливості вашої власності, умови ґрунту та сонячного світла, а також ваше бачення та вподобання щодо простору. Після цього ми створюємо індивідуальний план дизайну, пристосований до ваших унікальних потреб та вподобань',

  labelSecond: 'Концептуальний дизайн',
  descriptionSecond:
    'Потім наша команда розробить концептуальний дизайн, який включає ваші ідеї та вподобання, а також наші експертні рекомендації. Ми використовуємо найсучасніше програмне забезпечення для створення 3D-візуалізації дизайну, щоб ви могли уявити кінцевий продукт ще до початку робіт',

  labelThird: 'Будівництво та монтаж',
  descriptionThird:
    'Після того, як ви затвердите остаточний дизайн, наша команда приступить до будівництва та монтажу. Ми використовуємо лише найякісніші матеріали та працюємо з перевіреними підрядниками, щоб гарантувати, що кожен аспект проекту буде виконано досконало',

  labelFourth: 'Обслуговування та ремонт',
  descriptionFourth:
    'Ми не просто створюємо красиві відкриті простори - ми також допомагаємо їх підтримувати. Наша команда пропонує постійне обслуговування та догляд, щоб гарантувати, що ваш ландшафтний дизайн буде виглядати якнайкраще протягом довгих років.',
};

export const currentTextsBenefits = {
  description:
    'Вибір наших послуг з ландшафтного дизайну має низку переваг, серед яких:',
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Індивідуальний дизайн, адаптований до ваших унікальних потреб та вподобань',
  },
  { id: 2, text: 'Найсучасніше програмне забезпечення для 3D-візуалізації' },
  { id: 3, text: 'Високоякісні матеріали та перевірені підрядники' },
  { id: 3, text: 'Високоякісні матеріали та перевірені підрядники' },
  { id: 4, text: 'Поточне обслуговування та утримання' },
  {
    id: 5,
    text: 'Досвідчена та обізнана команда дизайнерів та архітекторів',
  },
];
