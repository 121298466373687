export const footerNavBar = {
  aboutUs: 'About us',

  services: 'Services',

  favourite: 'Favourite',

  contacts: 'Contacts',
};

export const footerTitles = {
  conditions: 'Conditions of use',
  privacy: 'Privacy policy',
  updated: 'Stay Updated with Us',
  reserved: '© MODERNACASA (2023) All rights reserved',
  placeholder: 'Email*',
};
