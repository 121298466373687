import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from './api.constants';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: builder => ({
    getServices: builder.query({
      query: language => ({
        url: `lead_forms/services/?lang=${language}`,
      }),
    }),
  }),
});

export const { useGetServicesQuery } = servicesApi;
