export const teamTexts = {
  label: 'Наша інвестиційна команда',
  description:
    'У Modernacasa ми віримо, що наші люди є нашим найбільшим активом. Наша інвестиційна команда складається з досвідчених професіоналів, які мають успішний досвід роботи в галузі.',
};

export const teamBlocks = {
  labelFirst: 'Інвестиційні аналітики',
  descriptionFirst:
    'Наші інвестиційні аналітики проводять глибокі дослідження та аналіз потенційних інвестиційних можливостей.',

  labelSecond: 'Портфельні менеджери',

  descriptionSecond:
    'Наші портфельні менеджери відповідають за формування та управління нашими інвестиційними портфелями.',

  labelThird: 'Ризик-менеджери',

  descriptionThird:
    'Наші ризик-менеджери тісно співпрацюють з інвестиційною командою для виявлення та ефективного управління ризиками.',
};
