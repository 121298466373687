import React, { FC } from 'react';
import { BookIcon } from '../AboutUsSection/AboutUsSection.styled';
import {
  OurStoryBlockWrapper,
  TopBlockWrapper,
  BookBlock,
  TitleBlock,
  Title,
  ImgTopBlock,
  BottomBlockWrapper,
  ImgBottomBlock,
  TextBlockWrapper,
  Text,
  Img,
} from './OurStoryBlock.styled';
import creators from '../../shared/assets/images/our-story-images/creators.png';
import office from '../../shared/assets/images/our-story-images/office.png';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';

interface StoryBlockProps {
  hoveredBookBlock?: boolean;
  asSection?: boolean;
}

export const OurStoryBlock: FC<StoryBlockProps> = ({
  hoveredBookBlock = true,
  asSection,
}) => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  return (
    <OurStoryBlockWrapper
      asSection={asSection}
      style={{
        opacity: hoveredBookBlock ? 1 : 0,
      }}>
      <TopBlockWrapper>
        <BookBlock>
          <BookIcon />
        </BookBlock>
        <TitleBlock>
          <Title>{t('mainPage.ourStoryTexts.title')}</Title>
        </TitleBlock>
        {isDesktop && (
          <ImgTopBlock>
            <Img src={creators} alt="creators" width={309} />
          </ImgTopBlock>
        )}
      </TopBlockWrapper>

      <BottomBlockWrapper>
        <ImgBottomBlock>
          <Img src={office} alt="office" width={643} />
        </ImgBottomBlock>

        <TextBlockWrapper>
          <Text>{t('mainPage.ourStoryTexts.text')}</Text>
        </TextBlockWrapper>
      </BottomBlockWrapper>
    </OurStoryBlockWrapper>
  );
};
