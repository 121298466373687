export const ContactsTitles = {
  contacts: 'Контакти',
  telNumber: '+34 600 59 20 07',
  email: 'info@modernacasa.es',
  socialMedia: ' Соц.медіа',
  form: 'Форма',
  name_placeholder: "Ім'я",
  email_placeholder: 'Електронна пошта*',
  phone_placeholder: 'Номер телефону',
  description_placeholder: 'Опис',
  password_placeholder: 'Пароль',
  send: 'Надіслати',
};