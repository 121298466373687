import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';
import { PartnersTabletSection } from '../../SmartHomePage/PartnersSection/TabletBlock';
import { PartnersMobileSection } from './MobileBlock';
import { ReactComponent as TrinaSolar } from '../../../shared/assets/icons/solar-energy/partners/trina-solar-logo.svg';
import { ReactComponent as Panasonic } from '../../../shared/assets/icons/solar-energy/partners/panasonic.svg';
import { ReactComponent as SunPower } from '../../../shared/assets/icons/solar-energy/partners/sun-power.svg';
import { ReactComponent as LgLogo } from '../../../shared/assets/icons/solar-energy/partners/lg-logo.svg';
export const PartnersSection: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  const icons = {
    firstIcon: <TrinaSolar className="other-icon" />,
     secondIcon: <SunPower className="other-icon sun-power" />,
    thirdIcon: <Panasonic className="other-icon" />,
   
    fourthIcon: <LgLogo className="other-icon lg" />,
  };
  return (
    <div>
      {isMobile ? (
        <PartnersMobileSection />
      ) : (
        <PartnersTabletSection
          isAnotherStyle
          description={t('solarEnergyPage.partnersSection.description')}
          icons={ isTablet? icons: null}
          withoutCenterIcon={isTablet}
        />
      )}
    </div>
  );
};
