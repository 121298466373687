export const CardsSection = {
  basic: {
    type: ' Basique',
    homePackage: ' Système de gardien à domicile',
    description: `T Cette configuration comprend un thermostat intelligent, un éclairage intelligent et un système de sécurité intelligent. Il permet de contrôler à distance la température, l'éclairage et la sécurité de votre maison depuis votre smartphone..`,
  },
  standart: {
    type: ' Standard',
    homePackage: ' Solution éco-intelligente',
    description: ` Cette configuration comprend toutes les fonctionnalités de la configuration de base, ainsi que des serrures intelligentes, un système d'irrigation intelligent et l'intégration de la commande vocale. Il permet une automatisation et un contrôle transparents des différents systèmes de votre maison.`,
  },
  premium: {
    type: ' Prime',
    homePackage: ' Configuration Oasis intelligente',
    description: `Passez à notre configuration alimentée par l'IA pour améliorer la gestion de l'énergie, le divertissement et la domotique. Parfait pour les grands ménages ou les propriétés commerciales à la recherche du nec plus ultra en matière de commodité et d'efficacité.`,
  },
};
