export const architectStepBlockTitle = ' Етапи Арквітекто';

export const architectStepBlockDescription = ' Первинна консультація';

export const architectStepSection = {
  blockTitle: 'Етапи Архітектури',
  descriptionTitle: 'Первинна консультація',
 stepOne: {
    title: 'Крок перший',
    p1: `Знайомство. Архітектор ближче знайомиться з замовником та об'єктом нерухомості або земельною ділянкою. На цьому початковому етапі архітектор спілкується з майбутнім замовником, з'ясовує його потреби, вимоги та очікування від майбутньої будівлі.`,
    p2: "Важливі всі найтонші нюанси: спосіб життя замовника, наявність дітей чи тварин, індивідуальні вподобання. Він також вивчає місце розташування об'єкта, аналізує місцевий контекст і навколишнє середовище, щоб визначити фактори, які впливають на дизайн будівлі",
  },
  stepTwo: {
    title: "Крок другий",
    p1: `Призначення об'єкта. Архітектор визначає основні вподобання клієнта щодо дизайну та функціональності будівлі. Це може включати визначення типу будівлі (житлова, комерційна, громадська) та стилю дизайну (класика, модерн, мінімалізм). `,
    p2: "Якщо об'єкт будується для подобової оренди, ймовірно, розміщенню та функціональності кухні не доведеться приділяти надто багато уваги",
  },
  stepThree: {
    title: "Крок третій",
    p1: "Вивчення ділянки. Тут архітектор знайомиться з територією, щоб зрозуміти її обмеження та можливості",
    p2: "Це може включати аналіз топографії, складу ґрунту та кліматичних умов, а також перевірку наявності комунікацій та інфраструктури",
  },
  stepFour: {
    title: "Крок четвертий",
    p1: "Аналіз правової складової. Визначення можливості вирішення завдань будівництва або реконструкції в рамках чинного законодавства та норм проектування",
    p2: "На цьому етапі архітектор визначає, наскільки реалістичним і здійсненним є проект з урахуванням вимог законодавства та норм проектування. Він також визначає вартість та ресурсоємність проекту",
  },
 stepFive: {
    title: "Крок п'ятий",
    p1: `Робота з документацією. Подача документів та отримання дозволу на будівництво або реконструкцію, отримання технічних умов від власників зовнішніх мереж водопостачання, газопроводу, електромереж.`,
    p2: ``,
  },
  stepSix: {
    title: 'Крок шостий',
    p1: `Функціональні рішення. Розробка архітектурної концепції - наступний крок після отримання дозволів та побажань замовника. На цьому етапі архітектор створює загальне уявлення про об'єкт, що проектується, включаючи визначення функціонального зонування та створення просторової схеми`,
    п2: "Створення концепції дає можливість оцінити потенціал об'єкта і зрозуміти, наскільки він буде відповідати побажанням замовника у функціональному аспекті питання. Чи буде йому комфортно жити в будинку з сім'єю або наскільки популярним буде об'єкт для оренди",
  },
  stepSeven: {
    title: 'Крок сьомий',
    p1: `Розробка ескізного проекту. Архітектор визначає архітектурні, конструктивні, функціональні та художні рішення об'єкта будівництва або реконструкції. `,
    p2: "Ескізний проект дозволяє замовнику побачити загальний вигляд об'єкта та оцінити візуальну відповідність своїм побажанням",
  },
  stepEight: {
    title: 'Крок восьмий',
    p1: "Розробка робочого проекту. Це завершальний етап архітектурного проектування, який включає в себе повний пакет проектної документації. В рамках розробки робочого проекту фахівець враховує архітектурний розділ, конструктивний розділ, каналізацію, водопровід, сантехніку, електрообладнання, вентиляцію, кондиціонування, опалення та інші складові.",
    p2: "Робочий проект містить детальний опис усіх конструктивних, технічних та інженерних рішень, необхідних для будівництва або реконструкції об'єкта",
  },
};