export const typeOfHouses = {
  airigh: 'Airigh',

  accessible: 'Accessible',
  bespokeDesign: 'Bespoke Design',
  whitehouse: 'Whitehouse',
  longhouse: 'Longhouse',
  steading: 'Steading',

};


export const modelTitle = 'Airigh Modular houses';
export const modelDescription = 'Choose your Compass ceramic pool from 24 models, which suits, thanks to its design, both to the modern residence and classical house. They are suitable for the garden, for the terrace next to the house or indoors as an indoor pool.';