import React, { FC, useState } from 'react';
import { HeaderSection } from './HeaderSection';
import {
  PreBuildHousesWrapper,
  BuildingSectionWrapper,
  ToogleWrapper,
  DropDownTitle,
  MenuWrapper,
} from './PreBuildHousesPage.styled';
import { ReactComponent as DownArrow } from '../../shared/assets/icons/drop-down-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { DropDownFilter } from '../../components/DropDownFilterBlock';
import { BuildingSection } from './BuildingSection';
import { useTranslation } from 'react-i18next';

export interface NavBarItemTitlesProps {
  itemName: string;
  routePath: string;
}

export const PreBuildHousesPage: FC = () => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const [currentTypeName, setCurrentTypeName] = useState<string>('all');
  const { t } = useTranslation();

  const navBarItemTitlesArray = t('mainPage.navBarItemTitlesArray', {
    returnObjects: true,
  }) as NavBarItemTitlesProps[];

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  const filterList = [
    { id: 7, type: 'All', name: t('mainPage.MenuItemsTitles.all') },
    { id: 1, type: 'Projects', name: t('mainPage.MenuItemsTitles.projects') },
    {
      id: 2,
      type: 'Smart home',
      name: t('mainPage.MenuItemsTitles.smart_home'),
    },
    {
      id: 3,
      type: 'Swimming pools',
      name: t('mainPage.MenuItemsTitles.swimming_pools'),
    },
    {
      id: 4,
      type: 'Modular houses',
      name: t('mainPage.MenuItemsTitles.modular_houses'),
    },
    {
      id: 5,
      type: 'Interior',
      name: t('mainPage.MenuItemsTitles.interior'),
    },
    {
      id: 6,
      type: 'Solar energy',
      name: t('mainPage.MenuItemsTitles.solar_energy'),
    },
  ];

  return (
    <PreBuildHousesWrapper>
      <HeaderSection
        filterList={filterList}
        setCurrentTypeName={setCurrentTypeName}
        currentTypeName={currentTypeName}
        currentLanguageName={navBarItemTitlesArray[0].itemName}
      />

      <BuildingSectionWrapper>
        {isMobile && (
          <MenuWrapper>
            <ToogleWrapper
              isOpenFilter={isOpenFilter}
              onClick={() => setIsOpenFilter(prev => !prev)}>
              <DropDownTitle>{navBarItemTitlesArray[0].itemName}</DropDownTitle>
              <DownArrow className="drop-down-icon" />
            </ToogleWrapper>
            {isOpenFilter && (
              <DropDownFilter
                filterList={filterList}
                rightPosition={'0'}
                setCurrentTypeName={setCurrentTypeName}
                setIsOpenFilter={setIsOpenFilter}
              />
            )}
          </MenuWrapper>
        )}
        <BuildingSection currentTypeName={currentTypeName} />
      </BuildingSectionWrapper>
    </PreBuildHousesWrapper>
  );
};
