export const MenuItemsTitles = {
  all: 'Tot',
  construction: 'Construcció',
  projects: 'Projectes',
  interior: 'Interior',
  modular_houses: 'Cases modulars',
  swimming_pools: 'Piscines',
  solar_energy: 'Energia solar',
  smart_home: ' Casa intel·ligent',
  inmobiliaria: 'Immobiliària',
  investments: 'Inversions',
  consulting: ' Consultoria',
  architect: 'Arquitectura',
  landscape_design: 'Disseny paisatgístic',
  building_materials: 'Materials de construcció',
  pre_build_houses: 'Сases construïdes',
};

export const MenuItemsDescriptions = {
  construction:
    `Experimenti el luxe i l'artesania amb els nostres serveis de construcció de viles. El nostre expert equip de constructors i contractistes dona vida a la seva visió, garantint una qualitat impecable i atenció al detall en cada pas. Des dels fonaments fins als tocs finals, lliurem viles excepcionals que reflecteixen el seu estil únic i superen totes les expectatives.`,
  projects:
    `Els projectes en l'àmbit d'edificis residencials impliquen planificar, dissenyar i construir llars o edificis d'apartaments. Requereixen una comprensió completa dels codis de construcció, els estàndards de seguretat i les necessitats de les persones que viuran en les estructures. Els projectes reeixits també han de considerar factors com la sostenibilitat, l' eficiència energètica i l' atractiu estètic. En general, els projectes de construcció residencial exerceixen un paper important en la provisió d' espais segurs,`,
  interior:
    `Elevi l'interior de la seva vila a noves altures de sofisticació amb els nostres serveis d'interiorisme a mida. El nostre equip de talentosos dissenyadors selecciona acabats, mobles i decoració exquisits, creant espais que destil·len luxe i confort. Amb una atenció meticulosa a cada detall, transformem la seva vila en un santuari captivador que complementa a la perfecció el seu estil de vida i mostra el seu estil personal.`,
  modular_houses:
    `Les cases modulars són una opció innovadora i flexible per a projectes de construcció residencial. Aquestes estructures es construeixen en seccions o ""mòduls"" fora del lloc i després es transporten al lloc de construcció per al seu assemblatge. Això permet un procés de construcció més ràpid i eficient amb menys materials desperdiciats. A més, les cases modulars es poden personalitzar per adaptar-se a una varietat d'estils i necessitats, cosa que les converteix en una excel·lent alternativa per a aquells que busquen una opció única i personalitzada.`,
  swimming_pools:
    ` Busques agregar un toc de luxe a la teva llar? Consideri els beneficis d'una piscina instal·lada. Les piscines residencials venen en una varietat d'estils i mides, cosa que les converteix en una excel·lent opció per a qualsevol llar. Poden proporcionar un espai divertit i relaxant perquè es reuneixin familiars i amics, alhora que augmenten el valor de la seva propietat. Ja sigui que estigui buscant una piscina petita i simple o una gran i elaborada, una piscina instal·lada pot transformar el seu pati del darrere en un impressionant oasi.`,
  solar_energy:
    `L'energia solar és una opció sostenible i rendible per alimentar la seva llar. En instal·lar panells solars a la seva teulada, pot generar la seva pròpia electricitat a partir dels raigs del sol. Això no només ajuda a reduir la seva petjada de carboni, sinó que també pot estalviar-li diners en les seves factures de serveis públics mensuals. Amb la configuració adequada, fins i tot pot vendre energia excedent a la xarxa.`,
  smart_home:
    `La tecnologia de llar intel·ligent permet una experiència de vida interconnectada i automatitzada. Amb un sistema de llar intel·ligent, pot controlar tot, des de la il·luminació i la temperatura fins a la seguretat i l'entreteniment, amb només uns pocs tocs al seu telèfon o comandaments de veu. Això no només brinda conveniència i comoditat, sinó que també pot augmentar l'eficiència energètica i la seguretat de la seva llar. En invertir en un sistema de llar intel·ligent, pot gaudir d'un espai vital més connectat, segur i eficient.`,
  inmobiliaria:
    `Trobi la parcel·la perfecta per al seu xalet d'eixida i simplifiqui el procés de construcció amb la nostra acurada selecció de parcel·les i projectes de xalets llestos per a construir. El nostre equip d'experts li garanteix l'accés a ubicacions privilegiades i plans predissenyats, cosa que facilita més que mai la construcció de la seva casa ideal amb comoditat i tranquil·litat.`,
  investments:
    `Maximitzi les seves inversions immobiliàries amb la nostra empresa promotora de viles. Amb la nostra experiència en la identificació d'ubicacions d'alt potencial i la creació de viles excepcionals, oferim una lucrativa oportunitat per fer créixer la seva cartera i aconseguir impressionants rendiments en el mercat immobiliari de luxe. Inverteixi amb confiança i permeti's ajudar-lo a alliberar tot el potencial dels seus projectes immobiliaris`,
  consulting:
    `Navegui pel mercat immobiliari espanyol amb facilitat i experiència a través dels nostres serveis integrals de consultoria. El nostre equip de professionals experimentats ofereix valuoses perspectives, anàlisi de mercat i orientació personalitzada per ajudar-lo a prendre decisions informades i assegurar oportunitats immobiliàries rendibles en el vibrant sector de la promoció de viles a Espanya.`,
  architect:
    `Transformi els seus somnis de construcció de viles en realitat amb l'experiència dels nostres qualificats arquitectes. Des de dissenys innovadors que harmonitzen amb l'entorn fins a una meticulosa atenció al detall, creem espais vitals excepcionals que superen les expectatives i deixen un llegat arquitectònic durador.`,
  landscape_design:
    `Realci la bellesa i l'encant de la seva vila amb els nostres serveis exquisits de disseny paisatgístic a Espanya. El nostre expert equip de dissenyadors crea impressionants espais a l'aire lliure, incorporant exuberants jardins, impressionants paisatges i captivadores característiques de l'aigua, assegurant que la seva vila destaqui com un veritable oasi de luxe i tranquil·litat.`,
  building_materials:
    `Oferim una àmplia selecció de materials de construcció d'alta qualitat per ajudar-lo a completar el seu projecte de construcció amb facilitat. Des dels materials bàsics fins als tocs finals, tenim tot el que necessita per fer realitat la seva visió.`,
};
