import i18next from 'i18next';

export const getTextsBenefits = () => {
  const textsBenefits = i18next.t(
    'buildingMaterialsPage.buildingTextsBenefits',
    {
      returnObjects: true,
    },
  ) as { id: number; text: string }[];

  return textsBenefits;
};
