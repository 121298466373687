export const conditionsText = [
  {
    id: 1,
    title: 'TERMS & CONDITIONS',
    text: `
     We are committed to protecting your privacy. This Privacy Policy describes how and why we process your personal data when you access our website at modernacasa.es (hereinafter “the website”), in the course of our marketing and recruitment activities, for communicating our services and business development. This policy also sets forth your privacy rights and explains how to get in touch with us if needed.
    `,
  },

  {
    id: 2,
    title: 'WHO ARE WE ?',
    text: `
    Modernacasa (hereinafter “we,” “us,” “our,” or “MC”) has created this website to communicate with casual visitors, potential clients, and companies interested in our services as well as to provide information about MC and the IT outsourcing industry. We welcome visitors to casually view information on our website and to use the site to directly communicate with MC. We control all personal data processed in accordance with this policy. By visiting this website, you accept our Privacy Policy and agree that we may collect personal data during your website visit. If you disagree with this Privacy Policy, please feel free to leave the website. This website is not intended to offer any services directly to children. We do not knowingly collect information from children. However, if you are under the age of 16 and want to get in touch with us, please, send us a written consent from your parents or guardians to process your personal information.
    `,
  },

  {
    id: 3,
    title: 'WHAT DATA DO WE COLLECT ?',
    text: `
   We collect, store and use data that is essential for giving visitors the maximum benefit from our website. We collect the following data during your website session:

Information you provide directly via contact forms or email messages, including your email address, phone number, name, surname, company name, job title, postal address and other information you are willing to provide us
Data about your behavior on web pages during your visit and information about how you navigate the website
Statistics, such as particular pages you visit, number of unique website entries, the browser from which you visit the website, your screen resolution, and the platform you use
Also, we collect your personal data in the course of our marketing activity and communication of our services in the most convenient way. In addition, we may use your personal data for secondary purposes closely related to the mentioned above purposes, in circumstances where you would reasonably expect such use. In the course of our marketing and recruitment activities, for communicating our services and business development we may collect the following data:

Information you provide directly via contact forms or email messages, including your email address, phone number, name, surname, company name, job title, postal address and other information you are willing to provide us
Data about your behavior on web pages during your visit and information about how you navigate the website
Statistics, such as particular pages you visit, number of unique website entries, the browser from which you visit the website, your screen resolution, and the platform you use
The above-mentioned data is collected on the base of your consent or other legal basis presented bellow and in compliance with applicable laws, along with required filings with data protection authorities, where required. You can send your consent by email to the info@modernacasa.es. We collect most non‐public personal information directly from you or your agents whether in person, by telephone or electronically. In some circumstances, we may be provided with your personal information by third parties (e.g. someone recommended you for potential employment). In such cases, we take reasonable steps to ensure that you are made aware of the data provided to us by the third party. MC does not intentionally collect any sensitive information about you.

    `,
  },

  {
    id: 4,
    title: 'HOW DO WE USE THIS DATA ?',
    text: `
   Personal data submitted to us through this website and personal data we collected from you, third parties or public sources will be used (processed) for the purposes specified in this Policy.
    `,
  },
  {
    id: 5,
    title: 'WE WILL USE YOUR PERSONAL DATA ON THE FOLLOWING LEGAL BASIS :',
    text: `
   Legitimate interest ( GDPR)
Consent (GDPR)
Other legal basis available under GDPR
Other legal basis available under GDPR
As a rule, your personal data will be used to :

Legitimate interest ( GDPR)
Consent (GDPR)
Other legal basis available under GDPR
Other legal basis available under GDPR
Personal data processed by MC is not subject to profiling and automated decision-making.

    `,
  },

  {
    id: 6,
    title: 'HOW LONG WILL WE RETAIN YOUR PERSONAL DATA :',
    text: `
   Legitimate interest ( GDPR)
Consent (GDPR)
Other legal basis available under GDPR
Other legal basis available under GDPR
As a rule, your personal data will be used to :

Legitimate interest ( GDPR)
Consent (GDPR)
Other legal basis available under GDPR
Other legal basis available under GDPR
Personal data processed by MC is not subject to profiling and automated decision-making.

    `,
  },

  {
    id: 7,
    title: 'WHAT ARE YOUR RIGHTS ?',
    text: `
   We aim to be completely transparent in how we use your personal data and take reasonable steps to allow you to correct, amend, delete, or limit the use of your personal data. You have the following data protection rights:

The right to be informed about the collection and use of your personal data. At any time, you can file an access request with us and ask for a copy of your personal data in a commonly used electronic format.
The right to request information on the purpose of data processing and information on third parties that have received your personal data.
The right to ask us to rectify personal data that is incorrect or outdated.
The right to restrict processing, meaning that you may request deletion of your personal data or ask us to stop processing your data at any time.
The right to request portability of your personal data.
The right to raise a justified objection to processing of your personal data.
The right to withdraw consent for processing data at any time if you have
previously provided us with consent for the processing of your personal data. MC will not charge any fee for your access request but may charge an administrative fee for providing a copy of your personal information. In order to protect your personal data, we may require identification from you before releasing the requested information. We promptly consider each your request and provide a substantive response either by taking the requested action or by providing an explanation for why your request cannot be executed on our side. You may exercise your rights by sending a request to the contacts listed in the contact details section below. If you have any complaints about how we process your data, please start with filing a complaint at info@modernacasa.es. We will try to do our best to resolve your application. In case you are not satisfied with our response, you have the right to lodge a complaint with the appropriate data protection authority at the place of your residence. Please keep in mind that if we are reaching you by email or correspondence, you may always unsubscribe from our mailing/marketing lists at any time by contacting by email indicated in this Policy or by using ‘unsubscribe’ button (link) in our email.

    `,
  },
  {
    id: 8,
    title: 'WHERE IS YOUR DATA PROCESSED ?',
    text: `
   Your personal data may be processed in various locations outside the European Union and the United States, each having different data protection standards. However, we will take all reasonable steps to ensure that your data is handled securely and in accordance with the highest standards provided for in the General Data Protection Regulation (GDPR, Regulation (EU) 2016/679 of the European Parliament and of the Council), this Privacy Policy, and industry standards.
    `,
  },
  {
    id: 9,
    title: 'PROCESSING PERSONAL DATA OF JOB APPLICANTS',
    text: `
  As part of any recruitment process, MC collects and processes personal data provided directly by job applicants, their referees, and recruitment companies working on our behalf. We are committed to being transparent about how the data is collected and used in compliance with our data protection obligations. Types of personal data we collect MC collects a range of information about you that includes:

telephone number, skype and links to social network profiles
Details of qualifications, skills, experience and employment history
Information about the current level of remuneration, including benefit entitlements
Information about your entitlement to work in the EU, if required by our clients
MC may collect personal data in a variety of ways. For example, data may be extracted from application forms, CVs (resumes), or through interviews or other means of assessment. Personal data is stored in our Applicant Tracking System and exported to other IT systems. If you are invited to MC for an interview, please be aware that for security reasons our premises are under video surveillance. We may collect images caught on camera.

    `,
  },

  {
    id: 10,
    title: 'PURPOSE OF PROCESSING',
    text: `
  Given the nature of our HR and recruiting activities, we need to process data in order to collaborate with you on possible job opportunities. MC has a legitimate interest in processing personal data during the recruitment process and for record-keeping purposes. Processing data of job applicants allows us to manage the recruitment process, assess and confirms suitability for employment. Provided your application is unsuccessful, MC will inform you and delete your personal data from all MC storages. However, if you give a separate consent, MC may keep your personal data on file for future prospective employment opportunities. You may withdraw your consent at any time.
    `,
  },

  {
    id: 11,
    title: 'RECIPIENTS OF PERSONAL DATA',
    text: `
  For the purposes of recruitment, we may share your personal data, including the data stored in our Applicant Tracking System, with affiliates, existing and prospective clients of MC. In this case, you will be informed separately. Also, we may disclose your personal data to other third parties mentioned in this Privacy Policy.
    `,
  },

  {
    id: 12,
    title: 'RETENTION PERIOD',
    text: `
  MC keeps personal data of job applicants until the vacancy is closed (not more than two (2) years). In case of candidature rejection, your personal data may be kept during the next one (1) year provided you give a separate consent and MC considers you as a potential candidate for other vacancies.
    `,
  },

  {
    id: 13,
    title: 'COOCKIES',
    text: `
  As is general practice, our website uses cookies to collect data and provide visitors with a more user-friendly browsing experience. Cookies are selected pieces of data that are stored on your computer to help a website recognize you on your next visit. Cookies automatically collect data that isn’t personally identifiable, such as preferred internet browser, operating system, domain name, website interactions, visit time, location, and most-viewed pages per visit. By accepting our Privacy Policy and remaining on the MC website, you agree with our website’s use of cookies. You may clean or disable cookies in your internet browser settings to delete this data from your device and stop data from being collected. Typically, browsers automatically accept cookies. If you want to reject cookies, block the use of cookies, delete cookies, or be notified when a cookie is sent, please change the appropriate settings in your browser. For more detailed information on this matter, please see our Cookie Policy.
    `,
  },
  {
    id: 14,
    title: 'EXTERNAL LINKS ON THE WEBSITE',
    text: `
  Our website may contain external links that take you to third-party websites. These websites operate independently of MC and are not covered by our Privacy Policy. Data that you share on a third-party website that is linked to from the MC website is covered by that third-party website’s own privacy policy and may be collected for use by such a third party. MC is not liable for data processed by third-party websites and cannot guarantee your safety when interacting with them.
    `,
  },
  {
    id: 15,
    title: 'REVISIONS TO THE PRIVACY POLICY',
    text: `
  MC reserves the right to make changes to this Privacy Policy at any time. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
    `,
  },

  {
    id: 13,
    title: 'CONTACT DETAILS',
    text: `
  If you have any questions or comments regarding this Privacy Policy, please send an email to info@modernacasa.es with the subject line "Privacy Policy Request"
    `,
  },
];
