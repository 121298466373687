import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';

export const ServicesPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${Breakpoints.maxWidth}px;
  margin: 0 auto;
  background-color: ${colorVariables.mainBackgroundColor};
`;

export const ServicesBlock = styled.div`
  padding: 9rem 0 2.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: center;
  gap: 1.25rem;
  width: 100%;
  background-color: ${colorVariables.mainBackgroundColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 9rem ${paddings.tabletSidePadding} 2.75rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 9rem ${paddings.desktopSidePadding} 2.75rem;
  }
`;

export const Service = styled.div<{ backgroundImage: string }>`
  position: relative;
  width: 100%;
  height: 17.5rem;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  transition: 0.3s transform ease;

  &:hover {
    transform: scale(1.01);
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: calc((100% - 1.25rem) / 2);
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc((100% - 2.5rem) / 3);
  }
`;

export const InfoBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1.25rem;
  top: 1.25rem;
  right: 1.25rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
`;
