export const footerNavBar = {
  aboutUs: 'Acerca de nosotros',
  services: 'Servicios',
  favourite: 'Favoritos',
  contacts: 'Contactos',
};

export const footerTitles = {
  conditions: 'Condiciones de uso',
  privacy: 'Política de privacidad',
  updated: 'Manténgase actualizado con nosotros',
  reserved: '© MODERNACASA (2023) Todos los derechos reservados',
  placeholder: 'Correo electrónico',
};