import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  CenterWrapper,
  TitleWrapper,
  Title,
  TextWrapper,
  Text,
} from './PreConstructionSection.styled';

export const PreConstructionSection: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CenterWrapper>
        <TitleWrapper>
          <Title>{t('constructionPage.preConstructionTitle')}</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text>{t('constructionPage.preConstructionText')}</Text>
        </TextWrapper>
      </CenterWrapper>
    </Wrapper>
  );
};
