export const investmentsProcess = [
  {
    number: 1,
    label: 'Le filtrage',
    description:
      "Nous utilisons une série d'outils et de techniques de sélection pour identifier les opportunités d'investissement potentielles.",
  },
  {
    number: 2,
    label: 'Recherche',
    description:
      "Nous menons une recherche fondamentale approfondie sur les entreprises et les secteurs dans lesquels nous envisageons d'investir",
  },
  {
    number: 3,
    label: "L'analyse",
    description:
      "Nous analysons les états financiers, les équipes de direction, le paysage concurrentiel et d'autres facteurs clés qui déterminent le succès des entreprises dans lesquelles nous envisageons d'investir.",
  },
  {
    number: 4,
    label: "L'évaluation",
    description:
      "Nous utilisons une série de techniques d'évaluation pour déterminer la valeur intrinsèque des entreprises dans lesquelles nous envisageons d'investir.",
  },

  {
    number: 5,
    label: 'Gestion des risques',
    description:
      "Nous adoptons une approche disciplinée de la gestion des risques, en mettant l'accent sur la préservation du capital et la prévention des pertes permanentes",
  },

];

export const investmentsProcessTexts = {
  label: "Notre processus d'investissement",
  description:
    "Notre processus d'investissement repose sur une approche rigoureuse et disciplinée de la recherche et de l'analyse. Nous pensons qu'une recherche et une analyse approfondies sont essentielles pour identifier des opportunités d'investissement de qualité et gérer efficacement les risques",
};