import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';

import {
  ConsultingHeaderServicesWrapper,
  ConsultingPageWrapper,
} from './ConsultingPage.styled';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { getProcessList } from './ConsultingPage.constants';
import { ProcessBlock } from '../../shared/components/ProcessBlock';

export const ConsultingPage: FC = () => {
  const menuItems = getMenuItems();
  const processList = getProcessList();
  const { t } = useTranslation();
  const consultingInfo = menuItems[8];
  const navItems = [
    {
      title: t('consultingPage.consultingNavItems.services'),
      sectionId: 'services-section',
    },
  ];
  const consultingProcessTexts = {
    label: t('consultingPage.consultingProcessTexts.label'),
    description: t('consultingPage.consultingProcessTexts.description'),
  };

  return (
    <ConsultingPageWrapper>
      <ConsultingHeaderServicesWrapper>
        <PageHeaderSection
          title={consultingInfo.label}
          logoImg={consultingInfo.icon}
          navItems={navItems}
          description={t('consultingPage.consultingDescription')}
          bgImage={consultingInfo.backgroundImg}
          id={consultingInfo.id}
        />
      </ConsultingHeaderServicesWrapper>

      <div id="services-section">
        <ProcessBlockHeader
          currentTexts={consultingProcessTexts}
          isTabletPadding
          titleSize={'50px'}
          descriptionMaxWidth={'787px'}
          isTitleBorder={false}
          mobileTitleSize={'1.875rem'}
        />
      </div>

      <ProcessBlock processList={processList} />
    </ConsultingPageWrapper>
  );
};
