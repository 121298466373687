export const teamTexts = {
  label: 'Nuestro equipo de inversión',
  description:
    'En Modernacasa creemos que nuestra gente es nuestro mayor activo. Nuestro equipo de inversión está formado por profesionales experimentados con una trayectoria de éxito en el sector.',
};

export const teamBlocks = {
  labelFirst: 'Analistas de inversión',
  descriptionFirst:
    'Nuestros analistas de inversiones llevan a cabo una investigación y un análisis en profundidad de las posibles oportunidades de inversión.',

  labelSecond: 'Gestores de carteras',

  descriptionSecond:
    'Nuestros gestores de cartera se encargan de construir y gestionar nuestras carteras de inversión.',

  labelThird: 'Gestores de riesgo',

  descriptionThird:
    'Nuestros gestores de riesgos colaboran estrechamente con nuestro equipo de inversión para identificar y gestionar eficazmente los riesgos.',
};
