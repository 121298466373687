import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { TopNavBarWrapper } from './TopNavBar.styled';
import { NavBarDesktop } from './DesktopBlock/NavBarDesktop';
import { Breakpoints } from '../../theme/Theme.constants';
import { NavBarTablet } from './TabletBlock';
import { useLocation } from 'react-router-dom';

export const TopNavBar: FC = () => {
  const location = useLocation();
  const isContactsPage = location.pathname === '/contacts';
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${Breakpoints.lg - 1}px)`,
  });

  return (
    <TopNavBarWrapper isContactsPage={isContactsPage}>
      {isDesktop && <NavBarDesktop />}
      {isTablet && <NavBarTablet />}
    </TopNavBarWrapper>
  );
};
