import React, { FC, useEffect } from 'react';
import { ProcessBlockHeader } from '../ProcessBlockHeader';
import {
  RealEstateServicesWrapper,
  List,
  Item,
  NumberWrapper,
  Number,
  TextWrapper,
  Text,
  IconWrapper,
  Icon,
} from './RealEstateServicesSection.styled';
import { ProcessListBlock } from '../ProcessListBlock';
import { useTranslation } from 'react-i18next';
import { getProcessList } from './RealEstateServicesSection.constants';
import { ReactComponent as Building } from '../../shared/assets/icons/real-estate/services/building.svg';
import { ReactComponent as Calculator } from '../../shared/assets/icons/real-estate/services/calculator.svg';
import { ReactComponent as Handshak } from '../../shared/assets/icons/real-estate/services/handshak.svg';
import { ReactComponent as Key } from '../../shared/assets/icons/real-estate/services/key.svg';
import { ReactComponent as Megaphone } from '../../shared/assets/icons/real-estate/services/megaphone.svg';
import { ReactComponent as ScaleBalanced } from '../../shared/assets/icons/real-estate/services/scale-balanced.svg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
export const RealEstateServicesSection: FC = () => {
  const { t } = useTranslation();
  // const processList = getProcessList();
  const realEstateProcessTexts = {
    label: t('realEstatePage.realEstateProcessTexts.label'),
    description: t('realEstatePage.realEstateProcessTexts.description'),
  };

  const list = [
    { number: 1, text: t('realEstatePage.processList.labelFirst'), icon: <Calculator /> },
    { number: 2, text:t('realEstatePage.processList.labelSecond'), icon: <Building /> },
    { number: 3, text: t('realEstatePage.processList.labelThird'), icon: <Megaphone /> },
    { number: 4, text: t('realEstatePage.processList.labelFourth'), icon: <Handshak /> },

    { number: 5, text: t('realEstatePage.processList.labelFifth'), icon: <Key /> },

    { number: 6, text: t('realEstatePage.processList.labelSixth'), icon: <ScaleBalanced /> },
  ];
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  return (
    <RealEstateServicesWrapper id="services-section">
      <ProcessBlockHeader
        currentTexts={realEstateProcessTexts}
        isTabletPadding
      />
      {/* <ProcessListBlock processList={processList} /> */}

      <List>
        {list.map(({ number, text, icon }) => {
          return (
            <Item>
              <NumberWrapper>
                <Number>{number}</Number>
              </NumberWrapper>
              <TextWrapper>
                <Text>{text}</Text>
              </TextWrapper>
              {isTablet && <IconWrapper>{icon}</IconWrapper>}
            </Item>
          );
        })}
      </List>
    </RealEstateServicesWrapper>
  );
};
