export const packagesTexts = {
label: 'Paquetes de inversión',
description:
'Nuestro proceso de inversión se basa en un enfoque riguroso y disciplinado de investigación y análisis. Creemos que la investigación y el análisis en profundidad son clave para identificar oportunidades de inversión de alta calidad y administrar el riesgo de manera efectiva.',
};

export const packagesBlocks = {
firstName: 'Nombre',
firstDownloadText: 'Descargar',

secondName: 'Nombre',
secondDownloadText: 'Descargar',

thirdName: 'Nombre',
thirdDownloadText: 'Descargar',
};