import { RoutePaths } from '../../../../shared/helpers/routes';

export const TopNavbarTitles = {
  // login: 'Iniciar sesión',
  call_to_action: 'Comience su proyecto',
};

export const navBarItemTitlesArray = [
  {
    itemName: 'Servicios',
    routePath: RoutePaths.services,
    storeItemName: 'Tienda',
  },
  {
    itemName: 'Acerca de nosotros',
    routePath: RoutePaths.aboutUsPage,
  },
  {
    itemName: 'Contactos',
    routePath: RoutePaths.contactsPage,
  },
  {
    itemName: 'Favoritos',
    routePath: RoutePaths.favourites,
  },
  // {
  //   itemName: 'Conectarse',
  //   routePath: RoutePaths.loginPage,
  // },
  {
    itemName: 'Inicie su proyecto',
    routePath: RoutePaths.leadForm,
  },
];



export const mobileNavBarItemTitlesArray = {
  itemNameLeadForm: 'Inicie su proyecto',
    routePathLeadForm: RoutePaths.leadForm,
  
  itemNameServices: 'Servicios',
  routePathServices: RoutePaths.services,
  
  itemNameAbout: 'Acerca de nosotros',
  routePathAbout: RoutePaths.aboutUsPage,

  itemNameContacts: 'Contactos',
  routePathContacts: RoutePaths.contactsPage,
 
  itemNameFavourite: 'Favoritos',
  routePathFavourite: RoutePaths.favourites,
};