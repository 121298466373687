export const CardsSection = {
  basic: {
    type: 'Básico',
    homePackage: ' Sistema de guardián del hogar',
    description: ` Esta configuración incluye un termostato inteligente, iluminación inteligente y un sistema de seguridad inteligente. Permite el control remoto de la temperatura, la iluminación y la seguridad de su hogar desde su teléfono inteligente.`,
  },
  standart: {
    type: ' Estándar',
    homePackage: ' Solución Eco-Inteligente',
    description: ` Esta configuración incluye todas las características de la configuración básica, además de cerraduras inteligentes, un sistema de riego inteligente e integración de control por voz. Permite la automatización y el control continuos de los diversos sistemas de su hogar.`,
  },
  premium: {
    type: ' De primera calidad',
    homePackage: ' Configuración inteligente de Oasis',
    description: `Actualice a nuestra configuración impulsada por IA para mejorar la administración de energía, el entretenimiento y la automatización del hogar. Perfecto para hogares más grandes o propiedades comerciales que buscan lo último en comodidad y eficiencia.`,
  },
};
