import React, { FC, useEffect } from 'react';
import {
  FAQcontent,
  ContentTitle,
  ContentDescription,
  ContentTitleBlock,
  FAQIcon,
} from './FAQItem.styled';
import { FaqItemComponentProps } from '../../MenuSection/MenuSection.types';
import { motion, AnimatePresence } from 'framer-motion';

export const FAQItem: FC<FaqItemComponentProps> = ({
  title,
  description,
  id,
  activeFAQId,
  setActiveFAQId,
  activeIdAfterClick,
}) => {
  const toggleOpen = () => {
    id === activeFAQId ? setActiveFAQId(0) : setActiveFAQId(id);
  };

  useEffect(() => {
    setActiveFAQId(0);
  }, [activeIdAfterClick, setActiveFAQId]);

  return (
    <FAQcontent onClick={toggleOpen}>
      <ContentTitleBlock>
        <ContentTitle isOpen={id === activeFAQId}>{title}</ContentTitle>
        <FAQIcon isOpen={id === activeFAQId} />
      </ContentTitleBlock>
      <AnimatePresence>
        {id === activeFAQId && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            style={{ overflow: 'hidden' }}>
            <ContentDescription>{description}</ContentDescription>
          </motion.div>
        )}
      </AnimatePresence>
    </FAQcontent>
  );
};
