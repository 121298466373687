export const navItems = {
  services: ' Prestations de service',
};

export const consultingProcessTexts = {
  label: 'Services de conseil',
  description: `Les services de conseil en immobilier sont fournis afin que nous puissions nous sentir confiants dans notre choix et ne pas nous inquiéter des risques potentiels. Nous répondrons à toutes vos questions relatives aux nuances juridiques et aux aspects techniques afin que vous puissiez construire la maison de vos rêves sans "surprises".`,
};

export const description =
  "Nos services de conseil constituent un outil puissant pour la réussite des investissements et de la gestion des biens immobiliers. Ils aident les clients à prendre des décisions éclairées sur la base d'une analyse fondamentale du marché et d'une expertise professionnelle dans le domaine de l'immobilier. Cette option comprend une large gamme de services - de l'évaluation des opportunités d'investissement à l'élaboration de stratégies d'investissement, en passant par l'optimisation de la gestion des actifs et l'augmentation de la rentabilité. Nous utilisons des technologies innovantes et des outils d'analyse de données dans notre travail afin de fournir des résultats précis et de haute qualité. Pour utiliser ce service, nous pouvons vous inviter à notre bureau de Barcelone ou vous proposer un appel en ligne.";

export const processList = [
  {
    number: 1,
    label: 'Recherche de marché',
    description: `Réalisation d'une analyse approfondie du marché immobilier, comprenant l'étude des tendances, de la demande, de l'offre, de l'environnement concurrentiel et de la prévision des tendances futures. L'analyse du marché immobilier est un outil qui nous aide à collecter des informations pour savoir s'il vaut la peine d'investir dans l'immobilier ou pour déterminer le potentiel locatif d'une ville ou d'une région. C'est l'occasion de comprendre quelles sont les maisons qui sont populaires aujourd'hui et quel type de bien immobilier sera demandé à long terme, au cours des prochaines années.`,
  },
  {
    number: 2,
    label: 'Conseil en investissement',
    description: `Une option distincte de notre service. Assistance aux investisseurs dans la prise de décisions éclairées sur les investissements immobiliers, notamment l'évaluation du potentiel des biens, l'analyse financière, la planification stratégique et les conseils sur l'optimisation de la rentabilité. Le service comprend également la détermination du niveau de risque pour les prêteurs associé au financement de l'achat de biens immobiliers. L'analyse aide à évaluer la stabilité du marché, les risques possibles et l'impact sur le retour des fonds empruntés.`,
  },
  {
    number: 3,
    label: `Gestion d'actifs`,
    description: `Assistance aux propriétaires de biens immobiliers dans la gestion et l'optimisation efficaces de leur portefeuille, notamment l'élaboration d'une stratégie de gestion, le marketing, la location, la gestion des frais d'exploitation et le suivi des résultats. Au cours des consultations sur la gestion d'actifs, une analyse de l'état actuel des biens immobiliers, une évaluation de leur valeur marchande, une analyse des flux de revenus et de dépenses, une évaluation de l'efficacité opérationnelle et des risques sont réalisées. Les consultants aident également les clients à élaborer et à mettre en œuvre des stratégies visant à améliorer la rentabilité, à réduire les coûts, à optimiser la gestion et à attirer et fidéliser les locataires.`,
  },
  {
    number: 4,
    label: 'Conseil en immobilier commercial',
    description: `Fournir des conseils sur l'immobilier commercial, notamment la location, la vente, l'achat et le développement de biens commerciaux tels que des bureaux, des locaux commerciaux, des complexes industriels et des hôtels. Un service idéal pour ceux qui ont prévu d'acquérir leur propre hôtel sur les rives de la Méditerranée ou de l'océan Atlantique, mais ne savent pas par où commencer et comment évaluer leurs perspectives financières.`,
  },
  {
    number: 5,
    label: 'Conseils sur le développement de projets',
    description: `Assistance dans le développement, la planification et la gestion de projets immobiliers, notamment la sélection de terrains, l'élaboration de concepts, la coordination de la construction, la gestion budgétaire et le contrôle de la qualité. Il s'agit d'un soutien individuel du client à toutes les étapes, qui souhaite s'assurer que ses actions sont correctes et sécuriser ses investissements.`,
  },
  {
    number: 6,
    label: 'Conseils juridiques',
    description: `Fournir des conseils juridiques et un soutien pour les transactions immobilières, notamment la vérification de la validité juridique des biens, la rédaction et l'analyse de contrats, le règlement des litiges et la protection des intérêts des clients. Nos experts suivent toutes les évolutions du cadre législatif et vous informent des pièges potentiels qui peuvent survenir avec le temps.`,
  },
  {
    number: 7,
    label: 'Conseils en durabilité et en efficacité énergétique',
    description: `Assistance dans le développement et la mise en œuvre de mesures visant à réduire l'impact environnemental des biens immobiliers, notamment l'évaluation de l'efficacité énergétique. En collaboration avec un consultant, une décision peut être prise sur l'utilisation de l'énergie éolienne ou solaire et évaluer la pertinence d'une telle initiative. Ainsi, les clients sont en mesure de réduire les coûts d'exploitation, d'augmenter la valeur de leurs installations et de se conformer aux normes et exigences environnementales.`,
  },
  {
    number: 8,
    label: `Conseils sur les questions d'intérieur et d'extérieur`,
    description: `Aider les propriétaires de biens immobiliers et les promoteurs à créer des espaces intérieurs et extérieurs attrayants et fonctionnels. L'objectif de ces consultations est de garantir une utilisation optimale de l'espace et de créer des intérieurs et des extérieurs esthétiquement agréables qui répondent aux besoins et aux attentes des clients, tout en reflétant l'unicité et le caractère du bien immobilier. Les consultants en intérieur et en extérieur travaillent sur la conception et l'aménagement des locaux, le choix des matériaux et des éléments de finition, le mobilier, l'éclairage, les schémas de couleurs et d'autres aspects qui influent sur l'atmosphère générale et l'apparence visuelle du bien immobilier. Ils fournissent un service complet pour donner vie aux murs de béton froid.`,
  },
];
