import { combineReducers } from '@reduxjs/toolkit';
import { servicesApi } from '../../api';
import { leadFormQuestionsApi } from '../../api/leadFormQuestionsApi';
import { globalReducer } from './globalReducer';
import { formsApi } from '../../api/formsApi';

export default combineReducers({
  global: globalReducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
  [leadFormQuestionsApi.reducerPath]: leadFormQuestionsApi.reducer,
  [formsApi.reducerPath]: formsApi.reducer,
});
