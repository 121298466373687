
import React, { FC, useEffect, useState } from 'react';
import {
  HousesDetailsWrapper,
  MainImage,
  BlockWrapper,
  NumberWrapper,
  Number,
  TextWrapper,
  Text,
  CurrentNumber,
} from './CaseHousesDetailsSection.styled';

import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';

interface HouseDetailsProps {
  housesDetails: { number: number; text: string }[];
  image: string;
  Block: any;
}
export const CaseHousesDetailsSection: FC<HouseDetailsProps> = ({
  housesDetails,
  image,
  Block,
}) => {
  const [isTextVisible, setIsTextVisible] = useState<boolean>(false);
  const [currentBlock, setCurrentBlock] = useState<number>(0);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  return (
    <HousesDetailsWrapper>
      <BlockWrapper>
        <MainImage
          src={image}
          onClick={() => {
            if (!isTextVisible) return;
            setIsTextVisible(false);
            setCurrentBlock(0);
          }}
        />

        {housesDetails.map(({ number, text }) => {
          const isAnotherStyle = currentBlock === number && isTextVisible;
          const isCurrentBlock = currentBlock === number;
          return (
            <Block currentBlock={currentBlock}>
              <NumberWrapper
                key={number}
                isAnotherStyle={isAnotherStyle}
                onClick={() => {
                  setCurrentBlock(number);
                  setIsTextVisible(true);
                  if (isCurrentBlock) {
                    setIsTextVisible(false);
                    setCurrentBlock(0);
                  }
                }}>
                <Number isAnotherStyle={isAnotherStyle}>{number}</Number>
              </NumberWrapper>

              {isTablet && isAnotherStyle && (
                <TextWrapper isAnotherStyle={isAnotherStyle}>
                  <Text isAnotherStyle={isAnotherStyle}>{text}</Text>
                </TextWrapper>
              )}
            </Block>
          );
        })}
      </BlockWrapper>

      {isMobile && isTextVisible && (
        <TextWrapper>
          {housesDetails.map(({ number, text }) => {
            if (number !== currentBlock) return null;

            return (
              <Text>
                <CurrentNumber>{currentBlock}.</CurrentNumber>
                {text}
              </Text>
            );
          })}
        </TextWrapper>
      )}
    </HousesDetailsWrapper>
  );
};
