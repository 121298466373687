import React, { FC, useState } from 'react';
import {
  StepsBlockWrapper,
  StepsBlock,
  StepsTitle,
  Steps,
  StepRow,
  StepTitle,
  StepNumber,
  DescriptionsBlock,
  DescriptionsTitle,
  Description,
  StepsContainer,
} from './StepsBlock.styled';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';

interface StepProps {
  id: number;
  title: string;
  description: React.ReactNode;
}

export interface StepsBlockComponentProps {
  stepsData: StepProps[];
  blockTitle: string;
  description: string;
  sectionId?: string;
}

export const StepsBlockComponent: FC<StepsBlockComponentProps> = ({
  stepsData,
  blockTitle,
  description,
  sectionId,
}) => {
  const [activeStepId, setActiveStepId] = useState<number | null>(
    stepsData[0].id,
  );
  // const [intervalId, setIntervalId] = useState<any>(null);
  // const stepsBlockRef = useRef<HTMLDivElement>(null); STEPS ANIMATION LOGIC

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const handleClick = (id: number) => {
    if (id === activeStepId) {
      return;
    }

    // STEPS ANIMATION LOGIC
    // stopTimer();
    // startTimer();

    setActiveStepId(id);
  };

  // STEPS ANIMATION LOGIC
  // const startTimer = () => {
  //   if (activeStepId && activeStepId > stepsData.length) {
  //     stopTimer();
  //     setActiveStepId(stepsData[0].id);
  //   }
  //   setIntervalId(
  //     setInterval(() => {
  //       setActiveStepId(count => {
  //         if (count && count === stepsData.length) {
  //           stopTimer();
  //           setActiveStepId(1);
  //         }
  //         return count && count + 1;
  //       });
  //     }, 5000),
  //   );
  // };

  // const stopTimer = () => {
  //   clearInterval(intervalId);
  // };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setActiveStepId(1);
  //         startTimer();
  //       } else {
  //         setActiveStepId(null);
  //         stopTimer();
  //       }
  //     },
  //     { rootMargin: '0px 0px -50% 0px' },
  //   );

  //   if (stepsBlockRef.current) {
  //     observer.observe(stepsBlockRef.current);
  //   }

  //   return () => {
  //     if (stepsBlockRef.current) {
  //       // eslint-disable-next-line react-hooks/exhaustive-deps
  //       observer.unobserve(stepsBlockRef.current);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stepsData]);

  return (
    <StepsBlockWrapper id={sectionId}>
      {isMobile && <StepsTitle>{blockTitle}</StepsTitle>}
      {isMobile ? (
        <StepsContainer>
          <StepsBlock>
            <Steps>
              {stepsData.map((step: StepProps) => (
                <StepNumber
                  key={step.id}
                  isActiveStep={activeStepId === step.id}
                  onClick={() => setActiveStepId(step.id)}>
                  {step.id}
                </StepNumber>
              ))}
            </Steps>
          </StepsBlock>
          <DescriptionsBlock>
            <Description stepsCount={stepsData.length}>
              <DescriptionsTitle>{description}</DescriptionsTitle>

              {activeStepId && stepsData[activeStepId - 1].description}
            </Description>
          </DescriptionsBlock>
        </StepsContainer>
      ) : (
        <StepsContainer>
          <StepsBlock>
            <StepsTitle>{blockTitle}</StepsTitle>
            <Steps>
              {stepsData.map((step: StepProps) => (
                <StepRow key={step.id} onClick={() => handleClick(step.id)}>
                  <StepTitle isActiveStep={activeStepId === step.id}>
                    {/* STEPS ANIMATION LOGIC */}
                    {/* <Filler
                      as={motion.div}
                      animate={{
                        width: activeStepId === step.id ? `100%` : `0`,
                      }}
                      transition={{
                        ease: 'linear',
                        duration: 5,
                        x: { duration: 0 },
                      }}
                    /> */}
                    {/* <StepTitleText isNotActive={activeStepId !== step.id}> */}
                    {step.title}
                    {/* </StepTitleText> */}
                  </StepTitle>
                  <StepNumber>{step.id}</StepNumber>
                </StepRow>
              ))}
            </Steps>
          </StepsBlock>
          <DescriptionsBlock>
            <DescriptionsTitle>{description}</DescriptionsTitle>
            <Description
              // onMouseEnter={stopTimer}
              // onMouseLeave={startTimer}
              stepsCount={stepsData.length}>
              {activeStepId
                ? stepsData[activeStepId - 1]?.description
                : stepsData[0]?.description}
            </Description>
          </DescriptionsBlock>
        </StepsContainer>
      )}
    </StepsBlockWrapper>
  );
};
