import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const ProcessListBlockWrapper = styled.div<{ anotherStyle?: boolean }>`
  ${props =>
    !props.anotherStyle &&
    `background: ${colorVariables.alternativeBackground};`}
`;

export const ProcessListWrapper = styled.div<{ anotherStyle?: boolean }>`
  position: relative;
  z-index: 15;
  ${props => props.anotherStyle && ' padding: 0px 1.25rem 0 1.25rem;'}
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 2.5rem 0 2.5rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0px 6.25rem 0 6.25rem;
  }
`;

export const ProcessList = styled.ul`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
  }
`;

export const ProcessItem = styled.li<{ anotherStyle?: boolean }>`
  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    ${props =>
      props.anotherStyle &&
      ` 
    height: 20.625rem;   
    border: 1px solid rgba(255, 255, 255, 0.2);  
    position: relative;
      
    `}
  }

  @media (min-width: ${Breakpoints.md}px) {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 20.625rem;
    flex-basis: calc(50% - 0.625rem);
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    height: 20.625rem;
    flex-basis: calc(100% / 4 - 15px);

    ${props =>
      props.anotherStyle &&
      `
    
    background: rgba(23, 23, 25, 0.1);
    backdrop-filter: blur(10px);
    `}
  }
`;

export const ProcessItemTopWrapper = styled.div<{ anotherStyle?: boolean }>`
  display: flex;

  ${props =>
    !props.anotherStyle
      ? `
      
  @media (max-width: ${Breakpoints.md}px) {
    border-width: 0.0625rem 0rem;
    border-style: solid;
    border-color: ${colorVariables.aboutUsOpacityPart};
  }
  
  `
      : `
  
    align-items: center;
    justify-content: center;
    height: 20.625rem;
    padding: 0 30px;
  `}

  @media (min-width: ${Breakpoints.md}px) {
    align-items: center;
    justify-content: center;
    height: 20.625rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    height: 20.625rem;
  }
`;

export const ProcessNumberWrapper = styled.div<{ anotherStyle?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;

  ${props =>
    !props.anotherStyle
      ? `
   background: ${colorVariables.processNumberBackground};
  border-right: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
  `
      : `
  border-width: 0px 1px 1px 0px;
border-style: solid;
border-color: rgba(255, 255, 255, 0.2);
background: rgba(23, 23, 25, 0.5);
    `}

  @media (max-width: ${Breakpoints.md}px) {
    margin-right: 1.25rem;
  }

  ${props =>
    !props.anotherStyle
      ? `
  @media (min-width: ${Breakpoints.md}px) {
    position: absolute;
    left: 0;
    top: 0;
    height: 3.75rem;
    border-bottom: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
  }
  
  `
      : `
  position: absolute;
    left: 0;
    top: 0;
    height: 3.75rem;
    border-bottom: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
  `}
`;

export const ProcessNumber = styled.span`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;

  color: ${colorVariables.mainTextColor};
`;

export const ProcessItemTitle = styled.p<{ anotherStyle?: boolean }>`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  padding: 1.25rem 0rem 1.25rem 0rem;

  color: rgba(255, 255, 255, 0.9);

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 0.9375rem;
  }
  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 1.125rem;
  }

  ${props => props.anotherStyle && 'text-align: center'}
`;
