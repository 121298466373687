export const HeaderSection = {
  pools: 'Piscines',
  colors: 'Colors',
  technology: 'Tecnologia',
  accessories: 'Accesoris',
  stages: 'Etapes',
  order: 'Sol·licitar',
   description: "Desitja afegir un toc de luxe a la seva llar? Consideri els avantatges d'una piscina instal·lada. Les piscines residencials estan disponibles en una gran varietat d'estils i mides, la qual cosa les converteix en una gran opció per a qualsevol llar. Poden proporcionar un espai divertit i relaxant perquè es reuneixin familiars i amics, alhora que augmenten el valor de la seva propietat. Tant si busca una piscina petita i senzilla com una gran i elaborada, una piscina instal·lada pot transformar el seu jardí en un impressionant oasi. ",
};

