export const currentTexts = {
  label: 'Наші матеріали',
  description: 'У нашому відділі ландшафтного дизайну ми пропонуємо широкий спектр послуг, які допоможуть вам перетворити ваш відкритий простір. Наші послуги включають в себе:'
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Широкий вибір високоякісних будівельних матеріалів',
  },
  { id: 2, text: 'Конкурентоспроможні ціни' },
  { id: 3, text: 'Кваліфікований персонал з глибокими знаннями про продукцію' },
  { id: 4, text: 'Зручні варіанти доставки' },
  {
    id: 5,
    text: 'Виняткове обслуговування клієнтів',
  },
];

export const benefitsTitle = 'Чому обирають нас?';
export const materialsList = {
  titleFirst: 'Бетон і цегляна кладка',
  textFirst:
    'Ми пропонуємо широкий асортимент бетонних та кладочних матеріалів, включаючи цеглу, блоки, бруківку та цемент. Ці матеріали ідеально підходять для будівництва фундаментів, підпірних стін, внутрішніх двориків тощо.',

  titleSecond: 'Пиломатеріали та каркасні роботи',
  textSecond:
    'Наші пиломатеріали та каркасні матеріали включають в себе габаритні пиломатеріали, фанеру, OSB та інші. Ці матеріали необхідні для зведення стін, дахів та підлог.',

  titleThird: 'Покрівля та сайдинг',
  textThird:
    'Ми пропонуємо широкий асортимент покрівельних та сайдингових матеріалів, включаючи бітумну черепицю, металочерепицю, вініловий сайдинг тощо. Ці матеріали мають вирішальне значення для захисту вашої споруди від негоди.',

  titleFourth: 'Вікна та двері',
  textFourth:
    "Наш асортимент вікон та дверей включає різноманітні стилі та матеріали, такі як дерево, вініл та склопластик. Ці матеріали забезпечують як функціональність, так і естетичність вашої конструкції.",

  titleFifth: 'Обладнання та інструменти',
  textFifth:
    'Ми також пропонуємо широкий асортимент фурнітури та інструментів, включаючи кріплення, клеї, пилки та свердла. Ці інструменти та аксесуари необхідні для ефективного та результативного завершення вашого будівельного проекту.',
};
