export const swimmingPoolsStepsSection = {
  blockTitle: 'Stages of swimming pools',
  descriptionTitle: 'Initial consultation',
  stepOne: {
    title: 'Design and Planning',
    p1: `Start by creating a detailed design for your swimming pool, considering factors such as size, shape, location, and features. Consult with professionals, such as architects and pool designers, to ensure the design meets your needs and complies with local regulations.`,
    p2: ``,
  },
  stepTwo: {
    title: 'Excavation',
    p1: `Excavation involves digging out the area where the pool will be located. Heavy machinery is typically used to remove soil and create the hole according to the design specifications. This step requires careful precision to achieve the desired shape and depth.`,
    p2: ``,
  },
  stepThree: {
    title: 'Reinforcement and Plumbing',
    p1: `Once the excavation is complete, the next step is to install a sturdy reinforcement framework. Steel bars, or rebar, are used to provide structural strength to the pool. Plumbing lines, including the ones for water supply and drainage, are also installed at this stage.`,
    p2: ``,
  },
  stepFour: {
    title: 'Pouring Concrete or Installing Prefabricated Pool Shell',
    p1: `Depending on your design and preferences, you can choose to pour concrete to create the pool structure or install a prefabricated pool shell. Concrete pools offer more customization options, while prefabricated shells provide faster installation.`,
    p2: ``,
  },
  stepFive: {
    title: 'Finishing and Filtration System',
    p1: `After the pool structure is in place, it's time to add the finishing touches. This includes applying waterproofing coatings, tiling the interior, and installing any additional features such as steps, diving boards, or waterfalls. Simultaneously, the filtration system, including pumps, filters, and heaters, is installed to ensure proper water circulation and maintenance.`,
    p2: ``,
  },
  stepSix: {
    title: 'Decking and Landscaping',
    p1: `Surrounding the pool area, you can create a deck or patio for lounging and recreational activities. Choose materials that are durable, slip-resistant, and suitable for outdoor use. Landscaping elements such as plants, trees, and lighting can also enhance the pool area's aesthetics and functionality`,
    p2: ``,
  },
  stepSeven: {
    title: 'Fill and Start Maintenance',
    p1: `Once the construction is complete, the pool is filled with water and chemical treatments are applied to establish the appropriate water balance. Start the necessary maintenance routines, such as regular cleaning, water testing, and upkeep of equipment, to ensure the pool remains safe, clean, and inviting for years to come.`,
    p2: ``,
  },
  
};
