import styled from 'styled-components';
import {
  Breakpoints,
  transitions,
  colorVariables,
} from '../../theme/Theme.constants';
import { TextField } from '../../shared/components/TextFieldInput/TextFieldInput.styled';
import { Button } from '../../shared/components/BluredButton/BluredButton.styled';

export const ContactsFormWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  padding: 2.18rem 1.25rem 0px 1.25rem;
  margin-bottom: 2.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    margin-bottom: 0;
    padding: 2.18rem 2.5rem 0px 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0;
    width: 100%;
  }
`;

export const ContactsBlockWrapper = styled.div`
  border: 1px solid ${colorVariables.blockBorderColor};
`;

export const MainTitle = styled.p`
  width: 100%;
  padding: 1rem 0 1rem 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  color: rgba(187, 187, 187, 0.6);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 1.875rem;
    line-height: 37px;
    padding: 1.875rem 0 1.875rem 0;
  }
`;

export const ContactsWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  @media (max-width: ${Breakpoints.md}px) {
    flex-direction: column;
    padding: 0.875rem 0px 0.875rem 0px;
  }
`;

export const Contacts = styled.a`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;

  color: ${colorVariables.mainTextColor};

  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.375rem;
    padding: 2.25rem 0px 2.25rem 0px;
    &:not(:last-child) {
      border-right: 1px solid ${colorVariables.blockBorderColor};
    }
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  padding: 1.25rem 0px 1.25rem 0px;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem 0px 1.875rem 0px;
  }
`;

export const NetworkIconWrapper = styled.a`
  &:not(:last-child) {
    margin-right: 1.875rem;
  }

  @media (min-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-right: 6.25rem;
    }
  }
`;
export const NetworkIcon = styled.img`
  width: 1.625rem;
  height: 1.625rem;

  @media (min-width: ${Breakpoints.md}px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const FormBlock = styled.div`
  ${TextField} {
    padding: 1rem 1.5rem;
    @media (min-width: ${Breakpoints.md}px) {
      padding: 1.875rem 1.25rem;
    }
  }

  ${Button} {
    border: none;
    backdrop-filter: none;
    background: ${colorVariables.contactsPageButtonColor};
    padding: 0.75rem;
    transition: ${transitions.backgroundHoverTransition};

    &:hover {
      background: ${colorVariables.buttonGreyBackground};
    }
    @media (min-width: ${Breakpoints.md}px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.31rem;

      .rotated-text {
        display: inline-flex;
        transform: rotate(-90deg);
      }
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
  }
`;

export const VerticalBlockWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
