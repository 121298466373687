export const currentTexts = {
  label: 'Додаткові опції',
  description:
    'До недавнього часу модульні будинки використовувалися в основному для ситуативних потреб, таких як тимчасове житло, дачні будиночки. Але сучасні технології не стоять на місці і продовжують розвиватися. Наразі готові та доступні будинки з блок-контейнерів мають достатньо характеристик, щоб забезпечити комфортне проживання. А ми готові запропонувати вам додаткові послуги, які допоможуть облагородити територію модульного будинку.',
};

export const options = {
  titleSwimmingPools: 'Басейни для плавання',
  describtionSwimmingPools:
    'Для багатьох з нас море завжди було джерелом мрій і відпочинку. Однак можливість побудувати власний модульний басейн на присадибній ділянці стала чудовою можливістю втілити ці мрії в реальність, навіть якщо до Атлантичного океану далеко. Завдяки нам будівництво басейнів перетворюється з недосяжної мрії в доступну реальність. Наявність штучної водойми поруч із заміським будинком стала звичним і природним доповненням до навколишнього ландшафту.',

  titleSmartHome: 'Розумний будинок',
  describtionSmartHome:
    "Розумний будинок - це ваш особистий помічник, який піклується про ваш комфорт і затишок. Систему можна встановити навіть у модульному будинку. Для керування системою вам знадобиться лише планшет, телефон або комп'ютер. Використовуючи унікальний захищений протокол і мобільний додаток, комплекс дає вам повний контроль над вашим простором у будь-який час. Вам навіть не доведеться вставати з ліжка, щоб поставити чайник.",

  titleSolarEnergy: 'Сонячна енергія',
  describtionSolarEnergy:
    'Сонце - наше головне джерело енергії та життя на Землі. Використання сонячної енергії - це перший крок до усвідомлення екологічних проблем. Ми раді бути з вами на цьому шляху, допомогти порадою та встановленням панелей на даху вашого модульного будинку.',
};
