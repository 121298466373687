

export const title = {
   top: 'Кращі',
  services: 'послуги',
}

export const allServices = 'Всі послуги'





export const description =
  "У Moderna Casa ми пропонуємо нашим клієнтам найсучасніші рішення для земельних ділянок та об'єктів нерухомості, враховуючи індивідуальні побажання кожного. Наш сервіс пов'язаний з наданням повного комплексу послуг - від котловану до будинку мрії: проектування та будівництво, оснащення території басейнами, розумними системами та сонячними панелями. Наша висококваліфікована команда надає консультаційні послуги, стежить за тенденціями, постійно підвищує якість роботи і гарантує виняткові результати, які перевершують очікування наших клієнтів. Ми впевнено крокуємо до звання лідера ринку, будуючи стратегічний фундамент завдяки довірі та професійним стандартам.";