import React, { FC, useState } from 'react';

import modularImg from '../../../shared/assets/images/pre-build-houses/modular.jpg';
import interiorImg from '../../../shared/assets/images/pre-build-houses/interior.jpg';
import poolsImg from '../../../shared/assets/images/pre-build-houses/pools.jpg';
import {
  BuldingWrapper,
  List,
  Item,
  Img,
  TypeWrapper,
  Type,
  TextsWrapper,
  Name,
} from './BuildingSection.styled';
import { PaginationBlock } from '../../../components/PaginationBlock/PaginationBlock';
import { useTranslation } from 'react-i18next';

interface BuildingSectionProps {
  currentTypeName: string;
}
export const BuildingSection: FC<BuildingSectionProps> = ({
  currentTypeName,
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const buildingList = [
    {
      id: 1,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Modular houses',
      typeName: t('mainPage.MenuItemsTitles.modular_houses'),
      img: modularImg,
    },
    {
      id: 2,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Projects',
      typeName: t('mainPage.MenuItemsTitles.projects'),
      img: poolsImg,
    },
    {
      id: 3,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Interior',
      typeName: t('mainPage.MenuItemsTitles.interior'),
      img: interiorImg,
    },
    {
      id: 4,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Modular houses',
      typeName: t('mainPage.MenuItemsTitles.modular_houses'),
      img: modularImg,
    },
    {
      id: 5,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Smart home',
      typeName: t('mainPage.MenuItemsTitles.smart_home'),
      img: poolsImg,
    },
    {
      id: 6,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Interior',
      typeName: t('mainPage.MenuItemsTitles.interior'),
      img: interiorImg,
    },

    {
      id: 7,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Solar energy',
      typeName: t('mainPage.MenuItemsTitles.solar_energy'),
      img: modularImg,
    },
    {
      id: 8,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Swimming pools',
      typeName: t('mainPage.MenuItemsTitles.swimming_pools'),
      img: poolsImg,
    },
    {
      id: 9,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Interior',
      typeName: t('mainPage.MenuItemsTitles.interior'),
      img: interiorImg,
    },

    {
      id: 10,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Modular houses',
      typeName: t('mainPage.MenuItemsTitles.modular_houses'),
      img: modularImg,
    },
    {
      id: 11,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Swimming pools',
      typeName: t('mainPage.MenuItemsTitles.swimming_pools'),
      img: poolsImg,
    },
    {
      id: 12,
      name: t('projectsPage.projectsList.label'),
      value: '€ 110.400',
      type: 'Interior',
      typeName: t('mainPage.MenuItemsTitles.interior'),
      img: interiorImg,
    },
  ];

  return (
    <BuldingWrapper>
      <List>
        {currentTypeName.length === 0 || currentTypeName === 'all' ? (
          <>
            {buildingList.map(({ id, name, value, img, typeName }) => {
              return (
                <Item key={id}>
                  <Img src={img} />
                  <TypeWrapper>
                    <Type>{typeName}</Type>
                  </TypeWrapper>

                  <TextsWrapper>
                    <Name>{name}</Name>
                    <Name>{value}</Name>
                  </TextsWrapper>
                </Item>
              );
            })}
          </>
        ) : (
          buildingList
            .filter(item => item.type.toLowerCase() === currentTypeName)
            .map(({ id, name, value, img, typeName }) => {
              return (
                <Item key={id}>
                  <Img src={img} />
                  <TypeWrapper>
                    <Type>{typeName}</Type>
                  </TypeWrapper>

                  <TextsWrapper>
                    <Name>{name}</Name>
                    <Name>{value}</Name>
                  </TextsWrapper>
                </Item>
              );
            })
        )}
      </List>

      <PaginationBlock
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </BuldingWrapper>
  );
};
