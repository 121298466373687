import React, { FC, useState } from 'react';
import {
  Title,
  AfterTitle,
  TextWrapper,
  FormWrapper,
  CheckboxBlock,
  CheckboxGroupBlock,
  QuestionBlock,
  CheckboxGroup,
  FormControlLabelBLock,
  FileUploaderBlock,
  UploadFileContent,
  UploadTitle,
  UploadIcon,
  UploadFormats,
  UploadInfo,
} from './QuestionsBlock.styled';
import { Field, Formik } from 'formik';
import { colorVariables } from '../../../theme/Theme.constants';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@mui/material';
import { TextAreaFieldInput } from '../../../shared/components/TextAreaFieldInput';
import { FileUploader } from 'react-drag-drop-files';
import { BluredButton } from '../../../shared/components/BluredButton';
import { AcceptButton, ControlsBlock } from '../LeadForm.styled';
import { useGetQuestionsQuery } from '../../../api/leadFormQuestionsApi';
import { LeadFormDataProps } from '../LeadForm';
import { ReactComponent as UploadFileIcon } from '../../../shared/assets/icons/lead-forms/upload-file-icon.svg';
import { ReactComponent as DeleteFileIcon } from '../../../shared/assets/icons/lead-forms/delete-file-icon.svg';
import { useSelector } from 'react-redux';
import {
  getClientPartnerNavigationItems,
  getMainNavbarItems,
} from '../LeadForm.constants';
import { ErrorMesage } from '../InformationBlock/InformationBlock.styled';

interface QuestionsProps {
  id: number;
  question: string;
}

interface InitialValuesProps {
  project_status?: number[];
  comments_questions?: string;
  project_info_file?: any;
}

interface QuestionsBlockProps {
  setCurrentNavItem: (currentNavItem: string) => void;
  setLeadFormData: (leadFormData: LeadFormDataProps) => void;
  leadFormData: LeadFormDataProps;
  fileName: any;
  setFileName: (fileName: any) => void;
  formData: any;
}

const fileTypes = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'DWG'];

export const QuestionsBlock: FC<QuestionsBlockProps> = ({
  setCurrentNavItem,
  setLeadFormData,
  leadFormData,
  fileName,
  setFileName,
  formData,
}) => {
  const { t } = useTranslation();
  const formsNavigationItems = getMainNavbarItems();
  const clientPartnerNavigationItems = getClientPartnerNavigationItems();
  const leadFormTitle = useSelector((state: any) => state.global.leadFormTitle);
  const [fileSize, setFileSize] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('language') !== 'ua'
      ? localStorage.getItem('language')
      : 'uk',
  );
  const maxSize = 200000000;

  const { data: questionsData } = useGetQuestionsQuery(currentLanguage);

  const initialValues = {
    project_status: leadFormData.project_status,
    comments_questions: leadFormData.comments_questions,
    project_info_file: leadFormData.project_info_file,
  };

  const handleSubmit = (values: InitialValuesProps) => {
    setLeadFormData({
      ...leadFormData,
      project_status: values.project_status,
      comments_questions: values.comments_questions,
    });

    setCurrentNavItem(formsNavigationItems[2].title);
  };

  const handleUploadFile = (file: any) => {
    console.log('file', file);
    setFileName(file.name);
    setFileSize(file.size);
    formData.append('project_info_file', file);
    setLeadFormData({
      ...leadFormData,
      project_info_file: file,
    });
  };

  const handleDeleteFile = () => {
    setFileName(null);
    setFileSize(0);
    formData.delete('project_info_file');
  };

  return (
    <>
      <TextWrapper>
        <Title>{t('leadForm.leadFormPage.questionTitle')}</Title>
        <AfterTitle>
          {t('leadForm.leadFormPage.questionDescription')}
        </AfterTitle>
      </TextWrapper>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, handleChange }) => (
          <FormWrapper>
            <CheckboxGroupBlock>
              <QuestionBlock>
                {t('leadForm.leadFormPage.projectStatus')}
              </QuestionBlock>
              <CheckboxGroup role="group" aria-labelledby="checkbox-group">
                {leadFormTitle === clientPartnerNavigationItems[0].title
                  ? questionsData?.client_questions.map(
                      (question: QuestionsProps) => (
                        <CheckboxBlock key={question.id}>
                          <FormControlLabelBLock
                            control={
                              <Field name="project_status">
                                {({ field, form }: any) => (
                                  <Checkbox
                                    checked={field.value?.includes(question.id)}
                                    onChange={() => {
                                      const isChecked = field.value?.includes(
                                        question.id,
                                      );
                                      const updatedValue = isChecked
                                        ? field.value.filter(
                                            (value: any) =>
                                              value !== question.id,
                                          )
                                        : [...(field.value || []), question.id];

                                      form.setFieldValue(
                                        field.name,
                                        updatedValue,
                                      );
                                    }}
                                    sx={{
                                      color: 'rgba(255, 255, 255, 0.2)',
                                      '&.Mui-checked': {
                                        color:
                                          colorVariables.alternativeTextColor,
                                      },
                                    }}
                                  />
                                )}
                              </Field>
                            }
                            label={question.question}
                          />
                        </CheckboxBlock>
                      ),
                    )
                  : questionsData?.partner_questions.map(
                      (question: QuestionsProps) => (
                        <CheckboxBlock key={question.id}>
                          <FormControlLabelBLock
                            control={
                              <Field name="project_status">
                                {({ field, form }: any) => (
                                  <Checkbox
                                    checked={field.value?.includes(question.id)}
                                    onChange={() => {
                                      const isChecked = field.value?.includes(
                                        question.id,
                                      );
                                      const updatedValue = isChecked
                                        ? field.value.filter(
                                            (value: any) =>
                                              value !== question.id,
                                          )
                                        : [...(field.value || []), question.id];

                                      form.setFieldValue(
                                        field.name,
                                        updatedValue,
                                      );
                                    }}
                                    sx={{
                                      color: 'rgba(255, 255, 255, 0.2)',
                                      '&.Mui-checked': {
                                        color:
                                          colorVariables.alternativeTextColor,
                                      },
                                    }}
                                  />
                                )}
                              </Field>
                            }
                            label={question.question}
                          />
                        </CheckboxBlock>
                      ),
                    )}
              </CheckboxGroup>
            </CheckboxGroupBlock>
            <Field
              label={t('leadForm.leadFormPage.commentsQuestionsPlaceholder')}
              type={'text'}
              value={values.comments_questions}
              name={'comments_questions'}
              component={TextAreaFieldInput}
            />
            <FileUploaderBlock>
              <FileUploader
                handleChange={handleUploadFile}
                name="project_info_file"
                types={fileTypes}
                hoverTitle=" "
                children={
                  <UploadFileContent>
                    <UploadInfo>
                      <UploadTitle>
                        {fileName
                          ? fileName
                          : t('leadForm.leadFormPage.uploadFile')}
                      </UploadTitle>
                      <UploadFormats>
                        (pdf, doc, docx, xls, xlsx, dwg)
                      </UploadFormats>
                    </UploadInfo>
                    {!fileName && <UploadFileIcon />}
                  </UploadFileContent>
                }
              />
              <UploadIcon onClick={handleDeleteFile}>
                {fileName && <DeleteFileIcon />}
              </UploadIcon>
              {fileSize >= maxSize && (
                <ErrorMesage>* The file must not exceed 200 MB</ErrorMesage>
              )}
            </FileUploaderBlock>
            <ControlsBlock>
              <BluredButton
                title={t('leadForm.leadFormPage.backButton')}
                handleClick={() =>
                  setCurrentNavItem(formsNavigationItems[0].title)
                }
              />

              <AcceptButton
                type="submit"
                onClick={() => handleSubmit(values)}
                disabled={fileSize >= maxSize}>
                {t('leadForm.leadFormPage.nextButton')}
              </AcceptButton>
            </ControlsBlock>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
