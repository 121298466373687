import design from '../../../../shared/assets/images/architect/4d-design.jpg';
import tour from '../../../../shared/assets/images/architect/3d-tour.jpg';
import consultations from '../../../../shared/assets/images/architect/consultations.jpg';
import foundation from '../../../../shared/assets/images/architect/foundation.jpg';
import support from '../../../../shared/assets/images/architect/support.jpg';
import busing from '../../../../shared/assets/images/architect/hannah-busing.jpg';
import model from '../../../../shared/assets/images/architect/model-house.jpg';

export const currentTexts = {
  label: 'El nostre procés',
  description:
    `En l'arquitectura, seguim un procés de disseny integral que garanteix que cada projecte s'executi amb precisió i atenció al detall. El nostre procés inclou:`,
};

export const currentPortfolioTexts = {
  label: 'Portafoli',
  description: '',
};

export const processList = [
  {
    number: 1,
    label: ' Pre-disseny',
    description:
      `En aquesta fase, l' arquitecte es reunirà amb el client per conèixer les seves necessitats i preferències, així com els requisits o limitacions específics de l' emplaçament. L' arquitecte també durà a terme una anàlisi de l' emplaçament per avaluar factors com la topografia, l' orientació i les vistes.`,
    img: design,
  },
  {
    number: 2,
    label: 'Disseny Esquemàtic',
    description:
      `A partir de la informació recopilada en la fase de predisseny, l' arquitecte desenvoluparà un concepte de disseny preliminar que esbossi la distribució general, la mida i la forma de la vila. Pot incloure esbossos o representacions per ajudar el client a visualitzar el disseny proposat.`,
    img: tour,
  },
  {
    number: 3,
    label: 'Desenvolupament del disseny',
    description:
      `En aquesta fase, l' arquitecte perfeccionarà el concepte de disseny i elaborarà dibuixos i plànols més detallats. Això pot incloure plànols de planta, alçats i seccions, així com models o representacions en 3D.`,
    img: busing,
  },
  {
    number: 4,
    label: 'Permisos i aprovacions',
    description:
      `Abans que pugui començar la construcció, l' arquitecte haurà d' obtenir els permisos o aprovacions necessaris de les autoritats locals, com ara permisos de construcció, permisos mediambientals o aprovacions de zonificació.`,
    img: consultations,
  },
  {
    number: 5,
    label: 'Documentació de la construcció',
    description:
      `Un cop finalitzat el disseny i obtinguts els permisos, l' arquitecte prepararà els documents de construcció detallats que brinden instruccions als constructors, inclosos els plànols, les especificacions i els cronogrames.`,
    img: model,
  },
  {
    number: 6,
    label: 'Administració de la construcció',
    description:
      `Un cop finalitzat el disseny i obtinguts els permisos, l' arquitecte prepararà els documents de construcció detallats que proporcionaran instruccions als constructors, inclosos plànols, especificacions i calendaris.`,
    img: foundation,
  },
  {
    number: 7,
    label: 'Després de la construcció',
    description:
      `Un cop acabada la vila, l' arquitecte pot prestar serveis posteriors a la construcció, com una inspecció final, plànols de construcció i un manual de manteniment.`,
    img: support,
  },
];
