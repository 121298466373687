export const currentTexts = {
  label: 'Opciones adicionales',
  description:
    'Hasta hace poco, las casas modulares se utilizaban principalmente para necesidades coyunturales, como viviendas temporales o casas de campo. Pero las tecnologías modernas no se quedan quietas y siguen desarrollándose. Actualmente, las casas prefabricadas y asequibles hechas de contenedores de bloques tienen características suficientes para proporcionar una vida cómoda. Y estamos dispuestos a ofrecerle servicios adicionales que ayudarán a mejorar el territorio de la casa modular.',
};

export const options = {
  titleSwimmingPools: 'Piscinas',
  describtionSwimmingPools:
    ' Para muchos de nosotros, el mar siempre ha sido una fuente de sueños y relajación. Sin embargo, poder construir su propia piscina modular en el patio trasero es una oportunidad increíble para hacer realidad esos sueños, incluso si el Océano Atlántico está lejos. Gracias a nosotros, la construcción de piscinas pasa de un sueño inalcanzable a una realidad accesible. La presencia de un embalse artificial junto a una casa de campo se ha convertido en un añadido habitual y natural al paisaje circundante.',

  titleSmartHome: 'Hogar inteligente',
  describtionSmartHome:
    'Una casa inteligente es su asistente personal que cuida de su confort y comodidad. El sistema puede instalarse incluso en una casa modular. Para controlar el sistema, todo lo que necesita es una tableta, un teléfono o un ordenador. Mediante un protocolo seguro único y una aplicación móvil, el complejo le ofrece un control total de su espacio en cualquier momento. Ni siquiera tiene que levantarse de la cama para poner la tetera.',

  titleSolarEnergy: 'Energía solar',
  describtionSolarEnergy:
    'El sol es nuestra principal fuente de energía y de vida en la Tierra. El uso de la energía solar es el primer paso hacia la concienciación sobre los problemas medioambientales. Estaremos encantados de acompañarle en este viaje, para ayudarle con el asesoramiento y la instalación de paneles en los tejados de su casa modular.',
};