export const MenuItemsTitles = {
  all: 'All',
  construction: 'Construction',
  projects: 'Projects',
  interior: 'Interior',
  modular_houses: 'Modular houses',
  swimming_pools: 'Swimming pools',
  solar_energy: 'Solar energy',
  smart_home: 'Smart home',
  inmobiliaria: 'Real estate',
  investments: 'Investments',
  consulting: 'Consulting',
  architect: 'Architect',
  landscape_design: 'Landscape design',
  building_materials: 'Building materials',
  pre_build_houses: 'Pre-build houses',
};

export const MenuItemsDescriptions = {
  construction:
    'Experience the epitome of luxury and craftsmanship with our construction services for villa development. Our expert team of builders and contractors bring your vision to life, ensuring impeccable quality and attention to detail at every step. From foundation to finishing touches, we deliver exceptional villas that reflect your unique style and exceed all expectations.',
  projects:
    'Projects in the realm of residential buildings involve planning, designing, and constructing homes or apartment buildings. They require a thorough understanding of building codes, safety standards, and the needs of the people who will live in the structures. Successful projects must also consider factors such as sustainability, energy efficiency, and aesthetic appeal. Overall, projects in residential construction play an important role in providing safe, ',
  interior: `Elevate your villa's interior to new heights of sophistication with our bespoke interior services. Our team of talented designers curates exquisite finishes, furniture, and decor, crafting spaces that exude luxury and comfort. With meticulous attention to every detail, we transform your villa into a captivating sanctuary that perfectly complements your lifestyle and showcases your personal style`,
  modular_houses:
    'Modular houses are an innovative and flexible option for residential building projects. These structures are built in sections or "modules" off-site and then transported to the building site for assembly. This allows for a faster, more efficient construction process with fewer materials wasted. Additionally, modular houses can be customized to fit a variety of styles and needs, making them a great option for those looking for a unique and personalized.',
  swimming_pools: `Looking to add a touch of luxury to your home? Consider the benefits of an installed swimming pool. Residential swimming pools come in a variety of styles and sizes, making them a great option for any home. They can provide a fun and relaxing space for family and friends to gather, while also increasing the value of your property. Whether you're looking for a small, simple pool or a grand, elaborate one, an installed swimming pool can transform your backyard into a stunning oasis.`,
  solar_energy: `Solar energy is a sustainable and cost-effective option for powering your home. By installing solar panels on your roof, you can generate your own electricity from the sun's rays. This not only helps to reduce your carbon footprint, but also can save you money on your monthly utility bills. With the right setup, you may even be able to sell excess energy back to the grid.`,
  smart_home:
    'Smart home technology allows for an interconnected and automated living experience. With a smart home system, you can control everything from lighting and temperature to security and entertainment with just a few taps on your phone or voice commands. This not only provides convenience and comfort, but also can increase the energy efficiency and safety of your home. By investing in a smart home system, you can enjoy a more connected, secure and efficient living space.',
  inmobiliaria:
    'Find the perfect plot for your dream villa and simplify the construction process with our curated selection of plots and ready-made villa projects. Our expert team ensures that you have access to prime locations and pre-designed plans, making it easier than ever to build your ideal home with convenience and peace of mind.',
  investments:
    'Maximize your real estate investments with our villa development company. With our expertise in identifying high-potential locations and creating exceptional villas, we offer a lucrative opportunity to grow your portfolio and achieve impressive returns in the luxury property market. Invest with confidence and let us help you unlock the full potential of your real estate ventures.',
  consulting:
    `Navigate the Spanish real estate market with ease and expertise through our comprehensive consulting services. Our team of experienced professionals provides valuable insights, market analysis, and personalized guidance to help you make informed decisions and secure profitable real estate opportunities in Spain's vibrant villa development sector.`,
  architect:
    'Transform your villa development dreams into reality with the expertise of our skilled architects. From innovative designs that harmonize with the surroundings to meticulous attention to detail, we create exceptional living spaces that surpass expectations and leave a lasting architectural legacy.',
  landscape_design:
    'Enhance the beauty and allure of your villa with our exquisite landscape design services in Spain. Our expert team of designers creates breathtaking outdoor spaces, incorporating lush gardens, stunning hardscapes, and captivating water features, ensuring that your villa stands out as a true oasis of luxury and tranquility',
  building_materials:
    'We offer a wide selection of high-quality building materials to help you complete your construction project with ease. From foundational materials to finishing touches, we have everything you need to bring your vision to life.',
};
