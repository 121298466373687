import styled from 'styled-components';
import { colorVariables,Breakpoints } from '../../../theme/Theme.constants';

export const Video = styled.video`
   position: fixed;
   top: 0;
   width: 100%;
   height: 100%;
   max-width: ${Breakpoints.maxWidth};
   object-fit: cover;
   z-index: -1;
`