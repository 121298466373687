export const aboutListText = {
  peopleText:
    'Contamos con un equipo de profesionales experimentados dedicados a prestar un servicio excelente a nuestros clientes.',
  bookText:
    'Tenemos un historial probado de éxito, con muchos clientes satisfechos.',
  clipboardText:
    'Ofrecemos una amplia gama de servicios para satisfacer las necesidades únicas de nuestros clientes.',
  microchipText:
    'Utilizamos las últimas tecnologías y estrategias de marketing para garantizar que las propiedades de nuestros clientes sean vistas por las personas adecuadas.',
  handshakeText:
    'Proporcionamos asistencia jurídica para garantizar que todas las transacciones se gestionen de forma fluida y eficaz.',
};
