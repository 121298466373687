export const currentTexts = {
  label: 'Options supplémentaires',
  description:
    "Jusqu'à récemment, les maisons modulaires étaient principalement utilisées pour répondre à des besoins ponctuels, tels que des logements temporaires ou des maisons de campagne. Mais les technologies modernes ne sont pas figées et continuent de se développer. Aujourd'hui, les maisons prêtes à l'emploi et abordables fabriquées à partir de blocs de conteneurs présentent suffisamment de caractéristiques pour offrir une vie confortable. Et nous sommes prêts à vous offrir des services supplémentaires qui vous aideront à améliorer le territoire de la maison modulaire.",
};

export const options = {
  titleSwimmingPools: 'Piscines',
  describtionSwimmingPools:
    "Pour beaucoup d'entre nous, la mer a toujours été une source de rêve et de détente. Cependant, la possibilité de construire sa propre piscine modulaire dans l'arrière-cour a été une occasion extraordinaire de réaliser ces rêves, même si l'océan Atlantique est loin. Grâce à nous, la construction de piscines passe d'un rêve inaccessible à une réalité accessible. La présence d'un réservoir artificiel à côté d'une maison de campagne est devenue un ajout commun et naturel au paysage environnant.",

  titleSmartHome: 'Maison intelligente',
  describtionSmartHome:
    "Une maison intelligente est votre assistant personnel qui s'occupe de votre confort et de votre bien-être. Le système peut être installé même dans une maison modulaire. Pour contrôler le système, il suffit d'une tablette, d'un téléphone ou d'un ordinateur. Grâce à un protocole sécurisé unique et à une application mobile, le complexe vous permet de contrôler entièrement votre espace à tout moment. Vous n'avez même pas besoin de sortir du lit pour faire chauffer la bouilloire",

  titleSolarEnergy: "L'énergie solaire",
  describtionSolarEnergy:
    "Le soleil est notre principale source d'énergie et de vie sur Terre. L'utilisation de l'énergie solaire est le premier pas vers la prise de conscience des problèmes environnementaux. Nous sommes heureux de vous accompagner dans cette démarche, de vous conseiller et d'installer des panneaux sur les toits de votre maison modulaire.",
};
