import styled from 'styled-components';
import { colorVariables, Breakpoints } from '../../theme/Theme.constants';

export const SolarEnergyWrapper = styled.div<{ bgImage: string }>`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;

  &:after {
    content: '';
    
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    max-width: 1920px;
    height: 100vh;

    @media (min-width: ${Breakpoints.maxWidth}px) {
      left: calc((100vw - ${Breakpoints.maxWidth}px) / 2);
    }
  }
`;

export const BenefitsSectionWrapper = styled.div`
  position: relative;

  padding-bottom: 3.75rem;
  &:after {
    content: '';
    background: ${colorVariables.opacityBackgroundColor};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding-bottom: 5.625rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding-bottom: 10.625rem;
  }
`;

export const PartnersSectionWrapper = styled.div`
  position: relative;
  z-index: 15;
  padding-bottom: 3.75rem;

  &:after {
    content: '';
    background: ${colorVariables.opacityBackgroundColor};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${Breakpoints.md}px) {
    padding-bottom: 6.25rem;
  }
`;

export const ServicesSectionWrapper = styled.div`
  position: relative;
  z-index: 15;
  padding-bottom: 2.5rem;
  &:after {
    content: '';
    background: ${colorVariables.opacityBackgroundColor};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem 2.5rem 6.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 7.5rem 0px 7.5rem;
  }
`;

export const SetupsSectionWrapper = styled.div`
  position: relative;
  z-index: 15;
  padding-bottom: 2.5rem;
  &:after {
    content: '';
    background: ${colorVariables.opacityBackgroundColor};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${Breakpoints.md}px) {
    padding-bottom: 6.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding-bottom: 18.75rem;
  }
`;
