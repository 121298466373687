export const ColorsSection = {
  title: 'Colors',
  description: `Experimenteu una impressionant col·lecció de colors i agradables mescles de colors primaris. Prepari's per quedar hipnotitzat per l'efecte hologràfic translúcid que confereix una brillantor distintiva als nostres productes.`,
  
  first: 'Anthracite',
  second: 'Nova Blue',
  third: 'Nova Grey',
  fourth: 'Nova Navy',
  fifth: 'Nova Pearl',
  sixth: 'Nova Stone',
};
