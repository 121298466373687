
export const philosophyTextsList = [
  {
    id: 1,
    label: 'Gestión del riesgo',
    description:
      'Adoptamos un enfoque disciplinado de la gestión del riesgo, centrado en preservar el capital y evitar pérdidas permanentes.',
  },

  {
    id: 2,
    label: 'Inversión en valor',
    description:
      'Buscamos invertir en empresas de alta calidad con sólidos fundamentos que coticen con descuento respecto a su valor intrínseco.',
  },

  {
    id: 3,
    label: 'Perspectiva a largo plazo',
    description:
      'Adoptamos un enfoque de inversión paciente y a largo plazo, centrándonos en los fundamentos de las empresas en las que invertimos.',
  },
];

export const philosophyTitle =
  'Nuestra filosofía de inversión se basa en tres principios fundamentales:';