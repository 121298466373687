export const aboutUsNavItems = {
  values: 'Valors',
  story: 'Història',
  team: 'Equip',
};

export const aboutUsSecondDescription =
  `La nostra empresa s'especialitza a proporcionar solucions de construcció i immobiliàries d'última generació que prioritzen la felicitat del client i la qualitat. La nostra àmplia gamma de serveis abasta des de la construcció i el disseny fins a habitatges, piscines, energia, immobiliàries, inversions i consultoria. Gràcies al nostre equip d'experts, oferim constantment resultats excepcionals que compleixen i superen les expectatives dels nostres clients, alhora que ens ajustem als seus pressupostos i terminis. Prioritzant la confiança i construint relacions duradores amb els nostres clients, podem oferir constantment resultats excepcionals i convertir-nos en un soci de confiança en el seu èxit.`;

export const aboutUsTitle = {
  about: 'Sobre',
  us: 'nosaltres',
};