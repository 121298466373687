

export const architectStepBlockTitle = '';
export const architectStepBlockDescription = '';

export const architectStepSection = {
  blockTitle: 'Stages of Arquitecto',
  descriptionTitle: 'Move in',
  stepOne: {
    title: 'Step one',
    p1: `Acquaintance. The architect gets to know the customer and the property or land closer. At this initial stage, the architect communicates with the future client, finds out his needs, requirements and expectations from the future building.`,
    p2: `All the most subtle nuances are important: the customer's lifestyle, the presence of children or animals, individual preferences. He also studies the location of the object, analyzes the local context and environment to determine the factors that affect the design of the building.`,
  },
  stepTwo: {
    title: 'Step two',
    p1: `Purpose of the object. The architect determines the client's main preferences regarding the design and functionality of the building. This may include defining the type of building (residential, commercial, public) and design style (classic, modern, minimalist). `,
    p2: `If the object is being built for daily rent, probably, the placement and functionality of the kitchen will not have to be given too much attention.`,
  },
  stepThree: {
    title: 'Step three',
    p1: ` Study of the site. Here the architect gets to know the territory in order to understand its limitations and possibilities.`,
    p2: `This may include analysis of topography, soil composition and climatic conditions, as well as checking the availability of communications and infrastructure.`,
  },
  stepFour: {
    title: 'Step four',
    p1: `Analysis of the legal component. Determining the possibility of solving construction or reconstruction problems within the framework of the current legislation and design standards.`,
    p2: `At this stage, the architect determines how realistic and feasible the project is, taking into account the requirements of legislation and design standards. It also determines the cost and resource intensity of the project.`,
  },
  stepFive: {
    title: 'Step five',
    p1: `Work with documentation. Submission of documents and obtaining a permit for construction or reconstruction, obtaining technical specifications from the owners of external water supply networks, gas pipelines, and electrical networks.`,
    p2: ``,
  },
  stepSix: {
    title: 'Step six',
    p1: `Functional solutions. The development of an architectural concept is the next step after obtaining the permissions and wishes of the customer. At this stage, the architect creates a general idea of the object being designed, including determining the functional zoning and creating a spatial scheme.`,
    p2: `Creating a concept makes it possible to assess the potential of the object and understand how it will meet the wishes of the customer in the functional aspect of the issue. Will he be comfortable living in a house with his family or how popular will the object be for rent.`,
  },
  stepSeven: {
    title: 'Step seven',
    p1: `Development of a preliminary design. The architect determines the architectural, constructive, functional and artistic solutions for the construction or reconstruction object. `,
    p2: `A draft design allows the customer to see the general appearance of the object and evaluate the visual compliance with his wishes.`,
  },
  stepEight: {
    title: 'Step eight',
    p1: `Development of a working draft. This is the final stage of architectural design, which includes a complete package of project documentation. As part of the development of the working draft, the specialist takes into account the architectural section, the structural section, sewerage, plumbing, electrical equipment, ventilation, air conditioning, heating and other components.`,
    p2: `The working draft contains a detailed description of all constructive, technical and engineering solutions necessary for the construction or reconstruction of the facility.`,
  },
};
