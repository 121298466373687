import React, { FC,  } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import bgImage from '../../shared/assets/images/about-us/desktop-bg.png';
import {
  AboutUsPageWrapper,
  OurValuesWrapper,
  OurStoryWrapper,
  // OurTeamWrapper,
} from './AboutUsPage.styled';

// import { getProcessList } from './AboutUsPage.constants';
import { ReactComponent as OpenBookIcon } from '../../shared/assets/icons/about-us-icons/book-open.svg';
import peopleIcon from '../../shared/assets/icons/about-us-icons/people.svg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { OurValuesBlock } from '../../components/OurValuesBlock';
import { OurStoryMobileBlock } from '../../components/OurStoryBlock/MobileBlock';
import { OurStoryBlock } from '../../components/OurStoryBlock';
// import { AboutUsTeamSection } from '../../components/AboutUsTeamSection';
import { getValueList } from '../../components/AboutUsSection/AboutUsSection.constatnts';



export const AboutUsPage: FC = () => {
  const { t } = useTranslation();
   const valuesList = getValueList();
 

  const navItems = [
    {
      title: t('aboutUsPage.aboutUsNavItems.values'),
      sectionId: 'values-section',
    },
    {
      title: t('aboutUsPage.aboutUsNavItems.story'),
      sectionId: 'story-section',
    },
    // {
    //   title: t('aboutUsPage.aboutUsNavItems.team'),
    //   sectionId: 'team-section',   // LOGIC FOR FUTURE FEATURES
    // },
  ];

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  return (
    <AboutUsPageWrapper>
      <PageHeaderSection
        title={`${
          t('aboutUsPage.aboutUsTitle.about') +
          ' ' +
          t('aboutUsPage.aboutUsTitle.us')
        }`}
        headerTitle={
          <>
            <span>{t('aboutUsPage.aboutUsTitle.about')}</span>
            <span className="us">{t('aboutUsPage.aboutUsTitle.us')}</span>
          </>
        }
        logoImg={<OpenBookIcon />}
        secondLogoImg={isTablet && peopleIcon}
        navItems={navItems}
        description={t('aboutUsPage.aboutUsSecondDescription')}
        withoutTitleBlock={true}
        bgImage={bgImage}
        isPrivacy
      />
      <OurValuesWrapper id="values-section">
        <OurValuesBlock valuesList={valuesList} asSection={true} />
      </OurValuesWrapper>

      <OurStoryWrapper id="story-section">
        {isMobile && <OurStoryMobileBlock />}
        {isTablet && <OurStoryBlock asSection={true} />}
      </OurStoryWrapper>

      {/* <OurTeamWrapper id="team-section">
        <AboutUsTeamSection />   // LOGIC FOR FUTURE FEATURES
      </OurTeamWrapper> */}
    </AboutUsPageWrapper>
  );
};
