import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessBlockHeader } from '../../../components/ProcessBlockHeader';
import {
  ProcessBlockWrapper,
  ProcessList,
  ProcessItem,
  ProcessItemTopWrapper,
  ProcessNumberWrapper,
  ProcessNumber,
  ProcessItemTitle,
  ProcessItemDescription,
  ProcessListWrapper,
} from './ProcessBlock.styled';

interface ProcessProps {
  processList: Array<{
    number: number;
    label: string;
    description: string;
    Icon?: any;
  }>;
}

export const ProcessBlock: FC<ProcessProps> = ({ processList }) => {
  return (
    <ProcessBlockWrapper>
      <ProcessListWrapper>
        <ProcessList>
          {processList.map(({ number, label, description, Icon }) => {
            return (
              <ProcessItem key={number}>
                <ProcessItemTopWrapper>
                  <ProcessNumberWrapper>
                    {!Icon ? <ProcessNumber>{number}</ProcessNumber> : <Icon />}
                  </ProcessNumberWrapper>
                  <ProcessItemTitle>{label}</ProcessItemTitle>
                </ProcessItemTopWrapper>
                <ProcessItemDescription>{description}</ProcessItemDescription>
              </ProcessItem>
            );
          })}
        </ProcessList>
      </ProcessListWrapper>
    </ProcessBlockWrapper>
  );
};
