export const PoolsSection = {
  title: 'Piscines',
  description: `Descobreixi les nostres piscines de ceràmica Compass: 24 models, perfectes per a cases modernes o clàssiques. Adequades per a jardins, terrasses o interiors. Realça el seu espai vital amb estil i versatilitat. Gaudeixi de la bellesa i la funcionalitat de les nostres piscines de ceràmica Compass, que ofereixen una harmoniosa barreja d'estil i versatilitat per a les seves necessitats d'oci i relaxació. Explori la nostra col·lecció ara i sorprengui's amb una piscina que realment realci el seu entorn vital. ¡Submergeixi's avui mateix!`,
  premium: 'Premium',
  selection: 'Selecció',
  small: 'Petit',
  length: 'Longitud',
  width: 'Amplada',
  depth: 'Profunditat',
  capacity: 'Capacitat',
};
