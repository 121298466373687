export const discoverList = {
  labelFirst: 'Nom',

  labelSecond: 'Nom',

  labelThird: 'Nom',
};

export const realEstateDiscoverTexts = {
  label: 'Découvrez votre propre terrain dès aujourd’hui',
  description:
    'Nous offrons une large gamme de terrains pour tous les besoins et budgets. Si vous rêvez de posséder votre propre terrain, ne cherchez plus. Notre sélection comprend tout, des petites parcelles urbaines aux vastes étendues rurales. Parcourez notre collection et trouvez la parcelle parfaite pour vos rêves.',
};

export const aboutDiscoverList = [
  {
    id: 1,
    label: 'À propos',
    text: "C'est une entreprise avec plus de 50 ans d'expérience dans l'industrie immobilière. Nous sommes spécialisés dans le développement, la construction et la vente de biens immobiliers en Espagne. Notre objectif est d'aider nos clients à trouver la propriété parfaite pour leurs besoins.",
  },
  {
    id: 2,
    label: 'Vente de biens immobiliers',
    text: "Nous offrons une large gamme de propriétés à vendre dans toute l'Espagne. Nos services comprennent la recherche de la propriété parfaite, la réalisation d'inspections et la fourniture de conseils sur l'achat de biens immobiliers. Nous garantissons à nos clients que nous trouverons le meilleur bien immobilier au meilleur prix.",
  },
];

export const aboutText =
  "Si vous êtes prêt à acheter, vendre ou louer une propriété, ou si vous souhaitez en savoir plus sur nos services, contactez-nous dès aujourd'hui. Notre équipe d'experts est prête à vous aider à chaque étape du processus.";



