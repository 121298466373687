import React, { FC } from 'react';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { ReactComponent as Shield } from '../../shared/assets/icons/shield.svg';
import { PrivacyConditionsWrapper } from './PrivacyConditionsPage.styled';
import { TextBlock } from './TextSection';

export interface TextProps {
  textList: { id: number; title: string; text: string | JSX.Element }[];
  title: string;
}

export const PrivacyConditionsPage: FC<TextProps> = ({ textList, title }) => {
  return (
    <PrivacyConditionsWrapper>
      <PageHeaderSection
        title={title}
        logoImg={<Shield />}
        withoutBorder
        isPrivacy
        withoutTitleBlock
        isDropDown
      />

      <TextBlock textList={textList} />
    </PrivacyConditionsWrapper>
  );
};
