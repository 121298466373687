import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const ContactsPageWrapper = styled.div`
  position: relative;
  z-index: 5;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: ${colorVariables.alternativeBackground} !important;
`;

export const FormMapBlocksWrapper = styled.div`
  padding-bottom: 3.75rem;
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
    flex-direction: row-reverse;
    padding: 2.5rem 6.25rem 3.75rem 6.25rem;
  }
`;
