export const investmentsProcess = [
  {
    number: 1,
    label: ' Proyección en pantalla',
    description:
      ' Utilizamos una gama de herramientas y técnicas de selección para identificar posibles oportunidades de inversión.',
  },
  {
    number: 2,
    label: ' Investigación',
    description:
      ' Llevamos a cabo una investigación fundamental en profundidad sobre las empresas e industrias en las que estamos considerando invertir.',
  },
  {
    number: 3,
    label: ' Análisis',
    description:
      'Analizamos los estados financieros, los equipos de gestión, los panoramas competitivos y otros factores clave que impulsan el éxito de las empresas en las que estamos considerando invertir.',
  },
  {
    number: 4,
    label: ' Evaluación',
    description:
      ' Utilizamos una variedad de técnicas de valoración para determinar el valor intrínseco de las empresas en las que estamos considerando invertir.',
  },

  {
    number: 5,
    label: ' Gestión de riesgos',
    description:
      ' Adoptamos un enfoque disciplinado de la gestión de riesgos, centrándonos en preservar el capital y evitar pérdidas permanentes.',
  },
];

export const investmentsProcessTexts = {
  label: 'Nuestro proceso de inversión',
  description:
    'Nuestro proceso de inversión se basa en un enfoque riguroso y disciplinado de la investigación y el análisis. Creemos que la investigación y el análisis en profundidad son fundamentales para identificar oportunidades de inversión de alta calidad y gestionar el riesgo con eficacia.',
};
