export const blockTitle = 'Етапи модульних будинків';

export const blockDescription = ' Первинна консультація';

export const modularHousesStepSection = {
  blockTitle: 'Stages of Modular house',
  descriptionTitle: 'Move in',
  stepOne: {
    title: 'Проектування та планування',
     p1: "На цьому початковому етапі визначаються розмір, планування та стиль модульного будинку. Спільно з архітектором або виробником модульних будинків створюється детальний проект з урахуванням специфічних вимог та отримання необхідних дозволів.",
     p2: ``,
  },
  stepTwo: {
    title: 'Підготовка фундаменту',
    p1: "Розчищення та вирівнювання будівельного майданчика - перше завдання на цьому етапі. Далі викопуються котловани, після чого будується міцний фундамент, який відповідає місцевим будівельним нормам. Фундамент повинен бути належним чином вирівняний і вирівняний, щоб підтримувати модульні блоки",
    p2: ``,
  },
  stepThree: {
    title: 'Виробництво модульних будинків',
    p1: "Співпрацюючи з відомим виробником модульних будинків, окремі модулі будуються в контрольованому заводському середовищі. Цей процес включає будівництво стін, підлоги, стелі та встановлення електричних і сантехнічних систем у кожному модулі.",
    p2: ``,
  },
  stepFour: {
    title: "Транспортування та підготовка майданчика",
    p1: "Після того, як модулі будуть готові, їх перевозять на будівельний майданчик за допомогою спеціалізованих вантажівок. Тим часом майданчик готується шляхом встановлення інженерних комунікацій та виконання необхідних робіт, таких як грейдерування та озеленення.",
    p2: ``,
  },
  stepFive: {
    title: 'Збірка та встановлення',
    p1: `За допомогою кранів або підйомного обладнання модулі встановлюються на фундамент. Кваліфіковані фахівці або будівельні бригади закріплюють модулі разом, забезпечуючи належне вирівнювання та герметизацію. Здійснюється електричне та сантехнічне з'єднання між модулями, а також додаються останні штрихи`,
    p2: ``,
  },
  stepSix: {
    title: "Внутрішнє та зовнішнє оздоблення",
    p1: "На цьому кроці фокус зміщується на завершення внутрішніх та зовнішніх робіт. Внутрішні роботи включають встановлення підлогового покриття, оздоблення стін, шаф та світильників, тоді як зовнішні роботи включають сайдинг, покрівлю, вікна та двері. Ці завершальні штрихи підвищують функціональність та естетику модульного будинку",
    p2: ``,
  },
  stepSeven: {
    title: 'Інженерні мережі та системна інтеграція',
    p1: "Підключення модульного будинку до місцевих інженерних мереж, таких як вода, електрика та газ, є метою цього кроку. Електричні, сантехнічні, опалювальні та охолоджувальні системи ретельно перевіряються для забезпечення їх належної функціональності",
    p2: ``,
  },
  stepEight: {
    title: "Фінальні перевірки та сертифікація",
    p1: "Для забезпечення відповідності будівельним нормам і правилам призначається фінальна перевірка місцевими органами влади. Ця перевірка охоплює всі аспекти будівництва модульного будинку. Після схвалення отримуються необхідні сертифікати про заселення або відповідність, які означають, що модульний будинок придатний для проживання на законних підставах",
    p2: ``,
  },
};
