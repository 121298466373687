export const MenuItemsTitles = {
  all: 'Todo',
  construction: 'Construcción',
  projects: 'Proyectos',
  interior: 'Interior',
  modular_houses: 'Casas modulares',
  swimming_pools: 'Piscinas',
  solar_energy: 'Energía solar',
  smart_home: ' Casa inteligente',
  inmobiliaria: 'Inmobiliaria',
  investments: 'Inversiones',
  consulting: ' Consultoría',
  architect: 'Arquitectura',
  landscape_design: 'Diseño paisajístico',
  building_materials: 'Materiales de construcción',
  pre_build_houses: 'Сasas construidas',
};

export const MenuItemsDescriptions = {
  construction:
    'Experimente el lujo y la artesanía con nuestros servicios de construcción de villas. Nuestro experto equipo de constructores y contratistas da vida a su visión, garantizando una calidad impecable y atención al detalle en cada paso. Desde los cimientos hasta los toques finales, entregamos villas excepcionales que reflejan su estilo único y superan todas las expectativas.',
  projects:
    'Los proyectos en el ámbito de edificios residenciales implican planificar, diseñar y construir hogares o edificios de apartamentos. Requieren una comprensión completa de los códigos de construcción, los estándares de seguridad y las necesidades de las personas que vivirán en las estructuras. Los proyectos exitosos también deben considerar factores como la sostenibilidad, la eficiencia energética y el atractivo estético. En general, los proyectos de construcción residencial desempeñan un papel importante en la provisión de espacios seguros,',
  interior:
    'Eleve el interior de su villa a nuevas alturas de sofisticación con nuestros servicios de interiorismo a medida. Nuestro equipo de talentosos diseñadores selecciona acabados, muebles y decoración exquisitos, creando espacios que destilan lujo y confort. Con una atención meticulosa a cada detalle, transformamos su villa en un santuario cautivador que complementa a la perfección su estilo de vida y muestra su estilo personal.',
  modular_houses:
    'Las casas modulares son una opción innovadora y flexible para proyectos de construcción residencial. Estas estructuras se construyen en secciones o ""módulos"" fuera del sitio y luego se transportan al sitio de construcción para su ensamblaje. Esto permite un proceso de construcción más rápido y eficiente con menos materiales desperdiciados. Además, las casas modulares se pueden personalizar para adaptarse a una variedad de estilos y necesidades, lo que las convierte en una excelente alternativa para aquellos que buscan una opción única y personalizada.',
  swimming_pools:
    ' ¿Buscas agregar un toque de lujo a tu hogar? Considere los beneficios de una piscina instalada. Las piscinas residenciales vienen en una variedad de estilos y tamaños, lo que las convierte en una excelente opción para cualquier hogar. Pueden proporcionar un espacio divertido y relajante para que se reúnan familiares y amigos, al mismo tiempo que aumentan el valor de su propiedad. Ya sea que esté buscando una piscina pequeña y simple o una grande y elaborada, una piscina instalada puede transformar su patio trasero en un impresionante oasis.',
  solar_energy:
    'La energía solar es una opción sostenible y rentable para alimentar su hogar. Al instalar paneles solares en su tejado, puede generar su propia electricidad a partir de los rayos del sol. Esto no solo ayuda a reducir su huella de carbono, sino que también puede ahorrarle dinero en sus facturas de servicios públicos mensuales. Con la configuración adecuada, incluso puede vender energía excedente a la red.',
  smart_home:
    'La tecnología de hogar inteligente permite una experiencia de vida interconectada y automatizada. Con un sistema de hogar inteligente, puede controlar todo, desde la iluminación y la temperatura hasta la seguridad y el entretenimiento, con solo unos pocos toques en su teléfono o comandos de voz. Esto no solo brinda conveniencia y comodidad, sino que también puede aumentar la eficiencia energética y la seguridad de su hogar. Al invertir en un sistema de hogar inteligente, puede disfrutar de un espacio vital más conectado, seguro y eficiente.',
  inmobiliaria:
    'Encuentre la parcela perfecta para su chalet de ensueño y simplifique el proceso de construcción con nuestra cuidada selección de parcelas y proyectos de chalets listos para construir. Nuestro equipo de expertos le garantiza el acceso a ubicaciones privilegiadas y planos prediseñados, lo que facilita más que nunca la construcción de su casa ideal con comodidad y tranquilidad.',
  investments:
    'Maximice sus inversiones inmobiliarias con nuestra empresa promotora de villas. Con nuestra experiencia en la identificación de ubicaciones de alto potencial y la creación de villas excepcionales, ofrecemos una lucrativa oportunidad para hacer crecer su cartera y lograr impresionantes rendimientos en el mercado inmobiliario de lujo. Invierta con confianza y permítanos ayudarle a liberar todo el potencial de sus proyectos inmobiliarios',
  consulting:
    'Navegue por el mercado inmobiliario español con facilidad y experiencia a través de nuestros servicios integrales de consultoría. Nuestro equipo de profesionales experimentados ofrece valiosas perspectivas, análisis de mercado y orientación personalizada para ayudarle a tomar decisiones informadas y asegurar oportunidades inmobiliarias rentables en el vibrante sector de la promoción de villas en España.',
  architect:
    'Transforme sus sueños de construcción de villas en realidad con la experiencia de nuestros cualificados arquitectos. Desde diseños innovadores que armonizan con el entorno hasta una meticulosa atención al detalle, creamos espacios vitales excepcionales que superan las expectativas y dejan un legado arquitectónico duradero.',
  landscape_design:
    'Realce la belleza y el encanto de su villa con nuestros exquisitos servicios de diseño paisajístico en España. Nuestro experto equipo de diseñadores crea impresionantes espacios al aire libre, incorporando exuberantes jardines, impresionantes paisajes y cautivadoras características del agua, asegurando que su villa destaque como un verdadero oasis de lujo y tranquilidad.',
  building_materials:
    'Ofrecemos una amplia selección de materiales de construcción de alta calidad para ayudarle a completar su proyecto de construcción con facilidad. Desde los materiales básicos hasta los toques finales, tenemos todo lo que necesita para hacer realidad su visión.',
};
