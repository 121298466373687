export const realEstateProcessTexts = {
  label: 'serveis',
  description:
    `El nostre objectiu és ajudar-lo a trobar la casa dels seus somnis, tant si desitja comprar com llogar. Oferim una àmplia gamma de propietats en diferents ubicacions i estils, per la qual cosa segur que trobarà alguna cosa que s'adapti a les seves necessitats i pressupost.Faci una ullada a les nostres propietats destacades a continuació o utilitzi la barra de recerca per trobar un tipus específic de propietat o ubicació. Si necessita ajuda o té alguna pregunta, no dubti a posar-se en contacte amb nosaltres.`,
};

export const processList = {
labelFirst: 'Gestió de propietats',
labelSecond: 'Valoració de la propietat',
labelThird: 'Màrqueting i publicitat',
labelFourth: 'Compra i venda',
labelFifth: 'Lloguer i arrendament',
labelSixth: 'Assistència legal',
};

export const servicesTexts = {
  label: 'Els nostres serveis immobiliaris',
  description: `Oferim una àmplia gamma de serveis de disseny d'interiors per ajudar-lo a aconseguir l'espai dels seus somnis. Des del concepte inicial i la planificació de l' espai fins a la selecció de colors, la recerca de mobles i els accessoris, el nostre equip pot encarregar-se de tots els aspectes del seu projecte. Els nostres serveis inclouen:`,
};

export const servicesList = {
  textFirst: 'Defineixi les seves necessitats',

  textSecond: 'Investigueu la ubicació',

  textThird: 'Avaluï la idoneïtat de la parcel·la',

  textFourth: 'Comprovi els aspectes legals i reglamentaris ',

  textFifth: 'Consideri la infraestructura i els serveis públics',

  textSixth: 'Avaluï el potencial futur i el valor de revenda',
};