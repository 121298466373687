import React, { FC } from 'react';
import {
  FAQMenuBlock,
  FAQMenuTitle,
  FAQAnswersTable,
  FAQAnswersTitleBlock,
  FAQAnswersTitle,
  FAQAnswers,
  FAQBlock,
} from './FAQDesktop.styled';
import { MenuListBlock } from '../../MenuListBlock';
import { ReactComponent as Question } from '../../../shared/assets/icons/question.svg';
// import { menuItems } from '../../MenuSection/MenuSection.constatnts';
import { FAQItem } from '../FAQItem';
import { FaqItemProps } from '../../MenuSection/MenuSection.types';
import { FaqComponentProps } from '../FAQSection.types';

export const FAQDesktop: FC<FaqComponentProps> = ({
  activeIdAfterClick,
  setActiveIdAfterClick,
  menuItems,
  activeFAQId,
  setActiveFAQId,
}) => {
  return (
    <FAQBlock>
      <FAQMenuBlock>
        <FAQMenuTitle>
          <span>FAQ</span>
          <Question className="question-icon" />
        </FAQMenuTitle>
        <MenuListBlock
          isNotRouting
          activeIdAfterClick={activeIdAfterClick}
          setActiveIdAfterClick={setActiveIdAfterClick}
          isFaq
        />
      </FAQMenuBlock>
      <FAQAnswersTable>
        <FAQAnswersTitleBlock>
          {menuItems[activeIdAfterClick - 1].icon}
          <FAQAnswersTitle>
            {menuItems[activeIdAfterClick - 1].label}
          </FAQAnswersTitle>
        </FAQAnswersTitleBlock>
        <FAQAnswers>
          {menuItems[activeIdAfterClick - 1].faq.map(
            (faqItem: FaqItemProps) => (
              <FAQItem
                {...faqItem}
                activeIdAfterClick={activeIdAfterClick}
                activeFAQId={activeFAQId}
                setActiveFAQId={setActiveFAQId}
                key={faqItem.title + faqItem.id + faqItem.description}
              />
            ),
          )}
        </FAQAnswers>
      </FAQAnswersTable>
    </FAQBlock>
  );
};
