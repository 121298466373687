export const packagesTexts = {
  label: 'Forfaits d’investissement',
  description:
    'Notre processus d’investissement repose sur une approche rigoureuse et disciplinée de la recherche et de l’analyse. Nous pensons que la recherche et l’analyse approfondies sont essentielles pour identifier des opportunités d’investissement de haute qualité et gérer efficacement le risque.',
};

export const packagesBlocks = {
  firstName: 'Nom',
  firstDownloadText: 'Télécharger',

  secondName: 'Nom',
  secondDownloadText: 'Télécharger',

  thirdName: 'Nom',
  thirdDownloadText: 'Télécharger',
};
