import React, { FC } from 'react';
import { HeaderTitlesEn } from '../HeaderSection.constatnts';
import {
  TopBlock,
  MiddleBlock,
  BottomBlock,
  LogoBlock,
  LogoImg,
  LogoTitle,
  SloganBlock,
  DescriptionBlock,
  TextBlock,
  HeaderHoverWrapper,
} from './HeaderHoverBlock.styled';
import { HeaderMenuComponent } from '../../HeaderMenuComponent';
import { getMenuItems } from '../../MenuSection/MenuSection.constatnts';

export interface HeaderHoverBlockProps {
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
}

export const HeaderHoverBlock: FC<HeaderHoverBlockProps> = ({
  setActiveId,
  activeId,
}) => {
  const menuItems = getMenuItems();
  return (
    <HeaderHoverWrapper isActive={activeId}>
      <TopBlock>
        <LogoBlock>
          <LogoImg />
          <LogoTitle>{HeaderTitlesEn.LOGO_TEXT}</LogoTitle>
        </LogoBlock>
      </TopBlock>
      <MiddleBlock>
        <TextBlock>
          {menuItems.map(({ id, label, description }) => {
            if (id !== activeId) return null;
            return (
              <>
                <SloganBlock>{label}</SloganBlock>
                <DescriptionBlock>{description}</DescriptionBlock>
              </>
            );
          })}
        </TextBlock>
        <HeaderMenuComponent setActiveId={setActiveId} activeId={activeId} />
      </MiddleBlock>
      <BottomBlock />
    </HeaderHoverWrapper>
  );
};
