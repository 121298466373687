import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../theme/Theme.constants';
import { MenuItem, Select } from '@mui/material';

export const TechnologiesSectionWrapper = styled.div`
  width: 100%;
  z-index: 10;
  border-top: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    margin: 5rem 0;
  }
`;

export const TechnologiesInfoBlockWrapper = styled.div`
  padding: 0;
  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0 ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 ${paddings.desktopSidePadding};
  }
`;

export const TechnologiesInfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-right: 1px solid ${colorVariables.blockBorderColor};
    border-left: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const TechnologiesTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.75rem;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  text-transform: uppercase;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    border-right: 1px solid ${colorVariables.blockBorderColor};
    border-bottom: none;
    font-size: 3.125rem;
    line-height: 3.75rem;
  }
`;

export const TechnologiesDescription = styled.div`
  ont-family: 'Overpass';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding: 1rem 1.25rem;
  background: ${colorVariables.alternativeBackground};
  backdrop-filter: blur(10px);
  color: rgba(255, 255, 255, 0.7);

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1rem;
    line-height: 1.875rem;
    padding: 2rem 6.875rem;
  }
`;

export const TechnologiesBlock = styled.div`
  padding: 2.5rem 1.25rem;
  border-top: 1px solid ${colorVariables.blockBorderColor};

  height: fit-content;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem ${paddings.tabletSidePadding};
    gap: 0.625rem 0;
    border-bottom: 1px solid ${colorVariables.blockBorderColor};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 3.75rem ${paddings.desktopSidePadding};
    gap: 1.25rem 0;
  }
`;

export const TechnologyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 3.75rem;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: 25%;
    height: 10.75rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc(100% / 6);
    height: 13rem;
  }
`;

export const TechnologyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  padding: 0rem 1.25rem 80px;
  @media only screen and (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5rem ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    padding: 5rem ${paddings.desktopSidePadding};
  }
`;

export const TechnologyDescription = styled.div`
  width: 100%;
  font-family: 'Overpass';
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${colorVariables.footerReservedTitle};
  padding: 1.875rem;
  background: ${colorVariables.alternativeBackground};
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  backdrop-filter: blur(1px);
  height: fit-content;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: 50%;
  }
`;

export const TechnologyImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  // & img {
  //   width: 80%;
  //   height: 80%;
  // }

  @media only screen and (max-width: ${Breakpoints.md}px) {
    margin-top: 30px;
  }
  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: 50%;
  }
  @media only screen and (min-height: ${Breakpoints.height}px) {
    height: 50vh;
  }
`;

export const SelectComponent = styled(Select)`
 
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  gap: 0.875rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  transition: ${transitions.backgroundHoverTransition};
  background: #171719;
  border-radius: 0 !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1px);

  .MuiSelect-icon {
    color: white;
  }

  

  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: calc(100vw - 80px);
    border: 1px solid ${colorVariables.blockBorderColor};
  }

  
  .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    font-family: 'Montserrat', sans-serif;
    color: ${colorVariables.mainTextColor};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 0;
  }

  div {
    padding: 0 !important;
  }

  &:nth-child(2n - 1) {
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }

  &:hover {
    background-color: ${colorVariables.opacityWhiteBackgroundColor};
    backdrop-filter: blur(2px);
    opacity: 1;
  }
`;

export const MenuItemComponent = styled(MenuItem)`
  display: flex;
  gap: 0.875rem;
  width: 100%;
  padding: 0 1.75rem !important;
  border: none;
  border-bottom: 1px solid ${colorVariables.blockBorderColor} !important;
  background-color: #1e1e1e !important;
  color: ${colorVariables.mainTextColor} !important;
  height: 3rem;
  transition: 0.3s background-color ease;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #262627 !important;
  }
`;
