export const aboutListText = {
  peopleText:
    'У нас є команда досвідчених професіоналів, які присвячені наданню відмінного сервісу нашим клієнтам.',
  bookText:
    'У нас є підтверджена історія успіху з багатьма задоволеними клієнтами.',
  clipboardText:
    'Ми пропонуємо широкий спектр послуг, щоб задовольнити унікальні потреби наших клієнтів.',
  microchipText:
    'Ми використовуємо останні технології та стратегії маркетингу, щоб забезпечити, що власності наших клієнтів бачать правильні люди.',
  handshakeText:
    'Ми надаємо юридичну допомогу, щоб забезпечити плавну та ефективну обробку всіх транзакцій.',
};
