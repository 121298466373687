import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';

export const HeaderSectionWrapper = styled.div`
  position: relative;
  width: 100%;

  & .blured-button {
    z-index: 10;
  }

  @media only screen and (min-height: 630px) {
    height: 100vh;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    height: fit-content;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) and (min-height: 630px) {
    height: 100vh;
  }
`;

export const HeaderSectionSubstrate = styled.div`
  width: 100%;
  background-color: ${colorVariables.mobileOpacityBackgroundColor};

  @media only screen and (min-height: 630px) {
    height: 100vh;
  }
  @media only screen and (min-width: ${Breakpoints.md}px) {
    background-color: ${colorVariables.tabletOpacityBackgroundColor};
    height: fit-content;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    background-color: ${colorVariables.opacityBackgroundColor};
    height: 100vh;
  }
`;

export const HeaderSectionBlocks = styled.div`
  width: 100%;
  padding: ${paddings.mobilePagePadding};
  margin-top: 3.75rem;

  @media only screen and (min-height: 630px) {
    height: calc(100vh - 3.75rem);
  }
  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: ${paddings.tabletPagePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    height: calc(100vh - 2.75rem);
    padding: ${paddings.desktopPagePadding};
    margin-top: 2.75rem;
  }
`;
