import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${colorVariables.blackBackgroundColor};
  border-top: 1px solid ${colorVariables.contactsHeaderBorderColor};
  padding: 0.6rem 1.25rem;
  margin-top: 55px;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0.93rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.lg}px) {
    padding: 1.25rem 6.25rem;
    margin-top: 40px;
  }
`;

export const LeftBlockWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftBlockIcon = styled.img`
  margin-right: 0.62rem;
`;

export const HeaderTitle = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`;

export const RightBlockWrapper = styled.div`
  margin-left: auto;
`;

export const RightIconWrapper = styled.button`
  background: ${colorVariables.contactsHeaderIconBackground};
  border: 1px solid ${colorVariables.contactsHeaderBtnBorderColor};
  cursor: pointer;
  padding: 0.62rem 0.93rem;
  
`;
export const RightBlockIcon = styled.img`
  width: 0.93rem;
  height: 0.93rem;
`;

export const OrderBtn = styled.button`
  width: 8.12rem;
  height: 2.18rem;
  background: ${colorVariables.contactsHeaderIconBackground};
  border: 1px solid ${colorVariables.contactsHeaderBtnBorderColor};
  cursor: pointer;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25rem;
  text-align: center;
  color: #ffe662;
`;
