import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const ConsultingPageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: ${colorVariables.alternativeBackground};
`;
export const ConsultingHeaderServicesWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    padding-bottom: 3.75rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding-bottom: 8.75rem;
  }
`;
