export const investmentsProcess = [
  {
    number: 1,
    label: ` Projecció en pantalla`,
    description:
      `Utilitzem una gamma d'eines i tècniques de selecció per identificar possibles oportunitats d'inversió.`,
  },
  {
    number: 2,
    label: ' Recerca',
    description:
      `Portem a terme una investigació fonamental en profunditat sobre les empreses i indústries en les quals estem considerant invertir.`,
  },
  {
    number: 3,
    label: 'Anàlisi',
    description:
      `Analitzem els estats financers, els equips de gestió, els panorames competitius i altres factors clau que impulsen l'èxit de les empreses en les quals estem considerant invertir.`,
  },
  {
    number: 4,
    label: ' Avaluació',
    description:
      `Utilitzem una varietat de tècniques de valoració per determinar el valor intrínsec de les empreses en les quals estem considerant invertir.`,
  },

  {
    number: 5,
    label: 'Gestió de riscos',
    description:
      `Adoptem un enfocament disciplinat de la gestió de riscos, centrant-nos a preservar el capital i evitar pèrdues permanents.`,
  },
];

export const investmentsProcessTexts = {
  label: `El nostre procés d'inversió`,
  description:
    `El nostre procés d'inversió es basa en un enfocament rigorós i disciplinat de la recerca i l'anàlisi. Creiem que la recerca i l'anàlisi en profunditat són fonamentals per identificar oportunitats d'inversió d'alta qualitat i gestionar el risc amb eficàcia.`,
};
