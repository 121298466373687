export const PoolsSection = {
  title: 'Piscinas',
  description: `Descubra nuestras piscinas de cerámica Compass: 24 modelos, perfectos para casas modernas o clásicas. Adecuadas para jardines, terrazas o interiores. Realce su espacio vital con estilo y versatilidad. Disfrute de la belleza y la funcionalidad de nuestras piscinas de cerámica Compass, que ofrecen una armoniosa mezcla de estilo y versatilidad para sus necesidades de ocio y relajación. Explore nuestra colección ahora y sorpréndase con una piscina que realmente realce su entorno vital. ¡Sumérjase hoy mismo!`,
  premium: 'Premium',
  selection: 'Selección',
  small: 'Pequeño',
  length: 'Longitud',
  width: 'Anchura',
  depth: 'Profundidad',
  capacity: 'Capacidad',
};
