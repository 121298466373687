import React, { FC } from 'react';
import { NavBlock, SliderSectionWrapper } from './MobileSlider.styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import firstSlide from '../../../shared/assets/images/slider-images/slider-img-01.jpg';
import secondSlide from '../../../shared/assets/images/slider-images/slider-img-02.jpg';
import thirdSlide from '../../../shared/assets/images/slider-images/slider-img-03.jpg';
import fourthSlide from '../../../shared/assets/images/slider-images/slider-img-4.jpg';
import 'swiper/css';
import 'swiper/css/pagination';
import { BluredButton } from '../../../shared/components/BluredButton';
import { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const MobileSlider: FC = () => {
  const { t } = useTranslation();
  const slides = [
    { id: 1, img: firstSlide },
    { id: 2, img: secondSlide },
    { id: 3, img: thirdSlide },
    { id: 4, img: fourthSlide },
  ];
  return (
    <SliderSectionWrapper>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {slides.map(({ id, img }) => {
          return (
            <SwiperSlide key={id}>
              <LazyLoadImage src={img} alt="" effect="blur" visibleByDefault />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <NavBlock>{t('mainPage.sliderTitles.LANDSCAPES_BTN')}</NavBlock>
      {/* <BluredButton
        title={t('mainPage.sliderTitles.LEARN_MORE')}
        handleClick={() => {}}
        className="learn-more-btn"
      /> */}
    </SliderSectionWrapper>
  );
};
