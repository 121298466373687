import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const BuildingMaterialsPageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: ${colorVariables.alternativeBackground};
`;

export const MaterialsSectionWrapper = styled.div`
  padding: 3.75rem 0 5rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem 0px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 6.25rem 0 8.125rem;
  }
`;

export const BenefitsSectionWrapper = styled.div`
  margin-bottom: 3.75rem;

  @media (min-width: ${Breakpoints.md}px) {
    margin-bottom: 5rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    margin-bottom: 7.5rem;
  }
`;
