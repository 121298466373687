export const interiorNavItems = {
  services: 'Serveis',
  portfolio: 'Portafoli',
};
export const description =
  `El disseny d'interiors no és només creativitat, sinó tota una gamma de serveis que converteixen els metres quadrats ordinaris en confort i harmonia. Un interior d'alta qualitat amb dibuixos ben pensats, components tècnics, colors i mobles ecològics ajudarà a crear un espai còmode i funcional que li donarà alegria a vostè i als seus éssers estimats durant molts anys. És una inversió en el seu benestar i comoditat que pot augmentar el seu estat d'ànim i eficiència, reduir l'estrès i millorar la seva qualitat de vida. I nosaltres coneixem la fórmula perfecta.`;
export const currentText = {
  label: `Els nostres serveis de disseny d'interiors`,
  description:
    `El desenvolupament d' un concepte d' interior no és només un procés creatiu, sinó també polifacètic que inclou moltes etapes. Estem disposats a convertir-nos en un guia de confiança per a vostè i acompanyar-lo en cada fase de la realització: des d'una bella imatge al seu cap fins a la plasmació dels seus somnis més salvatges en la vida real. Les etapes inclouen:`,
};

export const currentPortfolioText = {
  description:
    `Faci una ullada a alguns dels nostres projectes anteriors per veure la gamma d'estils i solucions de disseny que hem creat per als nostres clients. Tant si busca un estil modern, tradicional o una cosa intermèdia, podem ajudar-lo a aconseguir l'aspecte i la sensació que desitja.`,
};

export const servicesList = {
  planningText: `Desenvolupament del concepte de disseny`,
  colorText: `Creació de decisions de planificació i esbossos`,
  furnitureText: `Selecció de materials i elements d' acabat`,
  designText: `Desenvolupament de circuits elèctrics i d' enllumenat`,
  windowText: `Creació de la visualització en 3D`,
  artText: `Muntatge complet amb mobiliari, accessoris i decoració`,
};
