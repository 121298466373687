import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColorBlock,
  ColorTitle,
  ColorsBlock,
  ColorsDescription,
  ColorsInfoBlock,
  ColorsInfoBlockWrapper,
  ColorsSectionWrapper,
  ColorsTitle,
} from './ColorsSection.styled';
import { ColorDataProps } from '../SwimmingPoolsPage.types';
import anthracite from '../../../shared/assets/images/swiming-pools/colors/Anthracite.png';
import novaBlue from '../../../shared/assets/images/swiming-pools/colors/NovaBlue.png';
import novaGrey from '../../../shared/assets/images/swiming-pools/colors/NovaGrey.png';
import novaNavy from '../../../shared/assets/images/swiming-pools/colors/NovaNavy.png';
import novaPearl from '../../../shared/assets/images/swiming-pools/colors/NovaPearl.png';
import novaStone from '../../../shared/assets/images/swiming-pools/colors/NovaStone.png';

export const ColorsSection: FC = () => {
  const { t } = useTranslation();

  const colorsData = [
    {
      id: 1,
      // title: t('swimmingPools.ColorsSection.snote'),
      title: t('swimmingPools.ColorsSection.first'),
      color: anthracite,
    },
    {
      id: 2,
      title: t('swimmingPools.ColorsSection.second'),
      color: novaBlue,
    },
    {
      id: 3,
      title: t('swimmingPools.ColorsSection.third'),
      color: novaGrey,
    },
    {
      id: 4,
      title: t('swimmingPools.ColorsSection.fourth'),
      color: novaNavy,
    },
    {
      id: 5,
      title: t('swimmingPools.ColorsSection.fifth'),
      color: novaPearl,
    },
    {
      id: 6,
      title: t('swimmingPools.ColorsSection.sixth'),
      color: novaStone,
    },
  ];

  return (
    <ColorsSectionWrapper id="colors-section">
      <ColorsInfoBlockWrapper>
        <ColorsInfoBlock>
          <ColorsTitle>{t('swimmingPools.ColorsSection.title')}</ColorsTitle>
          <ColorsDescription>
            {t('swimmingPools.ColorsSection.description')}
          </ColorsDescription>
        </ColorsInfoBlock>
      </ColorsInfoBlockWrapper>
      <ColorsBlock>
        {colorsData.map((color: ColorDataProps) => (
          <ColorBlock key={color.id} backgroundColor={color.color}>
            <ColorTitle>{color.title}</ColorTitle>
          </ColorBlock>
        ))}
      </ColorsBlock>
    </ColorsSectionWrapper>
  );
};
