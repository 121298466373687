import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const RealEstatePageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;
export const ServicesSectionWrapper = styled.div`
  background-color: ${colorVariables.alternativeBackground};
  padding: 0px 0rem 3.75rem 0rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 2.5rem 3.75rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0px 0rem 5rem 0rem;
  }
`