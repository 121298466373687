import styled from 'styled-components';
import { colorVariables } from '../../theme/Theme.constants';

export const MenuList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid ${colorVariables.blockBorderColor};
`;

export const MenuListItem = styled.div<{
  isActive?: boolean;
  isClicked?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0 2.75rem;
  gap: 0.875rem;
  width: 50%;
  height: 4rem;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  background-color: ${colorVariables.alternativeBackground};
  transition: 0.3s all ease;
  cursor: pointer;

  &:nth-child(2n - 1) {
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }

  .menu-icon {
    fill: ${colorVariables.mainIconColor};
    width: 1.75rem;
    width: 1.75rem;
    transition: 0.3s all ease;
  }

  &:hover {
    background-color: ${colorVariables.opacityBackgroundColor};
    backdrop-filter: blur(2px);
    opacity: 1;

    .menu-icon {
      fill: ${colorVariables.mainIconColor};
    }
  }
`;

export const ItemTitle = styled.div`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
