export const investmentsNavItems = {
  philosophy: 'Філософія',

  process: 'Процес',

  packages: 'Пакети',

  team: 'Команда',
};

export const investmentsSecondDescription =
  'Іспанія - це місце, де середземноморська культура поєднується з багатою історією та унікальною архітектурою, приваблюючи туристів та інвесторів з усього світу. Це не дивно, адже іспанський ринок нерухомості є одним з найперспективніших і найприбутковіших в Європі. Іспанія пропонує широкий вибір - від квартир у центрі міста до розкішних вілл на узбережжі. Завдяки помірним цінам на житло, придбання нерухомості доступне широкому колу інвесторів з різних країн. Іспанські міста славляться своєю інфраструктурою і якістю життя, що приваблює людей, готових придбати нерухомість для постійного проживання або оренди. Кожен інвестор, який звертається до нас, може отримати індивідуальні пропозиції, стабільний фінансовий приплив, юридичні послуги та оцінку ризиків.';
