export const realEstateProcessTexts = {
  label: 'services',
  description:
    "Our goal is to help you find your dream home, whether you're looking to buy or rent. We offer a wide range of properties in different locations and styles, so you're sure to find something that suits your needs and budget. Take a look at our featured properties below or use the search bar to find a specific type of property or location. If you need any assistance or have questions, feel free to contact us.",
};
export const processList = {
  labelFirst: 'Property management',
  labelSecond: 'Property valuation',
  labelThird: 'Marketing and advertising',
  labelFourth: 'Buying and selling',
  labelFifth: 'Renting and leasing',
  labelSixth: 'Legal assistance',
};
  



export const servicesTexts = {
    label: 'Our Real estate Services',
    description:
      'We offer a wide range of interior design services to help you achieve the space of your dreams. From initial concept and space planning to color selection, furniture sourcing, and accessorizing, our team can handle every aspect of your project. Our services include:',
  };
export const servicesList = {
  textFirst: 'Define your requirements',

  textSecond: 'Research the location',

  textThird: "Assess the plot's suitability",

  textFourth: 'Check legal and regulatory aspects',

  textFifth: 'Consider infrastructure and utilities',

  textSixth: 'Evaluate future potential and resale value',
};
