import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { ReactComponent as ViewIcon } from '../../assets/icons/view-icon.svg';
import {
  SliderSectionWrapper,
  SlideImg,
  ViewBtn,
  ViewBtnText,
  NameProjectWrapper,
  NameProject,
  TopWrapper,
  PageName,
} from './SliderSection.styled';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';

interface SliderSectionProps {
  slides: { id: number; img: string; projectName?: string }[];
  isViewBtn?: boolean;
  isProjectName?: boolean;
  pageIcon?: React.ReactNode;
  pageName?: string;
}

export const SliderSection: FC<SliderSectionProps> = ({
  slides,
  isViewBtn,
  isProjectName,
  pageIcon,
  pageName,
}) => {
  const { t } = useTranslation();
  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
  };

  const isTablet = useMediaQuery({
    query: ` (min-width: ${Breakpoints.md}px)`,
  });
  return (
    <SliderSectionWrapper>
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        className="mySwiper">
        {slides.map(({ id, img, projectName }) => {
          return (
            <SwiperSlide key={id}>
              {/* {isProjectName && (
                <NameProjectWrapper>
                  {isTablet && (
                    <TopWrapper>
                      {pageIcon}
                      <PageName>{pageName}</PageName>
                    </TopWrapper>
                  )}  
                  <NameProject>{projectName}</NameProject>     //// for future features ///
                </NameProjectWrapper>
              )} */}
              <SlideImg src={img} alt="slider-img" loading="lazy" />
            </SwiperSlide>
          );
        })}
        {/* {isViewBtn && (
          <ViewBtn>
            <ViewIcon />
            <ViewBtnText>3D {t('interiorCasePage.viewBtnText')}</ViewBtnText>    //// for future features ///
          </ViewBtn>
        )} */}
      </Swiper>
    </SliderSectionWrapper>
  );
};
