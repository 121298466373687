export enum TopNavbarTitlesEn {}

export const languages = [
  {
    label: 'English',
    value: 'EN',
  },
  {
    label: 'Spanish',
    value: 'ES',
  },
  // {
  //   label: 'French',
  //   value: 'FR',
  // },
  {
    label: 'Catalan',
    value: 'CA',
  },
  {
    label: 'Ukrainian',
    value: 'UA',
  },
];
