export const solarEnergyBenefitsTexts = {
  label: 'Benefits of solar energy',
  description:
    'Solar panels are devices that convert solar energy into electricity. The use of panels has a positive impact on the economy and the environment. They can reduce electricity costs, reduce your carbon footprint and increase property value. While some homeowners are hesitant about cost and maintenance, the benefits outweigh the drawbacks.',
};
export const benefitsList = {
  firstLabel: 'No emissions of harmful gases.',
  secondLabel: 'Long equipment life.',
  thirdLabel: 'Improving energy security.',
  fourthLabel: 'An inexhaustible source of energy.',
};
