import styled from 'styled-components';
import { colorVariables } from '../../../theme/Theme.constants';

export const TextArea = styled.textarea`
  resize: none;
  min-width: 100%;
  padding: 1rem 1.25rem;
  background: inherit;
  border: 1px solid rgba(255, 255, 255, 0.2);
  outline: none;
  color: ${colorVariables.alternativeTextColor};

  &::-webkit-scrollbar {
    width: 0;
  }
`;
