import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Title,
  AfterTitle,
  TextWrapper,
  FormWrapper,
  ServicesBlock,
  FunctionButton,
  HeartIcon,
  ServicesCards,
  ServiceCard,
  ServiceIcon,
  ServiceTitle,
  ServiceInfo,
  AddServiceButton,
  TextAreaWrapper,
} from './FavouritesBlock.styled';
import { Field, Formik } from 'formik';
import { TextAreaFieldInput } from '../../../shared/components/TextAreaFieldInput';
import { AcceptButton, ControlsBlock } from '../LeadForm.styled';
import { ReactComponent as EmptyHeartIcon } from '../../../shared/assets/icons/favourite/empty-heart-icon.svg';
import { ReactComponent as AddServiceIcon } from '../../../shared/assets/icons/lead-forms/add-service-icon.svg';
import {
  addServiceInFauvorite,
  isFavouriteCheck,
} from '../../../shared/helpers/globalConstants';
import { getMenuItems } from '../../../components/MenuSection/MenuSection.constatnts';
import { MenuItemProps } from '../../../components/MenuSection/MenuSection.types';
import { LeadFormDataProps } from '../LeadForm';
import { getMainNavbarItems } from '../LeadForm.constants';
import { useTranslation } from 'react-i18next';

interface InitialValuesProps {
  services_question?: string;
}

interface NewMenuItemProps {
  id: number;
  service: string;
}

interface FavouritesBlockProps {
  setCurrentNavItem: (currentNavItem: string) => void;
  setLeadFormData: (leadFormData: LeadFormDataProps) => void;
  leadFormData: LeadFormDataProps;
}

export const FavouritesBlock: FC<FavouritesBlockProps> = ({
  setCurrentNavItem,
  setLeadFormData,
  leadFormData,
}) => {
  const [isFavourite, setIsFavourite] = useState(false);
  const [allServicesIsOpen, setAllServicesIsOpen] = useState(false);
  const [textAreaIsOpen, setTextAreaIsOpen] = useState(false);
  const [currentFavouritesServices, setCurrentFavouritesServices] = useState<
    NewMenuItemProps[] | []
  >([]);
  const formsNavigationItems = getMainNavbarItems();
  const { t } = useTranslation();
  const menuItems = getMenuItems();

  const newMenuItems = useMemo(
    () =>
      menuItems.map((menuItem: MenuItemProps) => ({
        id: menuItem.id,
        service: menuItem.label,
      })),
    [menuItems],
  );

  useEffect(() => {
    const isFavouriteArrayString = localStorage.getItem('isFavourite');

    let isFavouriteArray =
      isFavouriteArrayString && JSON.parse(isFavouriteArrayString);
    setCurrentFavouritesServices(isFavouriteArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavourite]);

  useEffect(() => {
    const isFavouriteArrayString = localStorage.getItem('isFavourite');
    if (!isFavouriteArrayString) return; //added to fix bug with undefined in isFavouriteArrayString
    let isFavouriteArray =
      isFavouriteArrayString && JSON.parse(isFavouriteArrayString);

    const servicesIds = isFavouriteArray.map((service: any) => service.id);

    const newFavoritesServices = newMenuItems.filter(
      (menuItem: NewMenuItemProps) => servicesIds.includes(menuItem.id),
    );

    setCurrentFavouritesServices(newFavoritesServices);
    localStorage.setItem(
      'isFavourite',
      JSON.stringify([...newFavoritesServices]),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsNavigationItems[0].title]);

  const handleSubmit = (values: InitialValuesProps) => {
    const favouriteServicesId = currentFavouritesServices?.map(
      (service: any) => service.id,
    );

    setLeadFormData({
      ...leadFormData,
      services_question: values.services_question,
      services: favouriteServicesId,
    });

    setCurrentNavItem(formsNavigationItems[1].title);
  };

  const handleChangeFauvoriteStatus = (id: number, title: string) => {
    addServiceInFauvorite(id, title);
    setIsFavourite(!isFavourite);
  };

  const currentIcon = (id: number) => {
    return menuItems.find((menuItem: MenuItemProps) => menuItem.id === id)
      ?.icon;
  };

  const handleAddAllServicesToFavourites = () => {
    if (currentFavouritesServices?.length === menuItems?.length) {
      localStorage.setItem('isFavourite', JSON.stringify([]));
      setCurrentFavouritesServices([]);
      setAllServicesIsOpen(false);
    } else {
      localStorage.setItem('isFavourite', JSON.stringify(newMenuItems));
      menuItems && setCurrentFavouritesServices([...newMenuItems]);
    }
  };

  const initialValues = {
    services_question: leadFormData.services_question,
  };

  return (
    <>
      <TextWrapper>
        <Title>{t('leadForm.leadFormPage.favouriteTitle')}</Title>
        <AfterTitle>
          {t('leadForm.leadFormPage.favouriteDescription')}
        </AfterTitle>
      </TextWrapper>
      <ServicesBlock>
        <FunctionButton onClick={handleAddAllServicesToFavourites}>
          {currentFavouritesServices?.length === menuItems?.length
            ? t('leadForm.leadFormPage.clearList')
            : t('leadForm.leadFormPage.fullCycleButton')}
        </FunctionButton>
        <ServicesCards>
          {allServicesIsOpen
            ? menuItems?.map((menuItem: MenuItemProps) => (
                <ServiceCard
                  key={menuItem.id}
                  onClick={() =>
                    handleChangeFauvoriteStatus(menuItem.id, menuItem.label)
                  }>
                  <ServiceInfo>
                    <ServiceIcon>{menuItem.icon}</ServiceIcon>
                    <ServiceTitle>{menuItem.label}</ServiceTitle>
                  </ServiceInfo>
                  <HeartIcon isFavourite={isFavouriteCheck(menuItem.id)}>
                    <EmptyHeartIcon />
                  </HeartIcon>
                </ServiceCard>
              ))
            : currentFavouritesServices?.map(
                (currentService: NewMenuItemProps) => (
                  <ServiceCard
                    key={currentService.id}
                    onClick={() =>
                      handleChangeFauvoriteStatus(
                        currentService.id,
                        currentService.service,
                      )
                    }>
                    <ServiceInfo>
                      <ServiceIcon>
                        {currentIcon(currentService.id)}
                      </ServiceIcon>
                      <ServiceTitle>{currentService.service}</ServiceTitle>
                    </ServiceInfo>
                    <HeartIcon
                      isFavourite={isFavouriteCheck(currentService.id)}>
                      <EmptyHeartIcon />
                    </HeartIcon>
                  </ServiceCard>
                ),
              )}
          {currentFavouritesServices?.length !== menuItems?.length && (
            <AddServiceButton
              onClick={() => setAllServicesIsOpen(!allServicesIsOpen)}>
              {!allServicesIsOpen && (
                <ServiceIcon>
                  <AddServiceIcon />
                </ServiceIcon>
              )}
              <ServiceTitle>
                {allServicesIsOpen
                  ? t('leadForm.leadFormPage.hide')
                  : t('leadForm.leadFormPage.addService')}
              </ServiceTitle>
            </AddServiceButton>
          )}
        </ServicesCards>
        <FunctionButton onClick={() => setTextAreaIsOpen(!textAreaIsOpen)}>
          {t('leadForm.leadFormPage.otherServices')}
        </FunctionButton>
      </ServicesBlock>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, handleChange }) => (
          <FormWrapper>
            <TextAreaWrapper textAreaIsOpen={textAreaIsOpen}>
              <Field
                label={t('leadForm.leadFormPage.comentsOrQuestions')}
                type={'text'}
                value={values.services_question}
                name={'services_question'}
                component={TextAreaFieldInput}
              />
            </TextAreaWrapper>
            <ControlsBlock isOneButton>
              <AcceptButton type="submit" onClick={() => handleSubmit(values)}>
                {t('leadForm.leadFormPage.nextButton')}
              </AcceptButton>
            </ControlsBlock>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
