import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import {
  ControlsBlock,
  HeaderSectionNavbarWrapper,
  HeartIcon,
  FavouritePageWrapper,
  LogoBlock,
  LogoTitle,
  NavigationBlock,
  RemoveButtonBlock,
  RemoveButtonTitle,
  ServiceCard,
  ServiceDescription,
  ServiceIconBlock,
  ServiceInfoBlock,
  ServiceTitle,
  ServicesCards,
  ServiceCardContent,
  FormControl,
  ButtonTitle,
  FormBlockWrapper,
  FormTitle,
  FormBlock,
} from './FavouritesPage.styled';
import { BluredButton } from '../../shared/components/BluredButton';
import { ReactComponent as Letter } from '../../shared/assets/icons/smart-home/letter.svg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { ReactComponent as FavouriteIcon } from '../../shared/assets/icons/favourite/favourite-icon.svg';
import { ReactComponent as RemoveBtnIcon } from '../../shared/assets/icons/favourite/remove-btn-icon.svg';
import { ReactComponent as EmptyHeartIcon } from '../../shared/assets/icons/favourite/empty-heart-icon.svg';
import { MenuItemProps } from '../../components/MenuSection/MenuSection.types';
import {
  addServiceInFauvorite,
  isFavouriteCheck,
  mainSection,
} from '../../shared/helpers/globalConstants';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../shared/helpers/routes';

export const FavouritesPage: FC = () => {
  const menuItems = getMenuItems();
  const [isFavourite, setIsFavourite] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBlockClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const handleChangeFauvoriteStatus = (id: number, title: string) => {
    addServiceInFauvorite(id, title);
    setIsFavourite(!isFavourite);
  };

  return (
    <FavouritePageWrapper>
      <HeaderSectionNavbarWrapper>
        <NavigationBlock>
          <LogoBlock>
            <FavouriteIcon />
            <LogoTitle>{t('favorites.FavoritesSection.title')}</LogoTitle>
          </LogoBlock>
        </NavigationBlock>
        {
          <ControlsBlock>
            <RemoveButtonBlock>
              <BluredButton
                title={
                  <RemoveButtonTitle>
                    <RemoveBtnIcon className="letter-button" />
                    <span>
                      {isTablet &&
                        t('favorites.FavoritesSection.removeButtonTitle')}
                    </span>
                  </RemoveButtonTitle>
                }
                handleClick={() => {
                  localStorage.setItem('isFavourite', JSON.stringify([]));
                  setIsFavourite(!isFavourite);
                }}
              />
            </RemoveButtonBlock>
            <BluredButton
              title={isTablet ? t('smartHome.HeaderSection.order') : <Letter />}
              handleClick={() => handleBlockClick(RoutePaths.leadForm)}
            />
          </ControlsBlock>
        }
      </HeaderSectionNavbarWrapper>
      <ServicesCards>
        {menuItems.slice(0, -1).map((menuItem: MenuItemProps) => (
          <ServiceCard
            key={menuItem.id}
            onClick={() =>
              handleChangeFauvoriteStatus(menuItem.id, menuItem.label)
            }
            isFavourite={isFavouriteCheck(menuItem.id)}>
            <ServiceCardContent>
              {!isMobile && (
                <ServiceIconBlock
                  isFavourite={isFavouriteCheck(menuItem.id)}
                  backgroundImage={menuItem.backgroundImg}>
                  {!isFavouriteCheck(menuItem.id) && menuItem.icon}
                </ServiceIconBlock>
              )}
              <ServiceInfoBlock>
                <ServiceTitle>
                  {isMobile && menuItem.icon}
                  <span>{menuItem.label}</span>
                </ServiceTitle>
                <ServiceDescription>{menuItem.description}</ServiceDescription>
              </ServiceInfoBlock>
              <HeartIcon isFavourite={isFavouriteCheck(menuItem.id)}>
                <EmptyHeartIcon />
              </HeartIcon>
            </ServiceCardContent>
            {isFavouriteCheck(menuItem.id) && !isMobile && (
              <FormControl
                onClick={() => handleBlockClick(menuItem?.routePath)}>
                <ButtonTitle>
                  {t('favorites.FavoritesSection.more')}
                </ButtonTitle>
              </FormControl>
            )}
          </ServiceCard>
        ))}
      </ServicesCards>
      <FormBlockWrapper>
        <FormBlock>
          <FormTitle>{t('favorites.FavoritesSection.formTitle')}</FormTitle>
          <BluredButton
            title={t('favorites.FavoritesSection.form')}
            handleClick={() => handleBlockClick(RoutePaths.leadForm)}
          />
        </FormBlock>
      </FormBlockWrapper>
    </FavouritePageWrapper>
  );
};
