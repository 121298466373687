import React, { FC, useState } from 'react';
import {
  HeaderWrapper,
  LeftPart,
  Title,
  RightPart,
  EmptyHeart,
  DropDownWrapper,
  DropDownTitle,
  ToogleWrapper,
  FilterList,
  FilterItem,
  FilterName,
} from './HeaderSection.styled';
import { BluredButton } from '../../../shared/components/BluredButton';
import emptyHeart from '../../../shared/assets/icons/empty-heart.svg';
import { ReactComponent as Letter } from '../../../shared/assets/icons/smart-home/letter.svg';
import { ReactComponent as DownArrow } from '../../../shared/assets/icons/drop-down-icon.svg';
import { ReactComponent as Logo } from '../../../shared/assets/icons/pre-build-houses/pre-build-logo.svg';
import { Breakpoints } from '../../../theme/Theme.constants';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { DropDownFilter } from '../../../components/DropDownFilterBlock';

interface HeaderProps {
  filterList: { id: number; name: string; type: string }[];
  setCurrentTypeName: any;
  currentTypeName: string;
  currentLanguageName: string;
}

export const HeaderSection: FC<HeaderProps> = ({
  filterList,
  setCurrentTypeName,
  currentTypeName,
  currentLanguageName
}) => {
  const { t } = useTranslation();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });
  const isTabletMaxWidth = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px) and (max-width: ${
      Breakpoints.xxl - 1
    }px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });

  return (
    <HeaderWrapper>
      <LeftPart>
        <Logo />
        <Title>{t('mainPage.MenuItemsTitles.pre_build_houses')}</Title>
        {isTabletMaxWidth && (
          <DropDownWrapper>
            <ToogleWrapper
              isOpenFilter={isOpenFilter}
              onClick={() => setIsOpenFilter(prev => !prev)}>
              <DropDownTitle>{currentLanguageName}</DropDownTitle>
              <DownArrow className="drop-down-icon" />
            </ToogleWrapper>
            {isOpenFilter && (
              <DropDownFilter
                filterList={filterList}
                setCurrentTypeName={setCurrentTypeName}
                setIsOpenFilter={setIsOpenFilter}
              />
            )}
          </DropDownWrapper>
        )}
        {isDesktop && (
          <FilterList>
            {filterList.map(({ id, name, type }) => {
              return (
                <FilterItem
                  key={id}
                  onClick={() => setCurrentTypeName(type.toLowerCase())}>
                  <FilterName
                    currentTypeName={name.toLowerCase() === currentTypeName}>
                    {name}
                  </FilterName>
                </FilterItem>
              );
            })}
          </FilterList>
        )}
      </LeftPart>

      <RightPart>
        <EmptyHeart src={emptyHeart} />
        <BluredButton
          title={
            isTablet ? (
              t('smartHome.HeaderSection.order')
            ) : (
              <Letter className="letter-button" />
            )
          }
          handleClick={() => {}}
        />
      </RightPart>
    </HeaderWrapper>
  );
};
