import React, { FC } from 'react';
import { LanguageDropdown } from '../../LanguageDropdown';
import { HeaderTitlesEn } from '../../HeaderSection/HeaderSection.constatnts';
import {
  FullBlock,
  LogoBlock,
  LogoImg,
  LogoTitle,
  NavBarDesktopBlocks,
  // StoreWrapper,
  FirstFullBlock,
} from './NavBarDesktop.styled';
import { NavBarItemTitlesProps } from '../TopNavBar.types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../../../shared/helpers/routes';
import { mainSection } from '../../../shared/helpers/globalConstants';

export const NavBarDesktop: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navBarItemTitlesArray = t('mainPage.navBarItemTitlesArray', {
    returnObjects: true,
  }) as NavBarItemTitlesProps[];

  const handleNavBarSectionClick = (routePath: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <>
      <NavBarDesktopBlocks>
        <FullBlock
          onClick={() => handleNavBarSectionClick(RoutePaths.mainPage)}>
          <LogoBlock>
            <LogoImg />
            <LogoTitle>{HeaderTitlesEn.LOGO_TEXT}</LogoTitle>
          </LogoBlock>
        </FullBlock>
        {navBarItemTitlesArray.map(
          (navBarItem: NavBarItemTitlesProps, index: number, storeItemName) =>
            index === 0 ? (
              <FirstFullBlock key={navBarItem.itemName + index}>
                <span
                  onClick={() =>
                    handleNavBarSectionClick(navBarItem.routePath)
                  }>
                  {navBarItem.itemName}
                </span>
                {/* STORE LOGIC */}
                {/* <StoreWrapper onClick={() => handleNavBarSectionClick('/')}>
                  {navBarItem.storeItemName ? navBarItem.storeItemName : ''}
                </StoreWrapper> */}
              </FirstFullBlock>
            ) : (
              <FullBlock
                key={navBarItem.itemName + index}
                onClick={() => handleNavBarSectionClick(navBarItem.routePath)}>
                <span>{navBarItem.itemName}</span>
              </FullBlock>
            ),
        )}
      </NavBarDesktopBlocks>
      {/* WE ARE WAITING FOR THE TRANSLATION */}
      <LanguageDropdown />
    </>
  );
};
