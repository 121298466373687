import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import { TextField } from '../../../shared/components/TextFieldInput/TextFieldInput.styled';
import { Button } from '../../../shared/components/BluredButton/BluredButton.styled';

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: fit-content;
  background: ${colorVariables.mainBackgroundColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    height: 100vh;
  }
`;

export const Contacts = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${colorVariables.blockBorderColor};
`;

export const MainTitleBlock = styled.div`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
  padding: 1rem;
  color: ${colorVariables.secondaryTextColor};
`;

export const ContactsBlock = styled.div`
  text-align: center;
  padding: 0.875rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  width: 500%;
  background: ${colorVariables.opacityWhiteBackgroundColor};

  &:first-child {
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colorVariables.opacityWhiteBackgroundColor};
  width: 100%;
  border: 1px solid ${colorVariables.blockBorderColor};
  border-right: none;
  border-left: none;
`;

export const IconsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  height: 3rem;
`;

export const NetworkIcon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  transition: ${transitions.opacityTransitions};

  &:hover {
    opacity: 0.5;
  }
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  border-top: 1px solid ${colorVariables.blockBorderColor};

  ${TextField} {
    padding: 1rem 1.5rem;
  }

  ${Button} {
    border: none;
    backdrop-filter: none;
    background: rgba(255, 255, 255, 0.15);
    transition: ${transitions.backgroundHoverTransition};

    &:hover {
      background: ${colorVariables.buttonGreyBackground};
    }
  }
`;

export const ButtonTitle = styled.span`
  border: none;
  cursor: pointer;
  background: none;
  color: ${colorVariables.mainTextColor};
`;
