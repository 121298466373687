import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';

export const ServicesWrapper = styled.div`
  position: relative;
  z-index: 15;
  @media (max-width: ${Breakpoints.md}px) {
    padding: 0px 1.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0px 6.25rem;
  }
`;

export const List = styled.ul`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem 1.25rem;
  }
`;

export const Item = styled.li`
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
  @media (min-width: ${Breakpoints.md}px) {
    flex-basis: calc(50% - 0.625rem);
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    flex-basis: calc(100% / 3 - 0.837rem);
  }
`;

export const Img = styled.img`
  display: block;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  background: ${colorVariables.alternativeBackground};
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 1.25rem 0;
  }
`;
export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25rem;

  text-align: center;

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
