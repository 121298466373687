import styled, { css } from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import bgImage from '../../../shared/assets/images/about-us/our-story-mobile-bg.jpg';

export const OurStoryMobileWrapper = styled.div`
  background-image: url(${bgImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  padding: 60px 1.25rem;
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`;

export const TextsWrapper = styled.div`
  border: 1px solid ${colorVariables.blockBorderColor};
  z-index: 15;
`;

export const TitleWrapper = styled.div`
  padding: 7px 0;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;

  text-align: center;
  text-transform: uppercase;

  color: ${colorVariables.secondaryTextColor};
`;

export const TextWrapper = styled.div`
  padding: 1rem 0.625rem;
`;
export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  max-width: 18.75rem;
  color: rgba(255, 255, 255, 0.7);
`;
