import React, { FC } from 'react';
import {
  ArrowBlock,
  ArrowIcon,
  BookBlock,
  BookIcon,
  ButtonsBlock,
  DescriptionBlock,
  HeartBlock,
  HeartIcon,
  TitleBlock,
} from './AboutUsMobile.styled';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../../shared/helpers/routes';
import { mainSection } from '../../../shared/helpers/globalConstants';
export const AboutUsMobile: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const aboutUsPath = RoutePaths.aboutUsPage;

  const handleBlockClick = (routePath: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <>
      <TitleBlock>
        <span>{t('mainPage.AboutUsTitles.about')}</span>
        <span className="opacity-part">{t('mainPage.AboutUsTitles.us')}</span>
      </TitleBlock>
      <DescriptionBlock>
        <p>{t('mainPage.AboutUsTitles.mainDescription')}</p>
      </DescriptionBlock>
      <ButtonsBlock onClick={() => handleBlockClick(aboutUsPath)}>
        <BookBlock>
          <BookIcon />
        </BookBlock>
        <HeartBlock>
          <HeartIcon />
        </HeartBlock>
        <ArrowBlock>
          <ArrowIcon />
        </ArrowBlock>
      </ButtonsBlock>
    </>
  );
};
