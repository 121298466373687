export const investmentsProcess = [
  {
    number: 1,
    label: 'Market monitoring and analysis',
    description:
      "A service element that provides the client with information about current trends in the real estate market. This includes market research, the state of supply and demand for certain types of properties, and future projections. In addition, this service may include an analysis of the current economic situation and its impact on the real estate market. For example, 2022 saw a steady increase in Spain's investment income, especially in real estate. This robust growth can be seen as a harbinger of how the market will develop in the coming years, including 2023. But only experienced specialists, based on observations, monitoring and comparison of proposals, will be able to describe the full picture for the client.",
  },
  {
    number: 2,
    label: 'Potential assessment',
    description:
      'The financial calculation of the return on the amount of money invested is a service that helps the client understand how much profit can be made from investing in real estate. Our experts make profitability calculations, taking into account not only the cost of the object, but also the expected income from rent or sale. Experts also analyze seasonality, weather conditions, current news, in order to answer the question as accurately as possible, what will be the income when renting or reselling after a while.',
  },
  {
    number: 3,
    label: ' Recommendations for the type of property',
    description:
      'Deciding where to invest in real estate can be tricky. Certainly, Barcelona and Madrid, the largest tourist destinations visited by thousands of tourists every year, can be an attractive choice for business. However, when investing in real estate, you should consider various factors that affect future returns. Because these cities are well-developed, suitable facilities may be limited. In addition, the best properties in these cities may be overpriced and not up to par with their quality. The service in question is to advise the client on the type of property that is best suited for his investment purposes. Recommendations may include different categories of properties such as residential or commercial, new builds or resales, as well as the location of the properties.',
  },
  {
    number: 4,
    label: 'Selection of liquid real estate',
    description:
      'The liquidity of villas, houses, townhouses is the main indicator of how quickly an asset can be converted into money. For investors, this is very important, because good liquidity means the ability to quickly sell your property and make a profit. At the same time, along with the location, type of real estate and quality of construction, an important factor in determining the liquidity is the uniqueness of the object. For example, a home with a panoramic view of the ocean or city skyline may be more attractive to buyers and therefore more marketable. This service includes an assessment of the potential for selling or renting real estate, as well as taking into account possible risks.',
  },

  {
    number: 5,
    label: 'Risk analysis',
    description:
      'A service that consists in assessing potential threats associated with investing in real estate. The risk can be argued with the economic situation, changes in legislation, competition in the market. Investment advisors help clients make an informed decision based on the level of risk and potential return.',
  },

  {
    number: 6,
    label: 'Transaction support (assignment or purchase and sale)',
    description:
      'A service that helps the client to conduct a real estate transaction, accompanying him at all stages of the process. In any corner of Spain, you can easily find a decent and comfortable place for a better life, and in order to make the purchase procedure as simple and clear as possible for you, our specialists will take responsibility for helping you at every stage.',
  },
];

export const investmentsProcessTexts = {
  label: 'Our Investment Process',
  description:
    'Our investment process is based on a rigorous and disciplined approach to research and analysis. We believe that in-depth research and analysis are key to identifying high-quality investment opportunities and managing risk effectively.',
};