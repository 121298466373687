export const ContactsTitles = {
  contacts: 'Contacts',
  telNumber: '+34 600 59 20 07',
  email: 'info@modernacasa.es',
  socialMedia: 'Réseaux sociaux',
  form: 'Former',
  name_placeholder: 'Nom',
  email_placeholder: 'Courriel',
  phone_placeholder: 'Numéro de téléphone',
  description_placeholder: 'Description',
  password_placeholder: 'Mot de passe',
  send: 'Envoyer',
};
