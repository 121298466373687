export const blockTitle = 'Etapes de les cases modulars';

export const blockDescription = 'Consulta inicial';

export const modularHousesStepSection = {
  stepOne: {
    title: 'Disseny i planificació',
    p1: `Durant aquest pas inicial, es determinen la mida, la distribució i l' estil de la casa modular. Treballant amb un arquitecte o fabricant de cases modulars, es crea un disseny detallat, tenint en compte els requisits específics i obtenint els permisos necessaris.`,
    p2: ``,
  },
  stepTwo: {
    title: 'Preparació dels fonaments',
    p1: `La primera tasca d' aquesta fase és escampar i anivellar el terreny. A continuació s' excava i es construeixen uns fonaments sòlids que compleixin les normes de construcció locals. La fonamentació ha d' estar degudament anivellada per suportar les unitats modulars.`,
    p2: ``,
  },
  stepThree: {
    title: `Fabricació d' unitats modulars`,
    p1: `En col·laboració amb un conegut fabricant de cases modulars, els mòduls individuals es construeixen en un entorn de fàbrica controlat. Aquest procés inclou la construcció de parets, sòls, sostres i la instal·lació de sistemes elèctrics i de fontaneria en cada mòdul.`,
    p2: ``,
  },
  stepFour: {
    title: 'Transport i preparació del terreny',
    p1: `Un cop acabats els mòduls, es transporten a l'obra en camions especialitzats. Mentrestant, es prepara el terreny instal·lant les connexions dels serveis públics i completant les obres necessàries, com l'anivellament i la jardineria.`,
    p2: ``,
  },
  stepFive: {
    title: 'Muntatge i instal·lació',
    p1: `Els mòduls es col·loquen sobre els fonaments amb grues o equips elevadors. Tècnics especialitzats o equips de construcció fixen els mòduls entre si, garantint la seva correcta alineació i segellament. Es realitzen les connexions elèctriques i de fontaneria entre els mòduls i s' afegeixen els tocs finals.`,
    p2: ``,
  },
  stepSix: {
    title: 'Acabat interior i exterior',
    p1: `En aquest pas, l'atenció se centra a completar el treball interior i exterior. El treball interior implica la instal·lació de sòls, acabats de parets, armaris i accessoris, mentre que el treball exterior inclou revestiments, teulades, finestres i portes. Aquests tocs finals milloren la funcionalitat i l'estètica de la casa modular.`,
    p2: ``,
  },
  stepSeven: {
    title: 'Integració de serveis i sistemes',
    p1: `L'objectiu d'aquest pas és connectar la casa modular els serveis públics locals, com l'aigua, l'electricitat i el gas. Els sistemes elèctrics, de fontaneria, calefacció i refrigeració es proven a fons per garantir el seu correcte funcionament.`,
    p2: ``,
  },
  stepEight: {
    title: 'Inspeccions finals i certificació',
    p1: `Per garantir el compliment dels codis i reglaments de construcció, es programa una inspecció final per part de les autoritats locals. Aquesta inspecció abasta tots els aspectes de la construcció de la casa modular. Un cop aprovada, s' obtenen els certificats d' ocupació o conformitat necessaris, la qual cosa significa que la casa modular és legalment habitable.`,
    p2: ``,
  },
};
