export const typeOfHouses = {
  airigh: 'Airigh',
  accessible: 'Accessible',
  bespokeDesign: ' Conception sur mesure',
  whitehouse: ' Maison Blanche',
  longhouse: ' Maison longue',
  steading: ' Tenir',
};

export const modelTitle = ' Maisons modulaires Airigh';
export const modelDescription = " Choisissez votre piscine en céramique Compass parmi 24 modèles, qui convient, grâce à son design, aussi bien à la résidence moderne qu'à la maison classique. Ils conviennent au jardin, à la terrasse à côté de la maison ou à l'intérieur comme piscine intérieure.";