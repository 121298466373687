import {
  TopNavbarTitles as TopNavbarTitlesCa,
  navBarItemTitlesArray as navBarItemTitlesCaArray,
  mobileNavBarItemTitlesArray as mobileNavBarItemTitlesCaArray,
} from '../pages/MainPage/TopNavBar/ca';
import { HeaderTitles as HeaderTitlesCa } from '../pages/MainPage/HeaderSection/ca';
import { SliderTitles as SliderTitlesCa } from '../pages/MainPage/SliderSection/ca';
import { ourStoryTexts as ourStoryTextsCa } from '../pages/MainPage/OurStoryBlock/ca';
import {StubProjectsPageTexts as StubProjectsPageTextsCa} from '../pages/StubProjectsPage/ca'
import {
  servicesTexts as SolarServicesTextsCa,
  servicesList as SolartServicesListCa,
} from '../pages/SolarEnergyPage/ServicesSection/ca';
import {
  ourValuesTitles as ourValuesTitlesCa,
  ourValuesList as ourValuesListCa,
} from '../pages/MainPage/OurValuesBlock/ca';
import {
  MenuItemsTitles as MenuItemsTitlesCa,
  MenuItemsDescriptions as MenuItemsDescriptionsCa,
} from '../pages/MainPage/MenuSection/ca';
import { AboutUsTitles as AboutUsTitlesCa } from '../pages/MainPage/AboutSection/ca';
import { ContactsTitles as ContactsTitlesCa } from '../pages/MainPage/ContactsSection/ca';
import {
  Construction as ConstructionCa,
  // Projects as ProjectsCa,
  ModularHouses as ModularHousesCa,
  Architect as ArchitectCa,
  Interior as InteriorCa,
  SwimmingPools as SwimmingPoolsCa,
  SmartHome as SmartHomeCa,
  Investments as InvestmentsCa,
  SolarEnergy as SolarEnergyCa,
  Inmobiliaria as InmobiliariaCa,
  Consulting as ConsultingCa,
  LandscapeDesign as LandscapeDesignCa,
} from '../pages/MainPage/FAQSection/ca';
import { contactsHeaderTitles as contactsHeaderTitlesCa } from '../pages/ContactsPage/ContactsHeaderSection/ca';
import {
  HeaderSection as HeaderSectionCa,
  description as SmartHomeDescriptionCa,
} from '../pages/SmartHomePage/HeaderSection/ca';

import { PartnersSection as PartnersSectionCa } from '../pages/SmartHomePage/PartnersSection/ca';
import { CardsSection as CardsSectionCa } from '../pages/SmartHomePage/CardsSection/ca';
import { HeaderSection as SwimmingPoolsHeaderSectionCa } from '../pages/SwimmingPoolsPage/HeaderSection/ca';
import { PoolsSection as SwimmingPoolsPoolsSectionCa } from '../pages/SwimmingPoolsPage/PoolsSection/ca';
import { ColorsSection as SwimmingPoolsColorsSectionCa } from '../pages/SwimmingPoolsPage/ColorsSection/ca';
import { PoolsAccessoriesSection as SwimmingPoolsAccessoriesSectionCa } from '../pages/SwimmingPoolsPage/AccessoriesSection/ca';
import { TechnologiesSection as SwimmingPoolsTechnologiesSectionCa } from '../pages/SwimmingPoolsPage/TechnologiesSection/ca';
import {
  AccessoriesSection as AccessoriesSectionCa,
  accessoriesIcons as AccessoriesIconsCa,
} from '../pages/SmartHomePage/AccessoriesSection/ca';
import { SmartHomeStepSection as SmartHomeStepsSectionCa } from '../pages/SmartHomePage/StepsBlockSection/ca';
import { MainSection as MainSectionCa } from '../pages/ServicesPage/ca';
import {
  investmentsNavItems as InvestmentsNavItemsCa,
  investmentsSecondDescription as InvestmentsSecondDescriptionCa,
} from '../pages/InvestmentsPage/HeaderSection/ca';
import {
  investmentsProcess as InvestmentsProcessCa,
  investmentsProcessTexts as investmentsProcessTextsCa,
} from '../pages/InvestmentsPage/InvestmentsProcess/ca';
import {
  philosophyTextsList as PhilosophyTextsListCa,
  philosophyTitle as PhilosophyTitleCa,
} from '../pages/InvestmentsPage/InvestmentsPhilosophySection/ca';
import {
  packagesTexts as packagesTextsCa,
  packagesBlocks as packagesBlocksCa,
} from '../pages/InvestmentsPage/InvestmentsPackagesSection/ca';
import {
  teamTexts as TeamTextsCa,
  teamBlocks as TeamBlocksCa,
} from '../pages/InvestmentsPage/InvestmentsTeamSection/ca';
import {
  realEstateNavItems as RealEstateNavItemsCa,
  realEstateSecondDescription as RealEstateSecondDescriptionCa,
} from '../pages/RealEstatePage/HeaderSection/ca';
import {
  realEstateProcessTexts as RealEstateProcessTextsCa,
  processList as ProcessListCa,
  servicesTexts as RealEstateServicesTextsCa,
  servicesList as RealEstateServicesListCa,
} from '../pages/RealEstatePage/ServicesSection/ca';

import { aboutListText as AboutListTextCa } from '../pages/RealEstatePage/AboutSection/ca';
import {
  navItems as ConsultingNavItemsCa,
  consultingProcessTexts as ConsultingProcessTextsCa,
  processList as ConsultingProcessListCa,
  description as ConsultingDescriptionCa,
} from '../pages/ConsultingPage/ca';
import {
  discoverList as DiscoverListCa,
  realEstateDiscoverTexts as RealEstateDiscoverTextsCa,
  aboutDiscoverList as AboutDiscoverListCa,
  aboutText as AboutTextCa,
} from '../pages/RealEstatePage/DiscoverSection/ca';
import {
  aboutUsNavItems as AboutUsNavItemsCa,
  aboutUsSecondDescription as AboutUsSecondDescriptionCa,
  aboutUsTitle as AboutUsTitleCa,
} from '../pages/AboutUsPage/HeaderSection/ca';
import {
  professionList as ProfessionListCa,
  pageTitle as PageTitleCa,
} from '../pages/AboutUsPage/OurTeamSection/ca';

import {
  pageTitle as OurStoryPageTitleCa,
  pageAfterTitle as OurStoryPageAfterTitleCa,
} from '../pages/AboutUsPage/OurStoryMobileBlock/ca';
import { projectsList as ProjectsListCa } from '../pages/ProjectsPage/ProjectsSection/ca';
import {
  headMenu as HeadMenuCa,
  typeHouseStyle as TypeHouseStyleCa,
  inputsTexts as InputsTextsCa,
  searchBtn as SearchBtnCa,
  removeAll as RemoveAllCa,
} from '../pages/ProjectsPage/FilterSection/ca';
import {
  headerSection as SolarHeaderSectionCa,
  description as SolarEnergyDescriptionCa,
} from '../pages/SolarEnergyPage/HeaderSection/ca';
import {
  solarEnergySetupsTexts as SolarEnergySetupsTextsCa,
  setupList as SetupListCa,
} from '../pages/SolarEnergyPage/SetupsSection/ca';
import {
  solarEnergyBenefitsTexts as SolarEnergyBenefitsTextsCa,
  benefitsList as BenefitsListCa,
} from '../pages/SolarEnergyPage/BenefitsSection/ca';
import { partnersSection as SolarPartnersSectionCa } from '../pages/SolarEnergyPage/PartnersSection/ca';
import {
  architectNavItems as ArchitectNavItemsCa,
  architectSecondDescription as ArchitectSecondDescriptionCa,
} from '../pages/ArchitectPage/HeaderSection/ca';
import {
  currentTexts as CurrentTextsCa,
  currentPortfolioTexts as CurrentPortfolioTextsCa,
  processList as ProcessListArchitectCa,
} from '../pages/ArchitectPage/ProcessSection/ca';
import {
  projectsName as ProjectsNameCa,
  portfolioText as PortfolioTextCa,
} from '../pages/ArchitectPage/PortfolioSection/ca';
import {
  aboutListLocation as AboutListLocationCa,
  infoText as InfoTextCa,
  mainText as MainTextCa,
  headerBtnText as HeaderBtnTextCa,
  titleAbout as TitleAboutCa,
  textsAbout as TextsAboutCa,
  textPhoto as TextPhotoCa,
  view as ViewBtnTextCa,
  housesDetailsMobile as HousesDetailsMobileCa,
  housesDetailsDesktop as HousesDetailsDesktopCa,
  photoTitle as PhotoTitleCa,
} from '../pages/InteriorCasePage/ca';
import {
  interiorNavItems as InteriorNavItemsCa,
  currentText as InteriorCurrentTextCa,
  currentPortfolioText as InteriorCurrentPortfolioTextCa,
  servicesList as InteriorServicesListCa,
  description as InteriorDescriptionCa,
} from '../pages/InteriorPage/ca';
import {
  navItems as ConstructionNavItemsCa,
  description as ConstructionDescriptionCa,
  processList as ConstructionProcessListCa,
  aboutTexts as ConstructionAboutTextsCa,
  preConstructionTitle as PreConstructionTitleCa,
  preConstructionText as PreConstructionTextCa,
  constructionStepBlockTitle as ConstructionStepBlockTitleCa,
  constructionStepBlockDescription as ConstructionStepBlockDescriptionCa,
  constructionStepSection as ConstructionStepSectionCa,
} from '../pages/ConstructionPage/ca';
import {
  navItems as LandscapeDesignNavItemsCa,
  description as LandscapeDesignDescriptionCa,
  currentTexts as LandscapeCurrentTextsCa,
  processList as LandscapeProcessListCa,
  currentTextsBenefits as CurrentTextsBenefitsCa,
  textsBenefits as TextsBenefitsCa,
} from '../pages/LandscapeDesignPage/ca';
import {
  currentTexts as BuildingCurrentTextsCa,
  textsBenefits as BuildingTextsBenefitsCa,
  benefitsTitle as BuildingBenefitsTitleCa,
  materialsList as BuildingMaterialsListCa,
} from '../pages/BuildingMaterialsPage/ca';
import {
  footerNavBar as footerNavBarCa,
  footerTitles as footerTitlesCa,
} from '../pages/MainPage/FooterSection/ca';
import { projectsItems as ProjectsItemsCa } from '../pages/ProjectsPage/HeaderSection/ca';
import { loginPage as LoginPageCa } from '../pages/LoginPage/ca';
import { FavoritesSection as FavoritesSectionCa } from '../pages/FavoritesPage/ca';
import {
  modularHousesNavItems as ModularHousesNavItemsCa,
  description as ModularHousesDescriptionCa,
} from '../pages/ModularHousesPage/HeaderSection/ca';
import {
  typeOfHouses as TypeOfHousesCa,
  modelTitle as ModelTitleCa,
  modelDescription as ModelDescriptionCa,
} from '../pages/ModularHousesPage/ModelSection/ca';
import {
  currentTexts as CurrentModularHousesTextsCa,
  options as OptionsCa,
} from '../pages/ModularHousesPage/OptionsSection/ca';
import {
  blockTitle as BlockTitleCa,
  blockDescription as BlockDescriptionCa,
  modularHousesStepSection as modularHousesStepSectionCa
} from '../pages/ModularHousesPage/StepsBlock/ca';

import {
  title as TopServicesTitleCa,
  allServices as AllServicesCa,
  description as ServicesDescriptionCa,
} from '../pages/MainPage/TopServicesSection/ca';

import {
  architectStepBlockTitle as ArchitectStepBlockTitleCa,
  architectStepBlockDescription as ArchitectStepBlockDescriptionCa,
  architectStepSection as ArchitectStepSectionCa,
} from '../pages/ArchitectPage/StepsSection/ca';
import { cookiesPopup as CookiesPopupCa } from '../components/CookiesPopup/en';
import {conditionsText as ConditionsTextCa} from '../pages/ConditionsPage/ca'
import { privacyText as PrivacyTextCa } from '../pages/PrivacyPage/ca'
import {
  LeadFormPage as LeadFormPageCa,
  ClientPartnerNavbarItems as ClientPartnerNavbarItemsCa,
  MainNavbarItems as MainNavbarItemsCa
} from '../pages/LeadFormPage/ca';
export const ca = {
  translation: {
    mainPage: {
      TopNavbarTitles: TopNavbarTitlesCa,
      navBarItemTitlesArray: navBarItemTitlesCaArray,
      mobileNavBarItemTitlesArray: mobileNavBarItemTitlesCaArray,
      headerTitles: HeaderTitlesCa,
      sliderTitles: SliderTitlesCa,
      MenuItemsTitles: MenuItemsTitlesCa,
      MenuItemsDescriptions: MenuItemsDescriptionsCa,
      AboutUsTitles: AboutUsTitlesCa,
      ourStoryTexts: ourStoryTextsCa,
      ourValuesList: ourValuesListCa,
      ourValuesTitles: ourValuesTitlesCa,
      ContactsTitles: ContactsTitlesCa,
      topServicesTitle: TopServicesTitleCa,
      allServices: AllServicesCa,
      servicesDescription: ServicesDescriptionCa,
      FAQ: {
        LandscapeDesign: LandscapeDesignCa,
        Construction: ConstructionCa,
        // Projects: ProjectsCa,
        ModularHouses: ModularHousesCa,
        Architect: ArchitectCa,
        Interior: InteriorCa,
        SwimmingPools: SwimmingPoolsCa,
        SmartHome: SmartHomeCa,
        Investments: InvestmentsCa,
        SolarEnergy: SolarEnergyCa,
        Inmobiliaria: InmobiliariaCa,
        Consulting: ConsultingCa,
      },
      footerNavBar: footerNavBarCa,
      footerTitles: footerTitlesCa,
    },
    contactsPage: {
      contactsHeaderTitles: contactsHeaderTitlesCa,
    },
    smartHome: {
      HeaderSection: HeaderSectionCa,
      PartnersSection: PartnersSectionCa,
      CardsSection: CardsSectionCa,
      AccessoriesSection: AccessoriesSectionCa,
      SmartHomeStepsSection: SmartHomeStepsSectionCa,
      AccessoriesIcons: AccessoriesIconsCa,
      SmartHomeDescription: SmartHomeDescriptionCa,
    },
    services: {
      MainSection: MainSectionCa,
    },
    investmentsPage: {
      investmentsNavItems: InvestmentsNavItemsCa,
      investmentsSecondDescription: InvestmentsSecondDescriptionCa,
      investmentsProcess: InvestmentsProcessCa,
      investmentsProcessTexts: investmentsProcessTextsCa,
      philosophyTextsList: PhilosophyTextsListCa,
      philosophyTitle: PhilosophyTitleCa,
      packagesTexts: packagesTextsCa,
      packagesBlocks: packagesBlocksCa,
      teamTexts: TeamTextsCa,
      teamBlocks: TeamBlocksCa,
    },
    realEstatePage: {
      realEstateNavItems: RealEstateNavItemsCa,
      realEstateSecondDescription: RealEstateSecondDescriptionCa,
      realEstateProcessTexts: RealEstateProcessTextsCa,
      processList: ProcessListCa,
      aboutListText: AboutListTextCa,
      discoverList: DiscoverListCa,
      realEstateDiscoverTexts: RealEstateDiscoverTextsCa,
      aboutDiscoverList: AboutDiscoverListCa,
      aboutText: AboutTextCa,
      servicesTexts: RealEstateServicesTextsCa,
      servicesList: RealEstateServicesListCa,
    },
    consultingPage: {
      consultingNavItems: ConsultingNavItemsCa,
      consultingProcessTexts: ConsultingProcessTextsCa,
      consultingProcessList: ConsultingProcessListCa,
      consultingDescription: ConsultingDescriptionCa,
    },
    aboutUsPage: {
      aboutUsNavItems: AboutUsNavItemsCa,
      aboutUsSecondDescription: AboutUsSecondDescriptionCa,
      aboutUsTitle: AboutUsTitleCa,
      professionList: ProfessionListCa,
      pageTitle: PageTitleCa,
      ourStoryPageTitle: OurStoryPageTitleCa,
      ourStoryPageAfterTitle: OurStoryPageAfterTitleCa,
    },
    projectsPage: {
      projectsItems: ProjectsItemsCa,
      headMenu: HeadMenuCa,
      typeHouseStyle: TypeHouseStyleCa,
      inputsTexts: InputsTextsCa,
      projectsList: ProjectsListCa,
      searchBtn: SearchBtnCa,
      removeAll: RemoveAllCa,
    },
    solarEnergyPage: {
      headerSection: SolarHeaderSectionCa,
      solarEnergyBenefitsTexts: SolarEnergyBenefitsTextsCa,
      solarEnergyDescription: SolarEnergyDescriptionCa,
      benefitsList: BenefitsListCa,
      partnersSection: SolarPartnersSectionCa,
      solarEnergySetupsTexts: SolarEnergySetupsTextsCa,
      setupList: SetupListCa,
      servicesTexts: SolarServicesTextsCa,
      servicesList: SolartServicesListCa,
    },
    architectPage: {
      architectNavItems: ArchitectNavItemsCa,
      architectSecondDescription: ArchitectSecondDescriptionCa,
      currentTexts: CurrentTextsCa,
      currentPortfolioTexts: CurrentPortfolioTextsCa,
      processList: ProcessListArchitectCa,
      projectsName: ProjectsNameCa,
      portfolioText: PortfolioTextCa,
      architectStepBlockTitle: ArchitectStepBlockTitleCa,
      architectStepBlockDescription: ArchitectStepBlockDescriptionCa,
      architectStepSection: ArchitectStepSectionCa,
    },
    interiorCasePage: {
      aboutListLocation: AboutListLocationCa,
      infoText: InfoTextCa,
      mainText: MainTextCa,
      headerBtnText: HeaderBtnTextCa,
      titleAbout: TitleAboutCa,
      textsAbout: TextsAboutCa,
      textPhoto: TextPhotoCa,
      viewBtnText: ViewBtnTextCa,
      housesDetailsMobile: HousesDetailsMobileCa,
      housesDetailsDesktop: HousesDetailsDesktopCa,
      photoTitle: PhotoTitleCa,
    },
    interiorPage: {
      interiorNavItems: InteriorNavItemsCa,
      interiorCurrentText: InteriorCurrentTextCa,
      interiorCurrentPortfolioText: InteriorCurrentPortfolioTextCa,
      interiorServicesList: InteriorServicesListCa,
      interiorDescription: InteriorDescriptionCa,
    },
    constructionPage: {
      constructionNavItems: ConstructionNavItemsCa,
      constructionDescription: ConstructionDescriptionCa,
      constructionProcessList: ConstructionProcessListCa,
      constructionAboutTexts: ConstructionAboutTextsCa,
      preConstructionTitle: PreConstructionTitleCa,
      preConstructionText: PreConstructionTextCa,
      constructionStepBlockTitle: ConstructionStepBlockTitleCa,
      constructionStepBlockDescription: ConstructionStepBlockDescriptionCa,
      constructionStepSection: ConstructionStepSectionCa,
    },

    landscapeDesignPage: {
      landscapeDesignNavItems: LandscapeDesignNavItemsCa,
      landscapeDesignDescription: LandscapeDesignDescriptionCa,
      landscapeCurrentTexts: LandscapeCurrentTextsCa,
      landscapeProcessList: LandscapeProcessListCa,
      currentTextsBenefits: CurrentTextsBenefitsCa,
      textsBenefits: TextsBenefitsCa,
    },
    buildingMaterialsPage: {
      buildingCurrentTexts: BuildingCurrentTextsCa,
      buildingTextsBenefits: BuildingTextsBenefitsCa,
      buildingBenefitsTitle: BuildingBenefitsTitleCa,
      buildingMaterialsList: BuildingMaterialsListCa,
    },
    swimmingPools: {
      HeaderSection: SwimmingPoolsHeaderSectionCa,
      PoolsSection: SwimmingPoolsPoolsSectionCa,
      ColorsSection: SwimmingPoolsColorsSectionCa,
      TechnologiesSection: SwimmingPoolsTechnologiesSectionCa,
      AccessoriesSection: SwimmingPoolsAccessoriesSectionCa,
    },

    loginPage: {
      loginPage: LoginPageCa,
    },

    favorites: {
      FavoritesSection: FavoritesSectionCa,
    },
    modularHousesPage: {
      modularHousesNavItems: ModularHousesNavItemsCa,
      modelTitle: ModelTitleCa,
      modelDescription: ModelDescriptionCa,
      typeOfHouses: TypeOfHousesCa,
      currentTexts: CurrentModularHousesTextsCa,
      options: OptionsCa,
      blockTitle: BlockTitleCa,
      blockDescription: BlockDescriptionCa,
      description: ModularHousesDescriptionCa,
      modularHousesStepSection: modularHousesStepSectionCa,
    },
    leadForm: {
      clientPartnerNavbarItems: ClientPartnerNavbarItemsCa,
      mainNavbarItems: MainNavbarItemsCa,
      leadFormPage: LeadFormPageCa,
    },
    cookiesPopup: {
      cookiesPopup: CookiesPopupCa,
    },
     stubProjectsPage: {
      stubProjectsPageTexts: StubProjectsPageTextsCa,
    },
     conditionsPage: {
      conditionsText: ConditionsTextCa,
    },
     privacyPage: {
      privacyText: PrivacyTextCa,
    }
  },
};
