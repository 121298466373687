export const cookiesPopup = {
  text: ` We use cookies to ensure that you get the best experience on our
         website.`,

  accept: 'Accept',

 
};


