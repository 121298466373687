import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import { TextField } from '../../../shared/components/TextFieldInput/TextFieldInput.styled';

export const LeftPartWrapper = styled.div`
  width: 100%;
`;

export const TopPartWrapper = styled.div`
  display: flex;
  margin-bottom: 1.62rem;
  border-top: 1px solid ${colorVariables.navBarBorderColor};
  border-bottom: 1px solid ${colorVariables.navBarBorderColor};
`;
export const NavBarWrapper = styled.div`
  padding: 1.25rem 5.9rem 1.25rem 1.25rem;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const MenuBlockWrapper = styled.div`
  width: 100%;
  padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;
export const MenuNameLinkWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.62rem;
  }
`;
export const MenuName = styled.p`
  font-weight: 500;
  font-size: 0.87rem;
  line-height: 1.06rem;
  cursor: pointer;

  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
`;

export const SocialBlockWrapper = styled.div`
  padding: 1.25rem;
`;
export const NetworkIconWrapper = styled.a`
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
`;

export const NetworkIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  transition: ${transitions.opacityTransitions};
`;

export const ContactsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 0.6rem;
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgb(30, 32, 33);
`;
export const ContactsName = styled.a`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
  text-align: center;
  &:first-child {
    margin-bottom: 0.625rem;
  }
`;

export const PrivacyBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 1rem 0.6rem;
  cursor: pointer;
`;

export const PrivacyText = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
  text-align: center;
  text-decoration-line: underline;
  &:first-child {
    margin-bottom: 0.625rem;
  }
`;

export const BottomPartWrapper = styled.div``;

export const FormWrapper = styled.div`
  display: flex;

  .form {
    width: 100%;
    display: flex;
  }
`;

export const FormTitle = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 100%;
  text-align: center;
  color: ${colorVariables.mainTextColor};
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.56rem;
`;

export const FormInput = styled.input`
  width: 100%;
  height: 3.25rem;
  outline: none;
  color: rgba(255, 255, 255, 0.3);
  background: ${colorVariables.mainBackgroundColor};
  border-width: 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
  padding-left: 1.25rem;
`;

export const SendIcon = styled.img`
  width 1,375rem;
  height: 1,375rem;
`;

export const SendBtn = styled.button`

 width 3.25rem;
  height: 3.25rem;
  padding: 0.93rem;
  background: rgba(255, 255, 255, 0.15);
   border-width: 1px 0px;
   border-style: solid;
   border-color: ${colorVariables.navBarBorderColor};
`;

export const ReservedBlock = styled.div`
  padding: 1.37rem 0rem 1.37rem 0rem;
`;

export const ReservedTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: rgba(255, 255, 255, 0.7);
`;

export const TextFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  & ${TextField} {
    height: 100%;
    border: 1px solid ${colorVariables.footerBorderColor};
    background: ${colorVariables.alternativeBackground};
  }
`;

export const ErrorMesage = styled.div`
  position: absolute;
  bottom: 0.25rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${colorVariables.errorColor};
  left: 1.25rem;
  width: 100%;
  height: 100%
  z-index: 20;
`;

export const PopupWrapper = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
`;

export const Popup = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid
    ${props =>
      props.isError ? colorVariables.errorColor : colorVariables.succesColor};
  width: 90%;
  height: 14.062rem;
  background: ${colorVariables.alternativeBackground};
  padding: 1rem;

  @media (min-width: ${Breakpoints.md}px) {
    width: 36.25rem;
    padding: 1.5rem 6.75rem;
  }

  & button {
    margin-top: 1.25rem;
    width: fit-content;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    background: rgba(255, 230, 98, 0.1);
    border: 1px solid ${colorVariables.yellowButtonBorder};
    color: ${colorVariables.alternativeTextColor};
  }
`;

export const PopupText = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 1.25rem;
`;
