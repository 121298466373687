import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const RealEstateServicesWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  padding-bottom: 4.375rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 2.5rem 0 5rem;
  }
`;

export const List = styled.ul`
  margin: 0 1.25rem;
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin: 0 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin: 0 6.25rem;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid ${colorVariables.navBarBorderColor};
  height: 3.75rem;

  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }
  @media (min-width: ${Breakpoints.md}px) {
    flex-basis: calc(50% - 0.625rem);
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${colorVariables.navBarBorderColor};
  width: 3.75rem;
  height: 3.75rem;
`;

export const Number = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const TextWrapper = styled.div`
  padding: 1.25rem 0;
  width: 100%;
  text-align: center;
`;

export const Text = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const IconWrapper = styled(NumberWrapper)`
  border-left: 1px solid ${colorVariables.navBarBorderColor};
  border-right: 0px;
`;

export const Icon = styled.img``;
