import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from '../locales/resources';
import { useMemo } from 'react';
const currentLanguage = localStorage.getItem('language');

const currentClientLanguage = navigator.language;
const currentClientLanguageFunc = () => {
  const languages = ['en', 'es', 'fr'];
  const isInclude = languages.includes(currentClientLanguage);
  if (isInclude) {
    return navigator.language;
  } else if (navigator.language === 'uk') {
    return 'ua';
  } else {
    return 'en';
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    lng: currentLanguage?.valueOf() || currentClientLanguageFunc(),
    fallbackLng: currentLanguage?.valueOf() || 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
