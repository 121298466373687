export const MainSection = {
  services: 'Servicios',
  сonstruction: 'Construcción',
  projects: 'Proyectos',
  interior: 'Interior',
  modularHouses: 'Casas modulares',
  architect: 'Arquitectura',
  consulting: 'Consultoría',
  solarEnergy: 'Energía solar',
  inmobiliaria: 'Inmobiliaria',
  investments: 'Inversiones',
  swimmingPools: 'Piscinas',
  smartHome: 'Casa inteligente',
  landscapeDesign: 'Construcción',
  buildingMaterials: 'Materiales de construcción',
};
