export const solarEnergySetupsTexts = {
  label: 'Energy solutions to meet your needs',
  description:
    'Smart home accessories are designed to make your home more convenient, efficient, and enjoyable. From smart thermostats and lighting systems to voice-activated assistants and security cameras, there are a variety of accessories available to help you automate and control different aspects of.',
};

export const setupList = {
  typeBasic: 'Basic',
  nameBasic: 'Basic setup',

  descriptionBasic:
    'Our basic setup includes solar panels, a battery, and an inverter to convert the energy collected by the panels into usable electricity. This setup is ideal for smaller households with lower energy consumption needs.',

  typeStandart: 'Standart',
  nameStandard: 'Standard setup',

  descriptionStandard:
    'Our standard setup includes additional panels and batteries for increased energy storage and production. This setup is ideal for medium-sized households with average energy consumption needs.',

  typePremium: 'Premium ',
  namePremium: 'Premium setup',

  descriptionPremium:
    'Our premium setup includes top-notch panels, batteries, and inverters with advanced monitoring and control systems. Ideal for larger households and commercial properties with high energy consumption.',
};
