export const interiorNavItems = {
  services: 'Servicios',
  portfolio: 'Portafolio',
};
export const description =
  'El diseño de interiores no es sólo creatividad, sino toda una gama de servicios que convierten los metros cuadrados ordinarios en confort y armonía. Un interior de alta calidad con dibujos bien pensados, componentes técnicos, colores y muebles ecológicos ayudará a crear un espacio cómodo y funcional que le dará alegría a usted y a sus seres queridos durante muchos años. Es una inversión en su bienestar y comodidad que puede aumentar su estado de ánimo y eficiencia, reducir el estrés y mejorar su calidad de vida. Y nosotros conocemos la fórmula perfecta.';
export const currentText = {
  label: 'Nuestros servicios de diseño de interiores',
  description:
    'El desarrollo de un concepto de interior no es sólo un proceso creativo, sino también polifacético que incluye muchas etapas. Estamos dispuestos a convertirnos en un guía de confianza para usted y acompañarle en cada fase de la realización: desde una bella imagen en su cabeza hasta la plasmación de sus sueños más salvajes en la vida real. Las etapas incluyen:',
};

export const currentPortfolioText = {
  description:
    'Eche un vistazo a algunos de nuestros proyectos anteriores para ver la gama de estilos y soluciones de diseño que hemos creado para nuestros clientes. Tanto si busca un estilo moderno, tradicional o algo intermedio, podemos ayudarle a conseguir el aspecto y la sensación que desea.',
};

export const servicesList = {
  planningText: 'Desarrollo del concepto de diseño',
  colorText: 'Creación de decisiones de planificación y bocetos',
  furnitureText: 'Selección de materiales y elementos de acabado',
  designText: 'Desarrollo de circuitos eléctricos y de iluminación',
  windowText: 'Creación de la visualización en 3D',
  artText: 'Montaje completo con mobiliario, accesorios y decoración',
};
