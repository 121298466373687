export const investmentsNavItems = {
  philosophy: 'Philosophy',

  process: 'Process',

  packages: 'Packages',

  team: 'Team',
};

export const investmentsSecondDescription =
  'Spain is a place where Mediterranean culture is combined with rich history and unique architecture, attracting tourists and investors from all over the world. This is not surprising, because the Spanish real estate market is one of the most promising and profitable in Europe. Spain offers a wide choice - from apartments in the city center to luxury villas on the coast. Due to moderate housing prices, the purchase of real estate is available to a wide range of investors from different countries. Spanish cities are famous for their infrastructure and quality of life, which attracts people who are ready to buy property for permanent residence or rental. Each investor who contacts us can receive individual offers, a stable financial inflow, legal services and risk assessment.';
