import React, { FC } from 'react';
import { ContactsFormSection } from '../../components/ContactsFormSection/ContactsFormSection';
import { ContactsHeaderSection } from '../../components/ContactsHeaderSection';
import { ContactsMapSection } from '../../components/ContactsMapSection';
import {
  ContactsPageWrapper,
  FormMapBlocksWrapper,
} from './ContactsPage.styled';

export const ContactsPage: FC = () => {
  return (
    <ContactsPageWrapper>
      <ContactsHeaderSection />
      <FormMapBlocksWrapper>
        <ContactsFormSection />
        <ContactsMapSection />
      </FormMapBlocksWrapper>
    </ContactsPageWrapper>
  );
};
