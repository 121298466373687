import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../theme/Theme.constants';
import { Link } from 'react-router-dom';
export const TopServicesSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  @media (min-width: ${Breakpoints.md}px) {
    padding: 6.25rem 2.5rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    padding: 6.25rem 6.25rem 7.5rem;
  }
`;
export const Wrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
  }
`;
export const TopBlock = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    background: ${colorVariables.alternativeBackground};
    border-width: 1px 1px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
    width: 100%;
  }
`;

export const MainIconWrapper = styled.div`
  padding: 3.437rem 3.75rem;
  border-right: 1px solid ${colorVariables.navBarBorderColor};
  min-width: 25.05%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MainIconImg = styled.img`
  @media (min-width: ${Breakpoints.xlg}px) {
    width: 6.875rem;
    height: 6.25rem;
  }
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${Breakpoints.md - 1}px) {
    padding: 1.875rem;
    background: ${colorVariables.alternativeBackground};
    border-width: 1px 0px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.437rem 0px;
    width: 100%;
  }
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  color: ${colorVariables.secondaryIconColor};
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  .translated & {
    font-size: 2rem;
  }
  @media (min-width: ${Breakpoints.md}px) {
    font-size: 3.125rem;
    line-height: 61px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 85px;
    line-height: 122px;

    .translated & {
      font-size: 65px;
    }
  }
  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 6.25rem;

    .translated & {
      font-size: 4.25rem;
    }
  }
`;
export const FirstTitlePart = styled.span`
  color: ${colorVariables.mainTextColor};
  margin-right: 15px;
`;

export const СenterBlock = styled.div`
  border-width: 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};

  @media (min-width: ${Breakpoints.md}px) {
    border-width: 1px 0px 0px 1px;
  }
`;

export const List = styled.ul`
  display: flex;

  @media (max-width: ${Breakpoints.md - 1}px) {
    flex-wrap: wrap;
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: 100%;
  }
`;

export const Item = styled.li<{ bgImage: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.25rem;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};

  @media (max-width: ${Breakpoints.md - 1}px) {
    flex-basis: calc(50%);
    &:first-child {
      border-width: 0px 0px 1px;
    }
  }

  &:nth-child(2) {
    border-width: 0px 1px 1px;
  }
  &:nth-child(3) {
    border-width: 0px;
  }
  &:last-child {
    border-width: 0px 1px 0px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.bgImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.16;
  }

  @media (min-width: ${Breakpoints.md}px) {
    height: 10.625rem;
    width: 100%;

    &:first-child {
      border-width: 0px 0px 0px;
    }
  }

  @media (min-width: ${Breakpoints.lg}px) {
    height: 18.75rem;
  }
`;

export const ElWrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  height: 100%;
  width: 100%;
  opacity: 1;
`;
export const IconWrapper = styled.div`
  svg {
    height: 2.5rem;
    width: 2.5rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    svg {
      height: 6.25rem;
      width: 6.25rem;
      opacity: 0.9;
    }
  }
`;
export const Label = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${colorVariables.mainTextColor};
  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const BottomBlock = styled.div`
  border-width: 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};

  @media (min-width: ${Breakpoints.md}px) {
    border-width: 1px;
    display: flex;
  }
`;

export const TextWrapper = styled.div`
  padding: 1rem;
  border-width: 0px 0px 1px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};

  @media (min-width: ${Breakpoints.md}px) {
    border-width: 0px;
    padding: 1.25rem 1rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    padding: 3.125rem;
  }

  @media (min-width: ${Breakpoints.xxl}px) {
    padding: 3.75rem;
  }
`;
export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25rem;

  opacity: 0.8;

  @media (min-width: ${Breakpoints.md}px) {
    line-height: 19px;
    opacity: 1;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 14px;
    line-height: 23px;
  }

  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 16px;
    line-height: 1.875rem;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.562rem 0px;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 4.687rem 2.5rem;
    border-left: 1px solid ${colorVariables.navBarBorderColor};
    min-width: 25%;
  }
`;

export const LinkText = styled(Link)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;

  text-align: center;
  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.alternativeTextColor};

  &:hover {
    opacity: 0.8;
  }
  @media (min-width: ${Breakpoints.md}px) {
    font-size: 1rem;
    line-height: 24px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    font-size: 1.25rem;
    line-height: 36px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 24px;
    line-height: 36px;
  }
`;
