export const aboutUsNavItems = {
  values: 'Valeurs',
  story: 'Histoire',
  team: 'Équipe',
};

export const aboutUsSecondDescription =
  "Notre entreprise est spécialisée dans la fourniture de solutions de construction et d'immobilier de pointe qui privilégient le bonheur et la qualité de la clientèle. Notre gamme complète de services englobe tout, de la construction et la conception de logements, piscines, énergie, immobilier, investissements et consultation. Grâce à notre équipe d'experts, nous offrons constamment des résultats exceptionnels qui répondent et dépassent les attentes de nos clients tout en respectant leurs budgets et leurs délais. En priorisant la confiance et en construisant des relations durables avec nos clients, nous pouvons constamment offrir des résultats exceptionnels et devenir un partenaire de confiance dans leur réussite.";

export const aboutUsTitle = {
about: 'À propos',
us: 'de nous'
};
  
