import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const InteriorPageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const ServicesSectionWrapper = styled.div`
  padding-bottom: 2.5rem;
  background-color: ${colorVariables.alternativeBackground};
  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem 2.5rem 6.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 7.5rem 0px 7.5rem;
  }
`;

export const PortfolioSectionWrapper = styled.div`
  background-color: ${colorVariables.alternativeBackground};
`;

export const PortfolioHeadWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 2.5rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0;
  }
`;
