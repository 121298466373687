export const ClientPartnerNavbarItems = {
  client: 'Client',
  partner: 'Partenaire',
};

export const MainNavbarItems = {
  favourite: 'Préféré',
  questions: 'Questions',
  information: 'Informations',
  contacted: 'Contacté',
};

export const LeadFormPage = {
  // favourite block
  favouriteTitle: 'Vos services préférés',
  favouriteDescription: 'Nous nous réjouissons de travailler avec vous !',
  fullCycleButton: 'Un projet à cycle complet',
  clearList: 'Liste claire des services',
  addService: 'Ajouter un service',
  hide: 'Cacher',
  otherServices: 'Autres services',
  comentsOrQuestions: 'Commentaires ou questions',
  backButton: 'Retour',
  nextButton: 'Suivant',
  sendButton: 'Envoyer',
  // question block
  questionTitle: 'Répondre à quelques questions',
  questionDescription:
    'Cela vous aidera à trouver la meilleure solution pour votre projet.',
  projectStatus: `Quel est l'état d'avancement de votre projet à l'heure actuelle ?`,
  otherButton: 'Autres',
  commentsQuestionsPlaceholder: 'Commentaires ou questions',
  uploadFile: 'Télécharger le fichier',
  // information block
  informationTitle: 'Écrire les coordonnées de la personne à contacter',
  informationDescription: `Cela nous permet de vous contacter d'une manière qui vous convient.`,
  name: 'Nom*',
  phoneNumber: 'Numéro de téléphone*',
  email: 'Courriel*',
  organization: 'Organisation',
  position: 'Poste de travail',
  checkboxText: `En cochant cette case, je confirme que j'ai lu et que j'accepte le traitement de mes données à caractère personnel conformément à la loi sur la protection des données.`,
  privacyPolicy: 'politique de confidentialité.',
  mainNameError: '* Veuillez saisir votre nom',
  secondaryNameError: '* Le nom ne peut pas commencer par un espace blanc',
  mainPhoneError: '* Veuillez saisir votre numéro',
  secondaryPhoneError: '* Numéro de téléphone invalide',
  mainEmailError: `* Veuillez saisir l'adresse électronique correcte`,
  secondaryEmailError: `* Format d'email non valide`,
  thirdEmailError: '* Utiliser uniquement des caractères anglais',
  // contacted block
  contactedTitle: 'Comment préférez-vous être contacté ?',
  contactedDescription:
    'Choisissez le moyen le plus pratique pour vous contacter',
  whatsapp: 'Whatsapp',
  edit: 'Editer',
  phoneCall: 'Appel téléphonique',
  emailType: 'Courriel',
  bookMeeting: 'Réserver une réunion',
  schedule: 'Planifier un appel',
  errorMessage: `Ooops quelque chose s'est mal passé...`,
  accessMessage: `Nous vous remercions de votre demande. Nous vous répondrons dès que possible.`,
  returnButton: 'Retour à Moderna Casa',
};
