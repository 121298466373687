import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PoolCard,
  PoolImageBlock,
  PoolInfo,
  PoolInfoBlock,
  PoolInfoItem,
  PoolInfoItemsBlock,
  PoolTitle,
  PoolsCards,
  PoolsCardsWrapper,
  PoolsDescription,
  PoolsInfoBlock,
  PoolsInfoBlockWrapper,
  PoolsNavigation,
  PoolsSectionWrapper,
  PoolsTitle,
} from './PoolsSection.styled';
import { RadioSwitcher } from '../../../shared/components/RadioSwitcher/RadiioSwitcher';

import { CurrentSwimmingPoolsProps } from '../SwimmingPoolsPage.types';
import { PaginationBlock } from '../../../components/PaginationBlock';

import java from '../../../shared/assets/images/swiming-pools/pools/3D-Java-101-02.png';
import xLTrainer from '../../../shared/assets/images/swiming-pools/pools/3D-XL-trainer-110-02.png';
import fun from '../../../shared/assets/images/swiming-pools/pools/3D-fun-100-02.png';
import xLJava from '../../../shared/assets/images/swiming-pools/pools/3D-XL-Java-114-02.png';
import xLTrainerFB from '../../../shared/assets/images/swiming-pools/pools/3D-XL-trainer-110-02.png';
import riverina from '../../../shared/assets/images/swiming-pools/pools/3D-Riverina-106-02.png';
import yachtpool from '../../../shared/assets/images/swiming-pools/pools/3D-yachtpool-02.png';
import fastLane from '../../../shared/assets/images/swiming-pools/pools/3D-fast-lane-02.png';

import aqua from '../../../shared/assets/images/swiming-pools/pools/small/3D-AQUA-53-02.png';
import briliant from '../../../shared/assets/images/swiming-pools/pools/small/3D-Briliant-66-02.png';
import briliantSecond from '../../../shared/assets/images/swiming-pools/pools/small/3D-Briliant-74-02.png';
import funSecond from '../../../shared/assets/images/swiming-pools/pools/small/3D-fun-80-02.png';
import riverinaSecond from '../../../shared/assets/images/swiming-pools/pools/small/3D-Riverina-67-02.png';
import x_trainer from '../../../shared/assets/images/swiming-pools/pools/small/3D-X_trainer-72_02.png';
import x_trainerSecond from '../../../shared/assets/images/swiming-pools/pools/small/3D_X_trainer_45-02.png';
import classic from '../../../shared/assets/images/swiming-pools/pools/small/Classic-63.png';
import i18next from 'i18next';

export const PoolsSection: FC = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const initialNavItem =
    `${t('swimmingPools.PoolsSection.premium')} l 8.8 - 11.5m` || '';
  const [currentNavItem, setCurrentNavItem] = useState<string>(initialNavItem);

  const SwimmingPoolsData = {
    [`${t('swimmingPools.PoolsSection.premium')} l 8.8 - 11.5m`]: [
      {
        id: 1,
        title: 'JAVA 101',
        image: java,
        length: '10,1 ',
        width: '3,82 ',
        depth: '1,51 ',
        capacity: '49 ',
      },
      {
        id: 2,
        title: 'RIVERINA 106',
        image: riverina,
        length: '10,6 ',
        width: '3,75 ',
        depth: '1,06 - 1,90 ',
        capacity: '45 ',
      },
      {
        id: 3,
        title: 'XL-JAVA 114',
        image: xLJava,
        length: '11,4',
        width: '4,00',
        depth: '1,10 - 1,69',
        capacity: '52',
      },
      {
        id: 4,
        title: 'FUN 100',
        image: fun,
        length: '10,00 ',
        width: '4,00 ',
        depth: '1,50 ',
        capacity: '45 ',
      },
      {
        id: 5,
        title: 'XL-TRAINER 110',
        image: xLTrainer,
        length: '11,00 ',
        width: '4,00 ',
        depth: '1,12 - 1,82 ',
        capacity: '52 ',
      },
      {
        id: 6,
        title: 'XL-TRAINER 110FB',
        image: xLTrainerFB,
        length: '11,00 ',
        width: '4,00 ',
        depth: '1,12 - 1,82 ',
        capacity: '52 ',
      },
    ],
    [`${t('swimmingPools.PoolsSection.selection')} l > 11.5m`]: [
      {
        id: 1,
        title: 'YACHT POOL',
        image: yachtpool,
        length: '11,50 ',
        width: '4,00 ',
        depth: '1,50 ',
        capacity: '42 ',
      },
      {
        id: 2,
        title: 'XXL-TRAINER 133FB',
        image: xLTrainerFB,
        length: '13,30 ',
        width: '4.50 ',
        depth: '1.52 ',
        capacity: '89 ',
      },
      {
        id: 3,
        title: 'FAST LANE',
        image: fastLane,
        length: '12.20 ',
        width: '2,90 ',
        depth: '1,50 ',
        capacity: '43 ',
      },
    ],
    [`${t('swimmingPools.PoolsSection.small')} l < 7.5m`]: [
      {
        id: 1,
        title: 'AQUA NOVA 53',
        image: aqua,
        length: '5,30 ',
        width: '3,20 ',
        depth: '1,51 ',
        capacity: '20',
      },
      {
        id: 2,
        title: 'BABY POOL',
        image: xLTrainerFB,
        length: '6,52 ',
        width: '2,40 ',
        depth: '0,88 ',
        capacity: '12',
      },
      {
        id: 3,
        title: 'RIVERINA 67',
        image: riverinaSecond,
        length: '6,72 ',
        width: '3,44 ',
        depth: '1,11 - 1.57 ',
        capacity: '24',
      },
      {
        id: 4,
        title: 'FUN 74S',
        image: funSecond,
        length: '7,45 ',
        width: '3,70 ',
        depth: '1,50 ',
        capacity: '33',
      },
      {
        id: 5,
        title: 'CLASSIC',
        image: classic,
        length: '6,20 ',
        width: '3,20 ',
        depth: '1,50 ',
        capacity: '21',
      },

      {
        id: 6,
        title: 'BRILIANT 66',
        image: briliant,
        length: '6,60 ',
        width: '3,65 ',
        depth: '1,40 ',
        capacity: '27',
      },

      {
        id: 7,
        title: 'BRILIANT 74',
        image: briliantSecond,
        length: '7,60 ',
        width: '3,65 ',
        depth: '1,40 ',
        capacity: '32',
      },
      {
        id: 8,
        title: 'X-TRAINER 45',
        image: x_trainer,
        length: '4,50 ',
        width: '3,00 ',
        depth: '1,24 - 1,49 ',
        capacity: '15',
      },
      {
        id: 9,
        title: 'X-TRAINER 45',
        image: x_trainerSecond,
        length: '7,20 ',
        width: '3,30 ',
        depth: '1,50',
        capacity: '23',
      },
    ],
  };

  const navigationItems = [
    {
      id: 1,
      title: `${t('swimmingPools.PoolsSection.premium')} l 8.8 - 11.5m`,
    },
    {
      id: 2,
      title: `${t('swimmingPools.PoolsSection.selection')} l > 11.5m`,
    },
    {
      id: 3,
      title: `${t('swimmingPools.PoolsSection.small')} l < 7.5m`,
    },
  ];
  useEffect(() => {
    i18next.on('languageChanged', lng => {
      setCurrentNavItem(
        `${t('swimmingPools.PoolsSection.premium')} l 8.8 - 11.5m` || '',
      );
    });
  }, [t]);

  return (
    <PoolsSectionWrapper id="pools-section">
      <PoolsInfoBlockWrapper>
        <PoolsInfoBlock>
          <PoolsTitle>{t('swimmingPools.PoolsSection.title')}</PoolsTitle>
          <PoolsDescription>
            {t('swimmingPools.PoolsSection.description')}
          </PoolsDescription>
        </PoolsInfoBlock>
      </PoolsInfoBlockWrapper>
      <PoolsCardsWrapper>
        <PoolsNavigation>
          <RadioSwitcher
            navItems={navigationItems}
            currentNavItem={currentNavItem}
            setCurrentNavItem={setCurrentNavItem}
          />
        </PoolsNavigation>
        <PoolsCards>
          {SwimmingPoolsData[currentNavItem].map(
            (currentPool: CurrentSwimmingPoolsProps) => (
              <PoolCard key={currentPool.id}>
                <PoolImageBlock>
                  <img src={currentPool.image} alt="pool" loading="lazy" />
                </PoolImageBlock>
                <PoolInfoBlock>
                  <PoolTitle>{currentPool.title}</PoolTitle>
                  <PoolInfo>
                    <PoolInfoItemsBlock>
                      <PoolInfoItem>
                        {`${t('swimmingPools.PoolsSection.length')}: ${
                          currentPool.length
                        }m`}
                      </PoolInfoItem>
                      <PoolInfoItem>
                        {`${t('swimmingPools.PoolsSection.depth')}: ${
                          currentPool.depth
                        }m`}
                      </PoolInfoItem>
                    </PoolInfoItemsBlock>
                    <PoolInfoItemsBlock>
                      <PoolInfoItem>
                        {`${t('swimmingPools.PoolsSection.width')}: ${
                          currentPool.width
                        }m`}
                      </PoolInfoItem>
                      <PoolInfoItem>
                        {`${t('swimmingPools.PoolsSection.capacity')}: ${
                          currentPool.capacity
                        }m³`}
                      </PoolInfoItem>
                    </PoolInfoItemsBlock>
                  </PoolInfo>
                </PoolInfoBlock>
              </PoolCard>
            ),
          )}
        </PoolsCards>
        {/* <PaginationBlock
          setCurrentPage={setCurrentPage}  ///// FOR FUTURE FEATURES /////
          currentPage={currentPage}
        /> */}
      </PoolsCardsWrapper>
    </PoolsSectionWrapper>
  );
};
