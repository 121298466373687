import styled from 'styled-components';
import { Breakpoints, colorVariables,transitions } from '../../../theme/Theme.constants';
import { Form } from 'formik';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 34px;
    line-height: 41px;
  }
`;

export const AfterTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${colorVariables.mainTextColorWitOpacity};
  margin-bottom: 3rem;
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    align-items: flex-end;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    width: 30rem;
  }
`;

export const ErrorMessageWrapper = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  .input-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0px, -50%);

    & path {
      fill: white;
      fill-opacity: 0.2;
      transition: 0.3s all ease;
    }
  }

  &:focus-within .input-icon {
    & path {
      fill: ${colorVariables.alternativeTextColor};
      fill-opacity: 1;
    }
  }
`;

export const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem 0 0;
  margin-bottom: 7rem;

  & .MuiButtonBase-root {
    min-width: 50px !important;
    max-width: 50px !important;
  }
`;

export const CheckboxText = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.4);
  & a {
    color: ${colorVariables.mainTextColor};
    text-decoration: underline;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: rgba(255, 230, 98, 0.1);
  border: 1px solid ${colorVariables.yellowButtonBorder};
  padding: 0.625rem 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  text-align: center;
  cursor: pointer;
  color: ${colorVariables.alternativeTextColor};

  @media (min-width: ${Breakpoints.md}px) {
    width: 8.125rem;
  }
`;

export const ErrorMesage = styled.div`
  position: absolute;
  bottom: -1rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${colorVariables.errorColor};
  left: 0%;
  width: 100%;
`;

export const PrivacyLink = styled.span`
  text-decoration: underline;
  color: ${colorVariables.mainTextColor};
  cursor: pointer;
  transition: ${transitions.opacityTransitions};
  &:hover{
    opacity: 0.8;
  }
`;