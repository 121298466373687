export const RoutePaths = {
  mainPage: '/',
  smartHome: '/smart-home',
  contactsPage: '/contacts',
  services: '/services',
  investmentsPage: '/investments',
  realEstatePage: '/real-estate',
  consultingPage: '/consulting',
  aboutUsPage: '/about-us',
  projectsPage: '/projects',
  solarEnergyPage: '/solar-energy',
  preBuildHousesPage: '/pre-build-houses',
  architectPage: '/architect',
  interiorCasePage: '/interior-case',
  interiorPage: '/interior',
  swimmingPools: '/swimming-pools',
  constructionPage: '/construction',
  landscapeDesignPage: '/landscape-design',
  buildingMaterialsPage: '/building-materials',
  loginPage: '/login',
  favourites: '/favorites',
  modularHousesPage: '/modular-houses',
  leadForm: 'lead-form',
  privacyPolicyPage: '/privacy-policy',
  conditionsPage: '/conditions',
};
