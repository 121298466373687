import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';

export const AboutSectionWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    border-width: 0px 1px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
  }
`;

export const TopWrapper = styled.div`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    border-width: 1px 0px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    flex-direction: column;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }
`;

export const TitleWrapper = styled.div`
  padding: 1.25rem 2.812rem;

  @media (max-width: ${Breakpoints.md - 1}px) {
    border-width: 1px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    align-items: center;
    width: 100%;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 2.5rem 3.125rem;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;

  color: ${colorVariables.mainTextColor};

  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 2.5rem;
    line-height: 49px;
    text-align: left;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 3.75rem;
    line-height: 73px;
  }
`;

export const Img = styled.img`
  display: block;
  width: 100%;
  height: 200px;

  @media (min-width: ${Breakpoints.xlg}px) {
    height: 100%;
    width: 29.375rem;
  }
`;

export const TextWrapper = styled.div`
  padding: 1.25rem;
  border-width: 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};

  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem 1.562rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 2.812rem 3.75rem;
  }
`;

export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.562rem;

  color: rgba(255, 255, 255, 0.8);
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media (max-width: ${Breakpoints.md}px) {
    text-align: center;
  }
  @media (min-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1.875rem;
    }
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 18px;
    line-height: 32px;
  }
`;
