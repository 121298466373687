export const typeOfHouses = {
  accessible: 'Accessible',
  bespokeDesign: 'Disseny a mida',
  whitehouse: 'Casa blanca',
  longhouse: 'casa comunal',
  steading: 'Establint',
};

export const modelTitle = ' Cases Modulars';
export const modelDescription =
  `Esculli la seva piscina de ceràmica Compass entre 24 models, que s'adapta, gràcies al seu disseny, tant a la residència moderna com a la casa clàssica. Són aptes per al jardí, per a la terrassa al costat de la casa o en interior com a piscina coberta.`;
