export const realEstateProcessTexts = {
  label: 'services',
  description:
    "Notre objectif est de vous aider à trouver la maison de vos rêves, que vous cherchiez à acheter ou à louer. Nous proposons un large éventail de biens immobiliers dans des lieux et des styles différents, de sorte que vous êtes sûr de trouver quelque chose qui corresponde à vos besoins et à votre budget. Jetez un coup d'œil à nos propriétés ci-dessous ou utilisez la barre de recherche pour trouver un type de propriété ou un emplacement spécifique. Si vous avez besoin d'aide ou si vous avez des questions, n'hésitez pas à nous contacter.",
};

export const processList = {
  labelFirst: 'Gestion immobilière',
  labelSecond: 'Évaluation des biens',
  labelThird: 'Marketing et publicité',
  labelFourth: 'Achat et vente',
  labelFifth: 'Location et leasing',
  labelSixth: 'Assistance juridique',
};


export const servicesTexts = {
    label: 'Nos services immobiliers',
    description:
      `Nous proposons une large gamme de services d'aménagement intérieur pour vous aider à réaliser l'espace de vos rêves. Du concept initial et de l'aménagement de l'espace à la sélection des couleurs, en passant par la recherche de mobilier et l'accessoirisation, notre équipe peut prendre en charge tous les aspects de votre projet. Nos services comprennent`,
  };
export const servicesList = {
  textFirst: 'Définir vos besoins',

  textSecond: "Recherche de l'emplacement",

  textThird: "Évaluer l'adéquation du terrain",

  textFourth: 'Vérifier les aspects juridiques et réglementaires',

  textFifth: 'Prise en compte des infrastructures et des services publics',

  textSixth: 'Évaluer le potentiel futur et la valeur de revente',
};
