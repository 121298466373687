import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FooterSectionWrapper } from './FooterSection.styled';
import { Breakpoints } from '../../theme/Theme.constants';
import instagram from '../../shared/assets/icons/contacts/instagram.svg';
import pinterest from '../../shared/assets/icons/contacts/pinterest.svg';
import whatsUp from '../../shared/assets/icons/contacts/whats-up.svg';
import youtube from '../../shared/assets/icons/contacts/youtube.svg';
import send from '../../shared/assets/icons/send-icon.svg';
import { FooterMobileBlock } from './MobileBlock';
import { FooterDesktopBlock } from './DesktopBlock';
import { FooterTabletBlock } from './TabletBlock';

export const FooterSection: FC = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px) and (max-width: ${
      Breakpoints.xxl - 1
    }px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });

  return (
    <FooterSectionWrapper>
      {isMobile && (
        <FooterMobileBlock
          instagram={instagram}
          pinterest={pinterest}
          whatsUp={whatsUp}
          youtube={youtube}
          send={send}
        />
      )}

      {isTablet && (
        <FooterTabletBlock
          instagram={instagram}
          pinterest={pinterest}
          whatsUp={whatsUp}
          youtube={youtube}
          send={send}
        />
      )}

      {isDesktop && (
        <FooterDesktopBlock
          instagram={instagram}
          pinterest={pinterest}
          whatsUp={whatsUp}
          youtube={youtube}
          send={send}
        />
      )}
    </FooterSectionWrapper>
  );
};
