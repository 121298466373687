import React, { FC, memo } from 'react';
import { TextArea } from './TextAreaFieldInput.styled';
import { FieldProps } from 'formik';

interface TextAreaFieldInputProps {
  label?: string;
  disabled?: boolean;
  addClassname?: string;
  onBlur?: () => void;
  required?: boolean;
  placeholder?: string;
}

export const TextAreaFieldInput: FC<TextAreaFieldInputProps & FieldProps> =
  memo(({ disabled, onBlur, field, required, label }) => {
    return (
      <TextArea
        {...field}
        value={field.value || ''}
        required={required}
        onBlur={event => {
          field?.onBlur(event);
          onBlur?.();
        }}
        placeholder={label}
        disabled={disabled}
      />
    );
  });
