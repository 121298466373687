export const ClientPartnerNavbarItems = {
  client: 'Cliente',
  partner: 'Socio',
};

export const MainNavbarItems = {
  favourite: 'Favorito',
  questions: 'Preguntas',
  information: 'Información',
  contacted: 'Contáctenos',
};

export const LeadFormPage = {
  // favourite block
  favouriteTitle: 'Sus servicios favoritos',
  favouriteDescription: '¡Esperamos con interés trabajar con usted!',
  fullCycleButton: 'Un proyecto de ciclo completo',
  clearList: 'Borrar lista de servicios',
  addService: 'Agregar un servicio',
  hide: 'Esconder',
  otherServices: 'Otros servicios',
  comentsOrQuestions: 'Comentarios o preguntas',
  backButton: 'Atrás',
  nextButton: 'Siguiente',
  sendButton: 'Enviar',
  // question block
  questionTitle: 'Responda algunas preguntas',
  questionDescription:
    'Esto le ayudará a encontrar la mejor solución para su proyecto.',
  projectStatus: '¿Cuál es el estado de su proyecto en este momento?',
  otherButton: 'Otro',
  commentsQuestionsPlaceholder: 'Comentarios o preguntas',
  uploadFile: 'Cargar archivo',
  // information block
  informationTitle: 'Escriba su información de contacto',
  informationDescription:
    'Esto es para que podamos comunicarnos con usted de una manera que le sea conveniente.',
  name: 'Nombre*',
  phoneNumber: 'Número de teléfono*',
  email: 'Correo electrónico*',
  organization: 'Organización',
  position: 'Puesto de trabajo',
  checkboxText:
    'Al marcar esta casilla, confirmo que he leído y acepto el procesamiento de mis datos personales de acuerdo con la política de privacidad',
  privacyPolicy: 'política de privacidad.',
  mainNameError: '* Por favor, introduzca su nombre',
  secondaryNameError: '* El nombre no puede empezar con espacios en blanco',
  mainPhoneError: '* Por favor, introduzca su número',
  secondaryPhoneError: '* Número de teléfono no válido',
  mainEmailError: '* Introduzca la dirección de correo electrónico correcta',
  secondaryEmailError: '* Formato de correo electrónico no válido',
  thirdEmailError: '* Utilice sólo caracteres ingleses',
  // contacted block
  contactedTitle: '¿Cómo prefiere que lo contacten?',
  contactedDescription:
    'Elija la forma más conveniente para que nos comuniquemos con usted',
  whatsapp: 'Whatsapp',
  edit: 'Editar',
  phoneCall: 'Llamada telefónica',
  emailType: 'Correo electrónico',
  bookMeeting: 'Reserve una reunión',
  schedule: 'Programar una llamada',
  errorMessage: 'Ooops algo salió mal...',
  accessMessage: `Gracias por su solicitud. Nos pondremos en contacto con usted lo antes posible.`,
  returnButton: 'Volver a Moderna Casa',
};