export const footerNavBar = {
  aboutUs: 'À propos de nous',

  services: ' Prestations de service',

  favourite: ' Préféré',

  contacts: 'Contacts',
};


export const footerTitles = {
  conditions: " Conditions d'utilisation",
  privacy: ' Politique de confidentialité',
  updated: 'Restez à jour avec nous',
  reserved: ' © MODERNACASA (2023) Tous droits réservés',
  placeholder: 'E-mail',
};