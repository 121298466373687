import styled from 'styled-components';

import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';

export const PaginationWrapper = styled.div``;

export const PaginationList = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.md}px) {
    width: 100%;
  }
  @media (min-width: ${Breakpoints.md}px) {
    justify-content: flex-end;
  }
`;

export const PaginationItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  &:last-child {
    padding: 1rem 1.25rem;
  }
  @media (min-width: ${Breakpoints.md}px) {
    width: 80px;
    padding: 1rem 1.875rem;
    &:last-child {
      padding: 1rem 1.5rem;
    }
  }
`;
export const PaginationIcon = styled.img``;

export const PaginationNumber = styled.span`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;

  text-align: center;
`;
