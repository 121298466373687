export const teamTexts = {
  label: 'Our Investment Team',
  description:
    'At Modernacasa, we believe that our people are our greatest asset. Our investment team is made up of experienced professionals with a track record of success in the industry.',
};

export const teamBlocks = {
  labelFirst: 'Investment analysts',
  descriptionFirst:
    'Our investment analysts conduct in-depth research and analysis on potential investment opportunities.',

  labelSecond: 'Portfolio managers',

  descriptionSecond:
    'Our portfolio managers are responsible for constructing and managing our investment portfolios.',

  labelThird: 'Risk managers',

  descriptionThird:
    'Our risk managers work closely with our investment team to identify and manage risk effectively.',
};
