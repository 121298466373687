import styled from 'styled-components';
import { colorVariables } from '../../../theme/Theme.constants';

export const TabletBlocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
 height: calc(100vh - 3.75rem);

 
`;

export const RightBlock = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.75fr 0.25fr;
  height: 100%;
  border-left: 1px solid ${colorVariables.blockBorderColor};
  & .blured-button {
    border: none;
    border-top: 1px solid ${colorVariables.blockBorderColor};
    border-right: 1px solid ${colorVariables.blockBorderColor};
    font-size: 1rem;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  border: 1px solid ${colorVariables.blockBorderColor};
  border-left: none;
  border-bottom: none;

  .blured-button {
    border: none;
    font-size: 1rem;
  }

  .blured-button:first-child {
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const LeftBlock = styled.div`
  display: grid;
  grid-template-rows: 4fr 1.48fr 0.5fr;
  height: 100%;

  @media only screen and (max-height: 600px) {
    grid-template-rows: 2.03fr 0.74fr 0.26fr;
  }
`;

export const EmptyBlocks = styled.div`
  display: flex;
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${colorVariables.blockBorderColor};
`;

export const SloganBlock = styled(Block)`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 100%;
`;

export const DescriptionBlock = styled(Block)`
  padding: 0 2rem;
  font-size: 1rem;
  line-height: 150%;

  border-top: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (max-height: 600px) {
    font-size: 0.625rem;
  }
`;

export const EmptyBlock = styled.div`
  width: 100%;
  
  border-right: 1px solid ${colorVariables.blockBorderColor};
  padding: 1.7rem 1rem;
`;
