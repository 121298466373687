export const MenuItemsTitles = {
  all: 'All',
  construction: 'Construction',
  projects: 'Projects',
  interior: 'Interior',
  modular_houses: 'Modular houses',
  swimming_pools: 'Swimming pools',
  solar_energy: 'Solar energy',
  smart_home: 'Smart home',
  inmobiliaria: 'Real estate',
  investments: 'Investments',
  consulting: 'Consulting',
  architect: 'Architect',
  landscape_design: 'Landscape design',
  building_materials: 'Building materials',
  pre_build_houses: 'Pre-build houses',
};

export const loginPage = {
  pageTitle: 'Login',
  mainTitle: 'Start your Construction with us.',
  text: 'To log in to your personal account, you need to book a call to order a project. after that, you will receive a password, which you can then change.',
  btnText: 'Next',
  popupIncorrectText: 'Password or login is not correct',
};
