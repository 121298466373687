import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import { Form } from 'formik';
import { FormControlLabel } from '@mui/material';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 34px;
    line-height: 41px;
  }
`;

export const AfterTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${colorVariables.mainTextColorWitOpacity};
  margin-bottom: 2rem;
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    align-items: flex-end;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    width: 30rem;
  }
`;

export const ErrorMessageWrapper = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

export const CheckboxGroupBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const QuestionBlock = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
`;

export const FormControlLabelBLock = styled(FormControlLabel)`
  display: flex;
  color: rgba(255, 255, 255, 0.4);
  width: 70px;
  white-space: nowrap;

  & span {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 1rem 0;
`;

export const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;

  & .MuiCheckbox-root {
    align-items: flex-start;
  }
`;

export const FileUploaderBlock = styled.div`
  position: relative;
  width: 100%;
  border: 1px dashed rgba(255, 255, 255, 0.2);
`;

export const UploadFileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 1rem 1.25rem;
  cursor: pointer;
  height: 3.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const UploadInfo = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const UploadTitle = styled.span`
  color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
  max-width: 100px;

  @media (min-width: ${Breakpoints.sm}px) {
    font-size: 1rem;
    line-height: 1.25rem;
    max-width: 170px;
  }
`;

export const UploadFormats = styled.span`
  color: rgba(255, 255, 255, 0.4);
`;

export const UploadIcon = styled.div`
  position: absolute;
  top: 0.875rem;
  right: 1.25rem;
  cursor: pointer;
  svg {
    path {
      transition: ${transitions.allHoverTransition};
    }
  }

  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;
