export const ColorsSection = {
  title: 'Couleurs',
  description:
    `Découvrez une collection étonnante de nouvelles puces de couleur et d'agréables mélanges de couleurs primaires ! Préparez-vous à être fasciné par l'effet holographique translucide qui confère une brillance particulière à nos produits.`,
  first: 'Anthracite',
  second: 'Nova Blue',
  third: 'Nova Grey',
  fourth: 'Nova Navy',
  fifth: 'Nova Pearl',
  sixth: 'Nova Stone',
};
