import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const HeaderMenuWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const HeaderMenuBlock = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.md - 1}px) {
    border-top: 1px solid ${colorVariables.blockBorderColor};
    border-left: 1px solid ${colorVariables.blockBorderColor};
  }

  @media only screen and (min-width: ${Breakpoints.md}px){

  }
`;

export const MenuItem = styled.li<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
  height: calc(100% / 4);
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  border-right: 1px solid ${colorVariables.blockBorderColor};
  cursor: pointer;
  transition: 0.3s all ease;
  opacity: ${props => (props.isActive ? 1 : 0.4)};
  z-index: 1;

  .menu-icon {
    fill: ${colorVariables.mainIconColor};
    width: calc(100% / 4);
    transition: 0.3s all ease;
  }

  &:hover {
    background-color: ${colorVariables.opacityBackgroundColor};
    backdrop-filter: blur(2px);
    opacity: 1;
    border: 1px solid ${colorVariables.blockBorderColor};

    & ~ li {
      opacity: 0.4;
    }

    .menu-icon {
      fill: ${colorVariables.mainIconColor};
    }
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    &:nth-child(10) {
      border-bottom: none;
    }
    &:nth-child(11) {
      border-bottom: none;
    }
    &:nth-child(12) {
      border-bottom: none;
    }
  }
`;
