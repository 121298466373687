import React, { FC } from 'react';

import {
  TextSection,
  TextWrapper,
  Title,
  FirstBlock,
  Text
} from './TextSection.styled';

export interface TextProps {
  textList: { id: number; title: string; text: string | JSX.Element }[];
}

export const TextBlock: FC<TextProps> = ({ textList }) => {
  return (
    <TextSection>
      <FirstBlock>
        {textList.map(({ title, text }) => {
          return (
            <TextWrapper>
              <Title>{title}</Title>
              {/* <span dangerouslySetInnerHTML={{
          __html: (t('mainPage.headerTitles.SLOGAN_TEXT') || '') as string,
        }}></span> {text} */}

              <Text
                dangerouslySetInnerHTML={{
                  __html: text as string,
                }}></Text>
            </TextWrapper>
          );
        })}
      </FirstBlock>
    </TextSection>
  );
};
