import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import { Form, Field } from 'formik';
import bgImage from '../../shared/assets/images/login/bg-image.jpg';

export const LoginPageWrapper = styled.div<{ isSubmit: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorVariables.opacityConstructionBackground};

  @media (max-height: ${Breakpoints.height}px) {
    height: 100%;
  }

  @media (min-height: ${Breakpoints.height}px) {
    height: 100vh;
  }
`;

export const Wrapper = styled.div<{ isSubmit: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-height: ${Breakpoints.height}px) {
    padding: 8.5rem 1.25rem 6.25rem;
  }

  @media (min-height: ${Breakpoints.height}px) {
    padding: 0rem 1.25rem;
  }

  @media (min-width: ${Breakpoints.md}px) and (min-height: ${Breakpoints.height}px) {
    padding: 7.875rem 3.75rem 7.812rem;
  }

  @media (min-width: ${Breakpoints.lg}px) and (min-height: ${Breakpoints.height}px) {
    padding: 5.875rem 0px 3.812rem;
  }

  ${props =>
    props.isSubmit
      ? ` filter: blur(5px);
          background: ${colorVariables.opacityConstructionBackground};`
      : ''};
`;

export const PageNameWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0.625rem;
  background: rgba(255, 230, 98, 0.04);
  border: 1px solid rgba(255, 230, 98, 0.7);
  backdrop-filter: blur(2px) !important;

  @media (max-width: ${Breakpoints.md}px) {
    position: absolute;
    top: 5rem;
    left: 1.25rem;
  }

  @media (min-width: ${Breakpoints.md}px) {
    margin-bottom: 7.812rem;
  }
`;

export const PageName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-left: 0.312rem;
  color: ${colorVariables.alternativeTextColor};
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: 1.25rem;

  cursor: pointer;
  @media (max-width: ${Breakpoints.md}px) {
    display: flex;

    top: 5rem;

    background: ${colorVariables.alternativeBackground};
    border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
    padding: 0.625rem;
  }

  @media (min-width: ${Breakpoints.md}px) {
    border: none;
    background: none;

    top: 2.5rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    top: 1.25rem;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    margin-bottom: 2.5rem;
  }
`;
export const Title = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;

  text-align: center;
  max-width: 230px;

  margin-bottom: 0.625rem;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 34px;
    line-height: 41px;
    max-width: 23.75rem;
  }
`;

export const AfterTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: ${colorVariables.mainTextColorWitOpacity};
  max-width: 20rem;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    max-width: 23.437rem;
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    align-items: flex-end;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    width: 30rem;
  }
`;

export const ErrorMessageWrapper = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  &:nth-child(1) {
    margin-bottom: 1.25rem;
  }
  &:nth-child(2) {
    margin-bottom: 1.875rem;
  }
  .input-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0px, -50%);
    fill: white;
    fill-opacity: 0.2;
    transition: 0.3s all ease;
  }

  &:focus-within .input-icon {
    fill: ${colorVariables.alternativeTextColor};
    fill-opacity: 1;
  }
`;
export const Input = styled(Field)`
  width: 100%;
  padding: 1rem 1.25rem;
  background: ${colorVariables.alternativeBackground};
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  outline: none;
  color: ${colorVariables.alternativeTextColor};
  &:placeholder {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;

    color: rgba(255, 255, 255, 0.3);
  }
`;

export const Button = styled.button`
  width: 100%;
  background: rgba(255, 230, 98, 0.1);
  border: 1px solid ${colorVariables.yellowButtonBorder};
  padding: 0.625rem 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  text-align: center;
  cursor: pointer;
  color: ${colorVariables.alternativeTextColor};

  @media (min-width: ${Breakpoints.md}px) {
    width: 8.125rem;
  }
`;

export const PopupWrapper = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ff4d14;
  width: 90%;
  height: 14.062rem;
  background: ${colorVariables.alternativeBackground};
  @media (min-width: ${Breakpoints.md}px) {
    width: 36.25rem;
  }
`;

export const PopupText = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 1.25rem;
`;
