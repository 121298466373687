import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import { Form } from 'formik';
import { TextArea } from '../../../shared/components/TextAreaFieldInput/TextAreaFieldInput.styled';
import { ControlsBlock } from '../LeadForm.styled';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 34px;
    line-height: 41px;
  }
`;

export const AfterTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${colorVariables.mainTextColorWitOpacity};
  margin-bottom: 2rem;
`;

export const ServicesBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
  margin-bottom: 1.25rem;
  @media (min-width: ${Breakpoints.lg}px) {
    width: 50rem;
  }
`;

export const ServicesCards = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  gap: 1.25rem;
`;
export const ServiceCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  cursor: pointer;
  transition: ${transitions.allHoverTransition};

  &:hover {
    background-color: ${colorVariables.yellowButtonBackground};
    border-color: ${colorVariables.alternativeTextColor};
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: calc(50% - 1.25rem / 2);
  }
`;
export const ServiceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;
export const ServiceIcon = styled.div`
  & svg {
    width: 1.875rem;
    height: 1.875rem;
  }
`;
export const ServiceTitle = styled.span`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5px;
`;

export const AddServiceButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #232325;
  cursor: pointer;
  transition: ${transitions.allHoverTransition};
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    width: calc(50% - 1.25rem / 2);
  }

  &:hover {
    background-color: inherit;
  }
`;

export const TextAreaWrapper = styled.div<{ textAreaIsOpen: boolean }>`
  width: 100%;
  opacity: ${props => (props.textAreaIsOpen ? 1 : 0)};
  transition: ${transitions.allHoverTransition};
`;

export const FunctionButton = styled.button`
  width: fit-content;
  padding: 0.5rem 0.75rem;
  background: inherit;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: ${transitions.allHoverTransition};

  &:hover {
    color: ${colorVariables.mainTextColor};
    border-color: ${colorVariables.alternativeTextColor};
    background-color: ${colorVariables.opacityWhiteBackgroundColor};
  }
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    align-items: flex-end;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    width: 30rem;
  }

  & ${TextArea} {
    margin-bottom: 1.25rem;
  }

  & ${ControlsBlock} {
    justify-content: flex-end;
  }
`;

export const HeartIcon = styled.div<{ isFavourite: boolean }>`
  display: block;
  min-width: 20px:
  min-height: 20px;
  cursor: pointer;

  & svg {
    transition: 0.3s fill ease;
    fill: ${props =>
      props.isFavourite ? colorVariables.alternativeTextColor : 'inherit'};
  }

  & path {
    stroke: ${props =>
      props.isFavourite
        ? colorVariables.alternativeTextColor
        : colorVariables.mainTextColor};
  }
`;
