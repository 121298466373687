import React, { FC, } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LeafletEvent } from 'leaflet';
import { CustomPopup, CustomPopupText } from './MapBlock.styled';

import {
  SimpleMapProps,
  locations,
  customIcon,
  url,
  bounds,
} from './MapBlock.constatnts';

export const MapBlock: FC<SimpleMapProps> = ({ position, zoom }) => {
  const onMarkerAdd = (e: LeafletEvent) => {
    const marker = e.target as L.Marker;
    marker.openPopup();
  };

  return (
    <MapContainer
      center={position}
      zoom={zoom}
      minZoom={3}
      style={{ height: '100%', width: '100%' }}
      attributionControl={false}
      maxBounds={bounds}
      maxBoundsViscosity={1.0}
      zoomControl={false}>
      <TileLayer url={url} noWrap={true} />
      {locations.map(location => {
        return (
          <Marker
            eventHandlers={{ add: onMarkerAdd }}
            key={location.name}
            position={location.coordinates}
            icon={customIcon}>
            <Popup>
              <CustomPopup>
                <CustomPopupText>{location.name}</CustomPopupText>
              </CustomPopup>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};
