import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';

export const FooterSectionWrapper = styled.div`
   background-color: rgb(30, 30, 30);

  @media screen and (min-width: ${Breakpoints.md}px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @media screen and (min-width: ${Breakpoints.xxl}px) {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    padding: 0 calc((100vw - ${Breakpoints.maxWidth - 200}px) / 2);
  }
`;
