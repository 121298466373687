import React, { FC } from 'react';
import { SmartHomeWrapper } from './SmartHomePage.styled';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { useTranslation } from 'react-i18next';
import { PartnersSection } from './PartnersSection/PartnersSection';
import { CardsSection } from './CardsSection';
import { AccessoriesSection } from './AccessoriesSection';
import { StepsBlockComponent } from '../../shared/components/StepsBlock/StepsBlock';
import Video from '../../shared/assets/video/smart-home-video.mp4';
import { VideoBlock } from '../../shared/components/VideoBlock';
export const SmartHomePage: FC = () => {
  const menuItems = getMenuItems();
  const smartHomeInfo = menuItems[5];
  const { t } = useTranslation();

  const navItems = [
    {
      title: t('smartHome.HeaderSection.partners'),
      sectionId: 'partners-section',
    },
    {
      title: t('smartHome.HeaderSection.accessories'),
      sectionId: 'accessories-section',
    },
    {
      title: t('smartHome.HeaderSection.stages'),
      sectionId: 'cards-section',
    },
  ];

  const smartHomeStepsData = [
    {
      id: 1,
      title: t('smartHome.SmartHomeStepsSection.stepOne.title'),
      description: (
        <>
          <p>{t('smartHome.SmartHomeStepsSection.stepOne.p1')}</p>
          <br />
          <p>{t('smartHome.SmartHomeStepsSection.stepOne.p2')}</p>
        </>
      ),
    },
    {
      id: 2,
      title: t('smartHome.SmartHomeStepsSection.stepTwo.title'),
      description: (
        <>
          <p>{t('smartHome.SmartHomeStepsSection.stepTwo.p1')}</p>
          <br />
          <p>{t('smartHome.SmartHomeStepsSection.stepTwo.p2')}</p>
        </>
      ),
    },
    {
      id: 3,
      title: t('smartHome.SmartHomeStepsSection.stepThree.title'),
      description: (
        <>
          <p>{t('smartHome.SmartHomeStepsSection.stepThree.p1')}</p>
          <br />
          <p>{t('smartHome.SmartHomeStepsSection.stepThree.p2')}</p>
        </>
      ),
    },
    {
      id: 4,
      title: t('smartHome.SmartHomeStepsSection.stepFour.title'),
      description: (
        <>
          <p>{t('smartHome.SmartHomeStepsSection.stepFour.p1')}</p>
          <br />
          <p>{t('smartHome.SmartHomeStepsSection.stepFour.p2')}</p>
        </>
      ),
    },
    {
      id: 5,
      title: t('smartHome.SmartHomeStepsSection.stepFive.title'),
      description: (
        <>
          <p>{t('smartHome.SmartHomeStepsSection.stepFive.p1')}</p>
          <br />
          <p>{t('smartHome.SmartHomeStepsSection.stepFive.p2')}</p>
        </>
      ),
    },
    {
      id: 6,
      title: t('smartHome.SmartHomeStepsSection.stepSix.title'),
      description: (
        <>
          <p>{t('smartHome.SmartHomeStepsSection.stepSix.p1')}</p>
          <br />
          <p>{t('smartHome.SmartHomeStepsSection.stepSix.p2')}</p>
        </>
      ),
    },
    
  ];

  return (
    <SmartHomeWrapper>
     
       <VideoBlock video={Video} />
      <PageHeaderSection
        title={smartHomeInfo.label}
        logoImg={smartHomeInfo.icon}
        navItems={navItems}
        description={t('smartHome.SmartHomeDescription')}
        id={smartHomeInfo.id}
        backgroundColor={'none'}
      />
      <PartnersSection />
      {/* <CardsSection /> for future feat */}
      <AccessoriesSection />
      <StepsBlockComponent
        stepsData={smartHomeStepsData}
        blockTitle={t('smartHome.SmartHomeStepsSection.blockTitle')}
        description={t('smartHome.SmartHomeStepsSection.descriptionTitle')}
      />
    </SmartHomeWrapper>
  );
};
