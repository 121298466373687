import React, { FC } from 'react';
import {
  Title,
  AfterTitle,
  TextWrapper,
  FormWrapper,
  InputWrapper,
  CheckboxBlock,
  CheckboxText,
  ErrorMesage,
  PrivacyLink,
} from './InformationBlock.styled';
import { Formik } from 'formik';

import { ReactComponent as NameIcon } from '../../../shared/assets/icons/lead-forms/name-icon.svg';
import { ReactComponent as EmailIcon } from '../../../shared/assets/icons/lead-forms/email-icon.svg';
import { ReactComponent as PhoneNumberIcon } from '../../../shared/assets/icons/lead-forms/phone-number-icon.svg';
import { ReactComponent as OrganizationIcon } from '../../../shared/assets/icons/lead-forms/organization-icon.svg';
import { ReactComponent as PositionIcon } from '../../../shared/assets/icons/lead-forms/position-icon.svg';
import { colorVariables } from '../../../theme/Theme.constants';
import { useTranslation } from 'react-i18next';
import { TextAuthInput } from '../../../shared/components/TextAuthInput';
import { Checkbox } from '@mui/material';
import { AcceptButton, ControlsBlock } from '../LeadForm.styled';
import { BluredButton } from '../../../shared/components/BluredButton';
import { LeadFormDataProps } from '../LeadForm';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
  getClientPartnerNavigationItems,
  getMainNavbarItems,
} from '../LeadForm.constants';
import { useNavigate } from 'react-router-dom';
import { mainSection } from '../../../shared/helpers/globalConstants';
import { RoutePaths } from '../../../shared/helpers/routes';
interface InitialValuesProps {
  name?: string;
  phone_number?: string;
  email?: string;
  checkbox?: string[] | [];
  organization?: string;
  position?: string;
}

interface InformationBlockProps {
  setCurrentNavItem: (currentNavItem: string) => void;
  setLeadFormData: (leadFormData: LeadFormDataProps) => void;
  leadFormData: LeadFormDataProps;
}

export const InformationBlock: FC<InformationBlockProps> = ({
  setCurrentNavItem,
  setLeadFormData,
  leadFormData,
}) => {
  const { t } = useTranslation();
  const formsNavigationItems = getMainNavbarItems();
  const clientPartnerNavigationItems = getClientPartnerNavigationItems();
  const leadFormTitle = useSelector((state: any) => state.global.leadFormTitle);
  const navigate = useNavigate();
  const initialValues = {
    name: leadFormData.name,
    phone_number: leadFormData.phone_number,
    email: leadFormData.email,
    checkbox: [],
    organization: leadFormData.organization,
    position: leadFormData.position,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(`${t('leadForm.leadFormPage.mainNameError')}`)
      .matches(/^[^\s].*$/, `${t('leadForm.leadFormPage.secondaryNameError')}`),
    phone_number: Yup.string()
      .required(`${t('leadForm.leadFormPage.mainPhoneError')}`)
      .matches(
        /^(?:\+|\d)[\d\s-]{8,13}\d$/,
        `${t('leadForm.leadFormPage.secondaryPhoneError')}`,
      ),
    email: Yup.string()
      .required(`${t('leadForm.leadFormPage.mainEmailError')}`)
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        `${t('leadForm.leadFormPage.secondaryEmailError')}`,
      )
      .matches(
        /^[a-zA-Z0-9.@]+$/,
        `${t('leadForm.leadFormPage.thirdEmailError')}`,
      ),
  });

  const handleSubmit = (values: InitialValuesProps, errors: any) => {
    setLeadFormData({
      ...leadFormData,
      name: values.name,
      phone_number: values.phone_number,
      email: values.email,
      organization: values.organization,
      position: values.position,
    });

    const formIsCorrect =
      values.name &&
      values.phone_number &&
      values.email &&
      !errors.name &&
      !errors.phone_number &&
      !errors.email;

    formIsCorrect && setCurrentNavItem(formsNavigationItems[3].title);
  };

  const handleBack = (values: InitialValuesProps) => {
    setLeadFormData({
      ...leadFormData,
      name: values.name,
      phone_number: values.phone_number,
      email: values.email,
      organization: values.organization,
      position: values.position,
    });

    setCurrentNavItem(formsNavigationItems[1].title);
  };

  const handleMenuItemClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };
  return (
    <>
      <TextWrapper>
        <Title>{t('leadForm.leadFormPage.informationTitle')}</Title>
        <AfterTitle>
          {t('leadForm.leadFormPage.informationDescription')}
        </AfterTitle>
      </TextWrapper>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, handleChange, errors, touched }) => (
          <FormWrapper>
            <InputWrapper>
              <TextAuthInput
                type="input"
                name="name"
                placeholder={t('leadForm.leadFormPage.name')}
                onChange={handleChange}
              />
              <NameIcon className="input-icon" />
              {errors.name && touched.name && (
                <ErrorMesage>{errors.name}</ErrorMesage>
              )}
            </InputWrapper>
            <InputWrapper>
              <TextAuthInput
                type="phone"
                name="phone_number"
                placeholder={t('leadForm.leadFormPage.phoneNumber')}
                onChange={handleChange}
              />
              <PhoneNumberIcon className="input-icon" />
              {errors.phone_number && touched.phone_number && (
                <ErrorMesage>{errors.phone_number}</ErrorMesage>
              )}
            </InputWrapper>
            <InputWrapper>
              <TextAuthInput
                type="input"
                name="email"
                placeholder={t('leadForm.leadFormPage.email')}
                onChange={handleChange}
              />
              <EmailIcon className="input-icon" />
              {errors.email && touched.email && (
                <ErrorMesage>{errors.email}</ErrorMesage>
              )}
            </InputWrapper>
            {leadFormTitle === clientPartnerNavigationItems[1].title && (
              <>
                <InputWrapper>
                  <TextAuthInput
                    type="input"
                    name="organization"
                    placeholder={t('leadForm.leadFormPage.organization')}
                    onChange={handleChange}
                  />
                  <OrganizationIcon className="input-icon" />
                </InputWrapper>
                <InputWrapper>
                  <TextAuthInput
                    type="input"
                    name="position"
                    placeholder={t('leadForm.leadFormPage.position')}
                    onChange={handleChange}
                  />
                  <PositionIcon className="input-icon" />
                </InputWrapper>
              </>
            )}

            <CheckboxBlock>
              <Checkbox
                onChange={handleChange}
                name="checkbox"
                sx={{
                  color: 'rgba(255, 255, 255, 0.2)',
                  '&.Mui-checked': {
                    color: colorVariables.alternativeTextColor,
                  },
                }}
              />
              <CheckboxText>
                {t('leadForm.leadFormPage.checkboxText')}{' '}
                <PrivacyLink
                  
                  onClick={() =>
                    handleMenuItemClick(RoutePaths.privacyPolicyPage)
                  }>
                  {t('leadForm.leadFormPage.privacyPolicy')}
                </PrivacyLink>
              </CheckboxText>
            </CheckboxBlock>
            <ControlsBlock>
              <BluredButton
                title={t('leadForm.leadFormPage.backButton')}
                handleClick={() => handleBack(values)}
              />

              <AcceptButton
                disabled={!values.checkbox.length}
                onClick={() => handleSubmit(values, errors)}>
                {t('leadForm.leadFormPage.nextButton')}
              </AcceptButton>
            </ControlsBlock>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
