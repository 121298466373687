import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import logo from '../../shared/assets/icons/investments/investments-logo.svg';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
// import bgImage from '../../shared/assets/images/menu-background-images/consulting.jpg';
import { InvestmentsPhilosophySection } from '../../components/InvestmentsPhilosophySection/InvestmentsPhilosophySection';
import { ProcessBlock } from '../../shared/components/ProcessBlock';
import { InvestmentsPackagesSection } from '../../components/InvestmentsPackagesSection';
import { InvestmentsTeamSection } from '../../components/InvestmentsTeamSection';
import { investmentsProcess } from './InvestmentsPage.constants';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import {
  InvestmentsPageWrapper,
  InvestmentsPhilosophyWrapper,
} from './InvestmentsPage.styled';
import { Breakpoints } from '../../theme/Theme.constants';
import { useMediaQuery } from 'react-responsive';
import bgImage from '../../shared/assets/images/investments/investments-bg.jpg'
export const InvestmentsPage: FC = () => {
  const menuItems = getMenuItems();
  const processItems = investmentsProcess();

  const investmentsInfo = menuItems[7];
  const { t } = useTranslation();

  const navItems = [
    {
      title: t('investmentsPage.investmentsNavItems.philosophy'),
      sectionId: 'philosophy-section',
    },
    {
      title: t('investmentsPage.investmentsNavItems.process'),
      sectionId: 'process-section',
    },
    // {
    //   title: t('investmentsPage.investmentsNavItems.packages'),
    //   sectionId: 'packages-section',   // LOGIC FOR FUTURE FEATURES
    // },
    {
      title: t('investmentsPage.investmentsNavItems.team'),
      sectionId: 'team-section',
    },
  ];

  const secondDescription = t('investmentsPage.investmentsSecondDescription')
    ? t('investmentsPage.investmentsSecondDescription')
    : 'At [Company Name], we have a simple yet effective investment philosophy that guides everything we do. Our focus is on generating superior risk-adjusted returns for our investors through a disciplined and research-driven approach to investing.';

  const investmentsProcessTexts = {
    label: t('investmentsPage.investmentsProcessTexts.label'),
    description: t('investmentsPage.investmentsProcessTexts.description'),
  };
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });
  
const isDesktopXlg = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
   const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  return (
    <InvestmentsPageWrapper>
      <PageHeaderSection
        title={investmentsInfo.label}
        logoImg={investmentsInfo.icon}
        navItems={navItems}
        description={investmentsInfo.description}
        withoutTitleBlock={false}
        bgImage={bgImage}
        secondDescription={secondDescription}
      />
      <InvestmentsPhilosophyWrapper id="philosophy-section">
        <InvestmentsPhilosophySection />
      </InvestmentsPhilosophyWrapper>

      <div id="process-section">
        <ProcessBlockHeader
          currentTexts={investmentsProcessTexts}
          isTabletPadding
           titleSize={isDesktop ? '30px' : isDesktopXlg ?'24px': isTablet ? '20px': ''}
        />
        <ProcessBlock processList={processItems} />
      </div>

      {/* <InvestmentsPackagesSection />  // LOGIC FOR FUTURE FEATURES */}
      <InvestmentsTeamSection />
    </InvestmentsPageWrapper>
  );
};
