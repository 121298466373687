import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PartnersWrapper,
  TitleWrapper,
  Title,
  AfterTitleWrapper,
  AfterTitle,
  ListBlock,
  ItemBlock,
  IconBlock,
} from './PartnersMobileSection.styled';
import trinaSolar from '../../../../shared/assets/icons/solar-energy/partners/trina-solar-logo.svg';
import panasonic from '../../../../shared/assets/icons/solar-energy/partners/panasonic.svg';
import sunPower from '../../../../shared/assets/icons/solar-energy/partners/sun-power.svg';
import lgLogo from '../../../../shared/assets/icons/solar-energy/partners/lg-logo.svg';
export const PartnersMobileSection: FC = () => {
  const { t } = useTranslation();
  const listBlock = [
    { number: 1, icon: trinaSolar , className: 'trina-solar'},
    { number: 2, icon: sunPower , className: 'sun-power'},
    { number: 3, icon: panasonic , className: 'panasonic'},
    
    { number: 4, icon: lgLogo , className: 'lg'},
    
  ];
  return (
    <PartnersWrapper>
      <TitleWrapper>
        <Title>{t('smartHome.PartnersSection.title')}</Title>
      </TitleWrapper>
      <AfterTitleWrapper>
        <AfterTitle>
          {t('solarEnergyPage.partnersSection.description')}
        </AfterTitle>
      </AfterTitleWrapper>

      <ListBlock>
        {listBlock.map(({ number, icon ,className}) => {
          return (
            <ItemBlock key={number}>
              <IconBlock src={icon} className={ className}/>
            </ItemBlock>
          );
        })}
      </ListBlock>
    </PartnersWrapper>
  );
};
