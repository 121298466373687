export const SmartHomeStepSection = {
  blockTitle: ' Etapas del hogar inteligente',
  descriptionTitle: ' Mudarse',
  stepOne: {
    title: 'Paso uno',
    p1: `Planificación de un sistema ""smart home"" antes de empezar a instalar un sistema doméstico inteligente, hay que planificarlo cuidadosamente. Esto incluye identificar los dispositivos y tecnologías adecuados, su ubicación, el cableado y otros detalles.`,
    p2: `También tienes que determinar el presupuesto que estás dispuesto a gastarte en instalar y configurar el sistema.`,
  },
  stepTwo: {
    title: 'Paso dos',
    p1: `Compra de dispositivos y materiales. Una vez planificado el sistema doméstico inteligente, hay que adquirir todos los dispositivos y materiales necesarios.`,
    p2: `Puede tratarse de sensores de movimiento, cerraduras de puertas, termostatos, enchufes inteligentes, dispositivos de iluminación o sistemas de videovigilancia, entre otros.`,
  },
  stepThree: {
    title: 'Paso tres',
    p1: `Instalación de sensores y dispositivos. En esta fase se instalan todos los sensores y dispositivos. Deben instalarse de acuerdo con un plan previamente determinado y teniendo en cuenta la necesidad de cableado. Nuestro equipo de especialistas privados se encargará de la instalación: electricistas, reparadores. `,
    p2: `Tras instalar los dispositivos y sensores, es necesario conectarlos al sistema de control. Puede ser un controlador doméstico inteligente que conectará todos los dispositivos entre sí.`,
  },
  stepFour: {
    title: 'Paso cuatro',
    p1: `Programación y configuración. Una vez conectados todos los dispositivos al sistema de control, hay que configurarlos para que funcionen juntos. Esto puede incluir la creación de guiones de automatización, la instalación del control por voz y la configuración de aplicaciones para controlar el sistema.`,
    p2: `Una solución integral debe reconocer de oído al propietario de la vivienda, conocer sus principales intereses y preferencias.`,
  },
  stepFive: {
    title: 'Paso cinco',
    p1: `Pruebas. Una vez configurado un sistema doméstico inteligente, hay que probarlo para garantizar que la innovación se integra correctamente. Esto incluye comprobar el estado de todos los dispositivos y sensores, así como verificar los escenarios de automatización y control. Ya en esta fase, el mecanismo ha aprendido a adaptarse a los hábitos. El programa apagará automáticamente las luces de todos los rincones de la casa cuando todo el mundo esté ya dormido, desconectará los enchufes innecesarios, pondrá la calefacción por suelo radiante y el sistema de climatización en modo ahorro, cerrará las puertas y activará el sistema de seguridad, que protegerá de forma fiable el perímetro de la casa.`,
    p2: `El sistema preparará las habitaciones para la mañana, le servirá café aromático, le despertará con su melodía favorita y se ocupará de la seguridad de sus hijos.`,
  },
  stepSix: {
    title: 'Paso seis',
    p1: `El disfrute. La etapa final, cuando el mecanismo está listo para funcionar. Tendrá que supervisar el funcionamiento del sistema, mantenerlo y actualizarlo si es necesario. Esta etapa es la más esperada, ya que a partir de este momento es capaz de dividir la vida en ""antes"" y ""después"" Su casa se convierte en el guardián perfecto, que vigila cuidadosamente cada ventana y puerta, y también recibe a los invitados como el mayordomo más perfecto. Incluso te avisa cuando llegan invitados devolviéndote la llamada a tu número, lo que te permite estar en contacto con ellos aunque estés fuera de casa.`,
    p2: `Con una casa así, puedes controlarla mediante sencillos comandos en el teléfono. Además, ahora es posible olvidarse de la oscuridad o el calor: con sólo pulsar un botón se encenderá la luz, se cerrarán las cortinas y se pondrá la música. El mecanismo está diseñado para hacer más cómoda la vida en la casa, optimizar el tiempo del residente y automatizar procesos clave.`,
  },
  
};
