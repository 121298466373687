import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const InteriorCaseWrapper = styled.div``;

export const AboutSectionWrapper = styled.div`
  padding: 2.5rem 0 3.75rem;
  background: ${colorVariables.alternativeBackground};
  @media (min-width: ${Breakpoints.md}px) {
    padding: 7.812rem 2.5rem 1.875rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 7.812rem 6.25rem 12.5rem;
  }
`;

export const HousesDetailsSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  padding: 0px 1.25rem 10rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 2.5rem 5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0px 6.25rem 7.5rem;
  }
`;


export const DetailsBlock = styled.div<{ currentBlock: number }>`
  position: absolute;
  top: 38%;
  right: 25%;

  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.xlg}px) {
    top: 22%;
    right: 48%;
    ${props =>
      props.currentBlock !== 2 &&
      `
      &:nth-of-type(1){
         
         z-index: 15;
      }
    `}
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    top: 33%;
    right: 63%;
    ${props =>
      props.currentBlock !== 9 &&
      `
      &:nth-of-type(1){
         
         z-index: 15;
      }
    `}
  }

  &:nth-of-type(2) {
    top: 56%;
    right: 37%;

    @media (min-width: ${Breakpoints.md}px) {
      top: 20%;
      left: 32%;
    }

    @media (min-width: ${Breakpoints.xlg}px) {
      top: 15%;
      left: 30%;
    }
  }
  &:nth-of-type(3) {
    top: 51%;
    right: 58%;

    @media (min-width: ${Breakpoints.md}px) {
      top: 40%;
      left: 20%;
    }

    @media (min-width: ${Breakpoints.xlg}px) {
      top: 22%;
      left: 47%;
    }
  }
  &:nth-of-type(4) {
    top: 40%;
    right: 310px;

    @media (min-width: ${Breakpoints.md}px) {
      top: 60%;
      left: 37%;
    }

    @media (min-width: ${Breakpoints.xlg}px) {
      top: 36%;
      left: 72%;
    }
  }

  &:nth-of-type(5) {
    top: 15%;
    left: 38%;

    @media (min-width: ${Breakpoints.md}px) {
      top: 77%;
      left: 55%;
    }

    @media (min-width: ${Breakpoints.xlg}px) {
      top: 53%;
      left: 70%;
    }
  }

  &:nth-of-type(6) {
    @media (min-width: ${Breakpoints.xlg}px) {
      top: 59%;
      left: 57%;
    }
  }
  &:nth-of-type(7) {
    @media (min-width: ${Breakpoints.xlg}px) {
      top: 70%;
      left: 47%;
    }
  }
  &:nth-of-type(8) {
    @media (min-width: ${Breakpoints.xlg}px) {
      top: 55%;
      left: 40%;
    }
  }
  &:nth-of-type(9) {
    @media (min-width: ${Breakpoints.xlg}px) {
      top: 45%;
      left: 17%;
    }
  }
  &:nth-of-type(10) {
    @media (min-width: ${Breakpoints.xlg}px) {
      top: 37%;
      left: 57%;
    }
  }
`;

export const PhotoSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
 
  padding-bottom: 3.75rem;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 2.5rem 5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0px 6.25rem 5rem;
  }
`;
