import React, { FC, useEffect, useState } from 'react';
import { HeaderSection } from '../../components/HeaderSection';
import { MenuSection } from '../../components/MenuSection';
import { SliderSection } from '../../components/SliderSection';
import { MainPageWrapper } from './MainPage.styled';
import { AboutUsSection } from '../../components/AboutUsSection';
import { ContactsSection } from '../../components/ContactsSection';

import { FAQSection } from '../../components/FAQSection';
import {
  getHoverMenuItems,
  getMenuItems,
} from '../../components/MenuSection/MenuSection.constatnts';
import i18next from 'i18next';
import { TopServicesSection } from '../../components/TopServicesSection';
import architectBg from '../../shared/assets/images/architect/architect-bg.jpg';
import firstSlide from '../../shared/assets/images/construction/slider-img-1.jpg';
import secondSlide from '../../shared/assets/images/construction/slider-img-2.jpg';
import investmentsBgImage from '../../shared/assets/images/investments/investments-bg.jpg';
export const MainPage: FC = () => {
  const [menuItems, setMenuItems] = useState<any>(getMenuItems());
  const [hoverMenuItems, setHoverMenuItems] = useState<any>(
    getHoverMenuItems(),
  );

  useEffect(() => {
    i18next.on('languageChanged', lng => {
      const menuItems = getMenuItems();
      const hoverMenuItems = getHoverMenuItems();
      setMenuItems(menuItems);
      setHoverMenuItems(hoverMenuItems);
    });
  }, []);

  useEffect(() => {
    const images = [architectBg, firstSlide, secondSlide, investmentsBgImage];

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  return (
    <MainPageWrapper>
      <HeaderSection menuItems={menuItems} />
      <SliderSection />
      <TopServicesSection />
      <MenuSection menuItems={hoverMenuItems} />
      <AboutUsSection />
      <ContactsSection />
      <FAQSection menuItems={menuItems} />
    </MainPageWrapper>
  );
};
