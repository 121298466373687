import React, { FC } from 'react';
import {
  BackgroundImage,
  BackgroundWithoutImage,
  MainBackgroundImage,
} from './BackgroundImageBlock.styled';

import { MenuItemProps } from '../MenuSection/MenuSection.types';
import mainBackgroundImage from '../../shared/assets/images/main-header-background-image.jpg';

export interface BackgroundImageBlockProps {
  activeId?: number | null;
  withoutMainBackgroundImg?: boolean;
  menuItems: any;
}

export const BackgroundImageBlock: FC<BackgroundImageBlockProps> = ({
  activeId,
  withoutMainBackgroundImg,
  menuItems,
}) => {
  const hoverItemsLength = 12;
  return (
    <>
      {!withoutMainBackgroundImg ? (
        <MainBackgroundImage
          loading="lazy"
          src={mainBackgroundImage}
          alt=""
          noneActiveId={!activeId}
        />
      ) : (
        <BackgroundWithoutImage />
      )}

      {menuItems.length > hoverItemsLength
        ? menuItems
            .slice(0, -1)
            .map((menuItem: MenuItemProps) => (
              <BackgroundImage
                loading="lazy"
                key={menuItem.id}
                src={menuItem.backgroundImg}
                alt={menuItem.label}
                isOpen={menuItem.id === activeId}
              />
            ))
        : menuItems.map((menuItem: MenuItemProps) => (
            <BackgroundImage
              loading="lazy"
              key={menuItem.id}
              src={menuItem.backgroundImg}
              alt={menuItem.label}
              isOpen={menuItem.id === activeId}
            />
          ))}
    </>
  );
};
