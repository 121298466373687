import styled, { css } from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../theme/Theme.constants';

export const OurValuesBlocksWrapper = styled.div<{ asSection?: boolean }>`
  ${props =>
    !props.asSection
      ? `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      width: 100%;
      padding-left: 6.25rem;
      padding-right: 6.25rem;
      background-color: ${colorVariables.blackBackgroundColor};
      pointer-events: none;
      opacity: 0;
      transition: ${transitions.opacityTransitions};
    `
      : ''}
`;

export const TitleBlockWrapper = styled.div<{ asSection?: boolean }>`
  padding: 10px 0px;
  width: 100%;
  border-width: 0.0625rem 0.0625rem 0rem 0.0625rem;
  border-style: solid;
  border-color: ${props =>
    !props.asSection
      ? `${colorVariables.ourValuesBlockBorderColor}`
      : `${colorVariables.borderBookBlockColor}`};
  @media (min-width: ${Breakpoints.xlg}px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-bottom: 3.75rem;
    border-width: 0.0625rem;
  }
`;
export const OurValuesMainTitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  text-transform: uppercase;

  color: ${colorVariables.secondaryTextColor};

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 3.75rem;
    line-height: 4.56rem;
  }
`;

export const OurValuesList = styled.ul<{ asSection?: boolean }>`
  @media (max-width: ${Breakpoints.md}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.xlg}px) {
    display: flex;
    flex-wrap: wrap;
    border-width: 0px 0.0625rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: ${colorVariables.borderBookBlockColor};
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 0px 40px;
    border-top: 1px solid ${colorVariables.borderBookBlockColor};
  }
`;

export const OurValuesItem = styled.li<{ asSection?: boolean }>`
  @media (max-width: ${Breakpoints.md}px) {
    border-width: 0px 0.0625rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: ${colorVariables.borderBookBlockColor};

    &:last-child {
      border-width: 0px 0.0625rem 0.0625rem 0.0625rem;
    }
  }

 
  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.xlg}px) {
   flex-basis: calc(50%);

   &:nth-child(even) {
      border-left: 1px solid  ${colorVariables.borderBookBlockColor};
    }
   
  }
  
  @media (min-width: ${Breakpoints.xlg}px) {
    flex-basis: calc(100% / 4);
    box-sizing: border-box;
    border-width: 0px 0.0625rem 0.0625rem 0px;
    border-style: solid;
    border-color: ${colorVariables.borderBookBlockColor}};
    &:first-child {
      border-width: 0px 0.0625rem 0.0625rem 0.0625rem;
      
    }

    
    
    &:nth-child(5){
      @media (min-width: ${Breakpoints.xlg}px){
         border-width: 0px 0.0625rem 0.0625rem 0.0625rem;

      margin-top: 40px;
      }
     
    }
     &:nth-child(n+5) {
       @media (min-width: ${Breakpoints.xlg}px){
         border-top: 0.0625rem solid  ${colorVariables.borderBookBlockColor};
      margin-top: 40px;
       }
      
    }
    
    
  }
  
`;

export const TextBlocksWrapper = styled.div<{ asSection?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background: ${colorVariables.opacityWhiteBackgroundColor};
  border-width: 0rem 0px 0.0625rem 0px;
  border-style: solid;
  border-color: ${colorVariables.borderBookBlockColor};
  height: 70px;
  @media (min-width: ${Breakpoints.xlg}px) {
    border-color:${colorVariables.borderBookBlockColor};
  }

  
`;

export const OurValuesTitles = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: ${colorVariables.mainTextColor};

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 24px;
    line-height: 22px;
  }
`;

export const OurValuesItemTextWrapper = styled.div`
  padding: 1.5625rem;
`;
export const OurValuesTexts = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;

  color: ${colorVariables.footerReservedTitle};
`;
