export const CardsSection = {
  basic: {
    type: 'Базовий',
    homePackage: ' Система Home Guardian',
    description: ` Це налаштування включає розумний термостат, розумне освітлення та розумну систему безпеки. Це дозволяє дистанційно контролювати температуру, освітлення та безпеку вашого будинку зі смартфона.`,
  },
  standart: {
    type: 'Стандарт',
    homePackage: ' Еко-розумне рішення',
    description: ` Це налаштування включає всі функції базового налаштування, а також інтелектуальні замки, розумну систему поливу та інтеграцію голосового керування. Це забезпечує безперебійну автоматизацію та керування різними системами вашого будинку.`,
  },
  premium: {
    type: 'Преміум',
    homePackage: ' Інтелектуальне налаштування Oasis',
    description: `Оновіть нашу установку на основі штучного інтелекту для покращеного керування енергією, розваг і домашньої автоматизації. Ідеально підходить для великих домогосподарств або комерційних об’єктів, яким потрібна максимальна зручність і ефективність.`,
  },
};
