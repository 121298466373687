export const discoverList = {
  labelFirst: 'Nombre',

  labelSecond: 'Nombre',

  labelThird: 'Nombre',
};

export const realEstateDiscoverTexts = {
  label: 'Descubre tu propia parcela de terreno hoy',
  description:
    'Ofrecemos una amplia gama de parcelas de terreno para cualquier necesidad y presupuesto. Si has estado soñando con ser dueño de tu propio terreno, no busques más. Nuestra selección incluye desde pequeñas parcelas urbanas hasta vastas extensiones rurales. Explora nuestra colección y encuentra la parcela perfecta para tus sueños.',
};

export const aboutDiscoverList = [
  {
    id: 1,
    label: 'Acerca de',
    text: 'Somos una empresa con más de 50 años de experiencia en la industria inmobiliaria. Nos especializamos en el desarrollo, construcción y venta de inmuebles en España. Nuestro objetivo es ayudar a nuestros clientes a encontrar la propiedad perfecta para sus necesidades.',
  },
  {
    id: 2,
    label: 'Venta de inmuebles',
    text: 'Ofrecemos una amplia gama de propiedades en venta en toda España. Nuestros servicios incluyen la búsqueda de la propiedad perfecta, la realización de inspecciones y la provisión de asesoramiento sobre la compra de inmuebles. Garantizamos a nuestros clientes que encontraremos la mejor propiedad al mejor precio.',
  },
];

export const aboutText =
  'Si está listo para comprar, vender o alquilar una propiedad, o si está interesado en saber más sobre nuestros servicios, póngase en contacto con nosotros hoy mismo. Nuestro equipo de expertos está listo para ayudarle en cada paso del camino.';