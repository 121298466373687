export const servicesTexts = {
    label: 'НАШІ ПОСЛУГИ З ВИКОРИСТАННЯ СОНЯЧНОЇ ЕНЕРГІЇ',
    description:
      `Ми пропонуємо широкий спектр послуг з дизайну інтер'єру, щоб допомогти вам створити простір вашої мрії. Від початкової концепції та планування простору до вибору кольору, підбору меблів та аксесуарів - наша команда може впоратися з кожним аспектом вашого проекту. Наші послуги включають:`,
  };
export const servicesList = {
  textFirst: 'Консультація та оцінка',

  textSecond: 'Дизайн і пропозиція',

  textThird: 'Фінансування та стимулювання',

  textFourth: 'Дозволи та погодження',

  textFifth: 'Встановлення',

  textSixth: 'Активація та обслуговування системи',
};

