export const swimmingPoolsStepsSection = {
  blockTitle: 'Etapas de las piscinas',
  descriptionTitle: 'Consulta inicial',
  stepOne: {
    title: 'Diseño y planificación',
    p1: `Empiece por crear un diseño detallado para su piscina, teniendo en cuenta factores como el tamaño, la forma, la ubicación y las características. Consulte a profesionales, como arquitectos y diseñadores de piscinas, para asegurarse de que el diseño satisface sus necesidades y cumple la normativa local.`,
    p2: ``,
  },
  stepTwo: {
    title: 'Excavación',
    p1: `La excavación consiste en excavar la zona donde se ubicará la piscina. Normalmente se utiliza maquinaria pesada para remover la tierra y crear el agujero de acuerdo con las especificaciones del diseño. Este paso requiere una cuidadosa precisión para conseguir la forma y profundidad deseadas.`,
    p2: ``,
  },
  stepThree: {
    title: 'Refuerzo y fontanería',
    p1: `Una vez terminada la excavación, el siguiente paso consiste en instalar un sólido armazón de refuerzo. Se utilizan barras de acero para proporcionar resistencia estructural a la piscina. En esta fase también se instalan las tuberías, incluidas las de suministro de agua y desagüe.`,
    p2: ``,
  },
  stepFour: {
    title:
      'Vertido del hormigón o instalación del vaso de la piscina prefabricada',
    p1: `Dependiendo de su diseño y preferencias, puede optar por verter hormigón para crear la estructura de la piscina o instalar un casco de piscina prefabricado. Las piscinas de hormigón ofrecen más opciones de personalización, mientras que los cascos prefabricados permiten una instalación más rápida.`,
    p2: ``,
  },
  stepFive: {
    title: 'Acabado y sistema de filtración',
    p1: `Una vez colocada la estructura de la piscina, es hora de añadir los toques finales. Esto incluye la aplicación de revestimientos impermeabilizantes, el embaldosado del interior y la instalación de cualquier elemento adicional, como escalones, trampolines o cascadas. Simultáneamente, se instala el sistema de filtración, que incluye bombas, filtros y calentadores, para garantizar la circulación y el mantenimiento adecuados del agua.`,
    p2: ``,
  },
  stepSix: {
    title: 'Cubiertas y jardinería',
    p1: `Alrededor de la zona de la piscina, puede crear una terraza o patio para descansar y realizar actividades recreativas. Elija materiales duraderos, antideslizantes y aptos para el exterior. Los elementos de jardinería, como plantas, árboles e iluminación, también pueden mejorar la estética y la funcionalidad de la zona de la piscina.`,
    p2: ``,
  },
  stepSeven: {
    title: 'Rellene y comience el mantenimiento',
    p1: `Una vez finalizada la construcción, la piscina se llena de agua y se aplican tratamientos químicos para establecer el equilibrio hídrico adecuado. Inicie las rutinas de mantenimiento necesarias, como la limpieza periódica, el análisis del agua y la conservación del equipo, para garantizar que la piscina siga siendo segura, limpia y acogedora durante años.`,
    p2: ``,
  },
};																								