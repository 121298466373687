export const MainSection = {
  services: 'Serveis',
  сonstruction: 'Construcció',
  projects: 'Projectes',
  interior: 'Interior',
  modularHouses: 'Cases modulars',
  architect: 'Arquitectura',
  consulting: 'Consultoria',
  solarEnergy: 'Energia solar',
  inmobiliaria: 'Immobiliària',
  investments: 'Inversions',
  swimmingPools: 'Piscines',
  smartHome: 'Casa intel·ligent',
  landscapeDesign: 'Construcció',
  buildingMaterials: 'Materials de construcció',
};
