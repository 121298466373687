import React, { FC } from 'react';
import {
  HeaderMenuBlock,
  HeaderMenuWrapper,
  MenuItem,
} from './HeaderMenuComponent.styled';

import { getMenuItems } from '../MenuSection/MenuSection.constatnts';

import { MenuItemProps } from '../MenuSection/MenuSection.types';
import { useNavigate } from 'react-router-dom';
import { mainSection } from '../../shared/helpers/globalConstants';

export interface HeaderMenuComponentProps {
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
  setMenuIsOpen?: (menuIsOpen: boolean) => void;
}

export const HeaderMenuComponent: FC<HeaderMenuComponentProps> = ({
  setActiveId,
  activeId,
  setMenuIsOpen,
}) => {
  const menuItems = getMenuItems();
  const navigate = useNavigate();

  const handleMenuItemClick = (routePath: string | undefined) => {
    setMenuIsOpen && setMenuIsOpen(false);

    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <HeaderMenuWrapper>
      <HeaderMenuBlock>
        {menuItems.map((menuItem: MenuItemProps) => (
          <MenuItem
            key={menuItem.id}
            isActive={!activeId || menuItem.id === activeId}
            onMouseEnter={() => setActiveId(menuItem.id)}
            onMouseLeave={() => setActiveId(null)}
            onClick={() => handleMenuItemClick(menuItem?.routePath)}>
            {menuItem.icon}
          </MenuItem>
        ))}
      </HeaderMenuBlock>
    </HeaderMenuWrapper>
  );
};
