import React, { FC } from 'react';
import {
  BurgerMenuButton,
  BurgerMenuContainer,
  BurgerMenuLine,
  BurgerMenuWrapper,
  LogoImg,
  LogoTitle,
  MenuHeader,
  NavBurgerWrapper,
  NavbarMenuItem,
  NavbarMenuItems,
} from './NavBurger.styled';
import { HeaderTitlesEn } from '../HeaderSection/HeaderSection.constatnts';
import { NavBarItemTitlesProps } from '../TopNavBar/TopNavBar.types';
import { MenuListBlock } from '../MenuListBlock';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';
import { mainSection } from '../../shared/helpers/globalConstants';

export interface NavBurgerProps {
  menuIsOpen: boolean;
  setMenuIsOpen: any;
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
}

export const NavBurger: FC<NavBurgerProps> = ({
  menuIsOpen,
  setMenuIsOpen,
  activeId,
  setActiveId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navBarItemTitlesArray = t('mainPage.navBarItemTitlesArray', {
    returnObjects: true,
  }) as NavBarItemTitlesProps[];

  const handleToggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const handleMenuItemClick = (routePath: string | undefined) => {
    setMenuIsOpen(false);

    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <NavBurgerWrapper>
      <BurgerMenuButton onClick={handleToggleMenu}>
        <BurgerMenuLine isOpen={menuIsOpen} />
        <BurgerMenuLine isOpen={menuIsOpen} />
        <BurgerMenuLine isOpen={menuIsOpen} />
      </BurgerMenuButton>

      <BurgerMenuContainer isOpen={menuIsOpen}>
        <BurgerMenuWrapper>
          <MenuHeader>
            <LogoImg />
            <LogoTitle>{HeaderTitlesEn.LOGO_TEXT}</LogoTitle>
          </MenuHeader>
          <NavbarMenuItems>
            {navBarItemTitlesArray
              .slice(0, -2)
              .map((navBarItem: NavBarItemTitlesProps, index: number) => (
                <NavbarMenuItem
                  key={navBarItem.itemName + index}
                  onClick={() => handleMenuItemClick(navBarItem.routePath)}>
                  {navBarItem.itemName}
                </NavbarMenuItem>
              ))}
          </NavbarMenuItems>
          <MenuListBlock
            setActiveId={setActiveId}
            activeId={activeId}
            setMenuIsOpen={setMenuIsOpen}
          />
        </BurgerMenuWrapper>
      </BurgerMenuContainer>
    </NavBurgerWrapper>
  );
};
