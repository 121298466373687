import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';

export const ModularHousesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`;
export const ModelSectionWrapper = styled.div`
  position: relative;
  z-index: 10;
  padding: 3.75rem 0px;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem 0px 5rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 8.75rem 6.25rem 11.875rem;
    border-top: 1px solid ${colorVariables.processBlockHeaderBorderColor};
    border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};
    backdrop-filter: blur(10px) !important;
  }

  
`;

export const OptionsSectionWrapper = styled.div`
   position: relative;
  z-index: 10;
 padding: 0rem 0px 5rem;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0rem 0px 10rem;
  }
`;

export const OptionsWrapper = styled.div<{ bgImage: string }>`
 position: relative;
  z-index: 10;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, ${colorVariables.alternativeBackground} 0%, rgba(23, 23, 25, 0) 100%);
    transform: rotate(180deg);

    @media (min-width: ${Breakpoints.xlg}px) {
      transform: rotate(0deg);
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const OptionsTextsWrapper = styled.div`
  position: relative;
  z-index: 10;
  padding: 9.062rem 1.25rem 5.312rem;

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 9.062rem 13.75rem 8.75rem;
  }
`;
export const OptionsTitle = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;

  margin-bottom: 1rem;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.xlg}px) {
  }
`;

export const OptionsText = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  max-width: 20rem;

  color: rgba(255, 255, 255, 0.8);

  @media (min-width: ${Breakpoints.md}px) {
    max-width: 31.25rem;
  }
`;
