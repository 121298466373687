export const headMenu = {
  style: 'Style',
  typology: 'Typologie',
  homes: 'Nouvelles Maisons',
};

export const typeHouseStyle = {
  pools: 'Piscines',
  classic: 'Classique',
  design: 'Design',
  minimalist: 'Minimaliste',
  modern: 'Moderne',
  original: 'Original',
  rustic: 'Rustique',
  traditional: 'Traditionnel',
  wood: 'En bois',
};

export const inputsTexts = {
  numOfBedrooms: 'Nombre de chambres :',

  bedrooms: 'chambres',

  surfaceM: 'Surface m2 :',

  surface: 'surface',

  numOfParking: 'Nombre de places de parking :',

  parking: 'parking',

  numOfBudget: 'Budget :',

  budget: 'budget',
};

export const searchBtn = 'Rechercher';
 export const removeAll = 'Supprimer tout';