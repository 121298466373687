import React, { FC } from 'react';
import {
  TopServicesSectionWrapper,
  TopBlock,
  Title,
  СenterBlock,
  List,
  Item,
  ElWrapper,
  BottomBlock,
  TextWrapper,
  Text,
  LinkWrapper,
  LinkText,
  FirstTitlePart,
  TitleWrapper,
  Label,
  IconWrapper,
  MainIconWrapper,
  MainIconImg,
  Wrapper,
} from './TopServicesSection.styled';

import { getMenuItems } from '../MenuSection/MenuSection.constatnts';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import mainLogo from '../../shared/assets/icons/logo.svg';
import { RoutePaths } from '../../shared/helpers/routes';
import { mainSection } from '../../shared/helpers/globalConstants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TopServicesSection: FC = () => {
  const menuItems = getMenuItems();
  const modularHousesInfo = menuItems[2];
  const interiorInfo = menuItems[1];
  const poolsInfo = menuItems[3];
  const smartHomeInfo = menuItems[5];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const handleMenuItemClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };
  const topServices = [
    {
      id: 1,
      label: modularHousesInfo.label,
      icon: modularHousesInfo.icon,
      bgImage: modularHousesInfo.backgroundImg,
      link: RoutePaths.modularHousesPage,
    },
    {
      id: 2,
      label: interiorInfo.label,
      icon: interiorInfo.icon,
      bgImage: interiorInfo.backgroundImg,
      link: RoutePaths.interiorPage,
    },
    {
      id: 3,
      label: smartHomeInfo.label,
      icon: smartHomeInfo.icon,
      bgImage: smartHomeInfo.backgroundImg,
      link: RoutePaths.smartHome,
    },
    {
      id: 4,
      label: poolsInfo.label,
      icon: poolsInfo.icon,
      bgImage: poolsInfo.backgroundImg,
      link: RoutePaths.swimmingPools,
    },
  ];
  return (
    <TopServicesSectionWrapper>
      <Wrapper>
        <TopBlock>
          {isTablet && (
            <MainIconWrapper>
              <MainIconImg src={mainLogo} />
            </MainIconWrapper>
          )}
          <TitleWrapper>
            <Title>
              <FirstTitlePart>
                {t('mainPage.topServicesTitle.top')}
              </FirstTitlePart>

              {t('mainPage.topServicesTitle.services')}
            </Title>
          </TitleWrapper>
        </TopBlock>
        <СenterBlock>
          <List>
            {topServices.map(({ id, label, icon, bgImage, link }) => {
              return (
                <Item bgImage={bgImage} key={id}>
                  <ElWrapper
                    to={link}
                    onClick={() => handleMenuItemClick(link)}>
                    <IconWrapper>{icon}</IconWrapper>
                    <Label>{label}</Label>
                  </ElWrapper>
                </Item>
              );
            })}
          </List>
        </СenterBlock>

        <BottomBlock>
          <TextWrapper>
            <Text>{t('mainPage.servicesDescription')}</Text>
          </TextWrapper>

          <LinkWrapper onClick={() => handleMenuItemClick(RoutePaths.services)}>
            <LinkText to={RoutePaths.services}>
              {' '}
              {t('mainPage.allServices')}
            </LinkText>
          </LinkWrapper>
        </BottomBlock>
      </Wrapper>
    </TopServicesSectionWrapper>
  );
};
