import styled, { css } from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../theme/Theme.constants';

export const OurStoryBlockWrapper = styled.div<{ asSection?: boolean }>`
  ${props =>
    !props.asSection
      ? `position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  background: ${colorVariables.ourStoryBlockBackground};

  pointer-events: none;

  opacity: 0;
  transition: ${transitions.opacityTransitions}, transform 0.3s ease;`
      : ''}
`;

export const TopBlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const BookBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.8125rem 3.125rem;
  background: ${colorVariables.opacityWhiteBackgroundColor};
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: ${colorVariables.borderBookBlockColor};

  @media (min-width: ${Breakpoints.xlg}px) {
    flex: 1;
    padding: 0.3125rem;
  }
`;

export const TitleBlock = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-width: 1px 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.borderBookBlockColor};
  padding: 1.875rem 0;
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0.3125rem;
  }
`;

export const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 3.56rem;
  line-height: 1.2;
  text-transform: uppercase;
  color: #bbbbbb;
  text-align: center;
`;

export const ImgTopBlock = styled.div`
  flex: 1;

  @media (min-width: ${Breakpoints.md}px) {
    height: 17.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    height: 18.75rem;
  }
`;
export const Img = styled.img`
  @media (min-width: ${Breakpoints.md}px) and (max-width: ${Breakpoints.xlg}px) {
    width: 18.125rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    width: 100%;
  }
  height: 100%;
`;
export const BottomBlockWrapper = styled.div`
  border: 1px solid ${colorVariables.borderBookBlockColor};
  display: flex;
  width: 100%;

  @media (min-width: ${Breakpoints.xlg}px) {
    flex-wrap: wrap;
  }
`;

export const ImgBottomBlock = styled.div`
  flex: 1;

  @media (min-width: ${Breakpoints.xlg}px) {
    height: 25rem;
  }
`;

export const TextBlockWrapper = styled.div`
  padding: 1.25rem;
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.68rem;
    flex: 1;
  }
`;

export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.8;
  color: ${colorVariables.footerReservedTitle};

  @media (min-width: ${Breakpoints.md}px) {
    max-width: 33.125rem;
  }
`;
