export const interiorNavItems = {
  services: 'Services',

  portfolio: 'Portfolio',
};

export const description = "Interior design is not only creativity, but also a whole range of services that turns ordinary square meters into comfort and harmony. A high-quality interior with thoughtful drawings, technical components, colors, and ecological furniture will help create a comfortable and functional space that will delight you and your loved ones for many years. It is an investment in your well-being and convenience that can increase your mood and efficiency, reduce stress and improve your quality of life. And we know the perfect formula."
export const currentText = {
  label: 'Our Interior Design Services',
  description:
    'The development of an interior concept is not only a creative process, but also a multifaceted one that includes many stages. We are ready to become a reliable guide for you and accompany you at every stage of implementation: from a beautiful picture in your head to the embodiment of your wildest dreams in the real life. Stages include:',
};
export const currentPortfolioText = {
  description:
    "Take a look at some of our past projects to see the range of styles and design solutions we've created for our clients. Whether you're looking for modern, traditional, or something in between, we can help you achieve your desired look and feel.",
};

export const servicesList = {
  planningText: 'Design concept development',
  colorText: 'Creation of planning decisions and sketches',
  furnitureText: 'Selection of materials and finishing elements',
  designText: 'Development of electrical and lighting circuits',
  windowText: 'Create 3D visualization',
  artText: 'Complete set with furniture, fittings and decor',
};