export const investmentsProcess = [
  {
    number: 1,
    label: 'Скринінг',
    description:
      ' Ми використовуємо ряд інструментів і методів перевірки, щоб визначити потенційні інвестиційні можливості.',
  },
  {
    number: 2,
    label: 'Дослідження',
    description:
      ' Ми проводимо поглиблені фундаментальні дослідження компаній і галузей, у які плануємо інвестувати.',
  },
  {
    number: 3,
    label: 'Аналіз',
    description:
      'Ми аналізуємо фінансову звітність, управлінські команди, конкурентний ландшафт та інші ключові фактори, які сприяють успіху компаній, у які ми плануємо інвестувати.',
  },
  {
    number: 4,
    label: 'Оцінка',
    description:
      ' Ми використовуємо низку методів оцінки, щоб визначити внутрішню вартість компаній, у які плануємо інвестувати.',
  },

  {
    number: 5,
    label: 'Управління ризиками',
    description:
      ' Ми використовуємо дисциплінований підхід до управління ризиками, зосереджуючись на збереженні капіталу та уникненні постійних втрат.',
  },

  {
    number: 6,
    label: ' Інвестиційні пакети',
    description:
      ' Наш інвестиційний процес базується на суворому та дисциплінованому підході до досліджень та аналізу. Ми віримо, що глибокі дослідження та аналіз є ключовими для визначення високоякісних інвестиційних можливостей та ефективного управління ризиками.',
  },
];

export const investmentsProcessTexts = {
  label: ' Наш інвестиційний процес',
  description:
    ' Наш інвестиційний процес базується на суворому та дисциплінованому підході до досліджень та аналізу. Ми віримо, що глибокі дослідження та аналіз є ключовими для визначення високоякісних інвестиційних можливостей та ефективного управління ризиками.',
};