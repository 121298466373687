export const solarEnergySetupsTexts = {
  label:
    ' Soluciones energéticas para satisfacer sus necesidades',
  description:
    'Los accesorios inteligentes para el hogar OSmart están diseñados para hacer que su hogar sea más conveniente, eficiente y agradable. Desde termostatos y sistemas de iluminación inteligentes hasta asistentes activados por voz y cámaras de seguridad, hay una variedad de accesorios disponibles para ayudarlo a automatizar y controlar diferentes aspectos de su hogar.',
};

export const setupList = {
  typeBasic: 'Básico',
  nameBasic: ' Configuración básica',

  descriptionBasic:
    ' Nuestra configuración básica incluye paneles solares, una batería y un inversor para convertir la energía recolectada por los paneles en electricidad utilizable. Esta configuración es ideal para hogares más pequeños con menores necesidades de consumo de energía.',

  typeStandart: 'Estándar',
  nameStandard: ' Configuración estándar',

  descriptionStandard:
    'Nuestra configuración estándar incluye paneles y baterías adicionales para aumentar el almacenamiento y la producción de energía. Esta configuración es ideal para hogares medianos con necesidades de consumo de energía promedio.',

  typePremium: ' Premium',
  namePremium: ' configuración premium',

  descriptionPremium:
    ' Nuestra configuración premium incluye paneles, baterías e inversores de primer nivel con sistemas avanzados de monitoreo y control. Ideal para hogares más grandes y propiedades comerciales con alto consumo de energía.',
};
