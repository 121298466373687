import {
  TopNavbarTitles as TopNavbarTitlesUa,
  navBarItemTitlesArray as navBarItemTitlesUaArray,
  mobileNavBarItemTitlesArray as mobileNavBarItemTitlesUaArray,
} from '../pages/MainPage/TopNavBar/ua';
import { HeaderTitles as HeaderTitlesUa } from '../pages/MainPage/HeaderSection/ua';
import { SliderTitles as SliderTitlesUa } from '../pages/MainPage/SliderSection/ua';
import { ourStoryTexts as ourStoryTextsUa } from '../pages/MainPage/OurStoryBlock/ua';
import {
  servicesTexts as SolarServicesTextsUa,
  servicesList as SolartServicesListUa,
} from '../pages/SolarEnergyPage/ServicesSection/ua';
import {
  ourValuesTitles as ourValuesTitlesUa,
  ourValuesList as ourValuesListUa,
} from '../pages/MainPage/OurValuesBlock/ua';
import {
  MenuItemsTitles as MenuItemsTitlesUa,
  MenuItemsDescriptions as MenuItemsDescriptionsUa,
} from '../pages/MainPage/MenuSection/ua';
import {modularHousesStepSection as ModularHousesStepSectionUa} from '../pages/ModularHousesPage/StepsBlock/ua'
import { AboutUsTitles as AboutUsTitlesUa } from '../pages/MainPage/AboutSection/ua';
import { ContactsTitles as ContactsTitlesUa } from '../pages/MainPage/ContactsSection/ua';
import {
  Construction as ConstructionUa,
  Projects as ProjectsUa,
  ModularHouses as ModularHousesUa,
  Architect as ArchitectUa,
  Interior as InteriorUa,
  SwimmingPools as SwimmingPoolsUa,
  SmartHome as SmartHomeUa,
  Investments as InvestmentsUa,
  SolarEnergy as SolarEnergyUa,
  Inmobiliaria as InmobiliariaUa,
  Consulting as ConsultingUa,
  LandscapeDesign as LandscapeDesignUa,
} from '../pages/MainPage/FAQSection/ua';
import {
  architectStepBlockTitle as ArchitectStepBlockTitleUa,
  architectStepBlockDescription as ArchitectStepBlockDescriptionUa,
  architectStepSection as ArchitectStepSectionUa,
} from '../pages/ArchitectPage/StepsSection/ua';
import {
  footerNavBar as footerNavBarUa,
  footerTitles as footerTitlesUa,
} from '../pages/MainPage/FooterSection/ua';
import { contactsHeaderTitles as contactsHeaderTitlesUa } from '../pages/ContactsPage/ContactsHeaderSection/ua';
import {
  HeaderSection as HeaderSectionUa,
  description as SmartHomeDescriptionUa,
} from '../pages/SmartHomePage/HeaderSection/ua';
import { PartnersSection as PartnersSectionUa } from '../pages/SmartHomePage/PartnersSection/ua';
import { CardsSection as CardsSectionUa } from '../pages/SmartHomePage/CardsSection/ua';
import { HeaderSection as SwimmingPoolsHeaderSectionUa } from '../pages/SwimmingPoolsPage/HeaderSection/ua';
import { PoolsSection as SwimmingPoolsPoolsSectionUa } from '../pages/SwimmingPoolsPage/PoolsSection/ua';
import { ColorsSection as SwimmingPoolsColorsSectionUa } from '../pages/SwimmingPoolsPage/ColorsSection/ua';
import { PoolsAccessoriesSection as SwimmingPoolsAccessoriesSectionUa } from '../pages/SwimmingPoolsPage/AccessoriesSection/ua';
import { TechnologiesSection as SwimmingPoolsTechnologiesSectionUa } from '../pages/SwimmingPoolsPage/TechnologiesSection/ua';
import {
  AccessoriesSection as AccessoriesSectionUa,
  accessoriesIcons as AccessoriesIconsUa,
} from '../pages/SmartHomePage/AccessoriesSection/ua';
import { SmartHomeStepSection as SmartHomeStepsSectionUa } from '../pages/SmartHomePage/StepsBlockSection/ua';
import { MainSection as MainSectionUa } from '../pages/ServicesPage/ua';
import {
  investmentsNavItems as InvestmentsNavItemsUa,
  investmentsSecondDescription as InvestmentsSecondDescriptionUa,
} from '../pages/InvestmentsPage/HeaderSection/ua';
import {
  investmentsProcess as InvestmentsProcessUa,
  investmentsProcessTexts as investmentsProcessTextsUa,
} from '../pages/InvestmentsPage/InvestmentsProcess/ua';
import {
  philosophyTextsList as PhilosophyTextsListUa,
  philosophyTitle as PhilosophyTitleUa,
} from '../pages/InvestmentsPage/InvestmentsPhilosophySection/ua';
import {
  packagesTexts as packagesTextsUa,
  packagesBlocks as packagesBlocksUa,
} from '../pages/InvestmentsPage/InvestmentsPackagesSection/ua';
import {
  teamTexts as TeamTextsUa,
  teamBlocks as TeamBlocksUa,
} from '../pages/InvestmentsPage/InvestmentsTeamSection/ua';
import {
  realEstateNavItems as RealEstateNavItemsUa,
  realEstateSecondDescription as RealEstateSecondDescriptionUa,
} from '../pages/RealEstatePage/HeaderSection/ua';
import {
  realEstateProcessTexts as RealEstateProcessTextsUa,
  processList as ProcessListUa,
  servicesTexts as RealEstateServicesTextsUa,
  servicesList as RealEstateServicesListUa,
} from '../pages/RealEstatePage/ServicesSection/ua';
import { aboutListText as AboutListTextUa } from '../pages/RealEstatePage/AboutSection/ua';
import {
  navItems as ConsultingNavItemsUa,
  consultingProcessTexts as ConsultingProcessTextsUa,
  processList as ConsultingProcessListUa,
  description as ConsultingDescriptionUa,
} from '../pages/ConsultingPage/ua';
import {
  discoverList as DiscoverListUa,
  realEstateDiscoverTexts as RealEstateDiscoverTextsUa,
  aboutDiscoverList as AboutDiscoverListUa,
  aboutText as AboutTextUa,
} from '../pages/RealEstatePage/DiscoverSection/ua';
import {
  aboutUsNavItems as AboutUsNavItemsUa,
  aboutUsSecondDescription as AboutUsSecondDescriptionUa,
  aboutUsTitle as AboutUsTitleUa,
} from '../pages/AboutUsPage/HeaderSection/ua';
import {
  professionList as ProfessionListUa,
  pageTitle as PageTitleUa,
} from '../pages/AboutUsPage/OurTeamSection/ua';
import {
  pageTitle as OurStoryPageTitleUa,
  pageAfterTitle as OurStoryPageAfterTitleUa,
} from '../pages/AboutUsPage/OurStoryMobileBlock/ua';
import { projectsItems as ProjectsItemsUa } from '../pages/ProjectsPage/HeaderSection/ua';
import { projectsList as ProjectsListUa } from '../pages/ProjectsPage/ProjectsSection/ua';
import {
  headMenu as HeadMenuUa,
  typeHouseStyle as TypeHouseStyleUa,
  inputsTexts as InputsTextsUa,
  searchBtn as SearchBtnUa,
  removeAll as RemoveAllUa,
} from '../pages/ProjectsPage/FilterSection/ua';
import {
  headerSection as SolarHeaderSectionUa,
  description as SolarEnergyDescriptionUa,
} from '../pages/SolarEnergyPage/HeaderSection/ua';
import {
  solarEnergySetupsTexts as SolarEnergySetupsTextsUa,
  setupList as SetupListUa,
} from '../pages/SolarEnergyPage/SetupsSection/ua';

import {
  solarEnergyBenefitsTexts as SolarEnergyBenefitsTextsUa,
  benefitsList as BenefitsListUa,
} from '../pages/SolarEnergyPage/BenefitsSection/ua';
import { partnersSection as SolarPartnersSectionUa } from '../pages/SolarEnergyPage/PartnersSection/ua';
import {
  architectNavItems as ArchitectNavItemsUa,
  architectSecondDescription as ArchitectSecondDescriptionUa,
} from '../pages/ArchitectPage/HeaderSection/ua';
import {
  currentTexts as CurrentTextsUa,
  currentPortfolioTexts as CurrentPortfolioTextsUa,
  processList as ProcessListArchitectUa,
} from '../pages/ArchitectPage/ProcessSection/ua';
import {
  projectsName as ProjectsNameUa,
  portfolioText as PortfolioTextUa,
} from '../pages/ArchitectPage/PortfolioSection/ua';
import {
  aboutListLocation as AboutListLocationUa,
  infoText as InfoTextUa,
  mainText as MainTextUa,
  headerBtnText as HeaderBtnTextUa,
  titleAbout as TitleAboutUa,
  textsAbout as TextsAboutUa,
  textPhoto as TextPhotoUa,
  view as ViewBtnTextUa,
  housesDetailsMobile as HousesDetailsMobileUa,
  housesDetailsDesktop as HousesDetailsDesktopUa,
  photoTitle as PhotoTitleUa,
} from '../pages/InteriorCasePage/ua';
import {
  navItems as ConstructionNavItemsUa,
  description as ConstructionDescriptionUa,
  processList as ConstructionProcessListUa,
  aboutTexts as ConstructionAboutTextsUa,
  preConstructionTitle as PreConstructionTitleUa,
  preConstructionText as PreConstructionTextUa,
  constructionStepBlockTitle as ConstructionStepBlockTitleUa,
  constructionStepBlockDescription as ConstructionStepBlockDescriptionUa,
  constructionStepSection as ConstructionStepSectionUa,
} from '../pages/ConstructionPage/ua';
import {
  navItems as LandscapeDesignNavItemsUa,
  description as LandscapeDesignDescriptionUa,
  currentTexts as LandscapeCurrentTextsUa,
  processList as LandscapeProcessListUa,
  currentTextsBenefits as CurrentTextsBenefitsUa,
  textsBenefits as TextsBenefitsUa,
} from '../pages/LandscapeDesignPage/ua';
import {
  currentTexts as BuildingCurrentTextsUa,
  textsBenefits as BuildingTextsBenefitsUa,
  benefitsTitle as BuildingBenefitsTitleUa,
  materialsList as BuildingMaterialsListUa,
} from '../pages/BuildingMaterialsPage/ua';
import {
  interiorNavItems as InteriorNavItemsUa,
  currentText as InteriorCurrentTextUa,
  currentPortfolioText as InteriorCurrentPortfolioTextUa,
  servicesList as InteriorServicesListUa,
  description as InteriorDescriptionUa,
} from '../pages/InteriorPage/ua';
import { FavoritesSection as FavoritesSectionUa } from '../pages/FavoritesPage/ua';
import { loginPage as LoginPageUa } from '../pages/LoginPage/ua';
import {
  modularHousesNavItems as ModularHousesNavItemsUa,
  description as ModularHousesDescriptionUa,
} from '../pages/ModularHousesPage/HeaderSection/ua';
import {
  typeOfHouses as TypeOfHousesUa,
  modelTitle as ModelTitleUa,
  modelDescription as ModelDescriptionUa,
} from '../pages/ModularHousesPage/ModelSection/ua';
import {
  currentTexts as CurrentModularHousesTextsUa,
  options as OptionsUa,
} from '../pages/ModularHousesPage/OptionsSection/ua';
import {
  blockTitle as BlockTitleUa,
  blockDescription as BlockDescriptionUa,
} from '../pages/ModularHousesPage/StepsBlock/ua';

import {
  title as TopServicesTitleUa,
  allServices as AllServicesUa,
  description as ServicesDescriptionUa,
} from '../pages/MainPage/TopServicesSection/ua';
import { ClientPartnerNavbarItems as ClientPartnerNavbarItemsUa } from '../pages/LeadFormPage/ua';
import { MainNavbarItems as MainNavbarItemsUa } from '../pages/LeadFormPage/ua';
import { LeadFormPage as LeadFormPageUa } from '../pages/LeadFormPage/ua';
import { cookiesPopup as CookiesPopupUa } from '../components/CookiesPopup/ua';
import { swimmingPoolsStepsSection as SwimmingPoolsStepsSectionUa } from '../pages/SwimmingPoolsPage/StepsBlockSection/ua'
import { StubProjectsPageTexts as StubProjectsPageTextsUa } from '../pages/StubProjectsPage/ua'
import {conditionsText as ConditionsTextUa} from '../pages/ConditionsPage/ua'
import {privacyText as PrivacyTextUa} from '../pages/PrivacyPage/ua'
export const ua = {
  translation: {
    mainPage: {
      TopNavbarTitles: TopNavbarTitlesUa,
      navBarItemTitlesArray: navBarItemTitlesUaArray,
      mobileNavBarItemTitlesArray: mobileNavBarItemTitlesUaArray,
      headerTitles: HeaderTitlesUa,
      sliderTitles: SliderTitlesUa,
      MenuItemsTitles: MenuItemsTitlesUa,
      MenuItemsDescriptions: MenuItemsDescriptionsUa,
      AboutUsTitles: AboutUsTitlesUa,
      ourStoryTexts: ourStoryTextsUa,
      ourValuesList: ourValuesListUa,
      ourValuesTitles: ourValuesTitlesUa,
      ContactsTitles: ContactsTitlesUa,
      topServicesTitle: TopServicesTitleUa,
      allServices: AllServicesUa,
      servicesDescription: ServicesDescriptionUa,
      FAQ: {
        LandscapeDesign: LandscapeDesignUa,
        Construction: ConstructionUa,
        Projects: ProjectsUa,
        ModularHouses: ModularHousesUa,
        Architect: ArchitectUa,
        Interior: InteriorUa,
        SwimmingPools: SwimmingPoolsUa,
        SmartHome: SmartHomeUa,
        Investments: InvestmentsUa,
        SolarEnergy: SolarEnergyUa,
        Inmobiliaria: InmobiliariaUa,
        Consulting: ConsultingUa,
      },
      footerNavBar: footerNavBarUa,
      footerTitles: footerTitlesUa,
    },
    contactsPage: {
      contactsHeaderTitles: contactsHeaderTitlesUa,
    },
    smartHome: {
      HeaderSection: HeaderSectionUa,
      PartnersSection: PartnersSectionUa,
      CardsSection: CardsSectionUa,
      AccessoriesSection: AccessoriesSectionUa,
      SmartHomeStepsSection: SmartHomeStepsSectionUa,
      SmartHomeDescription: SmartHomeDescriptionUa,
      AccessoriesIcons: AccessoriesIconsUa,
    },
    services: {
      MainSection: MainSectionUa,
    },
    investmentsPage: {
      investmentsNavItems: InvestmentsNavItemsUa,
      investmentsSecondDescription: InvestmentsSecondDescriptionUa,
      investmentsProcess: InvestmentsProcessUa,
      investmentsProcessTexts: investmentsProcessTextsUa,
      philosophyTextsList: PhilosophyTextsListUa,
      philosophyTitle: PhilosophyTitleUa,
      packagesTexts: packagesTextsUa,
      packagesBlocks: packagesBlocksUa,
      teamTexts: TeamTextsUa,
      teamBlocks: TeamBlocksUa,
    },
    realEstatePage: {
      realEstateNavItems: RealEstateNavItemsUa,
      realEstateSecondDescription: RealEstateSecondDescriptionUa,
      realEstateProcessTexts: RealEstateProcessTextsUa,
      processList: ProcessListUa,
      aboutListText: AboutListTextUa,
      discoverList: DiscoverListUa,
      realEstateDiscoverTexts: RealEstateDiscoverTextsUa,
      aboutDiscoverList: AboutDiscoverListUa,
      aboutText: AboutTextUa,
      servicesTexts: RealEstateServicesTextsUa,
      servicesList: RealEstateServicesListUa,
    },
    consultingPage: {
      consultingNavItems: ConsultingNavItemsUa,
      consultingProcessTexts: ConsultingProcessTextsUa,
      consultingProcessList: ConsultingProcessListUa,
      consultingDescription: ConsultingDescriptionUa,
    },
    aboutUsPage: {
      aboutUsNavItems: AboutUsNavItemsUa,
      aboutUsSecondDescription: AboutUsSecondDescriptionUa,
      aboutUsTitle: AboutUsTitleUa,
      professionList: ProfessionListUa,
      pageTitle: PageTitleUa,
      ourStoryPageTitle: OurStoryPageTitleUa,
      ourStoryPageAfterTitle: OurStoryPageAfterTitleUa,
    },
    projectsPage: {
      projectsItems: ProjectsItemsUa,
      headMenu: HeadMenuUa,
      typeHouseStyle: TypeHouseStyleUa,
      inputsTexts: InputsTextsUa,
      projectsList: ProjectsListUa,
      searchBtn: SearchBtnUa,
      removeAll: RemoveAllUa,
    },
    solarEnergyPage: {
      headerSection: SolarHeaderSectionUa,
      solarEnergyBenefitsTexts: SolarEnergyBenefitsTextsUa,
      solarEnergyDescription: SolarEnergyDescriptionUa,
      benefitsList: BenefitsListUa,
      partnersSection: SolarPartnersSectionUa,
      solarEnergySetupsTexts: SolarEnergySetupsTextsUa,
      setupList: SetupListUa,
      servicesTexts: SolarServicesTextsUa,
      servicesList: SolartServicesListUa,
    },
    architectPage: {
      architectNavItems: ArchitectNavItemsUa,
      architectSecondDescription: ArchitectSecondDescriptionUa,
      currentTexts: CurrentTextsUa,
      currentPortfolioTexts: CurrentPortfolioTextsUa,
      processList: ProcessListArchitectUa,
      projectsName: ProjectsNameUa,
      portfolioText: PortfolioTextUa,
      architectStepBlockTitle: ArchitectStepBlockTitleUa,
      architectStepBlockDescription: ArchitectStepBlockDescriptionUa,
      architectStepSection: ArchitectStepSectionUa,
    },
    interiorCasePage: {
      aboutListLocation: AboutListLocationUa,
      infoText: InfoTextUa,
      mainText: MainTextUa,
      headerBtnText: HeaderBtnTextUa,
      titleAbout: TitleAboutUa,
      textsAbout: TextsAboutUa,
      textPhoto: TextPhotoUa,
      viewBtnText: ViewBtnTextUa,
      housesDetailsMobile: HousesDetailsMobileUa,
      housesDetailsDesktop: HousesDetailsDesktopUa,
      photoTitle: PhotoTitleUa,
    },
    interiorPage: {
      interiorNavItems: InteriorNavItemsUa,
      interiorCurrentText: InteriorCurrentTextUa,
      interiorCurrentPortfolioText: InteriorCurrentPortfolioTextUa,
      interiorServicesList: InteriorServicesListUa,
      interiorDescription: InteriorDescriptionUa,
    },
    constructionPage: {
      constructionNavItems: ConstructionNavItemsUa,
      constructionDescription: ConstructionDescriptionUa,
      constructionProcessList: ConstructionProcessListUa,
      constructionAboutTexts: ConstructionAboutTextsUa,
      preConstructionTitle: PreConstructionTitleUa,
      preConstructionText: PreConstructionTextUa,
      constructionStepBlockTitle: ConstructionStepBlockTitleUa,
      constructionStepBlockDescription: ConstructionStepBlockDescriptionUa,
      constructionStepSection: ConstructionStepSectionUa,
    },
    landscapeDesignPage: {
      landscapeDesignNavItems: LandscapeDesignNavItemsUa,
      landscapeDesignDescription: LandscapeDesignDescriptionUa,
      landscapeCurrentTexts: LandscapeCurrentTextsUa,
      landscapeProcessList: LandscapeProcessListUa,
      currentTextsBenefits: CurrentTextsBenefitsUa,
      textsBenefits: TextsBenefitsUa,
    },

    buildingMaterialsPage: {
      buildingCurrentTexts: BuildingCurrentTextsUa,
      buildingTextsBenefits: BuildingTextsBenefitsUa,
      buildingBenefitsTitle: BuildingBenefitsTitleUa,
      buildingMaterialsList: BuildingMaterialsListUa,
    },
    swimmingPools: {
      HeaderSection: SwimmingPoolsHeaderSectionUa,
      PoolsSection: SwimmingPoolsPoolsSectionUa,
      ColorsSection: SwimmingPoolsColorsSectionUa,
      TechnologiesSection: SwimmingPoolsTechnologiesSectionUa,
      AccessoriesSection: SwimmingPoolsAccessoriesSectionUa,
      SwimmingPoolsStepsSection: SwimmingPoolsStepsSectionUa,
    },

    loginPage: {
      loginPage: LoginPageUa,
    },

    favorites: {
      FavoritesSection: FavoritesSectionUa,
    },
    modularHousesPage: {
      modularHousesNavItems: ModularHousesNavItemsUa,
      modelTitle: ModelTitleUa,
      modelDescription: ModelDescriptionUa,
      typeOfHouses: TypeOfHousesUa,
      currentTexts: CurrentModularHousesTextsUa,
      options: OptionsUa,
      blockTitle: BlockTitleUa,
      blockDescription: BlockDescriptionUa,
      description: ModularHousesDescriptionUa,
      modularHousesStepSection: ModularHousesStepSectionUa,
    },
    leadForm: {
      clientPartnerNavbarItems: ClientPartnerNavbarItemsUa,
      mainNavbarItems: MainNavbarItemsUa,
      leadFormPage: LeadFormPageUa,
    },
    cookiesPopup: {
      cookiesPopup: CookiesPopupUa,
    },
     stubProjectsPage: {
      stubProjectsPageTexts: StubProjectsPageTextsUa,
    },
     conditionsPage: {
      conditionsText: ConditionsTextUa,
    },
     privacyPage: {
      privacyText: PrivacyTextUa,
    }
  },
};
