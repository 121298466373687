import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../theme/Theme.constants';
import logo from '../../shared/assets/icons/logo.svg';
import { MenuList } from '../MenuListBlock/MenuListBlock.styled';

export const NavBurgerWrapper = styled.div``;

export const BurgerMenuButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none;
  cursor: pointer;
  padding: 0.75rem 0.25rem;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${colorVariables.blockBorderColor};
`;

export const BurgerMenuLine = styled.div<{ isOpen: boolean }>`
  width: 25px;
  height: 1px;
  background: ${colorVariables.mainTextColor};
  transition: all 0.2s ease-in-out;

  &:nth-child(1) {
    top: 0;
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(45deg) translate(4.5px, 5px)' : 'none'};
  }

  &:nth-child(2) {
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
  }

  &:nth-child(3) {
    transform: ${({ isOpen }) =>
      isOpen ? 'rotate(-45deg) translate(4px, -5px)' : 'none'};
  }
`;

export const BurgerMenuContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  overflow-y: auto;
  top: 3.75rem;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 3.75rem);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: all 0.3s ease;
  background: ${colorVariables.popupMenuBackground};
  &::-webkit-scrollbar {
    width: 0;
  }
  ${MenuList} {
    margin-bottom: 3.375rem;
  }
`;

export const BurgerMenuWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 3.75rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MenuHeader = styled.div`
  postion: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 3.75rem;
`;

export const LogoImg = styled.div`
  width: 3.375rem;
  height: 3rem;
  background-image: url(${logo});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const LogoTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 2rem;
  color: ${colorVariables.mainTextColor};
  cursor: pointer;

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const NavbarMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
`;

export const NavbarMenuItem = styled.div`
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 1.25rem;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  background-color: ${colorVariables.backgroundMenuItemColor};
  cursor: pointer;
  transition: ${transitions.backgroundHoverTransition};
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;

  &:first-child {
    border-top: 1px solid ${colorVariables.blockBorderColor};
  }

  &:hover {
    background-color: inherit;
  }
`;
