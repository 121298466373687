export const MainSection = {
  services: 'Послуги',
  сonstruction: 'Будівництво',
  projects: 'Проекти',
  interior: `Інтер'єр`,
  modularHouses: 'Модульні будинки',
  architect: 'Архітектор',
  consulting: 'Консультації',
  solarEnergy: 'Сонячна енергія',
  inmobiliaria: 'Нерухомість',
  investments: 'Інвестиції',
  swimmingPools: 'Басейни',
  smartHome: 'Розумний будинок',
  landscapeDesign: 'Ландшафтний дизайн',
  buildingMaterials: 'Будівельні матеріали',
};
