import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';
import { PartnersMobile } from './MobileBlock';
import { PartnersTabletSection } from './TabletBlock';

export const PartnersSection: FC = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  return (
    <>
      {isMobile ? (
        <PartnersMobile />
      ) : (
        <PartnersTabletSection
          description={t('smartHome.PartnersSection.description')}
        />
      )}
    </>
  );
};
