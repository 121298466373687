import styled from 'styled-components';
import { Button } from '../../../shared/components/BluredButton/BluredButton.styled';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../theme/Theme.constants';

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.75rem;
  width: 100%;
  background: ${colorVariables.blackBackgroundColor};
  border-top: 1px solid ${colorVariables.contactsHeaderBorderColor};
  backdrop-filter: blur(2px);
  padding: 0.625rem 1.25rem;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 1rem 2.5rem;
  }
  @media only screen and (min-width: ${Breakpoints.lg}px) {
    margin-top: 2.76rem;
    padding: 1.25rem 6.25rem;
  }
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-left: 1rem;
  color: ${colorVariables.mainTextColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding-right: 1.25rem;
    border-right: 1px solid ${colorVariables.additionalBorder};
    margin-right: 1.25rem;
  }
`;

export const RightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  ${Button} {
    padding: 0.5rem 0.675rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${colorVariables.alternativeTextColor};
    background-color: ${colorVariables.yellowButtonBackground};
    border: 1px solid ${colorVariables.yellowButtonBorder};
    transition: ${transitions.allHoverTransition};

    &:hover {
      @media only screen and (min-width: ${Breakpoints.md}px) {
        background: none;
        border: 1px solid ${colorVariables.buttonBorder};
        color: ${colorVariables.mainTextColor};
      }
    }

    @media only screen and (min-width: ${Breakpoints.md}px) {
      padding: 0.5rem 2.5rem;
    }
  }
`;

export const DropDownWrapper = styled.div`
  position: relative;
`;

export const ToogleWrapper = styled.div<{ isOpenFilter: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  & .drop-down-icon {
    fill: ${colorVariables.mainTextColor};
    rotate: ${props => (props.isOpenFilter ? '180deg;' : '0;')};
  }
`;
export const DropDownTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-right: 0.312rem;
  color: ${colorVariables.dropDownTitleColor};
`;

export const EmptyHeart = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
`;

export const FilterList = styled.ul`
  display: flex;
`;

export const FilterItem = styled.li`
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 1.875rem;
  }
`;

export const FilterName = styled.p<{ currentTypeName: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: ${colorVariables.mainTextColor};
  transition: ${transitions.allHoverTransition};
  opacity: ${props => (props.currentTypeName ? '1;' : '0.6;')};

  .translated & {
    font-size: 13px;
  }
  &:hover {
    opacity: 1;
  }
`;
