import React, { FC } from 'react';
import { BluredButton } from '../../../shared/components/BluredButton';
import { HeaderTitlesEn } from '../HeaderSection.constatnts';
import {
  TopBlock,
  Block,
  MiddleBlock,
  BottomBlock,
  LogoBlock,
  LogoImg,
  LogoTitle,
  SloganBlock,
  DescriptionBlock,
} from './HeaderDesktop.styled';
import { HeaderMenuComponent } from '../../HeaderMenuComponent';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../../../shared/helpers/routes';
import { mainSection } from '../../../shared/helpers/globalConstants';
import { useNavigate } from 'react-router';
import { setLeadFormTitle } from '../../../redux/actions/globalActions';
import { useDispatch } from 'react-redux';
import { getClientPartnerNavigationItems } from '../../../scenes/LeadForm/LeadForm.constants';

export interface HeaderDesktopProps {
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
}

export const HeaderDesktop: FC<HeaderDesktopProps> = ({
  setActiveId,
  activeId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientPartnerNavigationItems = getClientPartnerNavigationItems();

  const handleNavBarSectionClick = (routePath: string, formTitle: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
    dispatch(setLeadFormTitle(formTitle));
  };
  return (
    <>
      <TopBlock isActive={activeId}>
        <Block>
          {/* <BluredButton
            title={t('mainPage.headerTitles.HOW_WE_WORK_BUTTON')}    // LOGIC FOR FUTURE FEATURES
            handleClick={() => {}}
            className="blured-button"
          /> */}
        </Block>
        <LogoBlock>
          <LogoImg />
          <LogoTitle>{HeaderTitlesEn.LOGO_TEXT}</LogoTitle>
        </LogoBlock>
        <Block />
      </TopBlock>
      <MiddleBlock isActive={activeId}>
        <SloganBlock
          dangerouslySetInnerHTML={{
            __html: (t('mainPage.headerTitles.SLOGAN_TEXT') || '') as string, // added to make translation and indentation(br) work
          }}></SloganBlock>
        <HeaderMenuComponent setActiveId={setActiveId} activeId={activeId} />
      </MiddleBlock>
      <BottomBlock isActive={activeId}>
        <DescriptionBlock>
          {t('mainPage.headerTitles.HEADER_DESCRIPTION')}
        </DescriptionBlock>
        <Block>
          <BluredButton
            title={t('mainPage.headerTitles.CLIENT_BUTTON')}
            className="blured-button"
            handleClick={() =>
              handleNavBarSectionClick(
                RoutePaths.leadForm,
                clientPartnerNavigationItems[0].title,
              )
            }
          />
        </Block>
        <Block>
          <BluredButton
            title={t('mainPage.headerTitles.PARTNER_BUTTON')}
            handleClick={() =>
              handleNavBarSectionClick(
                RoutePaths.leadForm,
                clientPartnerNavigationItems[1].title,
              )
            }
            className="blured-button"
          />
        </Block>
      </BottomBlock>
    </>
  );
};
