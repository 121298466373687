import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: fit-content;
  background: ${colorVariables.mainBackgroundColor};

  @media only screen and (max-height: ${Breakpoints.sm}px) {
    height: 120vh;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: ${paddings.tabletPagePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
    height: 100vh;
  }
`;
export const TopBlock = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  height: calc(100% / 3);
  width: 100%;
  border: 1px solid ${colorVariables.blockBorderColor};
  border-right: none;
`;

export const MiddleBlock = styled(TopBlock)`
  border-top: none;
  grid-template-columns: 1fr 1fr;
  height: calc(100% / 9);
`;

export const BottomBlock = styled(TopBlock)`
  border-top: none;
  grid-template-columns: 1fr 1fr;
  height: calc(100% / 4.5);
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${colorVariables.blockBorderColor};

  &:first-child {
    border-left: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const MainTitleBlock = styled(Block)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 100%;
  text-transform: uppercase;
  color: ${colorVariables.secondaryTextColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 2.5rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 3.75rem;
    line-height: 4.625rem;
  }
`;

export const ContactsBlock = styled(Block)`
  font-size: 0.75rem;
  line-height: 1rem;

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const BlockTitle = styled(Block)`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: ${colorVariables.secondaryTextColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
`;

export const IconsWrapper = styled(Block)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: ${Breakpoints.md}px) {
  }
`;

export const IconsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  height: 3.75rem;
`;
export const NetworkIconWrapper = styled.a`
  cursor: pointer;
`;
export const NetworkIcon = styled.img`
  width: 3.75rem;
  height: 100%;

  transition: ${transitions.opacityTransitions};

  &:hover {
    opacity: 0.5;
  }
`;

export const FormWrapper = styled(Block)`
  display: block;
  height: 100%;
  min-height: fit-content;

  .form {
    height: 100%;
  }
`;

export const FormBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const InputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .bottom-field {
    border-bottom: none;
  }
`;

export const FormControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 3.375rem;
  padding: 0;
  border: none;
  background: rgba(255, 255, 255, 0.15);
  border-left: 1px solid ${colorVariables.blockBorderColor};
`;

export const ButtonTitle = styled.button`
  transform: rotate(270deg);
  border: none;
  cursor: pointer;
  background: none;
  color: ${colorVariables.mainTextColor};
`;

export const ErrorMesage = styled.div`
  position: absolute;
  bottom: 0.25rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${colorVariables.errorColor};
  left: 1.25rem;
  width: 100%;
  height: 100%
  z-index: 20;
`;

export const TextFieldWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const PopupWrapper = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
`;

export const Popup = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid
    ${props =>
      props.isError ? colorVariables.errorColor : colorVariables.succesColor};
  width: 90%;
  height: 14.062rem;
  background: ${colorVariables.alternativeBackground};
  padding: 0 6.75rem;

  @media (min-width: ${Breakpoints.md}px) {
    width: 36.25rem;
  }

  & button {
    margin-top: 1.25rem;
    width: fit-content;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    background: rgba(255, 230, 98, 0.1);
    border: 1px solid ${colorVariables.yellowButtonBorder};
    color: ${colorVariables.alternativeTextColor};
  }
`;

export const PopupText = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 1.25rem;
`;
