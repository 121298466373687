export const LandscapeDesign = [
  {
    id: 1,
    title: 'Landscaping: important element or whim? ',
    description: `Spanish-style houses, especially in the Mediterranean climate of Catalonia, go hand in hand with creating unique gardens. Here, certain features of local landscape art were formed, which still influence the design of gardens in the area. One of the characteristic features is the use of fresh flowers to decorate the house's facade. Potted or climbing plants are often used, which give the house a special charm and beauty. An important element is the creation of a corner for a pleasant pastime with friends and relatives. It can be a decorated place for a feast or relaxation, where you can enjoy the atmosphere and communication. A popular traditional element is the patio - a cozy patio with shady seating and comfortable furniture. This is a place where you can enjoy the fresh air and privacy inside your own garden. The abundance and variety of plants of all kinds in Catalan gardens, from mosses to climbers, is welcomed. Here you can see potted and container plants that add bright accents and create interesting compositions. Landscape design is an integral part of the real estate market in Spain. The exterior helps increase the property's price and demonstrate the homeowner's individuality. 
`,
  },
  {
    id: 2,
    title:
      'How long does it usually take to develop and implement a landscape project? ',
    description: `Designing and implementing a landscape project is a creative and multifaceted process that takes time and attention to detail. The time frame for completing a project may vary depending on the scope, complexity, and specific requirements of the client.
Small landscaping projects, such as a small garden or terrace, can be designed and implemented in a few weeks or months. In these cases, the process includes:
* Drawing up a design plan.
* Selecting plants and materials.
* Performing planting and decoration work.
Medium projects, such as landscaping a suburban area or community property, may require several months of work. In this case, the process includes more detailed planning, consultation with the client, concept development, and 3D visualization, as well as the implementation of landscaping, architectural elements, and infrastructure.
Larger and more complex projects, such as landscaping public parks or complexes, may require years of work. In such cases, the process includes:
* Long-term planning.
* Master plan design.
* Participation in approvals with government agencies and other stakeholders.
* Phased implementation of the project.
Our experts will try to make the perfect interior for you as quickly as possible. Therefore, do not put it off until tomorrow; order the service today and find out the details of the terms of work. `,
  },
  {
    id: 3,
    title: 'Landscaping: do it yourself or use the services of professionals?',
    description: `The picture in your head sometimes matches the reality. Landscape design is the art of creating beautiful and functional spaces that bring joy and comfort. When realizing your dream of an ideal garden or plot, the question arises: do everything yourself or turn to professionals for help?
Doing your own landscaping can be an exciting and creative process that allows you to bring your own ideas and preferences to life. By doing everything yourself, you can save some money and have full control over the implementation process. This can be especially interesting for people with certain skills and knowledge in landscaping and design.
But landscape design is a complex and multifaceted process that requires deep knowledge of plants, soils, climatic conditions, and planning and composition experience. Improper planning or selection of plants can lead to unsatisfactory results and loss of time and money. Our professionals have the expertise and experience to create a unique and functional design, considering your wishes and the site's characteristics. They will be able to multiply the advantages and hide the disadvantages of the territory, use every square meter as competently as possible, and focus on the residents' comfort. They can also save you time and effort by efficiently using resources.`,
  },
  {
    id: 4,
    title:
      'Take care of the exterior yourself or use the services of specialists? ',
    description: `Once a project is completed, our landscape designers can offer their assistance and maintenance advice to help you maintain and maintain the beauty and health of your landscape for years to come. They can advise you on proper watering, fertilizing, pruning, and general plant care. After all, each plant requires its own unique approach, and professionals will help you determine the optimal care regimen.
In addition, landscape designers can advise on the maintenance and care of other elements of your garden or exterior, such as paths, fountains, and artificial ponds. They will share their knowledge with you on proper cleaning and maintaining these items so they always look attractive and safe. In addition, landscape designers can help you determine the seasonal work and maintenance that your garden requires. They will tell you how to prepare your garden for winter or summer, remove foliage properly, prune plants or carry out the necessary maintenance work.
If you want to save time, you can continue cooperating with us and we will help you maintain your garden. `,
  },
  {
    id: 5,
    title: 'Rolled lawns or sowing, what is better for the site?',
    description: `Here are a few questions that might help you decide:
1. What is the size of the plot? Rolled lawns are usually preferred for small to medium-sized areas, as they can be installed quickly and immediately give the garden a complete look. Seed lawns are best suited for larger areas where a longer seeding process may be justified.
2. How much time are you willing to spend creating a lawn? Sowing lawns need more time. Roll lawns provide fast results, ready to use after laying.
3. What is your budget? Seed lawns can be more economical, especially for large areas. Roll lawns are usually more expensive than seed lawns, but they provide more immediate results.
4. What is the climate in your area? Some types of turf may be more adapted to certain climatic conditions, such as dry or humid regions. Seed lawns can be more flexible in selecting grass varieties better adapted to your climate.
5. What aesthetic effect do you want to achieve? Rolled lawns usually have a more uniform and instantaneous appearance, whereas seeded lawns can create a more natural and progressive landscape.`,
  },

  {
    id: 6,
    title: 'What to choose, automatic watering or manual watering of plants?',
    description: `Automatic watering:
1. Automatic watering provides convenience, especially if you have a large garden or many plants. You can set the watering program, and the system will independently monitor the regular and uniform watering of plants without your presence.
2. Automatic watering saves time you would spend manually watering each plant.
3. Automatic watering systems are often equipped with moisture sensors that can detect when plants need watering and only supply water when needed. This helps to avoid wasted water and conserve it.
Manual watering:
1. Manual watering allows you to control the watering process and directly interact with the plants. You can better observe their condition and respond to any problems or needs.
2. Manual watering allows you to choose the places and amount of watering for each plant separately. You can tailor watering to the characteristics of each plant, its moisture needs and environmental conditions.
3. Manual irrigation does not require installing special equipment and system, which can be more cost-effective than automatic irrigation.
When choosing between automatic and manual plant watering, consider your garden size, number of plants, water availability, and plant care preferences. For additional consultations and detailed analysis, you can contact our specialists.`,
  },
];

export const Construction = [
  {
    id: 1,
    title: 'What types of private houses are ordered from us most often?',
    description: `In Spain, private housing attracts attention with its features, which differ from typical objects of this category in other EU countries. The following types of houses are popular here:
        1. Villas are spacious houses over 100 m², with spacious plots of land, swimming pools and relaxation areas.
        2. Chalets are compact houses up to 110 m² with small surrounding areas.
        In Spain, the traditional Mediterranean style or high-tech style is often chosen when designing private houses. These styles feature elements of Spanish architecture, such as arched windows, vaults, mosaics, colorful tiles, and more.`,
  },
  {
    id: 2,
    title: 'Are there building restrictions?',
    description: `When building private housing in Spain, two main parameters are taken into account: the maximum allowable height of the building and its dimensions. Although the legislation defines the stages of construction, the norms are often revised. A land plot intended for construction must have a special document - Ficha Urbanistica, which contains the main permissible characteristics of objects. Each region also has its own local regulations that govern aspects such as:
1. Maximum height of private objects;
2. Design of facades;
3. Roof style and other characteristics.
These standards are fixed in the construction permit, without which any construction is illegal.`,
  },
  {
    id: 3,
    title:
      'What factors should be considered when planning the interior space of a house?',
    description: `To plan the space perfectly, it is important to analyze all the potential characteristics of the future home. Our experts have prepared a list of recommendations for you:
1. The house's premises should be divided into functional areas (residential, utility, public, private).
2. Rational layout of zones and convenient connections will help increase comfort and reduce the use of corridors.
3. Residential and business areas should be separated from each other.
4. Auxiliary premises should be hidden but accessible at any time.
5. Private areas, such as bedrooms, are recommended to be isolated and hidden from public space in far corners.
6. Consider family composition, age groups, and lifestyle when planning your home.
7. Avoid crossing dirty and clean areas to prevent the spread of dirt. For example, a garage should have a separate exit.
8. Combine rooms of similar functionality and zone them with the help of interior items.
9. Two entrances to the house are recommended - main and additional.
10. Consider future communications when developing a project. The layout really has a lot of details, and only experienced specialists will be able to competently approach the zoning of future housing. Turning to us, you can be sure that we will take into account your details and make every square meter of your space functional, thoughtful, safe and ergonomic.`,
  },
  {
    id: 4,
    title: 'What are the categories of land?',
    description: `When you have selected a site that suits your location, size, and landscape preferences, it is recommended that you contact a professional architect and request a report on the category and features of the selected site.
There are three types of land to be distinguished:
1. Suelo Urbano: These sites are within the city limits and are suitable for rapid residential development. There are already roads and necessary communications (electricity, gas, water).
2. Suelo urbanizable: construction on these sites is possible only after preliminary preparation and approval of a detailed plan by the municipality. Often in such areas, there are special requirements for environmental protection.
3. Suelo no urbanizable: this island is located in a protected area where the construction of residential properties is prohibited, and a license for this is given only in exceptional cases.
When conducting an analysis, the architect must clearly describe all the restrictions that apply to a particular site. For example, there may be strict distance requirements between neighboring houses, a ban on cutting down some old trees, and difficulties with connecting to electricity, gas, and running water.
`,
  },
  {
    id: 5,
    title: 'How long does the construction process take?',
    description: `Based on the experience of our experts, building a house in Spain usually takes approximately 18 months and goes through several key stages. At the beginning of the process, it is necessary to select and purchase a land plot that meets the requirements for construction. This is followed by developing the main project and preparing detailed estimates for the execution of works. Obtaining the necessary permits from local authorities and paying taxes in the municipality are also important steps. The conclusion of a contract with a contracting company and direct construction includes communications, foundation construction, and interior decoration. At the end of the process, a final inspection by the municipality is carried out to obtain a permit for operation, registration of property documents, and connection of electricity, water, and lighting in the new housing. Turning to us, we will help you make the process as comfortable and fast as possible.`,
  },
  {
    id: 6,
    title: 'What documents are needed to start construction?',
    description: `When the municipality approves your private home's construction, your architect can begin to develop a detailed plan and estimate the cost of materials and work (PEM - El Presupuesto de Ejecución Material).
Then you need to find a reliable construction company with which to conclude a contract, and this will require the following documents:
1. Licencia de obra mayor (major building permit) from Ayuntamiento (municipality).
2. Documents confirming your ownership of the land.
3. Checking the technical feasibility of connecting to centralized communications.
4. Detailed design of the building.
5. Photocopies of the general plan for the development of the area.
6. An act indicating the boundaries of the site and existing buildings.
After receiving official estimates and permits, you can consider obtaining financing from a bank. However, it should be remembered that obtaining a large loan covering all construction costs is only sometimes available to private individuals. Based on their experience of building a house in Spain, many people warn that loans for such projects to individuals can be issued with certain restrictions.`,
  },
];






export const ModularHouses = [
  {
    id: 1,
    title: 'How long does it take to assemble a modular house?',
    description: `The fast and convenient construction of modular houses attracts many people. These houses are assembled from large parts of walls and ceilings, which are delivered to the construction site. Assembly takes from 2 weeks to 1.5 months, after which the finishing and installation of engineering equipment begins. Ready-made modules containing half a room or several isolated rooms are delivered on special cargo platforms and installed on a pre-prepared foundation. Express installation takes from two to ten days, after which the house is ready for occupancy. Modular houses are ideal for those who want to be open to more than one place and prefer to move around. For example, if you have to travel frequently on business trips, you can move without worrying about finding a new home. Your home will always be there, like a snail shell. Assembling a modular home usually starts with placing the first module on the foundation and securing it. The next module is then dropped and attached to the previous one, and so on. After assembly, the joints are sealed and then proceed to the installation of the roof.`,
  },
  {
    id: 2,
    title: 'What is the strength and durability of modular homes?',
    description: `The durability of modular homes depends on various factors such as the technology, materials, and quality control used. By choosing us, you can be sure that we use only high-quality materials and work with integrity. The main structures of a house can last over 50 years, which is an impressive amount of time. The service life of the insulation is a little more difficult to determine since there is no long-term practice of using modern thermal insulation materials. However, provided there is no wetting, it can be assumed that the insulation will last about 20-25 years. Such statistics can be tracked in the territories of Spain, where there is no high humidity and rain. It is important to note that physical wear rarely causes a home to become unusable. Instead, such a house may become morally obsolete, and the owners may decide to sell it or rebuild it to meet modern requirements and tastes. Therefore, you can contact us at any time to refresh your square meters.`,
  },
  {
    id: 3,
    title: 'How popular are modular houses in Spain?',
    description: `Modular homes have gained popularity in Europe in recent years, and their appeal is not only due to the cost reduction but also the opportunity to offer a solution to overcome the economic crisis caused by the Covid-19 pandemic. Spain is the most active use of modular homes among all European countries. However, the history of modular homes goes back to the post-war era. It was then that this type of housing appeared, which was built quickly and inexpensively to meet the huge demand for living space. The term "modular" means that the house is built from individual modules. Each module is created independently. Most of the work is done in the factory, where individual modules are made from various materials. These modules are then delivered to the buyer's land and are finally put together to create a home on site. This technology is popular in Spain since the loyal climate and the soil characteristics, weather conditions, and other factors make it possible not to worry about the need for a foundation and additional fortifications.`,
  },

  {
    id: 4,
    title: 'What myths about modular homes should be debunked?',
    description: `1.	"Modular homes look formulaic." When building a new house from scratch, it is possible to design it fully, considering all the wishes of the future owner. However, in the case of modular homes, the answer is not so clear. The complexity of the implementation of the idea, the quality of finishes, and the area will affect the cost of housing. Although finishing according to the client's wishes is possible, companies often offer ready-made models. If you deviate from the classic design, the price may increase, and the creation time will increase.
2.	"It is impossible to expand the house." Both modular and traditional homes can be expanded if structure, laws and site permit. Many modular homes are designed in such a way that modules can be added and expanded. This makes it easy to add extra space. Extending a conventional home is a more complex and time-consuming process.
3.	"Modular homes are not about strength and reliability." With all the requirements and regular maintenance, modular houses can last from 50 to 70 years, just like houses built traditionally.
4.	"A simple box with no distinctive features." Previously, modular homes were considered too simple in appearance. Today, however, they represent an example of modern style. Traditional houses can have any appearance, depending on their owners' taste and stylistic preferences.
`,
  },

  {
    id: 5,
    title: 'What are the key benefits of modular homes in Spain?',
    description: `One of the big advantages of modular homes is the absence of building dirt. Most of the work is done outside the construction site. When the modules are delivered to the site, they are simply assembled together, and this does not involve a lot of debris or dirt. This means you don't have to worry about scattered construction waste and post-construction cleanup. Secondly, modular houses are often attracted by their environmental friendliness and energy efficiency. This is due to the optimization of production and the use of materials, considering environmental factors. Thanks to this, modular homes can be more energy efficient and environmentally friendly. In addition, this construction approach reduces waste and uses resources more efficiently. Modular houses offer a wide variety and high quality of the materials used. For example, metal and concrete are the most popular materials for building modular homes in Spain. Modular houses in Spain are considered temporary structures that do not affect the geodesy of the site. Another advantage of modular houses is the minimum of bureaucracy during their construction. To build such a house, you do not need to obtain permits or licenses, as is the case with traditional houses. This means that you can build a modular home on almost any site, even if it does not have a building permit. This gives you more flexibility and simplifies choosing a location for your future home.
`,
  },
];

export const Architect = [
  {
    id: 1,
    title: 'How long does an architectural project take?',
    description:
      `The optimal terms for designing an individual residential building depend on the interaction between the architect and the customer. The more effective the feedback and approval of the proposed design solutions, the faster the design will be completed. Usually, the process takes from 1 month to 3 months, but in the case of complex objects, it can take up to six months to develop a complete project.`,
  },
  {
    id: 2,
    title: 'Design project and working project - what are their features?',
    description:
     `The design project and the working project differ in their characteristics and functionality. The design project is abstract data that gives an idea of the appearance and concept of the future home. At the same time, the detailed design is the detailed and developed drawings and documentation necessary for the actual construction. These documents serve as a direct guide and basis for all construction work.`,
  },
  {
    id: 3,
    title: 'Which project is cheaper: standard or individual?',
    description:
      `Original and standard designs have different costs. A typical project is usually more affordable, as it has already been developed and re-sold after the initial costs have been covered. However, the standard project has a significant drawback since it was created considering the specific area, utilities, site orientation, and climatic conditions. A standard solution can only partially satisfy your desires and requirements, so modifications can lead to additional costs and delay construction. Therefore, turning to our specialists, you can be sure that an individual project will be developed for you, considering the recommendations.`,
  },

  {
    id: 4,
    title: 'What are the stages of designing and building a house?',
    description:
      `The sequence of building a private house is usually as follows: Earthworks, if there is a building permit and a finished house project.
1.	Construction of the foundation.
2.	Construction of walls and ceilings.
3.	Performance of roofing works.
4.	Installation of windows.
5.	Laying of internal communications and laying of external engineering networks.
6.	Internal finishing works, including ceilings and floors.
7.	Installation of doors and stairs.
8.	Installation and connection of engineering equipment.
9.	Carrying out external finishing. This sequence allows you to build a house in stages, from the foundation to the exterior, providing a systematic and structured approach to construction.
`,
  },

   {
    id: 4,
    title: 'What architectural style is most common in real estate in Spain?',
    description:
      `The Mediterranean style of houses is popular in the southern provinces of Spain. Facades are usually finished with white plaster, arched windows, and doors are used, and there are also open galleries and terraces. The roofs are covered with brown or orange tiles. This style is well suited to the hot climate of southern Spain, providing protection from the sun and creating an incredible feeling. As you approach the north, the architecture of the houses begins to change. You can find homes in the colonial style or with a facade made of natural stone. In the colonial style, the walls are usually finished with rough plaster or raw materials, the floors are made of wood, and wooden blinds are installed on the windows. Modern high-tech houses are becoming more and more popular. The predominance of glass and metal in the design characterizes the rectilinear shapes and lines. Such homes, especially those with sea views, have space, visibility, and a lot of light.
`,
  },
   {
    id: 5,
    title: 'What factors should be considered when planning the interior space of a house?',
    description:
      `1.	Determine the main goals and needs of your home. Arrange the rooms so that each zone is usable and there is a logical sequence of movement between them.
2.	Consider the natural flow of movement within the home. Create convenient paths from one room to another, avoiding narrow and congested corridors.
3.	Maximize your use of natural light — position windows to maximize natural light and create a pleasant indoor ambiance.
4.	Consider the need for privacy in different areas of the home. Place bedrooms and bathrooms in remote parts of the house, and leave public areas more open.
5.	The ratio of the size and proportions of the rooms is essential to create a harmonious and pleasing visual impression. Consider the correct proportions when placing furniture and accessories.
6.	Consider creating multifunctional spaces that can be used for various purposes. For example, a combined living-dining room or study, which can also be used as a guest bedroom.
7.	Provide enough storage space. Place cabinets or built-in wardrobes to efficiently use space and keep things organized.
8.	Consider your style and preferences when choosing finishes, color schemes and decor. Create atmosphere that reflects your personality and creates a pleasant impression. Moderna Casa experts can provide more detailed consultations and recommendations on developing an architectural project. We will make your home comfortable for every member of the family.

`,
  },
];

export const Interior = [
  {
    id: 1,
    title: 'Why not do without our interior services?',
    description:
      `An interior design project is a key tool that helps you create a harmonious and functional space that fits your lifestyle. Here are a few reasons why a design project is integral to a successful interior renovation and organization.
1.	The design project helps to make the most efficient use of the available space. It offers smart placement of furniture, accessories, and functional areas to create a comfortable and practical space that meets your needs.
2.	It is important that the interior reflects your lifestyle and meets your needs. Designers consider your preferences, hobbies, and daily routines to create a functional and enjoyable interior for you and your family.
3.	The design project allows you to evaluate all the interior elements in advance and imagine how they will look in reality. With the help of visualizations and plans, you can view various options for colors, materials, furniture, and decor and make informed decisions.
4.	Drawing up a design project allows you to determine the work cost and plan a repair budget more accurately. You will be able to see in advance all the costs associated with materials, furniture, finishes, and designer services and control the costs during the project.
5.	The design project helps avoid mistakes during repairs and allows professionals to consider all the technical aspects and details. This minimizes the possibility of unexpected problems and bad decisions and reduces the time and cost of fixing errors. And cooperation with Moderna Casa will make all processes more comfortable and enjoyable.
`,
  },
  {
    id: 2,
    title: 'Is interior design expensive?',
    description:
      `Of course, when developing a design project, we consider your budget. We understand that each client has their own financial capabilities and limitations, so our goal is to create an interior that reflects your taste and lifestyle and matches your financial capabilities. We strive to balance style, functionality, and affordability to create an interior that is not only aesthetically pleasing but also economically viable. Our team has experience working with various budgets, so we are ready to offer options that meet any financial expectations. While working on the interior and visualization, we can also provide you with information on the approximate cost of the necessary materials, furniture and work to give you an idea of the possible costs.`,
  },
  {
    id: 3,
    title: 'What is the design phase control service?',
    description:
      `Architectural supervision is an essential stage in implementing a design project, which guarantees the compliance of repair and construction works with the approved project. As part of the author's supervision, the designer and architect actively control the execution of all work and ensure that each step corresponds to the idea of the project. This includes: Constantly monitoring the construction process. Checking the quality of the materials used. The accuracy of the implementation of architectural solutions. Moderna Casa experts also provide timely answers to any questions or problems that arise and advise the customer on issues related to design and architecture.`,
  },

   {
    id: 4,
    title: 'What are the 3 main components of the interior design process?',
    description:
      `The design project includes several important components that help create a complete and detailed concept.
1.	One of the key elements is 3D visualization, which allows you to visualize every detail of your space. Thanks to this technique, we can choose the best materials and colors and evaluate the functionality of the placement of furniture and other items, taking into account your wishes.
2.	Technical documentation includes many plans and diagrams for the correct project implementation. This consists of a measurement plan, a plan for arranging furniture and equipment, a plan for installation and dismantling, a floor plan indicating the type of flooring, a ceiling plan, a plan for the location of sockets and switches, a plan for linking fixtures with a switching scheme, a program for finishing walls, a method for connecting sanitary equipment, sweeps walls in the bathrooms and kitchen, indicating the layout of the tiles, as well as plans for underfloor heating and radiators. When implementing this paragraph, we also consider the current legislative framework in Spain.
3.	An essential aspect of the design project is the selection of materials. All products presented on our website can boast of high quality and durability. We provide recommendations and indicate specific finishing materials, furniture, plumbing and lighting fixtures. In addition, we invite you to use our online store, where you can purchase these items and materials. All these design project elements work together to create a complete and detailed interior concept, from 3D visualizations to technical documentation and material selection, to realize your wishes and create the perfect space that reflects your style and preferences.
`,
  },
   {
    id: 5,
    title: 'Interior in Spanish style: what does it look like?',
    description:
      `Furniture of simple design in ebony or mahogany, leather armchairs and chairs. The Spanish style in the interior is distinguished by light and warm colors and the use of natural materials and elements made of stone, wood, and ceramics. Decorative pillows and candles create coziness. Features include period furniture, antiques, family photos, and wall paintings. There are no wallpapers and carpets; instead, painted walls, tiles, and wooden floors are used. Wooden beams on the ceiling and cabinets in niches are popular. There is a large dining table for family dinners. The decor includes pictures in wooden frames, ceramic dishes, figurines, vases, and candles. Hand-painted ceramic tiles are used. You can consult with us, and we will tell you more about the features of the interior of the Mediterranean countries. We also create design projects in other styles and listen to your advice and preferences.
`,
  },
   
   {
    id: 6,
    title: 'How long does the process of developing an interior concept take?',
    description:
      `Developing a design project for a private house in Spain takes about 25-45 days and is carried out in parallel with the construction. The designer-designer performs not only the development of the interior concept but also solves engineering and technical issues. During this time, the specialist performs the following tasks:
1.	Preparation of dimensional drawings and diagrams of engineering systems.
2.	Creating an interior visualization on a computer and making collages.
3.	Development of design schemes for the ceiling, walls, and floor.
4.	Layout of the location of doors, sockets, and lamps.
5.	Organization of underfloor heating and air conditioning.
6.	Search and purchase materials specified in the design project of the house.
7.	Registration of technical and legal documentation.
8.	Coordination of construction, control of terms, and quality of work.

`,
  },
   
   
];

export const SwimmingPools = [
  {
    id: 1,
    title: 'What can be added to the pool?',
    description:
     `1.	Installation of "artificial water flow". A great option for those who love physical activity. Even in small pools, this device allows you to practice sports swimming and enjoy hydromassage simultaneously.
2.	The bottom geyser offers each member of the family an air massage that creates a feeling of weightlessness and enriches the water with oxygen. The addition will help the younger family members quickly and more confidently start to stay on the water. And adult family members will be pleased that after such a massage, fatigue, insomnia and bad mood disappear.
3.	Waterfall is not only about aesthetics and external beauty but also an effective massage for the shoulder girdle. It is also the perfect addition to bring an atmosphere of relaxation and enjoyment to the pool. And the backlight option will create a feeling of comfort in the dead of night.
4.	Water slide is the dream of all children. They can tirelessly ride the slide for hours, enjoying fun and entertainment. In our company catalog, you will find a wide selection of pool accessories, and our experienced specialists will help you choose the perfect option that meets your requirements and wishes. We offer only the highest quality and functional attractions. Our specialists know how to make your pool a non-trivial and functional solution.
`,
  },
  {
    id: 2,
    title: 'Choosing the type of pool: skimmer or overflow?',
    description:
      `1.	Skimmer pool: water is filtered through surface skimmers and returned through wall nozzles.
2.	Overflow pool: water is poured into the storage tank, passes through the filtration, heating, and disinfection systems, and returns through the bottom nozzles. Choosing the type of pool is up to you, but if you have the ability and the budget, then an infinity pool is the preferred option. However, a skimmer pool can also be highly quality if all the necessary calculations and technologies are followed. We are ready to help you install any pool.
`,
  },
  {
    id: 3,
    title: 'What opportunities are there for pool automation and control?',
    description:
      `If you take a closer look at our services, you will see such a service as "smart home". It is designed not only for use inside the house but also for external elements.
1.	The mechanism allows you to automate the processes of filtering and maintaining the quality of water in the pool. You can control and adjust the operation of filters, pumps, disinfection, and water heating systems through an application on your smartphone or a control panel.
2.	The "smart home" system allows you to manage the water temperature in the pool intelligently. You can set the desired temperature using your mobile phone, and the system will automatically maintain it, including turning the water heaters on and off.
3.	Each person gets the opportunity to control the lighting around the pool, including underwater lights, using the "smart home" system. Thus creating different atmospheres and moods around the pool.
4.	Security and alarm is another advantage. You can receive notifications about the status of the pool, such as a change in water level or motion detection around the pool, to avoid an accident.
5.	Set up a schedule for the pool system and create automatic scripts. For example, you can set filtration and heating systems to turn on and off, and create scenes that turn on lights and background music when using the pool. These are just a few examples of what smart home systems can do to automate and manage your pool. We will help you get the maximum benefit from integrating the automation mechanism by contacting us.
`,
  },

  {
    id: 4,
    title: 'What materials are most suitable for finishing the inner surface of the pool?',
    description:
      `The most suitable materials for pool interior decoration are:
1.	Ceramic tile is a popular and durable material for swimming pool decoration. It has good water, chemical, and UV resistance and is easy to clean.
2.	Fiberglass is considered to be a durable material. It creates a smooth surface and provides good corrosion and UV protection.
3.	Epoxy resin guarantees a durable and waterproof coating for swimming pools. It has high chemical resistance and can withstand heavy loads.
4.	PVC membrane is a flexible material that can cover the pool's inside surface. They are highly resistant to chemicals and will last a long time.
5.	Polyurethane paint is widely used for swimming pool decoration. It provides a smooth and durable surface resistant to chemicals and UV radiation. We recommend you consult with our professionals to select the right material for your needs and pool conditions.

`,
  },

   {
    id: 5,
    title: 'What types of service and maintenance are required for the pool?',
    description:
      `The following services and maintenance are required to ensure the proper functioning and quality of the pool:
1.	Removing debris, leaves, and other debris from the pool using a net or a pool vacuum cleaner.
2.	Checking and correcting the chlorine level, pH balance and other chemical indicators of water. To ensure water safety and prevent the growth of harmful bacteria and algae.
3.	Checking and cleaning the pool filter. The filter removes fine particles and impurities from the water, and cleaning it regularly helps keep the filtration system running efficiently.
4.	Maintenance of the water heating system (if the system is installed). This may include checking and maintaining heating equipment such as boilers, heat pumps or solar collectors.
5.	Inspection and maintenance of the pool pump. The pump circulates the pool water and must be maintained to filter and treat the water effectively.
6.	Control of all security systems, including the presence and operability of the plotter (life buoy), switches, and signaling devices. These services and maintenance are important to keep the pool safe, clean, and working correctly. Therefore, we are ready to be your reliable assistants on this path and provide systematic quality services.

`,
  },

   {
    id: 6,
    title: 'How to ensure safety in the pool, especially for children?',
    description:
      `It is essential for us to not only to sell you a pool, but also to guarantee high safety for each family member. To do this, the following measures should be taken:
1.	Install a reliable fence around the pool, which will prevent unauthorized access of children to the water.
2.	If it is impossible to observe the pool, use protective covers such as plotters (life buoys) or pool covers to prevent children from accidentally falling into the water.
3.	Adults should always be near children near the pool. Regular and vigilant adult supervision can prevent dangerous situations.
4.	Educate children about safety rules in the pool. It is important to explain that they should not play near the pool without adults or jump into the water without permission.
5.	Life-saving equipment such as plotters, buoys or life buoys must always be available near the pool. In case of an emergency, they can be used to help. Write to us and we will show you how to make your pool practical, beautiful and thoughtful, and safe for everyone.
`
  },
   
    {
    id: 7,
    title: 'Can I choose the equipment for the pool myself?',
    description:
      `
      Self-selection of equipment for the pool is possible but serious problems must be solved. It requires studying many books and documents, understanding the principles of water treatment, calculating the diameters of pipelines and the speed of water movement, choosing the right equipment, and much more. This cannot be called a chance to save money since you must invest much of your time. Dealing with Moderna Casa, which specializes in improving the performance of your home's performance and the sale of equipment for pools, guarantees competitive prices and quality products.
`
  },
];

export const SmartHome = [
  {
    id: 1,
    title: 'Smart home: to install the system or not?',
    description:
      `A smart home is not just a technological novelty but a modern lifestyle that has become the new norm in the real estate world in Spain. Smart home makes your life convenient, efficient and enjoyable and showcases the cutting edge of technology right in your home. Home automation system can significantly reduce energy consumption in the apartment. Thanks to video surveillance, you can easily monitor your children's and your home's safety, even when you are away from home. And what surprised looks await your friends when you close the blinds with just one phrase, for example, "Siri, movie show", then turn off the lights, and the player turns on your favorite TV series - it's just magic. A life-saving trend designed to help optimize various household processes.`,
  },
  {
    id: 2,
    title: 'Can I figure out the system myself?',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec venenatis blandit ligula. Etiam a feugiat mauris. Duis et elit et enim blandit volutpat. Sed et lobortis turpis. Curabitur ac neque sed massa ultricies tristique. Nunc faucibus vel justo quis accumsan. Maecenas gravida lacus eget faucibus mollis. Donec mattis nisl quis pretium facilisis. Praesent ultricies orci dignissim, tempor felis et, rhoncus dolor.',
  },
  {
    id: 3,
    title: 'What has your company done',
    description:
     `Definitely yes. Since the "Smart Home" is just created to simplify every person's life. Our experts will help the client understand the principles of operation of various systems at the selection stage. After contact testing of the equipment in the showroom, all doubts about the potential owner disappear entirely. Within a week, you will get used to your new assistant and cannot imagine life without it.`,
  },
  {
    id: 4,
    title: 'Will there be problems with repairs if you have a smart home system?',
    description:
     `It all depends on the treatment and the stage of repair. It is advisable to notify the system's availability before finishing work begins to account for the need for cabling. Our experts will help you install the system both at the initial stage of building a house, and after installing the system in a finished house. Specialists will consider the features of the system installation and avoid installing unnecessary elements in the house.`,
  },
  {
    id: 5,
    title: 'What are the stages of installing a smart home system?',
    description:
     `Installation is carried out in two stages: the first is completed after completion of rough construction work, including cabling; the second stage takes place after finishing work and includes connecting and configuring the system. Usually the entire installation process takes about a week, but the time may vary depending on the complexity and amount of functionality needed.`,
  },
  {
    id: 6,
    title: 'How long does the smart home installation process take?',
    description:
     `The installation process of the system will take no more than two weeks, subject to the following requirements:
1.	Completion of the fine finishing of the premises and the absence of other work that may interfere with the installation.
2.	Providing a full-fledged functioning sewerage, electricity and water supply in the room.
3.	Free access to electrical panels that allow you to turn off the power lines to which the equipment is connected.
4.	Availability of unhindered access to all installation points of system components.
5.	Maintaining a stable temperature in the room from +18° to +30°. Considering these conditions, the specialists will install the system within the specified time, guaranteeing efficiency and high quality of work.
`,
  },
  {
    id: 7,
    title: 'What will happen to electricity consumption?',
    description:
     `Much to the surprise of many of our customers, smart home systems consume very little electricity, and even help reduce bills (approximately 30%) by alerting them to unnecessary overspending. For example, motion sensors help to avoid unnecessary consumption of electricity. But the greatest savings can be achieved in climate system management. In addition to the smart home system, you can order solar panels from us to receive comprehensive benefits, be eco-friendly, save your own money and maximize the potential of your home.
`,
  },

  {
    id: 8,
    title: 'What happens to the house when the Internet is turned off?',
    description:
     `Smart homes have wired systems that function independently of an Internet connection. If the network connection is lost, the "smart home" will continue to work as usual thanks to the provided engineering, coordinated with the operation of home appliances. The only systems that may experience some limitations are wireless. However, it is worth considering purchasing an uninterruptible power supply for cases of power outages, which is also helpful for all household appliances.
`,
  },
  {
    id: 9,
    title: 'Can the system be manually controlled?',
    description:
     `Yes, leaving the system control in manual mode is possible, especially if a small child or an older person needs to ensure safety. Thus, there is a guarantee that the stove in the kitchen will not be ignited by mistake. However, thanks to the intuitive interface, you can easily master the control functionality throughout the day. You can choose a simpler way to use the system and use the application on your phone.
`,
  },
  {
    id: 10,
    title: 'Smart home: is this system only suitable for housing?',
    description:
     `This innovative technology is ideal for residential buildings, offices, corporate buildings, and business centers. The system can offer many benefits for your business, from a reliable security system to remotely controlling lighting, heating, curtains, and blinds. Planning and installing such systems is best done before repairs start or as early as possible. However, regardless of whether it is a new renovation or an existing premises, Moderna Casa's experienced specialists, who are versed in the installation of smart technologies, will do their best to minimize any negative consequences from the building and offer you the best solution taking into account the specifics of your situation.
`,
  },
];

export const Investments = [
  {
    id: 1,
    title: 'Is it worth investing in a second home?',
    description: `Spain's second home price data for January 2023, provided by the Idealista property price index, allows us to take a closer look at the situation. On average, prices for secondary housing rose by 5.4%, reaching the level of €1,922 per square meter. Despite this, nationwide house price growth remained below the inflation rate of 5.8% in January. Looking at the capitals of Spain, we see that in 16 cities, the rise in prices for second homes has exceeded the inflation rate. The most significant growth was recorded in Santa Cruz de Tenerife (+18.7%), Alicante (+13.2%), Valencia (+11.4%), Guadalajara (+11.3%), Cuenca (+ 10.8%), Palma (+9.9%), Seville (+8.1%), Malaga (+8%), Segovia (+9.9%), Pontevedra (+8.3%), Badajoz (+7.3%), Huelva (+7.2%), La Coruña (+6.8%), Tarragona (+6.6%), Granada (+6.3%) and Pamplona (+5 ,8%). In addition, another 30 capitals experienced rising house prices, but they remained below the inflation rate. Among these cities, the major markets of Madrid (+4.9%) and Barcelona (+3%) should be singled out. January 2023 brought changes in the prices of second homes in Spain; there is an increase in prices. Therefore, deciding to purchase a resale property today and resell later can provide an excellent financial cushion for the investor.`,
  },

  {
    id: 2,
    title: 'What additional benefits does an investor get in Spain?',
    description: `Spain offers one of the most attractive programs for obtaining a residence permit (Residence Permit) and, subsequently citizenship. To become a member of the program, you need to purchase property worth at least €500,000, and after living in the country for 10 years, you will be able to apply for EU citizenship with all the associated benefits. Spain is an attractive destination for real estate investment, especially for countries with unstable economies. This country ranks fourth in terms of economy in the Eurozone and twelfth in the global economy. Investing in real estate here is profitable and reliable. Various experts estimate the return on such investments in the from 5 to 15% per annum range. Particularly attractive to investors are apartments or townhouses in popular resorts. This is due to the enormous popularity of Spain among tourists from all over the world. Here, an excellent climate, a beautiful Mediterranean coast, a high economic and social development, and a rich cultural heritage were successfully synchronized. All these factors make Spain very attractive for real estate investment.`,
  },

  {
    id: 3,
    title: 'Why choose us to invest?',
    description: `Many people choose to invest independently but need more knowledge to do it safely and competently. Therefore, a reliable assistant is indispensable. We specialize in developing and implementing optimal solutions for investors in Spain. Our goal is to help you get high passive income. We analyze the economic prospects of investing in real estate in Spain and help our clients to invest their money profitably. We strive to create an investment portfolio with high strategic accuracy, considering all potential risks for the investor. We provide a full range of services to ensure a high income in the real estate market in Spain. More than half of our clients trust us with management and complex administration after the completion of the transaction. And we regularly monitor legislative innovations to ensure your funds' safety and compliance with laws.`,
  },

  {
    id: 4,
    title: 'What are the characteristics of the most profitable properties?',
    description: `When choosing a property, there are key criteria that should be considered. One of these criteria is beautiful panoramic views of the sea or nature from the window, which should not be underestimated. A TripAdvisor study found that for 82% of travelers, an inspiring view from their window is the most important part of their trip. Therefore, attracting visitors and buyers of holiday homes requires significant effort. In addition, Spanish city apartments in other areas benefit from amenities and excellent transport infrastructure nearby. Consider additional criteria when choosing a second home as a real estate investment. Whether you are renting a property or planning to sell it, having the right features and amenities is essential. Adding modern essentials like fast Wi-Fi, high-definition TVs, and clean, stylish bathrooms and common spaces can greatly enhance the appeal of vacation homes. In warm climates, terrace dining areas, ambient lighting, or extra rooms often add value. In addition, it is important to accurately estimate the cost of maintaining a home to guarantee a return on investment. This includes accounting for cleaning, maintenance, gardening, and repairs costs.`,
  },
  {
    id: 5,
    title: 'Investments and Golden Visa: what prospects do they open?',
    description: `The Golden Visa is a visa that allows non-European Union investors and their families to become residents of Spain. Your immediate family members, including spouses and minor children, will be granted residence in Spain if you obtain a Golden Visa. This also applies to adult dependent children. Getting a Golden Visa in Spain gives you the opportunity to live in the country and access many benefits that residents of Spain can only enjoy. Who is eligible to apply for a Golden Visa in Spain? Anyone who buys a home in excess of EUR 500,000 can apply for this visa, provided that the applicant contributes at least this amount with their own funds, has invested in government bonds, or has shares or bank deposits in excess of EUR 1,000,000. Also eligible to apply for this visa are persons carrying out business projects in Spain that are of public interest. This applies to all projects that create jobs and have a socio-economic impact in the geographic area where they are implemented, as well as all projects that contribute to scientific or technological innovation in the country. How long is the Golden Visa valid in Spain? With this visa, you can reside in Spain for at least one year. After this period, you can apply for a residence permit valid for 2 years. Subject to investment and other requirements, the maximum validity period of a residence permit is 5 years.`,
  },
   
];

export const SolarEnergy = [
  {
    id: 1,
    title: 'Is it profitable to use solar panels?',
    description: `Yes, in Spain installing solar panels is becoming an increasingly attractive and smart choice. When evaluating the efficiency of a system for private electricity consumption, it should be taken into account that the service life of solar panels is about 25 years, and the investment usually pays off in about 7 years. This means that owners of solar systems in Spain not only have access to clean and renewable energy, but also have the opportunity to significantly reduce their energy costs over a long period of time. What's more, solar panels can reduce exposure to fluctuations in energy prices and mitigate the impact of changes in the cost of electricity.`,
  },

  {
    id: 2,
    title: 'Can high electricity prices be avoided? ',
    description: `The sun is an invaluable resource. In Spain, energy prices are now almost three times higher than the average for the past five years. Thanks to its abundance of sunshine, Spain relies on solar panels as an alternative source of energy. Payback is much faster than in the UK or Sweden.`,
  },

  {
    id: 3,
    title: 'What will be the future of solar energy in Spain? ',
    description: `By 2030, Spain plans to provide almost 75% of the country's energy production from alternative sources, while the current figure is about 47%. Spain is committed to a sustainable future based on clean energy sources. `,
  },

  {
    id: 4,
    title: 'Is it difficult to maintain solar panels? ',
    description: `The use of solar panels implies a simple and not particularly costly process for servicing solar panels. Of course, this is a little more difficult than doing nothing when your house is connected to public electrical networks, but it is also no more difficult than with any other home appliances - washing machine, refrigerator, which require maintenance. If your installation was purchased from trusted suppliers, then you, as a customer, are offered a full solar maintenance and repair service. On your own, you can wipe the panels from dirt and thus extend their service life. `,
  },

  {
    id: 5,
    title: 'Where else are solar panels in demand? ',
    description: `In China, more than 40 million homes are already equipped with solar water heaters, which indicates a high demand for this technology. Beijing has also installed an impressive 300 million square meters of solar panels by 2020. Spain is also taking active measures in the development of solar energy. Since March 2006, all new homes in Spain have been required by law to be equipped with solar panels. However, the world leader in the use of solar energy per capita is Israel. This country is showing progress by installing more solar panels per person than any other country.`,
  },

  {
    id: 6,
    title: 'Is solar energy relevant for hot water and heating?',
    description: `Solar energy makes it possible to use it both for space heating and for the production of hot water. Solar energy in producing hot water is one of the most efficient ways to use this energy source. However, space heating requires a much larger area of solar panels than water heating. It is important to note that solar heating is advantageous during winter when additional heat is needed. At the same time, during the summer period, large solar arrays must be protected from overheating. Warm water heated by solar energy can be circulated in underfloor heating systems or fed to radiators to maintain a comfortable indoor temperature. To achieve the highest efficiency, the system must be designed to operate at low fluid temperatures to maximize available solar energy.
`,
  },

  {
    id: 7,
    title: 'How long can solar panels last?',
    description: `Solar panels have an impressive lifespan that can be compared to other electronic devices. With proper use and care, they can continue to work for decades.
    The degradation coefficient of solar PV modules, which depends on the quality and manufacturability of products, plays a key role in determining the service life. The optimal scenario assumes that in 20-25 years the solar installation will produce about 80-85% of its original capacity. That is, it will continue to make a significant contribution to electricity generation.
    It is important to note that after this period, the solar panels do not fail instantly. They may continue to function, but with some performance degradation. Even after the guaranteed service life has expired, the solar installation can continue to operate and provide benefits for additional years.
    Manufacturers' warranties also play an important role. They typically offer a 5-year mechanical damage warranty for mid-range products and 10-12 years for premium products. This means that the buyer can be sure of the reliability and quality of solar panels over a certain period of time.
`,
  },
];

export const Inmobiliaria = [
  {
    id: 1,
    title: 'Why is it important to analyze the soil and terrain?',
    description:
      `The terrain and soil determine the fundamental properties of a site, such as its stability and ability to support a structure.
1.	Geological and geotechnical studies carried out by specialists help determine whether the soil is suitable for construction. Our experts check the density of the ground, its bearing properties, and resistance to deformation. This is important to ensure that your building is not in danger of sinking, collapsing, or shifting.
2.	Moderna Casa experts say that the topography and soil also play a role in the drainage and water supply of the site. Proper planning and placement of the house on the site avoid problems with stagnant water and improper wastewater drainage. Thus, it is possible to determine the optimal locations for placing drainage and sanitation systems.
3.	Terrain and ground can have an impact on the layout and architecture of a building. Irregularities in the terrain may require additional excavation and terracing of the site to create a flat site for construction. And having analyzed it in advance through our Real estate service, we can tell you a more accurate cost of services.
4.	Knowledge of the soil allows you to choose the best foundation methods adapted to the site's specific conditions. For example, a flat area can provide opportunities for further construction or creating a garden space.
`,
  },

  {
    id: 2,
    title: 'How to conduct a comparative analysis of several plots to select the right one?',
    description:
      `1.	Develop a list of criteria that will be used to compare plots. These criteria may include accessibility, land value, infrastructure, environmental factors, potential for future development, etc. Determine the importance of each criterion to highlight the most significant aspects.
2.	Collect all available data for each site, including plans, maps, infrastructure, environmental assessment, and land prices and permits.
3.	Analyze each site using predefined evaluation criteria. Evaluate each criterion on a scale or rating system. You can use numbers or rankings to make comparisons more objective.
4.	Considering the importance of each criterion, assign significance coefficients to them. For example, if accessibility is the most important criterion, you can give it more weight than other factors.
5.	Analyze the scores for each site and consider the criteria weights. Compare the results and determine which section has the highest overall score. This will be the most suitable place for construction.
6.	Besides benchmarking, the project budget, legal restrictions, local regulations and permits must be considered. The Real estate service also implies this type of analysis. Our experts specialize in the real estate market and will help you with analytics and collect all the necessary materials.

`,
  },

  {
    id: 3,
    title: 'How do we conduct infrastructure analytics?',
    description:
      `This service also includes infrastructure analysis. We pay attention to:
1.	Educational institutions for children, such as schools and kindergartens.
2.	Pharmacy, preferably open 24/7.
3.	Hospital or emergency room, at least in the immediate vicinity.
4.	Veterinary clinic, essential for farmers who want a house in Spain to develop crops and livestock.
5.	The presence of an asphalt road that facilitates the accessibility of the site.
6.	Distance to the nearest settlement.
7.	The proximity of automobile and railway stations for convenient transport communications.
8.	Grocery stores, markets, and malls for the convenience of daily shopping.
9.	Entertainment facilities, parks, and squares for recreation and leisure.
10.	Availability of protective structures against possible floods in the event of floods.
11.	The number and type of roads adjacent to the site and the congestion of the route on different days and seasons. For example, if the nearest hospital is 15 kilometers from the site and you must drive on an unpaved road, this can be an unpleasant disadvantage. The "Real estate" service helps to analyze the possible level of comfort that can be provided to the site's buyer.


`,
  },

  {
    id: 4,
    title: 'Why is groundwater testing necessary?',
    description:
      `Water is a potential hazard to building structures, especially foundations. Homes built on sites with high water tables are exposed to daily water damage, unlike homes with low water tables. This can lead to subsidence of the foundation, cracks in the walls, flooding of basements, mold and mildew. The impact on the foundation is caused not only by the groundwater itself. Chemicals dissolved in water (eg, sulfates, salts) can also have serious consequences. They can cause the loosening and dissolution of concrete, which manifests in the form of a white coating and delamination of the foundation. Drainage of a site with a high groundwater level will help prevent all these negative consequences. And the Real estate service will help to assess the situation even before the foundation comprehensively.


`,
  },

   {
    id: 5,
    title: 'Why is it impossible to do without terrain analysis?',
    description:
      `When choosing a piece of land for construction, always pay attention to the relief. Moderna Casa experts say that hilly terrain can be beautiful in landscape design but create difficulties when building a house. It is advisable to choose a flat area without bends and cliffs. An elevated site is preferred as it will provide flood protection and usually has a higher price tag.
`,
  },
   {
    id: 6,
    title: 'Why not do without the "Real Estate" service?',
    description:
      `There are always many difficulties at the preparatory stages, and all the most dangerous pitfalls often emerge after a while. The task of our experts is to help you recognize these risks. If in the future you decide to sell the land, you should take into account the factors affecting its value:
1.	The presence of multi-story buildings within a radius of 150 meters. The height of the buildings allows residents to observe the surrounding area, which can cause some concern.
2.	The proximity of hangars and farms. This can lead to unnecessary noise and unpleasant odors associated with animal husbandry.
3.	Abandoned buildings. They can become places of residence for people with low social responsibility, which creates potential problems with security and the neighborhood environment.
4.	The presence of cemeteries. Some buyers may perceive them as an undesirable element affecting the surrounding area's aesthetics and ecology.
5.	The proximity of power lines (power lines). Some research has linked them to possible health effects, which may put buyers off.
6.	The presence of garage cooperatives. They can affect the overall ambiance and visual aspect of the surrounding development. Considering all of these factors when purchasing a property will reduce the likelihood of problems during a subsequent sale. And our experts will help you walk this path with you.

`,
  },
];

export const Consulting = [
  {
    id: 1,
    title: 'What are consulting services in the real estate market in Spain?',
    description:
      `This is an opportunity to consult with competent people and entrust experts with an analysis of the current market situation and other issues that are directly or indirectly related to real estate in Spain. This analysis is a professional assessment of the infrastructure of an existing or planned building. Its main purpose is to identify vulnerabilities and improper operation of the facility that may lead to insufficient profitability. The experts also analyze buildings that are only in the planning stage. As a result of this process, shortcomings are identified, which, after their correction, will make the object more attractive for residents, customers, and tenants.`,
  },

  {
    id: 2,
    title: 'What does comprehensive consulting include?',
    description:
      `Project consulting includes several important components. Firstly, the market, target audience, and purchasing power of potential customers for whom the facility's construction is planned is assessed. This helps to determine the relevance of the project and its commercial viability. Secondly, an assessment is made of the aesthetic and planning literacy of the premises and an analysis of the demand for such an architectural solution among potential customers. This is important to create an attractive and comfortable space that meets the needs of future users. The third aspect includes checking technical documentation for compliance with norms and standards to guarantee the safety and quality of construction. Research is also being carried out on the expectations of the population living in the vicinity of the project in order to take into account their needs and provide the most appropriate services and amenities. An important step is to check compliance with laws and regulations so that the project has all the necessary permits. After receiving our consulting services, you can proceed to the stages of designing and building a house, which our experts also provide. Finally, the concept of the building is formed, and a marketing plan is developed. This includes identifying the unique features and benefits of the project, as well as marketing and promotion strategies to attract interested customers and successfully bring the property to market.
      `,
  },

  {
    id: 3,
    title: 'What does legal consulting include?',
    description:
      `
      Real estate is a complex and regulated area with various laws, rules, and procedures that must be followed. Legal advice in the real estate field is of great importance in protecting the rights and interests of clients, as well as ensuring successful transactions and operations. If, in their home country, it is easier for a buyer or investor to understand the rules and laws on their own, then foreign investors in real estate in Spain should learn about the pitfalls from experts. One of the main reasons for seeking legal advice is to ensure legal security. Lawyers analyze documents and transactions to make sure they are legal and warn of possible legal risks. They help clients avoid unexpected problems and disputes related to real estate and provide advice and solutions to resolve legal issues. Legal advice is required for the legal examination of documents. This includes checking contracts of sale, leases, land deeds, and other legal documents. The correctness and completeness of documentation are essential for a successful real estate transaction, and lawyers help clients avoid possible errors and problems associated with incorrectly executed documents. Our experts explain complex legal terms and procedures, the rules and laws governing real estate, and help clients understand their rights and obligations. They recommend best practices and strategies to achieve the desired results and warn of potential risks.
What are the most common requests we receive?
1.	Oral hourly consultations where our experts answer your questions and provide advice on various aspects of the property.
2.	An investment advisory opinion to help you make informed real estate investment decisions and assess the potential return on your investment.
3.	Marketing research of real estate markets includes residential, office, shopping and entertainment, hotel and industrial and warehouse segments. We analyze the demand, supply, and competition in these markets, helping you to understand the potential and development prospects of various real estate segments.
4.	Conceptual planning services that help develop effective and innovative construction and real estate development concepts, considering market factors, economics, customer needs, and legal requirements.
5.	Express analysis of the situation in the professional opinion format, which provides a prompt and objective assessment of the current situation and recommendations for further actions.
6.	Prepare an investment memorandum that describes the project, its potential, risks, and expected results to attract potential investors.
7.	Analysis of urban planning documentation and prospects for the project's development, which includes the study of urban planning plans, coordination with the requirements of state bodies, and an assessment of the prospects for the implementation of the project.

      `,
  },
];


// export const Projects = [
//   {
//     id: 1,
//     title: 'What types of services do you offer?',
//     description:
//       'We offer a wide range of construction and real estate services, including but not limited to: construction, interior design, modular housing, swimming pools, solar energy, smart homes, Inmobiliaria, investments, and consulting.',
//   },
//   {
//     id: 2,
//     title: 'What has your company done',
//     description:
//       'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec venenatis blandit ligula. Etiam a feugiat mauris. Duis et elit et enim blandit volutpat. Sed et lobortis turpis. Curabitur ac neque sed massa ultricies tristique. Nunc faucibus vel justo quis accumsan. Maecenas gravida lacus eget faucibus mollis. Donec mattis nisl quis pretium facilisis. Praesent ultricies orci dignissim, tempor felis et, rhoncus dolor.',
//   },
// ];