import React, { FC, useEffect } from 'react';
import { ProcessBlockHeader } from '../ProcessBlockHeader';
import {
  ProcessListBlockWrapper,
  ProcessListWrapper,
  ProcessList,
  ProcessItem,
  ProcessItemTopWrapper,
  ProcessNumberWrapper,
  ProcessNumber,
  ProcessItemTitle,
} from './ProcessListBlock.styled';

interface ProcessBlockProps {
  processList: Array<{ label: string; number: number }>;
  anotherStyle?: boolean;
}

export const ProcessListBlock: FC<ProcessBlockProps> = ({
  processList,
  anotherStyle,
}) => {
  return (
    <ProcessListBlockWrapper anotherStyle={anotherStyle}>
      <ProcessListWrapper anotherStyle={anotherStyle}>
        <ProcessList>
          {processList.map(({ number, label }) => {
            return (
              <ProcessItem anotherStyle={anotherStyle}>
                <ProcessItemTopWrapper anotherStyle={anotherStyle}>
                  <ProcessNumberWrapper anotherStyle={anotherStyle}>
                    <ProcessNumber>{number}</ProcessNumber>
                  </ProcessNumberWrapper>
                  <ProcessItemTitle anotherStyle={anotherStyle}>
                    {label}
                  </ProcessItemTitle>
                </ProcessItemTopWrapper>
              </ProcessItem>
            );
          })}
        </ProcessList>
      </ProcessListWrapper>
    </ProcessListBlockWrapper>
  );
};
