export const aboutListLocation = 'Cataluña, España';

export const infoText = 'Espectacular villa soleada en España';

export const mainText =
  'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.';

export const headerBtnText = 'Más sobre el interior';

export const titleAbout = 'Acerca del proyecto';

export const textsAbout = {
  text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
};

export const textPhoto =
  'Los accesorios para el hogar inteligente están diseñados para hacer que tu hogar sea más conveniente, eficiente y agradable. Desde termostatos inteligentes y sistemas de iluminación hasta asistentes de voz y cámaras de seguridad activadas por voz, existen una variedad de accesorios disponibles para ayudarte a automatizar y controlar diferentes aspectos de tu hogar.';

export const view = 'ver'
  

export const housesDetailsMobile = [
  {
    number: 1,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 2,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 3,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 4,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 5,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
];

export const housesDetailsDesktop = [
  {
    number: 1,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 2,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 3,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 4,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 5,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },

  {
    number: 6,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 7,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 8,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
    {
    number: 9,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
  {
    number: 10,
    text: 'Una vez que la casa haya obtenido un certificado de finalización por parte del ayuntamiento y sea aprobada por su agente, puede recostarse en el sofá que ha elegido con cuidado y admirar su maravillosa nueva casa. El período de garantía de defectos, en el que el contratista está obligado a reparar cualquier defecto, dura aproximadamente 12 meses.',
  },
];

export const photoTitle = 'Foto'