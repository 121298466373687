export const AccessoriesSection = {
  title: 'Accessories',
  description:
    'Smart home accessories are designed to make your home more convenient, efficient, and enjoyable. From smart thermostats and lighting systems to voice-activated assistants and security cameras, there are a variety of accessories available to help you automate and control different aspects of.',
  stages: 'Stages',
  order: 'Order',
};

export const accessoriesIcons = {
  conditioners: 'Air Conditioners',

  purifiers: 'Air Purifiers',

  bridges: 'bridges',

  cameras: 'cameras',

  doorbells: 'doorbells',

  fans: 'fans',

  faucets: 'faucets',

  garageDoors: 'garage doors',

  humidifiers: 'humidifiers',

  lights: 'lights',

  locks: 'locks',

  outlets: 'outlets',

  receivers: 'receivers',

  routers: 'routers',

  security: 'security',

  sensors: 'sensors',

  speakers: 'speakers',

  sprinklers: 'sprinklers',

  switches: 'switches',

  thermostats: 'thermostats',

  tvs: 'TVs',

  windows: 'windows',
};
  

