import styled from 'styled-components';
import { colorVariables } from '../../../theme/Theme.constants';

export const MobileBlocks = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;

  border: none;

  .blured-button {
    margin-bottom: 0.875rem;
    border: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const Block = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${colorVariables.blockBorderColor};
`;

export const SloganBlock = styled(Block)`
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 100%;
  border: none;
  height: fit-content;
  padding: 1.25rem 0;
`;

export const DescriptionBlock = styled(Block)`
  padding-bottom: 2.875rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border: none;
  height: fit-content;
`;
