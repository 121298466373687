import React, { FC, useState } from 'react';
import {
  LoginPageWrapper,
  Wrapper,
  Title,
  AfterTitle,
  TextWrapper,
  FormWrapper,
  Button,
  InputWrapper,
  PopupWrapper,
  Popup,
  PopupText,
} from './LoginPage.styled';
import { Formik } from 'formik';

import { ReactComponent as EmailIcon } from '../../shared/assets/icons/login/email-icon.svg';
import { ReactComponent as PasswordIcon } from '../../shared/assets/icons/login/password.svg';
import { ReactComponent as IncorrectIcon } from '../../shared/assets/icons/login/incorrect-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';

import { useTranslation } from 'react-i18next';
import { TextAuthInput } from '../../shared/components/TextAuthInput';

export const LoginPage: FC = () => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { t } = useTranslation();
  const validate = (values: { email: string; password: string }) => {
    const errors: { email?: string; password?: string } = {};

    if (!values.email) {
      errors.email = '';
    }

    if (!values.password) {
      errors.password = '';
    }

    return errors;
  };

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  return (
    <LoginPageWrapper
      isSubmit={isSubmit}
      onClick={() => {
        if (!isSubmit) return;
        setIsSubmit(false);
      }}>
      <Wrapper isSubmit={isSubmit}>
        <TextWrapper>
          <Title>{t('loginPage.loginPage.mainTitle')}</Title>
          <AfterTitle>{t('loginPage.loginPage.text')}</AfterTitle>
        </TextWrapper>

        <Formik
          initialValues={{ email: '', password: '' }}
          validate={validate}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // setSubmitting(true);
            setIsSubmit(true);
            resetForm();
          }}>
          {({ isSubmitting, values, handleChange }) => (
            <FormWrapper>
              <InputWrapper>
                <TextAuthInput
                  type="email"
                  name="email"
                  placeholder={t('mainPage.ContactsTitles.email_placeholder')}
                  value={values.email}
                  onChange={handleChange}
                />
                <EmailIcon className="input-icon" />
              </InputWrapper>

              <InputWrapper>
                <TextAuthInput
                  type="password"
                  name="password"
                  placeholder={t(
                    'mainPage.ContactsTitles.password_placeholder',
                  )}
                  value={values.password}
                  onChange={handleChange}
                />
                <PasswordIcon className="input-icon" />
              </InputWrapper>

              <Button type="submit"> {t('loginPage.loginPage.btnText')}</Button>
            </FormWrapper>
          )}
        </Formik>
      </Wrapper>
      {isSubmit && (
        <PopupWrapper>
          <Popup>
            <IncorrectIcon />
            <PopupText>{t('loginPage.loginPage.popupIncorrectText')}</PopupText>
          </Popup>
        </PopupWrapper>
      )}
    </LoginPageWrapper>
  );
};
