import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import aboutBgImage from '../../shared/assets/images/construction/slider-img-1.jpg';
export const ConstructionPageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: ${colorVariables.alternativeBackground};
`;

export const PreConstructionSectionWrapper = styled.div``;

export const ProcessSectionWrapper = styled.div`
  padding: 2.5rem 0px 3.75rem;
  background-color: ${colorVariables.alternativeBackground};
  @media (min-width: ${Breakpoints.md}px) {
    padding: 2.5rem 0px 6.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 5rem 0px 1.25rem;
  }
`;

export const AboutSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(${aboutBgImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin-bottom: 6.25rem;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colorVariables.opacityConstructionBackground};
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    margin-bottom: 12.5rem;
  }
`;

export const AboutText = styled.div`
  position: relative;
  z-index: 5;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 22px;

    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }
`;
