export const blockTitle = 'Etapas de las casas modulares';

export const blockDescription = 'Consulta inicial';

export const modularHousesStepSection = {
  stepOne: {
    title: 'Diseño y planificación',
    p1: `Durante este paso inicial, se determinan el tamaño, la distribución y el estilo de la casa modular. Trabajando con un arquitecto o fabricante de casas modulares, se crea un diseño detallado, teniendo en cuenta los requisitos específicos y obteniendo los permisos necesarios.`,
    p2: ``,
  },
  stepTwo: {
    title: 'Preparación de los cimientos',
    p1: `La primera tarea de esta fase es despejar y nivelar el terreno. A continuación se excava y se construyen unos cimientos sólidos que cumplan las normas de construcción locales. La cimentación debe estar debidamente nivelada  para soportar las unidades modulares.`,
    p2: ``,
  },
  stepThree: {
    title: 'Fabricación de unidades modulares',
    p1: `En colaboración con un conocido fabricante de casas modulares, los módulos individuales se construyen en un entorno de fábrica controlado. Este proceso incluye la construcción de paredes, suelos, techos y la instalación de sistemas eléctricos y de fontanería en cada módulo.`,
    p2: ``,
  },
  stepFour: {
    title: 'Transporte y preparación del terreno',
    p1: `Una vez terminados los módulos, se transportan a la obra en camiones especializados. Mientras tanto, se prepara el terreno instalando las conexiones de los servicios públicos y completando las obras necesarias, como la nivelación y la jardinería.`,
    p2: ``,
  },
  stepFive: {
    title: 'Montaje e instalación',
    p1: `Los módulos se colocan sobre los cimientos con grúas o equipos elevadores. Técnicos especializados o equipos de construcción fijan los módulos entre sí, garantizando su correcta alineación y sellado. Se realizan las conexiones eléctricas y de fontanería entre los módulos y se añaden los toques finales.`,
    p2: ``,
  },
  stepSix: {
    title: 'Acabado interior y exterior',
    p1: `En este paso, la atención se centra en completar el trabajo interior y exterior. El trabajo interior implica la instalación de suelos, acabados de paredes, armarios y accesorios, mientras que el trabajo exterior incluye revestimientos, tejados, ventanas y puertas. Estos toques finales mejoran la funcionalidad y la estética de la casa modular.`,
    p2: ``,
  },
  stepSeven: {
    title: 'Integración de servicios y sistemas',
    p1: `El objetivo de este paso es conectar la casa modular a los servicios públicos locales, como el agua, la electricidad y el gas. Los sistemas eléctricos, de fontanería, calefacción y refrigeración se prueban a fondo para garantizar su correcto funcionamiento.`,
    p2: ``,
  },
  stepEight: {
    title: 'Inspecciones finales y certificación',
    p1: `Para garantizar el cumplimiento de los códigos y reglamentos de construcción, se programa una inspección final por parte de las autoridades locales. Esta inspección abarca todos los aspectos de la construcción de la casa modular. Una vez aprobada, se obtienen los certificados de ocupación o conformidad necesarios, lo que significa que la casa modular es legalmente habitable.`,
    p2: ``,
  },
};
