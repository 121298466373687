import i18next from 'i18next';
import { RoutePaths } from '../../shared/helpers/routes';

type FooterNavBar = {
  id: number;
  translationKey: string;
  label: string;
  link: string;
};
export const getFooterNavBar = (isDesktop: boolean): FooterNavBar[] => {
  let translationKeys;
  !isDesktop
    ? (translationKeys = [
        { id: 1, label: 'aboutUs', link: RoutePaths.aboutUsPage },
        { id: 2, label: 'services', link: RoutePaths.services },

        { id: 3, label: 'favourite', link: RoutePaths.favourites},
        { id: 4, label: 'contacts', link: RoutePaths.contactsPage },
      ])
    : (translationKeys = [
        { id: 1, label: 'services', link: RoutePaths.services },
        { id: 2, label: 'aboutUs', link: RoutePaths.aboutUsPage },

        { id: 3, label: 'contacts', link: RoutePaths.contactsPage },
        { id: 4, label: 'favourite', link:  RoutePaths.favourites },
      ]);

  return translationKeys.map(({ id, label, link }) => ({
    id: id,
    translationKey: label,

    label: i18next.t(`mainPage.footerNavBar.${label}`),
    link,
  }));
};
