export const philosophyTextsList = [
  {
    id: 1,
    label: 'Gestion des risques',
    description:
      "Nous adoptons une approche disciplinée de la gestion des risques, en mettant l'accent sur la préservation du capital et en évitant les pertes permanentes",
  },

  {
    id: 2,
    label: 'Investissement dans la valeur',
    description:
      'Nous cherchons à investir dans des sociétés de grande qualité, aux fondamentaux solides, qui se négocient à un prix inférieur à leur valeur intrinsèque',
  },

  {
    id: 3,
    label: 'Perspective à long terme',
    description:
      "Nous adoptons une approche patiente et à long terme de l'investissement, en nous concentrant sur les fondamentaux des entreprises dans lesquelles nous investissons",
  },
];

export const philosophyTitle =
  "Notre philosophie d'investissement repose sur trois principes clés:";