export const solarEnergySetupsTexts = {
  label: ' Енергетичні рішення для задоволення ваших потреб',
  description:
    'Наші сонячні панелі є ефективним і доступним способом генерації електроенергії для будинків та бізнесів. Наша компанія пропонує індивідуальні рішення для тих, хто хоче перейти на сонячну енергію та зменшити витрати на електроенергію. Наші проекти мають великий вплив на довкілля та допомагають зменшити вуглецевий слід.',
};

export const setupList = {
  typeBasic: ' Базовий',
  nameBasic: ' Базове налаштування',

  descriptionBasic:
    ' Наша базова установка включає сонячні панелі, батарею та інвертор для перетворення енергії, зібраної панелями, у придатну для використання електроенергію. Ця установка ідеально підходить для невеликих домогосподарств з меншим енергоспоживанням.',

  typeStandart: 'Стандарт',
  nameStandard: 'Стандартне налаштування',

  descriptionStandard:
    'Наша стандартна комплектація включає додаткові панелі та батареї для збільшення накопичення та виробництва енергії. Ця установка ідеально підходить для домогосподарств середнього розміру з середніми потребами в енергії.',

  typePremium: 'Преміум',
  namePremium: 'Преміум налаштування',

  descriptionPremium:
    ' Наша преміум-система включає першокласні панелі, батареї та інвертори з передовими системами моніторингу та керування. Ідеально підходить для великих домогосподарств і комерційних приміщень з високим енергоспоживанням.',
};
