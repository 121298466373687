export const AccessoriesSection = {
  title: 'Accessoris',
  description:
    `Els accessoris intel·ligents per a la llar estan dissenyats per fer que casa seva sigui més còmoda, eficient i agradable. Des de termòstats intel·ligents i sistemes d'il·luminació fins a assistents activats per veu i càmeres de seguretat, hi ha una gran varietat d'accessoris disponibles per ajudar-lo a automatitzar i controlar diferents aspectes de la seva llar.`,
  stages: 'Etapes',
  order: 'Sol·licitar',
};

export const accessoriesIcons = {
  conditioners: `Condicionadors d'air`,

  purifiers: `Purificadors d'aire`,

  bridges: 'Ponts',

  cameras: 'Càmeres',

  doorbells: 'Timbres',

  fans: 'Ventiladors',

  faucets: 'Aixetes',

  garageDoors: 'Portes de garatge',

  humidifiers: 'Humidificadors',

  lights: 'Llums',

  locks: 'Panys',

  outlets: 'Preses de corrent',

  receivers: 'Receptors',

  routers: 'Encaminadors',

  security: 'Seguretat',

  sensors: 'Sensors',

  speakers: 'Altaveus',

  sprinklers: 'Ruixadors',

  switches: 'Interruptors',

  thermostats: 'Termòstats',

  tvs: 'Televisors',

  windows: 'Finestres',
};
  

