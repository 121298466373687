export const headMenu = {
  style: 'Estilo',
  typology: 'Tipología',
  homes: 'Casas Nuevas',
};

export const typeHouseStyle = {
  pools: 'Piscinas',
  classic: 'Clásico',
  design: 'De Diseño',
  minimalist: 'Minimalista',
  modern: 'Moderno',
  original: 'Original',
  rustic: 'Rústico',
  traditional: 'Tradicional',
  wood: 'De madera',
};

export const inputsTexts = {
  numOfBedrooms: 'N° de habitaciones:',

  bedrooms: 'habitaciones',

  surfaceM: 'Superficie m2:',

  surface: 'superficie',

  numOfParking: 'N° de plazas de aparcamiento:',

  parking: 'aparcamiento',

  numOfBudget: 'N° de presupuestos:',

  budget: 'presupuesto',
};

export const searchBtn = 'Buscar';
export const removeAll = 'Eliminar todo';