import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';

export const TopNavBarWrapper = styled.div<{ isContactsPage: boolean }>`
  position: fixed;
  display: flex;
  top: 0;
  padding: ${paddings.tabletPagePadding};
  height: 3.75rem;
  width: 100vw;
  background-color: ${({ isContactsPage }) =>
    isContactsPage
      ? colorVariables.blackBackgroundColor
      : colorVariables.mobileOpacityBackgroundColor};
  backdrop-filter: blur(2px);
  color: ${colorVariables.mainTextColor};
  z-index: 20;

  @media only screen and (max-width: ${Breakpoints.md}px) {
    padding: ${paddings.mobilePagePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 calc((100vw - ${Breakpoints.maxWidth}px) / 2);
    height: 2.75rem;
    color: ${colorVariables.mainTextColor};
  }
`;
