import styled from 'styled-components';
import {
  ItemTitle,
  MenuList,
  MenuListItem,
} from '../../MenuListBlock/MenuListBlock.styled';
import { colorVariables } from '../../../theme/Theme.constants';

export const FAQBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 3.75rem;
  width: 100%;
  max-height: 627px;

  ${MenuList} {
    width: 19.5rem;
    flex-direction: column;
  }

  ${MenuListItem} {
    width: 100%;
    padding: 0 1.5rem;
    border: none;
    border-bottom: 1px solid ${colorVariables.blockBorderColor};
    height: 3rem;
    transition: 0.3s background-color ease;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: ${colorVariables.menuBackgroundColor};
    }
  }

  ${ItemTitle} {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const FAQMenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colorVariables.blockBorderColor};
`;

export const FAQMenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1.5rem;
  border: none;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.875rem;
  background-color: ${colorVariables.mainBackgroundColor};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;

  .question-icon {
    fill: ${colorVariables.mainIconColor};
    width: 0.875rem;
    width: 1.375rem;
  }
`;

export const FAQAnswersTable = styled.div`
  display: flex;
  max-height: 627px;
  flex-direction: column;
  width: 36rem;
`;

export const FAQAnswersTitleBlock = styled.div`
  padding: 0 1.5rem;
  border: 1px solid ${colorVariables.blockBorderColor};
  border-bottom: none;
  width: 19.5rem;
  min-height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.875rem;
`;

export const FAQAnswersTitle = styled(ItemTitle)`
  0.875rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const FAQAnswers = styled.ul`
overflow-y: scroll;
  0.875rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  border: 1px solid ${colorVariables.blockBorderColor};

  
  &::-webkit-scrollbar {
    width: 0;
  }
`;
