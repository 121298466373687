import { RoutePaths } from '../../../../shared/helpers/routes';

export const TopNavbarTitles = {
  // login: 'Iniciar sessió',
  call_to_action: 'Comenci el seu projecte',
};

export const navBarItemTitlesArray = [
  {
    itemName: 'Serveis',
    routePath: RoutePaths.services,
    storeItemName: 'Botiga',
  },
  {
    itemName: 'Sobre nosaltres',
    routePath: RoutePaths.aboutUsPage,
  },
  {
    itemName: 'Contactes',
    routePath: RoutePaths.contactsPage,
  },
  {
    itemName: 'Favorits',
    routePath: RoutePaths.favourites,
  },
  // {
  //   itemName: 'Connectar-se',
  //   routePath: RoutePaths.loginPage,
  // },
  {
    itemName: 'Inicieu el vostre projecte',
    routePath: RoutePaths.leadForm,
  },
];



export const mobileNavBarItemTitlesArray = {
  itemNameLeadForm: 'Inicieu el vostre projecte',
    routePathLeadForm: RoutePaths.leadForm,
  
  itemNameServices: 'Serveis',
  routePathServices: RoutePaths.services,
  
  itemNameAbout: 'Sobre nosaltres',
  routePathAbout: RoutePaths.aboutUsPage,

  itemNameContacts: 'Contactes',
  routePathContacts: RoutePaths.contactsPage,
 
  itemNameFavourite: 'Favorits',
  routePathFavourite: RoutePaths.favourites,
};