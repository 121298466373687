import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../theme/Theme.constants';

export const AccessoriesSectionWrapper = styled.div`
  position: relative;
  z-index: 15;
  width: 100vw;
  max-width: 1920px;
  height: fit-content;
  padding: ${paddings.mobilePagePadding};
  padding-bottom: 50px;
  padding-top: 50px; // delete after the card section returns
  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: ${paddings.tabletPagePadding};
    padding-bottom: 100px;
    padding-top: 100px; // delete after the card section returns
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
    padding-top: 100px; // delete after the card section returns
  }
`;

export const AccessoriesBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccessoriesHeader = styled.div`
  display: flex;
  border: 1px solid ${colorVariables.blockBorderColor};
  height: 12.25rem;
  background: ${colorVariables.alternativeBackground};
  flex-direction: column;

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    flex-direction: row;
  }
`;

export const AccessoriesTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 4.625rem;
  text-transform: uppercase;

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc(100% / 2);
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const AccessoriesDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
  height: 100%;
  width: 100%;
  font-family: 'Overpass';
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${colorVariables.whiteOpacityColor};
  border-top: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 2.75rem;
    width: calc(100% / 2);
    border-top: none;
  }
`;

export const AccessoriesIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${colorVariables.blockBorderColor};
  border-left: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    border-top: none;
  }
`;

export const AccessoriesIconBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 12.25rem;
  width: calc(100% / 2);
  background: ${colorVariables.alternativeBackground};
  border-right: 1px solid ${colorVariables.blockBorderColor};
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    width: calc(100% / 4);
  }

  @media only screen and (min-width: ${Breakpoints.xlg}px) {
    width: calc(100% / 8);
  }
`;

export const ShowMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12.25rem;
  width: calc(100% / 2);
  background: ${colorVariables.alternativeBackground};
  border: none;
  border-right: 1px solid ${colorVariables.blockBorderColor};
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  color: ${colorVariables.mainTextColor};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  cursor: pointer;
  transition: ${transitions.backgroundHoverTransition};

  &:hover {
    background-color: ${colorVariables.blackBackgroundColor};
  }
`;

export const AccessoriesIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const AccessoriesIconTitle = styled.div`
  text-transform: capitalize;
  text-align: center;
  padding: 0.675rem 0.475rem;
  border-top: 1px solid ${colorVariables.blockBorderColor};
  font-size: 13px;
`;
