import React, { FC, useEffect, useState } from 'react';
import {
  ClientLeadPageWrapper,
  ClientPartnerSwitcherBlock,
  PopupWrapper,
  Popup,
  PopupText,
} from './LeadForm.styled';
import { RadioSwitcher } from '../../shared/components/RadioSwitcher/RadiioSwitcher';
import { InformationBlock } from './InformationBlock/InformationBlock';
import { QuestionsBlock } from './QuestionsBlock/QuestionsBlock';
import { ContactedBlock } from './ContactedBlock/ContactedBlock';
import { FavouritesBlock } from './FavouritesBlock/FavouritesBlock';
import {
  useSendClientFormMutation,
  useSendPartnerFormMutation,
} from '../../api';
import { useNavigate } from 'react-router-dom';
import { mainSection } from '../../shared/helpers/globalConstants';
import { ReactComponent as IncorrectIcon } from '../../shared/assets/icons/lead-forms/incorrect-icon.svg';
import { ReactComponent as CorrectIcon } from '../../shared/assets/icons/lead-forms/correct-icon.svg';
import { BluredButton } from '../../shared/components/BluredButton';
import { setLeadFormTitle } from '../../redux/actions/globalActions';
import { useSelector } from 'react-redux';
import {
  getClientPartnerNavigationItems,
  getMainNavbarItems,
} from './LeadForm.constants';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const FormData = require('form-data');

export interface LeadFormDataProps {
  project_info_file?: any;
  name?: string;
  phone_number?: string;
  email?: string;
  comments_questions?: string;
  services_question?: string;
  preferred_contact_method?: string;
  project_status?: number[];
  services?: number[];
  organization?: string;
  position?: string;
}

const initialFormData = {
  project_info_file: null,
  name: '',
  phone_number: '',
  email: '',
  comments_questions: '',
  services_question: '',
  preferred_contact_method: '',
  project_status: [],
  services: [],
  organization: '',
  position: '',
};

export const LeadForm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const leadFormTitle = useSelector((state: any) => state.global.leadFormTitle);
  const formsNavigationItems = getMainNavbarItems();
  const clientPartnerNavigationItems = getClientPartnerNavigationItems();

  const [leadFormData, setLeadFormData] =
    useState<LeadFormDataProps>(initialFormData);
  const [fileName, setFileName] = useState<any>('');
  const [currentNavItem, setCurrentNavItem] = useState(
    formsNavigationItems[0].title,
  );
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const [sendClientForm, { isError: clientError }] =
    useSendClientFormMutation();
  const [sendPartnerForm, { isError: partnerError }] =
    useSendPartnerFormMutation();
  const formData = new FormData();

  useEffect(() => {
    dispatch(setLeadFormTitle(clientPartnerNavigationItems[0].title));
    setCurrentNavItem(formsNavigationItems[0].title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsNavigationItems[0].title]);

  const handleSendForm = () => {
    leadFormData.services?.length &&
      formData.append(`services`, leadFormData.services);
    formData.append('services_question', leadFormData.services_question);
    leadFormData.project_status?.length &&
      formData.append('project_status', leadFormData.project_status);
    formData.append('comments_questions', leadFormData.comments_questions);
    formData.append('name', leadFormData.name);
    formData.append('phone_number', leadFormData.phone_number);
    formData.append('email', leadFormData.email);
    leadFormData.organization &&
      formData.append('organization', leadFormData.organization);
    leadFormData.position && formData.append('position', leadFormData.position);
    formData.append(
      'preferred_contact_method',
      leadFormData.preferred_contact_method,
    );
    leadFormData.project_info_file &&
      formData.append('project_info_file', leadFormData.project_info_file);

    leadFormTitle === clientPartnerNavigationItems[0].title
      ? sendClientForm(formData)
      : sendPartnerForm(formData);

    setIsSubmitForm(true);
  };

  const handleGoBack = () => {
    mainSection?.scrollTo(0, 0);
    navigate(-1);
    setIsSubmitForm(false);
    dispatch(setLeadFormTitle(clientPartnerNavigationItems[0].title));
  };

  useEffect(() => {
    setCurrentNavItem(formsNavigationItems[0].title);
    setLeadFormData(initialFormData);
    setFileName('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadFormTitle]);

  return (
    <ClientLeadPageWrapper>
      <ClientPartnerSwitcherBlock>
        <RadioSwitcher
          navItems={clientPartnerNavigationItems}
          currentNavItem={leadFormTitle}
          setCurrentNavItem={setLeadFormTitle}
          isDispatch
        />
      </ClientPartnerSwitcherBlock>

      <RadioSwitcher
        navItems={formsNavigationItems}
        currentNavItem={currentNavItem}
        setCurrentNavItem={setCurrentNavItem}
        withoutHandleNavigation
      />
      {currentNavItem === formsNavigationItems[0].title && (
        <FavouritesBlock
          setCurrentNavItem={setCurrentNavItem}
          setLeadFormData={setLeadFormData}
          leadFormData={leadFormData}
        />
      )}
      {currentNavItem === formsNavigationItems[1].title && (
        <QuestionsBlock
          setCurrentNavItem={setCurrentNavItem}
          setLeadFormData={setLeadFormData}
          leadFormData={leadFormData}
          fileName={fileName}
          setFileName={setFileName}
          formData={formData}
        />
      )}
      {currentNavItem === formsNavigationItems[2].title && (
        <InformationBlock
          setCurrentNavItem={setCurrentNavItem}
          setLeadFormData={setLeadFormData}
          leadFormData={leadFormData}
        />
      )}
      {currentNavItem === formsNavigationItems[3].title && (
        <ContactedBlock
          setCurrentNavItem={setCurrentNavItem}
          setLeadFormData={setLeadFormData}
          leadFormData={leadFormData}
          handleSendForm={handleSendForm}
        />
      )}
      {isSubmitForm && (
        <PopupWrapper>
          <Popup isError={clientError || partnerError}>
            {clientError || partnerError ? <IncorrectIcon /> : <CorrectIcon />}
            <PopupText>
              {clientError || partnerError
                ? t('leadForm.leadFormPage.errorMessage')
                : t('leadForm.leadFormPage.accessMessage')}
            </PopupText>
            <BluredButton
              title={t('leadForm.leadFormPage.returnButton')}
              handleClick={() => handleGoBack()}
            />
          </Popup>
        </PopupWrapper>
      )}
    </ClientLeadPageWrapper>
  );
};
