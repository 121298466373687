export const headMenu = {
  style: 'Style',
  typology: 'Typology',
  homes: 'New Homes',
};

export const typeHouseStyle = {
  pools: 'Swimming pools',
  classic: 'Classic',
  design: 'Designer',
  minimalist: 'Minimalist',
  modern: 'Modern',
  original: 'Original',
  rustic: 'Rustic',
  traditional: 'Traditional',
  wood: 'Wooden',
};

export const inputsTexts = {
  numOfBedrooms: '№ of bedrooms:',

  bedrooms: 'bedrooms',

  surfaceM: 'Surface m2:',

  surface: 'surface',

  numOfParking: '№ of parking spaces:',

  parking: 'parking',

  numOfBudget: 'Budget:',

  budget: 'budget',
};

export const searchBtn = 'Search'

export const removeAll = 'Delete All';