export const aboutListLocation = 'Каталонія, Іспанія';

export const infoText = 'Чарівна сонячна іспанська вілла';

export const mainText =
  'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.';

export const headerBtnText = "Докладніше про інтер'єр";

export const titleAbout = 'Про проект';

export const textsAbout = {
  text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
};

export const textPhoto =
  'Аксесуари для розумного дому покликані зробити ваш дім зручнішим, ефективнішим і приємнішим. Від розумних термостатів і систем освітлення до голосових помічників і камер безпеки з голосовим керуванням - існує безліч аксесуарів, які допоможуть вам автоматизувати і контролювати різні аспекти вашого будинку".';

export const view = 'перегляд'
  
  export const housesDetailsMobile = [
    {
      number: 1,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 2,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 3,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 4,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 5,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
  ];

  export const housesDetailsDesktop = [
    {
      number: 1,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 2,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 3,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 4,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 5,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },

    {
      number: 6,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 7,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 8,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 9,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
    {
      number: 10,
      text: "Як тільки будинок отримає свідоцтво про завершення від міськради та буде затверджений вашим агентом, ви зможете зручитися на своєму обраному дивані та любуватися своїм чудовим новим будинком. Період виявлення дефектів - коли підрядник зобов'язаний виправити будь-які недоліки - зазвичай триватиме 12 місяців.",
    },
  ];

  export const photoTitle = 'Фото'