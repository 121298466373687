import interiorFirstPhoto from '../../shared/assets/images/interior-case/interior-first-photo.jpg';
import interiorSecondPhoto from '../../shared/assets/images/interior-case/interior-second-photo.jpg';

import interiorFirstPhotoDesktop from '../../shared/assets/images/interior-case/interior-first-photo-desktop.jpg';
import interiorSecondPhotoDesktop from '../../shared/assets/images/interior-case/interior-second-photo-desktop.jpg';
import interiorThirdPhotoDesktop from '../../shared/assets/images/interior-case/interior-third-photo-desktop.jpg';
import interiorFourthPhotoDesktop from '../../shared/assets/images/interior-case/interior-fourth-photo-desktop.jpg';
import i18next from 'i18next';

export const getHouseDetails = () => {
  const housesDetailsMobile = i18next.t(
    'interiorCasePage.housesDetailsMobile',
    {
      returnObjects: true,
    },
  ) as { number: number; text: string }[];

  const housesDetailsDesktop = i18next.t(
    'interiorCasePage.housesDetailsDesktop',
    {
      returnObjects: true,
    },
  ) as { number: number; text: string }[];

  return { housesDetailsDesktop, housesDetailsMobile };
};

const isDesktop = window.innerWidth > 768;

export const imageListMobile = [
  { id: 1, img: interiorFirstPhoto },
  {
    id: 2,
    img: interiorSecondPhoto,
  },
  {
    id: 3,
    img: interiorFirstPhoto,
  },
  {
    id: 4,
    img: interiorSecondPhoto,
  },
];
export const imageList = [
  { id: 1, img: interiorFirstPhotoDesktop },
  {
    id: 2,
    img: interiorSecondPhotoDesktop,
  },
  {
    id: 3,
    img: interiorFourthPhotoDesktop,
  },
  {
    id: 4,
    img: interiorThirdPhotoDesktop,
  },
  { id: 5, img: interiorFirstPhotoDesktop },
  {
    id: 6,
    img: interiorSecondPhotoDesktop,
  },
  {
    id: 7,
    img: interiorFourthPhotoDesktop,
  },
  {
    id: 8,
    img: interiorThirdPhotoDesktop,
  },
];
