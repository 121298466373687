export const ClientPartnerNavbarItems = {
  client: 'Клієнт',
  partner: 'Партнер',
};

export const MainNavbarItems = {
  favourite: 'Обране',
  questions: 'Питання',
  information: 'Інформація',
  contacted: `Зв'язатись`,
};

export const LeadFormPage = {
  // favourite block
  favouriteTitle: 'Ваші обрані послуги',
  favouriteDescription: 'Ми з нетерпінням чекаємо співпраці з вами!',
  fullCycleButton: 'Повністю усі проекти',
  clearList: 'Очистити список серверів',
  addService: 'Додати послугу',
  hide: 'Скрити',
  otherServices: 'Інші послуги',
  comentsOrQuestions: 'Коментарі та питання',
  backButton: 'Назад',
  nextButton: 'Далі',
  sendButton: 'Відправити',
  // question block
  questionTitle: 'Дайте відповідь на декілька питань',
  questionDescription:
    'Це допоможе вам знайти найкраще рішення для вашого проекту',
  projectStatus: 'Який статус вашого проекту зараз?',
  otherButton: 'Інший',
  commentsQuestionsPlaceholder: 'Коментарі або запитання',
  uploadFile: 'Завантажити файл',
  // information block
  informationTitle: 'Напишіть контактну інформацію',
  informationDescription:
    'Це для того, щоб ми могли зв’язатися з вами зручним для вас способом',
  name: `Ім'я*`,
  phoneNumber: 'Номер телефону*',
  email: 'Електронна пошта*',
  organization: 'Організація',
  position: 'Посада',
  checkboxText:
    'Встановлюючи цей прапорець, я підтверджую, що прочитав і погоджуюся на обробку моїх персональних даних відповідно до',
  privacyPolicy: 'політики конфіденційності.',
  mainNameError: `* Будь ласка, введіть ваше ім'я`,
  secondaryNameError: `* Ім'я не може починатися з пробілу`,
  mainPhoneError: '* Будь ласка, введіть свій номер',
  secondaryPhoneError: '* Неправильний номер телефону',
  mainEmailError: '* Будь ласка, введіть правильний email',
  secondaryEmailError: '* Неправильний формат електронної пошти',
  thirdEmailError: '* Використовуйте тільки англійські символи',
  // contacted block
  contactedTitle: `Як ви віддаєте перевагу, щоб з вами зв'язувалися?`,
  contactedDescription: `Виберіть зручний для нас спосіб зв'язку з вами`,
  whatsapp: 'Whatsapp',
  edit: 'Редагувати',
  phoneCall: 'Телефонний дзвінок',
  emailType: 'Електронна пошта',
  bookMeeting: 'Замовити зустріч',
  schedule: 'Запланувати дзвінок',
  errorMessage: 'Ой, щось пішло не так...',
  accessMessage: `Дякуємо за вашу заявку. Ми зв'яжемося з вами якнайшвидше.`,
  returnButton: 'Повернутися до Moderna Casa',
};
