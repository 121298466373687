export const AccessoriesSection = {
  title: 'Accessories',
  description:
    "Les accessoires pour la maison intelligente sont conçus pour rendre votre maison plus pratique, plus efficace et plus agréable. Des thermostats intelligents aux systèmes d'éclairage, en passant par les assistants à commande vocale et les caméras de sécurité, il existe une grande variété d'accessoires disponibles pour vous aider à automatiser et à contrôler différents aspects de votre maison.",
 
};
export const accessoriesIcons = {
  conditioners: 'les climatiseurs',

  purifiers: "Purificateurs d'air",

  bridges: 'Ponts',

  cameras: 'Caméras',

  doorbells: `Sonnettes d'entrée`,

  fans: 'Ventilateurs',

  faucets: 'Robinetterie',

  garageDoors: 'Portes de garage',

  humidifiers: 'Humidificateurs',

  lights: `Sonnette d'alarme`,

  locks: 'Serrures',

  outlets: 'Prises de courant',

  receivers: 'Récepteurs',

  routers: 'Routeurs',

  security: 'Sécurité',

  sensors: 'Détecteurs',

  speakers: 'Haut-parleurs',

  sprinklers: 'Arroseurs automatiques',

  switches: 'Interrupteurs',

  thermostats: 'Thermostats',

  tvs: 'Téléviseurs',

  windows: 'Fenêtres',
};
  

