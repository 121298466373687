import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';

export const HousesDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BlockWrapper = styled.div`
  position: relative;
  margin-bottom: 1.25rem;
  width: 25rem;
  @media (min-width: ${Breakpoints.md}px) {
    width: 48rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    width: 75rem;
  }
`;
export const MainImage = styled.img`
  width: 25rem;
  @media (min-width: ${Breakpoints.md}px) {
    width: 48rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    width: 75rem;
  }
`;

export const NumberWrapper = styled.div<{ isAnotherStyle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  background: ${props =>
    !props.isAnotherStyle
      ? `${colorVariables.alternativeBackground}`
      : `${colorVariables.alternativeTextColor}`};
  border: 1px solid rgba(255, 230, 98, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 0px 40px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  @media (min-width: ${Breakpoints.md}px) {
    width: 1.875rem;
    height: 1.875rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    width: 2.5rem;
    height: 2.5rem;

    background: ${props =>
      !props.isAnotherStyle
        ? `rgba(23, 23, 25, 0.4)`
        : `${colorVariables.alternativeTextColor}`};
    border: 1px solid ${colorVariables.alternativeTextColor};
    backdrop-filter: blur(2px) !important;
  }
`;

export const Number = styled.span<{ isAnotherStyle: boolean }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 1rem;

  text-align: center;

  color: ${props =>
    props.isAnotherStyle
      ? `${colorVariables.alternativeBackground}`
      : `${colorVariables.alternativeTextColor}`};
`;

export const TextWrapper = styled.div<{ isAnotherStyle?: boolean }>`
  ${props =>
    !props.isAnotherStyle &&
    `
  padding: 1rem;
  border: 1px solid ${colorVariables.navBarBorderColor};
  margin: 0 1.25rem;
  `}

  ${props =>
    props.isAnotherStyle &&
    `
      position: absolute;
     
      left: 2.187rem;
      width: 20rem;
      z-index: 10;
      padding: 1rem 2.187rem;
      background: ${colorVariables.alternativeTextColor};
       @media (max-width: ${Breakpoints.xlg}px){
         top: 0;
       }
       @media (min-width: ${Breakpoints.xlg}px){
         bottom: 0;
         left: 2.812rem;
       }
  `}
`;

export const Text = styled.p<{ isAnotherStyle?: boolean }>`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25rem;
  max-width: 18.125rem;
  text-align: center;

  ${props =>
    props.isAnotherStyle &&
    `
    text-align: left;
    max-width: 16.25rem;
    color: ${colorVariables.alternativeBackground};
  `}
`;

export const CurrentNumber = styled.span`
  color: ${colorVariables.alternativeTextColor};
`;
