import React, { FC, useState } from 'react';
import {
  PaginationWrapper,
  PaginationList,
  PaginationItem,
  PaginationNumber,
  PaginationIcon,
} from './PaginationBlock.styled';
import rightArrow from '../../shared/assets/icons/right-arrow.svg';
import { paginationList } from '../ProjectsSection/ProjectsSection.constants';

interface PaginationProps {
  setCurrentPage: any;
  currentPage: number;
}

export const PaginationBlock: FC<PaginationProps> = ({
  setCurrentPage,
  currentPage,
}) => {
  return (
    <PaginationWrapper>
      <PaginationList>
        {paginationList.map(({ number }) => {
          return (
            <PaginationItem key={number} onClick={() => setCurrentPage(number)}>
              <PaginationNumber
                style={{
                  color:
                    currentPage === number
                      ? '#FFE662'
                      : 'rgba(255, 255, 255, 0.6)',
                }}>
                {number}
              </PaginationNumber>
            </PaginationItem>
          );
        })}
        <PaginationItem
          onClick={() => setCurrentPage((prev: number) => prev + 1)}>
          <PaginationIcon src={rightArrow} />
        </PaginationItem>
      </PaginationList>
    </PaginationWrapper>
  );
};
