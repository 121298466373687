import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import { TextField } from '../../../shared/components/TextFieldInput/TextFieldInput.styled';

export const FooterDesktopWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  border-width: 0px 1px 0px 1px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const FooterLeftPart = styled.div`
  width: 100%;
`;

export const NavBarWrapper = styled.div`
  display: flex;
  padding: 1.37rem 0rem 1.37rem 3.12rem;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const MenuNameLinkWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
export const MenuName = styled.span`
  font-weight: 500;
  font-size: 0.87rem;
  line-height: 1.06rem;
  cursor: pointer;
  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
`;

export const MenuBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.43rem 0rem 3.43rem 3.12rem;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const MenuNameWrapper = styled.div`
  display: flex;
`;

export const IndentedMenuName = styled.div`
  margin-bottom: 0.62rem;
  margin-right: 0.62rem;
  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
`;

export const FooterRightPart = styled.div`
  padding-top: 4.75rem;
  width: 100%;
`;

export const FormTitle = styled.p`
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  text-transform: uppercase;
  text-align: center;
  color: ${colorVariables.mainTextColor};
  margin-bottom: 4.62rem;
`;

export const FormWrapper = styled.div`
  width: 100%;
  .form {
    width: 100%;
    display: flex;
  }
`;

export const FormInput = styled.input`
  width: 100%;
  height: 3.18rem;
  outline: none;
  background: ${colorVariables.blackBackgroundColor};
  color: rgba(255, 255, 255, 0.3);
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
  padding-left: 1.25rem;
`;

export const SendBtn = styled.button`
  width: 3.25rem;
  height: 3.25rem;
  cursor: pointer;
  background: ${colorVariables.footerFormBtn};
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #313131;
`;

export const SendIcon = styled.img`
  width: 1.37rem;
  height: 1.37rem;
`;

export const BottomLeftPartBlockWrapper = styled.div`
  display: flex;
  padding: 0.87rem 2.5rem;
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: ${colorVariables.navBarBorderColor};
`;

export const SocialBlockWrapper = styled.div`
  margin-right: 3.75rem;
`;

export const NetworkIconWrapper = styled.a`
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
`;
export const NetworkIcon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
`;

export const ContactsBlockWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactsNameWrapper = styled.a`
  &:not(:last-child) {
    margin-right: 25px;
  }
`;
export const ContactsName = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.06rem;

  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }
`;
export const BottomRightPartBlockWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PrivacyBlockWrapper = styled.div`
  display: flex;
  padding: 21px 31px;
  border-right: 1px solid ${colorVariables.navBarBorderColor};
  cursor: pointer;
`;

export const PrivacyText = styled.span`
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 1.06rem;

  text-decoration-line: underline;

  transition: ${transitions.textHoverTransition};
  color: ${colorVariables.mainTextColor};
  &:hover {
    color: ${colorVariables.secondaryTextColor};
  }

  &:not(:last-child) {
    margin-right: 1.87rem;
  }
`;

export const ReservedBlock = styled.div`
  padding-left: 1.5rem;
`;

export const ReservedTitle = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.93rem;

  color: ${colorVariables.footerReservedTitle};
`;

export const TextFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  & ${TextField} {
    height: 100%;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }
`;

export const ErrorMesage = styled.div`
  position: absolute;
  bottom: 0.25rem;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${colorVariables.errorColor};
  left: 1.25rem;
  width: 100%;
  height: 100%
  z-index: 20;
`;

export const PopupWrapper = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
`;

export const Popup = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid
    ${props =>
      props.isError ? colorVariables.errorColor : colorVariables.succesColor};
  width: 90%;
  height: 14.062rem;
  background: ${colorVariables.alternativeBackground};
  padding: 0 6.75rem;

  @media (min-width: ${Breakpoints.md}px) {
    width: 36.25rem;
  }

  & button {
    margin-top: 1.25rem;
    width: fit-content;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    background: rgba(255, 230, 98, 0.1);
    border: 1px solid ${colorVariables.yellowButtonBorder};
    color: ${colorVariables.alternativeTextColor};
  }
`;

export const PopupText = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 1.25rem;
`;
