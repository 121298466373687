import React, { FC } from 'react';
import {
  PartnersMobileWrapper,
  // AppleIcon,
  IconBlock,
  TitleBlock,
  MIIcon,
  AjaxIcon,
  SmartLifeIcon,
  LifeSmartIcon,
  DescriptionBlock,
  IconsBlock,
} from './PartnersMobile.styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppleIcon} from '../../../../shared/assets/icons/smart-home/Apple.svg'
export const PartnersMobile: FC = () => {
  const { t } = useTranslation();

  return (
    <PartnersMobileWrapper id="partners-section">
      <TitleBlock>
        <span>{t('smartHome.PartnersSection.title')}</span>
      </TitleBlock>
      <DescriptionBlock>
        {t('smartHome.PartnersSection.description')}
      </DescriptionBlock>
      <IconsBlock>
        <IconBlock>
          <AppleIcon
            className='apple-icon'
           
          />
        </IconBlock>
        <IconBlock>
          <MIIcon />
        </IconBlock>
        <IconBlock>
          <AjaxIcon />
        </IconBlock>
      </IconsBlock>
      <IconsBlock>
        <IconBlock>
          <SmartLifeIcon />
        </IconBlock>
        <IconBlock>
          <LifeSmartIcon />
        </IconBlock>
      </IconsBlock>
    </PartnersMobileWrapper>
  );
};
