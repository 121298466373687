import { GlobalActionTypes } from '../actionTypes/globalActionTypes';

export const setLeadFormTitle = (payload: string) => ({
  type: GlobalActionTypes.SET_LEAD_FORM,
  payload,
});

export const setQuestionData = (payload: string[] | []) => ({
  type: GlobalActionTypes.SET_QUESTION_DATA,
  payload,
});
