import React, { FC, useEffect } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { RadioSwitcherWrapper } from './RadiioSwitcher.styled';
import { useDispatch } from 'react-redux';

export interface NavItemProps {
  id: number;
  title: string;
  icon?: React.ReactNode;
}

interface RadioSwitcherProps {
  navItems: NavItemProps[];
  currentNavItem: string;
  setCurrentNavItem: any;
  withoutHandleNavigation?: boolean;
  isDispatch?: boolean;
}

export const RadioSwitcher: FC<RadioSwitcherProps> = ({
  navItems,
  currentNavItem,
  setCurrentNavItem,
  withoutHandleNavigation,
  isDispatch,
}) => {
  const dispatch = useDispatch();
  return (
    <RadioSwitcherWrapper>
      <BottomNavigation
        showLabels
        value={currentNavItem}
        onChange={(event, newValue) => {
          if (isDispatch) {
            dispatch(setCurrentNavItem(newValue));
          } else {
            !withoutHandleNavigation && setCurrentNavItem(newValue);
          }
        }}>
        {navItems.map((navItem: NavItemProps) => (
          <BottomNavigationAction
            icon={navItem.icon}
            label={navItem.title}
            value={navItem.title}
            key={navItem.id}
          />
        ))}
      </BottomNavigation>
    </RadioSwitcherWrapper>
  );
};
