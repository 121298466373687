export const ClientPartnerNavbarItems = {
  client: 'Client',
  partner: 'Soci',
};

export const MainNavbarItems = {
  favourite: 'Favorit',
  questions: 'Preguntes',
  information: 'Informació',
  contacted: `Contacti'ns`,
};

export const LeadFormPage = {
  // favourite block
  favouriteTitle: 'Els seus serveis favorits',
  favouriteDescription: 'Esperem amb interès treballar amb vostè!',
  fullCycleButton: 'Un projecte de cicle complet',
  clearList: 'Esborrar llista de serveis',
  addService: 'Agregar un servei',
  hide: 'Amagar',
  otherServices: 'Altres serveis',
  comentsOrQuestions: 'Comentaris o preguntes',
  backButton: 'Enrere',
  nextButton: 'Següent',
  sendButton: 'Enviar',
  // question block
  questionTitle: 'Respongui algunes preguntes',
  questionDescription:
    `ixò l'ajudarà a trobar la millor solució per al seu projecte.`,
  projectStatus: `uin és l'estat del seu projecte en aquest moment?`,
  otherButton: 'Un altre',
  commentsQuestionsPlaceholder: 'Comentaris o preguntes',
  uploadFile: 'Carregar arxiu',
  // information block
  informationTitle: 'Escrigui la seva informació de contacte',
  informationDescription:
    `ixò és perquè puguem comunicar-nos amb vostè d'una manera que li sigui convenient.`,
  name: 'Nom*',
  phoneNumber: 'Número de telèfon*',
  email: 'Correu electrònic*',
  organization: 'Organització',
  position: 'Lloc de treball',
  checkboxText:
    `n marcar aquesta casella, confirmo que he llegit i accepto el processament de les meves dades personals d'acord amb la política de privacitap`,
  privacyPolicy: 'política de privacitat.',
  mainNameError: '* Per favor, introdueixi el seu nom',
  secondaryNameError: '* El nom no pot començar amb espais en blanc',
  mainPhoneError: '* Per favor, introdueixi el seu número',
  secondaryPhoneError: '* Número de telèfon no vàlid',
  mainEmailError: ` Introdueixi l' adreça de correu electrònic`,
  secondaryEmailError: '* Format de correu electrònic no vàlid',
  thirdEmailError: '*Utilitzi només caràcters anglesos',
  // contacted block
  contactedTitle: 'Com prefereix que li contactin?',
  contactedDescription:
    'Triï la forma més convenient perquè ens comuniquem amb vostè',
  whatsapp: 'Whatsapp',
  edit: 'Editar',
  phoneCall: 'Trucada telefònica',
  emailType: 'Correu electrònic',
  bookMeeting: 'Reserveu una reunió',
  schedule: 'Programar una trucada',
  errorMessage: 'Ooops alguna cosa ha sortir malament...',
  accessMessage: `Gràcies per la seva sol·licitud. Ens posarem en contacte amb vostè al més aviat possible.`,
  returnButton: 'Tornar a Moderna Casa',
};
