import React, { FC, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { LanguageDropdown } from '../../LanguageDropdown';
import { HeaderTitlesEn } from '../../HeaderSection/HeaderSection.constatnts';

import {
  LogoBlock,
  LogoImg,
  LogoTitle,
  NavBarTabletBlocks,
  NavItem,
  NavItemsBlock,
  SettingsBlock,
} from './NavbarTablet.styled';
import { NavBurger } from '../../NavBurger';
import { Breakpoints } from '../../../theme/Theme.constants';
import { NavBurgerMobile } from '../../NavBurger/MobileBlock';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { RoutePaths } from '../../../shared/helpers/routes';
import { mainSection } from '../../../shared/helpers/globalConstants';

export const NavBarTablet: FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [activeId, setActiveId] = useState<number | null>(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [menuIsOpen]);

  const handleNavBarSectionClick = (routePath: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <>
      <NavBarTabletBlocks>
        <LogoBlock
          onClick={() => handleNavBarSectionClick(RoutePaths.mainPage)}>
          <LogoImg />
          <LogoTitle>{HeaderTitlesEn.LOGO_TEXT}</LogoTitle>
        </LogoBlock>
        <NavItemsBlock>
          {!isMobile && (
            <> 
              {/* <NavItem      /////////  FOR FUTURE FEATURES //////////
                onClick={() => handleNavBarSectionClick(RoutePaths.loginPage)}> 
                {t('mainPage.TopNavbarTitles.login')}
              </NavItem> */}
              <NavItem
                onClick={() => handleNavBarSectionClick(RoutePaths.leadForm)}>
                {t('mainPage.TopNavbarTitles.call_to_action')}
              </NavItem>
            </>
          )}
          <SettingsBlock>
            {/* WE ARE WAITING FOR THE TRANSLATION */}
            <LanguageDropdown />
            {isMobile ? (
              <NavBurgerMobile
                menuIsOpen={menuIsOpen}
                setMenuIsOpen={setMenuIsOpen}
                activeId={activeId}
                setActiveId={setActiveId}
              />
            ) : (
              <NavBurger
                menuIsOpen={menuIsOpen}
                setMenuIsOpen={setMenuIsOpen}
                activeId={activeId}
                setActiveId={setActiveId}
              />
            )}
          </SettingsBlock>
        </NavItemsBlock>
      </NavBarTabletBlocks>
    </>
  );
};
