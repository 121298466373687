export const aboutListText = {
  peopleText:
    'Comptem amb un equip de professionals experimentats dedicats a prestar un servei excel·lent als nostres clients.',
  bookText:
    `Tenim un historial provat d'èxit, amb molts clients satisfets.`,
  clipboardText:
    'Oferim una àmplia gamma de serveis per satisfer les necessitats úniques dels nostres clients.',
  microchipText:
    'Utilitzem les últimes tecnologies i estratègies de màrqueting per garantir que les propietats dels nostres clients siguin vistes per les persones adequades.',
  handshakeText:
    'Proporcionem assistència jurídica per garantir que totes les transaccions es gestionin de forma fluida i eficaç.',
};