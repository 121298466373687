import React, { FC } from 'react';
import {
  Block,
  SloganBlock,
  DescriptionBlock,
  RightBlock,
  LeftBlock,
  EmptyBlocks,
  TabletBlocks,
  ButtonsBlock,
  EmptyBlock
} from './HeaderTablet.styled';
import { HeaderMenuComponent } from '../../HeaderMenuComponent';
import { BluredButton } from '../../../shared/components/BluredButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { mainSection } from '../../../shared/helpers/globalConstants';
import { RoutePaths } from '../../../shared/helpers/routes';
import { setLeadFormTitle } from '../../../redux/actions/globalActions';
import { useDispatch } from 'react-redux';
import { getClientPartnerNavigationItems } from '../../../scenes/LeadForm/LeadForm.constants';

export interface HeaderTabletProps {
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
}

export const HeaderTablet: FC<HeaderTabletProps> = ({
  setActiveId,
  activeId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientPartnerNavigationItems = getClientPartnerNavigationItems();

  const handleNavBarSectionClick = (routePath: string, formTitle: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
    dispatch(setLeadFormTitle(formTitle));
  };
  return (
    <TabletBlocks>
      <RightBlock>
        <SloganBlock
          dangerouslySetInnerHTML={{
            __html: (t('mainPage.headerTitles.SLOGAN_TEXT') || '') as string, // added to make translation and indentation(br) work
          }}></SloganBlock>
        <DescriptionBlock>
          {t('mainPage.headerTitles.HEADER_DESCRIPTION')}
        </DescriptionBlock>
        {/* <BluredButton
          title={t('mainPage.headerTitles.HOW_WE_WORK_BUTTON')}   /// for future features
          handleClick={() => {}}
          className="blured-button"
        /> */}
        <EmptyBlock></EmptyBlock>
      </RightBlock>
      <LeftBlock>
        <HeaderMenuComponent setActiveId={setActiveId} activeId={activeId} />
        <EmptyBlocks>
          <Block></Block>
          <Block></Block>
        </EmptyBlocks>
        <ButtonsBlock>
          <BluredButton
            title={t('mainPage.headerTitles.CLIENT_BUTTON')}
            handleClick={() =>
              handleNavBarSectionClick(
                RoutePaths.leadForm,
                clientPartnerNavigationItems[0].title,
              )
            }
            className="blured-button"
          />
          <BluredButton
            title={t('mainPage.headerTitles.PARTNER_BUTTON')}
            handleClick={() =>
              handleNavBarSectionClick(
                RoutePaths.leadForm,
                clientPartnerNavigationItems[1].title,
              )
            }
            className="blured-button"
          />
        </ButtonsBlock>
      </LeftBlock>
    </TabletBlocks>
  );
};
