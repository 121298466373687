import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CasePhotoWrapper,
  HeadWrapper,
  TitleWrapper,
  Title,
  TextWrapper,
  Text,
  ImgList,
  ImgItem,
  Img,
  ImgItemWrapper,
} from './CasePhotoSection.styled';

interface CasePhotoProps {
  imageList: { id: number; img: string }[];
  text: string;
}
export const CasePhotoSection: FC<CasePhotoProps> = ({ imageList, text }) => {
   const { t } = useTranslation();
  return (
    <CasePhotoWrapper>
      <HeadWrapper>
        <TitleWrapper>
          <Title>{t('interiorCasePage.photoTitle')}</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text>{text}</Text>
        </TextWrapper>
      </HeadWrapper>

      <ImgList>
        {imageList.map(({ id, img }, index) => {
          const isBigImage = index % 4 === 1 || index % 4 === 2;
          if (index % 2 === 0) {
            return (
              <ImgItemWrapper key={id} big={isBigImage}>
                <ImgItem>
                  <Img src={img} />
                </ImgItem>
                {imageList[index + 1] && (
                  <ImgItem>
                    <Img src={imageList[index + 1].img} />
                  </ImgItem>
                )}
              </ImgItemWrapper>
            );
          }
        })}
      </ImgList>
    </CasePhotoWrapper>
  );
};
