export const footerNavBar = {
  aboutUs: 'Sobre nosaltres',
  services: 'Serveis',
  favourite: 'Favorits',
  contacts: 'Contactes',
};

export const footerTitles = {
  conditions: `Condicions d' ús`,
  privacy: 'Política de privacitat',
  updated: 'Mantingueu-vos actualitzats amb nosaltres',
  reserved: '© MODERNACASA (2023) Tots els drets reservats',
  placeholder: 'Correu electrònic',
};