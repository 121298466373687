import React, { FC } from 'react';
import {
  FAQMenuTitle,
  FAQAnswersTable,
  FAQAnswers,
  FAQBlock,
  SelectComponent,
  MenuItemComponent,
} from './FAQMobile.styled';
import { FAQItem } from '../FAQItem';
import {
  FaqItemProps,
  MenuItemProps,
} from '../../MenuSection/MenuSection.types';
import { FaqComponentProps } from '../FAQSection.types';
import FormControl from '@mui/material/FormControl';

import { Breakpoints } from '../../../theme/Theme.constants';
import { useMediaQuery } from 'react-responsive';

export const FAQMobile: FC<FaqComponentProps> = ({
  activeIdAfterClick,
  setActiveIdAfterClick,
  menuItems,
  activeFAQId,
  setActiveFAQId,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const filteredFaqData = menuItems.filter(
    (faqItem: MenuItemProps) =>
      faqItem.id !== 2 && faqItem.id !== 16 && faqItem.id !== 13,
  ); // remove when we will have copyright for other services
  return (
    <FAQBlock>
      <FAQMenuTitle>FAQ</FAQMenuTitle>
      <FormControl sx={{ m: 1, minWidth: 120, position: 'relative ' }}>
        <SelectComponent
          value={menuItems[activeIdAfterClick - 1].id}
          onChange={() => {}}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                left: `${isMobile && '0 !important'}`,
              },
            },
          }}
          inputProps={{ 'aria-label': 'Without label' }}>
          {filteredFaqData.map((menuItem: MenuItemProps) => (
            <MenuItemComponent
              key={menuItem.id}
              onClick={() => setActiveIdAfterClick(menuItem.id)}
              value={menuItem.id}>
              {menuItem.icon}
              {menuItem.label}
            </MenuItemComponent>
          ))}
        </SelectComponent>
      </FormControl>
      <FAQAnswersTable>
        <FAQAnswers>
          {menuItems[activeIdAfterClick - 1].faq.map(
            (faqItem: FaqItemProps) => (
              <FAQItem
                activeIdAfterClick={activeIdAfterClick}
                {...faqItem}
                activeFAQId={activeFAQId}
                setActiveFAQId={setActiveFAQId}
                key={faqItem.title + faqItem.id + faqItem.description}
              />
            ),
          )}
        </FAQAnswers>
      </FAQAnswersTable>
    </FAQBlock>
  );
};
