import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';

export const TextSection = styled.div`
  background-color: ${colorVariables.alternativeBackground};

  padding-bottom: 7.5rem;
`;
export const FirstBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1.25rem 0px;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 5rem 2.5rem 0px;
  }
  @media (min-width: ${Breakpoints.lg}px) {
    padding: 5rem 0px 0px;
  }
`;


export const TextWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }
  max-width: 50rem;
 
   
  

 
`;
export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  margin-bottom: 1.25rem;
  color: ${colorVariables.alternativeTextColor};
`;

export const Text = styled.p`
   font-weight: 400;
    font-size: 10px;
    line-height: 15px;

    color: ${colorVariables.footerReservedTitle};

    @media (min-width: ${Breakpoints.lg}px) {
      font-size: 14px;
      line-height: 21px;
    }
`