import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';
import bgImage from '../../../shared/assets/images/building-materials/benefits-mobile.jpg';
import bgImageTablet from '../../../shared/assets/images/building-materials/benefits-tablet.jpg';
import bgImageDesktop from '../../../shared/assets/images/building-materials/benefits-desktop.jpg';
export const BenefitsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.5rem 0px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 23, 25, 0.3);
  }

  @media (max-width: ${Breakpoints.xlg}px) {
    align-items: center;
  }
  @media (min-width: ${Breakpoints.md}px) {
    background-image: url(${bgImageTablet});
    padding: 5rem 0px;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    background-image: url(${bgImageDesktop});
    padding: 7.5rem 0px;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: ${Breakpoints.md}px) {
    width: 100%;
  }
  z-index: 10;

  @media (min-width: ${Breakpoints.xlg}px) {
    margin-left: 6.25rem;
  }
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;

  color: #ffffff;

  text-shadow: 0px 0px 20px ${colorVariables.tabletOpacityBackgroundColor};
  backdrop-filter: blur(2px);
  margin-bottom: 2.5rem;

  @media (max-width: ${Breakpoints.xlg}px) {
    text-align: center;
  }

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 2.5rem;
    line-height: 49px;
    margin-bottom: 3.75rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
  }
`;
export const List = styled.ul`
  @media (min-width: ${Breakpoints.xlg}px) {
    width: 100%;
  }
`;

export const Item = styled.li`
  background: rgba(23, 23, 25, 0.1);
  padding: 1.875rem 1.25rem;
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  backdrop-filter: blur(0.625rem) !important;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem 3.125rem;

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    display: flex;
    justify-content: center;
    padding: 1.875rem 0;
    width: 27.812rem;
  }
`;

export const Text = styled.p`
  font-family: 'Overpass';
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 25px;
  text-align: center;

  text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);

  @media (min-width: ${Breakpoints.xlg}px) {
    max-width: 18.75rem;
  }
`;


