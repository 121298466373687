import styled from 'styled-components';
import plus from '../../../shared/assets/icons/faq/plus.svg';
import minus from '../../../shared/assets/icons/faq/minus.svg';
import { colorVariables } from '../../../theme/Theme.constants';
import { motion } from 'framer-motion';

export const FAQcontent = styled.li`
  width: 100%;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  &:last-child {
    border-bottom: none;
  }
`;

export const ContentTitle = styled.div<{ isOpen: boolean }>`
  color: ${props => (props.isOpen ? 'yellow' : 'white')};
`;

export const FAQIcon = styled.div<{ isOpen: boolean }>`
  background-repeat: no-repeat;
  background-position: center center;
  // background-size: cover;
  width: 1.375rem;
  height: 1.375rem;
  background-image: url(${props => (props.isOpen ? minus : plus)});
`;

export const ContentDescription = styled(motion.div)`
  height: 100%;
  padding: 0 1.25rem 1.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: ${colorVariables.mainTextColorWitOpacity};
`;
// animation: ${slideDown} 1s ease forwards;
export const ContentTitleBlock = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  padding: 1.25rem;
  cursor: pointer;
`;
// &:active ${ContentDescription} {
//   animation: ${slideDown} 1s ease reverse forwards;
// }
