import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MenuItemComponent,
  SelectComponent,
  TechnologiesBlock,
  TechnologiesDescription,
  TechnologiesInfoBlock,
  TechnologiesInfoBlockWrapper,
  TechnologiesSectionWrapper,
  TechnologiesTitle,
  TechnologyContentWrapper,
  TechnologyDescription,
  TechnologyImage,
} from './TechnologySection.styled';
import {
  NavItemProps,
  RadioSwitcher,
} from '../../../shared/components/RadioSwitcher/RadiioSwitcher';
import { ReactComponent as TechnologyTemplateImage } from '../../../shared/assets/images/swiming-pools/technology/technology-card-template.svg';
import carbon from '../../../shared/assets/images/swiming-pools/technology/carbon.png';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';
import i18next from 'i18next';

export const TechnologySection: FC = () => {
  const { t } = useTranslation();
  const initialNavItem =
    t('swimmingPools.TechnologiesSection.gelcoatAndColor') || '';
  const [currentNavItem, setCurrentNavItem] = useState<string>(initialNavItem);

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const technologiesContentData = {
    [t('swimmingPools.TechnologiesSection.gelcoatAndColor')]: {
      id: 1,
      description: t(
        'swimmingPools.TechnologiesSection.gelcoatAndColorDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.closedBeam')]: {
      id: 2,
      description: t('swimmingPools.TechnologiesSection.closedBeamDescription'),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.vinylesterBase')]: {
      id: 3,
      description: t(
        'swimmingPools.TechnologiesSection.vinylesterBaseDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.balsaReinforcement')]: {
      id: 4,
      description: t(
        'swimmingPools.TechnologiesSection.balsaReinforcementDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.vinylester')]: {
      id: 5,
      description: t('swimmingPools.TechnologiesSection.vinylesterDescription'),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.glassFiberMats')]: {
      id: 6,
      description: t(
        'swimmingPools.TechnologiesSection.glassFiberMatsDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.ceramicLayer')]: {
      id: 7,
      description: t(
        'swimmingPools.TechnologiesSection.ceramicLayerDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.polyesterLayer')]: {
      id: 8,
      description: t(
        'swimmingPools.TechnologiesSection.polyesterLayerDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.carbonFibreMats')]: {
      id: 9,
      description: t(
        'swimmingPools.TechnologiesSection.carbonFibreMatsDescription',
      ),
      image: carbon,
    },
    [t('swimmingPools.TechnologiesSection.exteriorTopCoat')]: {
      id: 9,
      description: t(
        'swimmingPools.TechnologiesSection.exteriorTopCoatDescription',
      ),
      image: carbon,
    },
  };

  const technologiesData = [
    {
      id: 1,
      title: t('swimmingPools.TechnologiesSection.gelcoatAndColor'),
    },
    {
      id: 2,
      title: t('swimmingPools.TechnologiesSection.closedBeam'),
    },
    {
      id: 3,
      title: t('swimmingPools.TechnologiesSection.vinylesterBase'),
    },
    {
      id: 4,
      title: t('swimmingPools.TechnologiesSection.balsaReinforcement'),
    },
    {
      id: 5,
      title: t('swimmingPools.TechnologiesSection.vinylester'),
    },
    {
      id: 6,
      title: t('swimmingPools.TechnologiesSection.glassFiberMats'),
    },
    {
      id: 7,
      title: t('swimmingPools.TechnologiesSection.ceramicLayer'),
    },
    {
      id: 8,
      title: t('swimmingPools.TechnologiesSection.polyesterLayer'),
    },
    {
      id: 9,
      title: t('swimmingPools.TechnologiesSection.carbonFibreMats'),
    },
    {
      id: 10,
      title: t('swimmingPools.TechnologiesSection.exteriorTopCoat'),
    },
  ];

  useEffect(() => {
    i18next.on('languageChanged', lng => {
      setCurrentNavItem(
        t('swimmingPools.TechnologiesSection.gelcoatAndColor') || '',
      );
    });
  }, [t]);
  return (
    <TechnologiesSectionWrapper id="technologies-section">
      <TechnologiesInfoBlockWrapper>
        <TechnologiesInfoBlock>
          <TechnologiesTitle>
            {t('swimmingPools.TechnologiesSection.title')}
          </TechnologiesTitle>
          <TechnologiesDescription>
            {t('swimmingPools.TechnologiesSection.description')}
          </TechnologiesDescription>
        </TechnologiesInfoBlock>
      </TechnologiesInfoBlockWrapper>
      <TechnologiesBlock>
        {isMobile ? (
          <SelectComponent
            value={currentNavItem}
            onChange={() => {}}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}>
            {technologiesData.map((technologyItem: NavItemProps) => (
              <MenuItemComponent
                key={technologyItem.id}
                onClick={() => setCurrentNavItem(technologyItem.title)}
                value={technologyItem.title}>
                {technologyItem.title}
              </MenuItemComponent>
            ))}
          </SelectComponent>
        ) : (
          <RadioSwitcher
            navItems={technologiesData}
            currentNavItem={currentNavItem}
            setCurrentNavItem={setCurrentNavItem}
          />
        )}
      </TechnologiesBlock>
      <TechnologyContentWrapper>
        <TechnologyDescription>
          {technologiesContentData[currentNavItem]?.description}
        </TechnologyDescription>
        <TechnologyImage>
          <img
            src={technologiesContentData[currentNavItem]?.image}
            alt="technology"
            loading="lazy"
          />
        </TechnologyImage>
      </TechnologyContentWrapper>
    </TechnologiesSectionWrapper>
  );
};
