import styled from 'styled-components';
import { colorVariables } from '../../../../theme/Theme.constants';
import apple from '../../../../shared/assets/icons/smart-home/Apple.svg';
import mi from '../../../../shared/assets/icons/smart-home/MI.svg';
import ajax from '../../../../shared/assets/icons/smart-home/ajax.svg';
import smartLife from '../../../../shared/assets/icons/smart-home/smart-life.svg';
import lifeSmart from '../../../../shared/assets/icons/smart-home/life-smart.svg';

export const PartnersMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  border-top: 1px solid ${colorVariables.blockBorderColor};
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 7.5rem;
  width: 100%;
`;

export const IconBlock = styled(Block)`
  padding: 1rem;
  border-right: 1px solid ${colorVariables.blockBorderColor};

  &:last-child {
    border-right: 1px solid ${colorVariables.blockBorderColor};
  }

  & .apple-icon {
   fill: ${colorVariables.mainIconColor};
    width: 2.125rem;
    height: 2.5rem;
  }
`;

export const TitleBlock = styled(Block)`
  font-weight: 700;
  font-size: 3rem;
  line-height: 100%;
  padding: 1.5rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
`;

export const DescriptionBlock = styled(Block)`
  font-family: 'Overpass';
  font-size: 12px;
  line-height: 20px;
  padding: 1rem;
  background: inherit;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  height: 100%;
`;

export const IconsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
`;

export const Icon = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const AppleIcon = styled(Icon)`
  width: 34px;
  height: 40px;
  background-image: url(${apple});
`;

export const MIIcon = styled(Icon)`
  width: 35px;
  height: 35px;
  background-image: url(${mi});
`;

export const AjaxIcon = styled(Icon)`
  width: 74px;
  height: 18px;
  background-image: url(${ajax});
`;

export const SmartLifeIcon = styled(Icon)`
  width: 90px;
  height: 52px;
  background-image: url(${smartLife});
`;

export const LifeSmartIcon = styled(Icon)`
  width: 74px;
  height: 14px;
  background-image: url(${lifeSmart});
`;
