export const navItems = {
  services: 'Services',
  benefits: 'Avantages',
  stages: 'Étapes',
};

export const description =
  "Vous souhaitez transformer votre espace extérieur en une oasis belle et fonctionnelle ? Ne cherchez pas plus loin que nos services experts en aménagement paysager. Notre équipe de designers et d'architectes expérimentés est là pour vous aider à transformer votre vision en réalité.";

export const currentTexts = {
  label: 'Services',
  description: `Nos services d'aménagement paysager proposent une large gamme de services pour vous aider à transformer votre espace extérieur. Nos services comprennent:`,
};
export const processList = {
  labelFirst: 'Consultation et analyse du site ',
  descriptionFirst:
    "Avant d'entamer un projet d'aménagement paysager, nous commençons par une consultation et une analyse du site. Nous nous rendons sur place pour évaluer les caractéristiques existantes de votre propriété, les conditions de sol et d'ensoleillement, ainsi que votre vision et vos préférences pour l'espace. À partir de là, nous créons un plan d'aménagement personnalisé adapté à vos besoins et à vos préférences.",

  labelSecond: 'Conception du projet',
  descriptionSecond:
    'Notre équipe élabore ensuite un concept qui intègre vos idées et vos préférences, ainsi que les recommandations de nos experts. Nous utilisons un logiciel de pointe pour créer des rendus en 3D de la conception afin que vous puissiez visualiser le produit final avant tout travail.',

  labelThird: 'Construction et installation ',
  descriptionThird:
    "Une fois que vous aurez approuvé le projet final, notre équipe commencera la construction et l'installation. Nous n'utilisons que des matériaux de la plus haute qualité et travaillons avec des entrepreneurs de confiance pour nous assurer que chaque aspect de la conception est exécuté à la perfection. ",

  labelFourth: 'Maintenance et entretien ',
  descriptionFourth:
    "Nous ne nous contentons pas de créer de magnifiques espaces extérieurs, nous contribuons également à leur entretien. Notre équipe propose des services de maintenance et d'entretien continus afin de garantir que votre aménagement paysager conserve son aspect optimal pendant de nombreuses années.",
};

export const currentTextsBenefits = {
  description:
    "Le choix de nos services d'aménagement paysager s'accompagne d'une série d'avantages, notamment ",
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Des conceptions personnalisées adaptées à vos besoins et préférences uniques ',
  },
  { id: 2, text: 'Des logiciels de pointe pour les rendus en 3D' },
  {
    id: 3,
    text: 'Des matériaux de haute qualité et des entrepreneurs de confiance',
  },
  { id: 4, text: "Services de maintenance et d'entretien continus " },
  {
    id: 5,
    text: "Une équipe de concepteurs et d'architectes expérimentés et compétents",
  },
];
