import { getClientPartnerNavigationItems } from '../../scenes/LeadForm/LeadForm.constants';
import { GlobalActionTypes } from '../actionTypes/globalActionTypes';

const clientPartnerNavigationItems = getClientPartnerNavigationItems();

export interface GlobalInterface {
  leadFormTitle: string;
  questionData: string[] | [];
}

const initialState: GlobalInterface = {
  leadFormTitle: clientPartnerNavigationItems[0].title,
  questionData: [],
};

export const globalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GlobalActionTypes.SET_LEAD_FORM:
      return { ...state, leadFormTitle: action.payload };
    case GlobalActionTypes.SET_QUESTION_DATA:
      return { ...state, questionData: action.payload };
    default:
      return { ...state };
  }
};
