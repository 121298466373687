
export const PoolsSection = {
  title: 'Pools',
  description: `Discover our Compass ceramic pools: 24 models, perfect for modern or classical homes. Suitable for gardens, terraces, or indoor use. Enhance your living space with style and versatility. Indulge in the beauty and functionality of our Compass ceramic pools, offering a harmonious blend of style and versatility for your leisure and relaxation needs. Explore our collection now and make a splash with a pool that truly enhances your living environment. Dive in today!`,
  premium: 'Premium',
  selection: 'Selection',
  small: 'Small',
  length: 'Length',
  width: 'Width',
  depth: 'Depth',
  capacity: 'Capacity',
};
