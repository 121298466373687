export const MainSection = {
  services: ' Prestations de service',
  сonstruction: 'Construction',
  projects: ' Projets',
  interior: ' Intérieur',
  modularHouses: ' Maisons modulaires',
  architect: ' Architecte',
  consulting: ' Consultant',
  solarEnergy: ' Énergie solaire',
  inmobiliaria: ' Immobilier',
  investments: ' Investissements',
  swimmingPools: ' Piscines',
  smartHome: ' Maison intelligente',
  landscapeDesign: ' Aménagement paysager',
  buildingMaterials: ' Matériaux de construction',
};
