export const SmartHomeStepSection = {
  blockTitle: 'Етапи розумного будинку',
  descriptionTitle: 'Введення в експлуатацію',
  stepOne: {
    title: 'Перший етап',
    p1: `Планування системи «розумний будинок». До початку установки системи «розумний будинок» необхідно ретельно спланувати всі деталі. Це включає вибір правильних пристроїв і технологій, визначення їх розташування, проводки та інших деталей.`,
    p2: `Також потрібно визначити бюджет, який ви готові витратити на установку та налаштування системи.`,
  },
  stepTwo: {
    title: 'Другий етап',
    p1: `Придбання пристроїв і матеріалів. Після того, як ви спланували систему «розумний будинок», вам потрібно придбати всі необхідні пристрої і матеріали.`,
    p2: `Це можуть бути датчики руху, замки для дверей, термостати, розумні розетки, освітлювальні пристрої, системи відеоспостереження та інші.`,
  },
  stepThree: {
    title: 'Третій етап',
    p1: `Установка датчиків та пристроїв. На цьому етапі усі датчики та пристрої установлюються. Вони повинні бути встановлені відповідно до попередньо визначеного плану та з урахуванням потреби проводки. Наша команда приватних спеціалістів впорається з установкою: електриків, ремонтників.`,
    p2: `Після встановлення пристроїв і датчиків їх необхідно підключити до системи управління. Це може бути контролер розумного будинку, який зв'язує всі пристрої разом.`,
  },
  stepFour: {
    title: 'Четвертий етап',
    p1: `Програмування та налаштування. Після того, як всі пристрої підключені до системи управління, їх необхідно налаштувати для спільної роботи. Це може включати створення автоматизованих сценаріїв, встановлення голосового управління та налаштування додатків для керування системою.`,
    p2: `Комплексне рішення повинно розпізнавати власника будинку за звуком, знати його основні інтереси та переваги.`,
  },
  stepFive: {
    title: `П'ятий етап`,
    p1: `Тестування. Після налаштування системи «розумний будинок» його необхідно протестувати, щоб переконатися, що інновація належним чином інтегрована. Це включає перевірку стану всіх пристроїв і датчиків, а також перевірку автоматизації та сценаріїв управління. Вже на цьому етапі механізм навчився адаптуватися до звичок. Програма автоматично вимикає світло в кожному куточку будинку, коли всі вже сплять, вимикає непотрібні електричні розетки, переводить систему підлогового опалення та клімат-контролю в економ-режим, закриває двері та активує систему безпеки, яка надійно захищає периметр вашого будинку.`,
    p2: `Система підготує кімнати на ранок, наплече ароматну каву, розбудить вас улюбленою мелодією та піклуватиметься про безпеку ваших дітей.`,
  },
  stepSix: {
    title: 'Шостий етап',
    p1: `Насолоджуйтесь. Останній етап, коли механізм готовий до роботи. Вам потрібно буде контролювати роботу системи, забезпечувати її обслуговування та, за потреби, оновлювати її. Цей етап є найбільш очікуваним, оскільки з цього моменту він здатний розділити життя на «до» і «після». Ваш будинок стає ідеальним охоронцем, який уважно стежить за кожним вікном і дверима, а також зустрічає гостей, як найбільш ідеальний дворецький. Він навіть дозволяє вам знати, коли гості надходять, передзвонюючи вам, щоб ви могли бути з ними на зв'язку, навіть якщо ви далеко від дому.`,
    p2: `З таким будинком ви можете керувати ним за допомогою простих команд у телефоні. Крім того, тепер неможливо забути про темряву або спеку - одне натискання кнопки вмикає світло, закриває штори та вмикає музику. Механізм призначений для того, щоб зробити проживання в будинку більш комфортним, оптимізувати час мешканця та автоматизувати основні процеси.`,
  },
};
