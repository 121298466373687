import React from 'react';
import construction from '../../shared/assets/images/menu-background-images/construction.jpg';
import projects from '../../shared/assets/images/menu-background-images/projects.jpg';
import interior from '../../shared/assets/images/menu-background-images/interior.jpg';
import modular_houses from '../../shared/assets/images/menu-background-images/modular_houses.jpg';
import swimming_pools from '../../shared/assets/images/menu-background-images/swimming_pools.jpg';
import solar_energy from '../../shared/assets/images/menu-background-images/solar_energy.jpg';
import smart_home from '../../shared/assets/images/menu-background-images/smart_home.jpg';
import realEstate from '../../shared/assets/images/menu-background-images/inmobiliaria.jpg';
import investments from '../../shared/assets/images/menu-background-images/investments.jpg';
import consulting from '../../shared/assets/images/menu-background-images/consulting.jpg';
import architect from '../../shared/assets/images/menu-background-images/architect.jpg';
import landscape_design from '../../shared/assets/images/menu-background-images/landscape_design.jpg';
import building_materials from '../../shared/assets/images/menu-background-images/building-materials.jpg';

import construction_hover from '../../shared/assets/images/menu-background-images/construction.jpg';
import projects_hover from '../../shared/assets/images/menu-background-images/projects-hover.jpg';
import interior_hover from '../../shared/assets/images/menu-background-images/interior-hover.jpg';
import modular_houses_hover from '../../shared/assets/images/menu-background-images/modular-houses-hover.jpg';
import swimming_pools_hover from '../../shared/assets/images/menu-background-images/pools-hover.jpg';
import solar_energy_hover from '../../shared/assets/images/menu-background-images/solar-energy-hover.jpg';
import smart_home_hover from '../../shared/assets/images/menu-background-images/smart-home-hover.jpg';
import real_estate_hover from '../../shared/assets/images/menu-background-images/inmobiliaria.jpg';
import investments_hover from '../../shared/assets/images/menu-background-images/investments-hover.jpg';
import consulting_hover from '../../shared/assets/images/menu-background-images/consulting-hover.jpg';
import architect_hover from '../../shared/assets/images/architect/architect-bg.jpg';
import landscape_design_hover from '../../shared/assets/images/landscape-design/slider-img-1.jpg';

import { ReactComponent as LandscapeDesign } from '../../shared/assets/icons/header-menu-icons/tree.svg';
import { ReactComponent as Construction } from '../../shared/assets/icons/header-menu-icons/hammer.svg';
import { ReactComponent as Projects } from '../../shared/assets/icons/header-menu-icons/buildings.svg';
import { ReactComponent as ModularHouses } from '../../shared/assets/icons/header-menu-icons/house.svg';
import { ReactComponent as Architect } from '../../shared/assets/icons/header-menu-icons/ruler.svg';
import { ReactComponent as Interior } from '../../shared/assets/icons/header-menu-icons/sofa.svg';
import { ReactComponent as SwimmingPools } from '../../shared/assets/icons/header-menu-icons/pool.svg';
import { ReactComponent as SmartHome } from '../../shared/assets/icons/header-menu-icons/webcam.svg';
import { ReactComponent as Investments } from '../../shared/assets/icons/header-menu-icons/statistic.svg';
import { ReactComponent as SolarEnergy } from '../../shared/assets/icons/header-menu-icons/monitor.svg';
import { ReactComponent as RealEstate } from '../../shared/assets/icons/header-menu-icons/chat.svg';
import { ReactComponent as Consulting } from '../../shared/assets/icons/header-menu-icons/person.svg';
import { ReactComponent as BuildingMaterials } from '../../shared/assets/icons/header-menu-icons/building-materials.svg';

import i18next from 'i18next';
import { RoutePaths } from '../../shared/helpers/routes';
import { MenuItemProps } from './MenuSection.types';

type MenuItemsTranslations = {
  all: string;
  construction: string;
  projects: string;
  interior: string;
  modular_houses: string;
  swimming_pools: string;
  solar_energy: string;
  smart_home: string;
  inmobiliaria: string;
  investments: string;
  consulting: string;
  architect: string;
  landscape_design: string;
  building_materials: string;
};

export const getMenuItems = (): MenuItemProps[] => {
  const menuItemsTitles = i18next.t('mainPage.MenuItemsTitles', {
    returnObjects: true,
  }) as MenuItemsTranslations;

  const menuItemsDescriptions = i18next.t('mainPage.MenuItemsDescriptions', {
    returnObjects: true,
  }) as MenuItemsTranslations;

  const faqItems = i18next.t('mainPage.FAQ', {
    returnObjects: true,
  }) as any;

  const menuItems = [
    {
      id: 1,
      label: menuItemsTitles.construction,
      backgroundImg: construction,
      icon: <Construction className="menu-icon" />,
      description: menuItemsDescriptions.construction,
      faq: faqItems.Construction,
      routePath: RoutePaths.constructionPage,
    },

    {
      id: 2,
      label: menuItemsTitles.interior,
      backgroundImg: interior,
      icon: <Interior className="menu-icon" />,
      description: menuItemsDescriptions.interior,
      faq: faqItems.Interior,
      routePath: RoutePaths.interiorPage,
    },
    {
      id: 3,
      label: menuItemsTitles.modular_houses,
      backgroundImg: modular_houses,
      icon: <ModularHouses className="menu-icon" />,
      description: menuItemsDescriptions.modular_houses,
      faq: faqItems.ModularHouses,
      routePath: RoutePaths.modularHousesPage,
    },
    {
      id: 4,
      label: menuItemsTitles.swimming_pools,
      backgroundImg: swimming_pools,
      icon: <SwimmingPools className="menu-icon" />,
      description: menuItemsDescriptions.swimming_pools,
      faq: faqItems.SwimmingPools,
      routePath: RoutePaths.swimmingPools,
    },
    {
      id: 5,
      label: menuItemsTitles.solar_energy,
      backgroundImg: solar_energy,
      icon: <SolarEnergy className="menu-icon" />,
      description: menuItemsDescriptions.solar_energy,
      faq: faqItems.SolarEnergy,
      routePath: RoutePaths.solarEnergyPage,
    },
    {
      id: 6,
      label: menuItemsTitles.smart_home,
      backgroundImg: smart_home,
      icon: <SmartHome className="menu-icon" />,
      description: menuItemsDescriptions.smart_home,
      faq: faqItems.SmartHome,
      routePath: RoutePaths.smartHome,
    },
    {
      id: 7,
      label: menuItemsTitles.inmobiliaria,
      backgroundImg: realEstate,
      icon: <RealEstate className="menu-icon" />,
      description: menuItemsDescriptions.inmobiliaria,
      faq: faqItems.Inmobiliaria,
      routePath: RoutePaths.realEstatePage,
    },

    {
      id: 8,
      label: menuItemsTitles.investments,
      backgroundImg: investments,
      icon: <Investments className="menu-icon" />,
      description: menuItemsDescriptions.investments,
      faq: faqItems.Investments,
      routePath: RoutePaths.investmentsPage,
    },
    {
      id: 9,
      label: menuItemsTitles.consulting,
      backgroundImg: consulting,
      icon: <Consulting className="menu-icon" />,
      description: menuItemsDescriptions.consulting,
      faq: faqItems.Consulting,
      routePath: RoutePaths.consultingPage,
    },
    {
      id: 10,
      label: menuItemsTitles.architect,
      backgroundImg: architect,
      icon: <Architect className="menu-icon" />,
      description: menuItemsDescriptions.architect,
      faq: faqItems.Architect,
      routePath: RoutePaths.architectPage,
    },
    {
      id: 11,
      label: menuItemsTitles.landscape_design,
      backgroundImg: landscape_design,
      icon: <LandscapeDesign className="menu-icon" />,
      description: menuItemsDescriptions.landscape_design,
      faq: faqItems.LandscapeDesign,
      routePath: RoutePaths.landscapeDesignPage,
    },
    {
      id: 12,
      label: menuItemsTitles.building_materials,
      backgroundImg: building_materials,
      icon: <BuildingMaterials className="menu-icon" />,
      description: menuItemsDescriptions.building_materials,
      faq: [],
      routePath: RoutePaths.buildingMaterialsPage,
    },

    // {
    //   id: 2,
    //   label: menuItemsTitles.projects,
    //   backgroundImg: projects,
    //   icon: <Projects className="menu-icon" />,
    //   description: menuItemsDescriptions.projects,
    //   faq: faqItems.Projects,
    //   routePath: RoutePaths.projectsPage,
    // },
  ];

  return menuItems;
};

export const getHoverMenuItems = () => {
  const menuItemsTitles = i18next.t('mainPage.MenuItemsTitles', {
    returnObjects: true,
  }) as MenuItemsTranslations;

  const hoverMenuItems = [
    {
      id: 1,
      label: menuItemsTitles.construction,
      backgroundImg: construction_hover,

      routePath: RoutePaths.constructionPage,
    },

    // {
    //   id: 2,
    //   label: menuItemsTitles.projects,
    //   backgroundImg: projects_hover,

    //   routePath: RoutePaths.projectsPage,
    // },
    {
      id: 3,
      label: menuItemsTitles.interior,
      backgroundImg: interior_hover,

      routePath: RoutePaths.interiorPage,
    },
    {
      id: 4,
      label: menuItemsTitles.modular_houses,
      backgroundImg: modular_houses_hover,

      routePath: RoutePaths.modularHousesPage,
    },
    {
      id: 5,
      label: menuItemsTitles.swimming_pools,
      backgroundImg: swimming_pools_hover,

      routePath: RoutePaths.swimmingPools,
    },
    {
      id: 6,
      label: menuItemsTitles.solar_energy,
      backgroundImg: solar_energy_hover,

      routePath: RoutePaths.solarEnergyPage,
    },
    {
      id: 7,
      label: menuItemsTitles.smart_home,
      backgroundImg: smart_home_hover,

      routePath: RoutePaths.smartHome,
    },
    {
      id: 8,
      label: menuItemsTitles.inmobiliaria,
      backgroundImg: real_estate_hover,

      routePath: RoutePaths.realEstatePage,
    },

    {
      id: 9,
      label: menuItemsTitles.investments,
      backgroundImg: investments_hover,

      routePath: RoutePaths.investmentsPage,
    },
    {
      id: 10,
      label: menuItemsTitles.consulting,
      backgroundImg: consulting_hover,

      routePath: RoutePaths.consultingPage,
    },
    {
      id: 11,
      label: menuItemsTitles.architect,
      backgroundImg: architect_hover,

      routePath: RoutePaths.architectPage,
    },
    {
      id: 12,
      label: menuItemsTitles.landscape_design,
      backgroundImg: landscape_design_hover,

      routePath: RoutePaths.landscapeDesignPage,
    },
    {
      id: 13,
      label: menuItemsTitles.building_materials,
      backgroundImg: building_materials,
      routePath: RoutePaths.buildingMaterialsPage,
    },
  ];

  return hoverMenuItems;
};
export const getMenuTitle = (): MenuItemsTranslations => {
  const menuItemsTitles = i18next.t('mainPage.MenuItemsTitles', {
    returnObjects: true,
  }) as MenuItemsTranslations;

  return menuItemsTitles;
};
