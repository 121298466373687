export const servicesTexts = {
    label: 'Our Solar energy Services',
    description:
      'We offer a wide range of interior design services to help you achieve the space of your dreams. From initial concept and space planning to color selection, furniture sourcing, and accessorizing, our team can handle every aspect of your project. Our services include:',
  };
export const servicesList =
{
  
  textFirst: 'Consultation and Assessment',
    
  textSecond: 'Design and Proposal',
   
  textThird: 'Financing and Incentives',
    
  textFourth: 'Permits and Approvals',
  
    
  textFifth: 'Installation',
  
    
  textSixth: 'System Activation and Maintenance',
  
};
