export const solarEnergyBenefitsTexts = {
  label: 'Beneficios de la energía solar',
  description:
    'Los paneles solares son dispositivos que convierten la energía solar en electricidad. El uso de paneles tiene un impacto positivo en la economía y el medio ambiente. Pueden reducir los costes de electricidad, disminuir la huella de carbono y aumentar el valor de la propiedad. Aunque algunos propietarios tienen dudas sobre el coste y el mantenimiento, los beneficios superan a los inconvenientes.',
};

export const benefitsList = {
  firstLabel: 'No emite gases nocivos.',
  secondLabel: 'Larga vida útil de los equipos',
  thirdLabel: 'Mejora de la seguridad energética.',
  fourthLabel: 'Fuente inagotable de energía.',
};
