export const ourValuesTitles = {
  title: 'Our Values',
  quality: 'Quality',
  
};

export const ourValuesList = [
  {
    id: 1,
    title: 'Efficiency',
    text: "We value our clients' time, so we invest in deadlines and don't make them wait.",
  },
  {
    id: 2,
    title: 'Quality',
    text: 'We always put quality first and do our best to ensure that our services meet the highest standards.',
  },
  {
    id: 3,
    title: 'Always nearby',
    text: 'We consult before, during and after providing the service, answer customer questions and provide recommendations.',
  },
  {
    id: 4,
    title: 'Innovation and development ',
    text: 'Always striving to pursue new technologies to ensure the best results for our clients.',
  },
  {
    id: 5,
    title: 'Environmental responsibility',
    text: "We use environmentally friendly materials in construction, take care of nature.",
  },
  {
    id: 6,
    title: 'Transparency and openness',
    text: 'Honesty is the main currency, so we adhere to openness in all relations with our customers.',
  },
  {
    id: 7,
    title: 'Reliable partners',
    text: 'We cooperate with proven and reliable companies that supply raw materials and equipment.',
  },
  {
    id: 8,
    title: 'Individual approach ',
    text: "We do our best to meet the customer's expectations 100%.",
  },
];
  
  

