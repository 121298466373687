export const PoolsSection = {
  title: 'piscines',
  description: `Découvrez nos piscines en céramique Compass : 24 modèles, parfaits pour les maisons modernes ou classiques. Elles conviennent aux jardins, aux terrasses ou à l'intérieur. Améliorez votre espace de vie avec style et polyvalence. Laissez-vous séduire par la beauté et la fonctionnalité de nos piscines en céramique Compass, qui offrent un mélange harmonieux de style et de polyvalence pour vos besoins de loisirs et de détente. Explorez notre collection dès maintenant et faites sensation avec une piscine qui rehausse véritablement votre cadre de vie. Plongez dès aujourd'hui !`,
    premium: 'Premium',
    selection: 'Sélection',
    small: 'Petit',
  length: 'Longueur',
  width: 'Largeur',
  depth: 'Profondeur',
  capacity: 'Capacité',
};
