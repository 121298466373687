import styled from 'styled-components';
import { colorVariables, Breakpoints } from '../../../theme/Theme.constants';

export const HeaderSection = styled.div<{ bgImage: string }>`
  position: relative;
  background-image: url(${props => props.bgImage});
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 23, 25, 0.7);
  }
`;

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 10;
  margin-top: 3.75rem;
  background: rgba(0, 0, 0, 0.7);
  border-top: 1px solid ${colorVariables.contactsHeaderBorderColor};
  backdrop-filter: blur(2px) !important;
  padding: 1.25rem 1.562rem;

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 2.5rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    margin-top: 2.75rem;
    padding: 1.25rem 6.25rem;
  }
`;

export const TextWrapper = styled.div`
  @media (min-width: ${Breakpoints.lg}px) {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-right: 1.875rem;
  }
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0.625rem;
  text-align: center;

  @media (min-width: ${Breakpoints.md}px) {
    text-align: left;
    margin-bottom: 0.312rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 0px;
  }
`;

export const InfoList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${Breakpoints.md}px) {
    justify-content: left;
  }
`;
export const InfoItem = styled.li`
  &:not(:last-child) {
    padding-right: 0.625rem;
    margin-right: 0.625rem;
    border-right: 1px solid ${colorVariables.secondaryIconColor};
  }

  @media (min-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      padding-right: 1rem;
      margin-right: 1rem;
    }
  }
  @media (min-width: ${Breakpoints.lg}px) {
    &:not(:last-child) {
      padding-right: 1.875rem;
      margin-right: 1.875rem;
    }
  }
`;
export const InfoText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: ${colorVariables.popupText};
`;
export const MoreBtn = styled.button`
  width: 12.5rem;
  height: 2.187rem;
  background: rgba(255, 230, 98, 0.1);
  border: 1px solid ${colorVariables.yellowButtonBorder};
  backdrop-filter: blur(2px) !important;
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.25rem;

  text-align: center;

  color: ${colorVariables.alternativeTextColor};

  @media (min-width: ${Breakpoints.lg}px) {
    width: 13.75rem;
  }

  .translated & {
    font-size: 14px;
  }
`;

export const MainInfoWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 40%;
  padding: 0 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0 2.5rem;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    padding: 0px 6.25rem;
  }
`;
export const MainTitle = styled(Title)`
  font-size: 1.875rem;
  line-height: 37px;
  text-align: left;
  margin-bottom: 0.125rem;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 36px;
    line-height: 44px;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    font-size: 44px;
    line-height: 54px;
  }
`;

export const VillaInfoText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
  opacity: 0.7;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 1.25rem;
    line-height: 24px;
  }
  @media (min-width: ${Breakpoints.lg}px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 2.5rem;
  }
`;

export const MainText = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25rem;
  @media (min-width: ${Breakpoints.lg}px) {
    font-size: 18px;
    line-height: 32px;
    max-width: 700px;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const ViewBtn = styled.button`
  position: absolute;
  bottom: 1.875rem;
  left: 6.25rem;
  display: flex;
  align-items: center;
  background: ${colorVariables.opacityBackgroundColor};
  border: 1px solid ${colorVariables.secondaryIconColor};
  padding: 1rem 2.5rem;
  backdrop-filter: blur(2px) !important;
  z-index: 10;
  cursor: pointer;
`;
export const ViewBtnText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-left: 0.625rem;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  color: ${colorVariables.mainTextColor};
`;
