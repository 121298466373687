import React, { FC, useState, useRef, useEffect } from 'react';
import {
  AboutUsWrapper,
  ArrowBlock,
  ArrowIcon,
  Block,
  BookBlock,
  BookIcon,
  BottomBlock,
  DescriptionBlock,
  HeartBlock,
  HeartIcon,
  MiddleBlock,
  TitleBlock,
  TopBlock,
  AboutUsBlocksWrapper,
  ContentWrapper,
} from './AboutUsSection.styled';
import { AboutUsMobile } from './MobileBlock';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { useTranslation } from 'react-i18next';
import { OurValuesBlock } from '../OurValuesBlock';
import { OurStoryBlock } from '../OurStoryBlock';
import creators from '../../shared/assets/images/our-story-images/creators.png';
import office from '../../shared/assets/images/our-story-images/office.png';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../shared/helpers/routes';
import { mainSection } from '../../shared/helpers/globalConstants';
import { getValueList } from './AboutUsSection.constatnts';

export const AboutUsSection: FC = () => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [hoveredHeartBlock, setHoveredHeartBlock] = useState(false);
  const [hoveredBookBlock, setHoveredBookBlock] = useState(false);
  const hoverTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const valuesList = getValueList();
  const aboutUsPath = RoutePaths.aboutUsPage;
  const navigate = useNavigate();
  useEffect(() => {
    const images = [creators, office];

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []); // added to fix long loading times for images

  const handleMouseEnter = (isHeart: boolean) => {
    if (!isDesktopXlg) return;
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setHovered(true);

      isHeart ? setHoveredHeartBlock(true) : setHoveredBookBlock(true);
    }, 100);
  };

  const handleMouseLeave = (isHeart: boolean) => {
    if (!isDesktopXlg) return;
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setHovered(false);
      isHeart ? setHoveredHeartBlock(false) : setHoveredBookBlock(false);
    }, 100);
  };

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  const isDesktopXlg = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });

  const handleBlockClick = (routePath: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <AboutUsBlocksWrapper>
      {isDesktopXlg && (
        <>
          {hoveredHeartBlock && (
            <OurValuesBlock
              valuesList={valuesList}
              hoveredHeartBlock={hoveredHeartBlock}
            />
          )}
          {hoveredBookBlock && (
            <OurStoryBlock hoveredBookBlock={hoveredBookBlock} />
          )}
        </>
      )}
      <AboutUsWrapper hovered={hovered}>
        {isDesktop ? (
          <ContentWrapper hovered={hovered}>
            <TopBlock>
              <HeartBlock
                onMouseEnter={() => {
                  if (hoveredBookBlock) return;
                  handleMouseEnter(true);
                }}
                onMouseLeave={() => handleMouseLeave(true)}
                onClick={() => handleBlockClick(aboutUsPath)}
                hovered={hovered}>
                <HeartIcon />
              </HeartBlock>

              <TitleBlock
                hovered={hovered}
                hoveredHeartBlock={hoveredHeartBlock}>
                <span>{t('mainPage.AboutUsTitles.about')}</span>

                <span className="opacity-part">
                  {t('mainPage.AboutUsTitles.us')}
                </span>
              </TitleBlock>
            </TopBlock>

            <MiddleBlock>
              <DescriptionBlock hovered={hovered}>
                <p>{t('mainPage.AboutUsTitles.mainDescription')}</p>
              </DescriptionBlock>
              <Block hovered={hovered} />
            </MiddleBlock>

            <BottomBlock>
              <Block hovered={hovered} />

              <BookBlock
                hovered={hovered}
                onMouseEnter={() => {
                  if (hoveredHeartBlock) return;
                  handleMouseEnter(false);
                }}
                onMouseLeave={() => handleMouseLeave(false)}
                onClick={() => handleBlockClick(aboutUsPath)}>
                <BookIcon />
              </BookBlock>

              <Block hovered={hovered} />

              <ArrowBlock
                hovered={hovered}
                onClick={() => handleBlockClick(aboutUsPath)}>
                <ArrowIcon />
              </ArrowBlock>
            </BottomBlock>
          </ContentWrapper>
        ) : (
          <AboutUsMobile />
        )}
      </AboutUsWrapper>
    </AboutUsBlocksWrapper>
  );
};
