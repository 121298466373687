import React, { FC } from 'react';
import {
  MaterialWrapper,
  List,
  Item,
  Img,
  TextWrapper,
  Title,
  Text,
} from './MaterialsSection.styled';
import masonry from '../../../shared/assets/images/building-materials/masonry.jpg';
import framing from '../../../shared/assets/images/building-materials/framing.jpg';
import roofing from '../../../shared/assets/images/building-materials/roofing.jpg';
import windows from '../../../shared/assets/images/building-materials/windows.jpg';
import hardware from '../../../shared/assets/images/building-materials/hardware.jpg';
import { useTranslation } from 'react-i18next';
export const MaterialsSection: FC = () => {
  const { t } = useTranslation();
  const materialsList = [
    {
      id: 1,
      title: t('buildingMaterialsPage.buildingMaterialsList.titleFirst'),
      text: t('buildingMaterialsPage.buildingMaterialsList.textFirst'),
      img: masonry,
    },
    {
      id: 2,
      title: t('buildingMaterialsPage.buildingMaterialsList.titleSecond'),
      text: t('buildingMaterialsPage.buildingMaterialsList.textSecond'),
      img: framing,
    },
    {
      id: 3,
      title: t('buildingMaterialsPage.buildingMaterialsList.titleThird'),
      text: t('buildingMaterialsPage.buildingMaterialsList.textThird'),
      img: roofing,
    },
    {
      id: 4,
      title: t('buildingMaterialsPage.buildingMaterialsList.titleFourth'),
      text: t('buildingMaterialsPage.buildingMaterialsList.textFourth'),
      img: windows,
    },
    {
      id: 5,
      title: t('buildingMaterialsPage.buildingMaterialsList.titleFifth'),
      text: t('buildingMaterialsPage.buildingMaterialsList.textFifth'),
      img: hardware,
    },
  ];
  return (
    <MaterialWrapper>
      <List>
        {materialsList.map(({ id, title, text, img }) => {
          return (
            <Item key={id}>
              <Img src={img} />
              <TextWrapper>
                <Title>{title}</Title>
                <Text>{text}</Text>
              </TextWrapper>
            </Item>
          );
        })}
      </List>
    </MaterialWrapper>
  );
};
