import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';
import { Button } from '../../shared/components/BluredButton/BluredButton.styled';

export const FavouritePageWrapper = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: ${colorVariables.alternativeBackground};
`;

export const HeaderSectionNavbarWrapper = styled.div`
  position: static;
  margin-top: 3.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 3.75rem;
  width: 100%;
  padding: 0 ${paddings.mobileSidePadding};
  border-top: 1px solid ${colorVariables.navBarBorderColor};
  background: ${colorVariables.opacityBackgroundColor};
  width: 100%;
  max-width: 1920px;
  height: 4.125rem;
  z-index: 15;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 0 ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 0 ${paddings.desktopSidePadding};
    margin-top: 2.75rem;
  }
`;

export const NavigationBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding-right: 1rem;
  padding: 0.25rem 0;
`;

export const LogoTitle = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 100%;
  padding-right: 1.25rem;
  width: max-content;
`;

export const ControlsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  ${Button} {
    padding: 0.5rem 0.675rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${colorVariables.alternativeTextColor};
    background-color: ${colorVariables.yellowButtonBackground};
    border: 1px solid ${colorVariables.yellowButtonBorder};
    transition: ${transitions.allHoverTransition};

    &:hover {
      @media only screen and (min-width: ${Breakpoints.md}px) {
        background: none;
        border: 1px solid ${colorVariables.buttonBorder};
        color: ${colorVariables.mainTextColor};
      }
    }

    @media only screen and (min-width: ${Breakpoints.md}px) {
      padding: 0.5rem 2.5rem;
    }
  }
`;

export const RemoveButtonBlock = styled.div`
  ${Button} {
    padding: 0.675rem;
    white-space: nowrap;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${colorVariables.mainTextColor};
    background-color: ${colorVariables.opacityWhiteBackgroundColor};
    border: 1px solid ${colorVariables.blockBorderColor};
    transition: ${transitions.allHoverTransition};

    &:hover {
      @media only screen and (min-width: ${Breakpoints.md}px) {
        background: none;
        border: 1px solid ${colorVariables.buttonBorder};
        color: ${colorVariables.mainTextColor};
      }
    }

    @media only screen and (min-width: ${Breakpoints.md}px) {
      padding: 0.5rem 2.5rem;
    }
  }
`;

export const RemoveButtonTitle = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    gap: 0.625rem;
  }
`;

export const ServicesCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  padding: 1.875rem ${paddings.mobileSidePadding};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.875rem ${paddings.desktopSidePadding};
  }
`;

export const ServiceCard = styled.div<{
  isFavourite: boolean;
}>`
  display: flex;
  width: 100%;
  opacity: ${props => (props.isFavourite ? '1' : '0.6')};
  border: 1px solid rgba(101, 105, 109, 0.3);
  height: 6.5rem;
  transition: 0.3s opacity ease;
  cursor: pointer;

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc((100% - 1.25rem) / 2);
  }

  &:hover {
    opacity: 1;
  }
`;

export const ServiceCardContent = styled.div`
  position: relative;
  grid-template-columns: 1fr 4fr;
  width: 100%;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    display: grid;
  }
`;

export const ServiceIconBlock = styled.div<{
  isFavourite: boolean;
  backgroundImage: any;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(58, 58, 60, 0.2);
  background-image: url(${props => props.isFavourite && props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ServiceInfoBlock = styled.div`
  padding: 1rem 1.25rem;
  overflow: hidden;
`;
export const ServiceTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    gap: 0;
  }

  .menu-icon {
    width: 18px;
    height: 16px;
  }
`;
export const ServiceDescription = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  height: 2.75rem;
  width: 100%;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;

export const HeartIcon = styled.div<{ isFavourite: boolean }>`
display: block;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  min-width: 20px:
  min-height: 20px;

  & svg {
    transition: 0.3s fill ease;
    fill: ${props =>
      props.isFavourite ? colorVariables.alternativeTextColor : 'inherit'};
  }

  & path {
    stroke: ${props =>
      props.isFavourite
        ? colorVariables.alternativeTextColor
        : colorVariables.mainTextColor};
  }
`;

export const FormControl = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 3.375rem;
  padding: 0;
  border: none;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(101, 105, 109, 0.3);
`;

export const ButtonTitle = styled.span`
  transform: rotate(270deg);
  border: none;
  cursor: pointer;
  background: none;
  color: ${colorVariables.mainTextColor};
`;

export const FormBlockWrapper = styled.div`
  padding: 1.875rem ${paddings.mobileSidePadding};

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem ${paddings.tabletSidePadding};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 1.875rem ${paddings.desktopSidePadding};
  }
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  justify-content: center;
  border: 1px dashed rgba(101, 105, 109, 0.9);
  padding: 1rem;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    flex-direction: row;
    gap: 3rem;
    padding: 2rem;
  }

  & ${Button} {
    padding: 0.5rem 0.675rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${colorVariables.alternativeTextColor};
    background-color: ${colorVariables.yellowButtonBackground};
    border: 1px solid ${colorVariables.yellowButtonBorder};
    transition: ${transitions.allHoverTransition};
    width: 100%;
    padding: 0.5rem 2.5rem;

    &:hover {
      @media only screen and (min-width: ${Breakpoints.md}px) {
        background: none;
        border: 1px solid ${colorVariables.buttonBorder};
        color: ${colorVariables.mainTextColor};
      }
    }

    @media only screen and (min-width: ${Breakpoints.md}px) {
      width: fit-content;
    }
  }
`;

export const FormTitle = styled.span`
  max-width: 19.75rem;
  font-size: 0.875rem;
  line-height: 150%;
  opacity: 0.7;
`;
