export const navItems = {
  services: 'Services',
  benefits: 'Benefits',
  stages: 'Stages',
};

export const description =
  'Are you looking to transform your outdoor space into a beautiful and functional oasis? Look no further than our expert landscape design services. Our team of experienced designers and architects is here to help you turn your vision into a reality.';

export const currentTexts = {
  label: 'Services',
  description:
    'At our Landscape Design Services, we offer a wide range of services to help you transform your outdoor space. Our services include:',
};

export const processList = {
  labelFirst: 'Consultation and Site Analysis',
  descriptionFirst:
    'Before we begin any landscape design project, we start with a consultation and site analysis. This includes an on-site visit to assess the existing features of your property, soil and sunlight conditions, and your vision and preferences for the space. From there, we create a customized design plan tailored to your unique needs and preferences.',

  labelSecond: 'Concept Design',
  descriptionSecond:
    'Our team will then develop a concept design that incorporates your ideas and preferences, as well as our expert recommendations. We use state-of-the-art software to create 3D renderings of the design so you can visualize the final product before any work is done.',

  labelThird: 'Construction and Installation',
  descriptionThird:
    'Once you approve the final design, our team will begin construction and installation. We use only the highest quality materials and work with trusted contractors to ensure that every aspect of the design is executed to perfection.',

  labelFourth: 'Maintenance and Upkeep',
  descriptionFourth:
    "We don't just create beautiful outdoor spaces - we also help maintain them. Our team offers ongoing maintenance and upkeep services to ensure that your landscape design continues to look its best for years to come.",
};

export const currentTextsBenefits = {
  description:
    'Choosing our Landscape Design Services comes with a range of benefits, including:',
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Customized designs tailored to your unique needs and preferences',
  },
  { id: 2, text: 'State-of-the-art software for 3D renderings' },
  { id: 3, text: 'High-quality materials and trusted contractors' },
  { id: 4, text: 'Ongoing maintenance and upkeep services' },
  {
    id: 5,
    text: 'Experienced and knowledgeable team of designers and architects',
  },
];
