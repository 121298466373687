import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import logo from '../../../shared/assets/icons/logo.svg';

export const NavBarTabletBlocks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  color: ${colorVariables.mainTextColor};

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    grid-template-columns: 2.005fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
  cursor: pointer;
`;

export const LogoImg = styled.div`
  width: 1.5rem;
  height: 1.25rem;
  background-image: url(${logo});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const LogoTitle = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${colorVariables.mainTextColor};
  cursor: pointer;

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const NavItemsBlock = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const NavItem = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: max-content;
  cursor: pointer;
  color: ${colorVariables.mainTextColor};
  transition: ${transitions.textHoverTransition};

  &:hover {
    color: ${colorVariables.mainTextColorWitOpacity};
  }
`;

export const SettingsBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
`;
