import i18next from 'i18next';
import projectsMobileImg from '../../shared/assets/images/projects/projects-mobile-img.jpg';
export const getProjectsSectionTransaltion = () => {
  const projectsTranslate = i18next.t('projectsPage.projectsList', {
    returnObjects: true,
  }) as any;

  const projectsList = [
    {
      id: 1,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 2,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 3,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 4,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },

    {
      id: 5,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 6,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 7,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 8,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
    {
      id: 9,
      label: projectsTranslate.label,
      img: projectsMobileImg,
      price: projectsTranslate.price,
      execution: projectsTranslate.execution,
    },
  ];

  return projectsList;
};

export const paginationList = [
  { number: 1 },
  { number: 2 },
  { number: 3 },
  { number: 4 },
];
