export const aboutListText = {
  peopleText:
    'We have a team of experienced professionals who are dedicated to providing excellent service to our clients.',
  bookText:
    'We have a proven track record of success, with many satisfied clients.',
  clipboardText:
    'We offer a wide range of services to meet the unique needs of our clients.',
  microchipText:
    "We use the latest technology and marketing strategies to ensure that our clients' properties are seen by the right people.",
  handshakeText:
    'We provide legal assistance to ensure that all transactions are handled smoothly and efficiently.',
};