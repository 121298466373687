export const navItems = {
  preConstruction: 'Préconstruction',
  process: 'Processus',
};

export const description =
  "Grâce à notre riche expérience et à la qualité de nos projets de construction, nous garantissons à chacun de nos clients une approche professionnelle et la meilleure politique de prix. Nous achetons des matériaux de construction éprouvés à des prix compétitifs, nous fournissons des rapports pour chaque étape, nous exécutons avec compétence toute la gamme des travaux de construction de maisons en briques, en béton mousse, en béton cellulaire, en béton armé, en blocs, en utilisant des structures métalliques et d'autres éléments porteurs du bâtiment. Buckingham Palace, la tour de Raiponce ou le château de Howl - nous pouvons réaliser tous vos souhaits architecturaux et construire une maison clé en main.";

export const processList = {
  labelFirst: "Attention à l'environnement",
  descriptionFirst:
    "Nous nous efforçons d'utiliser des matériaux organiques qui ne nuisent pas à la nature. Les substituts bon marché et les produits chimiques sont tabous pour nous. Nous nous soucions non seulement de l'environnement, mais aussi de nos clients qui vivront, créeront, travailleront, dormiront et recevront des invités dans la maison.",

  labelSecond: 'Expérience et professionnalisme',
  descriptionSecond: `Notre équipe est composée de professionnels hautement qualifiés qui ont l'expérience de différents types et styles de construction. Nous savons quels matériaux et quelles technologies sont les mieux adaptés à chaque projet spécifique`,

  labelThird: 'Un travail complexe',
  descriptionThird:
    "Nous fournissons des services de construction de maisons clés en main, ce qui signifie que nous prenons en charge toutes les étapes du travail, de la conception à la mise en service. Cela nous permet d'assurer un niveau élevé de qualité du travail et de garantir le respect des délais.",

  labelFourth: 'La qualité des matériaux',
  descriptionFourth:
    "Nous n'utilisons que des matériaux et des composants de haute qualité provenant de fournisseurs de confiance, ce qui garantit la durabilité et la fiabilité de votre maison. Nous ne travaillons qu'avec des fournisseurs de confiance afin de garantir la durabilité de chaque mètre carré de votre maison",

  labelFifth: 'Approche individuelle',
  descriptionFifth: `Nous voulons comprendre les besoins de chacun de nos clients et les satisfaire. Maison de fête, maison de ville pour un couple âgé ou villa pour une jeune famille avec des enfants - chaque client a des besoins individuels. Nous tenons compte de vos souhaits et de vos exigences, et nous vous aidons à choisir les meilleures solutions pour votre projet. Nous travaillons avec chaque client individuellement pour créer une maison qui réponde à tous ses besoins et préférences`,

  labelSixth: 'Contrôle de la qualité',
  descriptionSixth:
    "Nous contrôlons rigoureusement la qualité de toutes les étapes du travail, de la sélection des matériaux à l'installation des équipements. Cela nous permet d'assurer un niveau élevé de qualité du travail et de garantir le respect de toutes les normes et exigences. Nous fournissons des rapports sur chaque étape et garantissons la transparence.",
};

export const aboutTexts = {
  textFirst: 'Nous établissons de nouvelles normes sur le marché',

  textSecond:
    "Pour nous, chaque maison ne se résume pas à des mètres carrés, mais à un espace douillet, <br/> pensé dans les moindres détails, où vous voulez vivre, profiter et appeler à juste titre cet endroit 'maison'. ",

  textThird: `Nous construisons des maisons qui sont associées aux meilleurs moments de la vie - <br/> le premier mot de votre enfant, la demande en mariage, la soirée la plus chaleureuse avec votre famille. `,

  textFourth: 'Nous construisons des maisons qui deviennent votre vie',

  textFifth:
    'La principale valeur pour nous est votre sourire, la principale récompense est la confiance',
};

export const preConstructionTitle = 'Planification avant construction ';
export const preConstructionText =
  "Nous nous efforçons de créer non seulement une maison, mais aussi un véritable coin de bonheur et de confort, où vous pourrez profiter de chaque instant. Nous ne nous limitons pas à la création de maisons standard, nous vous aidons à créer votre style de vie unique sur votre propre terrain. C'est pourquoi nous acceptons tous les projets et savons que rien n'est impossible. Votre fantaisie architecturale peut devenir une réalité aujourd'hui - il vous suffit de nous contacter. Nous trouverons les meilleures solutions pour votre future maison, et nous développerons avec vous l'option idéale qui deviendra l'incarnation de vos rêves et de vos désirs.";

export const constructionStepBlockTitle = 'Étapes de construction';
export const constructionStepBlockDescription = 'Consultation initiale';

export const constructionStepSection = {
  blockTitle: 'Étapes de la maison intelligente',
  descriptionTitle: 'Emménager',
  stepOne: {
    title: 'Étape un',
    p1: `Planification. La première étape de la construction est le moment où l'idée d'une future maison prend forme. Ici, nous nous posons des questions sur la façon dont la maison devrait être, comment elle devrait être située sur le terrain et quels matériaux seront utilisés. Au cours du processus de conception, nos experts améliorent chaque détail de la maison, des fondations et des murs porteurs jusqu'au toit et aux conduits de ventilation.`,
    p2: `Nous nous occupons de la sélection de tous les matériaux nécessaires, du grade de béton pour couler les fondations à la spécification des portes et fenêtres, ainsi que de l'implantation du système de "maison intelligente". Si vous avez besoin d'une conception plus détaillée, nous pouvons calculer toutes les installations et sélectionner les matériaux pour les finitions extérieures et intérieures.`,
  },
  stepTwo: {
    title: 'Étape deux',
    p1: `Travaux préparatoires et fondation. Avant de commencer la construction d'une maison, il faut effectuer toute une série de travaux préparatoires. Cela comprend le nettoyage du terrain des débris, l'élimination de la végétation inutile, le nivellement du sol, le démantèlement des anciens bâtiments. Mais ce n'est que le début - il faut ensuite préparer la future fondation de la maison. L'une des étapes les plus importantes est l'élaboration de la fondation. Avant de commencer à creuser une fosse, il faut amener toutes les installations et marquer la future structure.`,
    p2: `Ce n'est qu'après cela que vous pouvez commencer à construire la fondation. Le choix du type de fondation dépend de nombreux facteurs, tels que les caractéristiques du sol, la surface et le nombre d'étages de la maison. Chaque type de fondation a ses propres caractéristiques et exigences en matière de qualité des matériaux. Par exemple, lors de l'utilisation de béton, il faut tenir compte de sa rétractation, qui se produit pendant le processus de séchage. Par conséquent, en confiant notre équipe, vous pouvez être sûr à 100% que votre maison aura une fondation solide.`,
  },
  stepThree: {
    title: 'Étape trois',
    p1: `Murs et planchers. Après que la fondation soit solidement fixée, il est temps de construire les murs. Il est important de choisir un matériau qui fournira la résistance, la résistance au gel et la fiabilité de la future structure. Aujourd'hui, l'un des matériaux les plus courants pour la construction de faible hauteur est la brique, le bloc de gaz et le bloc de mousse. La brique est un matériau beau, écologique et durable qui est utilisé pour la construction de murs porteurs et de cloisons intérieures. Grâce à la possibilité de combiner différents types de briques, vous pouvez obtenir un bâtiment attrayant dans le style espagnol ou tout autre style qui ne nécessite pas de décoration supplémentaire. Cependant, la brique est lourde et est posée lentement par le constructeur. Les blocs de béton cellulaire sont un matériau léger, durable et respectueux de l'environnement, doté de bonnes propriétés d'isolation thermique. Grâce à sa forme correcte et à ses dimensions précises, la pose des blocs de béton cellulaire est rapide. Les blocs de gaz ne s'effritent même pas lorsqu'ils sont coupés avec une scie à métaux.`,
    p2: `Cependant, le béton cellulaire peut absorber l'humidité de la mer et n'est pas le matériau le plus esthétique, ce qui peut être corrigé par une finition de façade supplémentaire. Dans le processus d'érection des murs, l'installation des planchers intermédiaires et des plafonds, ainsi que des cloisons intérieures, a lieu. Les performances d'isolation thermique, de vapeur et de son du futur design sont des paramètres importants qui améliorent considérablement les performances du bâtiment.`,
  },
  stepFour: {
    title: 'Étape quatre',
    p1: `Sélection et installation de la toiture. La toiture est un élément aussi important du bâtiment que la fondation et les murs. Elle protège non seulement le bâtiment des conditions météorologiques défavorables, mais ajoute également à son attrait esthétique. L'un des types courants de toitures est la toiture à quatre pans, qui se distingue par sa rigidité et son apparence attrayante. Elles offrent une protection supplémentaire aux murs du bâtiment contre les influences extérieures, mais réduisent en même temps la surface du grenier et nécessitent une conception et une installation professionnelles, que notre entreprise peut fournir. Les toits à quatre pans, ou pyramidaux, sont également demandés.`,
    p2: `Ils se caractérisent par une susceptibilité minimale au vent, ce qui peut être un avantage supplémentaire pour les habitants de l'Espagne, qui connaissent bien le froid vent du nord de la Tramontane. C'est une solution idéale pour les bâtiments carrés et aussi moins chère que les toits à quatre pans. Les toits plats sont une autre option qui se caractérise par une installation rapide et un faible coût d'aménagement.`,
  },
  stepFive: {
    title: 'Étape cinq',
    p1: `Finitions. Les travaux de finition, qui donnent non seulement au bâtiment un aspect final, mais jouent également un rôle important dans la création d'un espace confortable pour les habitants. Pour maintenir un climat intérieur confortable, on utilise des enduits modernes. Dans les cas où c'est nécessaire, des façades ventilées sont installées. Ensuite, on commence l'installation des fenêtres, des portes, des escaliers, ainsi que l'alignement des pentes et la connexion des installations.`,
    p2: `Les murs intérieurs sont enduits et recouverts d'un revêtement de finition (peinture, papier peint ou enduit décoratif), et les installations sanitaires et les luminaires sont également installés. Enfin, il ne reste plus qu'à agencer les meubles, et votre nouvelle maison avec une âme espagnole est prête à emménager.,`,
  },
};
