export const currentTexts = {
  label: 'Opcions addicionals',
  description:
    `Fins fa poc, les cases modulars s'utilitzaven principalment per a necessitats conjunturals, com habitatges temporals o cases de pagès. Però les tecnologies modernes no es queden quietes i continuen desenvolupant-se. Actualment, les cases prefabricades i assequibles fetes de contenidors de blocs tenen característiques suficients per proporcionar una vida còmoda. I estem disposats a oferir-li serveis addicionals que ajudaran a millorar el territori de la casa modular.`,
};

export const options = {
  titleSwimmingPools: 'Piscines',
  describtionSwimmingPools:
    `Per a molts de nosaltres, el mar sempre ha estat una font de somnis i relaxació. No obstant això, poder construir la seva pròpia piscina modular al pati del darrere es una oportunitat increïble per fer realitat aquests somnis, fins i tot si l'Oceà Atlàntic és lluny. Gràcies a nosaltres, la construcció de piscines passa d'un somni inabastable a una realitat accessible. La presència d' un embassament artificial al costat d' una casa de pagès s' ha convertit en un afegit habitual i natural al paisatge circumdant.`,

  titleSmartHome: 'Llar intel·ligent',
  describtionSmartHome:
    `Una casa intel·ligent és el seu assistent personal que cuida del seu confort i comoditat. El sistema es pot instal·lar fins i tot en una casa modular. Per controlar el sistema, tot el que necessita és una tauleta, un telèfon o un ordinador. Mitjançant un protocol segur únic i una aplicació mòbil, el complex li ofereix un control total del seu espai en qualsevol moment. Ni tan sols ha d'aixecar-se del llit per posar la tetera.`,

  titleSolarEnergy: 'Energia solar',
  describtionSolarEnergy:
    `El sol és la nostra principal font d'energia i de vida a la Terra. L' ús de l' energia solar és el primer pas cap a la conscienciació sobre els problemes mediambientals. Estarem encantats d'acompanyar-lo en aquest viatge, per ajudar-lo amb l'assessorament i la instal·lació de panells a les teulades de casa seva.`,
};
