export const packagesTexts = {
  label: 'Інвестиційні пакети',
  description:
    'Наш процес інвестування ґрунтується на ретельному та дисциплінованому підході до досліджень та аналізу. Ми віримо, що глибокі дослідження та аналіз допомагають ідентифікувати високоякісні інвестиційні можливості та ефективно управляти ризиками.',
};

export const packagesBlocks = {
  firstName: 'Назва',
  firstDownloadText: 'Завантажити',

  secondName: 'Назва',
  secondDownloadText: 'Завантажити',

  thirdName: 'Назва',
  thirdDownloadText: 'Завантажити',
};