import styled from 'styled-components';
import { colorVariables } from '../../theme/Theme.constants';

export const BackgroundImage = styled.img<{
  isOpen: boolean;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: 1s opacity ease;
  opacity: ${props => (props.isOpen ? 1 : 0)};
`;

export const MainBackgroundImage = styled.img<{
  noneActiveId: boolean;
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  transition: 1s opacity ease;
`;

export const BackgroundWithoutImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: ${colorVariables.mainBackgroundColor};
`;
