import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const InvestmentsTeamWrapper = styled.div`
  padding-bottom: 3.125rem;
  background: ${colorVariables.alternativeBackground};
`;

export const TeamListWrapper = styled.div`
  margin: 0rem 1.25rem 0rem 1.25rem;
  padding-bottom: 3.75rem;
  @media (min-width: ${Breakpoints.md}px) {
    margin: 0rem 2.5rem 0rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin: 0rem 6.25rem 0rem 6.25rem;
    padding-bottom: 8.75rem;
  }
`;
export const TeamList = styled.ul`
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
  }
`;

export const TeamItem = styled.li`
  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
  @media (min-width: ${Breakpoints.md}px) {
    flex-basis: calc(50% - 0.625rem);
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    flex-basis: calc(100% / 3 - 0.9rem);
  }
`;
export const ImagesWrapper = styled.div`
  height: 17.5rem;
`;
export const Images = styled.img`
  width: 100%;
  height: 100%;
`;

export const TeamTextsWrapper = styled.div`
  border: 1px solid ${colorVariables.aboutUsOpacityPart};
  padding: 0.9375rem;
  height: 7.5rem;
`;

export const TeamLabel = styled.p`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 0.625rem;
  color: rgba(255, 255, 255, 0.9);
`;

export const TeamText = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;

  color: ${colorVariables.whiteOpacityColor};
`;
