import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OurValuesBlocksWrapper,
  TitleBlockWrapper,
  OurValuesMainTitle,
  TextBlocksWrapper,
  OurValuesList,
  OurValuesItem,
  OurValuesTitles,
  OurValuesTexts,
  OurValuesItemTextWrapper,
} from './OurValuesBlock.styled';

interface Value {
  valuesList: Array<{
    id: number;
    title: string;
    text: string;
  }>;
  hoveredHeartBlock?: boolean;
  asSection?: boolean;
}

export const OurValuesBlock: FC<Value> = ({
  valuesList,
  hoveredHeartBlock = true,
  asSection,
}) => {
  const { t } = useTranslation();

  return (
    <OurValuesBlocksWrapper
      style={{ opacity: hoveredHeartBlock ? 1 : 0 }}
      asSection={asSection}>
      <TitleBlockWrapper asSection={asSection}>
        <OurValuesMainTitle>
          {t('mainPage.ourValuesTitles.title')}
        </OurValuesMainTitle>
      </TitleBlockWrapper>

      <OurValuesList asSection={asSection}>
        {valuesList.map(({ id, title, text }) => {
          return (
            <OurValuesItem key={id} asSection={asSection}>
              <TextBlocksWrapper asSection={asSection}>
                <OurValuesTitles>{title}</OurValuesTitles>
              </TextBlocksWrapper>
              <OurValuesItemTextWrapper>
                <OurValuesTexts>{text}</OurValuesTexts>
              </OurValuesItemTextWrapper>
            </OurValuesItem>
          );
        })}
      </OurValuesList>
    </OurValuesBlocksWrapper>
  );
};
