export const MenuItemsTitles = {
  all: 'Tout',
  construction: 'Construction',
  projects: 'Projets',
  interior: "L'intérieur",
  modular_houses: 'Maisons modulaires',
  swimming_pools: 'Piscines',
  solar_energy: 'Énergie solaire',
  smart_home: ' Maison intelligente',
  inmobiliaria: 'Immobilier',
  investments: 'Investissements',
  consulting: 'Conseil',
  architect: 'Architecte',
  landscape_design: 'Paysagiste',
  building_materials: 'Matériaux de construction',
  pre_build_houses: 'Maisons construites',
};

export const MenuItemsDescriptions = {
  construction:
    "Découvrez le summum du luxe et de l'artisanat grâce à nos services de construction de villas. Notre équipe de constructeurs et d'entrepreneurs experts donne vie à votre vision, en garantissant une qualité irréprochable et en prêtant attention aux détails à chaque étape. Des fondations aux finitions, nous livrons des villas exceptionnelles qui reflètent votre style unique et dépassent toutes les attentes.",
  projects:
    "Les projets de construction résidentielle impliquent la planification, la conception et la construction de maisons ou d'immeubles d'habitation. Ils nécessitent une compréhension approfondie des codes du bâtiment, des normes de sécurité et des besoins des personnes qui vivront dans les structures. Les projets réussis doivent également prendre en compte des facteurs tels que la durabilité, l'efficacité énergétique et l'attrait esthétique. En général, les projets de construction résidentielle jouent un rôle important dans la création d'espaces sûrs.",
  interior:
    "Nos services d'aménagement intérieur sur mesure permettent d'élever l'intérieur de votre villa à un niveau de sophistication inégalé. Notre équipe de designers talentueux sélectionne des finitions, des meubles et des décors exquis pour créer des espaces qui respirent le luxe et le confort. Avec une attention méticuleuse à chaque détail, nous transformons votre villa en un sanctuaire captivant qui complète parfaitement votre style de vie et met en valeur votre style personnel.",
  modular_houses:
    "Les maisons modulaires constituent une option innovante et flexible pour les projets de construction résidentielle. Ces structures sont construites en sections ou 'modules' hors site, puis transportées sur le chantier pour y être assemblées. Cela permet un processus de construction plus rapide et plus efficace, avec moins de gaspillage de matériaux. En outre, les maisons modulaires peuvent être personnalisées pour s'adapter à une variété de styles et de besoins, ce qui en fait une excellente option pour ceux qui recherchent une option unique et personnalisée.",
  swimming_pools:
    "Vous souhaitez ajouter une touche de luxe à votre maison ? Pensez aux avantages de l'installation d'une piscine. Les piscines résidentielles sont disponibles dans une grande variété de styles et de tailles, ce qui en fait un excellent choix pour n'importe quelle maison. Elles peuvent constituer un espace de divertissement et de détente pour la famille et les amis, tout en augmentant la valeur de votre propriété. Qu'il s'agisse d'une petite piscine simple ou d'une grande piscine élaborée, l'installation d'une piscine peut transformer votre maison.",
  solar_energy:
    "L'énergie solaire est une option durable et rentable pour alimenter votre maison en électricité. En installant des panneaux solaires sur votre toit, vous pouvez produire votre propre électricité à partir des rayons du soleil. Cela vous permet non seulement de réduire votre empreinte carbone, mais aussi d'économiser de l'argent sur vos factures mensuelles d'électricité. Avec une installation adéquate, vous pouvez même revendre l'énergie excédentaire au réseau.",
  smart_home:
    "La technologie de la maison intelligente permet une expérience de vie interconnectée et automatisée. Avec un système de maison intelligente, vous pouvez tout contrôler, de l'éclairage à la température en passant par la sécurité et le divertissement, en quelques clics sur votre téléphone ou par des commandes vocales. Ce système n'est pas seulement pratique et confortable, il peut aussi améliorer l'efficacité énergétique et la sécurité de votre maison.",
  inmobiliaria:
    "Trouvez le terrain idéal pour la villa de vos rêves et simplifiez le processus de construction grâce à notre sélection de terrains et de projets de villas prêts à l'emploi. Notre équipe d'experts s'assure que vous avez accès à des emplacements de premier choix et à des plans préconçus, ce qui rend plus facile que jamais la construction de votre maison idéale, en toute commodité et en toute tranquillité d'esprit.",
  investments:
    "Maximisez vos investissements immobiliers avec notre société de développement de villas. Grâce à notre expertise dans l'identification d'emplacements à fort potentiel et la création de villas exceptionnelles, nous vous offrons une opportunité lucrative de développer votre portefeuille et d'obtenir des rendements impressionnants sur le marché de l'immobilier de luxe. Investissez en toute confiance et laissez-nous vous aider à libérer tout le potentiel de vos projets immobiliers.",
  consulting:
    'Naviguez sur le marché immobilier espagnol avec facilité et expertise grâce à nos services de conseil complets. Notre équipe de professionnels expérimentés fournit des informations précieuses, des analyses de marché et des conseils personnalisés pour vous aider à prendre des décisions éclairées et à saisir des opportunités immobilières rentables dans le secteur dynamique de la construction de villas en Espagne.',
  architect:
    "Transformez vos rêves de développement de villas en réalité grâce à l'expertise de nos architectes qualifiés. Des conceptions innovantes qui s'harmonisent avec l'environnement à l'attention méticuleuse portée aux détails, nous créons des espaces de vie exceptionnels qui dépassent les attentes et laissent un héritage architectural durable.",
  landscape_design:
    "Mettez en valeur la beauté et l'allure de votre villa grâce à nos services d'aménagement paysager en Espagne. Notre équipe de concepteurs experts crée des espaces extérieurs à couper le souffle, en incorporant des jardins luxuriants, de superbes paysages en dur et des jeux d'eau captivants, afin que votre villa se distingue comme une véritable oasis de luxe et de tranquillité.",
  building_materials:
    "Nous proposons une large sélection de matériaux de construction de haute qualité pour vous aider à mener à bien votre projet de construction. Des matériaux de base aux finitions, nous avons tout ce qu'il vous faut pour donner vie à votre projet.",
};
