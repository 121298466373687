import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';

export const SliderSectionWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background: ${colorVariables.mainBackgroundColor};

  .learn-more-btn {
    position: absolute;
    bottom: 5.25rem;
    right: ${paddings.tabletSidePadding};
    width: 11rem;
    z-index: 1;
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: ${colorVariables.alternativeTextColor};

    @media only screen and (min-width: ${Breakpoints.lg}px) {
      right: ${paddings.desktopSidePadding};
    }

    @media only screen and (min-width: ${Breakpoints.xxl}px) {
      width: 19.5rem;
    }
  }

  .main-btn {
    position: absolute;
    bottom: 1.875rem;
    text-align: start;
    padding: 1rem 2rem;
    background: rgba(46, 46, 46, 0.2);
    left: ${paddings.tabletSidePadding};
    z-index: 1;
    width: 25rem;
    height: 6.75rem;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 100%;

    @media only screen and (min-width: ${Breakpoints.lg}px) {
      left: ${paddings.desktopSidePadding};
      padding: 0.875rem 3.5rem;
      width: 38.75rem;
      height: 6.75rem;
      font-size: 2.5rem;
    }

    @media only screen and (min-width: ${Breakpoints.xxl}px) {
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    display: flex;
    justify-content: end;
    bottom: 1.875rem !important;
    padding-right: ${paddings.tabletSidePadding};

    @media only screen and (min-width: ${Breakpoints.lg}px) {
      padding-right: ${paddings.desktopSidePadding};
    }
  }

  .swiper-pagination-bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 3.375rem;
    text-align: center;
    line-height: 1.25rem;
    border-radius: 0;
    font-size: 12px;
    color: ${colorVariables.mainTextColorWitOpacity};
    opacity: 1;
    border-top: 1px ${colorVariables.buttonBorder} solid;
    border-right: 1px ${colorVariables.buttonBorder} solid;
    border-bottom: 1px ${colorVariables.buttonBorder} solid;
    margin-left: 0 !important;
    margin-right: 0 !important;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.3);
    transition: ${transitions.buttonBlurHoverTransition};

    @media only screen and (min-width: ${Breakpoints.xxl}px) {
      width: 4.875rem;
    }

    &:first-child {
      border-left: 1px ${colorVariables.buttonBorder} solid;
    }

    &:hover {
      backdrop-filter: blur(5px);
    }
  }

  .swiper-pagination-bullet-active {
    color: ${colorVariables.mainTextColor};
  }
`;

export const InfoBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2.75rem;
  right: ${paddings.tabletSidePadding};
  width: 8.875rem;
  height: 2rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  z-index: 4;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    right: ${paddings.desktopSidePadding};
  }
`;
