import React, { FC } from 'react';
import {
  BurgerMenuButton,
  BurgerMenuContainer,
  BurgerMenuLine,
  NavBurgerWrapper,
  NavbarMenuItem,
  NavbarMenuItems,
  BurgerMenuWrapper,
  NavbarMenuItemsWrapper,
  ServicesList,
  ServicesItem,
  ServicesName,
} from './NavBurgerMobile.styled';

// import { mobileNavBarItemTitlesEnArray } from '../../TopNavBar/TopNavBar.constatnts';
import { NavBarItemTitlesProps } from '../../TopNavBar/TopNavBar.types';
import { getMenuItems } from '../../MenuSection/MenuSection.constatnts';
import { HeaderMenuComponent } from '../../HeaderMenuComponent';
import { useNavigate } from 'react-router';
import { mainSection } from '../../../shared/helpers/globalConstants';
import { useTranslation } from 'react-i18next';
export interface NavBurgerProps {
  menuIsOpen: boolean;
  setMenuIsOpen: any;
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
}

export const NavBurgerMobile: FC<NavBurgerProps> = ({
  menuIsOpen,
  setMenuIsOpen,
  activeId,
  setActiveId,
}) => {
  const navigate = useNavigate();
  const menuItems = getMenuItems();
  const { t } = useTranslation();
  const handleToggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const handleMenuItemClick = (routePath: string | undefined) => {
    setMenuIsOpen(false);

    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  const navBarItemArray = {
    firstBlock: [
      {
        itemName: t('mainPage.mobileNavBarItemTitlesArray.itemNameServices'),
        routePath: t('mainPage.mobileNavBarItemTitlesArray.routePathServices'),
      },
      {
        itemName: t('mainPage.mobileNavBarItemTitlesArray.itemNameAbout'),
        routePath: t('mainPage.mobileNavBarItemTitlesArray.routePathAbout'),
      },
    ],
    secondBlock: [
      {
        itemName: t('mainPage.mobileNavBarItemTitlesArray.itemNameContacts'),
        routePath: t('mainPage.mobileNavBarItemTitlesArray.routePathContacts'),
      },
      {
        itemName: t('mainPage.mobileNavBarItemTitlesArray.itemNameFavourite'),
        routePath: t('mainPage.mobileNavBarItemTitlesArray.routePathFavourite'),
      },
    ],
  };
  const menuList = [
    menuItems[9],
    menuItems[0],
    menuItems[10],
    menuItems[1],
    menuItems[2],
    menuItems[3],
    menuItems[4],
    menuItems[5],
    menuItems[6],
    menuItems[7],
    menuItems[8],
    menuItems[11],
  ]
  
  return (
    <NavBurgerWrapper>
      <BurgerMenuButton onClick={handleToggleMenu}>
        <BurgerMenuLine isOpen={menuIsOpen} />
        <BurgerMenuLine isOpen={menuIsOpen} />
        <BurgerMenuLine isOpen={menuIsOpen} />
      </BurgerMenuButton>

      <BurgerMenuContainer isOpen={menuIsOpen}>
        <BurgerMenuWrapper>
          <NavbarMenuItems>
            <NavbarMenuItem
              onClick={() =>
                handleMenuItemClick(
                  t('mainPage.mobileNavBarItemTitlesArray.routePathLeadForm') ||
                    '',
                )
              }>
              {t('mainPage.mobileNavBarItemTitlesArray.itemNameLeadForm')}
            </NavbarMenuItem>
            <NavbarMenuItemsWrapper>
              {navBarItemArray.firstBlock.map(
                (navBarItem: NavBarItemTitlesProps, index: number) => {
                  return (
                    <NavbarMenuItem
                      key={navBarItem.itemName + index}
                      className={`block-${index}`}
                      onClick={() => handleMenuItemClick(navBarItem.routePath)}>
                      {navBarItem.itemName}
                    </NavbarMenuItem>
                  );
                },
              )}
            </NavbarMenuItemsWrapper>
            <NavbarMenuItemsWrapper>
              {navBarItemArray.secondBlock.map(
                (navBarItem: NavBarItemTitlesProps, index: number) => {
                  return (
                    <NavbarMenuItem
                      key={navBarItem.itemName + index}
                      className={`block-${index}`}
                      onClick={() => handleMenuItemClick(navBarItem.routePath)}>
                      {navBarItem.itemName}
                    </NavbarMenuItem>
                  );
                },
              )}
            </NavbarMenuItemsWrapper>
          </NavbarMenuItems>
          <ServicesList>
            {menuList.map(({ icon, label, id, routePath }) => {
              return (
                <ServicesItem
                  key={id}
                  onClick={() => handleMenuItemClick(routePath)}>
                  {icon}
                  <ServicesName>{label}</ServicesName>
                </ServicesItem>
              );
            })}
          </ServicesList>
        </BurgerMenuWrapper>
      </BurgerMenuContainer>
    </NavBurgerWrapper>
  );
};
