import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getPhilosophyTextsList } from './InvestmentsPhilosophySection.constants';
import {
  InvestmentsPhilosophyWrapper,
  InvestmentsPhilosophyBgWrapper,
  InvestmentsTitle,
  InvestmentsList,
  InvestmentsItem,
  InvestmentsItemTitle,
  InvestmentsItemText,
} from './InvestmentsPhilosophySection.styled';

export const InvestmentsPhilosophySection: FC = () => {
  const { t } = useTranslation();
  const textsList = getPhilosophyTextsList();
  return (
    <InvestmentsPhilosophyBgWrapper>
      <InvestmentsPhilosophyWrapper>
        <InvestmentsTitle>
          {t('investmentsPage.philosophyTitle')}
        </InvestmentsTitle>
        <InvestmentsList>
          {textsList.map(({ id, label, description }) => {
            return (
              <InvestmentsItem key={id}>
                <InvestmentsItemTitle>{label}</InvestmentsItemTitle>
                <InvestmentsItemText>{description}</InvestmentsItemText>
              </InvestmentsItem>
            );
          })}
        </InvestmentsList>
      </InvestmentsPhilosophyWrapper>
    </InvestmentsPhilosophyBgWrapper>
  );
};
