import styled from 'styled-components';
import { colorVariables, Breakpoints } from '../../../../theme/Theme.constants';

export const PartnersWrapper = styled.div`
  position: relative;
  z-index: 15;
`;

export const TitleWrapper = styled.div`
  padding: 1.562rem 2.5rem;
  background: rgba(23, 23, 25, 0.1);

  border-width: 1px 0px;
  border-style: solid;
  border-color: ${colorVariables.aboutUsOpacityPart};
  backdrop-filter: blur(10px);
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

export const AfterTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 1.25rem;
  border-width: 1px 0px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
`;

export const AfterTitle = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  max-width: 20rem;
  opacity: 0.8;
`;

export const ListBlock = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const ItemBlock = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 0;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  flex-basis: calc(100% / 2);
`;

export const IconBlock = styled.img`
  width: 6.875rem;
  height: 1.25rem;

  &.sun-power {
    width: 8.75rem;
    height: 2.812rem;
  }

  &.lg{
    width: 5rem;
    height: 2.187rem;
  }
`;
