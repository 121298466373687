import design from '../../../../shared/assets/images/architect/4d-design.jpg';
import tour from '../../../../shared/assets/images/architect/3d-tour.jpg';
import consultations from '../../../../shared/assets/images/architect/consultations.jpg';
import foundation from '../../../../shared/assets/images/architect/foundation.jpg';
import support from '../../../../shared/assets/images/architect/support.jpg';
import busing from '../../../../shared/assets/images/architect/hannah-busing.jpg';
import model from '../../../../shared/assets/images/architect/model-house.jpg';
export const currentTexts = {
  label: 'Our Process',
  description:
    'At Arquitecto, we follow a comprehensive design process that ensures that every project is executed with precision and attention to detail. Our process includes:',
};

export const currentPortfolioTexts = {
  label: 'Portfolio',
  description: '',
};

export const processList = [
  {
    number: 1,
    label: '4D design',
    description:
      'Thanks to innovative technologies, we will be able to show our clients at what stage the process of working on a project is. And also visualize in volumetric form how the room will look today, tomorrow, in a week, so that you can be sure that deadlines are met.',
    img: design,
  },
  {
    number: 2,
    label: '3D tour',
    description:
      "Even before the house is built, you can walk along its corridors, visit your office or children's room. Does this seem like fantasy? With the help of the 3D tour, you can feel like you are in a Minecraft game, where you can walk around the apartments, open the doors and enjoy every square meter. The main advantage is that this 3D visualization will soon transform into your real home.",
    img: tour,
  },
  {
    number: 3,
    label: 'Before, during and after',
    description:
      'We accompany our clients at every stage of creating the perfect architectural solution. Our mission is to turn any space into a place you call home. And this is possible only if you pay attention to details at each stage.',
    img: busing,
  },
  {
    number: 4,
    label: 'Consultations',
    description:
      'We provide advice, encourage your grandiose rational ideas and discourage risky initiatives, based on our experience. We will tell you where it is better to place windows so that there is a lot of sunlight. We will warn you against incorrect placement of pipes and more.',
    img: consultations,
  },
  {
    number: 5,
    label: 'Flexible layouts',
    description:
      "Did you change your concept at the last moment? It's OK. Thanks to computer modeling, a three-dimensional model of the future building is created, which makes it possible to make changes to the project or propose new ideas. With the help of professional programs, architects easily create realistic visualizations of the future building based on drawings and calculations. This allows you to quickly make changes to the project, avoid mistakes and unnecessary costs for alterations already during construction.",
    img: model,
  },
  {
    number: 6,
    label: 'Foundation without risks',
    description:
      'We have all the necessary equipment for geodetic surveys and research of soils and groundwater. At this stage, we pre-calculate the cost of the project and analyze its investment attractiveness. It is possible to evaluate the profitability of construction to the end only after the development of a preliminary design.',
    img: foundation,
  },
  {
    number: 7,
    label: 'Legal support',
    description:
      'Specialists will develop the necessary technical documentation (including a draft design, a detailed design with all diagrams and drawings), which will comply with the features of the operation of the facility, current building codes and regulations, special requirements, as well as environmental and sanitary standards in accordance with your order.',
    img: support,
  },
];
