import i18next from 'i18next';

export const mainSection = document.getElementById('root');

export const isFavouriteCheck = (id: number) => {
  const isFavouriteArrayString = localStorage.getItem('isFavourite');

  let isFavouriteArray =
    isFavouriteArrayString && JSON.parse(isFavouriteArrayString);

  const isServiceFavourite = isFavouriteArray?.some(
    (service: any) => service.id === id,
  );

  return isServiceFavourite;
};

export const addServiceInFauvorite = (id: number, title: string) => {
  if (!localStorage.getItem('isFavourite')) {
    localStorage.setItem('isFavourite', JSON.stringify([]));
  }

  const isFavouriteArrayString = localStorage.getItem('isFavourite');

  let isFavouriteArray =
    isFavouriteArrayString && JSON.parse(isFavouriteArrayString);

  const isServiceFavourite = isFavouriteArray?.some(
    (service: any) => service.id === id,
  );

  if (isServiceFavourite) {
    isFavouriteArray = isFavouriteArray?.filter(
      (service: any) => service.id !== id,
    );
  } else {
    isFavouriteArray.push({
      id: id,
      service: title,
    });
  }

  localStorage.setItem('isFavourite', JSON.stringify(isFavouriteArray));
};

export const getPrivacy = () => {
  const privacyText = i18next.t('privacyPage.privacyText', {
    returnObjects: true,
  }) as { id: number; title: string; text: string }[];

  return privacyText;
};

export const getConditions = () => {
  const conditionsText = i18next.t('conditionsPage.conditionsText', {
    returnObjects: true,
  }) as { id: number; title: string; text: string }[];

  return conditionsText;
};
