import React, { ChangeEvent, FC, memo } from 'react';
import { Input } from './TextAuthInput.styled';

export interface TextAuthInputProps {
  onChange?: (e: string | number | ChangeEvent<any>) => void;
  placeholder: string;
  type: string;
  name: string;
  required?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  value?: string | number;
  inputProps?: any;
  addClassname?: string;
}

export const TextAuthInput: FC<TextAuthInputProps> = ({
  type,
  name,
  onChange,
  required,
  disabled,
  defaultValue,
  placeholder,
  addClassname,
  value,
}) => {
  return (
    <Input
      type={type}
      name={name}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      defaultValue={defaultValue}
      className={addClassname}
    />
  );
};
