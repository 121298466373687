export const packagesTexts = {
label: `Paquets d' inversió`,
description:
`El nostre procés d'inversió es basa en un enfocament rigorós i disciplinat de recerca i anàlisi. Creiem que la recerca i l'anàlisi en profunditat són clau per identificar oportunitats d'inversió d'alta qualitat i administrar el risc de manera efectiva.`,
};

export const packagesBlocks = {
firstName: 'Nom',
firstDownloadText: 'Descarregar',

secondName: 'Nom',
secondDownloadText: 'Descarregar',

thirdName: 'Nom',
thirdDownloadText: 'Descarregar',
};