import React, { FC } from 'react';
import {
  BenefitsWrapper,
  List,
  Item,
  Text,
  Img,
} from './BenefitsSection.styled';
import image from '../../../shared/assets/images/construction/benefits.tablet.jpg';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';
import { getTextsBenefits } from './BenefitsSection.constants';
export const BenefitsSection: FC = () => {
  const textsBenefits = getTextsBenefits();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  return (
    <BenefitsWrapper>
      <List>
        {textsBenefits.map(({ id, text }) => {
          return (
            <Item key={id}>
              <Text>{text}</Text>
            </Item>
          );
        })}
      </List>
      {isDesktop && <Img />}
    </BenefitsWrapper>
  );
};
