import React, { FC } from 'react';
import {
  DropDownFilterWrapper,
  List,
  Item,
  Name,
} from './DropDownFilter.styled';

interface DropDownProps {
  filterList: { id: number; name: string }[];
  rightPosition?: string;
  setCurrentTypeName: any;
  setIsOpenFilter: any;
}

export const DropDownFilter: FC<DropDownProps> = ({
  filterList,
  rightPosition,
  setCurrentTypeName,
  setIsOpenFilter,
}) => {
  return (
    <DropDownFilterWrapper rightPosition={rightPosition}>
      <List>
        {filterList.map(({ id, name }) => {
          return (
            <Item
              key={id}
              onClick={() => {
                setCurrentTypeName(name.toLowerCase());
                setIsOpenFilter(false);
              }}>
              <Name>{name}</Name>
            </Item>
          );
        })}
      </List>
    </DropDownFilterWrapper>
  );
};
