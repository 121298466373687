import React, { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  HeaderSectionWrapper,
  HeaderSectionSubstrate,
  HeaderSectionBlocks,
} from './HeaderSection.styled';
import { Breakpoints } from '../../theme/Theme.constants';
import { HeaderDesktop } from './DesktopBlock';
import { HeaderTablet } from './TabletBlock';
import { HeaderMobile } from './MobileBlock';
import { HeaderHoverBlock } from './HeaderHoverBlock';

import { BackgroundImageBlock } from '../BackgroundImageBlock';

export const HeaderSection: FC<{ menuItems: any }> = ({ menuItems }) => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  return (
    <HeaderSectionWrapper>
      <BackgroundImageBlock activeId={activeId} menuItems={menuItems} />
      <HeaderSectionSubstrate>
        <HeaderSectionBlocks>
          {isDesktop && (
            <HeaderDesktop setActiveId={setActiveId} activeId={activeId} />
          )}
          {isDesktop && (
            <HeaderHoverBlock setActiveId={setActiveId} activeId={activeId} />
          )}
          {isTablet && !isDesktop && (
            <HeaderTablet setActiveId={setActiveId} activeId={activeId} />
          )}
          {isMobile && (
            <HeaderMobile setActiveId={setActiveId} activeId={activeId} />
          )}
        </HeaderSectionBlocks>
      </HeaderSectionSubstrate>
    </HeaderSectionWrapper>
  );
};
