import {
  TopNavbarTitles as TopNavbarTitlesEs,
  navBarItemTitlesArray as navBarItemTitlesEsArray,
  mobileNavBarItemTitlesArray as mobileNavBarItemTitlesEsArray,
} from '../pages/MainPage/TopNavBar/es';
import {StubProjectsPageTexts as StubProjectsPageTextsEs} from '../pages/StubProjectsPage/es'
import { HeaderTitles as HeaderTitlesEs } from '../pages/MainPage/HeaderSection/es';

import { SliderTitles as SliderTitlesEs } from '../pages/MainPage/SliderSection/es';

import { ourStoryTexts as ourStoryTextsEs } from '../pages/MainPage/OurStoryBlock/es';
import {
  servicesTexts as SolarServicesTextsEs,
  servicesList as SolartServicesListEs,
} from '../pages/SolarEnergyPage/ServicesSection/es';
import {
  ourValuesTitles as ourValuesTitlesEs,
  ourValuesList as ourValuesListEs,
} from '../pages/MainPage/OurValuesBlock/es';

import {
  MenuItemsTitles as MenuItemsTitlesEs,
  MenuItemsDescriptions as MenuItemsDescriptionsEs,
} from '../pages/MainPage/MenuSection/es';

import { AboutUsTitles as AboutUsTitlesEs } from '../pages/MainPage/AboutSection/es';

import { ContactsTitles as ContactsTitlesEs } from '../pages/MainPage/ContactsSection/es';

import {
  Construction as ConstructionEs,
  // Projects as ProjectsEs,
  ModularHouses as ModularHousesEs,
  Architect as ArchitectEs,
  Interior as InteriorEs,
  SwimmingPools as SwimmingPoolsEs,
  SmartHome as SmartHomeEs,
  Investments as InvestmentsEs,
  SolarEnergy as SolarEnergyEs,
  Inmobiliaria as InmobiliariaEs,
  Consulting as ConsultingEs,
  LandscapeDesign as LandscapeDesignEs,
} from '../pages/MainPage/FAQSection/es';

import {
  footerNavBar as footerNavBarEs,
  footerTitles as footerTitlesEs,
} from '../pages/MainPage/FooterSection/es';

import { contactsHeaderTitles as contactsHeaderTitlesEs } from '../pages/ContactsPage/ContactsHeaderSection/es';

import {
  HeaderSection as HeaderSectionEs,
  description as SmartHomeDescriptionEs,
} from '../pages/SmartHomePage/HeaderSection/es';

import { PartnersSection as PartnersSectionEs } from '../pages/SmartHomePage/PartnersSection/es';

import { CardsSection as CardsSectionEs } from '../pages/SmartHomePage/CardsSection/es';

import { HeaderSection as SwimmingPoolsHeaderSectionEs } from '../pages/SwimmingPoolsPage/HeaderSection/es';

import { PoolsSection as SwimmingPoolsPoolsSectionEs } from '../pages/SwimmingPoolsPage/PoolsSection/es';

import { ColorsSection as SwimmingPoolsColorsSectionEs } from '../pages/SwimmingPoolsPage/ColorsSection/es';

import { PoolsAccessoriesSection as SwimmingPoolsAccessoriesSectionEs } from '../pages/SwimmingPoolsPage/AccessoriesSection/es';

import { TechnologiesSection as SwimmingPoolsTechnologiesSectionEs } from '../pages/SwimmingPoolsPage/TechnologiesSection/es';

import {
  AccessoriesSection as AccessoriesSectionEs,
  accessoriesIcons as AccessoriesIconsEs,
} from '../pages/SmartHomePage/AccessoriesSection/es';

import { SmartHomeStepSection as SmartHomeStepsSectionEs } from '../pages/SmartHomePage/StepsBlockSection/es';

import { MainSection as MainSectionEs } from '../pages/ServicesPage/es';

import {
  investmentsNavItems as InvestmentsNavItemsEs,
  investmentsSecondDescription as InvestmentsSecondDescriptionEs,
} from '../pages/InvestmentsPage/HeaderSection/es';

import {
  investmentsProcess as InvestmentsProcessEs,
  investmentsProcessTexts as investmentsProcessTextsEs,
} from '../pages/InvestmentsPage/InvestmentsProcess/es';

import {
  philosophyTextsList as PhilosophyTextsListEs,
  philosophyTitle as PhilosophyTitleEs,
} from '../pages/InvestmentsPage/InvestmentsPhilosophySection/es';

import {
  packagesTexts as packagesTextsEs,
  packagesBlocks as packagesBlocksEs,
} from '../pages/InvestmentsPage/InvestmentsPackagesSection/es';

import {
  teamTexts as TeamTextsEs,
  teamBlocks as TeamBlocksEs,
} from '../pages/InvestmentsPage/InvestmentsTeamSection/es';

import {
  realEstateNavItems as RealEstateNavItemsEs,
  realEstateSecondDescription as RealEstateSecondDescriptionEs,
} from '../pages/RealEstatePage/HeaderSection/es';

import {
  realEstateProcessTexts as RealEstateProcessTextsEs,
  processList as ProcessListEs,
  servicesTexts as RealEstateServicesTextsEs,
  servicesList as RealEstateServicesListEs,
} from '../pages/RealEstatePage/ServicesSection/es';

import { aboutListText as AboutListTextEs } from '../pages/RealEstatePage/AboutSection/es';

import {
  navItems as ConsultingNavItemsEs,
  consultingProcessTexts as ConsultingProcessTextsEs,
  processList as ConsultingProcessListEs,
  description as ConsultingDescriptionEs,
} from '../pages/ConsultingPage/es';

import {
  discoverList as DiscoverListEs,
  realEstateDiscoverTexts as RealEstateDiscoverTextsEs,
  aboutDiscoverList as AboutDiscoverListEs,
  aboutText as AboutTextEs,
} from '../pages/RealEstatePage/DiscoverSection/es';

import {
  aboutUsNavItems as AboutUsNavItemsEs,
  aboutUsSecondDescription as AboutUsSecondDescriptionEs,
  aboutUsTitle as AboutUsTitleEs,
} from '../pages/AboutUsPage/HeaderSection/es';

import {
  professionList as ProfessionListEs,
  pageTitle as PageTitleEs,
} from '../pages/AboutUsPage/OurTeamSection/es';

import {
  pageTitle as OurStoryPageTitleEs,
  pageAfterTitle as OurStoryPageAfterTitleEs,
} from '../pages/AboutUsPage/OurStoryMobileBlock/es';

import { projectsItems as ProjectsItemsEs } from '../pages/ProjectsPage/HeaderSection/es';

import { projectsList as ProjectsListEs } from '../pages/ProjectsPage/ProjectsSection/es';

import {
  headMenu as HeadMenuEs,
  typeHouseStyle as TypeHouseStyleEs,
  inputsTexts as InputsTextsEs,
  searchBtn as SearchBtnEs,
  removeAll as RemoveAllEs,
} from '../pages/ProjectsPage/FilterSection/es';

import {
  headerSection as SolarHeaderSectionEs,
  description as SolarEnergyDescriptionEs,
} from '../pages/SolarEnergyPage/HeaderSection/es';

import {
  solarEnergySetupsTexts as SolarEnergySetupsTextsEs,
  setupList as SetupListEs,
} from '../pages/SolarEnergyPage/SetupsSection/es';

import {
  solarEnergyBenefitsTexts as SolarEnergyBenefitsTextsEs,
  benefitsList as BenefitsListEs,
} from '../pages/SolarEnergyPage/BenefitsSection/es';

import { partnersSection as SolarPartnersSectionEs } from '../pages/SolarEnergyPage/PartnersSection/es';

import {
  architectNavItems as ArchitectNavItemsEs,
  architectSecondDescription as ArchitectSecondDescriptionEs,
} from '../pages/ArchitectPage/HeaderSection/es';

import {
  currentTexts as CurrentTextsEs,
  currentPortfolioTexts as CurrentPortfolioTextsEs,
  processList as ProcessListArchitectEs,
} from '../pages/ArchitectPage/ProcessSection/es';

import {
  projectsName as ProjectsNameEs,
  portfolioText as PortfolioTextEs,
} from '../pages/ArchitectPage/PortfolioSection/es';

import {
  aboutListLocation as AboutListLocationEs,
  infoText as InfoTextEs,
  mainText as MainTextEs,
  headerBtnText as HeaderBtnTextEs,
  titleAbout as TitleAboutEs,
  textsAbout as TextsAboutEs,
  textPhoto as TextPhotoEs,
  view as ViewBtnTextEs,
  housesDetailsMobile as HousesDetailsMobileEs,
  housesDetailsDesktop as HousesDetailsDesktopEs,
  photoTitle as PhotoTitleEs,
} from '../pages/InteriorCasePage/es';

import {
  interiorNavItems as InteriorNavItemsEs,
  currentText as InteriorCurrentTextEs,
  currentPortfolioText as InteriorCurrentPortfolioTextEs,
  servicesList as InteriorServicesListEs,
  description as InteriorDescriptionEs,
} from '../pages/InteriorPage/es';

import {
  navItems as ConstructionNavItemsEs,
  description as ConstructionDescriptionEs,
  processList as ConstructionProcessListEs,
  aboutTexts as ConstructionAboutTextsEs,
  preConstructionTitle as PreConstructionTitleEs,
  preConstructionText as PreConstructionTextEs,
  constructionStepBlockTitle as ConstructionStepBlockTitleEs,
  constructionStepBlockDescription as ConstructionStepBlockDescriptionEs,
  constructionStepSection as ConstructionStepSectionEs,
} from '../pages/ConstructionPage/es';

import {
  navItems as LandscapeDesignNavItemsEs,
  description as LandscapeDesignDescriptionEs,
  currentTexts as LandscapeCurrentTextsEs,
  processList as LandscapeProcessListEs,
  currentTextsBenefits as CurrentTextsBenefitsEs,
  textsBenefits as TextsBenefitsEs,
} from '../pages/LandscapeDesignPage/es';

import {
  currentTexts as BuildingCurrentTextsEs,
  textsBenefits as BuildingTextsBenefitsEs,
  benefitsTitle as BuildingBenefitsTitleEs,
  materialsList as BuildingMaterialsListEs,
} from '../pages/BuildingMaterialsPage/es';
import { loginPage as LoginPageEs } from '../pages/LoginPage/es';
import { FavoritesSection as FavoritesSectionEs } from '../pages/FavoritesPage/es';
import {
  modularHousesNavItems as ModularHousesNavItemsEs,
  description as ModularHousesDescriptionEs,
} from '../pages/ModularHousesPage/HeaderSection/es';
import {
  typeOfHouses as TypeOfHousesEs,
  modelTitle as ModelTitleEs,
  modelDescription as ModelDescriptionEs,
} from '../pages/ModularHousesPage/ModelSection/es';
import {
  currentTexts as CurrentModularHousesTextsEs,
  options as OptionsEs,
} from '../pages/ModularHousesPage/OptionsSection/es';
import {
  blockTitle as BlockTitleEs,
  blockDescription as BlockDescriptionEs,
} from '../pages/ModularHousesPage/StepsBlock/es';
import {
  title as TopServicesTitleEs,
  allServices as AllServicesEs,
  description as ServicesDescriptionEs,
} from '../pages/MainPage/TopServicesSection/es';
import {
  architectStepBlockTitle as ArchitectStepBlockTitleEs,
  architectStepBlockDescription as ArchitectStepBlockDescriptionEs,
  architectStepSection as ArchitectStepSectionEs,
} from '../pages/ArchitectPage/StepsSection/es';
import { cookiesPopup as CookiesPopupEs } from '../components/CookiesPopup/es';
import { modularHousesStepSection as modularHousesStepSectionEs } from '../pages/ModularHousesPage/StepsBlock/es';
import { swimmingPoolsStepsSection as SwimmingPoolsStepsSectionEs } from '../pages/SwimmingPoolsPage/StepsBlockSection/es';
import { ClientPartnerNavbarItems as ClientPartnerNavbarItemsEs } from '../pages/LeadFormPage/es';
import { MainNavbarItems as MainNavbarItemsEs } from '../pages/LeadFormPage/es';
import { LeadFormPage as LeadFormPageEs } from '../pages/LeadFormPage/es';
import {conditionsText as ConditionsTextEs} from '../pages/ConditionsPage/es'
import {privacyText as PrivacyTextEs} from '../pages/PrivacyPage/es'
export const es = {
  translation: {
    mainPage: {
      TopNavbarTitles: TopNavbarTitlesEs,
      navBarItemTitlesArray: navBarItemTitlesEsArray,
      mobileNavBarItemTitlesArray: mobileNavBarItemTitlesEsArray,
      headerTitles: HeaderTitlesEs,
      sliderTitles: SliderTitlesEs,
      MenuItemsTitles: MenuItemsTitlesEs,
      MenuItemsDescriptions: MenuItemsDescriptionsEs,
      AboutUsTitles: AboutUsTitlesEs,
      ourStoryTexts: ourStoryTextsEs,
      ourValuesTitles: ourValuesTitlesEs,
      ourValuesList: ourValuesListEs,
      ContactsTitles: ContactsTitlesEs,
      topServicesTitle: TopServicesTitleEs,
      allServices: AllServicesEs,
      servicesDescription: ServicesDescriptionEs,
      FAQ: {
        LandscapeDesign: LandscapeDesignEs,
        Construction: ConstructionEs,
        // Projects: ProjectsEs,
        ModularHouses: ModularHousesEs,
        Architect: ArchitectEs,
        Interior: InteriorEs,
        SwimmingPools: SwimmingPoolsEs,
        SmartHome: SmartHomeEs,
        Investments: InvestmentsEs,
        SolarEnergy: SolarEnergyEs,
        Inmobiliaria: InmobiliariaEs,
        Consulting: ConsultingEs,
      },
      footerNavBar: footerNavBarEs,
      footerTitles: footerTitlesEs,
    },
    contactsPage: {
      contactsHeaderTitles: contactsHeaderTitlesEs,
    },
    smartHome: {
      HeaderSection: HeaderSectionEs,
      PartnersSection: PartnersSectionEs,
      CardsSection: CardsSectionEs,
      AccessoriesSection: AccessoriesSectionEs,
      SmartHomeStepsSection: SmartHomeStepsSectionEs,
      SmartHomeDescription: SmartHomeDescriptionEs,
      AccessoriesIcons: AccessoriesIconsEs,
    },
    services: {
      MainSection: MainSectionEs,
    },
    investmentsPage: {
      investmentsNavItems: InvestmentsNavItemsEs,
      investmentsSecondDescription: InvestmentsSecondDescriptionEs,
      investmentsProcess: InvestmentsProcessEs,
      investmentsProcessTexts: investmentsProcessTextsEs,
      philosophyTextsList: PhilosophyTextsListEs,
      philosophyTitle: PhilosophyTitleEs,
      packagesTexts: packagesTextsEs,
      packagesBlocks: packagesBlocksEs,
      teamTexts: TeamTextsEs,
      teamBlocks: TeamBlocksEs,
    },
    realEstatePage: {
      realEstateNavItems: RealEstateNavItemsEs,
      realEstateSecondDescription: RealEstateSecondDescriptionEs,
      realEstateProcessTexts: RealEstateProcessTextsEs,
      processList: ProcessListEs,
      aboutListText: AboutListTextEs,
      discoverList: DiscoverListEs,
      realEstateDiscoverTexts: RealEstateDiscoverTextsEs,
      aboutDiscoverList: AboutDiscoverListEs,
      aboutText: AboutTextEs,
      servicesTexts: RealEstateServicesTextsEs,
      servicesList: RealEstateServicesListEs,
    },
    consultingPage: {
      consultingNavItems: ConsultingNavItemsEs,
      consultingProcessTexts: ConsultingProcessTextsEs,
      consultingProcessList: ConsultingProcessListEs,
      consultingDescription: ConsultingDescriptionEs,
    },
    aboutUsPage: {
      aboutUsNavItems: AboutUsNavItemsEs,
      aboutUsSecondDescription: AboutUsSecondDescriptionEs,
      aboutUsTitle: AboutUsTitleEs,
      professionList: ProfessionListEs,
      pageTitle: PageTitleEs,
      ourStoryPageTitle: OurStoryPageTitleEs,
      ourStoryPageAfterTitle: OurStoryPageAfterTitleEs,
    },
    projectsPage: {
      projectsItems: ProjectsItemsEs,
      headMenu: HeadMenuEs,
      typeHouseStyle: TypeHouseStyleEs,
      inputsTexts: InputsTextsEs,
      projectsList: ProjectsListEs,
      searchBtn: SearchBtnEs,
      removeAll: RemoveAllEs,
    },
    solarEnergyPage: {
      headerSection: SolarHeaderSectionEs,
      solarEnergyBenefitsTexts: SolarEnergyBenefitsTextsEs,
      solarEnergyDescription: SolarEnergyDescriptionEs,
      benefitsList: BenefitsListEs,
      partnersSection: SolarPartnersSectionEs,
      solarEnergySetupsTexts: SolarEnergySetupsTextsEs,
      setupList: SetupListEs,
      servicesTexts: SolarServicesTextsEs,
      servicesList: SolartServicesListEs,
    },
    architectPage: {
      architectNavItems: ArchitectNavItemsEs,
      architectSecondDescription: ArchitectSecondDescriptionEs,
      currentTexts: CurrentTextsEs,
      currentPortfolioTexts: CurrentPortfolioTextsEs,
      processList: ProcessListArchitectEs,
      projectsName: ProjectsNameEs,
      portfolioText: PortfolioTextEs,
      architectStepBlockTitle: ArchitectStepBlockTitleEs,
      architectStepBlockDescription: ArchitectStepBlockDescriptionEs,
      architectStepSection: ArchitectStepSectionEs,
    },
    interiorCasePage: {
      aboutListLocation: AboutListLocationEs,
      infoText: InfoTextEs,
      mainText: MainTextEs,
      headerBtnText: HeaderBtnTextEs,
      titleAbout: TitleAboutEs,
      textsAbout: TextsAboutEs,
      textPhoto: TextPhotoEs,
      viewBtnText: ViewBtnTextEs,
      housesDetailsMobile: HousesDetailsMobileEs,
      housesDetailsDesktop: HousesDetailsDesktopEs,
      photoTitle: PhotoTitleEs,
    },
    interiorPage: {
      interiorNavItems: InteriorNavItemsEs,
      interiorCurrentText: InteriorCurrentTextEs,
      interiorCurrentPortfolioText: InteriorCurrentPortfolioTextEs,
      interiorServicesList: InteriorServicesListEs,
      interiorDescription: InteriorDescriptionEs,
    },
    constructionPage: {
      constructionNavItems: ConstructionNavItemsEs,
      constructionDescription: ConstructionDescriptionEs,
      constructionProcessList: ConstructionProcessListEs,
      constructionAboutTexts: ConstructionAboutTextsEs,
      preConstructionTitle: PreConstructionTitleEs,
      preConstructionText: PreConstructionTextEs,
      constructionStepBlockTitle: ConstructionStepBlockTitleEs,
      constructionStepBlockDescription: ConstructionStepBlockDescriptionEs,
      constructionStepSection: ConstructionStepSectionEs,
    },
    landscapeDesignPage: {
      landscapeDesignNavItems: LandscapeDesignNavItemsEs,
      landscapeDesignDescription: LandscapeDesignDescriptionEs,
      landscapeCurrentTexts: LandscapeCurrentTextsEs,
      landscapeProcessList: LandscapeProcessListEs,
      currentTextsBenefits: CurrentTextsBenefitsEs,
      textsBenefits: TextsBenefitsEs,
    },

    buildingMaterialsPage: {
      buildingCurrentTexts: BuildingCurrentTextsEs,
      buildingTextsBenefits: BuildingTextsBenefitsEs,
      buildingBenefitsTitle: BuildingBenefitsTitleEs,
      buildingMaterialsList: BuildingMaterialsListEs,
    },
    swimmingPools: {
      HeaderSection: SwimmingPoolsHeaderSectionEs,
      PoolsSection: SwimmingPoolsPoolsSectionEs,
      ColorsSection: SwimmingPoolsColorsSectionEs,
      TechnologiesSection: SwimmingPoolsTechnologiesSectionEs,
      AccessoriesSection: SwimmingPoolsAccessoriesSectionEs,
      SwimmingPoolsStepsSection: SwimmingPoolsStepsSectionEs,
    },
    favorites: {
      FavoritesSection: FavoritesSectionEs,
    },
    loginPage: {
      loginPage: LoginPageEs,
    },
    modularHousesPage: {
      modularHousesNavItems: ModularHousesNavItemsEs,
      modelTitle: ModelTitleEs,
      modelDescription: ModelDescriptionEs,
      typeOfHouses: TypeOfHousesEs,
      currentTexts: CurrentModularHousesTextsEs,
      options: OptionsEs,
      blockTitle: BlockTitleEs,
      blockDescription: BlockDescriptionEs,
      description: ModularHousesDescriptionEs,
      modularHousesStepSection: modularHousesStepSectionEs,
    },
    cookiesPopup: {
      cookiesPopup: CookiesPopupEs,
    },
    leadForm: {
      clientPartnerNavbarItems: ClientPartnerNavbarItemsEs,
      mainNavbarItems: MainNavbarItemsEs,
      leadFormPage: LeadFormPageEs,
    },
     stubProjectsPage: {
      stubProjectsPageTexts: StubProjectsPageTextsEs,
    },
     conditionsPage: {
      conditionsText: ConditionsTextEs,
    },
     privacyPage: {
      privacyText: PrivacyTextEs,
    }
  },
};
