import React, { FC } from 'react';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { RealEstateServicesSection } from '../../components/RealEstateServicesSection/RealEstateServicesSection';
import bgImage from '../../shared/assets/images/menu-background-images/inmobiliaria.jpg';
import { RealEstateAboutSection } from '../../components/RealEstateAboutSection/RealEstateAboutSection';
import { RealEstateDiscoverSection } from '../../components/RealEstateDiscoverSection/RealEstateDiscoverSection';
import { useTranslation } from 'react-i18next';
import { RealEstatePageWrapper,ServicesSectionWrapper } from './RealEstatePage.styled';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { PageServicesSection } from '../../shared/components/PageServicesSection';
import firstServiceImg from '../../shared/assets/images/real-estate/services/first-img.jpg';
import secondServiceImg from '../../shared/assets/images/real-estate/services/second-img.jpg';
import thirdServiceImg from '../../shared/assets/images/real-estate/services/third-img.jpg';
import fourthServiceImg from '../../shared/assets/images/real-estate/services/fourth-img.jpg';
import fifthServiceImg from '../../shared/assets/images/real-estate/services/fifth-img.jpg';
import sixthServiceImg from '../../shared/assets/images/real-estate/services/sixth-img.jpg';


export const RealEstatePage: FC = () => {
  const menuItems = getMenuItems();
  const { t } = useTranslation();
  const RealEstateInfo = menuItems[6];
  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  const isDesktopXXl = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });
  const navItems = [
    {
      title: t('realEstatePage.realEstateNavItems.services'),
      sectionId: 'services-section',
    },
    {
      title: t('realEstatePage.realEstateNavItems.landPlot'),
      sectionId: 'land-plot-section',
    },

  ];

  const servicesTexts = {
    label: t('realEstatePage.servicesTexts.label'),
    description: t('realEstatePage.servicesTexts.description'),
  };
  const servicesList = [
    {
      id: 1,
      text: t('realEstatePage.servicesList.textFirst'),
      img: firstServiceImg,
    },
    {
      id: 2,
      text: t('realEstatePage.servicesList.textSecond'),
      img: secondServiceImg,
    },
    {
      id: 3,
      text: t('realEstatePage.servicesList.textThird'),
      img: thirdServiceImg,
    },
    {
      id: 4,
      text: t('realEstatePage.servicesList.textFourth'),
      img: fourthServiceImg,
    },
    {
      id: 5,
      text: t('realEstatePage.servicesList.textFifth'),
      img: fifthServiceImg,
    },
    {
      id: 6,
      text: t('realEstatePage.servicesList.textSixth'),
      img: sixthServiceImg,
    },
  ];
  return (
    <RealEstatePageWrapper>
      <PageHeaderSection
        title={RealEstateInfo.label}
        logoImg={RealEstateInfo.icon}
        navItems={navItems}
        description={t('realEstatePage.realEstateSecondDescription')}
        bgImage={bgImage}
        id={RealEstateInfo.id}
      />
      <RealEstateServicesSection />
      <RealEstateAboutSection />

      <ServicesSectionWrapper id="services-section">
        <ProcessBlockHeader
          currentTexts={servicesTexts}
          mobileTitleSize={'30px'}
          isTextAlignCenter={false}
          descriptionColor={'rgba(255, 255, 255, 0.7)'}
          titlePadding={'35px'}
          titleWrapperWidth={isDesktopXXl ? '65%;' : isDesktop ? '50%;' : ''}
          titleSize={
            isDesktopXXl ? '40px' : isDesktop ? '27px;' : isTablet ? '15px' : ''
          }
        />
        <PageServicesSection servicesList={servicesList} />
      </ServicesSectionWrapper>
      {/* <RealEstateDiscoverSection />      //// for future features /// */}
    </RealEstatePageWrapper>
  );
};
