export const ContactsTitles = {
  contacts: 'Contactos',
  telNumber: '+34 600 59 20 07',
  email: 'info@modernacasa.es',
  socialMedia: 'Redes sociales',
  form: 'Formulario',
  name_placeholder: 'Nombre',
  email_placeholder: 'Correo electrónico*',
  phone_placeholder: 'Teléfono de contacto',
  description_placeholder: 'Descripción',
  password_placeholder: 'Contraseña',
  send: 'Enviar',
};