export const conditionsText = [
  {
    id: 1,
    title: 'TERMES I CONDICIONS',
    text: `
    Estem compromesos a protegir la seva privacitat. Aquesta Política de Privacitat descriu com i per què tractem les seves dades personals quan accedeix al nostre lloc web en modernacasa.es (en endavant, ""el lloc web""), en el curs de les nostres activitats de màrqueting i contractació, per comunicar els nostres serveis i desenvolupament comercial. Aquesta política també estableix els seus drets de privacitat i explica com posar-se en contacte amb nosaltres si és necessari.
    `,
  },

  {
    id: 2,
    title: 'QUI SOM',
    text: `
    Modernacasa (en endavant, ""nosaltres"", ""ens"", ""nostre"" o ""MC"") ha creat aquest lloc web per comunicar-se amb visitants ocasionals, clients potencials i empreses interessades en els nostres serveis, així com per proporcionar informació sobre MC i la TI. indústria de la subcontractació. Donem la benvinguda als visitants perquè vegin informació al nostre lloc web i l'utilitzin per comunicar-se directament amb MC. Controlem totes les dades personals processadores d'acord amb aquesta política. En visitar aquest lloc web, accepta la nostra Política de privacitat i accepta que podem recopilar dades personals durant la seva visita al lloc web.Si no està d'acord amb aquesta Política de privacitat, no dubti a abandonar el lloc web. Aquest lloc web no pretén oferir cap servei directament als infants. No recopilem sabent informació de nens. No obstant això, si és menor de 16 anys i desitja posar-se en contacte amb nosaltres, envieu-nos un consentiment per escrit dels seus pares o tutors per processar la seva informació personal.
    `,
  },

  {
    id: 3,
    title: 'QUINES DADES RECOPILEM?',
    text: `
   Recopilem, emmagatzemem i fem servir dades que són essencials per brindar als visitants el màxim benefici del nostre lloc web. Recopilem les següents dades durant la seva sessió al lloc web:

Informació que proporciona directament a través de formularis de contacte o missatges de correu electrònic, inclosa la seva adreça de correu electrònic, número de telèfon, nom, cognom, nom de l' empresa, càrrec, adreça postal i altra informació que està disposat a proporcionar-nos.
Dades sobre el seu comportament a les pàgines web durant la seva visita i informació sobre com navega pel lloc web.
Estadístiques, com les pàgines particulars que visita, la quantitat d'entrades úniques al lloc web, el navegador des del qual visita el lloc web, la resolució de la seva pantalla i la plataforma que utilitza.
A més, recopilem les seves dades personals en el curs de la nostra activitat de màrqueting i comunicació dels nostres serveis de la manera més convenient. A més, podem utilitzar les seves dades personals per a finalitats secundàries estretament relacionades amb les finalitats esmentides anteriorment, en circumstàncies en què raonablement esperaria aquest ús. En el curs de les nostres activitats de màrqueting i contractació, per comunicar els nostres serveis i desenvolupament comercial, podem recopilar les següents dades:

Informació que proporciona directament a través de formularis de contacte o missatges de correu electrònic, inclosa la seva adreça de correu electrònic, número de telèfon, nom, cognom, nom de l' empresa, càrrec, adreça postal i altra informació que està disposat a proporcionar-nos.
Dades sobre el seu comportament a les pàgines web durant la seva visita i informació sobre com navega pel lloc web.
Estadístiques, com les pàgines particulars que visita, la quantitat d'entrades úniques al lloc web, el navegador des del qual visita el lloc web, la resolució de la seva pantalla i la plataforma que utilitza.
Les dades esmentades anteriorment es recopilen sobre la base del seu consentiment o una altra base legal que es presenta a continuació i de conformitat amb les lleis aplicables, juntament amb les presentacions requerides davant les autoritats de protecció de dades, quan sigui necessari. Pot enviar el seu consentiment per correu electrònic a info@modernacasa.es. Recopilem la major part de la informació personal no pública directament de vostè o els seus agents, ja sigui en persona, per telèfon o electrònicament. En algunes circumstàncies, és possible que tercers ens proporcionin la seva informació personal (per exemple, algú ho va recomanar per a una possible ocupació). En aquests casos, prenem mesures raonables per assegurar-nos que estigui al tant de les dades que ens proporciona el tercer. MC no recopila intencionalment cap informació confidencial sobre vostè.

    `,
  },

  {
    id: 4,
    title: 'COM UTILITZEM AQUESTES DADES?',
    text: `
  Les dades personals que se'ns envien a través d'aquest lloc web i les dades personals que recopilem de vostè, de tercers o de fonts públiques s'utilitzaran (processaran) per a les finalitats especificades en aquesta Política.
    `,
  },
  {
    id: 5,
    title: 'UTILITZAREM LES SEVES DADES PERSONALS SOBRE LA SEGÜENT BASE LEGAL:',
    text: `
  Interès legítim (RGPD)
Consentiment (RGPD)
Una altra base legal disponible sota GDPR
Una altra base legal disponible sota GDPR
Per regla general, les seves dades personals s' utilitzaran per:

Interès legítim (RGPD)
Consentiment (RGPD)
Una altra base legal disponible sota GDPR
Una altra base legal disponible sota GDPR
Les dades personals tractades per MC no estan subjectes a l' elaboració de perfils ni a la presa de decisions automatitzada.
    `,
  },
  {
    id: 6,
    title: 'QUINS SÓN ELS TEUS DRETS?',
    text: `
   El nostre objectiu és ser completament transparents en la forma en què fem servir les seves dades personals i prendre mesures raonables per permetre-li corregir, modificar, eliminar o limitar l'ús de les seves dades personals. Té els drets de protecció de dades següents:

El dret a ser informat sobre la recopilació i l' ús de les seves dades personals. En qualsevol moment, pot presentar-nos una sol·licitud d'accés i sol·licitar una còpia de les seves dades personals en un format electrònic d'ús comú.
Dret a demanar informació sobre la finalitat del tractament de dades i informació sobre tercers que hagin rebut les seves dades personals.
Dret a demanar-nos la rectificació de les dades personals que siguin incorrectes o estiguin desactualitzades.
El dret a restringir el processament, cosa que significa que pot sol·licitar l'eliminació de les seves dades personals o demanar-nos que deixem de processar les seves dades en qualsevol moment.
Dret a sol·licitar la portabilitat de les seves dades personals.
El dret a oposar-se justificadament al tractament de les seves dades personals.
El dret a retirar el consentiment per al processament de dades en qualsevol moment si prèviament ens va donar el seu consentiment per al tractament de les seves dades personals. MC no cobrarà cap tarifa per la seva sol·licitud d'accés, però pot cobrar una tarifa administrativa per proporcionar una còpia de la seva informació personal. Per protegir les seves dades personals, és possible que li demanem que s'identifiqui abans de divulgar la informació sol·licitada. Considerem immediatament cadascuna de les seves sol·licituds i brindem una resposta substantiva, ja sigui prenent la mesura sol·licitada o brindant una explicació de per què la seva sol·licitud no pot executar-se de la nostra part. Pot exercir els seus drets enviant una sol·licitud als contactes que figuren a la secció de detalls de contacte a continuació. Si té alguna queixa sobre com processem les seves dades, comenci per presentar una queixa a info@modernacasa.es. Intentarem fer tot el possible per resoldre la seva sol·licitud. En cas que no estigui satisfet amb la nostra resposta, té dret a presentar una queixa davant l' autoritat de protecció de dades corresponent en el lloc de la seva residència. Tingui en compte que si ens comuniquem amb vostè per correu electrònic o correspondència, sempre pot donar-se de baixa de les nostres llistes de correu/mercadeig en qualsevol moment comunicant-se amb el correu electrònic indicat en aquesta Política o usant el botó (enllaç) ""cancel·lar subscripció"" en el nostre correu electrònic.

    `,
  },
  {
    id: 7,
    title: 'ON ES PROCESSEN LES SEVES DADES?',
    text: `
   Les seves dades personals poden processar-se en diversos llocs fora de la Unió Europea i els Estats Units, cadascun amb diferents estàndards de protecció de dades. Tanmateix, prendrem totes les mesures raonables per garantir que les seves dades es manegin de forma segura i d'acord amb els més alts estàndards previstos en el Reglament General de Protecció de Dades (RGPD, Reglament (UE) 2016/679 del Parlament Europeu i del Consell) , aquesta Política de privacitat i els estàndards de la indústria.
    `,
  },
  {
    id: 8,
    title: `TRACTAMENT DE DADES PERSONALS DE SOL·LICITANTS D'OCUPACIÓ`,
    text: `
  Com a part de qualsevol procés de contractació, MC recopila i processa dades personals proporcionades directament pels sol·licitants d'ocupació, les seves referències i les empreses de contractació que treballen en el nostre nom. Ens comprometem a ser transparents sobre com es recopilen i utilitzen les dades de conformitat amb les nostres obligacions de protecció de dades. Tipus de dades personals que recopilem MC recopila una varietat d'informació sobre vostè que inclou:

Número de telèfon, skype i enllaços a perfils de xarxes socials.
Detalls de qualificacions, habilitats, experiència i historial d' ocupació.
Informació sobre el nivell actual de remuneració, inclosos els drets a prestacions.
Informació sobre el seu dret a treballar a la UE, si així ho requereixen els nostres clients.
MC pot recopilar dades personals de diverses maneres. Per exemple, les dades es poden extreure de formularis de sol·licitud, CV (currículum vitae), o mitjançant entrevistes o altres mitjans d'avaluació. Les dades personals s' emmagatzemen en el nostre Sistema de seguiment de candidats i s' exporten a altres sistemes de TI. Si el conviden a MC per a una entrevista, tingui en compte que, per raons de seguretat, les nostres instal·lacions estan sota vigilància per vídeo. Podem recopilar imatges captades per la càmera.
    `,
  },

  {
    id: 9,
    title: 'FINALITAT DEL PROCESSAMENT',
    text: `
 Donada la naturalesa de les nostres activitats de recursos humans i contractació, necessitem processar dades per poder col·laborar amb vostè en possibles oportunitats laborals. MC té un interès legítim a processar dades personals durant el procés de contractació i amb finalitats de manteniment de registres. El processament de les dades dels sol·licitants d'ocupació ens permet gestionar el procés de contractació, avaluar i confirmar la idoneïtat per a l'ocupació. Sempre que la seva sol·licitud no tingui èxit, MC l'informarà i eliminarà les seves dades personals de tots els emmagatzematges de MC. No obstant això, si atorga un consentiment per separat, MC pot mantenir les seves dades personals a l'arxiu per a futures oportunitats d'ocupació. Pot retirar el seu consentiment en qualsevol moment.
    `,
  },

  {
    id: 10,
    title: 'DESTINATARIS DE DADES PERSONALS',
    text: `
 Als efectes de la contractació, podem compartir les seves dades personals, incloses les dades emmagatzemades en el nostre Sistema de seguiment de candidats, amb afiliats, clients actuals i potencials de MC. En aquest cas, se l' informarà per separat. A més, podem divulgar les seves dades personals a altres tercers esmentats en aquesta Política de privacitat.
    `,
  },

  {
    id: 11,
    title: 'PERÍODE DE RETENCIÓ',
    text: `
  MC conserva les dades personals dels sol·licitants d'ocupació fins que es tanqui la vacant (no més de dos (2) anys). En cas de rebuig de la candidatura, les seves dades personals podran conservar-se durant el pròxim (1) any sempre que doni el seu consentiment per separat i MC ho consideri com un candidat potencial per a altres vacants.
    `,
  },

  {
    id: 12,
    title: 'COOCKIES',
    text: `
 Com és pràctica general, el nostre lloc web utilitza cookies per recopilar dades i brindar als visitants una experiència de navegació més fàcil d'usar. Les cookies són peces seleccionades de dades que s'emmagatzemen en la seva computadora per ajudar que un lloc web el reconegui en la seva pròxima visita. Les cookies recopilen automàticament dades que no són d'identificació personal, com el navegador d'Internet preferit, el sistema operatiu, el nom de domini, les interaccions del lloc web, el temps de visita, la ubicació i les pàgines més vistes per visita. En acceptar la nostra Política de privacitat i romandre al lloc web de MC, accepta l'ús de cookies del nostre lloc web. Pot netejar o deshabilitar les cookies en la configuració del seu navegador d'Internet per eliminar aquestes dades del seu dispositiu i aturar la recopilació de dades. En general, els navegadors accepten automàticament les cookies. Si desitja rebutjar les cookies, bloquejar l'ús de cookies, eliminar cookies o rebre una notificació quan s'enviï una cookie, canviï la configuració adequada al seu navegador. Per obtenir informació més detallada sobre aquest assumpte, consulteu la nostra Política de Cookies.
    `,
  },
  {
    id: 13,
    title: 'ENLLAÇOS EXTERNS AL LLOC WEB',
    text: `
  El nostre lloc web pot contenir enllaços externs que el portin a llocs web de tercers. Aquests llocs web funcionen independentment de MC i no estan coberts per la nostra Política de privacitat. Les dades que comparteix en un lloc web de tercers que està vinculat des del lloc web de MC estan cobertes per la pròpia política de privacitat del lloc web d'aquest tercer i poden ser recopilades per a ús d'aquest tercer. MC no és responsable de les dades processadores per llocs web de tercers i no pot garantir la seva seguretat en interactuar amb ells.
    `,
  },
  {
    id: 14,
    title: 'REVISIONS A LA POLÍTICA DE PRIVACITAT',
    text: `
      MC es reserva el dret de realitzar canvis en aquesta Política de Privacitat en qualsevol moment. Se li recomana revisar aquesta Política de Privacitat periòdicament per a qualsevol canvi. Els canvis a aquesta Política de privacitat són efectius quan es publiquen en aquesta pàgina.
    `,
  },

  {
    id: 15,
    title: 'DETALLS DE CONTACTE',
    text: `
      Si té alguna pregunta o comentari respecte a aquesta Política de Privacitat, enviï un correu electrònic a info@modernacasa.es amb l'assumpte ""Sol·licitud de Política de Privacitat""
    `,
  },
];
