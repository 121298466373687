import {
  TopNavbarTitles as TopNavbarTitlesEn,
  navBarItemTitlesArray as navBarItemTitlesEnArray,
  mobileNavBarItemTitlesArray as mobileNavBarItemTitlesEnArray,
} from '../pages/MainPage/TopNavBar/en';
import { HeaderTitles as HeaderTitlesEn } from '../pages/MainPage/HeaderSection/en';
import { SliderTitles as SliderTitlesEn } from '../pages/MainPage/SliderSection/en';
import { ourStoryTexts as ourStoryTextsEn } from '../pages/MainPage/OurStoryBlock/en';
import {
  ourValuesTitles as ourValuesTitlesEn,
  ourValuesList as ourValuesListEn,
} from '../pages/MainPage/OurValuesBlock/en';
import {
  MenuItemsTitles as MenuItemsTitlesEn,
  MenuItemsDescriptions as MenuItemsDescriptionsEn,
} from '../pages/MainPage/MenuSection/en';

import { AboutUsTitles as AboutUsTitlesEn } from '../pages/MainPage/AboutSection/en';
import {
  Construction as ConstructionEn,
  // Projects as ProjectsEn,
  ModularHouses as ModularHousesEn,
  Architect as ArchitectEn,
  Interior as InteriorEn,
  SwimmingPools as SwimmingPoolsEn,
  SmartHome as SmartHomeEn,
  Investments as InvestmentsEn,
  SolarEnergy as SolarEnergyEn,
  Inmobiliaria as InmobiliariaEn,
  Consulting as ConsultingEn,
  LandscapeDesign as LandscapeDesignEn,
} from '../pages/MainPage/FAQSection/en';

import {
  footerNavBar as footerNavBarEn,
  footerTitles as footerTitlesEn,
} from '../pages/MainPage/FooterSection/en';
import { contactsHeaderTitles as contactsHeaderTitlesEn } from '../pages/ContactsPage/ContactsHeaderSection/en';
import { ContactsTitles as ContactsTitlesEn } from '../pages/MainPage/ContactsSection/en';
import {
  HeaderSection as HeaderSectionEn,
  description as SmartHomeDescriptionEn,
} from '../pages/SmartHomePage/HeaderSection/en';
import { PartnersSection as PartnersSectionEn } from '../pages/SmartHomePage/PartnersSection/en';
import { partnersSection as SolarPartnersSectionEn } from '../pages/SolarEnergyPage/PartnersSection/en';
import {
  servicesTexts as SolarServicesTextsEn,
  servicesList as SolartServicesListEn,
} from '../pages/SolarEnergyPage/ServicesSection/en';

import {
  architectNavItems as ArchitectNavItemsEn,
  architectSecondDescription as ArchitectSecondDescriptionEn,
} from '../pages/ArchitectPage/HeaderSection/en';
import {
  currentTexts as CurrentTextsEn,
  currentPortfolioTexts as CurrentPortfolioTextsEn,
  processList as ProcessListArchitectEn,
} from '../pages/ArchitectPage/ProcessSection/en';
import {
  projectsName as ProjectsNameEn,
  portfolioText as PortfolioTextEn,
} from '../pages/ArchitectPage/PortfolioSection/en';
import {
  architectStepBlockTitle as ArchitectStepBlockTitleEn,
  architectStepBlockDescription as ArchitectStepBlockDescriptionEn,
  architectStepSection as ArchitectStepSectionEn,
} from '../pages/ArchitectPage/StepsSection/en';
import {
  aboutListLocation as AboutListLocationEn,
  infoText as InfoTextEn,
  mainText as MainTextEn,
  headerBtnText as HeaderBtnTextEn,
  titleAbout as TitleAboutEn,
  textsAbout as TextsAboutEn,
  textPhoto as TextPhotoEn,
  view as ViewBtnTextEn,
  housesDetailsMobile as HousesDetailsMobileEn,
  housesDetailsDesktop as HousesDetailsDesktopEn,
  photoTitle as PhotoTitleEn,
} from '../pages/InteriorCasePage/en';
import {
  interiorNavItems as InteriorNavItemsEn,
  currentText as InteriorCurrentTextEn,
  currentPortfolioText as InteriorCurrentPortfolioTextEn,
  servicesList as InteriorServicesListEn,
  description as InteriorDescriptionEn,
} from '../pages/InteriorPage/en';
import {
  navItems as ConstructionNavItemsEn,
  description as ConstructionDescriptionEn,
  processList as ConstructionProcessListEn,
  aboutTexts as ConstructionAboutTextsEn,
  preConstructionTitle as PreConstructionTitleEn,
  preConstructionText as PreConstructionTextEn,
  constructionStepBlockTitle as ConstructionStepBlockTitleEn,
  constructionStepBlockDescription as ConstructionStepBlockDescriptionEn,
  constructionStepSection as ConstructionStepSectionEn,
} from '../pages/ConstructionPage/en';
import {
  navItems as LandscapeDesignNavItemsEn,
  description as LandscapeDesignDescriptionEn,
  currentTexts as LandscapeCurrentTextsEn,
  processList as LandscapeProcessListEn,
  currentTextsBenefits as CurrentTextsBenefitsEn,
  textsBenefits as TextsBenefitsEn,
} from '../pages/LandscapeDesignPage/en';
import {
  currentTexts as BuildingCurrentTextsEn,
  textsBenefits as BuildingTextsBenefitsEn,
  benefitsTitle as BuildingBenefitsTitleEn,
  materialsList as BuildingMaterialsListEn,
} from '../pages/BuildingMaterialsPage/en';
import { CardsSection as CardsSectionEn } from '../pages/SmartHomePage/CardsSection/en';
import { HeaderSection as SwimmingPoolsHeaderSectionEn } from '../pages/SwimmingPoolsPage/HeaderSection/en';
import { PoolsSection as SwimmingPoolsPoolsSectionEn } from '../pages/SwimmingPoolsPage/PoolsSection/en';
import { ColorsSection as SwimmingPoolsColorsSectionEn } from '../pages/SwimmingPoolsPage/ColorsSection/en';
import { PoolsAccessoriesSection as SwimmingPoolsAccessoriesSectionEn } from '../pages/SwimmingPoolsPage/AccessoriesSection/en';
import { TechnologiesSection as SwimmingPoolsTechnologiesSectionEn } from '../pages/SwimmingPoolsPage/TechnologiesSection/en';
import {
  AccessoriesSection as AccessoriesSectionEn,
  accessoriesIcons as AccessoriesIconsEn,
} from '../pages/SmartHomePage/AccessoriesSection/en';
import { SmartHomeStepSection as SmartHomeStepsSectionEn } from '../pages/SmartHomePage/StepsBlockSection/en';
import { MainSection as MainSectionEn } from '../pages/ServicesPage/en';
import {
  investmentsNavItems as InvestmentsNavItemsEn,
  investmentsSecondDescription as InvestmentsSecondDescriptionEn,
} from '../pages/InvestmentsPage/HeaderSection/en';
import {
  philosophyTextsList as PhilosophyTextsListEn,
  philosophyTitle as PhilosophyTitleEn,
} from '../pages/InvestmentsPage/InvestmentsPhilosophySection/en';

import {
  packagesTexts as packagesTextsEn,
  packagesBlocks as packagesBlocksEn,
} from '../pages/InvestmentsPage/InvestmentsPackagesSection/en';
import {
  teamTexts as TeamTextsEn,
  teamBlocks as TeamBlocksEn,
} from '../pages/InvestmentsPage/InvestmentsTeamSection/en';
import {
  realEstateNavItems as RealEstateNavItemsEn,
  realEstateSecondDescription as RealEstateSecondDescriptionEn,
} from '../pages/RealEstatePage/HeaderSection/en';
import {
  realEstateProcessTexts as RealEstateProcessTextsEn,
  processList as ProcessListEn,
  servicesTexts as RealEstateServicesTextsEn,
  servicesList as RealEstateServicesListEn,
} from '../pages/RealEstatePage/ServicesSection/en';

import { aboutListText as AboutListTextEn } from '../pages/RealEstatePage/AboutSection/en';
import {
  navItems as ConsultingNavItemsEn,
  consultingProcessTexts as ConsultingProcessTextsEn,
  processList as ConsultingProcessListEn,
  description as ConsultingDescriptionEn,
} from '../pages/ConsultingPage/en';
import {
  discoverList as DiscoverListEn,
  realEstateDiscoverTexts as RealEstateDiscoverTextsEn,
  aboutDiscoverList as AboutDiscoverListEn,
  aboutText as AboutTextEn,
} from '../pages/RealEstatePage/DiscoverSection/en';
import {
  aboutUsNavItems as AboutUsNavItemsEn,
  aboutUsSecondDescription as AboutUsSecondDescriptionEn,
  aboutUsTitle as AboutUsTitleEn,
} from '../pages/AboutUsPage/HeaderSection/en';
import {
  professionList as ProfessionListEn,
  pageTitle as PageTitleEn,
} from '../pages/AboutUsPage/OurTeamSection/en';
import {
  pageTitle as OurStoryPageTitleEn,
  pageAfterTitle as OurStoryPageAfterTitleEn,
} from '../pages/AboutUsPage/OurStoryMobileBlock/en';
import { projectsItems as ProjectsItemsEn } from '../pages/ProjectsPage/HeaderSection/en';
import { projectsList as ProjectsListEn } from '../pages/ProjectsPage/ProjectsSection/en';
import {
  headMenu as HeadMenuEn,
  typeHouseStyle as TypeHouseStyleEn,
  inputsTexts as InputsTextsEn,
  searchBtn as SearchBtnEn,
  removeAll as RemoveAllEn,
} from '../pages/ProjectsPage/FilterSection/en';
import {
  headerSection as SolarHeaderSectionEn,
  description as SolarEnergyDescriptionEn,
} from '../pages/SolarEnergyPage/HeaderSection/en';
import {
  solarEnergySetupsTexts as SolarEnergySetupsTextsEn,
  setupList as SetupListEn,
} from '../pages/SolarEnergyPage/SetupsSection/en';
import {
  solarEnergyBenefitsTexts as SolarEnergyBenefitsTextsEn,
  benefitsList as BenefitsListEn,
} from '../pages/SolarEnergyPage/BenefitsSection/en';
import {
  investmentsProcess as InvestmentsProcessEn,
  investmentsProcessTexts as investmentsProcessTextsEn,
} from '../pages/InvestmentsPage/InvestmentsProcess/en';
import { loginPage as LoginPageEn } from '../pages/LoginPage/en';
import { FavoritesSection as FavoritesSectionEn } from '../pages/FavoritesPage/en';
import {
  modularHousesNavItems as ModularHousesNavItemsEn,
  description as ModularHousesDescriptionEn,
} from '../pages/ModularHousesPage/HeaderSection/en';
import {
  typeOfHouses as TypeOfHousesEn,
  modelTitle as ModelTitleEn,
  modelDescription as ModelDescriptionEn,
} from '../pages/ModularHousesPage/ModelSection/en';
import {
  currentTexts as CurrentModularHousesTextsEn,
  options as OptionsEn,
} from '../pages/ModularHousesPage/OptionsSection/en';
import {
  blockTitle as BlockTitleEn,
  blockDescription as BlockDescriptionEn,
} from '../pages/ModularHousesPage/StepsBlock/en';
import {
  title as TopServicesTitleEn,
  allServices as AllServicesEn,
  description as ServicesDescriptionEn,
} from '../pages/MainPage/TopServicesSection/en';
import { ClientPartnerNavbarItems as ClientPartnerNavbarItemsEn } from '../pages/LeadFormPage/en';
import { MainNavbarItems as MainNavbarItemsEn } from '../pages/LeadFormPage/en';
import { LeadFormPage as LeadFormPageEn } from '../pages/LeadFormPage/en';
import { cookiesPopup as CookiesPopupEn } from '../components/CookiesPopup/en';
import { modularHousesStepSection as modularHousesStepSectionEn } from '../pages/ModularHousesPage/StepsBlock/en';
import { swimmingPoolsStepsSection as SwimmingPoolsStepsSectionEn } from '../pages/SwimmingPoolsPage/StepsBlockSection/en';
import { StubProjectsPageTexts as StubProjectsPageTextsEn } from '../pages/StubProjectsPage/en'
import {conditionsText as ConditionsTextEn} from '../pages/ConditionsPage/en'
import {privacyText as PrivacyTextEn} from '../pages/PrivacyPage/en'
export const en = {
  translation: {
    mainPage: {
      TopNavbarTitles: TopNavbarTitlesEn,
      navBarItemTitlesArray: navBarItemTitlesEnArray,
      mobileNavBarItemTitlesArray: mobileNavBarItemTitlesEnArray,
      headerTitles: HeaderTitlesEn,
      sliderTitles: SliderTitlesEn,
      MenuItemsTitles: MenuItemsTitlesEn,
      MenuItemsDescriptions: MenuItemsDescriptionsEn,
      AboutUsTitles: AboutUsTitlesEn,
      ourStoryTexts: ourStoryTextsEn,
      ourValuesTitles: ourValuesTitlesEn,
      ourValuesList: ourValuesListEn,
      ContactsTitles: ContactsTitlesEn,
      topServicesTitle: TopServicesTitleEn,
      allServices: AllServicesEn,
      servicesDescription: ServicesDescriptionEn,
      FAQ: {
        LandscapeDesign: LandscapeDesignEn,
        Construction: ConstructionEn,
        // Projects: ProjectsEn,
        ModularHouses: ModularHousesEn,
        Architect: ArchitectEn,
        Interior: InteriorEn,
        SwimmingPools: SwimmingPoolsEn,
        SmartHome: SmartHomeEn,
        Investments: InvestmentsEn,
        SolarEnergy: SolarEnergyEn,
        Inmobiliaria: InmobiliariaEn,
        Consulting: ConsultingEn,
      },
      footerNavBar: footerNavBarEn,
      footerTitles: footerTitlesEn,
    },
    contactsPage: {
      contactsHeaderTitles: contactsHeaderTitlesEn,
    },
    smartHome: {
      HeaderSection: HeaderSectionEn,
      SmartHomeDescription: SmartHomeDescriptionEn,
      PartnersSection: PartnersSectionEn,
      CardsSection: CardsSectionEn,
      AccessoriesSection: AccessoriesSectionEn,
      SmartHomeStepsSection: SmartHomeStepsSectionEn,
      AccessoriesIcons: AccessoriesIconsEn,
    },
    services: {
      MainSection: MainSectionEn,
    },
    investmentsPage: {
      investmentsNavItems: InvestmentsNavItemsEn,
      investmentsSecondDescription: InvestmentsSecondDescriptionEn,
      investmentsProcess: InvestmentsProcessEn,
      investmentsProcessTexts: investmentsProcessTextsEn,
      philosophyTextsList: PhilosophyTextsListEn,
      philosophyTitle: PhilosophyTitleEn,
      packagesTexts: packagesTextsEn,
      packagesBlocks: packagesBlocksEn,
      teamTexts: TeamTextsEn,
      teamBlocks: TeamBlocksEn,
    },
    realEstatePage: {
      realEstateNavItems: RealEstateNavItemsEn,
      realEstateSecondDescription: RealEstateSecondDescriptionEn,
      realEstateProcessTexts: RealEstateProcessTextsEn,
      processList: ProcessListEn,
      aboutListText: AboutListTextEn,
      discoverList: DiscoverListEn,
      realEstateDiscoverTexts: RealEstateDiscoverTextsEn,
      aboutDiscoverList: AboutDiscoverListEn,
      aboutText: AboutTextEn,
      servicesTexts: RealEstateServicesTextsEn,
      servicesList: RealEstateServicesListEn,
    },
    consultingPage: {
      consultingNavItems: ConsultingNavItemsEn,
      consultingProcessTexts: ConsultingProcessTextsEn,
      consultingProcessList: ConsultingProcessListEn,
      consultingDescription: ConsultingDescriptionEn,
    },
    aboutUsPage: {
      aboutUsNavItems: AboutUsNavItemsEn,
      aboutUsSecondDescription: AboutUsSecondDescriptionEn,
      aboutUsTitle: AboutUsTitleEn,
      professionList: ProfessionListEn,
      pageTitle: PageTitleEn,
      ourStoryPageTitle: OurStoryPageTitleEn,
      ourStoryPageAfterTitle: OurStoryPageAfterTitleEn,
    },
    projectsPage: {
      projectsItems: ProjectsItemsEn,
      headMenu: HeadMenuEn,
      typeHouseStyle: TypeHouseStyleEn,
      inputsTexts: InputsTextsEn,
      projectsList: ProjectsListEn,
      searchBtn: SearchBtnEn,
      removeAll: RemoveAllEn,
    },
    solarEnergyPage: {
      headerSection: SolarHeaderSectionEn,
      solarEnergyDescription: SolarEnergyDescriptionEn,
      solarEnergyBenefitsTexts: SolarEnergyBenefitsTextsEn,
      benefitsList: BenefitsListEn,
      partnersSection: SolarPartnersSectionEn,
      solarEnergySetupsTexts: SolarEnergySetupsTextsEn,
      setupList: SetupListEn,
      servicesTexts: SolarServicesTextsEn,
      servicesList: SolartServicesListEn,
    },
    architectPage: {
      architectNavItems: ArchitectNavItemsEn,
      architectSecondDescription: ArchitectSecondDescriptionEn,
      currentTexts: CurrentTextsEn,
      currentPortfolioTexts: CurrentPortfolioTextsEn,
      processList: ProcessListArchitectEn,
      projectsName: ProjectsNameEn,
      portfolioText: PortfolioTextEn,
      architectStepBlockTitle: ArchitectStepBlockTitleEn,
      architectStepBlockDescription: ArchitectStepBlockDescriptionEn,
      architectStepSection: ArchitectStepSectionEn,
    },
    interiorCasePage: {
      aboutListLocation: AboutListLocationEn,
      infoText: InfoTextEn,
      mainText: MainTextEn,
      headerBtnText: HeaderBtnTextEn,
      titleAbout: TitleAboutEn,
      textsAbout: TextsAboutEn,
      textPhoto: TextPhotoEn,
      viewBtnText: ViewBtnTextEn,
      housesDetailsMobile: HousesDetailsMobileEn,
      housesDetailsDesktop: HousesDetailsDesktopEn,
      photoTitle: PhotoTitleEn,
    },
    interiorPage: {
      interiorNavItems: InteriorNavItemsEn,
      interiorCurrentText: InteriorCurrentTextEn,
      interiorCurrentPortfolioText: InteriorCurrentPortfolioTextEn,
      interiorDescription: InteriorDescriptionEn,
      interiorServicesList: InteriorServicesListEn,
    },
    constructionPage: {
      constructionNavItems: ConstructionNavItemsEn,
      constructionDescription: ConstructionDescriptionEn,
      constructionProcessList: ConstructionProcessListEn,
      constructionAboutTexts: ConstructionAboutTextsEn,
      preConstructionTitle: PreConstructionTitleEn,
      preConstructionText: PreConstructionTextEn,

      constructionStepBlockTitle: ConstructionStepBlockTitleEn,
      constructionStepBlockDescription: ConstructionStepBlockDescriptionEn,
      constructionStepSection: ConstructionStepSectionEn,
    },
    landscapeDesignPage: {
      landscapeDesignNavItems: LandscapeDesignNavItemsEn,
      landscapeDesignDescription: LandscapeDesignDescriptionEn,
      landscapeCurrentTexts: LandscapeCurrentTextsEn,
      landscapeProcessList: LandscapeProcessListEn,
      currentTextsBenefits: CurrentTextsBenefitsEn,
      textsBenefits: TextsBenefitsEn,
    },

    buildingMaterialsPage: {
      buildingCurrentTexts: BuildingCurrentTextsEn,
      buildingTextsBenefits: BuildingTextsBenefitsEn,
      buildingBenefitsTitle: BuildingBenefitsTitleEn,
      buildingMaterialsList: BuildingMaterialsListEn,
    },
    swimmingPools: {
      HeaderSection: SwimmingPoolsHeaderSectionEn,
      PoolsSection: SwimmingPoolsPoolsSectionEn,
      ColorsSection: SwimmingPoolsColorsSectionEn,
      TechnologiesSection: SwimmingPoolsTechnologiesSectionEn,
      AccessoriesSection: SwimmingPoolsAccessoriesSectionEn,
      SwimmingPoolsStepsSection: SwimmingPoolsStepsSectionEn,
    },

    loginPage: {
      loginPage: LoginPageEn,
    },
    favorites: {
      FavoritesSection: FavoritesSectionEn,
    },
    modularHousesPage: {
      modularHousesNavItems: ModularHousesNavItemsEn,
      modelTitle: ModelTitleEn,
      modelDescription: ModelDescriptionEn,
      typeOfHouses: TypeOfHousesEn,
      currentTexts: CurrentModularHousesTextsEn,
      options: OptionsEn,
      blockTitle: BlockTitleEn,
      blockDescription: BlockDescriptionEn,
      description: ModularHousesDescriptionEn,
      modularHousesStepSection: modularHousesStepSectionEn,
    },
    leadForm: {
      clientPartnerNavbarItems: ClientPartnerNavbarItemsEn,
      mainNavbarItems: MainNavbarItemsEn,
      leadFormPage: LeadFormPageEn,
    },
    cookiesPopup: {
      cookiesPopup: CookiesPopupEn,
    },
    stubProjectsPage: {
      stubProjectsPageTexts: StubProjectsPageTextsEn,
    },
    conditionsPage: {
      conditionsText: ConditionsTextEn,
    },
     privacyPage: {
      privacyText: PrivacyTextEn,
    }
  },
};
