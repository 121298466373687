export const realEstateProcessTexts = {
  label: 'servicios',
  description:
    'Nuestro objetivo es ayudarle a encontrar la casa de sus sueños, tanto si desea comprar como alquilar. Ofrecemos una amplia gama de propiedades en diferentes ubicaciones y estilos, por lo que seguro que encontrará algo que se adapte a sus necesidades y presupuesto. Eche un vistazo a nuestras propiedades destacadas a continuación o utilice la barra de búsqueda para encontrar un tipo específico de propiedad o ubicación. Si necesita ayuda o tiene alguna pregunta, no dude en ponerse en contacto con nosotros.',
};

export const processList = {
labelFirst: 'Gestión de propiedades',
labelSecond: 'Valoración de la propiedad',
labelThird: 'Marketing y publicidad',
labelFourth: 'Compra y venta',
labelFifth: 'Alquiler y arrendamiento',
labelSixth: 'Asistencia legal',
};

export const servicesTexts = {
  label: 'Nuestros servicios inmobiliarios',
  description: `Ofrecemos una amplia gama de servicios de diseño de interiores para ayudarle a conseguir el espacio de sus sueños. Desde el concepto inicial y la planificación del espacio hasta la selección de colores, la búsqueda de muebles y los accesorios, nuestro equipo puede encargarse de todos los aspectos de su proyecto. Nuestros servicios incluyen:`,
};

export const servicesList = {
  textFirst: 'Defina sus necesidades',

  textSecond: 'Investigue la ubicación',

  textThird: 'Evalúe la idoneidad de la parcela',

  textFourth: 'Compruebe los aspectos legales y reglamentarios ',

  textFifth: 'Considere la infraestructura y los servicios públicos',

  textSixth: 'Evalúe el potencial futuro y el valor de reventa',
};
