export const ourValuesTitles = {
  title: 'Наші цінності',
  quality: 'Якість',
  
};

export const ourValuesList = [
  {
    id: 1,
    title: 'Ефективність',
    text: "Ми цінуємо час наших клієнтів, тому інвестуємо в дедлайни і не змушуємо їх чекати.",
  },
  {
    id: 2,
    title: 'Якість',
    text: "Ми завжди ставимо якість на перше місце і робимо все можливе, щоб наші послуги відповідали найвищим стандартам",
  },
  {
    id: 3,
    title: 'Завжди поруч',
    text: "Ми консультуємо до, під час та після надання послуги, відповідаємо на запитання клієнтів та надаємо рекомендації.",
  },
  {
    id: 4,
    title: 'Інновації та розвиток',
    text: 'Завжди прагнемо використовувати нові технології для забезпечення найкращих результатів для наших клієнтів',
  },
  {
    id: 5,
    title: 'Екологічна відповідальність',
    text: "Ми використовуємо екологічно чисті матеріали в будівництві, дбаємо про природу",
  },
  {
    id: 6,
    title: "Прозорість та відкритість",
    text: "Чесність - головна валюта, тому ми дотримуємося відкритості у всіх відносинах з нашими клієнтами.",
  },
  {
    id: 7,
    title: 'Надійні партнери',
    text: "Ми співпрацюємо з перевіреними та надійними компаніями, які постачають сировину та обладнання.",
  },
  {
    id: 8,
    title: 'Індивідуальний підхід',
    text: "Ми робимо все можливе, щоб виправдати очікування клієнта на 100%.",
  },
];