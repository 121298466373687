export const HeaderSection = {
  pools: 'Басейни',
  colors: 'Кольори',
  technology: 'Технології',
  accessories: 'Аксесуари',
  stages: 'Етапи',
  order: 'Order',
  description:
    "Бажаєте додати нотку розкоші у свій будинок? Розгляньте переваги встановленого басейну. Житлові басейни бувають різних стилів і розмірів, що робить їх чудовим варіантом для будь-якого будинку. Вони можуть забезпечити веселе і розслаблююче місце для збору сім'ї та друзів, а також підвищити цінність вашої нерухомості. Незалежно від того, чи шукаєте ви маленький, простий басейн або великий, складний, встановлений басейн може перетворити ваш задній двір на приголомшливий оазис.",
};
