export const swimmingPoolsStepsSection = {
  blockTitle: 'Етапи будівництва басейнів',
  descriptionTitle: 'Початкова консультація',
  stepOne: {
    title: 'Проектування та планування',
    p1: 'Почніть зі створення детального проекту вашого басейну, враховуючи такі фактори, як розмір, форма, розташування та особливості. Проконсультуйтеся з професіоналами, такими як архітектори та дизайнери басейнів, щоб переконатися, що проект відповідає вашим потребам і відповідає місцевим нормам.',
    p2: ``,
  },
  stepTwo: {
    title: 'Розкопки',
    p1: 'Земляні роботи передбачають викопування ділянки, на якій буде розташований басейн. Зазвичай використовується важка техніка для видалення ґрунту і створення ями відповідно до проектних специфікацій. Цей крок вимагає ретельної точності для досягнення бажаної форми та глибини',
    p2: ``,
  },
  stepThree: {
    title: 'Армування та водопровід',
    p1: 'Після завершення земляних робіт наступним кроком є встановлення міцного арматурного каркасу. Сталеві прути, або арматура, використовуються для забезпечення міцності конструкції басейну. На цьому етапі також встановлюються сантехнічні комунікації, в тому числі для водопостачання та водовідведення',
    p2: ``,
  },
  stepFour: {
    title: 'Заливка бетону або встановлення збірної оболонки басейну',
    p1: 'Залежно від вашого дизайну та уподобань, ви можете вибрати заливку бетону для створення конструкції басейну або встановлення збірної оболонки басейну. Бетонні басейни пропонують більше можливостей для налаштування, тоді як збірні оболонки забезпечують швидший монтаж.',
    p2: ``,
  },
  stepFive: {
    title: 'Оздоблення та система фільтрації',
    p1: 'Після того, як конструкція басейну встановлена, настав час додати останні штрихи. Це включає в себе нанесення гідроізоляційних покриттів, облицювання плиткою внутрішньої частини та встановлення будь-яких додаткових елементів, таких як сходинки, вишки для стрибків у воду або водоспади. Одночасно встановлюється система фільтрації, включаючи насоси, фільтри та нагрівачі, для забезпечення належної циркуляції води та обслуговування',
    p2: ``,
  },
  stepSix: {
    title: 'Настил та благоустрій території',
    p1: 'Навколо басейну можна створити терасу або внутрішній дворик для відпочинку та розваг. Обирайте міцні, неслизькі та придатні для використання на відкритому повітрі матеріали. Ландшафтні елементи, такі як рослини, дерева та освітлення, також можуть покращити естетику та функціональність зони басейну',
    p2: ``,
  },
  stepSeven: {
    title: 'Наповнення та початок обслуговування',
    p1: 'Після завершення будівництва басейн заповнюють водою і проводять хімічну обробку для встановлення відповідного водного балансу. Розпочніть необхідні процедури технічного обслуговування, такі як регулярне очищення, тестування води та обслуговування обладнання, щоб забезпечити безпеку, чистоту та привабливість басейну на довгі роки.',
    p2: ``,
  },
};