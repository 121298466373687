import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../theme/Theme.constants';

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid ${colorVariables.blockBorderColor};

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    min-width: 6.25rem;
    height: 100%;
    border: none;
  }
`;

export const DropdownButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
  color: ${colorVariables.mainTextColor};
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  border: none;
  cursor: pointer;
  background: inherit;
`;

export const DropdownArrow = styled.img<{ isOpen: boolean }>`
  position: absolute;
  bottom: ${props => (props.isOpen ? '-3px' : '0')};
  width: 100%;
  height: 1.8px;
  z-index: 4;
  background-size: cover;
  background-position: center;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    bottom: ${props => (props.isOpen ? '-3.5px' : '0')};
    height: 3.5px;
  }
`;

export const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  left: -1px !important;
  z-index: 15;
  min-width: 2.5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${colorVariables.mainTextColorWitOpacity};
`;

export const DropdownOption = styled.a<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: ${props => (props.isOpen ? 'pointer' : 'default')};
  min-width: 2.5rem;
  height: 2.5rem;
  transition: ${transitions.textHoverTransition};
  border-top: none;
  border-bottom: 0.5px solid ${colorVariables.blockBorderColor};
  border-left: 1px solid ${colorVariables.blockBorderColor};
  border-right: 1px solid ${colorVariables.blockBorderColor};

  &:hover {
    color: #ddd;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: 6.25rem;
    border-top: none;
    border-bottom: 1px solid ${colorVariables.blockBorderColor};
  }
`;
