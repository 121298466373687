import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  transitions,
} from '../../../theme/Theme.constants';
import heart from '../../../shared/assets/icons/about-us-icons/heart.svg';
import book from '../../../shared/assets/icons/about-us-icons/book.svg';
import rightArrow from '../../../shared/assets/icons/about-us-icons/right-arrow.svg';

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: fit-content
  width: 100%;
`;

export const ButtonsBlock = styled(Block)`
  display: flex;
  width: 100vw;
  height: 7rem;
  border-top: 1px solid ${colorVariables.blockBorderColor};
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
`;

export const IconBlock = styled(Block)`
  background-color: ${colorVariables.buttonGreyBackground};
  cursor: pointer;
  transition: ${transitions.backgroundHoverTransition};
  width: 100%;
  height: 100%;

  &:hover {
    background-color: inherit;
  }
`;

export const HeartBlock = styled(IconBlock)`
  border-right: 1px solid ${colorVariables.blockBorderColor};
`;

export const BookBlock = styled(IconBlock)`
  border-right: 1px solid ${colorVariables.blockBorderColor};
`;

export const ArrowBlock = styled(IconBlock)``;

export const Icon = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const HeartIcon = styled(Icon)`
  width: 3.75rem;
  height: 3.25rem;
  background-image: url(${heart});

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    width: 4.625rem;
    height: 4rem;
  }
`;

export const BookIcon = styled(Icon)`
  width: 3.675rem;
  height: 2.875rem;
  background-image: url(${book});

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    width: 4.875rem;
    height: 3.875rem;
  }
`;

export const ArrowIcon = styled(Icon)`
  width: 1.5rem;
  height: 2.875rem;
  background-image: url(${rightArrow});

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    width: 2rem;
    height: 3.875rem;
  }
`;

export const TitleBlock = styled(Block)`
  padding: 1.5rem;
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.75rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};

  .opacity-part {
    color: rgba(255, 255, 255, 0.2);
  }
`;

export const DescriptionBlock = styled(Block)`
  padding: 1rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
`;
