export const SmartHomeStepSection = {
  blockTitle: 'Stages of smart home',
  descriptionTitle: 'Move in',
  stepOne: {
    title: 'Step one',
    p1: `Planning a «smart home» system. Careful planning must be carried out before starting the installation of a smart home system. This includes identifying the right devices and technologies, their location, wiring, and other details.`,
    p2: `You also need to determine the budget that you are willing to spend on installing and configuring the system.`,
  },
  stepTwo: {
    title: 'Step two',
    p1: `Purchase of devices and materials. After you have planned the smart home system, you need to purchase all the necessary devices and materials.`,
    p2: `These can be motion sensors, door locks, thermostats, smart sockets, lighting devices, video surveillance systems, and others.`,
  },
  stepThree: {
    title: 'Step three',
    p1: ` Installation of sensors and devices. At this stage, all sensors and devices are installed. They must be installed in accordance with a previously determined plan and taking into account the need for wiring. Our team of private specialists will cope with the installation: electricians, repairmen.`,
    p2: `After installing devices and sensors, it is necessary to connect them to the control system. It could be a smart home controller that will link all the devices together.`,
  },
  stepFour: {
    title: 'Step four',
    p1: `Programming and setting. Once all devices are connected to the control system, they must be configured to work together. This may include creating automation scripts, installing voice control, and setting up applications to control the system.`,
    p2: `A comprehensive solution should recognize the owner of the home by ear, know about his main interests and preferences.`,
  },
  stepFive: {
    title: 'Step five',
    p1: `Testing. Once a smart home system is set up, it needs to be tested to ensure that the innovation is properly integrated. This includes checking the health of all devices and sensors, as well as checking automation and control scenarios. Already at this stage, the mechanism has learned to adapt to habits. The program will automatically turn off the lights in every corner of the house when everyone is already asleep, turn off unnecessary electrical outlets, switch the underfloor heating and climate control system to economy mode, close the doors and activate the security system, which will reliably protect the perimeter of your house.`,
    p2: `The system will prepare the rooms for the morning, pour aromatic coffee for you, wake you up with your favorite melody and take care of the safety of your children.`,
  },
  stepSix: {
    title: 'Step six',
    p1: `Enjoyment. The final stage, when the mechanism is ready for operation. You will need to monitor the operation of the system, maintain it and update it if necessary. This stage is the most long-awaited, since from this moment it is able to divide life into «before» and «after» Your house becomes the perfect guardian, who carefully watches every window and door, and also greets guests like the most perfect butler. It even lets you know when guests are arriving by calling your number back, allowing you to stay in touch with them even when you're away from home.`,
    p2: `With such a house, you can control it using simple commands on the phone. In addition, it is now impossible to forget about the darkness or the heat - one press of the button will turn on the light, close the curtains and turn on the music. The mechanism is designed to make living in the house more comfortable, optimize the time resource of the resident and automate the main processes.`,
  },
  
};
