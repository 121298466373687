import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';
import { Field, Form } from 'formik';
import { FormControlLabel, RadioGroup } from '@mui/material';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 0.5rem;
  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 34px;
    line-height: 41px;
  }
`;

export const AfterTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${colorVariables.mainTextColorWitOpacity};
  margin-bottom: 3rem;
`;

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    align-items: flex-end;
  }

  @media (min-width: ${Breakpoints.lg}px) {
    width: 30rem;
  }
`;

export const RadioGroupBlock = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  min-width: 100%;
`;

export const FieldBlock = styled(Field)`
  min-width: 100%;
`;

export const CommunicationBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0.375rem 1rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 72px;
`;

export const CommunicationInfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
`;

export const NumberBlock = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;

  @media (min-width: ${Breakpoints.sm}px) {
    font-size: 0.875rem;
    line-height: 1px;
  }
`;

export const Number = styled.span`
  color: ${colorVariables.alternativeTextColor};
  padding-right: 0.375rem;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
`;

export const Edit = styled.button`
  background: inherit;
  border: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
`;

export const CommunicationTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommunicationTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
`;

export const FormControlLabelBlock = styled(FormControlLabel)`
  & span {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: rgba(255, 230, 98, 0.8);
  }
`;
