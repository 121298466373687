export const solarEnergyBenefitsTexts = {
  label: 'Переваги сонячної енергії',
  description:
    `Сонячні панелі - це пристрої, які перетворюють сонячну енергію в електричну. Використання панелей позитивно впливає на економіку та навколишнє середовище. Вони можуть знизити витрати на електроенергію, зменшити вуглецевий слід і підвищити вартість нерухомості. Хоча деякі домовласники вагаються щодо вартості та обслуговування, переваги переважають недоліки.`,
};
export const benefitsList = {
  firstLabel: 'Відсутність викидів шкідливих газів.',
  secondLabel: 'Тривалий термін служби обладнання',
  thirdLabel: 'Підвищення енергетичної безпеки',
  fourthLabel: 'Невичерпне джерело енергії',
};
