export const navItems = {
  preConstruction: 'Preconstrucció',
  process: 'Procés',
};

export const description =
  `Gràcies a la nostra rica experiència i la realització d'alta qualitat dels projectes de construcció de capital, garantim a cadascun dels nostres clients un enfocament professional i la millor política de preus. Comprenem materials de construcció provats a preus competitius, proporcionem informes per a cada etapa, realitzem de forma competent tota la gamma de treballs en la construcció de cases de maó, formigó cel·lular, formigó armat, blocs, utilitzant estructures metàl·liques i altres elements portants de l'edifici. El palau de Buckingham, la torre de Rapunzel o el castell ambulant de Howl: podem fer realitat qualsevol dels seus desitjos arquitectònics i construir una casa clau en mà.`;

export const processList = {
  labelFirst: 'Atenció al medi ambient',
  descriptionFirst:
    'Ens esforcem per utilitzar materials orgànics que no malmetin la natura. Els substituts barats i els productes químics són tabú per a nosaltres. No només ens preocupem pel medi ambient, sinó també pels nostres clients, que viuran, crearan, treballaran, dormiran i rebran convidats a la casa.',

  labelSecond: 'Experiència i professionalitat',
  descriptionSecond:
    `El nostre equip està format per professionals altament qualificats i experimentats en diversos tipus i estils de construcció. Sabem quins materials i tecnologies són els més adequats per a cada projecte específic.`,

  labelThird: 'Obra complexa',
  descriptionThird:
    'Oferim serveis de construcció de cases ""clau en mà"", la qual cosa significa que ens encarreguem de totes les fases del treball, des del disseny fins a la posada en marxa. Això ens permet assegurar un alt nivell de qualitat de les obres i garantir el compliment dels terminis.',

  labelFourth: 'La qualitat dels materials',
  descriptionFourth:
    `Només utilitzem materials i components d'alta qualitat de proveïdors de confiança, la qual cosa garanteix la durabilitat i fiabilitat de casa seva . Només treballem amb proveïdors de confiança per garantir la durabilitat de cada metre quadrat de casa seva.`,

  labelFifth: 'Enfocament individual',
  descriptionFifth:
    `Volem entendre les necessitats de cadascun dels nostres clients i satisfer-les. Ja es tracti d'una casa de festa, un adossat per a una parella d'avis o un xalet per a una família jove amb nens, cada client té necessitats individuals. Tenim en compte els seus desitjos i requisits, i l'ajudem a triar les millors solucions per al seu projecte. Treballem amb cada client de forma individual per crear una llar que satisfaci totes les seves necessitats i preferències.`,

  labelSixth: 'Control de qualitat',
  descriptionSixth:
    'Controlem estrictament la qualitat de totes les fases del treball, des de la selecció dels materials fins a la instal·lació dels equips. Això ens permet oferir un alt nivell de qualitat del treball i garantir el compliment de totes les normes i requisits. Proporcionem informes sobre cada etapa i garantim la transparència.',
};

export const aboutTexts = {
  textFirst: 'Estableixem nous estàndards al mercat.',

  textSecond:
    'Per a nosaltres, cada casa no són només metres quadrats, sinó un espai acollidor, <br/> pensat fins al més mínim detall, en el qual vols viure, gaudir i cridar legítimament a aquest lloc ""llar"".',

  textThird:
    `Construïm cases que s'associen als millors moments de la vida: <br/> la primera paraula del teu fill, la proposició de matrimoni, la vetllada més càlida amb la teva família.`,

  textFourth: 'Construïm cases que es converteixen en la teva vida.',

  textFifth:
    'El principal valor per a nosaltres és el seu somriure, la principal recompensa és la confiança.',
};

export const preConstructionTitle = 'Etapes de la construcció';

export const preConstructionText =
  `Ens esforcem per crear no només una llar, sinó un veritable racó de felicitat i confort, on gaudir de cada minut. No ens limitem a crear cases estàndard, sinó que ajudem a fer realitat el seu estil de vida únic en el seu propi terreny. Per això, assumim qualsevol projecte i sabem que res és impossible. La seva fantasia arquitectònica es pot fer realitat avui mateix, només s'ha de posar en contacte amb nosaltres. Trobarem les millors solucions per a la seva futura llar, i juntament amb vostè desenvoluparem l'opció ideal que es convertirà en l'encarnació dels seus somnis i desitjos.`;

  export const constructionStepBlockTitle = 'Etapes de la construcció';
export const constructionStepBlockDescription = 'Consulta inicial';

export const constructionStepSection = {
  blockTitle: ' Etapes de la construcció',
  descriptionTitle: ' Consulta inicial',
  stepOne: {
    title: 'Pas un',
    p1: `La planificació. La primera fase de la construcció és el moment en què neix la idea de la futura casa. Aquí ens fem preguntes sobre com ha de ser la casa, com s'ha d'ubicar al solar i quins materials s'utilitzaran. Durant el procés de disseny, els nostres experts milloren cada detall de la casa, des dels fonaments i els murs de càrrega fins a la teulada i els conductes de ventilació.`,
    p2: `El període de garantia de defectes, en el qual el contractista està obligat a reparar qualsevol inconvenient, durarà al voltant de 12 mesos.`,
  },
  stepTwo: {
    title: 'Pas dos',
    p1: `Treballs preparatoris i fonamentació. Abans de començar a construir una casa, cal realitzar tot un seguit de treballs preparatoris. Això inclou la neteja del lloc de les runes, l' eliminació de la vegetació innecessària, l' anivellament del sòl, el desmantellament d' edificis antics. Però això és només el principi: després cal preparar els futurs fonaments de la casa. Una de les etapes més importants és el desenvolupament dels fonaments. Abans de començar a cavar un clot, cal portar totes les comunicacions i marcar la futura estructura.`,
    p2: `Només després podrà començar a construir els fonaments. L' elecció del tipus de fonamentació depèn de molts factors, com les característiques del sòl, la superfície i el nombre de plantes de la casa. Cada tipus de fonamentació té les seves pròpies característiques i requisits de qualitat dels materials. Per exemple, quan s' utilitza formigó, cal tenir en compte la seva contracció, que es produeix durant el procés d' assecat. Per tant, confiant en el nostre equip, pot estar segur al 100% que la seva casa tindrà uns fonaments sòlids.`,
  },
  stepThree: {
    title: 'Pas tres',
    p1: `Parets i terres. Un cop fixats els fonaments, arriba el moment de construir les parets. És important triar un material que proporcioni solidesa, resistència a les gelades i fiabilitat al futur disseny. Avui dia, un dels materials més comuns per a la construcció d'edificis baixos és el totxo, el bloc de formigó de gas i el bloc d'escuma. El maó és un material bell, respectuós amb el medi ambient i durador que s' utilitza per a la construcció de murs de càrrega i envans interiors. Gràcies a la possibilitat de combinar diferents tipus de maons, es pot aconseguir un edifici atractiu en estil espanyol o qualsevol altre que no requereixi decoració addicional. No obstant això, el totxo és pesat i el constructor el col·loca lentament.
Els blocs de formigó cel·lular són un material lleuger, durador i respectuós amb el medi ambient, amb altes propietats d'aïllament tèrmic. Gràcies a la seva forma i dimensions precises, la col·locació dels blocs de formigó cel·lular és ràpida. Els blocs de formigó cel·lular no es desmoronen ni tan sols en tallar-los amb una serra convencional.',
    p2: 'Tanmateix, el formigó cel·lular pot absorbir la humitat del mar i no és el material més agradable estèticament, cosa que es pot corregir amb un acabat addicional de la façana.
En el procés d'aixecament de murs, es duu a terme la instal·lació de sostres i envans interiors. Paràmetres importants del futur disseny són les seves prestacions d' aïllament tèrmic, acústic i de vapor, que milloren notablement el rendiment de l' edifici.`,
  },
  stepFour: {
    title: 'Pas quatre',
    p1: `Selecció i instal·lació de la teulada. La teulada no és un element menys important de l'edifici que els fonaments i les parets. No només protegeix l'edifici de les inclemències del temps, sinó que també contribueix al seu atractiu estètic. Un dels tipus de teulada més comunes són les teulades a quatre aigües, que es distingeixen per la seva rigidesa i el seu atractiu aspecte. Proporcionen una protecció addicional a les parets de l'edifici davant les influències externes, però alhora redueixen la superfície de l'àtic i requereixen un disseny i una instal·lació professionals, que la nostra empresa pot proporcionar.`,
    p2: `Les teulades piramidals, també estan molt sol·licitades. Es caracteritzen per una mínima susceptibilitat al vent, cosa que pot ser un avantatge addicional per als residents a Espanya, que coneixen bé el fred vent del nord Tramuntana. És una solució ideal per a edificis quadrats i també més barata que les teulades a quatre aigües. Les teulades planes són una altra opció que es caracteritza per una gran rapidesa d'instal·lació i un baix cost de col·locació.`,
  },
  stepFive: {
    title: 'Pas cinc',
    p1: `Acabat.Els treballs d' acabat, que no només donen a l' edifici un aspecte final, sinó que també exerceixen un paper important en la creació d' un espai confortable per als residents. Per mantenir un clima interior agradable, s'utilitzen revocs moderns. En els casos en què és necessari, s'instal·len façanes ventilades. A continuació comença la instal·lació de finestres, portes, escales, així com l'alineació dels pendents i la connexió de les comunicacions.`,
    p2: `Les parets interiors s'enlluernen i es cobreixen amb un revestiment d'acabat (pintura, paper pintat o guix decoratiu), i també s'instal·len els accessoris de fontaneria i les làmpades. Finalment, només queda col·locar els mobles, i la seva nova llar amb ànima espanyola estarà llesta per entrar a viure.`,
  },
};
