import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../theme/Theme.constants';

export const ProcessTopTextsWrapper = styled.div<{
  isTabletPadding: boolean;
  isColumnText?: boolean;
  withoutPadding?: boolean;
  componentMarginBottom?: string;
}>`
  position: relative;
  z-index: 15;
  margin-bottom: ${props => props.componentMarginBottom ? props.componentMarginBottom : '1.875rem'} ;
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    ${props => (props.isColumnText ? 'flex-direction: column;' : '')}
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${colorVariables.aboutUsOpacityPart};
   
    margin-bottom: ${props => props.componentMarginBottom ? props.componentMarginBottom : '2.5rem'} ;

    ${({ isTabletPadding }) => {
      return isTabletPadding
        ? `margin-left: 2.5rem; margin-right: 2.5rem;`
        : '';
    }}
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    margin-left: 0rem;
    margin-right: 0rem;

    ${props =>
      !props.withoutPadding
        ? `  padding-left: 6.25rem;
    padding-right: 6.25rem;`
        : ``}

    border-width: 0.0625rem 0rem;
    border-style: solid;
    border-color: ${colorVariables.aboutUsOpacityPart};
    
     margin-bottom: ${props => props.componentMarginBottom ? props.componentMarginBottom : '6.25rem'} ;
  }
`;

export const ProcessTitleWrapper = styled.div<{
  isTitleBorder: boolean;
  isBackgroundTextColor?: boolean;
  withoutDescription?: boolean;
  isColumnText?: boolean;
  titlePadding?: string;
  titleWrapperWidth?: string;
}>`
  display: flex;

  align-items: center;
  justify-content: center;

  @media (max-width: ${Breakpoints.md}px) {
    padding: ${props =>
      props.titlePadding
        ? `
    ${props.titlePadding};
    `
        : `
     1.5625rem 1.25rem;
    `};
    border-width: 0.0625rem 0rem;
    border-style: solid;
    border-color: ${colorVariables.aboutUsOpacityPart};
  }

  @media (min-width: ${Breakpoints.md}px) {
    ${props => (props.isBackgroundTextColor ? 'width: 50%;' : '  width: 75%;')}
    ${props => (props.isColumnText ? 'width: 100%;' : 'width: 50%;')};
    ${props =>
      props.titleWrapperWidth
        ? `width: ${props.titleWrapperWidth};`
        : 'width: 50%;'};

    ${props =>
      !props.withoutDescription &&
      `
      border-right: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
     `};

    padding: ${props => (props.titlePadding ? props.titlePadding : '1.875rem')};
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: ${props =>
      props.titlePadding ? props.titlePadding : '2.8125rem'};
    ${props =>
      !props.withoutDescription &&
      `
      border-width: 0rem 0.0625rem;
    border-style: solid;
    border-color: ${colorVariables.aboutUsOpacityPart};
     `};

    ${props => (props.withoutDescription ? 'width: 100%;' : 'width: 50%;')};
    ${props =>
      props.titleWrapperWidth
        ? `width: ${props.titleWrapperWidth};`
        : 'width: 50%;'};
  }

  ${props =>
    !props.isTitleBorder &&
    `@media (min-width: ${Breakpoints.xxl}px) {
    width: 50%;
    border-left: 1px solid ${colorVariables.aboutUsOpacityPart};
    border-right: none;
    padding: 1.875rem 0 1.875rem 1.875rem;
    
  }`}
`;

export const ProcessTitle = styled.p<{
  titleSize?: string;
  mobileTitleSize?: string;
  isBackgroundTextColor?: boolean;
  titleMaxWidth?: string;
  isTextAlignCenter?: boolean;
  withoutDescription?: boolean;
}>`
  font-weight: 700;
  ${props =>
    props.mobileTitleSize
      ? `font-size: ${props.mobileTitleSize};`
      : 'font-size: 1.625rem;'}

  line-height: 2rem;

 ${props => props.isTextAlignCenter && 'text-align: center;'}
 
  text-transform: uppercase;
  max-width: ${props =>
    props.titleMaxWidth?.length !== 0 ? `${props.titleMaxWidth};` : '20rem;'} 
  color: ${colorVariables.mainTextColor};

  text-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    text-align: ${props => (props.isTextAlignCenter ? 'center;' : 'left;')};
    max-width: ${props =>
      props.titleMaxWidth?.length !== 0 ? props.titleMaxWidth : '15.625rem;'};
    ${props =>
      props.titleSize
        ? `font-size: ${props.titleSize};`
        : 'font-size: 2.5rem;'}
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    line-height: 3.0625rem;
    max-width: ${props =>
      props.titleMaxWidth ? props.titleMaxWidth : '860px;'};

    ${props =>
      props.titleSize ? `font-size: ${props.titleSize};` : 'font-size:2.5rem;'}
  }
`;

export const ProcessAfterTitleWrapper = styled.div<{
  isTitleBorder?: boolean;
  isBackgroundTextColor?: boolean;
  isTextAlignCenter?: boolean;
  descriptionPadding?: string;
}>`
  ${props =>
    props.isTextAlignCenter &&
    `
  display: flex;
  justify-content: center;
  align-items: center;
 
`}
  @media (max-width: ${Breakpoints.md}px) {
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    border-bottom: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
  }

  @media (min-width: ${Breakpoints.md}px) {
    width: 100%;
    padding: ${props =>
      props.descriptionPadding ? props.descriptionPadding : '1.25rem'};
  }

  ${props =>
    !props.isBackgroundTextColor
      ? `
    @media (min-width: ${Breakpoints.xlg}px) {
    
     padding: ${
       props.descriptionPadding ? props.descriptionPadding : '2.8125rem 2.5rem'
     };
    border-right: 0.0625rem solid ${colorVariables.aboutUsOpacityPart};
  }

  @media (min-width: ${Breakpoints.xxl}px) {
    padding: ${
      props.descriptionPadding ? props.descriptionPadding : '2.8125rem 5.625rem'
    };
    ${
      props.isTitleBorder
        ? 'padding: 2.8125rem 5.625rem;'
        : 'padding: 2.8125rem 0.625rem;'
    }
  }
  
  `
      : `
  @media (min-width: ${Breakpoints.xlg}px){
    padding: ${
      props.descriptionPadding ? props.descriptionPadding : '1.875rem 2.187rem'
    };
  }
  `}

  ${props =>
    props.isBackgroundTextColor &&
    `
     border-width: 0px 1px 0px 0px ;
      border-style: solid;
      border-color:${colorVariables.processBlockHeaderBorderColor};
      @media (max-width: ${Breakpoints.xlg}px) {
        background: rgba(23, 23, 25, 0.6);


     
      backdrop-filter: blur(10px);
      }

       @media (min-width: ${Breakpoints.xlg}px){
          background: ${colorVariables.alternativeBackground};
       }
  `}
`;

export const ProcessAfterTitle = styled.p<{
  descriptionMaxWidth?: string;
  isBackgroundTextColor?: boolean;
  isTextAlignCenter?: boolean;
  descriptionColor?: string;
}>`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;

  ${props =>
    props.descriptionColor?.length !== 0
      ? `
   color: ${props.descriptionColor};
  
  `
      : `
  color: ${colorVariables.mainTextColor};
  opacity: 0.8;
  `}

  ${props =>
    props.isTextAlignCenter &&
    `
   @media (max-width: ${Breakpoints.md}px) {
    text-align: center;
  }
  `}

  @media (min-width: ${Breakpoints.md}px) {
    max-width: ${props =>
      props.descriptionMaxWidth?.length !== 0
        ? `${props.descriptionMaxWidth};`
        : '22.5rem;'};
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    font-size: 1rem;
    line-height: 1.875rem;
    ${props =>
      !props.isBackgroundTextColor
        ? `
    ${
      props.descriptionMaxWidth
        ? `max-width: ${props.descriptionMaxWidth};`
        : 'max-width: 40.625rem;'
    }
  
   
   `
        : 'max-width: 787px;'}
  }
`;
