import styled from 'styled-components';
import { Button } from '../../shared/components/BluredButton/BluredButton.styled';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';

export const DropDownFilterWrapper = styled.div<{ rightPosition?: string }>`
  position: absolute;
  top: 1.562rem;
  padding: 1rem 0px 1rem 1rem;
  background: ${colorVariables.dropDownBackground};
  border: 1px solid ${colorVariables.blockBorderColor};
  z-index: 15;
  ${props =>
    props.rightPosition?.length !== 0 ? `right: ${props.rightPosition}` : ''}
`;

export const List = styled.ul`
  width: 8.75rem;
`;

export const Item = styled.li`
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
export const Name = styled.span`
  

  transition: ${transitions.allHoverTransition};
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;
