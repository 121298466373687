export const professionList = {
  professionMarketing: 'Marketing Coordinator',

  professionWebDesigner: 'Web Designer',

  professionSales: 'President of Sales',

  professionCoordinator: 'Marketing Coordinator',
};

export const pageTitle = 'Our team';
