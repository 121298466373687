
export const investmentsNavItems = {
  philosophy: 'Philosophie',

  process: 'Processus',

  packages: ' Paquets',

  team: 'Équipe',
};
export const investmentsSecondDescription =
  "L'Espagne est un pays où la culture méditerranéenne s'allie à une histoire riche et à une architecture unique, ce qui attire les touristes et les investisseurs du monde entier. Ce n'est pas surprenant, car le marché immobilier espagnol est l'un des plus prometteurs et des plus rentables d'Europe. L'Espagne offre un large choix - des appartements en centre-ville aux villas de luxe sur la côte. En raison des prix modérés des logements, l'achat d'un bien immobilier est accessible à un large éventail d'investisseurs de différents pays. Les villes espagnoles sont réputées pour leurs infrastructures et leur qualité de vie, ce qui attire les personnes prêtes à acheter une propriété pour une résidence permanente ou une location. Chaque investisseur qui nous contacte peut bénéficier d'offres individuelles, d'un apport financier stable, de services juridiques et d'une évaluation des risques.";
