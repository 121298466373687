import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OurStoryMobileWrapper,
  TextsWrapper,
  TitleWrapper,
  Title,
  TextWrapper,
  Text,
} from './OurStoryMobileBlock.styled';

export const OurStoryMobileBlock: FC = () => {
  const { t } = useTranslation();
  return (
    <OurStoryMobileWrapper>
      <TextsWrapper>
        <TitleWrapper>
          <Title>{t('aboutUsPage.ourStoryPageTitle')}</Title>
        </TitleWrapper>
        <TextWrapper>
          <Text>{t('aboutUsPage.ourStoryPageAfterTitle')}</Text>
        </TextWrapper>
      </TextsWrapper>
    </OurStoryMobileWrapper>
  );
};
