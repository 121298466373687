import React, { FC } from 'react';
import { ProcessBlockHeader } from '../ProcessBlockHeader';
import {
  InvestmentsTeamWrapper,
  TeamList,
  TeamItem,
  TeamLabel,
  TeamListWrapper,
  Images,
  TeamTextsWrapper,
  TeamText,
  ImagesWrapper,
} from './InvestmentsTeamSection.styled';

import analysts from '../../shared/assets/images/investments/analysts.png';
import managers from '../../shared/assets/images/investments/managers.png';
import riskManagers from '../../shared/assets/images/investments/risk-managers.png';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';

export const InvestmentsTeamSection: FC = () => {
  const { t } = useTranslation();
  const teamTexts = {
    label: t('investmentsPage.teamTexts.label'),
    description: t('investmentsPage.teamTexts.description'),
  };

  const teamBlocks = [
    {
      id: 1,
      label: t('investmentsPage.teamBlocks.labelFirst'),
      description: t('investmentsPage.teamBlocks.descriptionFirst'),
      img: analysts,
    },
    {
      id: 2,
      label: t('investmentsPage.teamBlocks.labelSecond'),
      description: t('investmentsPage.teamBlocks.descriptionSecond'),
      img: managers,
    },
    {
      id: 3,
      label: t('investmentsPage.teamBlocks.labelThird'),
      description: t('investmentsPage.teamBlocks.descriptionThird'),
      img: riskManagers,
    },
  ];

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });
  

   const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  return (
    <InvestmentsTeamWrapper id="team-section">
      <ProcessBlockHeader
        currentTexts={teamTexts}
        titleSize={isDesktop ? '30px' : isTablet ? '20px': ''}
      />
      <TeamListWrapper>
        <TeamList>
          {teamBlocks.map(({ id, label, description, img }) => {
            return (
              <TeamItem key={id}>
                <ImagesWrapper>
                  <Images src={img} />
                </ImagesWrapper>

                <TeamTextsWrapper>
                  <TeamLabel>{label}</TeamLabel>
                  <TeamText>{description}</TeamText>
                </TeamTextsWrapper>
              </TeamItem>
            );
          })}
        </TeamList>
      </TeamListWrapper>
    </InvestmentsTeamWrapper>
  );
};
