export const MenuItemsTitles = {
  all: 'Усе',
  construction: 'Будівництво',
  projects: 'Проекти',
  interior: "Інтер'єр",
  modular_houses: 'Модульні будинки',
  swimming_pools: 'Басейни',
  solar_energy: 'Сонячна енергія',
  smart_home: 'Розумний будинок',
  inmobiliaria: 'Нерухомість',
  investments: 'Інвестиції',
  consulting: 'Консультації',
  architect: 'Архітектура',
  landscape_design: 'Ландшафтний дизайн',
  building_materials: 'Будівельні матеріали',
  pre_build_houses: 'Будинки з попереднім монтажем',
};

export const MenuItemsDescriptions = {
  construction:
    'Відчуйте втілення розкоші та майстерності, скориставшись нашими будівельними послугами з будівництва вілл. Наша команда досвідчених будівельників і підрядників втілить ваш задум у життя, гарантуючи бездоганну якість і увагу до деталей на кожному кроці. Від фундаменту до завершальних штрихів ми створюємо виняткові вілли, які відображають ваш унікальний стиль і перевершують усі очікування.',
  projects:
    'Проекти житлових будинків передбачають планування, проектування та будівництво будинків або багатоквартирних будинків. Вони вимагають глибокого розуміння будівельних норм, стандартів безпеки та потреб людей, які житимуть у цих будівлях. Успішні проекти також повинні враховувати такі фактори, як стійкість, енергоефективність та естетична привабливість. Загалом, проекти житлового будівництва відіграють важливу роль у створенні безпечного простору',
  interior:
    "Підніміть інтер'єр вашої вілли до нових вершин вишуканості за допомогою наших послуг з облаштування інтер'єру на замовлення. Наша команда талановитих дизайнерів розробляє вишукані оздоблювальні матеріали, меблі та декор, створюючи простір, що випромінює розкіш та комфорт. З ретельною увагою до кожної деталі ми перетворимо вашу віллу на чарівний притулок, який ідеально доповнить ваш спосіб життя та продемонструє ваш особистий стиль.",
  modular_houses:
    'Модульні будинки - це інноваційний та гнучкий варіант житлового будівництва. Ці конструкції будуються секціями або "модулями" за межами будівельного майданчика, а потім перевозяться на будівельний майданчик для складання. Це дозволяє пришвидшити та підвищити ефективність будівельного процесу з меншими витратами матеріалів. Крім того, модульні будинки можна адаптувати до різних стилів і потреб, що робить їх чудовим варіантом для тих, хто шукає унікальний, індивідуальний варіант.',
  swimming_pools:
    "Хочете додати нотку розкоші у свій будинок? Подумайте про переваги встановлення басейну. Житлові басейни бувають різних стилів і розмірів, що робить їх чудовим вибором для будь-якого будинку. Вони можуть забезпечити веселе і розслаблююче місце для збору сім'ї та друзів, одночасно підвищуючи вартість вашої нерухомості. Незалежно від того, чи шукаєте ви маленький, простий басейн або великий, складний, встановлений басейн може перетворити ваш будинок.",
  solar_energy:
    'Сонячна енергія - це екологічний та економічно вигідний варіант для забезпечення вашого будинку електроенергією. Встановивши сонячні панелі на даху, ви зможете генерувати власну електроенергію з сонячних променів. Це не тільки допомагає зменшити вуглецевий слід, але й заощадити гроші на щомісячних рахунках за комунальні послуги. При правильному налаштуванні ви навіть можете продавати надлишок енергії назад в мережу.',
  smart_home:
    "Технологія розумного дому забезпечує взаємопов'язане та автоматизоване життя. За допомогою системи розумного дому ви можете контролювати все - від освітлення і температури до безпеки і розваг - лише кількома дотиками на телефоні або голосовими командами. Це не тільки забезпечує зручність і комфорт, але й може підвищити енергоефективність і безпеку вашого будинку.",
  inmobiliaria:
    'Знайдіть ідеальну ділянку для вілли своєї мрії та спростіть процес будівництва, скориставшись нашою добіркою ділянок і готових проектів вілл. Наша команда експертів гарантує, що ви отримаєте доступ до найкращих локацій та попередньо розроблених планів, завдяки чому побудувати свій ідеальний будинок буде простіше, ніж будь-коли, зі зручністю та спокоєм у душі.',
  investments:
    "Максимізуйте свої інвестиції в нерухомість з нашою девелоперською компанією. Завдяки нашому досвіду у визначенні перспективних локацій та створенні виняткових вілл, ми пропонуємо вигідну можливість розширити свій портфель і досягти вражаючих прибутків на ринку елітної нерухомості. Інвестуйте з упевненістю і дозвольте нам допомогти вам розкрити весь потенціал ваших проектів у сфері нерухомості.",
  consulting:
    "Орієнтуйтеся на іспанському ринку нерухомості з легкістю та знанням справи завдяки нашим комплексним консалтинговим послугам. Наша команда досвідчених професіоналів надає цінну інформацію, аналіз ринку та індивідуальні рекомендації, які допоможуть вам прийняти обґрунтовані рішення та забезпечити вигідні можливості придбання нерухомості у динамічному секторі будівництва вілл в Іспанії.",
  architect:
    'Втілюйте свої мрії про будівництво вілли в реальність за допомогою досвіду наших досвідчених архітекторів. Від інноваційного дизайну, що гармоніює з навколишнім середовищем, до ретельної уваги до деталей, ми створюємо виняткові житлові простори, які перевершують очікування та залишають по собі довговічну архітектурну спадщину.',
  landscape_design:
    `Підкресліть красу та привабливість вашої вілли за допомогою наших вишуканих послуг з ландшафтного дизайну в Іспанії. Наша команда дизайнерів створює захоплюючі простори на відкритому повітрі з пишними садами, приголомшливими ландшафтами та чарівними водними об'єктами, щоб ваша вілла виділялася як справжній оазис розкоші та спокою.`,
  building_materials:
    'Ми пропонуємо широкий вибір високоякісних будівельних матеріалів, які допоможуть вам з легкістю реалізувати ваш будівельний проект. Від фундаментних матеріалів до оздоблювальних штрихів - у нас є все необхідне, щоб втілити ваше бачення в життя.',
};
