export const typeOfHouses = {
  airigh: 'Airigh',
  accessible: 'Accesible',
  bespokeDesign: 'Diseño a medida',
  whitehouse: 'Casa blanca',
  longhouse: 'casa comunal',
  steading: 'Estableciendo',
};

export const modelTitle = 'Casas Modulares Airigh';
export const modelDescription =
  'Elija su piscina de cerámica Compass entre 24 modelos, que se adapta, gracias a su diseño, tanto a la residencia moderna como a la casa clásica. Son aptas para el jardín, para la terraza junto a la casa o en interior como piscina cubierta.';
