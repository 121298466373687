import React, { FC, useState } from 'react';
import { FAQSectionWrapper } from './FAQSection.styled';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { FAQDesktop } from './DesktopBlock';
import { FAQMobile } from './MobileBlock';

interface FAQProps {
  menuItems: any;
}

export const FAQSection: FC<FAQProps> = ({ menuItems }) => {
  const [activeIdAfterClick, setActiveIdAfterClick] = useState(1);
  const [activeFAQId, setActiveFAQId] = useState(0);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: ${Breakpoints.lg - 1}px)`,
  });

  return (
    <FAQSectionWrapper>
      {isDesktop && (
        <FAQDesktop
          activeFAQId={activeFAQId}
          setActiveFAQId={setActiveFAQId}
          activeIdAfterClick={activeIdAfterClick}
          setActiveIdAfterClick={setActiveIdAfterClick}
          menuItems={menuItems}
        />
      )}
      {isTablet && (
        <FAQMobile
          activeFAQId={activeFAQId}
          setActiveFAQId={setActiveFAQId}
          activeIdAfterClick={activeIdAfterClick}
          setActiveIdAfterClick={setActiveIdAfterClick}
          menuItems={menuItems}
        />
      )}
    </FAQSectionWrapper>
  );
};
