import styled from 'styled-components';
import { colorVariables } from '../../theme/Theme.constants';

export const SwimmingPoolsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
 

  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
     background: rgba(0, 0, 0, 0.4);
  }
`;
