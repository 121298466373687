import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../../theme/Theme.constants';
import logo from '../../../shared/assets/icons/logo.svg';

export const TopBlock = styled.div<{ isActive: number | null }>`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  height: calc(100% / 3);
  width: 100%;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  border-left: 1px solid ${colorVariables.blockBorderColor};
  transition: 0.4s opacity ease;
  opacity: ${props => (props.isActive ? 0 : 1)};

  & .blured-button {
    position: absolute;
    bottom: 0;
    border: none;
    border-top: 1px solid ${colorVariables.blockBorderColor};
  }
`;

export const MiddleBlock = styled(TopBlock)`
  grid-template-columns: 3fr 1fr;
  
`;

export const BottomBlock = styled(TopBlock)`
  grid-template-columns: 2fr 1fr 1fr;
`;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  border-right: 1px solid ${colorVariables.blockBorderColor};
`;

export const LogoBlock = styled(Block)`
  flex-direction: column;
  gap: 1.125rem;
`;

export const LogoImg = styled.div`
  width: 3.375rem;
  height: 3rem;
  background-image: url(${logo});
  background-size: cover;
  background-position: center;
`;

export const LogoTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 2rem;
`;

export const SloganBlock = styled(Block)`
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 100%;

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 5rem;
  }
`;

export const DescriptionBlock = styled(Block)`
  padding: 0 2rem;
  font-size: 0.2rem;
  line-height: 1.875rem;

  @media only screen and (min-width: ${Breakpoints.sm}px) {
    font-size: 0.2rem;
  }

  @media only screen and (min-width: 520px) {
    font-size: 0.5rem;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 0.625rem;
  }

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 0.75rem;
  }

  @media only screen and (max-height: ${Breakpoints.md}px) {
    font-size: 0.625rem;
  }
`;
