import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupWrapper, Text, Accept } from './CookiesPopup.styled';

interface PopupProps {
  isPopupOpen: boolean;
  setIsPopupOpen(value: boolean): void;
}

export const CookiesPopup: FC<PopupProps> = ({
  isPopupOpen,
  setIsPopupOpen,
}) => {
  const { t } = useTranslation();
  return (
    <PopupWrapper isPopupOpen={isPopupOpen}>
      <Text>{t('cookiesPopup.cookiesPopup.text')}</Text>
      <Accept onClick={() => setIsPopupOpen(false)}>
        {t('cookiesPopup.cookiesPopup.accept')}
      </Accept>
    </PopupWrapper>
  );
};
