export const SmartHomeStepSection = {
  blockTitle: 'Étapes de la maison intelligente',
  descriptionTitle: 'Emménager',
  stepOne: {
    title: 'Première étape',
    p1: `Planification d'un système "maison intelligente". Une planification minutieuse doit être effectuée avant de commencer l'installation d'un système de maison intelligente. Il s'agit notamment d'identifier les appareils et les technologies appropriés, leur emplacement, le câblage et d'autres détails`,
    p2: `Vous devez également déterminer le budget que vous êtes prêt à consacrer à l'installation et à la configuration du système`,
  },
  stepTwo: {
    title: 'Deuxième étape',
    p1: `Achat d'appareils et de matériel. Après avoir planifié le système de maison intelligente, vous devez acheter tous les appareils et matériaux nécessaires`,
    p2: `Il peut s'agir de détecteurs de mouvement, de serrures de porte, de thermostats, de prises intelligentes, de dispositifs d'éclairage, de systèmes de vidéosurveillance, etc`,
  },
  stepThree: {
    title: 'Étape trois',
    p1: ` Installation des capteurs et des appareils. À ce stade, tous les capteurs et appareils sont installés. Ils doivent être installés conformément à un plan préalablement établi et en tenant compte de la nécessité d'un câblage. Notre équipe de spécialistes privés se chargera de l'installation : électriciens, réparateurs `,
    p2: `Après avoir installé les appareils et les capteurs, il est nécessaire de les connecter au système de contrôle. Il peut s'agir d'un contrôleur de maison intelligente qui reliera tous les appareils entre eux.`,
  },
  stepFour: {
    title: 'Étape quatre',
    p1: `Programmation et réglage. Une fois que tous les appareils sont connectés au système de contrôle, ils doivent être configurés pour fonctionner ensemble. Il peut s'agir de créer des scripts d'automatisation, d'installer la commande vocale et de configurer des applications pour contrôler le système`,
    p2: `Une solution complète doit reconnaître le propriétaire de la maison à l'oreille, connaître ses principaux intérêts et préférences.`,
  },
  stepFive: {
    title: 'Étape cinq',
    p1: `Essais. Une fois qu'un système de maison intelligente est mis en place, il doit être testé pour s'assurer que l'innovation est correctement intégrée. Il s'agit notamment de vérifier l'état de tous les appareils et capteurs, ainsi que les scénarios d'automatisation et de contrôle. À ce stade, le mécanisme a déjà appris à s'adapter aux habitudes. Le programme éteindra automatiquement les lumières dans tous les coins de la maison lorsque tout le monde est déjà endormi, éteindra les prises électriques inutiles, fera passer le chauffage par le sol et le système de contrôle climatique en mode économique, fermera les portes et activera le système de sécurité, qui protégera de manière fiable le périmètre de votre maison`,
    p2: `Le système prépare les pièces pour le matin, vous verse un café aromatique, vous réveille avec votre mélodie préférée et veille à la sécurité de vos enfants`,
  },
  stepSix: {
    title: 'Étape six',
    p1: `Plaisir. C'est l'étape finale, lorsque le mécanisme est prêt à fonctionner. Vous devrez surveiller le fonctionnement du système, l'entretenir et le mettre à jour si nécessaire. Votre maison devient le gardien parfait, qui surveille attentivement chaque fenêtre et chaque porte, et qui accueille les invités comme le plus parfait des majordomes. Elle vous informe même de l'arrivée de vos invités en rappelant votre numéro, ce qui vous permet de rester en contact avec eux même lorsque vous n'êtes pas chez vous`,
    p2: `Avec une telle maison, vous pouvez la contrôler à l'aide de simples commandes sur le téléphone. En outre, il est désormais impossible d'oublier l'obscurité ou la chaleur - une simple pression sur le bouton permet d'allumer la lumière, de fermer les rideaux et d'allumer la musique. Ce mécanisme est conçu pour rendre la vie dans la maison plus confortable, optimiser les ressources en temps de l'habitant et automatiser les principaux processus.`,
  },
};
