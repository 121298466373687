export const packagesTexts = {
  label: 'Investment packages',
  description:
    'Our investment process is based on a rigorous and disciplined approach to research and analysis. We believe that in-depth research and analysis are key to identifying high-quality investment opportunities and managing risk effectively.',
};

export const packagesBlocks = {
  firstName: 'Name',
  firstDownloadText: 'Download',

  secondName: 'Name',
  secondDownloadText: 'Download',

  thirdName: 'Name',
  thirdDownloadText: 'Download',
};
