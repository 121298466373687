
export const aboutListLocation = 'Catalunya, Espanya';

export const infoText = 'Espectacular vila assolellada a Espanya';

export const headerBtnText = `Més sobre l'interior`;
 export const mainText =
   'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.';

export const titleAbout = 'Sobre el projecte';
export const textsAbout = {
  text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
};


export const textPhoto =
  `Els accessoris per a la llar intel·ligent estan dissenyats per fer que la teva llar sigui més convenient, eficient i agradable. Des de termòstats intel·ligents i sistemes d'il·luminació fins a assistents de veu i càmeres de seguretat activades per veu, hi ha una varietat d'accessoris disponibles per ajudar-te a automatitzar i controlar diferents aspectes de la teva llar.`;

export const view = 'veure'
  

export const housesDetailsMobile = [
  {
    number: 1,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 2,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 3,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 4,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 5,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
];

export const housesDetailsDesktop = [
  {
    number: 1,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 2,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 3,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 4,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 5,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },

  {
    number: 6,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 7,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 8,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 9,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
  {
    number: 10,
    text: `Un cop la casa hagi obtingut un certificat de finalització per part de l'ajuntament i sigui aprovada pel seu agent, pot recosir-se al sofà que ha triat amb cura i admirar la seva nova casa. El període de garantia de defectes, en què el contractista està obligat a reparar qualsevol defecte, dura aproximadament 12 mesos.`,
  },
];

export const photoTitle = 'Foto'