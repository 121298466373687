export const currentTexts = {
  label: 'Nos matériaux',
  description: `Nos services d'aménagement paysager proposent une large gamme de services pour vous aider à transformer votre espace extérieur. Nos services comprennent:`,
};

export const materialsList = {
  titleFirst: 'Béton et maçonnerie',
  textFirst: `Nous proposons une gamme de matériaux de béton et de maçonnerie, notamment des briques, des blocs, des pavés et du ciment. Ces matériaux sont parfaits pour la construction de fondations, de murs de soutènement, de patios, etc.`,

  titleSecond: `Bois d'œuvre et charpente`,
  textSecond: `Nos matériaux de bois d'œuvre et de charpente comprennent le bois de construction, le contreplaqué, l'OSB et bien d'autres encore. Ces matériaux sont essentiels pour la construction de murs, de toits et de planchers.`,

  titleThird: 'Toiture et bardage',
  textThird: `Nous proposons une gamme de matériaux de couverture et de revêtement, notamment des bardeaux, des toits métalliques, des revêtements en vinyle, etc. Ces matériaux sont essentiels pour protéger votre structure des éléments.`,

  titleFourth: 'Fenêtres et portes',
  textFourth: `Notre sélection de fenêtres et de portes comprend une variété de styles et de matériaux, tels que le bois, le vinyle et la fibre de verre. Ces matériaux sont à la fois fonctionnels et esthétiques.`,

  titleFifth: 'Quincaillerie et outils',
  textFifth:
    "Nous proposons également une gamme de quincaillerie et d'outils, notamment des fixations, des adhésifs, des scies et des perceuses. Ces outils et accessoires sont essentiels pour mener à bien votre projet de construction de manière efficace.",
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Large choix de matériaux de construction de haute qualité',
  },
  { id: 2, text: 'Des prix compétitifs' },
  {
    id: 3,
    text: 'Personnel expert ayant une connaissance approfondie des produits',
  },
  { id: 4, text: 'Options de livraison pratiques' },
  {
    id: 5,
    text: 'Un service clientèle exceptionnel',
  },
];

export const benefitsTitle = 'Pourquoi nous choisir ?';
