import React, { FC, useState } from 'react';
import {
  ContactsWrapper,
  ContactsBlock,
  MainTitleBlock,
  IconsBlock,
  IconsWrapper,
  NetworkIcon,
  FormBlock,
  Contacts,
} from './ContactsMobile.styled';

import instagramMobile from '../../../shared/assets/icons/contacts/instagram.svg';
import pinterest from '../../../shared/assets/icons/contacts/pinterest.svg';
import whatsUp from '../../../shared/assets/icons/contacts/whats-up.svg';
import youtube from '../../../shared/assets/icons/contacts/youtube.svg';
import { Form, Formik } from 'formik';
import { TextFieldInput } from '../../../shared/components/TextFieldInput';
import { FormValuesProps } from '../ContactsSection.types';
import { BluredButton } from '../../../shared/components/BluredButton';
import { useTranslation } from 'react-i18next';
import { useSendMainPageFormMutation } from '../../../api';
import * as Yup from 'yup';
import {
  ErrorMesage,
  Popup,
  PopupText,
  PopupWrapper,
  TextFieldWrapper,
} from '../ContactsSection.styled';
import { ReactComponent as IncorrectIcon } from '../../../shared/assets/icons/lead-forms/incorrect-icon.svg';
import { ReactComponent as CorrectIcon } from '../../../shared/assets/icons/lead-forms/correct-icon.svg';

export const ContactsMobile: FC = () => {
  const { t } = useTranslation();

  const [sendFormMutation, { isError: formError }] =
    useSendMainPageFormMutation();
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const handleSubmit = async (
    formValues: FormValuesProps,
    { resetForm, setErrors }: any,
  ) => {
    try {
      await sendFormMutation({
        name: formValues.name,
        email: formValues.email,
        phone_number: formValues.phone_number,
        description: formValues.description,
      });

      setIsSubmitForm(true);

      resetForm({
        values: formInitialValues,
      });
    } catch (error: any) {
      const apiErrors = error?.response?.data?.errors || {};
      setErrors(apiErrors);
    }
  };

  const formInitialValues = {
    name: '',
    email: '',
    phone_number: '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(`${t('leadForm.leadFormPage.mainEmailError')}`)
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        `${t('leadForm.leadFormPage.secondaryEmailError')}`,
      ),
  });

  const handleGoBack = () => {
    setIsSubmitForm(false);
  };

  return (
    <ContactsWrapper>
      <MainTitleBlock> {t('mainPage.ContactsTitles.contacts')}</MainTitleBlock>
      <Contacts>
        <ContactsBlock> {t('mainPage.ContactsTitles.telNumber')}</ContactsBlock>
        <ContactsBlock> {t('mainPage.ContactsTitles.email')}</ContactsBlock>
      </Contacts>
      <IconsWrapper>
        <IconsBlock>
          <a href="https://wa.me/+34600592007" target="_blank" rel="noreferrer">
            <NetworkIcon src={whatsUp} alt="instagram-icon" />
          </a>
          <NetworkIcon src={instagramMobile} alt="instagram-icon" />
          <NetworkIcon src={pinterest} alt="instagram-icon" />
          <NetworkIcon src={youtube} alt="pinterest-icon" />
        </IconsBlock>
      </IconsWrapper>
      <MainTitleBlock>{t('mainPage.ContactsTitles.form')}</MainTitleBlock>
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {({
          isSubmitting,
          resetForm,
          errors,
          values,
          handleChange,
          touched,
        }) => (
          <Form className="form">
            <FormBlock>
              <TextFieldInput
                type="text"
                name="name"
                placeholder={t('mainPage.ContactsTitles.name_placeholder')}
                value={values.name}
                onChange={handleChange}
              />
              <TextFieldWrapper>
                <TextFieldInput
                  isError={errors.email}
                  onChange={handleChange}
                  value={values.email}
                  type="text"
                  name="email"
                  placeholder={t('mainPage.ContactsTitles.email_placeholder')}
                />
                <ErrorMesage>{errors.email}</ErrorMesage>
              </TextFieldWrapper>
              <TextFieldInput
                type="text"
                name="phone_number"
                placeholder={t('mainPage.ContactsTitles.phone_placeholder')}
                value={values.phone_number}
                onChange={handleChange}
              />
              <TextFieldInput
                type="text"
                name="description"
                placeholder={t(
                  'mainPage.ContactsTitles.description_placeholder',
                )}
                value={values.description}
                onChange={handleChange}
                addClassname="bottom-field"
              />
              <BluredButton
                title={t('mainPage.ContactsTitles.send')}
                handleClick={() => {
                  handleSubmit(values, {
                    resetForm,
                    setErrors: null,
                  });
                  resetForm();
                }}
              />
            </FormBlock>
          </Form>
        )}
      </Formik>
      {isSubmitForm && (
        <PopupWrapper>
          <Popup isError={formError}>
            {formError ? <IncorrectIcon /> : <CorrectIcon />}
            <PopupText>
              {formError
                ? t('leadForm.leadFormPage.errorMessage')
                : t('leadForm.leadFormPage.accessMessage')}
            </PopupText>
            <BluredButton
              title={t('leadForm.leadFormPage.returnButton')}
              handleClick={() => handleGoBack()}
            />
          </Popup>
        </PopupWrapper>
      )}
    </ContactsWrapper>
  );
};
