export const ourValuesTitles = {
  title: 'Nos valeurs',
  quality: 'Qualité',
};

export const ourValuesList = [
  {
    id: 1,
    title: "L'efficacité",
    text: `Nous accordons de l'importance au temps de nos clients, c'est pourquoi nous investissons dans les délais et ne les faisons pas attendre.`,
  },
  {
    id: 2,
    title: 'La qualité',
    text: `Nous plaçons toujours la qualité au premier plan et faisons de notre mieux pour que nos services répondent aux normes les plus élevées. `,
  },
  {
    id: 3,
    title: 'Toujours à proximité',
    text: `Nous menons des consultations avant, pendant et après la prestation du service, répondons aux questions des clients et formulons des recommandations.`,
  },
  {
    id: 4,
    title: 'Innovation et développement',
    text: `Nous nous efforçons toujours de développer de nouvelles technologies afin de garantir les meilleurs résultats à nos clients.`,
  },

  {
    id: 5,
    title: 'Responsabilité environnementale',
    text: `Nous utilisons des matériaux de construction respectueux de l'environnement et nous prenons soin de la nature.`,
  },

  {
    id: 6,
    title: 'Transparence et ouverture',
    text: `L'honnêteté est la principale devise, c'est pourquoi nous adhérons à la transparence dans toutes nos relations avec nos clients.`,
  },
  {
    id: 7,
    title: 'Partenaires fiables',
    text: `Nous coopérons avec des entreprises éprouvées et fiables qui fournissent des matières premières et des équipements.`,
  },
  {
    id: 8,
    title: 'Approche individuelle',
    text: `Nous faisons de notre mieux pour répondre à 100 % aux attentes de nos clients`,
  },
];
