import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';
import logo from '../../../shared/assets/icons/logo.svg';
import {
  HeaderMenuWrapper,
  MenuItem,
} from '../../HeaderMenuComponent/HeaderMenuComponent.styled';

export const HeaderHoverWrapper = styled.div<{ isActive: number | null }>`
  position: absolute;
  top: 2.7rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: ${paddings.desktopPagePadding};
  transition: 0.4s opacity ease;
  opacity: ${props => (!props.isActive ? 0 : 1)};

  ${HeaderMenuWrapper}
`;

export const TopBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% / 3);
  width: 100%;
`;

export const MiddleBlock = styled(TopBlock)`
  display: grid;
  grid-template-columns: 3fr 1fr;

 
  ${HeaderMenuWrapper} {
    border: 1px solid ${colorVariables.hoverHeaderMenuBorder} !important;
  }

  ${MenuItem} {
    border: 1px solid ${colorVariables.hoverHeaderMenuBorder};
  }
`;

export const BottomBlock = styled(TopBlock)``;

export const Block = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const LogoBlock = styled(Block)`
  flex-direction: column;
  gap: 1.125rem;
`;

export const LogoImg = styled.div`
  width: 3.375rem;
  height: 3rem;
  background-image: url(${logo});
  background-size: cover;
  background-position: center;
`;

export const LogoTitle = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.625rem;
  line-height: 2rem;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SloganBlock = styled.div`
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 100%;

  @media only screen and (min-width: ${Breakpoints.xxl}px) {
    font-size: 5.625rem;
    .translated & {
      font-size: 4.625rem;
    }
  }

  @media only screen and (max-height: 720px) {
    font-size: 2.55rem;
  }
`;

export const DescriptionBlock = styled.div`
  font-size: 0.2rem;
  line-height: 157.6%;
  padding: 1.25rem 10.25rem 0 0;

  @media only screen and (min-width: ${Breakpoints.sm}px) {
    font-size: 0.2rem;
  }

  @media only screen and (min-width: 520px) {
    font-size: 0.5rem;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    font-size: 0.675rem;
  }

  @media only screen and (min-width: 1720px) {
    font-size: 1rem;
  }

  @media only screen and (max-height: 720px) {
    font-size: 0.675rem;
  }
`;
