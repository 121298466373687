import styled from 'styled-components';

import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../theme/Theme.constants';

export const BuldingWrapper = styled.div``;

export const List = styled.ul`
  margin-bottom: 1.875rem;

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem 1.25rem;
    margin-bottom: 2.5rem;
  }
`;

export const Item = styled.li`
  position: relative;

  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    flex-basis: calc(50% - 0.625rem);
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    flex-basis: calc(100% / 3 - 0.835rem);
  }
`;

export const Img = styled.img`
  display: block;
  width: 100%;
`;

export const TypeWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.625rem;
  background: ${colorVariables.opacityBackgroundColor};
  backdrop-filter: blur(2px);
  border-radius: 1px;
  padding: 0.312rem 1.25rem;
`;

export const Type = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  text-align: center;
  color: ${colorVariables.popupText};
`;

export const TextsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid ${colorVariables.aboutUsOpacityPart};
`;

export const Name = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25rem;

  color: ${colorVariables.mainTextColor};
`;
