import React, { FC, useState } from 'react';
import {
  ContactsWrapper,
  BottomBlock,
  MiddleBlock,
  TopBlock,
  ContactsBlock,
  MainTitleBlock,
  BlockTitle,
  IconsBlock,
  IconsWrapper,
  NetworkIcon,
  FormControl,
  FormWrapper,
  FormBlock,
  InputsBlock,
  ButtonTitle,
  ErrorMesage,
  TextFieldWrapper,
  PopupWrapper,
  Popup,
  PopupText,
  NetworkIconWrapper,
} from './ContactsSection.styled';
import instagram from '../../shared/assets/icons/contacts/instagram.svg';
import pinterest from '../../shared/assets/icons/contacts/pinterest.svg';
import whatsUp from '../../shared/assets/icons/contacts/whats-up.svg';
import { Form, Formik } from 'formik';
import { TextFieldInput } from '../../shared/components/TextFieldInput';
import { FormValuesProps } from './ContactsSection.types';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { ContactsMobile } from './MobileBlock';
import { useTranslation } from 'react-i18next';
import youtube from '../../shared/assets/icons/contacts/youtube.svg';
import { useSendMainPageFormMutation } from '../../api';
import { ReactComponent as IncorrectIcon } from '../../shared/assets/icons/lead-forms/incorrect-icon.svg';
import { ReactComponent as CorrectIcon } from '../../shared/assets/icons/lead-forms/correct-icon.svg';
import * as Yup from 'yup';
import { BluredButton } from '../../shared/components/BluredButton';

export const ContactsSection: FC = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  const { t } = useTranslation();

  const [sendFormMutation, { isError: formError }] =
    useSendMainPageFormMutation();
  const [isSubmitForm, setIsSubmitForm] = useState(false);

  const handleSubmit = async (
    formValues: FormValuesProps,
    { resetForm, setErrors }: any,
  ) => {
    try {
      await sendFormMutation({
        name: formValues.name,
        email: formValues.email,
        phone_number: formValues.phone_number,
        description: formValues.description,
      });

      setIsSubmitForm(true);

      resetForm({
        values: formInitialValues,
      });
    } catch (error: any) {
      const apiErrors = error?.response?.data?.errors || {};
      setErrors(apiErrors);
    }
  };

  const formInitialValues = {
    name: '',
    email: '',
    phone_number: '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(`${t('leadForm.leadFormPage.mainEmailError')}`)
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        `${t('leadForm.leadFormPage.secondaryEmailError')}`,
      )
      .matches(
        /^[a-zA-Z0-9.@]+$/,
        `${t('leadForm.leadFormPage.thirdEmailError')}`,
      ),
  });

  const handleGoBack = () => {
    setIsSubmitForm(false);
  };

  return (
    <>
      {isDesktop ? (
        <ContactsWrapper>
          <TopBlock>
            <MainTitleBlock>
              {t('mainPage.ContactsTitles.contacts')}
            </MainTitleBlock>
            <ContactsBlock>
              {t('mainPage.ContactsTitles.telNumber')}
            </ContactsBlock>
            <ContactsBlock>{t('mainPage.ContactsTitles.email')}</ContactsBlock>
          </TopBlock>
          <MiddleBlock>
            <BlockTitle> {t('mainPage.ContactsTitles.socialMedia')}</BlockTitle>
            <BlockTitle>{t('mainPage.ContactsTitles.form')}</BlockTitle>
          </MiddleBlock>
          <BottomBlock>
            <IconsWrapper>
              <IconsBlock>
                <NetworkIconWrapper
                  href="https://www.instagram.com/modernacasa.es/"
                  target="_blank"
                  rel="noreferrer">
                  <NetworkIcon src={instagram} alt="instagram-icon" />
                </NetworkIconWrapper>

                <NetworkIconWrapper
                  href="https://wa.me/+34600592007"
                  target="_blank"
                  rel="noreferrer">
                  <NetworkIcon src={whatsUp} alt="wgatsUp-icon" />
                </NetworkIconWrapper>

                <NetworkIconWrapper
                  href="https://pin.it/2iuHA5D"
                  target="_blank"
                  rel="noreferrer">
                  <NetworkIcon src={pinterest} alt="pinterest-icon" />
                </NetworkIconWrapper>
                <NetworkIconWrapper
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCI7kAr6wujbQCErOuHvpcYw">
                  <NetworkIcon src={youtube} alt="pinterest-icon" />
                </NetworkIconWrapper>
              </IconsBlock>
            </IconsWrapper>
            <FormWrapper>
              <Formik
                initialValues={formInitialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                {({
                  isSubmitting,
                  resetForm,
                  errors,
                  values,
                  handleChange,
                  touched,
                }) => {
                  return (
                    <Form className="form">
                      <FormBlock>
                        <InputsBlock>
                          <TextFieldInput
                            onChange={handleChange}
                            type="text"
                            name="name"
                            value={values.name}
                            placeholder={t(
                              'mainPage.ContactsTitles.name_placeholder',
                            )}
                          />
                          <TextFieldWrapper>
                            <TextFieldInput
                              isError={errors.email}
                              onChange={handleChange}
                              value={values.email}
                              type="text"
                              name="email"
                              placeholder={t(
                                'mainPage.ContactsTitles.email_placeholder',
                              )}
                            />

                            <ErrorMesage>{errors.email}</ErrorMesage>
                          </TextFieldWrapper>
                          <TextFieldInput
                            onChange={handleChange}
                            value={values.phone_number}
                            type="text"
                            name="phone_number"
                            placeholder={t(
                              'mainPage.ContactsTitles.phone_placeholder',
                            )}
                          />
                          <TextFieldInput
                            onChange={handleChange}
                            value={values.description}
                            type="text"
                            name="description"
                            placeholder={t(
                              'mainPage.ContactsTitles.description_placeholder',
                            )}
                            addClassname="bottom-field"
                          />
                        </InputsBlock>
                        <FormControl>
                          <ButtonTitle
                            type="button"
                            onClick={() => {
                              handleSubmit(values, {
                                resetForm,
                                setErrors: null,
                              });
                              resetForm();
                            }}>
                            {t('mainPage.ContactsTitles.send')}
                          </ButtonTitle>
                        </FormControl>
                      </FormBlock>
                    </Form>
                  );
                }}
              </Formik>
            </FormWrapper>
          </BottomBlock>
          {isSubmitForm && (
            <PopupWrapper>
              <Popup isError={formError}>
                {formError ? <IncorrectIcon /> : <CorrectIcon />}
                <PopupText>
                  {formError
                    ? t('leadForm.leadFormPage.errorMessage')
                    : t('leadForm.leadFormPage.accessMessage')}
                </PopupText>
                <BluredButton
                  title={t('leadForm.leadFormPage.returnButton')}
                  handleClick={() => handleGoBack()}
                />
              </Popup>
            </PopupWrapper>
          )}
        </ContactsWrapper>
      ) : (
        <ContactsMobile />
      )}
    </>
  );
};
