export const footerNavBar = {
  aboutUs: 'Про нас',

  services: 'Послуги',

  favourite: 'Обране',

  contacts: 'Контакти',
};

export const footerTitles = {
  conditions: 'Умови використання',
  privacy: 'Політика конфіденційності',
  updated: 'Будьте з нами в курсі подій',
  reserved: '© MODERNACASA (2023) Всі права захищені',
  placeholder: 'Електронна пошта',
};
