export const TechnologiesSection = {
  title: 'Technologie',
  description: `Compass Pools : la rencontre de la technologie et de la perfection. Innovation en matière de céramique de carbone, système autonettoyant Vantage, installation facile. Des piscines écologiques prêtes pour l'avenir. Choisissez-nous pour une installation sans faille et une fonctionnalité de pointe.`,
  gelcoatAndColor: 'Gelcoat et couleur',
  closedBeam: 'Poutre fermée',
  vinylesterBase: 'Base vinylester',
  balsaReinforcement: 'Renforcement en balsa',
  vinylester: 'Vinylester renforcé de fibres de verre',
  glassFiberMats: 'Tapis en fibre de verre',
  ceramicLayer: 'Couche de céramique',
  polyesterLayer: `Couche de polyester`,
  carbonFibreMats: 'Tapis en fibre de carbone',
  exteriorTopCoat: 'Couche de finition extérieure',
  gelcoatAndColorDescription: `La couche de gelcoat, développée à l'origine pour les surfaces des yachts de luxe, sert de barrière de protection supérieure pour les piscines Compass. Elle présente une résistance exceptionnelle aux rayons UV et aux dommages mécaniques. Notre système de coloration, qui fait partie de l'un des systèmes de gelcoat les plus avancés au monde, garantit que les piscines Compass Pools conservent leur aspect de haute qualité, même après de nombreuses années d'utilisation.`,

  closedBeamDescription: `La poutre fermée, qui s'étend sur tout le bord de la piscine, garantit une résistance et une stabilité exceptionnelles, tout en conservant la flexibilité nécessaire. Cet aspect est crucial lors du transport et de l'installation, car il permet à la piscine de conserver parfaitement sa forme. Grâce à la conception de la poutre fermée, nos piscines offrent une durabilité et une intégrité structurelle inégalées.`,
  vinylesterBaseDescription: `Nos piscines sont équipées d'une base en résine vinylester de haute qualité, qui sert de barrière initiale résistante à l'eau. Cette couche est appliquée sur le gelcoat, ce qui empêche efficacement l'osmose.`,

  balsaReinforcementDescription: `La couche extérieure de notre piscine est renforcée par du bois de balsa, connu pour ses propriétés légères. Grâce à son emplacement stratégique et à sa grande épaisseur, il offre une résistance suffisante, ce qui élimine la nécessité de recourir à des renforts supplémentaires.`,

  vinylesterDescription: `Nos piscines sont dotées d'une deuxième barrière étanche renforcée par des fibres de verre, ce qui leur confère une flexibilité et une résistance optimales. Cette combinaison garantit la durabilité et la résistance de nos piscines, ce qui permet d'en profiter longtemps.`,

  glassFiberMatsDescription: `Nos piscines sont dotées d'une seconde barrière étanche robuste, renforcée par de la fibre de verre, qui leur confère une souplesse et une résistance inégalées. L'incorporation de la fibre de verre garantit la durabilité et l'intégrité structurelle maximales de nos piscines.`,

  ceramicLayerDescription: `Compass Pools dispose d'une couche céramique de pointe, développée en Australie et aux États-Unis. Conçue à l'origine pour les projets de la NASA, elle garantit une protection à 100 % contre l'osmose, ce qui assure la durabilité et la fiabilité de nos piscines.`,

  polyesterLayerDescription: `La couche de polyester, située au-dessus de la couche de céramique, constitue l'élément central de notre piscine composite. Une fois cette couche complètement durcie, la piscine acquiert d'excellentes propriétés mécaniques, ce qui garantit sa durabilité et sa solidité à long terme.`,

  carbonFibreMatsDescription: `Nous utilisons des matelas en fibre de carbone, un matériau léger et robuste que l'on trouve couramment dans les équipements sportifs et l'industrie aéronautique, pour renforcer les parties les plus critiques de nos piscines. Cette intégration garantit que les piscines Compass sont exceptionnellement solides et conservent une forme constante, ce qui les distingue sur le marché.`,

  exteriorTopCoatDescription: `Spécialement conçu pour résister aux éléments, ce revêtement avancé constitue une couche protectrice qui permet à votre piscine de conserver son éclat pendant des années. Grâce à sa résistance supérieure aux rayons UV, aux produits chimiques et aux intempéries, notre couche de finition extérieure garantit que votre piscine conserve sa couleur éclatante et sa finition impeccable, ce qui en fait le choix idéal pour les installations de piscines extérieures.`,
};

