export const TechnologiesSection = {
  title: 'Tecnologia',
  description: `Compass Pools: on la tecnologia s'uneix a la perfecció. Innovació en ceràmica de carboni, sistema d'autoneteja Vantage, fàcil instal·lació. Piscines ecològiques preparades per al futur. Trieu-nos per a una instal·lació perfecta i una funcionalitat d'avantguarda.`,
  gelcoatAndColor: 'Gelcoat i color',
  closedBeam: 'Viga tancada',
  vinylesterBase: 'Base de viniléster',
  bassaReinforcement: 'Reforç de bassa',
  vinylester: 'Vinilèster reforçat amb fibra de vidre',
  glassFiberMats: 'Capa de fibra de vidre',
  ceramicLayer: 'Capa ceràmica',
  polyesterLayer: 'Capa de polièster',
  carbonFibreMats: 'Capa de fibra de carboni',
  exteriorTopCoat: 'Capa exterior',
  gelcoatAndColorDescription:
    `La capa de gelcoat, desenvolupada originalment per a superfícies de iots de luxe, serveix de barrera protectora superior per a les piscines Compass. Presenta una resistència excepcional a la radiació UV i als danys mecànics. El nostre sistema de coloració, que forma part d'un dels sistemes de gelcoat més avançats del món, garanteix que les piscines Compass Pools mantinguin el seu aspecte d'alta qualitat fins i tot després de molts anys d'ús.`,
  closedBeamDescription: `La biga tancada, que s'estén al llarg de tota la vora de la piscina, garanteix una resistència i una estabilitat excepcionals, mantenint alhora la flexibilitat necessària. Això és crucial durant el transport i la instal·lació, ja que garanteix que la piscina conservi la seva forma a la perfecció. Amb un disseny de biga tancada, les nostres piscines ofereixen una durabilitat i una integritat estructural inigualables.`,
  vinylesterBaseDescription: `Les nostres piscines estan equipades amb una base de resina vinilèster d'alta qualitat, que serveix com a barrera inicial resistent a l'aigua. Aquesta capa s'aplica sobre el gelcoat, impedint eficaçment l'aparició d'osmosi.',
  bassaReinforcementDescription: 'La capa exterior de la nostra piscina està reforçada amb fusta de bassa, coneguda per la seva lleugeresa. Amb la seva col·locació estratègica i el seu ampli gruix, proporciona prou resistència, eliminant la necessitat de reforços addicionals.',
  vinylesterDescription:
    'Les nostres piscines compten amb una segona barrera impermeable reforçada amb fibra de vidre, que proporciona una flexibilitat i resistència òptimes. Aquesta combinació garanteix que les nostres piscines siguin duradores i resistents, cosa que permet un gaudi durador.',
  glassFiberMatsDescription: 'Les nostres piscines compten amb una robusta segona barrera impermeable, reforçada amb fibra de vidre, per proporcionar una flexibilitat i resistència sense igual. La incorporació de fibra de vidre garanteix la màxima durabilitat i integritat estructural de les nostres piscines.`,
  ceramicLayerDescription: `Compass Pools compta amb una capa ceràmica d'última generació, desenvolupada a Austràlia i Estats Units. Dissenyada originalment per a projectes de la NASA, garanteix un 100% de protecció contra l'osmosi, assegurant la durabilitat i fiabilitat de les nostres piscines.`,
  polyesterLayerDescription: `La capa de polièster, situada damunt la capa de ceràmica, forma el component central de la nostra piscina composta. Un cop aquesta capa s'ha endurit per complet, la piscina adquireix excel·lents propietats mecàniques, cosa que garanteix la seva durabilitat i resistència a llarg termini.`,
  carbonFibreMatsDescription: `Utilitzem esteres de fibra de carboni, un material lleuger i robust que es troba habitualment en equips esportius i en la indústria aeronàutica, per reforçar les parts més crítiques de les nostres piscines. Aquesta integració garanteix que les piscines Compass siguin excepcionalment resistents i mantinguin una forma uniforme, cosa que les distingeix en el mercat.`,
  exteriorTopCoatDescription: `Especialment dissenyat per resistir les inclemències del temps, aquest avançat revestiment proporciona una capa protectora que manté l'aspecte enlluernador de la seva piscina durant anys. Amb la seva resistència superior a la radiació UV, als productes químics i a la intempèrie, la nostra capa superior exterior garanteix que la seva piscina conservi el seu color vibrant i el seu acabat impecable, cosa que la converteix en l'opció perfecta per a instal·lacions de piscines a l'aire lliure.`,
};

