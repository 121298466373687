import React, { FC } from 'react';
import {
  BenefitsWrapper,
  List,
  Item,
  Text,
  Title,
  Wrapper,
} from './BenefitsSection.styled';

import { getTextsBenefits } from './BenefitsSection.constants';
import { useTranslation } from 'react-i18next';

export const BenefitsSection: FC = () => {
  const textsBenefits = getTextsBenefits();
  const { t } = useTranslation();
  return (
    <BenefitsWrapper>
      <Wrapper>
        <Title>{t('buildingMaterialsPage.buildingBenefitsTitle')}</Title>
        <List>
          {textsBenefits.map(({ id, text }) => {
            return (
              <Item key={id}>
                <Text>{text}</Text>
              </Item>
            );
          })}
        </List>
      </Wrapper>
    </BenefitsWrapper>
  );
};
