export const TechnologiesSection = {
  title: 'Технології',
  description: `Басейни Compass: де технологія зустрічається з досконалістю. Інновації з вуглецевої кераміки, система самоочищення Vantage, простий монтаж. Готові до майбутнього, екологічно чисті басейни. Вибирайте нас за безшовну установку і найсучаснішу функціональність.`,
  gelcoatAndColor: 'Гелькоут і колір',
  closedBeam: 'Закрита балка',
  vinylesterBase: 'Основа з вінілестеру',
  balsaReinforcement: 'Армування бальзою',
  vinylester: 'Армований скловолокном вініпласт',
  glassFiberMats: 'Скловолокнисті мати',
  ceramicLayer: 'Керамічний шар',
  polyesterLayer: 'Поліефірний шар',
  carbonFibreMats: 'Мати з вуглецевого волокна',
  exteriorTopCoat: 'Зовнішнє покриття',
  gelcoatAndColorDescription:
    "Шар гелькоута, спочатку розроблений для поверхонь розкішних яхт, служить верхнім захисним бар'єром для басейнів Compass Pools. Він демонструє виняткову стійкість до ультрафіолетового випромінювання і механічних пошкоджень. Наша система фарбування, що є частиною однієї з найдосконаліших у світі систем гелькоута, гарантує, що басейни Compass збережуть свій високоякісний зовнішній вигляд навіть після багатьох років використання.",

  closedBeamDescription: `Закрита балка, що проходить по всьому краю басейну, гарантує виняткову міцність і стабільність, зберігаючи при цьому необхідну гнучкість. Це має вирішальне значення під час транспортування і установки, гарантуючи, що басейн бездоганно зберігає свою форму. Завдяки конструкції із закритими балками наші басейни забезпечують неперевершену довговічність і структурну цілісність.`,
  vinylesterBaseDescription: `Наші басейни обладнані високоякісною основою з вінілестерової смоли, яка слугує початковим водонепроникним бар'єром. Цей шар наноситься поверх гелькоута, ефективно запобігаючи виникненню осмосу`,

  balsaReinforcementDescription: `Зовнішній шар нашого басейну має армування з бальзової деревини, відомої своїми легкими властивостями. Завдяки стратегічному розташуванню і достатній товщині вона забезпечує достатню міцність, усуваючи необхідність в додатковому армуванні.`,

  vinylesterDescription: `Наші басейни мають другий водонепроникний бар'єр, армований скловолокном, що забезпечує оптимальну гнучкість і міцність. Ця комбінація гарантує довговічність і еластичність наших басейнів, що дозволяє насолоджуватися ними протягом тривалого часу.`,

  glassFiberMatsDescription: `Наші басейни мають міцний другий водонепроникний бар'єр, армований скловолокном, що забезпечує неперевершену гнучкість і міцність. Скловолокно забезпечує максимальну довговічність і структурну цілісність наших басейнів.`,

  ceramicLayerDescription: `Басейни Compass Pools мають найсучасніший керамічний шар, розроблений в Австралії та США. Спочатку розроблений для проектів NASA, він гарантує 100% захист осмосу, забезпечуючи довговічність і надійність наших басейнів.`,

  polyesterLayerDescription: `Поліефірний шар, розташований поверх керамічного шару, є основним компонентом нашого композитного басейну. Після повного затвердіння цей шар набуває чудових механічних властивостей, забезпечуючи довговічність і міцність басейну на довгий час.`,

  carbonFibreMatsDescription: `Ми використовуємо килимки з вуглецевого волокна, легкого і міцного матеріалу, який зазвичай використовується в спортивному спорядженні та авіабудуванні, для зміцнення найбільш важливих частин наших басейнів. Ця інтеграція гарантує, що басейни Compass надзвичайно міцні і зберігають стабільну форму, що виділяє їх на ринку.`,

  exteriorTopCoatDescription: `Спеціально розроблене, щоб протистояти стихії, це вдосконалене покриття забезпечує захисний шар, який збереже ваш басейн приголомшливим на довгі роки. Завдяки чудовій стійкості до ультрафіолетового випромінювання, хімічних речовин і атмосферних впливів, наше зовнішнє покриття гарантує, що ваш басейн збереже свій яскравий колір і бездоганну поверхню, що робить його ідеальним вибором для установки на відкритому повітрі.`,
};

