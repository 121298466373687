export const teamTexts = {
  label: "Notre équipe d'investissement",
  description:
    "Chez Modernacasa, nous pensons que notre personnel est notre plus grand atout. Notre équipe d'investissement est composée de professionnels expérimentés qui ont fait leurs preuves dans le secteur",
};

export const teamBlocks = {
  labelFirst: "Analystes d'investissement",
  descriptionFirst:
    "Nos analystes en investissement mènent des recherches et des analyses approfondies sur les opportunités d'investissement potentielles",

  labelSecond: 'Gestionnaires de portefeuille',

  descriptionSecond: `Nos gestionnaires de portefeuille sont responsables de la construction et de la gestion de nos portefeuilles d'investissement.`,

  labelThird: 'Gestionnaires de risques',

  descriptionThird:
    "Nos gestionnaires de risques travaillent en étroite collaboration avec notre équipe d'investissement afin d'identifier et de gérer efficacement les risques.",
};
