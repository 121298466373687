import React, { FC } from 'react';
import { HeaderTitlesEn } from '../HeaderSection.constatnts';
import {
  SloganBlock,
  DescriptionBlock,
  MobileBlocks,
  ButtonsBlock,
} from './HeaderMobile.styled';
import { BluredButton } from '../../../shared/components/BluredButton';
import { useNavigate } from 'react-router';
import { mainSection } from '../../../shared/helpers/globalConstants';
import { RoutePaths } from '../../../shared/helpers/routes';
import { setLeadFormTitle } from '../../../redux/actions/globalActions';
import { useDispatch } from 'react-redux';
import { getClientPartnerNavigationItems } from '../../../scenes/LeadForm/LeadForm.constants';
import { useTranslation } from 'react-i18next';

export interface HeaderMobileProps {
  activeId: number | null;
  setActiveId: (activeId: number | null) => void;
}

export const HeaderMobile: FC<HeaderMobileProps> = ({
  setActiveId,
  activeId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const clientPartnerNavigationItems = getClientPartnerNavigationItems();

  const handleNavBarSectionClick = (routePath: string, formTitle: string) => {
    mainSection?.scrollTo(0, 0);
    navigate(routePath);
    dispatch(setLeadFormTitle(formTitle));
  };
  return (
    <MobileBlocks>
      <SloganBlock
        dangerouslySetInnerHTML={{
          __html: (t('mainPage.headerTitles.SLOGAN_TEXT') || '') as string,
        }}></SloganBlock>
      <DescriptionBlock>
        {' '}
        {t('mainPage.headerTitles.HEADER_DESCRIPTION')}
      </DescriptionBlock>
      <ButtonsBlock>
        {/* <BluredButton
          title={HeaderTitlesEn.HOW_WE_WORK_BUTTON}     /// for future features
          handleClick={() => {}}
          className="blured-button"
        /> */}
        <BluredButton
          title={t('mainPage.headerTitles.CLIENT_BUTTON')}
          handleClick={() =>
            handleNavBarSectionClick(
              RoutePaths.leadForm,
              clientPartnerNavigationItems[0].title,
            )
          }
          className="blured-button"
        />
        <BluredButton
          title={t('mainPage.headerTitles.PARTNER_BUTTON')}
          handleClick={() =>
            handleNavBarSectionClick(
              RoutePaths.leadForm,
              clientPartnerNavigationItems[1].title,
            )
          }
          className="blured-button"
        />
      </ButtonsBlock>
    </MobileBlocks>
  );
};
