import React from 'react';

import { ReactComponent as Conditioners } from '../../shared/assets/icons/smart-home/accessories/conditioners.svg';
import { ReactComponent as Purifiers } from '../../shared/assets/icons/smart-home/accessories/purifiers.svg';
import { ReactComponent as Bridges } from '../../shared/assets/icons/smart-home/accessories/bridges.svg';
import { ReactComponent as Cameras } from '../../shared/assets/icons/smart-home/accessories/cameras.svg';
import { ReactComponent as Doorbells } from '../../shared/assets/icons/smart-home/accessories/doorbells.svg';
import { ReactComponent as Fans } from '../../shared/assets/icons/smart-home/accessories/fans.svg';
import { ReactComponent as Faucets } from '../../shared/assets/icons/smart-home/accessories/faucets.svg';
import { ReactComponent as GarageDoors } from '../../shared/assets/icons/smart-home/accessories/garage-doors.svg';
import { ReactComponent as Humidifiers } from '../../shared/assets/icons/smart-home/accessories/humidifiers.svg';
import { ReactComponent as Lights } from '../../shared/assets/icons/smart-home/accessories/lights.svg';
import { ReactComponent as Locks } from '../../shared/assets/icons/smart-home/accessories/locks.svg';
import { ReactComponent as Outlets } from '../../shared/assets/icons/smart-home/accessories/outlets.svg';
import { ReactComponent as Receivers } from '../../shared/assets/icons/smart-home/accessories/receivers.svg';
import { ReactComponent as Routers } from '../../shared/assets/icons/smart-home/accessories/routers.svg';
import { ReactComponent as Security } from '../../shared/assets/icons/smart-home/accessories/security.svg';
import { ReactComponent as Sensors } from '../../shared/assets/icons/smart-home/accessories/sensors.svg';
import { ReactComponent as Speakers } from '../../shared/assets/icons/smart-home/accessories/speakers.svg';
import { ReactComponent as Sprinklers } from '../../shared/assets/icons/smart-home/accessories/sprinklers.svg';
import { ReactComponent as Switches } from '../../shared/assets/icons/smart-home/accessories/switches.svg';
import { ReactComponent as Thermostats } from '../../shared/assets/icons/smart-home/accessories/thermostats.svg';
import { ReactComponent as Tvs } from '../../shared/assets/icons/smart-home/accessories/tvs.svg';
import { ReactComponent as Windows } from '../../shared/assets/icons/smart-home/accessories/windows.svg';
import i18next from 'i18next';


export const getAccessoriesIcons = () => {
  const accessoriesTitle = i18next.t('smartHome.AccessoriesIcons', {
    returnObjects: true,
  }) as any
  const accessoriesIcons = [
  {
    title: accessoriesTitle.conditioners,
    icon: <Conditioners />,
  },
  {
    title: accessoriesTitle.purifiers,
    icon: <Purifiers />,
  },
  {
    title: accessoriesTitle.bridges,
    icon: <Bridges />,
  },
  {
    title: accessoriesTitle.cameras,
    icon: <Cameras />,
  },
  {
    title: accessoriesTitle.doorbells,
    icon: <Doorbells />,
  },
  {
    title: accessoriesTitle.fans,
    icon: <Fans />,
  },
  {
    title: accessoriesTitle.faucets,
    icon: <Faucets />,
  },
  {
    title: accessoriesTitle.garageDoors,
    icon: <GarageDoors />,
  },
  {
    title: accessoriesTitle.humidifiers,
    icon: <Humidifiers />,
  },
  {
    title: accessoriesTitle.lights,
    icon: <Lights />,
  },
  {
    title: accessoriesTitle.locks,
    icon: <Locks />,
  },
  {
    title: accessoriesTitle.outlets,
    icon: <Outlets />,
  },
  {
    title: accessoriesTitle.receivers,
    icon: <Receivers />,
  },
  {
    title: accessoriesTitle.routers,
    icon: <Routers />,
  },
  {
    title: accessoriesTitle.security,
    icon: <Security />,
  },
  {
    title: accessoriesTitle.sensors,
    icon: <Sensors />,
  },
  {
    title: accessoriesTitle.speakers,
    icon: <Speakers />,
  },
  {
    title: accessoriesTitle.sprinklers,
    icon: <Sprinklers />,
  },
  {
    title: accessoriesTitle.switches,
    icon: <Switches />,
  },
  {
    title: accessoriesTitle.thermostats,
    icon: <Thermostats />,
  },
  {
    title: accessoriesTitle.tvs,
    icon: <Tvs />,
  },
  {
    title: accessoriesTitle.windows,
    icon: <Windows />,
  },
  ];
  
  return accessoriesIcons
}
export const accessoriesIcons = [
  {
    title: 'conditioners',
    icon: <Conditioners />,
  },
  {
    title: 'purifiers',
    icon: <Purifiers />,
  },
  {
    title: 'bridges',
    icon: <Bridges />,
  },
  {
    title: 'cameras',
    icon: <Cameras />,
  },
  {
    title: 'doorbells',
    icon: <Doorbells />,
  },
  {
    title: 'fans',
    icon: <Fans />,
  },
  {
    title: 'faucets',
    icon: <Faucets />,
  },
  {
    title: 'garage doors',
    icon: <GarageDoors />,
  },
  {
    title: 'humidifiers',
    icon: <Humidifiers />,
  },
  {
    title: 'lights',
    icon: <Lights />,
  },
  {
    title: 'locks',
    icon: <Locks />,
  },
  {
    title: 'outlets',
    icon: <Outlets />,
  },
  {
    title: 'receivers',
    icon: <Receivers />,
  },
  {
    title: 'routers',
    icon: <Routers />,
  },
  {
    title: 'security',
    icon: <Security />,
  },
  {
    title: 'sensors',
    icon: <Sensors />,
  },
  {
    title: 'speakers',
    icon: <Speakers />,
  },
  {
    title: 'sprinklers',
    icon: <Sprinklers />,
  },
  {
    title: 'switches',
    icon: <Switches />,
  },
  {
    title: 'thermostats',
    icon: <Thermostats />,
  },
  {
    title: 'tvs',
    icon: <Tvs />,
  },
  {
    title: 'windows',
    icon: <Windows />,
  },
];
