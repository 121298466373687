import design from '../../../../shared/assets/images/architect/4d-design.jpg';
import tour from '../../../../shared/assets/images/architect/3d-tour.jpg';
import consultations from '../../../../shared/assets/images/architect/consultations.jpg';
import foundation from '../../../../shared/assets/images/architect/foundation.jpg';
import support from '../../../../shared/assets/images/architect/support.jpg';
import busing from '../../../../shared/assets/images/architect/hannah-busing.jpg';
import model from '../../../../shared/assets/images/architect/model-house.jpg';

export const currentTexts = {
  label: 'Nuestro Proceso',
  description:
    'En la arquitectura, seguimos un proceso de diseño integral que garantiza que cada proyecto se ejecute con precisión y atención al detalle. Nuestro proceso incluye:',
};

export const currentPortfolioTexts = {
  label: 'Portafolio',
  description: '',
};

export const processList = [
  {
    number: 1,
    label: ' Pre-diseño',
    description:
      'En esta fase, el arquitecto se reunirá con el cliente para conocer sus necesidades y preferencias, así como los requisitos o limitaciones específicos del emplazamiento. El arquitecto también llevará a cabo un análisis del emplazamiento para evaluar factores como la topografía, la orientación y las vistas.',
    img: design,
  },
  {
    number: 2,
    label: 'Diseño Esquemático',
    description:
      'A partir de la información recopilada en la fase de prediseño, el arquitecto desarrollará un concepto de diseño preliminar que esboce la distribución general, el tamaño y la forma de la villa. Puede incluir bocetos o representaciones para ayudar al cliente a visualizar el diseño propuesto.',
    img: tour,
  },
  {
    number: 3,
    label: 'Desarrollo del diseño',
    description:
      'En esta fase, el arquitecto perfeccionará el concepto de diseño y elaborará dibujos y planos más detallados. Esto puede incluir planos de planta, alzados y secciones, así como modelos o representaciones en 3D.',
    img: busing,
  },
  {
    number: 4,
    label: 'Permisos y aprobaciones',
    description:
      'Antes de que pueda comenzar la construcción, el arquitecto tendrá que obtener los permisos o aprobaciones necesarios de las autoridades locales, como permisos de construcción, permisos medioambientales o aprobaciones de zonificación.',
    img: consultations,
  },
  {
    number: 5,
    label: 'Documentación de la construcción',
    description:
      ' Una vez finalizado el diseño y obtenidos los permisos, el arquitecto preparará los documentos de construcción detallados que brindan instrucciones a los constructores, incluidos los planos, las especificaciones y los cronogramas.',
    img: model,
  },
  {
    number: 6,
    label: 'Administración de la construcción',
    description:
      'Una vez finalizado el diseño y obtenidos los permisos, el arquitecto preparará los documentos de construcción detallados que proporcionarán instrucciones a los constructores, incluidos planos, especificaciones y calendarios.',
    img: foundation,
  },
  {
    number: 7,
    label: 'Después de la construcción',
    description:
      'Una vez terminada la villa, el arquitecto puede prestar servicios posteriores a la construcción, como una inspección final, planos de construcción y un manual de mantenimiento.',
    img: support,
  },
];
