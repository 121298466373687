export const LandscapeDesign = [
  {
    id: 1,
    title: 'Paisatgisme: element important o caprici?',
    description: `Les cases d'estil espanyol, especialment en el clima mediterrani de Catalunya, van de la mà de la creació de jardins únics. Aquí es van formar certs trets del paisatgisme local, que encara influeixen en el disseny dels jardins de la zona. Un dels trets característics és l' ús de flors fresques per decorar la façana de la casa. Sovint s'utilitzen plantes en test o enfiladisses, que confereixen a la casa un encant i una bellesa especials. Un element important és la creació d'un racó per passar una estona agradable amb amics i familiars. Pot ser un lloc decorat per a una festa o per relaxar-se, on gaudir de l'ambient i la comunicació. Un element tradicional popular és el pati: un pati acollidor amb seients a l'ombra i mobles còmodes. És un lloc on es pot gaudir de l'aire fresc i la intimitat dins del propi jardí. S' agraeix l' abundància i varietat de plantes de tota mena als jardins catalans, des de molses fins a enfiladisses. Aquí podrà veure plantes en testos i contenidors que afegeixen accents brillants i creen composicions interessants. El disseny paisatgístic és una part integral del mercat immobiliari a Espanya. L'exterior ajuda a augmentar el preu de la propietat i a demostrar la individualitat del propietari.`,
  },
  {
    id: 2,
    title:
      `Quant temps sol portar desenvolupar i executar un projecte de paisatgisme? ',
    description: 'Dissenyar i executar un projecte paisatgístic és un procés creatiu i polifacètic que requereix temps i atenció al detall. El termini per completar un projecte pot variar en funció de l' abast, la complexitat i els requisits específics del client.
Els petits projectes de paisatgisme, com un petit jardí o terrassa, poden dissenyar-se i executar-se en unes poques setmanes o mesos. En aquests casos, el procés inclou:
* Elaboració d' un pla de disseny.
* Selecció de plantes i materials.
* Realització dels treballs de plantació i decoració.
Els projectes mitjans, com enjardinar una zona suburbana o una propietat comunitària, poden requerir diversos mesos de treball. En aquest cas, el procés inclou una planificació més detallada, consultes amb el client, desenvolupament del concepte i visualització en 3D, així com l' execució del paisatgisme, els elements arquitectònics i la infraestructura.
Els projectes més grans i complexos, com el paisatgisme de parcs o complexos públics, poden requerir anys de treball. En aquests casos, el procés inclou:
* Planificació a llarg termini.
* Disseny del pla mestre.
* Participació en les aprovacions amb organismes governamentals i altres parts interessades.
* Execució del projecte per fases.
Els nostres experts intentaran crear l'interior perfecte per a vostè el més aviat possible. Per tant, no ho deixi per demà; demani el servei avui mateix i informeu-vos dels detalls de les condicions de treball.
`,
  },
  {
    id: 3,
    title:'Paisatgisme: ho fa vostè mateix o recorre als serveis de professionals?',
     description: `La imatge que té al cap de vegades coincideix amb la realitat. El paisatgisme és l'art de crear espais bells i funcionals que aportin alegria i confort. A l'hora de fer realitat el seu somni d'un jardí o parcel·la ideal, sorgeix la pregunta: fer-ho tot vostè mateix o recórrer a l'ajuda de professionals?
Fer vostè mateix el paisatgisme pot ser un procés emocionant i creatiu que li permeti donar vida a les seves pròpies idees i preferències. En fer-ho tot vostè mateix, pot estalviar alguna cosa de diners i tenir ple control sobre el procés d'execució. Això pot ser especialment interessant per a persones amb certes habilitats i coneixements en paisatgisme i disseny.
Però el disseny paisatgístic és un procés complex i polifacètic que requereix un profund coneixement de les plantes, els sòls, les condicions climàtiques i experiència en planificació i composició. Una planificació o selecció de plantes inadequada pot donar lloc a resultats insatisfactoris i a pèrdues de temps i diners. Els nostres professionals tenen els coneixements i l'experiència necessaris per crear un disseny únic i funcional, tenint en compte els seus desitjos i les característiques del lloc. Seran capaços de multiplicar els avantatges i ocultar els inconvenients del territori, utilitzar cada metre quadrat de la forma més competent possible i centrar-se en la comoditat dels residents. També podran estalviar-li temps i esforç utilitzant els recursos de forma eficient.`,
  },
  {
    id: 4,
    title:
      `T'ocupes tu mateix de l'exterior o recorres als serveis d'especialistes?`,
    description: `Un cop finalitzat el projecte, els nostres paisatgistes poden oferir-li la seva assistència i consells de manteniment per ajudar-lo a conservar i mantenir la bellesa i salut del seu jardí durant anys. Poden aconsellar-li sobre el reg adequat, l' abonament, la poda i la cura general de les plantes. Al cap i a la fi, cada planta requereix el seu propi enfocament, i els professionals l' ajudaran a determinar el règim de cures òptim.
A més, els paisatgistes poden assessorar-lo sobre el manteniment i cura d' altres elements del seu jardí o exterior, com camins, fonts i estanys artificials. Compartiran amb vostè els seus coneixements sobre la neteja i el manteniment adequats d' aquests elements perquè sempre tinguin un aspecte atractiu i segur. A més, els paisatgistes poden ajudar-lo a determinar els treballs i el manteniment estacionals que requereix el seu jardí. Li indicaran com preparar el seu jardí per a l' hivern o l' estiu, eliminar adequadament el fullatge, podar les plantes o realitzar els treballs de manteniment necessaris.
Si vol estalviar temps, pot continuar col·laborant amb nosaltres i l'ajudarem a mantenir el seu jardí.
`,
  },
  {
    id: 5,
    title: 'Gespa enrotllada o sembrada, què és millor per al lloc?',
   description:  `Aquí teniu algunes preguntes que poden ajudar-lo a decidir:
1. Quina és la mida de la parcel·la? Les gespa en rotllo solen ser preferibles per a superfícies petites o mitjanes, ja que poden instal·lar-se ràpidament i donen al jardí un aspecte complet immediatament. Les gespa sembrades són més adequades per a zones més grans, on es pot justificar un procés de sembra més llarg.
2. Quant temps està disposat a dedicar a crear una gespa? Les gespa de sembra necessiten més temps. Les gespa en rotllo ofereixen resultats ràpids, llestos per fer servir després de la col·locació.
3. Quin és el seu pressupost? Les gespa de sembra poden ser més econòmiques, sobretot per a superfícies grans. Les gespa en rotllo solen ser més cars que els sembrats, però proporcionen resultats més immediats.
4. Quin és el clima de la seva zona? Alguns tipus de gespa poden adaptar-se millor a determinades condicions climàtiques, com les regions seques o humides. Les gespa de sembra poden ser més flexibles a l'hora de seleccionar les varietats de gespa més ben adaptades al seu clima.
5. Quin efecte estètic desitja aconseguir? Les gespa en rotllo solen tenir un aspecte més uniforme i instantani, mentre que les gespa sembrades poden crear un paisatge més natural i progressiu.
`,
  },

  {
    id: 6,
    title: 'Què triar, reg automàtic o reg manual de les plantes?',
    description: `Reg automàtic:
        1. El reg automàtic proporciona comoditat, especialment si té un jardí gran o moltes plantes. Pot establir el programa de reg, i el sistema controlarà de forma independent el reg regular i uniforme de les plantes sense la seva presència.
        2. El reg automàtic estalvia temps que empraria regant manualment cada planta.
        3. Els sistemes de reg automàtic solen estar equipats amb sensors d'humitat que poden detectar quan les plantes necessiten reg i només subministren aigua quan és necessari. Això ajuda a evitar el malbaratament d'aigua i a conservar-la.
        Reg manual:
        1. El reg manual et permet controlar el procés de reg i interactuar directament amb les plantes. Pots observar millor el seu estat i respondre a qualsevol problema o necessitat.
        2. El reg manual li permet triar els llocs i la quantitat de reg per a cada planta per separat. Pot adaptar el reg a les característiques de cada planta, les seves necessitats d' humitat i les condicions ambientals.
        3. El reg manual no requereix la instal·lació d'equips i sistemes especials, per la qual cosa pot resultar més rendible que el reg automàtic.
        A l' hora de triar entre el reg automàtic i el manual, tingui en compte la mida del seu jardí, el nombre de plantes, la disponibilitat d' aigua i les preferències de cura de les plantes. Per a consultes addicionals i anàlisis detallades, pot posar-se en contacte amb els nostres especialistes.
`,
  },
];

export const SolarEnergy = [
  {
    id: 1,
    title: 'És rendible utilitzar panells solars? ',
    description: `Sí, a Espanya instal·lar panells solars és una opció cada vegada més atractiva i intel·ligent. A l'hora d'avaluar l'eficiència d'un sistema per al consum privat d'electricitat, cal tenir en compte que la vida útil dels panells solars és d'uns 25 anys, i la inversió sol amortitzar-se en uns 7 anys. Això significa que els propietaris de sistemes solars a Espanya no només tenen accés a una energia neta i renovable, sinó que també tenen l'oportunitat de reduir significativament els seus costos energètics durant un llarg període de temps. És més, els panells solars poden reduir l'exposició a les fluctuacions dels preus de l'energia i mitigar l'impacte dels canvis en el cost de l'electricitat.`,
  },

  {
    id: 2,
    title: `Es poden evitar els alts preus de l'electricitat?`,
   description: `El sol és un recurs inestimable. A Espanya, els preus de l'energia són ara gairebé tres vegades superiors a la mitjana dels últims cinc anys. Gràcies a la seva abundància de sol, Espanya aposta pels panells solars com a font alternativa d'energia. L'amortització és molt més ràpida que al Regne Unit o Suècia.`,
  },

  {
    id: 3,
    title: `Quin serà el futur de l'energia solar a Espanya?`,
    description: `Per al 2030, Espanya preveu que gairebé el 75% de la producció energètica del país procedeixi de fonts alternatives, mentre que la xifra actual ronda el 47%. Espanya aposta per un futur sostenible basat en fonts d'energia netes.`,
  },

  {
    id: 4,
    title: `És difícil mantenir els panells solars?`,
    description: `L'ús de panells solars implica un procés senzill i no especialment costós per al seu manteniment. Per suposat, això és una mica més difícil que no fer res quan casa seva està connectada a les xarxes elèctriques públiques, però tampoc és més difícil que amb qualsevol altre electrodomèstic - rentadora, frigorífic, que requereixen manteniment. Si la seva instal·lació ha estat adquirida a proveïdors de confiança, a vostè, com a client, se li ofereix un servei complet de manteniment i reparació de l'energia solar. Pel seu compte, pot netejar els panells de la brutícia i prolongar així la seva vida útil.`,
  },

  {
    id: 5,
    title: 'On més es demanen panells solars?',
   description: `A la Xina, més de 40 milions de llars ja estan equipades amb escalfadors solars d'aigua, cosa que indica una gran demanda d'aquesta tecnologia. Pequín també ha instal·lat la impressionant xifra de 300 milions de metres quadrats de panells solars per al 2020. Espanya també està prenent mesures actives en el desenvolupament de l'energia solar. Des de març de 2006, tots els habitatges nous a Espanya estan obligats per llei a estar equipats amb panells solars. No obstant això, el líder mundial en l'ús d'energia solar per càpita és Israel. Aquest país està demostrant el seu progrés instal·lant més panells solars per persona que cap altre.`,
  },

  {
    id: 6,
    title:
      `És rellevant l'energia solar per a l'aigua calenta i la calefacció?`,
    description: `L'energia solar permet utilitzar-la tant per a la calefacció d'espais com per a la producció d'aigua calenta. L' energia solar per produir aigua calenta és una de les formes més eficients d' utilitzar aquesta font d' energia. Tanmateix, la calefacció d' espais requereix una superfície molt més gran de panells solars que l' escalfament d' aigua. És important assenyalar que la calefacció solar és avantatjosa durant l' hivern, quan es necessita calor addicional. Al mateix temps, durant l'estiu, els grans panells solars s'han de protegir del sobreescalfament. L' aigua calenta escalfada per energia solar pot circular en sistemes de calefacció per sòl radiant o alimentar radiadors per mantenir una temperatura interior confortable. Per aconseguir la màxima eficiència, el sistema s'ha de dissenyar per funcionar a baixes temperatures del fluid per maximitzar l'energia solar disponible`,
  },

  {
    id: 7,
    title: 'Quant duren els panells solars?',
    description: `Els panells solars tenen una vida útil impressionant, comparable a la d'altres dispositius electrònics. Amb un ús i una cura adequada, poden continuar funcionant durant dècades.
    El coeficient de degradació dels mòduls solars fotovoltaics, que depèn de la qualitat i la fabricabilitat dels productes, exerceix un paper clau en la determinació de la vida útil. L'escenari òptim suposa que en 20-25 anys la instal·lació solar produirà al voltant del 80-85% de la seva capacitat original. És a dir, seguirà contribuint de forma significativa a la generació d' electricitat.
   És important tenir en compte que, després d'aquest període, els panells solars no fallen instantàniament. Poden continuar funcionant, però amb certa degradació del seu rendiment. Fins i tot després que hagi expirat la vida útil garantida, la instal·lació solar pot continuar funcionant i proporcionant beneficis durant anys addicionals.
    Les garanties dels fabricants també exerceixen un paper important. Solen oferir una garantia per danys mecànics de 5 anys per als productes de gamma mitjana i de 10-12 anys per als de gamma alta. Això significa que el comprador pot estar segur de la fiabilitat i qualitat dels panells solars durant un determinat període de temps.`,
  },
];

export const Inmobiliaria = [
  {
    id: 1,
    title: 'Per què és important analitzar el sòl i el terreny?',
    description: `
      El terreny i el sòl determinen les propietats fonamentals d' un emplaçament, com la seva estabilitat i la seva capacitat per suportar una estructura.
Els estudis geològics i geotècnics realitzats per especialistes ajuden a determinar si el sòl és apte per a la construcció. Els nostres experts comproven la densitat del sòl, les seves propietats portants i la seva resistència a la deformació. Això és important per garantir que el seu edifici no corre perill d'enfonsar-se, ensorrar-se o desplaçar-se.
Els experts de Moderna Casa afirmen que la topografia i el sòl també influeixen en el drenatge i el subministrament d'aigua del solar. Una planificació i ubicació adequades de la casa en el solar eviten problemes d' aigua estancada i drenatge inadequat d' aigües residuals. Així, és possible determinar els llocs òptims per col·locar els sistemes de drenatge i sanejament.
El terreny i el sòl poden influir en la disposició i l' arquitectura d' un edifici. Les irregularitats del terreny poden requerir excavacions i aterrassaments addicionals del solar per crear un terreny pla per a la construcció. I havent-ho analitzat prèviament a través del nostre servei immobiliari, podem indicar-li un cost de serveis més exacte.
El coneixement del sòl permet triar els millors mètodes de fonamentació adaptats a les condicions específiques del lloc. Per exemple, una zona planera pot oferir oportunitats per seguir construint o crear un espai enjardinat.

      `,
  },

  {
    id: 2,
    title:
      `Com realitzar una anàlisi comparativa de diverses parcel·les per seleccionar l'adequada?`,
    description: `
     Elabori una llista de criteris que s'utilitzaran per comparar les parcel·les. Aquests criteris poden incloure l' accessibilitat, el valor del terreny, les infraestructures, els factors mediambientals, el potencial de desenvolupament futur, etc. Determini la importància de cada criteri per destacar els aspectes més significatius.
Recopilar totes les dades disponibles de cada emplaçament, inclosos plànols, mapes, infraestructures, avaluació mediambiental i preus i permisos del sòl.
Analitzi cada lloc utilitzant criteris d' avaluació predefinits. Avaluï cada criteri en una escala o sistema de classificació. Pot utilitzar números o classificacions perquè les comparacions siguin més objectives.
Tenint en compte la importància de cada criteri, així com coeficients de significació. Per exemple, si l'accessibilitat és el criteri més important, pot donar-li més pes que a altres factors.
Analitzi les puntuacions de cada lloc i tingui en compte la ponderació dels criteris. Compari els resultats i determini quina secció té la puntuació global més alta. Aquest serà el lloc més adequat per a la construcció.
A més de l' avaluació comparativa, cal tenir en compte el pressupost del projecte, les restriccions legals, la normativa local i els permisos. El servei immobiliari també implica aquest tipus d' anàlisi. Els nostres experts, especialitzats en el mercat immobiliari, l'ajudaran amb l'anàlisi i recopilaran tot el material necessari.

      `,
  },

  {
    id: 3,
    title: `Com analitzem les infraestructures?`,
    description: `
     Aquest servei també inclou l' anàlisi de la infraestructura. Prestem atenció a:
Institucions educatives per a infants, com escoles i llars d' infants.
Farmàcia, preferiblement oberta 24 hores al dia, 7 dies a la setmana.
Hospital o sala d' urgències, almenys en les immediacions.
Clínica veterinària, imprescindible per a agricultors que vulguin una casa a Espanya per desenvolupar cultius i ramaderia.
La presència d' una carretera asfaltada que facilita l' accessibilitat del lloc.
Distància a l' assentament més proper.
La proximitat d' estacions d' automòbil i ferrocarril facilita les comunicacions.
Botigues de comestibles, mercats i centres comercials per a la comoditat de les compres diàries.
Instal·lacions de lleure, parcs i places d'esbarjo.
Disponibilitat d' estructures de protecció contra possibles inundacions en cas de crescudes.
El nombre i tipus de carreteres adjacents a l' emplaçament i la congestió de la ruta en diferents dies i estacions. Per exemple, si l'hospital més proper està a 15 quilòmetres del solar i cal conduir per una carretera sense asfaltar, això pot ser un desagradable desavantatge. El servei ""Immobiliària"" ajuda a analitzar el possible nivell de comoditat que es pot proporcionar al comprador del solar.

      `,
  },

  {
    id: 4,
    title: `Per què cal analitzar les aigües subterrànies`,
    description: `
     L' aigua és un perill potencial per a les estructures dels edificis, especialment per als fonaments. Les cases construïdes en llocs amb nivells freàtics alts estan exposades a danys diaris per l' aigua, a diferència de les cases amb nivells freàtics baixos. Això pot provocar l'esfondrament dels fonaments, esquerdes a les parets, inundació dels soterranis, floridura i fongs. L' impacte en els fonaments no només el causen les pròpies aigües subterrànies. Les substàncies químiques dissoltes a l'aigua (per exemple, sulfats, sals) també poden tenir greus conseqüències. Poden provocar el despreniment i la dissolució del formigó, que es manifesta en forma de capa blanca i delaminació dels fonaments. El drenatge d' un terreny amb un alt nivell d' aigües subterrànies ajudarà a prevenir totes aquestes conseqüències negatives. I el servei immobiliari ajudarà a avaluar exhaustivament la situació fins i tot abans de la fonamentació.

      `,
  },

  {
    id: 5,
    title: `Per què és impossible prescindir de l'anàlisi del terreny?`,
    description: `
    A l' hora de triar un terreny per construir, presti sempre atenció al relleu. Els experts de Moderna Casa afirmen que els terrenys accidentats poden ser bells en el disseny paisatgístic, però crear dificultats a l'hora de construir una casa. És aconsellable triar una zona planera, sense corbes ni penya-segats. És preferible un terreny elevat, ja que oferirà protecció contra les inundacions i sol tenir un preu més elevat.

      `,
  },

  {
    id: 6,
    title: 'Per què no prescindir del servei ""Immobiliari""?',
    description: `
  Sempre hi ha moltes dificultats en les fases preparatòries, i els esculls més perillosos solen sorgir al cap d'un temps. La tasca dels nostres experts és ajudar-lo a reconèixer aquests riscos. Si en el futur decideix vendre el terreny, ha de tenir en compte els factors que afecten el seu valor:
La presència d' edificis de diversos pisos en un radi de 150 metres. L' alçada dels edificis permet als residents observar els voltants, la qual cosa pot causar certa inquietud.
La proximitat d' hangars i granges. Això pot provocar sorolls innecessaris i olors desagradables associades a la cria d' animals.
Edificis abandonats. Poden esdevenir llocs de residència de persones amb escassa responsabilitat social, la qual cosa crea possibles problemes de seguretat i amb l' entorn del barri.
La presència de cementiris. Alguns compradors poden percebre' ls com un element indesitjable que afecta l' estètica i l' ecologia de l' entorn.
La proximitat de línies elèctriques (esteses elèctriques). Algunes investigacions les han relacionat amb possibles efectes sobre la salut, cosa que pot fer enrere els compradors.
La presència de cooperatives de garatges. Poden afectar l' ambient general i l' aspecte visual de la urbanització circumdant. Tenir en compte tots aquests factors a l' hora de comprar una propietat reduirà la probabilitat que sorgeixin problemes durant una venda posterior. I els nostres experts l'ajudaran a recórrer aquest camí amb vostè.

      `,
  },
];

export const Consulting = [
  {
    id: 1,
    title:
      'Què són els serveis de consultoria en el mercat immobiliari a Espanya?',
   description: `
 Es tracta d'una oportunitat per a consultar a persones competents i confiar a experts una anàlisi de la situació actual del mercat i d'altres qüestions relacionades directament o indirectament amb el sector immobiliari a Espanya. Aquesta anàlisi és una avaluació professional de la infraestructura d'un edifici existent o en projecte. El seu objectiu principal és identificar les vulnerabilitats i el funcionament inadequat de la instal·lació que poden conduir a una rendibilitat insuficient. Els experts també analitzen edificis que només estan en fase de planificació. Com a resultat d'aquest procés, s'identifiquen les deficiències que, després de la seva correcció, faran que l'objecte sigui més atractiu per a residents, clients i inquilins.

      `,
  },

  {
    id: 2,
    title: `Què inclou l'assessorament integral?`,
    description: `
    La consultoria de projectes inclou diversos components importants. En primer lloc, s'avalua el mercat, el públic objectiu i el poder adquisitiu dels clients potencials per als quals està prevista la construcció de la instal·lació. Això ajuda a determinar la pertinència del projecte i la seva viabilitat comercial. En segon lloc, s' avalua l' alfabetització estètica i urbanística del local i s' analitza la demanda d' una solució arquitectònica d' aquest tipus entre els clients potencials. Això és important per crear un espai atractiu i còmode que satisfaci les necessitats dels futurs usuaris. El tercer aspecte inclou la comprovació que la documentació tècnica compleix les normes i estàndards per garantir la seguretat i qualitat de la construcció. També s'investiguen les expectatives de la població que viu en les immediacions del projecte per tenir en compte les seves necessitats i oferir els serveis i comoditats més adequats. Un pas important és comprovar el compliment de les lleis i normatives perquè el projecte compti amb tots els permisos necessaris. Després de rebre els nostres serveis de consultoria, es pot passar a les fases de disseny i construcció de la casa, que també són a càrrec dels nostres experts. Finalment, es forma el concepte de l' edifici i es desenvolupa un pla de màrqueting. Això inclou la identificació de les característiques i avantatges exclusius del projecte, així com estratègies de màrqueting i promoció per atreure els clients interessats i treure la propietat al mercat amb èxit.

      `,
  },

  {
    id: 3,
    title: `Què inclou l'assessorament jurídic?`,
    description: `
   El sector immobiliari és una àrea complexa i regulada amb diverses lleis, normes i procediments que s' han de seguir. L' assessorament jurídic en l' àmbit immobiliari és de gran importància per protegir els drets i interessos dels clients, així com per garantir l' èxit de les transaccions i operacions. Si, al seu país d'origen, és més fàcil per a un comprador o inversor entendre les normes i lleis per si mateix, llavors els inversors estrangers en el sector immobiliari a Espanya s'han d'informar sobre els esculls de la mà d'experts. Una de les principals raons per buscar assessorament jurídic és garantir la seguretat jurídica. Els advocats analitzen documents i transaccions per assegurar-se que són legals i adverteixen de possibles riscos jurídics. Ajuden els clients a evitar problemes i litigis inesperats relacionats amb els béns immobles i ofereixen assessorament i solucions per resoldre qüestions legals. L' examen legal dels documents requereix assessorament jurídic. Això inclou la comprovació de contractes de compravenda, arrendaments, títols de propietat i altres documents legals. La correcció i integritat de la documentació són essencials per a l' èxit d' una transacció immobiliària, i els advocats ajuden els clients a evitar possibles errors i problemes associats a documents executats incorrectament. Els nostres experts expliquen termes i procediments legals complexos, les normes i lleis que regeixen la propietat immobiliària, i ajuden els clients a comprendre els seus drets i obligacions. Recomanen les millors pràctiques i estratègies per aconseguir els resultats desitjats i adverteixen dels riscos potencials.

      `,
  },

  {
    id: 4,
    title: `Quines són les peticions més comunes que rebem?`,
    description: `
  Consultes orals per hores en què els nostres experts responen a les seves preguntes i l'assessoren sobre diversos aspectes de la propietat.
Una opinió consultiva sobre inversions per ajudar-lo a prendre decisions informades sobre inversions immobiliàries i avaluar el rendiment potencial de la seva inversió.
L' estudi de mercat dels mercats immobiliaris inclou els segments residencial, d' oficines, comercial i de lleure, hoteler i industrial i de magatzems. Analitzem la demanda, l' oferta i la competència en aquests mercats, ajudant-lo a comprendre el potencial i les perspectives de desenvolupament de diversos segments immobiliaris.
Serveis de planificació conceptual que ajuden a desenvolupar conceptes de construcció i desenvolupament immobiliari eficaços i innovadors, tenint en compte factors de mercat, econòmics, necessitats dels clients i requisits legals.
Anàlisi expressa de la situació en el format d' opinió professional, que proporciona una avaluació ràpida i objectiva de la situació actual i recomanacions per a noves accions.
Prepari un memoràndum d' inversió que descrigui el projecte, el seu potencial, riscos i resultats esperats per atreure possibles inversors.
Anàlisi de la documentació urbanística i perspectives de desenvolupament del projecte, que inclou l' estudi dels plans urbanístics, la coordinació amb els requisits dels organismes estatals i l' avaluació de les perspectives d' execució del projecte.

      `,
  },
];

export const SwimmingPools = [
  {
    id: 1,
    title: `Què es pot afegir a la piscina?`,
    description: `Instal·lació d'un ""flux d'aigua artificial"". Una gran opció per als amants de l'activitat física. Fins i tot en piscines petites, aquest aparell permet gaudir alhora de la natació esportiva i de l'hidromassatge. El gèiser del fons ofereix a cada membre de la família un massatge d'aire que crea una sensació d'ingravidesa i enriqueix l'aigua amb oxigen. El complement ajudarà els membres més joves de la família a començar a flotar més ràpid i amb més confiança. I els familiars adults estaran encantats que després d'aquest massatge desapareguin el cansament, l'insomni i el mal humor. Una cascada no és només estètica i bellesa exterior, sinó també un eficaç massatge per a la cintura escapular. També és el complement perfecte per crear un ambient de relaxació i gaudi a la piscina. I l'opció de retroil·luminació crearà una sensació de confort en la foscor. Un tobogan aquàtic és el somni de tots els nens. Poden passar-se hores baixant incansablement pel tobogan, gaudint de la diversió i l' entreteniment. En el catàleg de la nostra empresa trobarà una àmplia gamma d'accessoris per a piscines, i els nostres experimentats especialistes l'ajudaran a triar l'opció perfecta que compleixi els seus requisits i desitjos. Només oferim les atraccions més funcionals i de més qualitat. Els nostres experts saben com fer de la seva piscina una solució no trivial i funcional.

     `,
  },
  {
    id: 2,
    title: `E el tipus de piscina: ¿skimmer o desbordant?`,
    description: `
      Piscina amb skimmer: l' aigua es filtra a través de skimmers de superfície i es retorna a través de broquets de paret.
Piscina desbordant: l' aigua s' aboca al tanc d' emmagatzematge, passa pels sistemes de filtració, calefacció i desinfecció, i torna per les broquetes del fons. Triar el tipus de piscina depèn de vostè, però si té la capacitat i el pressupost, l'opció preferida és una piscina infinita. No obstant això, una piscina de skimmer també pot ser de gran qualitat si se segueixen tots els càlculs i tecnologies necessaris. Estem disposats a ajudar-lo a instal·lar qualsevol piscina.

      
      `,
  },
  {
    id: 3,
    title:
      `Quines oportunitats ofereix l'automatització i el control de piscines?`,
    description: `
      Si fa una ullada més de prop als nostres serveis, veurà un servei com a ""casa intel·ligent"". Està dissenyat no només per al seu ús dins de la casa, sinó també per a elements externs.
El mecanisme permet automatitzar els processos de filtratge i manteniment de la qualitat de l' aigua de la piscina. Pots controlar i ajustar el funcionament dels filtres, les bombes, la desinfecció i els sistemes d'escalfament de l'aigua mitjançant una aplicació en el teu smartphone o un panell de control.
El sistema ""casa intel·ligent"" permet gestionar la temperatura de l'aigua de la piscina de forma intel·ligent. Pots fixar la temperatura desitjada amb el mòbil i el sistema la mantindrà automàticament, inclòs l'encesa i apagada dels escalfadors d'aigua.
Cada persona té l'oportunitat de controlar la il·luminació de la piscina, inclosos els llums subaquàtics, mitjançant el sistema ""smart home"". D'aquesta manera es creen diferents atmosferes i ambients al voltant de la piscina.
La seguretat i l'alarma són un altre avantatge. Pot rebre notificacions sobre l'estat de la piscina, com un canvi en el nivell de l'aigua o la detecció de moviment al voltant de la piscina, per evitar un accident.
Estableixi un horari per al sistema de la piscina i faci guions automàtics. Per exemple, pot programar l'encesa i apagada dels sistemes de filtració i calefacció, i crear escenes que encenguin els llums i la música de fons quan s'utilitzi la piscina. Aquests són només alguns exemples del que els sistemes domèstics intel·ligents poden fer per automatitzar i gestionar la seva piscina. L'ajudarem a obtenir el màxim benefici de la integració del mecanisme d'automatització posant-se en contacte amb nosaltres.

      `,
  },

  {
    id: 4,
    title:
      `Quins materials són els més adequats per a l'acabat de la superfície interior de la piscina?`,
    description: `
      Els materials més adequats per a la decoració interior de piscines són:
La rajola ceràmica és un material popular i durador per a la decoració de piscines. Té bona resistència a l'aigua, als productes químics i als raigs UV, i és fàcil de netejar.
La fibra de vidre es considera un material durador. Crea una superfície llisa i proporciona una bona protecció contra la corrosió i els raigs UV.
La resina epoxi garanteix un revestiment durador i impermeable per a piscines. Té una gran resistència química i suporta càrregues pesades.
La membrana de PVC és un material flexible que pot cobrir la superfície interior de la piscina. Són molt resistents als productes químics i duren molt de temps.
La pintura de poliuretà s'utilitza molt en la decoració de piscines. Proporciona una superfície llisa i duradora resistent als productes químics i a la radiació UV. Li recomanem que consulti amb els nostres professionals per seleccionar el material adequat a les seves necessitats i a les condicions de la seva piscina.
      `,
  },

  {
    id: 5,
    title: 'Quin tipus de servei i manteniment requereix la piscina?',
    description: `Els següents serveis i manteniment són necessaris per a garantir el bon funcionament i la qualitat de la piscina:
Retirar enderrocs, fulles i altres restes de la piscina utilitzant una xarxa o un aspirador de piscines.
Comprovar i corregir el nivell de clor, l'equilibri del pH i altres indicadors químics de l'aigua. Garantir la seguretat de l'aigua i evitar la proliferació de bacteris i algues nocives.
Comprovació i neteja del filtre de la piscina. El filtre elimina les partícules fines i les impureses de l'aigua, i netejar-lo amb regularitat ajuda a mantenir el sistema de filtració funcionant amb eficàcia.
Manteniment del sistema d'escalfament d'aigua (si el sistema està instal·lat). Pot incloure la revisió i el manteniment d'equips de calefacció com a calderes, bombes de calor o col·lectors solars.
Inspecció i manteniment de la bomba de la piscina. La bomba fa circular l'aigua de la piscina i ha de mantenir-se per a filtrar i tractar l'aigua amb eficàcia.
Control de tots els sistemes de seguretat, incloent-hi la presència i operativitat d'una boia salvavides, interruptors i dispositius de senyalització. Aquests serveis i el manteniment són importants per a mantenir la piscina segura, neta i funcionant correctament. Per tant, estem disposats a ser els seus ajudants de confiança en aquest camí i proporcionar serveis sistemàtics de qualitat.

      `,
  },

  {
    id: 6,
    title:
      'Com garantir la seguretat a la piscina, especialment dels nens?',
    description: `Per a nosaltres és essencial no sols vendre-li una piscina, sinó també garantir una elevada seguretat per a cada membre de la família. Per a això, cal prendre les següents mesures:
Instal·li una tanca fiable al voltant de la piscina, que impedeixi l'accés no autoritzat dels nens a l'aigua.
Si no és possible observar la piscina, utilitza cobertes protectores com boies salvavides o cobertes de piscina per a evitar que els nens caiguin accidentalment a l'aigua.
Els adults han d'estar sempre prop dels nens prop de la piscina. La supervisió regular i atenta d'un adult pot evitar situacions perilloses.
Eduqui als nens sobre les normes de seguretat en la piscina. És important explicar-los que no han de jugar prop de la piscina sense adults ni tirar-se a l'aigua sense permís.
Prop de la piscina ha d'haver-hi sempre equips de salvament, com a boies o balises salvavides. En cas d'emergència, poden servir d'ajuda. Escrigui'ns i li mostrarem com fer que la seva piscina sigui pràctica, bonica i pensada, i segura per a tots.

      `,
  },

  {
    id: 7,
    title: `Puc triar jo mateix l'equipament de la piscina?`,
   description: `L'autoselecció de l'equip per a la piscina és possible, però cal resoldre seriosos problemes. Requereix estudiar molts llibres i documents, comprendre els principis del tractament de l'aigua, calcular els diàmetres de les canonades i la velocitat de moviment de l'aigua, triar l'equip adequat i molt més. No es pot dir que sigui una oportunitat per a estalviar diners, ja que cal invertir molt de temps. Tractar amb Moderna Casa, especialitzada en la millora del rendiment del seu habitatge i en la venda d'equips per a piscines, li garanteix preus competitius i productes de qualitat.

      `,
  },
];

export const Architect = [
  {
    id: 1,
    title: 'Quant dura un projecte arquitectònic?',
    description: `
 Les condicions òptimes per a dissenyar un edifici residencial individual depenen de la interacció entre l'arquitecte i el client. Com més eficaç sigui la retroalimentació i l'aprovació de les solucions de disseny proposades, més ràpid es completarà el disseny. Normalment, el procés dura d'1 a 3 mesos, però en el cas d'objectes complexos, pot portar fins a sis mesos desenvolupar un projecte complet.
      `,
  },
  {
    id: 2,
    title:
      'Projecte de disseny i projecte de treball: quines característiques tenen?',
    description: `
 El projecte de disseny i el projecte d'obra difereixen en les seves característiques i funcionalitat. El projecte de disseny són dades abstractes que donen una idea de l'aspecte i el concepte del futur habitatge. Al mateix temps, el projecte d'execució són els plans detallats i desenvolupats i la documentació necessària per a la construcció pròpiament dita. Aquests documents serveixen de guia directa i de base per a tots els treballs de construcció.

     
      `,
  },
  {
    id: 3,
    title: `Quin projecte és més barat: l'estàndard o l'individual?`,
    description: `
 Els dissenys originals i estàndard tenen costos diferents. Un projecte estàndard sol ser més assequible, ja que ja s'ha desenvolupat i revenut una vegada coberts els costos inicials. No obstant això, el projecte estàndard té un inconvenient important, ja que es va crear tenint en compte la zona específica, els serveis públics, l'orientació del solar i les condicions climàtiques. Una solució estàndard només pot satisfer parcialment els seus desitjos i requisits, per la qual cosa les modificacions poden implicar costos addicionals i retardar la construcció. Per això, acudint als nostres especialistes, pot estar segur que s'elaborarà un projecte individual per a vostè, tenint en compte les recomanacions.

      
      `,
  },
   {
    id: 4,
    title: `Quines són les etapes del disseny i la construcció d'una casa?`,
   description: `
La seqüència de construcció d'una casa particular sol ser la següent: Moviment de terres, si existeix llicència d'obres i projecte de casa acabada.
Construcció dels fonaments.
Construcció de parets i sostres.
Realització de treballs de teulada.
Instal·lació de finestres.
Estesa de comunicacions internes i estesa de xarxes externes d'enginyeria.
Treballs d'acabat interior, inclosos sostres i sòls.
Instal·lació de portes i escales.
Instal·lació i connexió d'equips d'enginyeria.
Realització dels acabats exteriors. Aquesta seqüència permet construir una casa per etapes, des dels fonaments fins a l'exterior, proporcionant un enfocament sistemàtic i estructurat de la construcció.

      
      `,
  },

  {
    id: 5,
    title:
      'Quin estil arquitectònic és el més comú als immobles a Espanya?',
   description: `
L'estil mediterrani de cases és popular a les províncies del sud d'Espanya. Les façanes solen estar acabades amb guix blanc, s'utilitzen finestres i portes arquejades, i també hi ha galeries obertes i terrasses. Les teulades es cobreixen amb teules marrons o taronges. Aquest estil s'adapta bé al clima càlid del sud d'Espanya, ja que protegeix del sol i crea una sensació increïble. A mesura que s'acosta al nord, l'arquitectura de les cases comença a canviar. Pot trobar cases d'estil colonial o amb façana de pedra natural. En l'estil colonial, les parets solen estar acabades amb guix rugós o matèries primeres, els sòls són de fusta i s'instal·len persianes de fusta en les finestres. Les cases modernes d'alta tecnologia són cada vegada més populars. El predomini del cristall i el metall en el disseny caracteritza les formes i línies rectilínies. Aquestes cases, sobretot les que tenen vistes a la mar, tenen espai, visibilitat i molta llum.

      `,
  },

  {
    id: 6,
    title:
      `Quins factors han de tenir-se en compte en planificar l'espai interior d'una casa?`,
    description: `
 Determini els principals objectius i necessitats de la seva llar. Organitza les habitacions de manera que cada zona sigui utilitzable i existeixi una seqüència lògica de moviment entre elles.
Tingui en compte la circulació natural dins de la casa. Faci recorreguts còmodes d'una habitació a una altra, evitant passadissos estrets i congestionats.
Aprofiti al màxim la llum natural: col·loqui les finestres de manera que aprofitin al màxim la llum natural i creuen un ambient interior agradable.
Tingui en compte la necessitat d'intimitat en les diferents zones de la casa. Col·loqui els dormitoris i els banys en zones allunyades de la casa, i deixi les zones públiques més obertes.
La relació entre la grandària i les proporcions de les habitacions és essencial per a crear una impressió visual harmoniosa i agradable. Tingui en compte les proporcions correctes a l'hora de col·locar mobles i accessoris.
Consideri la possibilitat de crear espais multifuncionals que puguin utilitzar-se per a diversos fins. Per exemple, un saló-menjador combinat o un estudi, que també pugui utilitzar-se com a dormitori de convidats.
Proporcioni suficient espai d'emmagatzematge. Col·loqui estanteríes o armaris de paret per a aprofitar eficientment l'espai i mantingui les coses organitzades.
Tingui en compte el seu estil i preferències a l'hora de triar acabats, combinacions de colors i decoració. Creu un ambient que reflecteixi la seva personalitat i generi una impressió agradable. Els experts de Moderna Casa poden oferir-li consultes i recomanacions més detallades sobre el desenvolupament d'un projecte arquitectònic. Farem que la seva casa sigui còmoda per a tots els membres de la família.
      `,
  },
];

export const SmartHome = [
  {
    id: 1,
    title:'Llar intel·ligent: instal·lar el sistema o no?',
    description: `
 Una casa intel·ligent no és només una novetat tecnològica, sinó un estil de vida modern que s'ha convertit en la nova norma en el món immobiliari a Espanya. La llar intel·ligent fa que la seva vida sigui còmoda, eficient i agradable i mostra l'avantguarda de la tecnologia just a la seva casa. El sistema de domòtica pot reduir significativament el consum d'energia a l'apartament. Gràcies a la videovigilància, podrà controlar fàcilment la seguretat dels seus fills i de la seva llar, fins i tot quan estigui fora de casa. I quines mirades de sorpresa esperen als teus amics quan tanques les persianes amb una sola frase, per exemple, ""*Siri, programa de cinema"", apagues les llums i el reproductor encén la teva sèrie de televisió favorita: és pura màgia. Una tendència que salva vides i ajuda a optimitzar diversos processos domèstics.
      `,
  },
  {
    id: 2,
    title: 'Puc entendre el sistema per mi mateix?',
   description: `
 Per descomptat que sí. Perquè la ""casa intel·ligent"" s'ha creat per a simplificar la vida de qualsevol persona. Els nostres experts ajudaran el client a comprendre els principis de funcionament dels diferents sistemes en la fase de selecció. Després de la prova de contacte dels equips a la sala d'exposicions, tots els dubtes del possible propietari desapareixen per complet. En una setmana, s'acostumarà al seu nou assistent i no podrà imaginar la vida sense ell.
      
      `,
  },
  {
    id: 3,
    title:
      'Hi haurà problemes amb les reparacions si tens un sistema domèstic intel·ligent?',
    description: `
 Tot depèn del tractament i de la fase de reparació. És aconsellable notificar la disponibilitat del sistema abans que comencin les obres d'acabat per a tenir en compte la necessitat de cablejat. Els nostres experts l'ajudaran a instal·lar el sistema tant en la fase inicial de construcció d'una casa, com després d'instal·lar el sistema en una casa acabada. Els especialistes tindran en compte les característiques de la instal·lació del sistema i evitaran instal·lar elements innecessaris a la casa.

      
      `,
  },

  {
    id: 4,
    title:
      `Quines són les etapes de la instal·lació d'un sistema domèstic intel·ligent?`,
    description: `
 La instal·lació es duu a terme en dues etapes: la primera es completa una vegada acabats els treballs preliminars de construcció, inclòs el cablejat; la segona etapa té lloc una vegada acabats els treballs i inclou la connexió i configuració del sistema. En general, tot el procés d'instal·lació dura aproximadament una setmana, però el temps pot variar en funció de la complexitat i la quantitat de funcions necessàries.

      
      `,
  },

  {
    id: 5,
    title: `Quant dura el procés d'instal·lació d'una casa intel·ligent?`,
    description: `
 El procés d'instal·lació del sistema no durarà més de dues setmanes, sempre que es compleixin els següents requisits:
La finalització de l'acabat fi dels locals i l'absència d'altres treballs que puguin interferir amb la instal·lació.
Subministrament de clavegueram, electricitat i aigua en tota regla a l'habitació.
Accés lliure als quadres elèctrics que permeten desconnectar les línies d'alimentació a les quals està connectat l'equip.
Disponibilitat d'accés sense obstacles a tots els punts d'instal·lació dels components del sistema.
Mantenir una temperatura estable a l'habitació de +18° a +30°. Tenint en compte aquestes condicions, els especialistes instal·laran el sistema en el termini especificat, garantint l'eficiència i l'alta qualitat del treball.

      
      `,
  },

  {
    id: 6,
    title: `Què passarà amb el consum d'electricitat?`,
    description: `
 Per a sorpresa de molts dels nostres clients, els sistemes domèstics intel·ligents consumeixen molt poca electricitat, i fins i tot ajuden a reduir les factures (aproximadament un 30%) en alertar-los de despeses innecessàries. Per exemple, els sensors de moviment ajuden a evitar el consum innecessari d'electricitat. Però on més s'estalvia és en la gestió del sistema de climatització. A més del sistema domèstic intel·ligent, pot encarregar-nos panells solars per a obtenir amplis beneficis, ser respectuós amb el medi ambient, estalviar els seus propis diners i maximitzar el potencial de la seva llar.


      
      `,
  },

  {
    id: 7,
    title: `Què li passa a la casa quan s'apaga Internet?`,
   description: `Les cases intel·ligents tenen sistemes cablejats que funcionen independentment de la connexió a Internet. Si es perd la connexió a la xarxa, la ""casa intel·ligent"" continuarà funcionant com sempre gràcies a l'enginyeria subministrada, coordinada amb el funcionament dels electrodomèstics. Els únics sistemes que poden experimentar algunes limitacions són els sense fils. No obstant això, val la pena considerar la compra d'un sistema d'alimentació ininterrompuda per a casos de talls d'electricitat, que també és útil per a tots els electrodomèstics.

      
      `,
  },

  {
    id: 8,
    title: 'Es pot controlar manualment el sistema?',
    description: `Sí, és possible deixar el control del sistema en manera manual, especialment si un nen petit o una persona major necessiten garantir la seguretat. D'aquesta manera, es garanteix que l'estufa de la cuina no s'encendrà per error. No obstant això, gràcies a la interfície intuïtiva, podrà dominar fàcilment les funcions de control al llarg del dia. Pot triar una forma més senzilla d'utilitzar el sistema i utilitzar l'aplicació en el seu telèfon.
      `,
  },

  {
    id: 9,
    title: 'Llar intel·ligent: és un sistema només apte per a habitatges?',
    description: `
 Aquesta innovadora tecnologia és ideal per a edificis residencials, oficines, edificis corporatius i centres de negocis. El sistema pot oferir molts avantatges per al seu negoci, des d'un sistema de seguretat fiable fins al control a distància de la il·luminació, la calefacció, les cortines i les persianes. El millor és planificar i instal·lar aquest tipus de sistemes abans que comencin les reparacions o al més aviat possible. No obstant això, tant si es tracta d'una nova reforma com d'un local ja existent, els experimentats especialistes de Moderna Casa, versats en la instal·lació de tecnologies intel·ligents, faran tot el possible per minimitzar qualsevol conseqüència negativa de l'edifici i oferir-li la millor solució tenint en compte les particularitats de la seva situació.

      `,
  },
];

export const Interior = [
  {
    id: 1,
    title: `Per què no prescindir dels nostres serveis d'interior?`,
    description: `
 Un projecte de disseny d'interiors és una eina clau que l'ajuda a crear un espai harmoniós i funcional que s'adapti al seu estil de vida. Aquestes són algunes de les raons per les quals un projecte de disseny és essencial per a l'èxit d'una renovació i organització d'interiors.
El projecte de disseny ajuda a aprofitar al màxim l'espai disponible. Ofereix una col·locació intel·ligent de mobles, accessoris i àrees funcionals per a crear un espai còmode i pràctic que satisfaci les seves necessitats.
És important que l'interior reflecteixi el seu estil de vida i satisfaci les seves necessitats. Els dissenyadors tenen en compte les seves preferències, aficions i rutines diàries per a crear un interior funcional i agradable per a vostè i la seva família.
El projecte de disseny et permet avaluar per endavant tots els elements de l'interior i imaginar com quedaran en la realitat. Amb l'ajuda de visualitzacions i plans, pots veure diverses opcions de colors, materials, mobles i decoració i prendre decisions amb coneixement de causa.
Elaborar un projecte de disseny et permet determinar el cost de l'obra i planificar un pressupost de reparació amb major precisió. Podràs veure per endavant tots els costos associats a materials, mobiliari, acabats i serveis de disseny i controlar els costos durant el projecte.
El projecte de disseny ajuda a evitar errors durant les reparacions i permet als professionals tenir en compte tots els aspectes tècnics i detalls. Això minimitza la possibilitat de problemes inesperats i dolentes decisions, i redueix el temps i el cost de la reparació d'errors. La cooperació amb Moderna Casa farà que tots els processos siguin més còmodes i agradables.

      `,
  },
  {
    id: 2,
    title: `És car el disseny d'interiors?`,
    description: `
 Per descomptat, a l'hora de desenvolupar un projecte de disseny, tenim en compte el seu pressupost. Entenem que cada client té les seves pròpies capacitats i limitacions financeres, per la qual cosa el nostre objectiu és crear un interior que reflecteixi el seu gust i estil de vida i s'ajusti a les seves capacitats financeres. Ens esforcem per equilibrar estil, funcionalitat i assequibilitat per a crear un interior que no sols sigui estèticament agradable, sinó també econòmicament viable. El nostre equip té experiència treballant amb diferents pressupostos, per la qual cosa estem preparats per a oferir opcions que satisfacin qualsevol expectativa financera. Mentre treballem a l'interior i la visualització, també podem facilitar-li informació sobre el cost aproximat dels materials, el mobiliari i l'obra necessaris perquè es faci una idea dels possibles costos.

      
      `,
  },
  {
    id: 3,
    title: 'Què és el servei de control de la fase de disseny?',
    description: `
 
 La supervisió arquitectònica és una etapa essencial en l'execució d'un projecte de disseny, que garanteix la conformitat de les obres de reparació i construcció amb el projecte aprovat. Com a part de la supervisió de l'autor, el dissenyador i l'arquitecte controlen activament l'execució de tots els treballs i garanteixen que cada pas es correspongui amb la idea del projecte. Això inclou: Supervisar constantment el procés de construcció. La comprovació de la qualitat dels materials utilitzats. La precisió de l'execució de les solucions arquitectòniques. Els experts de Moderna Casa també responen puntualment a qualsevol pregunta o problema que sorgeixi i assessoren el client en qüestions relacionades amb el disseny i l'arquitectura.

     
     `,
  },

  {
    id: 4,
    title:
      `Quins són els 3 components principals del procés de disseny d'interiors?`,
    description: `
 El projecte de disseny inclou diversos components importants que ajuden a crear un concepte complet i detallat.
Un dels elements clau és la visualització en 3D, que li permet visualitzar cada detall del seu espai. Gràcies a aquesta tècnica, podem triar els millors materials i colors i avaluar la funcionalitat de la col·locació de mobles i altres elements, tenint en compte els seus desitjos.
La documentació tècnica inclou nombrosos plans i diagrames per a la correcta execució del projecte. Es tracta d'un pla de mesures, un pla de disposició del mobiliari i equipament, un pla d'instal·lació i desmuntatge, un pla de planta indicant el tipus de paviment, un pla de sostre, un pla d'ubicació d'endolls i interruptors, un pla de connexió d'instal·lacions amb un esquema de commutació, un programa d'acabat de parets, un mètode de connexió d'aparells sanitaris, escombrats de parets en banys i cuina, indicant la disposició dels taulells, així com plans de calefacció per sòl radiant i radiadors. A l'hora d'aplicar aquest apartat, també tenim en compte el marc legislatiu vigent a Espanya.
Un aspecte essencial del projecte de disseny és la selecció de materials. Tots els productes presentats en el nostre lloc web poden presumir d'alta qualitat i durabilitat. Proporcionem recomanacions i indiquem materials específics d'acabat, mobiliari i accessoris de lampisteria i il·luminació. A més, li convidem a utilitzar la nostra botiga en línia, on podrà adquirir aquests articles i materials. Tots aquests elements del projecte de disseny treballen junts per a crear un concepte d'interior complet i detallat, des de visualitzacions en 3D fins a documentació tècnica i selecció de materials, per a fer realitat els seus desitjos i crear l'espai perfecte que reflecteixi el seu estil i les seves preferències.
     
     `,
  },

  {
    id: 5,
    title: 'Interior en estil espanyol: com és?',
    description: `Mobles de disseny senzill en banús o caoba, butaques i cadires de cuir. L'estil espanyol a l'interior es distingeix pels colors clars i càlids i l'ús de materials naturals i elements de pedra, fusta i ceràmica. Els coixins decoratius i les veles creen un ambient acollidor. Els elements inclouen mobles d'època, antiguitats, fotos familiars i pintures murals. No hi ha paper pintat ni catifes, sinó parets pintades, rajoles i sòls de fusta. Són populars les bigues de fusta en el sostre i els armaris en nínxols. Hi ha una gran taula de menjador per als sopars familiars. La decoració inclou quadres amb marcs de fusta, plats de ceràmica, figures, gerros i veles. S'utilitzen taulells de ceràmica pintats a mà. Pot consultar-nos i li explicarem més coses sobre les característiques de l'interior dels països mediterranis. També creem projectes de disseny en altres estils i escoltem els seus consells i preferències.

     
     `,
  },

  {
    id: 6,
    title: `Quant dura el procés de desenvolupament d'un concepte d'interior?`,
    description: `Desenvolupar un projecte de disseny per a una casa particular a Espanya porta entre 25 i 45 dies i es realitza en paral·lel a la construcció. El dissenyador projectista realitza no sols el desenvolupament del concepte interior, sinó que també resol qüestions tècniques i d'enginyeria. Durant aquest temps, l'especialista fa les següents tasques:
Preparació de plans dimensionals i diagrames de sistemes d'enginyeria.
Creació d'una visualització interior en un ordinador i realització de collages.
Desenvolupament d'esquemes de disseny per al sostre, les parets i el sòl.
Disposició de l'ubicació de portes, endolls i llums.
Organització de calefacció per sòl radiant i aire condicionat.
Buscar i comprar els materials especificats en el projecte de disseny de la casa.
Registre de documentació tècnica i jurídica.
Coordinació de la construcció, control de terminis i qualitat del treball.

     `,
  },
];

export const Construction = [
  {
    id: 1,
    title: 'Quins tipus de cases particulars ens encarreguen amb més freqüència?',
    description: `A Espanya, els habitatges particulars criden l'atenció per les seves característiques, que difereixen dels objectes típics d'aquesta categoria en altres països de la UE. Els següents tipus de cases són populars aquí:
1. Les viles són cases espaioses de més de 100 m², amb àmplies parcel·les, piscines i zones de relax.
2. Les mansions són cases compactes de fins a 110 m² amb petites zones circumdants.
A Espanya se sol optar per l'estil mediterrani tradicional o l'estil d'alta tecnologia a l'hora de dissenyar cases particulars. Aquests estils presenten elements de l'arquitectura espanyola, com a finestres arquejades, voltes, mosaics, taulells de colors, etc.`,
  },
  {
    id: 2,
    title: 'Hi ha restriccions de construcció?',
    description: `Quan es construeixen habitatges privats a Espanya, es tenen en compte dos paràmetres principals: l'altura màxima permesa de l'edifici i les seves dimensions. Encara que la legislació defineix les fases de construcció, les normes es revisen sovint. Un terreny destinat a la construcció ha de tenir un document especial - Fitxa Urbanística, que conté les principals característiques permissibles dels objectes. Cada regió té també la seva pròpia normativa local que regula aspectes com:
1. Altura màxima dels edificis privats;
2. Disseny de les façanes;
3. Estil de la teulada i altres característiques.
Aquestes normes es fixen en el permís de construcció, sense el qual qualsevol construcció és il·legal.
`,
  },
  {
    id: 3,
    title:
      `Quins factors han de tenir-se en compte en planificar l'espai interior d'una casa?`,
    description: `Per a planificar l'espai a la perfecció, és important analitzar totes les característiques potencials del futur habitatge. Els nostres experts han preparat una llista de recomanacions per a vostè:
1. Els locals de la casa han de dividir-se en àrees funcionals (residencial, utilitària, pública, privada).
2. La disposició racional de les zones i les connexions convenients ajudaran a augmentar la comoditat i reduir l'ús de passadissos.
3. Les zones d'oci i de treball han d'estar separades entre si.
4. Les zones auxiliars han d'estar ocultes però accessibles en qualsevol moment.
5. Les zones privades, com els dormitoris, es recomana que estiguin aïllades i amagades de l'espai públic en racons allunyats.
6. Tingui en compte la composició de la família, els grups d'edat i l'estil de vida a l'hora de planificar el seu habitatge.
7. Eviti creuar zones brutes i netes per a prevenir la propagació de la brutícia. Per exemple, un garatge ha de tenir una sortida independent.
8. Combini estades de funcionalitat similar i zonifiqui-les amb ajuda d'elements interiors.
9. Es recomanen dues entrades a la casa: principal i addicional.
10. Tingui en compte les comunicacions futures a l'hora de desenvolupar un projecte. La distribució té realment molts detalls, i només especialistes experimentats podran abordar de manera competent la zonificació del futur habitatge. Recorrent a nosaltres, pot estar segur que tindrem en compte els seus detalls i farem que cada metre quadrat del seu espai sigui funcional, pensat, segur i ergonòmic.`,
  },
  {
    id: 4,
    title: 'Quines són les categories de sòl?',
    description: `Quan hagi seleccionat un terreny que s'adapti a la seva ubicació, grandària i preferències paisatgístiques, és recomanable que es posi en contacte amb un arquitecte professional i sol·liciti un informe sobre la categoria i les característiques del terreny seleccionat.
Cal distingir tres tipus de terrenys:
1. Sòl Urbà: Aquests terrenys es troben dins dels límits de la ciutat i són aptes per a un ràpid desenvolupament residencial. Ja existeixen carreteres i les comunicacions necessàries (electricitat, gas, aigua).
2. Sòl urbanitzable: la construcció en aquests terrenys només és possible després de la preparació preliminar i l'aprovació d'un pla detallat per part de l'ajuntament. Sovint, en aquestes zones existeixen requisits especials per a la protecció del medi ambient.
3. Sòl no urbanitzable: es troba en una zona protegida on està prohibida la construcció d'habitatges, i només es concedeix llicència per a això en casos excepcionals.
En realitzar una anàlisi, l'arquitecte ha de descriure clarament totes les restriccions que s'apliquen a un emplaçament concret. Per exemple, pot haver-hi requisits estrictes de distància entre cases veïnes, la prohibició de talar alguns arbres vells i dificultats per a connectar-se a l'electricitat, el gas i l'aigua corrent.
`,
  },
  {
    id: 5,
    title: 'Quant dura el procés de construcció?',
    description: `Segons l'experiència dels nostres experts, la construcció d'una casa a Espanya sol durar aproximadament 18 mesos i passa per diverses fases clau. Al principi del procés, és necessari seleccionar i comprar una parcel·la que compleixi els requisits per a la construcció. A continuació, s'elabora el projecte principal i es preparen pressupostos detallats per a l'execució de les obres. L'obtenció dels permisos necessaris de les autoritats locals i el pagament d'impostos en el municipi són també passos importants. La signatura d'un contracte amb una empresa contractista i la construcció directa inclouen les comunicacions, la construcció dels fonaments i la decoració interior. Al final del procés, es duu a terme una inspecció final per part de l'ajuntament per a obtenir el permís de funcionament, el registre dels documents de propietat i la connexió d'electricitat, aigua i enllumenat en el nou habitatge. Recorrent a nosaltres, l'ajudarem al fet que el procés sigui el més còmode i ràpid possible.
`,
  },

  {
    id: 6,
    title: 'Quins documents es necessiten per a iniciar la construcció?',
    description: `Quan l'ajuntament aprovi la construcció del seu habitatge particular, el seu arquitecte podrà començar a elaborar un pla detallat i estimar el cost dels materials i l'obra (PEM - El Pressupost d'Execució Material).
A continuació, haurà de trobar una empresa constructora de confiança amb la qual celebrar un contracte, per al que necessitarà els següents documents:
1. Llicència d'obra major de l'Ajuntament.
2. Documents que acreditin la propietat del terreny.
3. Comprovació de la viabilitat tècnica de la connexió a les comunicacions centralitzades.
4. Disseny detallat de l'edifici.
5. Fotocòpies del pla general d'ordenació de la zona.
6. Una acta en la qual s'indiquin els límits del solar i els edificis existents.
Després de rebre els pressupostos i permisos oficials, pot plantejar-se obtenir finançament d'un banc. No obstant això, cal recordar que l'obtenció d'un gran préstec que cobreixi tots els costos de construcció només està a l'abast dels particulars en algunes ocasions. Basant-se en la seva experiència en la construcció d'una casa a Espanya, moltes persones adverteixen que els préstecs per a aquesta mena de projectes a particulars poden concedir-se amb unes certes restriccions.
`,
  },
];

export const Investments = [
  {
    id: 1,
    title: 'Val la pena invertir en un segon habitatge?',
    description: `
 Les dades de preus del segon habitatge a Espanya per a gener de 2023, proporcionats per l'índex de preus immobiliaris Idealista, ens permeten analitzar la situació més de prop. De mitjana, els preus de l'habitatge secundari van pujar un 5,4%, aconseguint el nivell de 1.922 euros per metre quadrat. Malgrat això, el creixement del preu de l'habitatge a escala nacional es va mantenir al gener per sota de la taxa d'inflació, que va ser del 5,8%. Si ens fixem en les capitals d'Espanya, veiem que en 16 ciutats la pujada dels preus del segon habitatge ha superat la taxa d'inflació. Els creixements més significatius es van registrar a Santa Cruz de Tenerife (+18,7%), Alacant (+13,2%), València (+11,4%), Guadalajara (+11,3%), Cuenca (+10,8%), Palma (+9,9%), Sevilla (+8.1%), Màlaga (+8%), Segòvia (+9,9%), Pontevedra (+8,3%), Badajoz (+7,3%), Huelva (+7,2%), La Corunya (+6,8%), Tarragona (+6,6%), Granada (+6,3%) i Pamplona (+5,8%). A més, altres 30 capitals van experimentar pujades del preu de l'habitatge, però es van mantenir per sota de la taxa d'inflació. Entre aquestes ciutats, cal destacar els grans mercats de Madrid (+4,9%) i Barcelona (+3%). Gener de 2023 va portar canvis en els preus de les segones residències a Espanya; hi ha un augment dels preus. Per tant, decidir comprar una propietat de segona mà avui i revendre-la més tard pot proporcionar un excel·lent matalàs financer per a l'inversor.
      `,
  },

  {
    id: 2,
    title: 'Quins avantatges addicionals obté un inversor a Espanya?',
    description: `
 Espanya ofereix un dels programes més atractius per a obtenir un permís de residència (Permís de Residència) i, posteriorment, la nacionalitat. Per a acollir-se al programa, és necessari adquirir una propietat per valor d'almenys 500.000 euros i, després de viure al país durant 10 anys, podrà sol·licitar la ciutadania de la UE amb tots els beneficis associats. Espanya és un destí atractiu per a la inversió immobiliària, especialment per a països amb economies inestables. Aquest país ocupa el quart lloc en termes econòmics en l'Eurozona i el dotzè en l'economia mundial. Invertir aquí en béns immobles és rendible i fiable. Diversos experts estimen que la rendibilitat d'aquestes inversions oscil·la entre el 5 i el 15% anual. Especialment atractius per als inversors són els apartaments o cases adossades en centres turístics populars. Això es deu a l'enorme popularitat d'Espanya entre els turistes de tot el món. Aquí s'han sincronitzat amb èxit un clima excel·lent, una bella costa mediterrània, un alt desenvolupament econòmic i social i un ric patrimoni cultural. Tots aquests factors fan que Espanya sigui molt atractiva per a la inversió immobiliària.

      `,
  },
  {
    id: 3,
    title: 'Per què triar-nos per a invertir?',
    description: `
 Moltes persones decideixen invertir de manera independent, però necessiten més coneixements per a fer-ho amb seguretat i competència. Per això, és indispensable comptar amb un assistent de confiança. Ens especialitzem a desenvolupar i implementar solucions òptimes per a inversors a Espanya. El nostre objectiu és ajudar-lo a obtenir alts ingressos passius. Analitzem les perspectives econòmiques de la inversió immobiliària a Espanya i ajudem els nostres clients a invertir els seus diners de manera rendible. Ens esforcem per crear una cartera d'inversió amb una alta precisió estratègica, tenint en compte tots els riscos potencials per a l'inversor. Proporcionem una gamma completa de serveis per a garantir uns alts ingressos en el mercat immobiliari a Espanya. Més de la meitat dels nostres clients ens confien la gestió i administració complexa després de la finalització de la transacció. A més, supervisem regularment les innovacions legislatives per a garantir la seguretat dels seus fons i el compliment de les lleis.
 `,
  },

  {
    id: 4,
    title: 'Quines són les característiques dels immobles més rendibles?',
    description: `
 A l'hora de triar una propietat, hi ha criteris clau que han de tenir-se en compte. Un d'aquests criteris són les belles vistes panoràmiques de la mar o la naturalesa des de la finestra, que no han de subestimar-se. Un estudi de TripAdvisor va revelar que per al 82% dels viatgers, una vista inspiradora des de la seva finestra és la part més important del seu viatge. Per tant, atreure visitants i compradors d'habitatges vacacionals requereix un esforç important. A més, els apartaments urbans espanyols en altres zones es beneficien de serveis i d'una excel·lent infraestructura de transport. Consideri altres criteris a l'hora de triar un segon habitatge com a inversió immobiliària. Tant si lloga una propietat com si planeja vendre-la, és essencial comptar amb les característiques i comoditats adequades. Afegir elements essencials moderns, com a Wi-Fi ràpid, televisors d'alta definició i banys i espais comuns nets i elegants, pot augmentar enormement l'atractiu de les cases de vacances. En climes càlids, les terrasses amb menjador, la il·luminació ambiental o les habitacions addicionals solen afegir valor. A més, és important calcular amb precisió el cost de manteniment d'una casa per a garantir la rendibilitat de la inversió. Això inclou comptabilitzar les despeses de neteja, manteniment, jardineria i reparacions.

      `,
  },

  {
    id: 5,
    title: 'Inversions i Golden Visa: quines perspectives obren?',
    description: `El Golden Visa és un visat que permet als inversors de fora de la Unió Europea i als seus familiars convertir-se en residents a Espanya. Els seus familiars directes, inclosos cònjuges i fills menors d'edat, obtindran la residència a Espanya si vostè obté un Golden Visa. Això també s'aplica als fills adults dependents. Obtenir un Golden Visa a Espanya li dona l'oportunitat de viure al país i accedir a molts beneficis dels quals només poden gaudir els residents a Espanya. Qui pot sol·licitar un Golden Visa a Espanya? Pot sol·licitar aquest visat qualsevol persona que compri un habitatge per un valor superior a 500.000 euros, sempre que el sol·licitant aporti almenys aquesta quantitat amb fons propis, hagi invertit en bons de l'Estat o tingui accions o dipòsits bancaris per un valor superior a 1.000.000 d'euros. També poden sol·licitar aquest visat les persones que duguin a terme projectes empresarials a Espanya que siguin d'interès públic. Això s'aplica a tots els projectes que creïn llocs de treball i tinguin un impacte socioeconòmic en l'àrea geogràfica on s'executen, així com a tots els projectes que contribueixin a la innovació científica o tecnològica al país. Durant quant temps és vàlid el Golden Visa a Espanya? Amb aquest visat, pot residir a Espanya durant almenys un any. Després d'aquest període, pot sol·licitar un permís de residència vàlid durant 2 anys. Subjecte a la inversió i a altres requisits, el període màxim de validesa d'un permís de residència és de 5 anys.
      `,
  },
];

export const ModularHouses = [
  {
    id: 1,
    title: 'Quant es triga a muntar una casa modular?',
    description: `
 La construcció ràpida i còmoda de les cases modulars atreu a molta gent. Aquestes cases es munten a partir de grans peces de parets i sostres, que es lliuren en l'obra. El muntatge dura entre dues setmanes i un mes i mig, després de la qual cosa comença l'acabat i la instal·lació dels equips d'enginyeria. Els mòduls prefabricats que contenen mitja habitació o diverses habitacions aïllades es lliuren en plataformes de càrrega especials i s'instal·len sobre uns fonaments ja preparats. La instal·lació expressa dura entre dos i deu dies, després dels quals la casa està llesta per a ser ocupada. Les cases modulars són ideals per als qui volen estar oberts a més d'un lloc i prefereixen desplaçar-se. Per exemple, si ha de viatjar amb freqüència per motius de treball, pot mudar-se sense preocupar-se de trobar una nova llar. La seva casa sempre serà aquí, com una petxina de caragol. El muntatge d'una casa modular sol començar col·locant el primer mòdul sobre els fonaments i assegurant-ho. A continuació es deixa caure el següent mòdul i es fixa a l'anterior, i així successivament. Després del muntatge, se segellen les juntes i es procedeix a la instal·lació de la teulada.

      `,
  },

  {
    id: 2,
    title: 'Quina és la resistència i durabilitat de les cases modulars?',
   description: `La durabilitat de les cases modulars depèn de diversos factors, com la tecnologia, els materials i el control de qualitat utilitzats. En triar-nos, pot estar segur que només utilitzem materials d'alta qualitat i treballem amb integritat. Les estructures principals d'una casa poden durar més de 50 anys, una quantitat de temps impressionant. La vida útil de l'aïllament és una mica més difícil de determinar, ja que no existeix una pràctica a llarg termini d'ús de materials moderns d'aïllament tèrmic. No obstant això, sempre que no hi hagi humitats, cal suposar que l'aïllament durarà uns 20-25 anys. Aquestes estadístiques es poden seguir en els territoris d'Espanya, on no hi ha molta humitat ni pluja. És important tenir en compte que el desgast físic rares vegades provoca que una casa quedi inutilitzable. Per contra, una casa d'aquest tipus pot quedar moralment obsoleta, i els propietaris poden decidir vendre-la o reconstruir-la per a adaptar-la a les necessitats i gustos moderns. Per tant, pot posar-se en contacte amb nosaltres en qualsevol moment per a renovar els seus metres quadrats.
      `,
  },

  {
    id: 3,
    title: 'Quina popularitat tenen les cases modulars a Espanya?',
    description: `
 Les cases modulars han guanyat popularitat a Europa en els últims anys, i el seu atractiu no sols es deu a la reducció de costos, sinó també a l'oportunitat d'oferir una solució per a superar la crisi econòmica provocada per la pandèmia del COVID-19. Espanya és el país europeu amb major ús de cases modulars. No obstant això, la història de les cases modulars es remunta a la postguerra. Va ser llavors quan va aparèixer aquest tipus d'habitatge, que es construïa de manera ràpida i barata per a satisfer l'enorme demanda d'espai habitable. El terme ""modular"" significa que la casa es construeix a partir de mòduls individuals. Cada mòdul es crea de manera independent. La major part del treball es realitza a la fàbrica, on els mòduls individuals es fabriquen amb diversos materials. A continuació, aquests mòduls es lliuren en el terreny del comprador i finalment s'assemblen per a crear una casa in situ. Aquesta tecnologia és molt popular a Espanya, ja que el clima benigne i les característiques del sòl, les condicions meteorològiques i altres factors permeten despreocupar-se de la necessitat de fonaments i fortificacions addicionals.
      `,
  },
  {
    id: 4,
    title: 'Quins mites sobre les cases modulars cal desmentir?',
    description: `""Les cases modulars semblen de fórmula"". Quan es construeix una casa nova des de zero, és possible dissenyar-la per complet, tenint en compte tots els desitjos del futur propietari. No obstant això, en el cas de les cases modulars, la resposta no és tan clara. La complexitat de l'execució de la idea, la qualitat dels acabats i la superfície afectaran el cost de l'habitatge. Encara que l'acabat segons els desitjos del client és possible, les empreses solen oferir models ja fets. Si es desvia del disseny clàssic, el preu pot augmentar, i el temps de creació també.
""És impossible ampliar la casa"". Tant les cases modulars com les tradicionals poden ampliar-se si l'estructura, les lleis i l'emplaçament ho permeten. Moltes cases modulars estan dissenyades de tal forma que es poden afegir i ampliar mòduls. Això facilita afegir espai extra. Ampliar una casa convencional és un procés més complex i llarg.
""Les cases modulars no tenen a veure amb la resistència i fiabilitat"". Amb tots els requisits i un manteniment regular, les cases modulars poden durar de 50 a 70 anys, igual que les cases construïdes tradicionalment.
""Una simple caixa sense trets distintius"". Abans, les cases modulars es consideraven massa simples en aparença. Avui, no obstant això, representen un exemple d'estil modern. Les cases tradicionals poden tenir qualsevol aspecte, depenent del gust i les preferències estilístiques dels seus propietaris.
 `,
  },

  {
    id: 5,
    title:
      'Quins són els principals avantatges de les cases modulars a Espanya?',
    description: `
 Una dels grans avantatges de les cases modulars és l'absència de terra d'obra. La major part del treball es realitza fora de l'obra. Quan els mòduls es lliuren en l'obra, simplement s'assemblen entre si, i això no implica una gran quantitat d'enderrocs o brutícia. Això significa que vostè no ha de preocupar-se pels residus de construcció dispersos i la neteja posterior a la construcció. En segon lloc, les cases modulars solen atreure pel seu respecte al medi ambient i la seva eficiència energètica. Això es deu a l'optimització de la producció i l'ús de materials, tenint en compte factors mediambientals. Gràcies a això, les cases modulars poden ser més eficients des del punt de vista energètic i més respectuoses amb el medi ambient. A més, aquest enfocament de construcció redueix els residus i utilitza els recursos de forma més eficient. Les cases modulars ofereixen una gran varietat i qualitat dels materials utilitzats. Per exemple, el metall i el formigó són els materials més populars per a la construcció de cases modulars a Espanya. Les cases modulars a Espanya es consideren estructures temporals que no afecten la geodèsia del lloc. Un altre avantatge de les cases modulars és la mínima burocràcia durant la seva construcció. Per a construir una casa d'aquest tipus no és necessari obtenir permisos ni llicències, com ocorre amb les cases tradicionals. Això significa que pot construir una casa modular en gairebé qualsevol solar, encara que no tingui permís de construcció. Això li dona més flexibilitat i simplifica l'elecció de l'emplaçament de la seva futura casa.


     `,
  },
];

// export const Projects = [
//   {
//     id: 1,
//     title: 'Quins tipus de serveis oferim?',
//     description:
//       'Oferim una àmplia gamma de serveis de construcció i inmobles, incloent però no limitats a: construcció, disseny d'interiors, habitatges modulars, piscines, energia solar, llars intel·ligents, Immobiliària, inversions i consultoria.',
//   },
// ];
