export const aboutListLocation = 'Catalogne, Espagne';

export const infoText = 'Spectaculaire villa ensoleillée en Espagne';

export const mainText =
  'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.';

export const headerBtnText = "Plus à l'intérieur";

export const titleAbout = 'À propos du projet';

export const textsAbout = {
  text: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
};

export const textPhoto =
  "Les accessoires pour la maison intelligente sont conçus pour rendre votre maison plus pratique, plus efficace et plus agréable. Des thermostats intelligents aux systèmes d'éclairage en passant par les assistants vocaux et les caméras de sécurité à commande vocale, il existe une grande variété d'accessoires disponibles pour vous aider à automatiser et à contrôler différents aspects de votre maison";
export const view = 'Vue';


export const housesDetailsMobile = [
  {
    number: 1,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 2,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 3,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 4,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 5,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
];

export const housesDetailsDesktop = [
  {
    number: 1,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 2,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 3,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 4,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 5,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },

  {
    number: 6,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 7,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 8,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 9,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
  {
    number: 10,
    text: "Une fois que la maison aura obtenu un certificat de fin de travaux délivré par le conseil municipal et approuvé par votre agent, vous pourrez vous détendre sur le canapé que vous avez soigneusement choisi et admirer votre merveilleuse nouvelle maison. La période de défauts - pendant laquelle l'entrepreneur est tenu de corriger tous les défauts - durera normalement 12 mois.",
  },
];

export const photoTitle = 'Photo';