import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import { servicesApi } from '../../api';
import { leadFormQuestionsApi } from '../../api/leadFormQuestionsApi';
import { formsApi } from '../../api/formsApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware().concat(
      [servicesApi.middleware],
      [leadFormQuestionsApi.middleware],
      [formsApi.middleware],
    ),
});

export default store;
