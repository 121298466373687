import React, { FC } from 'react';
import { MapBlock } from '../MapBlock';
import { ContactsMapWrapper } from './ContactsMapSection.styled';

export const ContactsMapSection: FC = () => {
  const firstPosition: [number, number] = [41.3749, 2.1756]; //position that will be shown on the map
  const zoom = 13;
  return (
    <ContactsMapWrapper>
      <MapBlock position={firstPosition} zoom={zoom} />
    </ContactsMapWrapper>
  );
};
