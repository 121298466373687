import styled from 'styled-components';
import { colorVariables } from '../../../theme/Theme.constants';
import { Field } from 'formik';


export const Input = styled(Field)`
  width: 100%;
  padding: 1rem 1.25rem;
  background: ${colorVariables.alternativeBackground};
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  outline: none;
  color: ${colorVariables.alternativeTextColor};
  &:placeholder {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;

    color: rgba(255, 255, 255, 0.3);
  }
`;