import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CaseHeaderSection } from '../../shared/components/CaseHeaderSection/CaseHeaderSection';
import { Breakpoints } from '../../theme/Theme.constants';
import mobileBgImage from '../../shared/assets/images/interior-case/mobile-bg.jpg';
import tabletBgImage from '../../shared/assets/images/interior-case/tablet-bg.jpg';
import desktopBgImage from '../../shared/assets/images/interior-case/desktop-bg.jpg';
import imgAbout from '../../shared/assets/images/interior-case/about-img.jpg';

import {
  InteriorCaseWrapper,
  AboutSectionWrapper,
  HousesDetailsSectionWrapper,
  DetailsBlock,
  PhotoSectionWrapper,
} from './InteriorCasePage.styled';
import { CaseAboutSection } from '../../shared/components/CaseAboutSection';
import { CaseHousesDetailsSection } from '../../shared/components/CaseHousesDetailsSection';
import {
  imageList,
  imageListMobile,
  getHouseDetails,
} from './InteriorCasePage.constants';
import imageDetails from '../../shared/assets/images/interior-case/details-image.png';
import { CasePhotoSection } from '../../shared/components/CasePhotoSection';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { useTranslation } from 'react-i18next';

export const InteriorCasePage: FC = () => {
  const getTitle = getMenuItems();
  const { t } = useTranslation();
  const { housesDetailsDesktop, housesDetailsMobile } = getHouseDetails();
  const aboutList = [
    {
      id: 1,
      aboutInfo: t('interiorCasePage.aboutListLocation'),
    },
    {
      id: 2,
      aboutInfo: '120.00 m2',
    },
    {
      id: 3,
      aboutInfo: '€ 15 500',
    },
  ];
  const texts = [
    {
      id: 1,
      text: t('interiorCasePage.textsAbout.text'),
    },
    {
      id: 2,
      text: t('interiorCasePage.textsAbout.text'),
    },
    {
      id: 3,
      text: t('interiorCasePage.textsAbout.text'),
    },
  ];

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.lg}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTabletMaxWidth = useMediaQuery({
    query: `(max-width: ${Breakpoints.xlg - 1}px)`,
  });
  return (
    <InteriorCaseWrapper>
      <CaseHeaderSection
        title={getTitle[5].label}
        aboutList={aboutList}
        infoText={t('interiorCasePage.infoText')}
        mainText={t('interiorCasePage.mainText')}
        btnText={t('interiorCasePage.headerBtnText')}
        isViewBtn={isDesktop}
        bgImage={
          !isDesktop
            ? isMobile
              ? mobileBgImage
              : tabletBgImage
            : desktopBgImage
        }
      />

      <AboutSectionWrapper>
        <CaseAboutSection
          title={t('interiorCasePage.titleAbout')}
          image={imgAbout}
          texts={texts}
        />
      </AboutSectionWrapper>
      <HousesDetailsSectionWrapper>
        <CaseHousesDetailsSection
          housesDetails={
            isTabletMaxWidth ? housesDetailsMobile : housesDetailsDesktop
          }
          image={imageDetails}
          Block={DetailsBlock}
        />
      </HousesDetailsSectionWrapper>

      <PhotoSectionWrapper>
        <CasePhotoSection
          imageList={isMobile ? imageListMobile : imageList}
          text={t('interiorCasePage.textPhoto')}
        />
      </PhotoSectionWrapper>
    </InteriorCaseWrapper>
  );
};
