export const navItems = {
  preConstruction: ' Попереднє будівництво',
  process: 'Процес',
};

export const description =
  'Завдяки багатому досвіду та якісній реалізації проектів капітального будівництва ми гарантуємо кожному нашому клієнту професійний підхід та найкращу цінову політику. Ми закуповуємо перевірені будівельні матеріали за конкурентними цінами, надаємо звіти по кожному етапу, грамотно виконуємо весь комплекс робіт з будівництва будинків з цегли, пінобетону, газобетону, залізобетону, блоків, з використанням металоконструкцій та інших несучих елементів будівлі. Букінгемський палац, вежа Рапунцель або рухомий замок Хаула - ми можемо реалізувати будь-яке ваше архітектурне побажання і побудувати будинок під ключ.';


export const preConstructionTitle = 'Передпроектне планування';
export const preConstructionText =
  "Ми прагнемо створити не просто будинок, а справжній куточок щастя і затишку, де ви зможете насолоджуватися кожною хвилиною. Ми не обмежуємося створенням типових будинків, ми допомагаємо втілити ваш унікальний стиль життя на власній ділянці. Тому ми беремося за будь-який проект і знаємо, що немає нічого неможливого. Ваша архітектурна фантазія може стати реальністю вже сьогодні - просто зверніться до нас. Ми знайдемо найкращі рішення для вашого майбутнього будинку, і разом з вами розробимо ідеальний варіант, який стане втіленням ваших мрій і бажань";

export const processList = {
  labelFirst: 'Увага до навколишнього середовища',
  descriptionFirst:
    'Ми прагнемо використовувати органічні матеріали, які не шкодять природі. Дешеві замінники та хімічні продукти для нас табу. Ми дбаємо не тільки про навколишнє середовище, а й про наших клієнтів, які будуть жити, творити, працювати, спати і приймати гостей в будинку.',

  labelSecond: 'Досвід і професіоналізм',
  descriptionSecond:
    'Наша команда складається з висококваліфікованих фахівців, які мають досвід роботи з різними типами та стилями будівництва. Ми знаємо, які матеріали і технології найкраще використовувати для кожного конкретного проекту.',

  labelThird: 'Комплексна робота',
  descriptionThird:
    'Ми надаємо послуги з будівництва будинків "під ключ", а це означає, що ми беремо на себе всі етапи робіт - від проектування до введення в експлуатацію. Це дозволяє нам забезпечити високий рівень якості робіт і гарантувати дотримання термінів.',

  labelFourth: 'Якість матеріалів',
  descriptionFourth:
    'Ми використовуємо тільки високоякісні матеріали та комплектуючі від перевірених постачальників, що гарантує довговічність і надійність вашого будинку. Ми працюємо тільки з перевіреними постачальниками, щоб гарантувати довговічність кожного квадратного метра вашого будинку.',
  labelFifth: 'Індивідуальний підхід',
  descriptionFifth:
    "Ми хочемо зрозуміти потреби кожного нашого клієнта і задовольнити їх. Будинок для вечірок, таунхаус для літньої пари або вілла для молодої сім'ї з дітьми - кожен клієнт має індивідуальні потреби. Ми враховуємо ваші побажання і вимоги, а також допомагаємо вибрати найкращі рішення для вашого проекту. Ми працюємо з кожним клієнтом індивідуально, щоб створити будинок, який відповідає всім його потребам і перевагам.",
  labelSixth: 'Контроль якості',
  descriptionSixth:
    'Ми суворо контролюємо якість всіх етапів робіт, від підбору матеріалів до монтажу обладнання. Це дозволяє нам забезпечити високий рівень якості робіт і гарантувати дотримання всіх норм і вимог. Ми надаємо звітність по кожному етапу і гарантуємо прозорість.',
};

export const aboutTexts = {
  textFirst: 'Ми встановлюємо нові стандарти на ринку.',

  textSecond:
    ' Для нас кожен будинок – це не просто квадратні метри, а затишний, <br/> продуманий до дрібниць простір, де хочеться жити,  <br/> насолоджуватися і по праву називати це місце «домом».',

  textThird:
    "Ми будуємо будинки, які асоціюються з найкращими моментами життя - <br/> першим словом вашої дитини, пропозицією вийти заміж,  <br/> найтеплішим вечором у колі сім'ї.",

  textFourth: 'Ми будуємо будинки, які стають вашим життям.',

  textFifth:
    'Головна цінність для нас - ваша посмішка, головна нагорода - довіра.',
};

export const constructionStepBlockTitle = ' Етапи будівництва';
export const constructionStepBlockDescription = ' Первинна консультація';

export const constructionStepSection = {
  
 stepOne: {
    title: 'Крок перший',
    p1: `Знайомство. Архітектор ближче знайомиться з замовником та об'єктом нерухомості або земельною ділянкою. На цьому початковому етапі архітектор спілкується з майбутнім замовником, з'ясовує його потреби, вимоги та очікування від майбутньої будівлі.`,
    p2: "Важливі всі найтонші нюанси: спосіб життя замовника, наявність дітей чи тварин, індивідуальні вподобання. Він також вивчає місце розташування об'єкта, аналізує місцевий контекст і навколишнє середовище, щоб визначити фактори, які впливають на дизайн будівлі",
  },
  stepTwo: {
    title: 'Крок другий',
    p1: `Призначення об'єкта. Архітектор визначає основні побажання клієнта щодо дизайну та функціональності будівлі. Це може включати визначення типу будівлі (житлова, комерційна, громадська) та стилю дизайну (класика, модерн, мінімалізм). `,
    p2: "Якщо об'єкт будується для подобової оренди, ймовірно, розміщенню та функціональності кухні не доведеться приділяти надто багато уваги",
  },
  stepThree: {
    title: 'Крок третій',
    p1: `Дослідження ділянки. Тут архітектор знайомиться з територією, щоб зрозуміти її обмеження та можливості`,
    p2: "Це може включати аналіз рельєфу, складу ґрунту та кліматичних умов, а також перевірку наявності комунікацій та інфраструктури",
  },
  stepFour: {
    title: 'Крок четвертий',
    p1: `Аналіз правової складової. Визначення можливості вирішення завдань будівництва або реконструкції в рамках чинного законодавства та норм проектування.`,
    p2: "На цьому етапі архітектор визначає, наскільки реалістичним і здійсненним є проект з урахуванням вимог законодавства і норм проектування. Він також визначає вартість та ресурсоємність проекту",
  },
  stepFive: {
    title: "Крок п'ятий",
    p1: `Робота з документацією. Подача документів та отримання дозволу на будівництво або реконструкцію, отримання технічних умов від власників зовнішніх мереж водопостачання, газопроводу, електромереж.`,
    p2: ``,
  },
  stepSix: {
    title: 'Крок шостий',
    p1: `Функціональні рішення. Розробка архітектурної концепції - наступний крок після отримання дозволів та побажань замовника. На цьому етапі архітектор створює загальне уявлення про об'єкт, що проектується, включаючи визначення функціонального зонування та створення просторової схеми`,
    п2: `Створення концепції дає можливість оцінити потенціал об'єкта і зрозуміти, наскільки він буде відповідати побажанням замовника у функціональному аспекті питання. Чи буде йому комфортно жити в будинку з сім'єю або наскільки популярним буде об'єкт для оренди`,
  },
  stepSeven: {
    title: "Крок сьомий",
    p1: `Розробка ескізного проекту. Архітектор визначає архітектурні, конструктивні, функціональні та художні рішення об'єкта будівництва або реконструкції.`,
    p2: "Ескізний проект дозволяє замовнику побачити загальний вигляд об'єкта та оцінити візуальну відповідність своїм побажанням",
  },
  stepEight: {
    title: 'Крок восьмий',
    p1: `Розробка робочого проекту. Це завершальний етап архітектурного проектування, який включає в себе повний пакет проектної документації. В рамках розробки робочого проекту фахівець враховує архітектурний розділ, конструктивний розділ, каналізацію, водопровід, сантехніку, електрообладнання, вентиляцію, кондиціонування, опалення та інші складові.`,
    p2: "Робочий проект містить детальний опис усіх конструктивних, технічних та інженерних рішень, необхідних для будівництва або реконструкції об'єкта",
  },
};
