import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';
// import bgImg from '../../../shared/assets/images/construction/construction-bg.jpg';
import bgImgDesktop from '../../../shared/assets/images/construction/construction-bg-desktop.jpg';

export const Wrapper = styled.div`
  position: relative;
  background-image: url(${bgImgDesktop});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 260% 100%;
  height: 100vh;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(23, 23, 25, 0.3);
  }

  @media (min-width: ${Breakpoints.md}px) {
    background-size: cover;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    
    background-size: cover;
    height: 110vh;
  }
`;

export const CenterWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 5;

  background: rgba(23, 23, 25, 0.2);

  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  backdrop-filter: blur(0.625rem) !important;

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    align-items: center;
    margin: 0 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin: 0 6.25rem;
  }
`;

export const TitleWrapper = styled.div`
  padding: 1.562rem 1.25rem;

  @media (max-width: ${Breakpoints.md}px) {
    border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  }
  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.125rem 0.625rem;
    width: 80%;
    border-right: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 2.812rem 0rem 2.812rem 2.187rem;
    border: none;
    width: 60%;
  }
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;

  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);

  @media (min-width: ${Breakpoints.md}px) {
    text-align: left;
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const TextWrapper = styled.div`
  padding: 1rem 1.25rem 2.187rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.25rem;
    width: 100%;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 1.875rem 4.062rem;
    border-left: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  }
`;

export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25rem;

  opacity: 0.8;

  @media (min-width: ${Breakpoints.xxl}px) {
    font-size: 16px;
    line-height: 1.875rem;
  }
`;
