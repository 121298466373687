import styled from 'styled-components';
import { colorVariables } from '../../../theme/Theme.constants';

export const TextField = styled.input<{ isError: any, value:any }>`
  width: 100%;
  height: 100%;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid ${colorVariables.blockBorderColor};
  padding: 0rem 1.25rem;
  padding-bottom: ${props => (props.isError ? '1rem' : 0)};
  color: ${colorVariables.alternativeTextColor};
  outline: none;

  &:placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;
