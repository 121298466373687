export const TechnologiesSection = {
  title: 'Technology',
  description:
    'Compass Pools: where technology meets perfection. Carbon ceramic innovation, self-cleaning Vantage system, easy installation. Future-ready, eco-friendly pools. Choose us for seamless installation and cutting-edge functionality.',
  gelcoatAndColor: 'Gelcoat and Colour',
  closedBeam: 'Closed beam',
  vinylesterBase: 'Vinylester base',
  balsaReinforcement: 'Balsa reinforcement',
  vinylester: 'Glass fibre reinforced vinylester',
  glassFiberMats: 'Glass fiber mats',
  ceramicLayer: 'Ceramic layer',
  polyesterLayer: 'Polyester layer',
  carbonFibreMats: 'Carbon fibre mats',
  exteriorTopCoat: 'Exterior top coat',
  gelcoatAndColorDescription:
    "The gelcoat layer, originally developed for luxury yacht surfaces, serves as the top protective barrier for Compass Pools. It exhibits exceptional resistance to UV radiation and mechanical damage. Our coloring system, part of one of the world's most advanced gelcoat systems, ensures that Compass Pools maintain their high-quality appearance even after many years of use.",

  closedBeamDescription:
    'The closed beam, extending along the entire edge of the pool, guarantees exceptional strength and stability, while maintaining the necessary flexibility. This is crucial during transportation and installation, ensuring the pool retains its shape flawlessly. With a closed beam design, our pools provide unmatched durability and structural integrity.',
  vinylesterBaseDescription:
    'Our pools are equipped with a high-quality vinylester resin base, serving as the initial water-resistant barrier. This layer is applied over the gelcoat, effectively preventing the occurrence of osmosis.',

  balsaReinforcementDescription:
    'The outer layer of our pool features balsa wood reinforcement, known for its lightweight properties. With its strategic placement and ample thickness, it provides sufficient strength, eliminating the need for additional reinforcements.',

  vinylesterDescription:
    'Our pools feature a second waterproof barrier reinforced with glass fiber, providing optimum flexibility and strength. This combination ensures our pools are durable and resilient, allowing for long-lasting enjoyment.',

  glassFiberMatsDescription:
    'Our pools feature a robust second waterproof barrier, reinforced with glass fiber, to provide unparalleled flexibility and strength. The incorporation of glass fiber ensures the utmost durability and structural integrity of our pools.',

  ceramicLayerDescription:
    'Compass Pools features a state-of-the-art ceramic layer, developed in Australia and the USA. Originally designed for NASA projects, it guarantees 100% osmosis protection, ensuring the durability and reliability of our pools.',

  polyesterLayerDescription:
    'The polyester layer, situated atop the ceramic layer, forms the core component of our composite pool. Once this layer has fully hardened, the pool acquires excellent mechanical properties, ensuring its long-lasting durability and strength.',

  carbonFibreMatsDescription:
    'We utilize carbon fiber mats, a lightweight and robust material commonly found in sports equipment and aircraft industry, to reinforce the most critical parts of our pools. This integration ensures that Compass pools are exceptionally strong and maintain consistent shape, setting them apart in the market.',

  exteriorTopCoatDescription:
    'Specially designed to withstand the elements, this advanced coating provides a protective layer that keeps your pool looking stunning for years to come. With its superior resistance to UV radiation, chemicals, and weathering, our exterior top coat ensures that your pool retains its vibrant color and flawless finish, making it the perfect choice for outdoor pool installations.',
};

