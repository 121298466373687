import React, { FC, useEffect } from 'react';
import {
  Title,
  AfterTitle,
  TextWrapper,
  FormWrapper,
  CommunicationBlock,
  FieldBlock,
  RadioGroupBlock,
  CommunicationInfoBlock,
  CommunicationTitle,
  FormControlLabelBlock,
  CommunicationTitleBlock,
  NumberBlock,
  Number,
  Edit,
} from './ContactedBlock.styled';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { Radio } from '@mui/material';
import { AcceptButton, ControlsBlock } from '../LeadForm.styled';
import { BluredButton } from '../../../shared/components/BluredButton';
import { colorVariables } from '../../../theme/Theme.constants';
import { ReactComponent as WhatsAppIcon } from '../../../shared/assets/icons/lead-forms//whats-app-icon.svg';
import { LeadFormDataProps } from '../LeadForm';
import { getMainNavbarItems } from '../LeadForm.constants';

interface ContactedBlockProps {
  setCurrentNavItem: (currentNavItem: string) => void;
  setLeadFormData: (leadFormData: LeadFormDataProps) => void;
  leadFormData: LeadFormDataProps;
  handleSendForm: () => void;
}

export const ContactedBlock: FC<ContactedBlockProps> = ({
  setCurrentNavItem,
  setLeadFormData,
  leadFormData,
  handleSendForm,
}) => {
  const { t } = useTranslation();
  const formsNavigationItems = getMainNavbarItems();

  const initialValues = {
    preferred_contact_method: 'whats_app',
  };

  const handleChangeContactMethod = (value: string) => {
    setLeadFormData({
      ...leadFormData,
      preferred_contact_method: value,
    });
  };

  return (
    <>
      <TextWrapper>
        <Title>{t('leadForm.leadFormPage.contactedTitle')}</Title>
        <AfterTitle>
          {t('leadForm.leadFormPage.contactedDescription')}
        </AfterTitle>
      </TextWrapper>

      <Formik initialValues={initialValues} onSubmit={handleSendForm}>
        {({ errors, touched, values }) => (
          <FormWrapper>
            <FieldBlock name="preferred_contact_method">
              {({ field }: any) => (
                <RadioGroupBlock
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}>
                  <CommunicationBlock>
                    <CommunicationInfoBlock>
                      <WhatsAppIcon />
                      <CommunicationTitleBlock>
                        <CommunicationTitle>Whatsapp</CommunicationTitle>
                        {leadFormData.phone_number && (
                          <NumberBlock>
                            <Number>{leadFormData.phone_number}</Number>
                            <Edit
                              onClick={() =>
                                setCurrentNavItem(formsNavigationItems[2].title)
                              }>
                              {t('leadForm.leadFormPage.edit')}
                            </Edit>
                          </NumberBlock>
                        )}
                      </CommunicationTitleBlock>
                    </CommunicationInfoBlock>

                    <Radio
                      value="whats_app"
                      onClick={() => handleChangeContactMethod('whats_app')}
                      sx={{
                        color: `rgba(255, 255, 255, 0.2)`,
                        '&.Mui-checked': {
                          color: colorVariables.alternativeTextColor,
                        },
                      }}
                    />
                  </CommunicationBlock>
                  <CommunicationBlock>
                    <CommunicationInfoBlock>
                      <WhatsAppIcon />
                      <CommunicationTitle>
                        {t('leadForm.leadFormPage.phoneCall')}
                      </CommunicationTitle>
                    </CommunicationInfoBlock>

                    <Radio
                      value="phone_call"
                      onClick={() => handleChangeContactMethod('phone_call')}
                      sx={{
                        color: `rgba(255, 255, 255, 0.2)`,
                        '&.Mui-checked': {
                          color: colorVariables.alternativeTextColor,
                        },
                      }}
                    />
                  </CommunicationBlock>

                  <CommunicationBlock>
                    <CommunicationInfoBlock>
                      <WhatsAppIcon />
                      <CommunicationTitle>
                        {t('leadForm.leadFormPage.emailType')}
                      </CommunicationTitle>
                    </CommunicationInfoBlock>

                    <Radio
                      value="email"
                      onClick={() => handleChangeContactMethod('email')}
                      sx={{
                        color: `rgba(255, 255, 255, 0.2)`,
                        '&.Mui-checked': {
                          color: colorVariables.alternativeTextColor,
                        },
                      }}
                    />
                  </CommunicationBlock>

                  <CommunicationBlock>
                    <CommunicationInfoBlock>
                      <WhatsAppIcon />
                      <CommunicationTitle>
                        {t('leadForm.leadFormPage.bookMeeting')}
                      </CommunicationTitle>
                    </CommunicationInfoBlock>

                    <FormControlLabelBlock
                      onClick={() => {
                        handleChangeContactMethod('meeting');
                        window.open(
                          'https://calendly.com/d/ytd-cwd-nyr/30min',
                          '_blank',
                        );
                      }}
                      value="meeting"
                      control={
                        <Radio
                          sx={{
                            display: 'none',
                            color: `rgba(255, 255, 255, 0.2)`,
                            '&.Mui-checked': {
                              color: colorVariables.alternativeTextColor,
                            },
                          }}
                        />
                      }
                      label={t('leadForm.leadFormPage.schedule')}
                    />
                  </CommunicationBlock>
                </RadioGroupBlock>
              )}
            </FieldBlock>
            {errors.preferred_contact_method &&
              touched.preferred_contact_method && (
                <div>{errors.preferred_contact_method}</div>
              )}
            <ControlsBlock>
              <BluredButton
                title={t('leadForm.leadFormPage.backButton')}
                handleClick={() =>
                  setCurrentNavItem(formsNavigationItems[2].title)
                }
              />

              <AcceptButton type="submit" onClick={() => {}}>
                {t('leadForm.leadFormPage.sendButton')}
              </AcceptButton>
            </ControlsBlock>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
