import React, { FC, useState } from 'react';

import {
  Menu,
  MenuItem,
  MenuItemTitle,
  MenuSectionWrapper,
} from './MenuSection.styled';
import { MenuItemProps } from './MenuSection.types';
import { MenuListBlock } from '../MenuListBlock';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../theme/Theme.constants';
import { BackgroundImageBlock } from '../BackgroundImageBlock';
import { useNavigate } from 'react-router';
import { mainSection } from '../../shared/helpers/globalConstants';

interface MenuSectionProps {
  menuItems: any;
}

export const MenuSection: FC<MenuSectionProps> = ({ menuItems }) => {
  const [activeId, setActiveId] = useState<any>(null);
  const navigate = useNavigate();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const handleMenuItemClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  return (
    <MenuSectionWrapper>
      {isDesktop ? (
        <Menu isHovered={activeId !== null}>
          <BackgroundImageBlock
            activeId={activeId}
            withoutMainBackgroundImg
            menuItems={menuItems}
          />
          {menuItems.map((menuItem: MenuItemProps) => (
            <MenuItem
              key={menuItem.id}
              isActive={!activeId || menuItem.id === activeId}
              onMouseEnter={() => setActiveId(menuItem.id)}
              onMouseLeave={() => setActiveId(null)}
              onClick={() => handleMenuItemClick(menuItem?.routePath)}>
              <MenuItemTitle>{menuItem.label}</MenuItemTitle>
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <MenuListBlock setActiveId={setActiveId} activeId={activeId} />
      )}
    </MenuSectionWrapper>
  );
};
