
export const investmentsNavItems = {
  philosophy: ' Filosofía',

  process: 'Proceso',

  packages: ' Paquetes',

  team: 'Equipo',
};
export const investmentsSecondDescription =
  'España es un lugar donde la cultura mediterránea se combina con una rica historia y una arquitectura única, atrayendo a turistas e inversores de todo el mundo. No es de extrañar, porque el mercado inmobiliario español es uno de los más prometedores y rentables de Europa. España ofrece una amplia variedad: desde apartamentos en el centro de la ciudad hasta villas de lujo en la costa. Debido a los moderados precios de la vivienda, la compra de inmuebles está al alcance de un amplio abanico de inversores de diferentes países. Las ciudades españolas son famosas por su infraestructura y calidad de vida, lo que atrae a personas dispuestas a comprar propiedades para residencia permanente o alquiler. Cada inversor que se ponga en contacto con nosotros puede recibir ofertas individuales, una entrada financiera estable, servicios jurídicos y evaluación de riesgos.';
