export const navItems = {
  services: 'Serveis',
};

export const consultingProcessTexts = {
  label: 'Serveis de consultoria',
  description:
    'Els serveis de consultoria immobiliària es presten perquè puguem sentir-nos segurs de la nostra elecció i despreocupar-nos de possibles riscos. Respondrem a totes les seves preguntes relacionades amb matisos legals i qüestions tècniques perquè pugui construir la casa dels seus somnis sense ""sorpreses"".',
};
export const description =
  `Els nostres serveis de consultoria són una poderosa eina per a l'èxit de la inversió i la gestió immobiliària. Això ajuda als clients a prendre decisions informades basades en l' anàlisi fonamental del mercat i en l' experiència immobiliària professional. Aquesta opció inclou una àmplia gamma de serveis: des de l' avaluació d' oportunitats d' inversió i el desenvolupament d' estratègies d' inversió fins a l' optimització de la gestió d' actius i l' augment de la rendibilitat. Utilitzem tecnologies innovadores i eines d'anàlisi de dades en el nostre treball per oferir resultats precisos i d'alta qualitat. Per utilitzar aquest servei, podem convidar-lo a la nostra oficina de Barcelona o oferir-li una trucada en línia.`;

export const processList = [
  {
    number: 1,
    label: `Investigació de mercat`,
    description:
      `Realització d' una anàlisi exhaustiva del mercat immobiliari, incloent-hi l' estudi de les tendències, la demanda, l' oferta, l' entorn competitiu i la predicció de futures tendències. L'anàlisi del mercat immobiliari és una eina que ens ajuda a recopilar informació per saber si val la pena invertir en béns immobles o per determinar el potencial de lloguer d'una ciutat o àrea. Aquesta és una oportunitat per comprendre quines cases estan en tendència avui dia i quin tipus de propietat tindrà demanda a llarg termini, en els propers anys.`,
  },
  {
    number: 2,
    label: `Assessorament en inversions`,
    description:
      `Una opció separada del nostre servei. Es tracta de l' assistència als inversors en la presa de decisions informades sobre inversions immobiliàries, incloent-hi l' avaluació del potencial dels objectes, l' anàlisi financera, la planificació estratègica i l' assessorament sobre l' optimització de la rendibilitat. El servei també inclou la determinació del nivell de risc per als prestadors associat amb el finançament de la compra de béns immobles. L' anàlisi ajuda a avaluar l' estabilitat del mercat, els possibles riscos i l' impacte en el retorn dels fons del préstec.`,
  },
  {
    number: 3,
    label: `Gestió d' actius`,
    description:
      `Assistència als propietaris en la gestió i optimització efectiva de la seva cartera, incloent-hi el desenvolupament d' una estratègia de gestió, màrqueting, arrendament, gestió de despeses operatives i seguiment de resultats. En el curs de les consultes sobre la gestió d' actius, es duu a terme una anàlisi de l' estat actual dels objectes immobiliaris, una avaluació del seu valor de mercat, una anàlisi dels fluxos d' ingressos i despeses, una avaluació de l' eficiència operativa i els riscos. Els consultors també ajuden als clients a desenvolupar i implementar estratègies per millorar la rendibilitat, reduir costos, optimitzar la gestió i atraure i retenir llogaters.`,
  },
  {
    number: 4,
    label: `Assessorament comercial`,
    description:
      `Assessorament en assumptes immobiliaris comercials, incloent-hi l' arrendament, la venda, la compra i el desenvolupament de propietats comercials com oficines, locals comercials, complexos industrials i hotels. Un servei ideal per a aquells que han planejat adquirir el seu propi hotel a les costes del Mediterrani o de l'oceà Atlàntic, però no saben per on començar i com avaluar les seves perspectives financeres.`,
  },
  {
    number: 5,
    label: `Assessorament en el desenvolupament de projectes`,
    description:
      `Assistència en el desenvolupament, planificació i gestió de projectes immobiliaris, incloent-hi la selecció de terrenys, desenvolupament de conceptes, coordinació de construcció, gestió pressupostària i control de qualitat. Es tracta d'un suport individual al client en totes les etapes, qui desitja assegurar-se que les seves accions siguin correctes i assegurar les seves inversions.`,
  },
  {
    number: 6,
    label: `Assessorament legal`,
    description:
      `Brindar assessorament legal i suport per a transaccions immobiliàries, incloent-hi la verificació de la legalitat dels objectes, la preparació i anàlisi de contractes, la resolució de disputes i la protecció dels interessos dels clients. Els nostres experts monitoren tots els canvis en el marc legislatiu i l'informen sobre possibles obstacles que puguin sorgir amb el temps.`,
  },
  {
    number: 7,
    label:`Assessorament en sostenibilitat i eficiència energètica`,
    description:
      `Assistència en el desenvolupament i implementació de mesures per reduir l' impacte ambiental dels béns immobles, incloent-hi l' avaluació de l' eficiència energètica. Juntament amb un consultor, es pot prendre una decisió sobre l' ús d' energia eòlica o energia solar i avaluar la pertinència d' aquesta iniciativa. D'aquesta manera, els clients poden reduir els costos operatius, augmentar el valor de les seves instal·lacions i complir amb les normes i requisits ambientals.`,
  },
  {
    number: 8,
    label: `Assessorament en qüestions d' interior i exterior`,
    description:
      `Ajudar els propietaris de béns immobles i desenvolupadors a crear espais interiors i exteriors atractius i funcionals. L' objectiu d' aquestes consultes és assegurar l' ús òptim de l' espai i crear interiors i exteriors estèticament agradables que satisfacin les necessitats i expectatives dels clients, així com reflecteixin la singularitat i el caràcter de la propietat. Els consultors d' interior i exterior treballen en el disseny i distribució de locals, l' elecció de materials i elements d' acabat, els mobles, la il·luminació, els esquemes de color i altres aspectes que afecten l' atmosfera general i l' aparença visual de la propietat. Proporcionen un servei integral per donar vida i color a les fredes parets de formigó.`,
  },
];
