import {
  TopNavbarTitles as TopNavbarTitlesFr,
  navBarItemTitlesArray as navBarItemTitlesFrArray,
  mobileNavBarItemTitlesArray as mobileNavBarItemTitlesFrArray,
} from '../pages/MainPage/TopNavBar/fr';
import { HeaderTitles as HeaderTitlesFr } from '../pages/MainPage/HeaderSection/fr';
import { SliderTitles as SliderTitlesFr } from '../pages/MainPage/SliderSection/fr';
import { ourStoryTexts as ourStoryTextsFr } from '../pages/MainPage/OurStoryBlock/fr';
import {
  servicesTexts as SolarServicesTextsFr,
  servicesList as SolartServicesListFr,
} from '../pages/SolarEnergyPage/ServicesSection/fr';
import {
  ourValuesTitles as ourValuesTitlesFr,
  ourValuesList as ourValuesListFr,
} from '../pages/MainPage/OurValuesBlock/fr';

import {
  MenuItemsTitles as MenuItemsTitlesFr,
  MenuItemsDescriptions as MenuItemsDescriptionsFr,
} from '../pages/MainPage/MenuSection/fr';
import { AboutUsTitles as AboutUsTitlesFr } from '../pages/MainPage/AboutSection/fr';

import { ContactsTitles as ContactsTitlesFr } from '../pages/MainPage/ContactsSection/fr';
import {
  Construction as ConstructionFr,
  Projects as ProjectsFr,
  ModularHouses as ModularHousesFr,
  Architect as ArchitectFr,
  Interior as InteriorFr,
  SwimmingPools as SwimmingPoolsFr,
  SmartHome as SmartHomeFr,
  Investments as InvestmentsFr,
  SolarEnergy as SolarEnergyFr,
  Inmobiliaria as InmobiliariaFr,
  Consulting as ConsultingFr,
  LandscapeDesign as LandscapeDesignFr,
} from '../pages/MainPage/FAQSection/fr';
import { architectStepSection as ArchitectStepSectionFr } from '../pages/ArchitectPage/StepsSection/fr';
import {
  footerNavBar as footerNavBarFr,
  footerTitles as footerTitlesFr,
} from '../pages/MainPage/FooterSection/fr';
import { contactsHeaderTitles as contactsHeaderTitlesFr } from '../pages/ContactsPage/ContactsHeaderSection/fr';

import {
  HeaderSection as HeaderSectionFr,
  description as SmartHomeDescriptionFr,
} from '../pages/SmartHomePage/HeaderSection/fr';
import { PartnersSection as PartnersSectionFr } from '../pages/SmartHomePage/PartnersSection/fr';
import { CardsSection as CardsSectionFr } from '../pages/SmartHomePage/CardsSection/fr';
import { HeaderSection as SwimmingPoolsHeaderSectionFr } from '../pages/SwimmingPoolsPage/HeaderSection/fr';
import { PoolsSection as SwimmingPoolsPoolsSectionFr } from '../pages/SwimmingPoolsPage/PoolsSection/fr';
import { ColorsSection as SwimmingPoolsColorsSectionFr } from '../pages/SwimmingPoolsPage/ColorsSection/fr';
import { PoolsAccessoriesSection as SwimmingPoolsAccessoriesSectionFr } from '../pages/SwimmingPoolsPage/AccessoriesSection/fr';
import { TechnologiesSection as SwimmingPoolsTechnologiesSectionFr } from '../pages/SwimmingPoolsPage/TechnologiesSection/fr';
import {
  AccessoriesSection as AccessoriesSectionFr,
  accessoriesIcons as AccessoriesIconsFr,
} from '../pages/SmartHomePage/AccessoriesSection/fr';
import { SmartHomeStepSection as SmartHomeStepsSectionFr } from '../pages/SmartHomePage/StepsBlockSection/fr';
import { MainSection as MainSectionFr } from '../pages/ServicesPage/fr';
import {
  investmentsNavItems as InvestmentsNavItemsFr,
  investmentsSecondDescription as InvestmentsSecondDescriptionFr,
} from '../pages/InvestmentsPage/HeaderSection/fr';
import {
  investmentsProcess as InvestmentsProcessFr,
  investmentsProcessTexts as investmentsProcessTextsFr,
} from '../pages/InvestmentsPage/InvestmentsProcess/fr';
import {
  philosophyTextsList as PhilosophyTextsListFr,
  philosophyTitle as PhilosophyTitleFr,
} from '../pages/InvestmentsPage/InvestmentsPhilosophySection/fr';
import {
  packagesTexts as packagesTextsFr,
  packagesBlocks as packagesBlocksFr,
} from '../pages/InvestmentsPage/InvestmentsPackagesSection/fr';
import {
  teamTexts as TeamTextsFr,
  teamBlocks as TeamBlocksFr,
} from '../pages/InvestmentsPage/InvestmentsTeamSection/fr';
import {
  realEstateNavItems as RealEstateNavItemsFr,
  realEstateSecondDescription as RealEstateSecondDescriptionFr,
} from '../pages/RealEstatePage/HeaderSection/fr';
import {
  realEstateProcessTexts as RealEstateProcessTextsFr,
  processList as ProcessListFr,
  servicesTexts as RealEstateServicesTextsFr,
  servicesList as RealEstateServicesListFr,
} from '../pages/RealEstatePage/ServicesSection/fr';
import { aboutListText as AboutListTextFr } from '../pages/RealEstatePage/AboutSection/fr';
import {
  navItems as ConsultingNavItemsFr,
  consultingProcessTexts as ConsultingProcessTextsFr,
  processList as ConsultingProcessListFr,
  description as ConsultingDescriptionFr,
} from '../pages/ConsultingPage/fr';
import {
  discoverList as DiscoverListFr,
  realEstateDiscoverTexts as RealEstateDiscoverTextsFr,
  aboutDiscoverList as AboutDiscoverListFr,
  aboutText as AboutTextFr,
} from '../pages/RealEstatePage/DiscoverSection/fr';
import {
  aboutUsNavItems as AboutUsNavItemsFr,
  aboutUsSecondDescription as AboutUsSecondDescriptionFr,
  aboutUsTitle as AboutUsTitleFr,
} from '../pages/AboutUsPage/HeaderSection/fr';
import {
  professionList as ProfessionListFr,
  pageTitle as PageTitleFr,
} from '../pages/AboutUsPage/OurTeamSection/fr';
import {
  pageTitle as OurStoryPageTitleFr,
  pageAfterTitle as OurStoryPageAfterTitleFr,
} from '../pages/AboutUsPage/OurStoryMobileBlock/fr';
import { projectsItems as ProjectsItemsFr } from '../pages/ProjectsPage/HeaderSection/fr';
import { projectsList as ProjectsListFr } from '../pages/ProjectsPage/ProjectsSection/fr';
import {
  headMenu as HeadMenuFr,
  typeHouseStyle as TypeHouseStyleFr,
  inputsTexts as InputsTextsFr,
  searchBtn as SearchBtnFr,
  removeAll as RemoveAllFr,
} from '../pages/ProjectsPage/FilterSection/fr';
import {
  headerSection as SolarHeaderSectionFr,
  description as SolarEnergyDescriptionFr,
} from '../pages/SolarEnergyPage/HeaderSection/fr';
import {
  solarEnergySetupsTexts as SolarEnergySetupsTextsFr,
  setupList as SetupListFr,
} from '../pages/SolarEnergyPage/SetupsSection/fr';
import {
  solarEnergyBenefitsTexts as SolarEnergyBenefitsTextsFr,
  benefitsList as BenefitsListFr,
} from '../pages/SolarEnergyPage/BenefitsSection/fr';
import { partnersSection as SolarPartnersSectionFr } from '../pages/SolarEnergyPage/PartnersSection/fr';
import {
  architectNavItems as ArchitectNavItemsFr,
  architectSecondDescription as ArchitectSecondDescriptionFr,
} from '../pages/ArchitectPage/HeaderSection/fr';
import {
  currentTexts as CurrentTextsFr,
  currentPortfolioTexts as CurrentPortfolioTextsFr,
  processList as ProcessListArchitectFr,
} from '../pages/ArchitectPage/ProcessSection/fr';
import {
  projectsName as ProjectsNameFr,
  portfolioText as PortfolioTextFr,
} from '../pages/ArchitectPage/PortfolioSection/fr';
import {
  aboutListLocation as AboutListLocationFr,
  infoText as InfoTextFr,
  mainText as MainTextFr,
  headerBtnText as HeaderBtnTextFr,
  titleAbout as TitleAboutFr,
  textsAbout as TextsAboutFr,
  textPhoto as TextPhotoFr,
  view as ViewBtnTextFr,
  housesDetailsMobile as HousesDetailsMobileFr,
  housesDetailsDesktop as HousesDetailsDesktopFr,
  photoTitle as PhotoTitleFr,
} from '../pages/InteriorCasePage/fr';
import {
  interiorNavItems as InteriorNavItemsFr,
  currentText as InteriorCurrentTextFr,
  currentPortfolioText as InteriorCurrentPortfolioTextFr,
  servicesList as InteriorServicesListFr,
  description as InteriorDescriptionFr,
} from '../pages/InteriorPage/fr';
import {
  navItems as ConstructionNavItemsFr,
  description as ConstructionDescriptionFr,
  processList as ConstructionProcessListFr,
  aboutTexts as ConstructionAboutTextsFr,
  preConstructionTitle as PreConstructionTitleFr,
  preConstructionText as PreConstructionTextFr,
  constructionStepBlockTitle as ConstructionStepBlockTitleFr,
  constructionStepBlockDescription as ConstructionStepBlockDescriptionFr,
  constructionStepSection as ConstructionStepSectionFr,
} from '../pages/ConstructionPage/fr';
import {
  navItems as LandscapeDesignNavItemsFr,
  description as LandscapeDesignDescriptionFr,
  currentTexts as LandscapeCurrentTextsFr,
  processList as LandscapeProcessListFr,
  currentTextsBenefits as CurrentTextsBenefitsFr,
  textsBenefits as TextsBenefitsFr,
} from '../pages/LandscapeDesignPage/fr';

import {
  currentTexts as BuildingCurrentTextsFr,
  textsBenefits as BuildingTextsBenefitsFr,
  benefitsTitle as BuildingBenefitsTitleFr,
  materialsList as BuildingMaterialsListFr,
} from '../pages/BuildingMaterialsPage/fr';
import { modularHousesStepSection as modularHousesStepSectionFr } from '../pages/ModularHousesPage/StepsBlock/fr';
import { loginPage as LoginPageFr } from '../pages/LoginPage/fr';
import { FavoritesSection as FavoritesSectionFr } from '../pages/FavoritesPage/fr';
import {
  modularHousesNavItems as ModularHousesNavItemsFr,
  description as ModularHousesDescriptionFr,
} from '../pages/ModularHousesPage/HeaderSection/fr';
import {
  typeOfHouses as TypeOfHousesFr,
  modelTitle as ModelTitleFr,
  modelDescription as ModelDescriptionFr,
} from '../pages/ModularHousesPage/ModelSection/fr';
import {
  currentTexts as CurrentModularHousesTextsFr,
  options as OptionsFr,
} from '../pages/ModularHousesPage/OptionsSection/fr';
import {
  blockTitle as BlockTitleFr,
  blockDescription as BlockDescriptionFr,
} from '../pages/ModularHousesPage/StepsBlock/fr';
import {
  title as TopServicesTitleFr,
  allServices as AllServicesFr,
  description as ServicesDescriptionFr,
} from '../pages/MainPage/TopServicesSection/fr';
import { cookiesPopup as CookiesPopupFr } from '../components/CookiesPopup/fr';
import { ClientPartnerNavbarItems as ClientPartnerNavbarItemsFr } from '../pages/LeadFormPage/fr';
import { MainNavbarItems as MainNavbarItemsFr } from '../pages/LeadFormPage/fr';
import { LeadFormPage as LeadFormPageFr } from '../pages/LeadFormPage/fr';
import { swimmingPoolsStepsSection as SwimmingPoolsStepsSectionFr } from '../pages/SwimmingPoolsPage/StepsBlockSection/fr'
import { StubProjectsPageTexts as StubProjectsPageTextsFr } from '../pages/StubProjectsPage/fr'
import {conditionsText as ConditionsTextFr} from '../pages/ConditionsPage/fr'
import {privacyText as PrivacyTextFr} from '../pages/PrivacyPage/fr'
export const fr = {
  translation: {
    mainPage: {
      TopNavbarTitles: TopNavbarTitlesFr,
      navBarItemTitlesArray: navBarItemTitlesFrArray,
      mobileNavBarItemTitlesArray: mobileNavBarItemTitlesFrArray,
      headerTitles: HeaderTitlesFr,
      sliderTitles: SliderTitlesFr,
      MenuItemsTitles: MenuItemsTitlesFr,
      MenuItemsDescriptions: MenuItemsDescriptionsFr,
      AboutUsTitles: AboutUsTitlesFr,
      ourStoryTexts: ourStoryTextsFr,
      ourValuesList: ourValuesListFr,
      ourValuesTitles: ourValuesTitlesFr,
      ContactsTitles: ContactsTitlesFr,
      topServicesTitle: TopServicesTitleFr,
      allServices: AllServicesFr,
      servicesDescription: ServicesDescriptionFr,
      FAQ: {
        LandscapeDesign: LandscapeDesignFr,
        Construction: ConstructionFr,
        Projects: ProjectsFr,
        ModularHouses: ModularHousesFr,
        Architect: ArchitectFr,
        Interior: InteriorFr,
        SwimmingPools: SwimmingPoolsFr,
        SmartHome: SmartHomeFr,
        Investments: InvestmentsFr,
        SolarEnergy: SolarEnergyFr,
        Inmobiliaria: InmobiliariaFr,
        Consulting: ConsultingFr,
      },
      footerNavBar: footerNavBarFr,
      footerTitles: footerTitlesFr,
    },
    contactsPage: {
      contactsHeaderTitles: contactsHeaderTitlesFr,
    },
    smartHome: {
      HeaderSection: HeaderSectionFr,
      PartnersSection: PartnersSectionFr,
      CardsSection: CardsSectionFr,
      AccessoriesSection: AccessoriesSectionFr,
      SmartHomeStepsSection: SmartHomeStepsSectionFr,
      SmartHomeDescription: SmartHomeDescriptionFr,
      AccessoriesIcons: AccessoriesIconsFr,
    },
    services: {
      MainSection: MainSectionFr,
    },
    investmentsPage: {
      investmentsNavItems: InvestmentsNavItemsFr,
      investmentsSecondDescription: InvestmentsSecondDescriptionFr,
      investmentsProcess: InvestmentsProcessFr,
      investmentsProcessTexts: investmentsProcessTextsFr,
      philosophyTextsList: PhilosophyTextsListFr,
      philosophyTitle: PhilosophyTitleFr,
      packagesTexts: packagesTextsFr,
      packagesBlocks: packagesBlocksFr,
      teamTexts: TeamTextsFr,
      teamBlocks: TeamBlocksFr,
    },

    realEstatePage: {
      realEstateNavItems: RealEstateNavItemsFr,
      realEstateSecondDescription: RealEstateSecondDescriptionFr,
      realEstateProcessTexts: RealEstateProcessTextsFr,
      processList: ProcessListFr,
      aboutListText: AboutListTextFr,
      discoverList: DiscoverListFr,
      realEstateDiscoverTexts: RealEstateDiscoverTextsFr,
      aboutDiscoverList: AboutDiscoverListFr,
      aboutText: AboutTextFr,
      servicesTexts: RealEstateServicesTextsFr,
      servicesList: RealEstateServicesListFr,
    },
    consultingPage: {
      consultingNavItems: ConsultingNavItemsFr,
      consultingProcessTexts: ConsultingProcessTextsFr,
      consultingProcessList: ConsultingProcessListFr,
      consultingDescription: ConsultingDescriptionFr,
    },
    aboutUsPage: {
      aboutUsNavItems: AboutUsNavItemsFr,
      aboutUsSecondDescription: AboutUsSecondDescriptionFr,
      aboutUsTitle: AboutUsTitleFr,
      professionList: ProfessionListFr,
      pageTitle: PageTitleFr,
      ourStoryPageTitle: OurStoryPageTitleFr,
      ourStoryPageAfterTitle: OurStoryPageAfterTitleFr,
    },

    projectsPage: {
      projectsItems: ProjectsItemsFr,
      headMenu: HeadMenuFr,
      typeHouseStyle: TypeHouseStyleFr,
      inputsTexts: InputsTextsFr,
      projectsList: ProjectsListFr,
      searchBtn: SearchBtnFr,
      removeAll: RemoveAllFr,
    },
    solarEnergyPage: {
      headerSection: SolarHeaderSectionFr,
      solarEnergyBenefitsTexts: SolarEnergyBenefitsTextsFr,
      solarEnergyDescription: SolarEnergyDescriptionFr,
      benefitsList: BenefitsListFr,
      partnersSection: SolarPartnersSectionFr,
      solarEnergySetupsTexts: SolarEnergySetupsTextsFr,
      setupList: SetupListFr,
      servicesTexts: SolarServicesTextsFr,
      servicesList: SolartServicesListFr,
    },
    architectPage: {
      architectNavItems: ArchitectNavItemsFr,
      architectSecondDescription: ArchitectSecondDescriptionFr,
      currentTexts: CurrentTextsFr,
      currentPortfolioTexts: CurrentPortfolioTextsFr,
      processList: ProcessListArchitectFr,
      projectsName: ProjectsNameFr,
      portfolioText: PortfolioTextFr,

      architectStepSection: ArchitectStepSectionFr,
    },
    interiorCasePage: {
      aboutListLocation: AboutListLocationFr,
      infoText: InfoTextFr,
      mainText: MainTextFr,
      headerBtnText: HeaderBtnTextFr,
      titleAbout: TitleAboutFr,
      textsAbout: TextsAboutFr,
      textPhoto: TextPhotoFr,
      viewBtnText: ViewBtnTextFr,
      housesDetailsMobile: HousesDetailsMobileFr,
      housesDetailsDesktop: HousesDetailsDesktopFr,
      photoTitle: PhotoTitleFr,
    },
    interiorPage: {
      interiorNavItems: InteriorNavItemsFr,
      interiorCurrentText: InteriorCurrentTextFr,
      interiorCurrentPortfolioText: InteriorCurrentPortfolioTextFr,
      interiorServicesList: InteriorServicesListFr,
      interiorDescription: InteriorDescriptionFr,
    },
    constructionPage: {
      constructionNavItems: ConstructionNavItemsFr,
      constructionDescription: ConstructionDescriptionFr,
      constructionProcessList: ConstructionProcessListFr,
      constructionAboutTexts: ConstructionAboutTextsFr,
      preConstructionTitle: PreConstructionTitleFr,
      preConstructionText: PreConstructionTextFr,
      constructionStepBlockTitle: ConstructionStepBlockTitleFr,
      constructionStepBlockDescription: ConstructionStepBlockDescriptionFr,
      constructionStepSection: ConstructionStepSectionFr,
    },
    landscapeDesignPage: {
      landscapeDesignNavItems: LandscapeDesignNavItemsFr,
      landscapeDesignDescription: LandscapeDesignDescriptionFr,
      landscapeCurrentTexts: LandscapeCurrentTextsFr,
      landscapeProcessList: LandscapeProcessListFr,
      currentTextsBenefits: CurrentTextsBenefitsFr,
      textsBenefits: TextsBenefitsFr,
    },

    buildingMaterialsPage: {
      buildingCurrentTexts: BuildingCurrentTextsFr,
      buildingTextsBenefits: BuildingTextsBenefitsFr,
      buildingBenefitsTitle: BuildingBenefitsTitleFr,
      buildingMaterialsList: BuildingMaterialsListFr,
    },
    swimmingPools: {
      HeaderSection: SwimmingPoolsHeaderSectionFr,
      PoolsSection: SwimmingPoolsPoolsSectionFr,
      ColorsSection: SwimmingPoolsColorsSectionFr,
      TechnologiesSection: SwimmingPoolsTechnologiesSectionFr,
      AccessoriesSection: SwimmingPoolsAccessoriesSectionFr,
      SwimmingPoolsStepsSection: SwimmingPoolsStepsSectionFr,
    },

    loginPage: {
      loginPage: LoginPageFr,
    },

    favorites: {
      FavoritesSection: FavoritesSectionFr,
    },
    modularHousesPage: {
      modularHousesNavItems: ModularHousesNavItemsFr,
      modelTitle: ModelTitleFr,
      modelDescription: ModelDescriptionFr,
      typeOfHouses: TypeOfHousesFr,
      currentTexts: CurrentModularHousesTextsFr,
      options: OptionsFr,
      blockTitle: BlockTitleFr,
      blockDescription: BlockDescriptionFr,
      description: ModularHousesDescriptionFr,
      modularHousesStepSection: modularHousesStepSectionFr
    },
    cookiesPopup: {
      cookiesPopup: CookiesPopupFr,
    },
    leadForm: {
      clientPartnerNavbarItems: ClientPartnerNavbarItemsFr,
      mainNavbarItems: MainNavbarItemsFr,
      leadFormPage: LeadFormPageFr,
    },
     stubProjectsPage: {
      stubProjectsPageTexts: StubProjectsPageTextsFr,
    },
     conditionsPage: {
      conditionsText: ConditionsTextFr,
    },
     privacyPage: {
      privacyText: PrivacyTextFr,
    }
  },
};
