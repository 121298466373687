import React, { ChangeEvent, FC, memo } from 'react';
import { TextField } from './TextFieldInput.styled';

export interface TextFieldInputProps {
  onChange?: (e: string | number | ChangeEvent<any>) => void;
  placeholder: string;
  type: string;
  name: string;
  required?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  defaultValue?: string;
  value?: string | number;
  inputProps?: any;
  addClassname?: string;
  isError?: any;
}

export const TextFieldInput: FC<TextFieldInputProps> = memo(
  ({
    type,
    name,
    onChange,
    required,
    disabled,
    defaultValue,
    placeholder,
    addClassname,
    value,
    isError,
  }) => {
    return (
      <TextField
        isError={isError}
        type={type}
        name={name}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        className={addClassname}
      />
    );
  },
);
