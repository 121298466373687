export const navItems = {
  services: 'Serveis',
  benefits: 'Beneficis',
  stages: 'Etapes',
};

export const description =
`Desitja transformar el seu espai exterior en un oasi bell i funcional? No busqui més i recorri als nostres experts serveis de disseny paisatgístic. El nostre equip d'experimentats dissenyadors i arquitectes és aquí per ajudar-lo a convertir la seva visió en realitat.`;


export const currentTexts = {
  label: 'Serveis',
  description:
    'En els nostres serveis de disseny paisatgístic, oferim una àmplia gamma de serveis per ajudar-lo a transformar el seu espai exterior. Els nostres serveis inclouen',
};
export const processList = {
  labelFirst: 'Consulta i Anàlisi del Lloc',
  descriptionFirst: `Abans de començar qualsevol projecte de disseny paisatgístic, comencem amb una consulta i una anàlisi del lloc. Això inclou una visita in situ per avaluar les característiques existents de la seva propietat, les condicions del sòl i la llum solar, i la seva visió i preferències per a l' espai. A partir d'aquí, creem un pla de disseny personalitzat adaptat a les seves necessitats i preferències.`,

  labelSecond: 'Disseny conceptual',
  descriptionSecond: `A continuació, el nostre equip desenvoluparà un disseny conceptual que incorpori les seves idees i preferències, així com les recomanacions dels nostres experts. Utilitzem programari d'última generació per crear representacions en 3D del disseny perquè pugui visualitzar el producte final abans de realitzar qualsevol treball.`,

  labelThird: 'Construcció i instal·lació',
  descriptionThird:
    `Un cop aprovi el disseny final, el nostre equip començarà la construcció i la instal·lació. Utilitzem només materials de la més alta qualitat i treballem amb contractistes de confiança per garantir que cada aspecte del disseny s'executi a la perfecció.`,

  labelFourth: 'Manteniment i conservació',
  descriptionFourth:
    'No només creem bells espais exteriors, també ajudem a mantenir-los. El nostre equip ofereix serveis continus de manteniment i conservació per garantir que el seu disseny paisatgístic segueixi lluint el millor possible durant anys.',
};

export const currentTextsBenefits = {
  description:
  `Elegir els nostres serveis de disseny paisatgístic comporta una sèrie de beneficis, entre els quals s' inclouen:`,
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Dissenys personalitzats adaptats a les seves necessitats i preferències úniques',
  },
  { id: 2, text: `Programari d' última generació per a renderitzats en 3D` },
  { id: 3, text: `Materials d' alta qualitat i contractistes de confiança` },
  { id: 4, text: 'Serveis continus de manteniment i conservació' },
  {
    id: 5,
    text: 'Equip de dissenyadors i arquitectes amb experiència i coneixements',
  },
];
