import styled from 'styled-components';
import { Button } from '../../shared/components/BluredButton/BluredButton.styled';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../theme/Theme.constants';

export const PreBuildHousesWrapper = styled.div`
  background-color: ${colorVariables.blackBackgroundColor};
`;

export const ToogleGridWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${colorVariables.blackBackgroundColor};
  height: 3.125rem;
  width: 100%;
  border-top: 1px solid ${colorVariables.contactsHeaderBorderColor};
  padding: 1rem 6.25rem;
`;

export const FirstGridColumnWrapper = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
`;
export const FirstGridColumn = styled.div<{ activeColumn: boolean }>`
  width: 3.125rem;
  height: 1.125rem;
  background: ${props =>
    props.activeColumn
      ? `${colorVariables.contactsHeaderIconBackground};`
      : `${colorVariables.footerFormBtn};`};
  border: 1px solid ${props =>
    props.activeColumn
      ? `${colorVariables.yellowButtonBorder};`
      : `${colorVariables.whiteOpacityColor};`} 
  backdrop-filter: blur(2px) !important;

  &:not(:last-child) {
    margin-right: 0.312rem;
  }
`;

export const SecondGridColumnWrapper = styled(FirstGridColumnWrapper)``;

export const SecondGridColumn = styled(FirstGridColumn)<{
  activeColumn: boolean;
}>`
  width: 4.75rem;
  height: 1.125rem;
`;

export const BuildingSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  padding: 1rem 1.25rem 1.875rem;
  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.25rem 2.5rem 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 1.25rem 6.25rem 2.5rem;
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
  margin-bottom: 1.062rem;
`;
export const ToogleWrapper = styled.div<{ isOpenFilter: boolean }>`
  display: flex;

  align-items: center;
  cursor: pointer;
  width: 4.375rem;
  margin-left: auto;
  & .drop-down-icon {
    fill: ${colorVariables.mainMenuIconColor};
    rotate: ${props => (props.isOpenFilter ? '180deg;' : '0;')};
  }
`;

export const DropDownTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.062rem;
  margin-right: 0.312rem;
  color: ${colorVariables.dropDownTitleColor};
`;
