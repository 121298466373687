export const typeOfHouses = {
  airigh: 'Airigh',
  accessible: 'Доступний',
  bespokeDesign: 'Індивідуальний дизайн',
  whitehouse: 'Білий будинок',
  longhouse: ' Лонгхаус',
  steading: ' Насадження',
};

export const modelTitle = 'Модульні будинки Airigh';
export const modelDescription = ' Виберіть свій керамічний басейн Compass з 24 моделей, який підійде, завдяки своєму дизайну, як для сучасної резиденції, так і для класичного будинку. Вони підходять для саду, для тераси біля будинку або в приміщенні як критий басейн.';
