import React, { FC } from 'react';
import {
  AboutSectionWrapper,
  TitleWrapper,
  Title,
  Img,
  TextWrapper,
  Text,
  TopWrapper,
} from './CaseAboutSection.styled';
import img from '../../assets/images/interior-case/about-img.jpg';

interface AboutSectionProps {
   title: string;
   image: string;
   texts: { id: number, text: string }[];
}

export const CaseAboutSection: FC<AboutSectionProps> = ({ title, image, texts }) => {
 

  return (
    <AboutSectionWrapper>
      <TopWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        <Img src={image} />
      </TopWrapper>

      <TextWrapper>
        {texts.map(({ id, text }) => {
          return <Text key={id}>{text}</Text>;
        })}
      </TextWrapper>
    </AboutSectionWrapper>
  );
};
