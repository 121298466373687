import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const AboutUsPageWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
`;

export const OurValuesWrapper = styled.div`
  padding: 3.75rem 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 4.6875rem 2.5rem 7.8125rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 11.875rem 6.25rem 14.6875rem;
  }
`;

export const OurStoryWrapper = styled.div`
  padding: 0 0rem 3.75rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0rem 2.5rem 9.375rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0rem 6.25rem 10.3125rem;
  }
`;

export const OurTeamWrapper = styled.div`
  padding: 3.75rem 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 0 2.5rem 6.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 0 6.25rem 7.5rem;
  }
`;
