import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { Breakpoints } from '../../theme/Theme.constants';
import {
  ConstructionPageWrapper,
  PreConstructionSectionWrapper,
  ProcessSectionWrapper,
  AboutSectionWrapper,
  AboutText,
} from './ConstructionPage.styled';
import { PreConstructionSection } from './PreConstructionSection';
import { ProcessBlock } from '../../shared/components/ProcessBlock/ProcessBlock';
import { ReactComponent as Management } from '../../shared/assets/icons/construction/managment.svg';
import { ReactComponent as UserGroup } from '../../shared/assets/icons/construction/user-group.svg';
import { ReactComponent as Gem } from '../../shared/assets/icons/construction/gem.svg';
import { ReactComponent as Briefcase } from '../../shared/assets/icons/construction/briefcase.svg';
import { ReactComponent as UserShield } from '../../shared/assets/icons/construction/user-shield.svg';
import { ReactComponent as Threes } from '../../shared/assets/icons/construction/trees.svg';
import slideImg from '../../shared/assets/images/construction/slider-img.jpg';
import slideImgMobile from '../../shared/assets/images/construction/slider-mobile-img.jpg';
import slideImgDesktop from '../../shared/assets/images/construction/slider-desktop-img.jpg';
import { SliderSection } from '../../shared/components/PagesSliderSection';
import { StepsBlockComponent } from '../../shared/components/StepsBlock/StepsBlock';
import firstSlide from '../../shared/assets/images/construction/slider-img-1.jpg';
import secondSlide from '../../shared/assets/images/construction/slider-img-2.jpg';

export const ConstructionPage: FC = () => {
  const menuItems = getMenuItems();
  const constructionInfo = menuItems[0];
  const { t } = useTranslation();

  useEffect(() => {
    const images = [firstSlide, secondSlide];

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);
  const navItems = [
    {
      title: t('constructionPage.constructionNavItems.preConstruction'),
      sectionId: 'pre-construction-section',
    },
    {
      title: t('constructionPage.constructionNavItems.process'),
      sectionId: 'process-section',
    },
  ];

  const processList = [
    {
      number: 1,
      label: t('constructionPage.constructionProcessList.labelFirst'),
      Icon: Threes,

      description: t(
        'constructionPage.constructionProcessList.descriptionFirst',
      ),
    },
    {
      number: 2,
      label: t('constructionPage.constructionProcessList.labelSecond'),
      Icon: Management,

      description: t(
        'constructionPage.constructionProcessList.descriptionSecond',
      ),
    },
    {
      number: 3,
      label: t('constructionPage.constructionProcessList.labelThird'),
      Icon: Briefcase,

      description: t(
        'constructionPage.constructionProcessList.descriptionThird',
      ),
    },
    {
      number: 4,
      label: t('constructionPage.constructionProcessList.labelFourth'),
      Icon: Gem,

      description: t(
        'constructionPage.constructionProcessList.descriptionFourth',
      ),
    },
    {
      number: 5,
      label: t('constructionPage.constructionProcessList.labelFifth'),
      Icon: UserGroup,

      description: t(
        'constructionPage.constructionProcessList.descriptionFifth',
      ),
    },
    {
      number: 6,
      label: t('constructionPage.constructionProcessList.labelSixth'),
      Icon: UserShield,
      description: t(
        'constructionPage.constructionProcessList.descriptionSixth',
      ),
    },
  ];

  const aboutTexts = [
    {
      id: 1,
      text: t('constructionPage.constructionAboutTexts.textFirst'),
    },
    {
      id: 2,
      text: t('constructionPage.constructionAboutTexts.textSecond'),
    },
    {
      id: 3,
      text: t('constructionPage.constructionAboutTexts.textThird'),
    },
    {
      id: 4,
      text: t('constructionPage.constructionAboutTexts.textFourth'),
    },
    {
      id: 5,
      text: t('constructionPage.constructionAboutTexts.textFifth'),
    },
  ];

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTablet = useMediaQuery({
    query: ` (min-width: ${Breakpoints.md}px)`,
  });

  const currentSlideImg = isMobile
    ? slideImgMobile
    : isTablet
    ? slideImg
    : isDesktop
    ? slideImgDesktop
    : '';

  const slides = [
    { id: 1, img: firstSlide },
    { id: 2, img: secondSlide },
    // { id: 3, img: currentSlideImg },
    // { id: 4, img: currentSlideImg },
  ];

  const constructionStepsData = [
    {
      id: 1,
      title: t('constructionPage.constructionStepSection.stepOne.title'),
      description: (
        <>
          <p>{t('constructionPage.constructionStepSection.stepOne.p1')}</p>
          <br />
          <p>{t('constructionPage.constructionStepSection.stepOne.p2')}</p>
        </>
      ),
    },
    {
      id: 2,
      title: t('constructionPage.constructionStepSection.stepTwo.title'),
      description: (
        <>
          <p>{t('constructionPage.constructionStepSection.stepTwo.p1')}</p>
          <br />
          <p>{t('constructionPage.constructionStepSection.stepTwo.p2')}</p>
        </>
      ),
    },
    {
      id: 3,
      title: t('constructionPage.constructionStepSection.stepThree.title'),
      description: (
        <>
          <p>{t('constructionPage.constructionStepSection.stepThree.p1')}</p>
          <br />
          <p>{t('constructionPage.constructionStepSection.stepThree.p2')}</p>
        </>
      ),
    },
    {
      id: 4,
      title: t('constructionPage.constructionStepSection.stepFour.title'),
      description: (
        <>
          <p>{t('constructionPage.constructionStepSection.stepFour.p1')}</p>
          <br />
          <p>{t('constructionPage.constructionStepSection.stepFour.p2')}</p>
        </>
      ),
    },
    {
      id: 5,
      title: t('constructionPage.constructionStepSection.stepFive.title'),
      description: (
        <>
          <p>{t('constructionPage.constructionStepSection.stepFive.p1')}</p>
          <br />
          <p>{t('constructionPage.constructionStepSection.stepFive.p2')}</p>
        </>
      ),
    },
  ];
  return (
    <ConstructionPageWrapper>
      <PageHeaderSection
        logoImg={constructionInfo.icon}
        title={constructionInfo.label}
        navItems={navItems}
        bgImage={constructionInfo.backgroundImg}
        description={t('constructionPage.constructionDescription')}
        descriptionColor={isDesktop ? '#F1F2F6' : ''}
        id={constructionInfo.id}
      />

      <PreConstructionSectionWrapper id="pre-construction-section">
        <PreConstructionSection />
      </PreConstructionSectionWrapper>

      <ProcessSectionWrapper id="process-section">
        <ProcessBlock processList={processList} />
      </ProcessSectionWrapper>

      {isTablet && (
        <AboutSectionWrapper>
          {aboutTexts.map(({ id, text }) => {
            return (
              <AboutText
                key={id}
                dangerouslySetInnerHTML={{ __html: text }}></AboutText>
            );
          })}
        </AboutSectionWrapper>
      )}
      <StepsBlockComponent
        stepsData={constructionStepsData}
        blockTitle={t('constructionPage.constructionStepBlockTitle')}
        description={t('constructionPage.constructionStepBlockDescription')}
      />
      <SliderSection slides={slides} />
    </ConstructionPageWrapper>
  );
};
