import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';

export const CustomPopup = styled.div`
  position: relative;
  width: 100%;
  height: 3.37rem;
  border: 1px solid ${colorVariables.alternativeTextColor};
  border-radius: 1px;
  padding: 0.62rem 1.25rem 0.62rem 1.25rem;
  color: ${colorVariables.mainTextColor};
  border: 1px solid ${colorVariables.iconYellow};
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colorVariables.buttonGreyBackground};
    backdrop-filter: blur(2px);
    z-index: 1;
  }
  > * {
    position: relative;
    z-index: 2;
  }
`;

export const CustomPopupText = styled.span`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: ${colorVariables.popupText};
`;