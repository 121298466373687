import React, { FC } from 'react';
import { ItemTitle, MenuList, MenuListItem } from './MenuListBlock.styled';

import { getMenuItems } from '../MenuSection/MenuSection.constatnts';
import { MenuItemProps } from '../MenuSection/MenuSection.types';
import { useNavigate } from 'react-router';
import { mainSection } from '../../shared/helpers/globalConstants';

export interface MenuListBlockProps {
  activeId?: number | null;
  setActiveId?: (activeId: number | null) => void;
  activeIdAfterClick?: number;
  setActiveIdAfterClick?: (activeIdAfterClick: number) => void;
  setMenuIsOpen?: (menuIsOpen: boolean) => void;
  isNotRouting?: boolean;
  isFaq?:boolean;
}

export const MenuListBlock: FC<MenuListBlockProps> = ({
  activeId,
  setActiveId,
  activeIdAfterClick,
  setActiveIdAfterClick,
  setMenuIsOpen,
  isNotRouting,
  isFaq
}) => {
  const menuItems = getMenuItems();
  const navigate = useNavigate();

  const handleMenuListItemClick = (
    itemId: number,
    routePath: string | undefined,
  ) => {
    setActiveIdAfterClick &&
      activeIdAfterClick &&
      setActiveIdAfterClick(itemId);
    setMenuIsOpen && setMenuIsOpen(false);

    if (!routePath || isNotRouting) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  const filteredFaqData = menuItems.filter(
    (faqItem: MenuItemProps) => faqItem.id !== 2 && faqItem.id !== 16 && faqItem.id !== 13,
  ); // remove when we will have copyright for other services
  return (
    <MenuList>
      {isFaq &&
        filteredFaqData.map((menuItem: MenuItemProps) => (
          <MenuListItem
            key={menuItem.id}
            isActive={!activeId || menuItem.id === activeId}
            isClicked={menuItem.id === activeIdAfterClick}
            onClick={() =>
              handleMenuListItemClick(menuItem.id, menuItem?.routePath)
            }
            onMouseEnter={() => setActiveId && setActiveId(menuItem.id)}
            onMouseLeave={() => setActiveId && setActiveId(null)}>
            {menuItem.icon}
            <ItemTitle>{menuItem.label}</ItemTitle>
          </MenuListItem>
        ))}

      {!isFaq &&
        menuItems.slice(0, -1).map((menuItem: MenuItemProps) => (
          <MenuListItem
            key={menuItem.id}
            isActive={!activeId || menuItem.id === activeId}
            isClicked={menuItem.id === activeIdAfterClick}
            onClick={() =>
              handleMenuListItemClick(menuItem.id, menuItem?.routePath)
            }
            onMouseEnter={() => setActiveId && setActiveId(menuItem.id)}
            onMouseLeave={() => setActiveId && setActiveId(null)}>
            {menuItem.icon}
            <ItemTitle>{menuItem.label}</ItemTitle>
          </MenuListItem>
        ))}
    </MenuList>
  );
};
