export const TechnologiesSection = {
  title: 'Tecnología',
  description: `Compass Pools: donde la tecnología se une a la perfección. Innovación en cerámica de carbono, sistema de autolimpieza Vantage, fácil instalación. Piscinas ecológicas preparadas para el futuro. Elíjanos para una instalación perfecta y una funcionalidad de vanguardia.`,
  gelcoatAndColor: 'Gelcoat y color',
  closedBeam: 'Viga cerrada',
  vinylesterBase: 'Base de viniléster',
  balsaReinforcement: 'Refuerzo de balsa',
  vinylester: 'Viniléster reforzado con fibra de vidrio',
  glassFiberMats: 'Capa de fibra de vidrio',
  ceramicLayer: 'Capa cerámica',
  polyesterLayer: 'Capa de poliéster',
  carbonFibreMats: 'Capa de fibra de carbono',
  exteriorTopCoat: 'Capa exterior',
  gelcoatAndColorDescription:
    'La capa de gelcoat, desarrollada originalmente para superficies de yates de lujo, sirve de barrera protectora superior para las piscinas Compass. Presenta una resistencia excepcional a la radiación UV y a los daños mecánicos. Nuestro sistema de coloración, que forma parte de uno de los sistemas de gelcoat más avanzados del mundo, garantiza que las piscinas Compass Pools mantengan su aspecto de alta calidad incluso después de muchos años de uso.',
  closedBeamDescription: `La viga cerrada, que se extiende a lo largo de todo el borde de la piscina, garantiza una resistencia y una estabilidad excepcionales, manteniendo al mismo tiempo la flexibilidad necesaria. Esto es crucial durante el transporte y la instalación, ya que garantiza que la piscina conserve su forma a la perfección. Con un diseño de viga cerrada, nuestras piscinas ofrecen una durabilidad y una integridad estructural inigualables.`,
  vinylesterBaseDescription: `Nuestras piscinas están equipadas con una base de resina viniléster de alta calidad, que sirve como barrera inicial resistente al agua. Esta capa se aplica sobre el gelcoat, impidiendo eficazmente la aparición de ósmosis.`,
  balsaReinforcementDescription: `La capa exterior de nuestra piscina está reforzada con madera de balsa, conocida por su ligereza. Con su colocación estratégica y su amplio grosor, proporciona suficiente resistencia, eliminando la necesidad de refuerzos adicionales.`,
  vinylesterDescription:
    'Nuestras piscinas cuentan con una segunda barrera impermeable reforzada con fibra de vidrio, que proporciona una flexibilidad y resistencia óptimas. Esta combinación garantiza que nuestras piscinas sean duraderas y resistentes, lo que permite un disfrute duradero.',
  glassFiberMatsDescription: `Nuestras piscinas cuentan con una robusta segunda barrera impermeable, reforzada con fibra de vidrio, para proporcionar una flexibilidad y resistencia sin igual. La incorporación de fibra de vidrio garantiza la máxima durabilidad e integridad estructural de nuestras piscinas.`,
  ceramicLayerDescription: `Compass Pools cuenta con una capa cerámica de última generación, desarrollada en Australia y Estados Unidos. Diseñada originalmente para proyectos de la NASA, garantiza un 100% de protección contra la ósmosis, asegurando la durabilidad y fiabilidad de nuestras piscinas.`,
  polyesterLayerDescription: `La capa de poliéster, situada encima de la capa de cerámica, forma el componente central de nuestra piscina compuesta. Una vez que esta capa se ha endurecido por completo, la piscina adquiere excelentes propiedades mecánicas, lo que garantiza su durabilidad y resistencia a largo plazo.`,
  carbonFibreMatsDescription: `Utilizamos esteras de fibra de carbono, un material ligero y robusto que se encuentra habitualmente en equipos deportivos y en la industria aeronáutica, para reforzar las partes más críticas de nuestras piscinas. Esta integración garantiza que las piscinas Compass sean excepcionalmente resistentes y mantengan una forma uniforme, lo que las distingue en el mercado.`,
  exteriorTopCoatDescription: `Especialmente diseñado para resistir las inclemencias del tiempo, este avanzado revestimiento proporciona una capa protectora que mantiene el aspecto deslumbrante de su piscina durante años. Con su resistencia superior a la radiación UV, a los productos químicos y a la intemperie, nuestra capa superior exterior garantiza que su piscina conserve su color vibrante y su acabado impecable, lo que la convierte en la opción perfecta para instalaciones de piscinas al aire libre.`,
};

