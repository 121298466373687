import i18next from "i18next";

export const AboutUsTitlesEn = {
  about: 'About',
  us: 'Us',
  mainDescription: `At Moderna  Casa, we excel in delivering state-of-the-art solutions for construction and real estate with a strong focus on customer satisfaction and quality. We offer a comprehensive range of services, including construction, design, housing, pools, energy, real estate, investments, and consulting. Our highly skilled team consistently delivers exceptional outcomes that surpass our client's expectations while staying within their budgets and timelines. By building trust and fostering lasting relationships with our clients, we establish ourselves as a reliable partner in their success.`,
};
interface Value {
  id: number;
  title: string;
  text: string;
}

export const getValueList = () => {
  const valueList = i18next.t('mainPage.ourValuesList', {
        returnObjects: true,
  }) as Array<Value>;
  
  return valueList
}