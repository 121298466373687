export const swimmingPoolsStepsSection = {
  blockTitle: ' Étapes de la maison intelligente',
  descriptionTitle: 'Première consultation',
 stepOne : {
    title : 'Conception et planification',
    p1 : `Commencez par créer un projet détaillé pour votre piscine, en tenant compte de facteurs tels que la taille, la forme, l'emplacement et les caractéristiques. Consultez des professionnels, tels que des architectes et des concepteurs de piscines, pour vous assurer que le projet répond à vos besoins et qu'il est conforme aux réglementations locales`,
    p2 : ``,
  },
  stepTwo : {
    title : 'Excavation',
    p1 : `L'excavation consiste à creuser la zone où sera située la piscine. Des machines lourdes sont généralement utilisées pour enlever la terre et créer le trou conformément aux spécifications du projet. Cette étape exige une grande précision pour obtenir la forme et la profondeur souhaitées`,
    p2 : ``,
  },
  stepThree : {
    title : 'Renforcement et plomberie',
    p1 : `Une fois l'excavation terminée, l'étape suivante consiste à installer un cadre de renforcement solide. Des barres d'acier, ou barres d'armature, sont utilisées pour renforcer la structure de la piscine. C'est également à ce stade que sont installées les conduites de plomberie, y compris celles qui servent à l'alimentation en eau et à l'évacuation des eaux usées`,
    p2 : ``,
  },
  stepFour : {
    title : 'Couler du béton ou installer une coque de piscine préfabriquée',
    p1 : `En fonction de votre projet et de vos préférences, vous pouvez choisir de couler du béton pour créer la structure de la piscine ou d'installer une coque de piscine préfabriquée. Les piscines en béton offrent davantage d'options de personnalisation, tandis que les coques préfabriquées s'installent plus rapidement`,
    p2 : ``,
  },
  stepFive : {
    title : 'Finition et système de filtration',
    p1 : `Après la mise en place de la structure de la piscine, il est temps d'apporter les touches finales. Il s'agit d'appliquer des revêtements d'étanchéité, de carreler l'intérieur et d'installer des éléments supplémentaires tels que des marches, des plongeoirs ou des cascades. Simultanément, le système de filtration, comprenant les pompes, les filtres et les réchauffeurs, est installé pour assurer une circulation et un entretien corrects de l'eau.`,
    p2 : ``,
  },
  stepSix : {
    title : 'Decking and Landscaping',
    p1 : `Autour de la piscine, vous pouvez créer une terrasse ou un patio pour vous détendre et vous adonner à des activités récréatives. Choisissez des matériaux durables, antidérapants et adaptés à une utilisation en extérieur. Des éléments d'aménagement tels que des plantes, des arbres et des éclairages peuvent également améliorer l'esthétique et la fonctionnalité de l'espace piscine`,
    p2 : ``,
  },
  stepSeven : {
    title : `Remplir et commencer l'entretien`,
    p1 : `Une fois la construction terminée, la piscine est remplie d'eau et les traitements chimiques sont appliqués pour établir l'équilibre approprié de l'eau. Commencez les opérations d'entretien nécessaires, telles que le nettoyage régulier, l'analyse de l'eau et l'entretien de l'équipement, afin de vous assurer que la piscine reste sûre, propre et accueillante pour les années à venir`,
    p2 : ``,
  },
 
};
