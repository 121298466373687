export const navItems = {
  services: 'Servicios',
  benefits: 'Beneficios',
  stages: 'Etapas',
};

export const description =
'¿Desea transformar su espacio exterior en un oasis bello y funcional? No busque más y recurra a nuestros expertos servicios de diseño paisajístico. Nuestro equipo de experimentados diseñadores y arquitectos está aquí para ayudarle a convertir su visión en realidad.';


export const currentTexts = {
  label: 'Servicios',
  description:
    'En nuestros servicios de diseño paisajístico, ofrecemos una amplia gama de servicios para ayudarle a transformar su espacio exterior. Nuestros servicios incluyen',
};
export const processList = {
  labelFirst: 'Consulta y Análisis del Sitio',
  descriptionFirst: `Antes de comenzar cualquier proyecto de diseño paisajístico, empezamos con una consulta y un análisis del lugar. Esto incluye una visita in situ para evaluar las características existentes de su propiedad, las condiciones del suelo y la luz solar, y su visión y preferencias para el espacio. A partir de ahí, creamos un plan de diseño personalizado adaptado a sus necesidades y preferencias.`,

  labelSecond: 'Diseño conceptual',
  descriptionSecond: `A continuación, nuestro equipo desarrollará un diseño conceptual que incorpore sus ideas y preferencias, así como las recomendaciones de nuestros expertos. Utilizamos software de última generación para crear representaciones en 3D del diseño para que pueda visualizar el producto final antes de realizar cualquier trabajo.`,

  labelThird: 'Construcción e instalación',
  descriptionThird:
    'Una vez que apruebe el diseño final, nuestro equipo comenzará la construcción y la instalación. Utilizamos sólo materiales de la más alta calidad y trabajamos con contratistas de confianza para garantizar que cada aspecto del diseño se ejecute a la perfección.',

  labelFourth: 'Mantenimiento y conservación',
  descriptionFourth:
    'No sólo creamos bellos espacios exteriores, también ayudamos a mantenerlos. Nuestro equipo ofrece servicios continuos de mantenimiento y conservación para garantizar que su diseño paisajístico siga luciendo lo mejor posible durante años.',
};

export const currentTextsBenefits = {
  description:
  'Elegir nuestros servicios de diseño paisajístico conlleva una serie de beneficios, entre los que se incluyen:',
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Diseños personalizados adaptados a sus necesidades y preferencias únicas',
  },
  { id: 2, text: 'Software de última generación para renderizados en 3D' },
  { id: 3, text: 'Materiales de alta calidad y contratistas de confianza' },
  { id: 4, text: 'Servicios continuos de mantenimiento y conservación' },
  {
    id: 5,
    text: 'Equipo de diseñadores y arquitectos con experiencia y conocimientos',
  },
];
