import React, { FC, useState } from 'react';
import {
  HeaderSectionNavbarWrapper,
  PageHeaderSectionWrapper,
  NavigationBlock,
  LogoBlock,
  LogoTitle,
  Navigation,
  NavItem,
  ControlsBlock,
  TextBlock,
  SloganBlock,
  DescriptionBlock,
  ArrowBlock,
  BgImageWrapper,
  TitleBlock,
  TitleBlockWrapper,
  TextBlockWrapper,
  DescriptionBlockWrapper,
  Description,
  TextTopBlockWrapper,
  SecondLogoImgWrapper,
  SecondLogoImg,
  DropDownWrapperText,
  DropDownText,
  HeartIcon,
  TitleServicesWrapper,
  ServicesTitle,
} from './PageHeaderSection.styled';
import {
  NavItemProps,
  PageHeaderSectionProps,
} from './PageHeaderSection.types';
import { BluredButton } from '../BluredButton';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../theme/Theme.constants';
import { ReactComponent as Letter } from '../../../shared/assets/icons/smart-home/letter.svg';
import { ReactComponent as DownArrow } from '../../../shared/assets/icons/smart-home/arrow.svg';
import { ReactComponent as DrowDownIcon } from '../../../shared/assets/icons/drop-down-icon.svg';
import { useNavigate } from 'react-router-dom';
import {
  addServiceInFauvorite,
  isFavouriteCheck,
  mainSection,
} from '../../helpers/globalConstants';
import { ReactComponent as EmptyHeartIcon } from '../../../shared/assets/icons/favourite/empty-heart-icon.svg';
import { RoutePaths } from '../../helpers/routes';
export const PageHeaderSection: FC<PageHeaderSectionProps> = ({
  title,
  logoImg,
  navItems,
  description,
  withoutTitleBlock,
  bgImage,
  secondDescription,
  secondLogoImg,
  headerTitle,
  isDropDown,
  setIsDropDownOpen,
  isDropDownOpen,
  isServices,
  withoutBgColor,
  headerHeight,
  descriptionColor,
  withoutBorder,
  titleSize,

  backgroundColor,
  isPrivacy,
  id,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isFavourite, setIsFavourite] = useState(false);

  const bigNavigationLength = navItems && navItems.length > 5;

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });

  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });

  const handleNavItemClick = (routePath: string | undefined) => {
    if (!routePath) {
      return;
    }

    mainSection?.scrollTo(0, 0);
    navigate(routePath);
  };

  const handleChangeFauvoriteStatus = (id: number, title: string) => {
    addServiceInFauvorite(id, title);
    setIsFavourite(!isFavourite);
  };

  return (
    <PageHeaderSectionWrapper
      bgImage={bgImage}
      isDropDown={isDropDown}
      withoutBgColor={withoutBgColor}
      backgroundColor={backgroundColor}
      headerHeight={headerHeight}>
      <HeaderSectionNavbarWrapper
        isDropDown={isDropDown}
        isServices={isServices}>
        <NavigationBlock withoutTitleBlock={withoutTitleBlock}>
          <LogoBlock>
            {logoImg}

            {!isServices ? (
              <TitleServicesWrapper
                withoutTitleBlock={withoutTitleBlock}
                withoutBorder={withoutBorder}>
                {!isPrivacy && (
                  <ServicesTitle
                    to={RoutePaths.services}
                    onClick={() => handleNavItemClick(RoutePaths.services)}>
                    Services
                  </ServicesTitle>
                )}

                <LogoTitle
                  bigNavigationLength={bigNavigationLength}
                  withoutTitleBlock={withoutTitleBlock}
                  withoutBorder={withoutBorder}
                  isDropDown={isDropDown}>
                  {title}
                </LogoTitle>
              </TitleServicesWrapper>
            ) : (
              <LogoTitle
                bigNavigationLength={bigNavigationLength}
                withoutTitleBlock={withoutTitleBlock}
                withoutBorder={withoutBorder}
                isServices={isServices}
                isDropDown={isDropDown}>
                {title}
              </LogoTitle>
            )}

            {/* <LogoTitle
              bigNavigationLength={bigNavigationLength}
              withoutTitleBlock={withoutTitleBlock}
              withoutBorder={withoutBorder}
              isDropDown={isDropDown}>
              {title}
            </LogoTitle> */}
          </LogoBlock>
          <Navigation withoutTitleBlock={withoutTitleBlock}>
            {!isDropDown
              ? bigNavigationLength
                ? isDesktop && (
                    <>
                      {navItems.map((navItem: NavItemProps) => (
                        <NavItem
                          key={navItem.sectionId}
                          onClick={() => handleNavItemClick(navItem?.link)}>
                          {navItem.title}
                        </NavItem>
                      ))}
                    </>
                  )
                : isTablet && (
                    <>
                      {navItems?.map((navItem: NavItemProps) => (
                        <NavItem
                          key={navItem.sectionId}
                          href={`#${navItem.sectionId}`}
                          onClick={() => handleNavItemClick(navItem?.link)}>
                          {navItem.title}
                        </NavItem>
                      ))}
                    </>
                  )
              : navItems?.map((navItem: NavItemProps) => (
                  <DropDownWrapperText
                    key={navItem.sectionId}
                    onClick={() => setIsDropDownOpen((prev: boolean) => !prev)}>
                    <DropDownText>{navItem.title}</DropDownText>
                    <DrowDownIcon
                      className="drop-down-icon"
                      style={{
                        rotate: isDropDownOpen ? '180deg' : '',
                      }}
                    />
                  </DropDownWrapperText>
                ))}
          </Navigation>
        </NavigationBlock>
        {
          <ControlsBlock>
            {!withoutTitleBlock && (
              <HeartIcon
                isFavourite={id && isFavouriteCheck(id)}
                onClick={() => id && handleChangeFauvoriteStatus(id, title)}>
                <EmptyHeartIcon />
              </HeartIcon>
            )}
            {!isPrivacy && (
              <BluredButton
                title={
                  isTablet ? (
                    t('smartHome.HeaderSection.order')
                  ) : (
                    <Letter className="letter-button" />
                  )
                }
                handleClick={() => handleNavItemClick(RoutePaths.leadForm)}
              />
            )}
          </ControlsBlock>
        }
      </HeaderSectionNavbarWrapper>

      {!isDropDown && !withoutTitleBlock && !isServices && (
        <BgImageWrapper>
          <TextBlock>
            <SloganBlock titleSize={titleSize}>{title}</SloganBlock>
            <DescriptionBlock descriptionColor={descriptionColor}>
              {secondDescription ? secondDescription : description}
            </DescriptionBlock>
          </TextBlock>
          {navItems && (
            <ArrowBlock>
              <a href={`#${navItems[0].sectionId}`}>
                <DownArrow />
              </a>
            </ArrowBlock>
          )}
        </BgImageWrapper>
      )}
      {!isDropDown && withoutTitleBlock && !isServices && (
        <BgImageWrapper>
          <TextBlock withoutTitleBlock={withoutTitleBlock}>
            <TextBlockWrapper>
              <TextTopBlockWrapper>
                {secondLogoImg && (
                  <SecondLogoImgWrapper>
                    <SecondLogoImg src={secondLogoImg} />
                  </SecondLogoImgWrapper>
                )}

                <TitleBlockWrapper>
                  <TitleBlock>{headerTitle}</TitleBlock>
                </TitleBlockWrapper>
              </TextTopBlockWrapper>

              <DescriptionBlockWrapper>
                <Description>
                  {secondDescription ? secondDescription : description}
                </Description>
              </DescriptionBlockWrapper>
            </TextBlockWrapper>
          </TextBlock>
        </BgImageWrapper>
      )}
    </PageHeaderSectionWrapper>
  );
};
