import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { SliderSection } from '../../shared/components/PagesSliderSection';
import { Breakpoints } from '../../theme/Theme.constants';
import {
  InteriorPageWrapper,
  ServicesSectionWrapper,
  PortfolioSectionWrapper,
  PortfolioHeadWrapper,
} from './InteriorPage.styled';
import { PageServicesSection } from '../../shared/components/PageServicesSection';
import mobileSliderImg from '../../shared/assets/images/interior-page/slider-mobile-img.jpg';
import tabletSliderImg from '../../shared/assets/images/interior-page/slider-tablet-img.jpg';
import desktopSliderImg from '../../shared/assets/images/interior-page/slider-desktop-img.jpg';
import planning from '../../shared/assets/images/interior-page/planning.jpg';
import color from '../../shared/assets/images/interior-page/color.jpg';
import furniture from '../../shared/assets/images/interior-page/furniture.jpg';
import design from '../../shared/assets/images/interior-page/design.jpg';
import window from '../../shared/assets/images/interior-page/window.jpg';
import art from '../../shared/assets/images/interior-page/art.jpg';
import { useTranslation } from 'react-i18next';
export const InteriorPage: FC = () => {
  const menuItems = getMenuItems();
  const interiorInfo = menuItems[1];
  const { t } = useTranslation();
  const navItems = [
    {
      title: t('interiorPage.interiorNavItems.services'),
      sectionId: 'services-section',
    },
    {
      title: t('interiorPage.interiorNavItems.portfolio'),
      sectionId: 'portfolio-section',
    },
  ];

  const currentText = {
    label: t('interiorPage.interiorCurrentText.label'),
    description: t('interiorPage.interiorCurrentText.description'),
  };
  const currentPortfolioText = {
    label: t('architectPage.currentPortfolioTexts.label'),
    description: t('interiorPage.interiorCurrentPortfolioText.description'),
  };

  const isDesktopXXl = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.md - 1}px)`,
  });

  const isTablet = useMediaQuery({
    query: ` (min-width: ${Breakpoints.md}px) and (max-width: ${
      Breakpoints.xlg - 1
    }px)`,
  });
  const isTabletMaxWidth = useMediaQuery({
    query: ` (min-width: ${Breakpoints.md}px) and (max-width: ${
      Breakpoints.xxl - 1
    }px)`,
  });
  const currentImgSlide = isMobile
    ? mobileSliderImg
    : isTablet
    ? tabletSliderImg
    : isDesktop
    ? desktopSliderImg
    : '';

  const slides = [
    {
      id: 1,
      projectName: `${t('architectPage.projectsName.firstName')} 1`,
      img: currentImgSlide,
    },
  ];

  const servicesList = [
    {
      id: 1,
      text: t('interiorPage.interiorServicesList.planningText'),
      img: planning,
    },
    {
      id: 2,
      text: t('interiorPage.interiorServicesList.colorText'),
      img: color,
    },
    {
      id: 3,
      text: t('interiorPage.interiorServicesList.furnitureText'),
      img: furniture,
    },
    {
      id: 4,
      text: t('interiorPage.interiorServicesList.designText'),
      img: design,
    },
    {
      id: 5,
      text: t('interiorPage.interiorServicesList.windowText'),
      img: window,
    },
    { id: 6, text: t('interiorPage.interiorServicesList.artText'), img: art },
  ];
  return (
    <InteriorPageWrapper>
      <PageHeaderSection
        logoImg={interiorInfo.icon}
        title={interiorInfo.label}
        navItems={navItems}
        bgImage={interiorInfo.backgroundImg}
        description={t('interiorPage.interiorDescription')}
        id={interiorInfo.id}
      />

      <ServicesSectionWrapper id="services-section">
        <ProcessBlockHeader
          currentTexts={currentText}
          mobileTitleSize={'30px'}
          isTextAlignCenter={false}
          descriptionColor={'rgba(255, 255, 255, 0.7)'}
          titlePadding={'1.5625rem 2.25rem'}
          titleSize={isTabletMaxWidth ? '18px;' : isDesktopXXl ? '33px' : ''}
          descriptionMaxWidth={isDesktopXXl ? '700px' : ''}
        />
        <PageServicesSection servicesList={servicesList} />
      </ServicesSectionWrapper>

      <PortfolioSectionWrapper id="portfolio-section">
        <PortfolioHeadWrapper>
          <ProcessBlockHeader
            currentTexts={currentPortfolioText}
            mobileTitleSize={'30px'}
            descriptionColor={'rgba(255, 255, 255, 0.7)'}
            titleSize={isTabletMaxWidth ? '30px;' : isDesktopXXl ? '60px' : ''}
            descriptionMaxWidth={isDesktopXXl ? '700px' : ''}
          />
        </PortfolioHeadWrapper>

        <SliderSection
          slides={slides}
          isViewBtn
          isProjectName
          pageIcon={interiorInfo.icon}
          pageName={interiorInfo.label}
        />
      </PortfolioSectionWrapper>
    </InteriorPageWrapper>
  );
};
