import styled from 'styled-components';
import { colorVariables, transitions } from '../../../theme/Theme.constants';

export const Button = styled.button`
  background: none;
  border: 1px solid ${colorVariables.buttonBorder};
  color: ${colorVariables.mainTextColor};
  width: 100%;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  padding: 1rem;
  backdrop-filter: blur(2px);
  cursor: pointer;
  transition: ${transitions.buttonBlurHoverTransition};

  &:hover {
    backdrop-filter: blur(5px);
  }
`;
