import React, { FC, useState, useEffect } from 'react';
import { getMenuItems } from '../../components/MenuSection/MenuSection.constatnts';
import { ProcessBlockHeader } from '../../components/ProcessBlockHeader';
import { ProcessListBlock } from '../../components/ProcessListBlock';
import { PageHeaderSection } from '../../shared/components/PageHeaderSection';
import { PartnersSection } from './PartnersSection';
import { SetupsSection } from './SetupsSection';
import { SliderSection } from '../../shared/components/PagesSliderSection';
import Video from '../../shared/assets/video/solar-energy-video.mp4';
import {
  SolarEnergyWrapper,
  BenefitsSectionWrapper,
  PartnersSectionWrapper,
  SetupsSectionWrapper,
  ServicesSectionWrapper,
} from './SolarEnergyPage.styled';
import { useTranslation } from 'react-i18next';
import testSlide from '../../shared/assets/images/solar-energy/slider/slider-img.jpg';
import firstSlide from '../../shared/assets/images/solar-energy/slider/slider-img-1.jpg';
import secondSlide from '../../shared/assets/images/solar-energy/slider/slider-img-2.jpg';
import thirdSlide from '../../shared/assets/images/solar-energy/slider/slider-img-3.jpg';

import firstServiceImg from '../../shared/assets/images/solar-energy/services/first-img.jpg';
import secondServiceImg from '../../shared/assets/images/solar-energy/services/second-img.jpg';
import thirdServiceImg from '../../shared/assets/images/solar-energy/services/third-img.jpg';
import fourthServiceImg from '../../shared/assets/images/solar-energy/services/fourth-img.jpg';
import fifthServiceImg from '../../shared/assets/images/solar-energy/services/fifth-img.jpg';
import sixthServiceImg from '../../shared/assets/images/solar-energy/services/sixth-img.jpg';

import { Breakpoints } from '../../theme/Theme.constants';
import { PageServicesSection } from '../../shared/components/PageServicesSection';
import { useMediaQuery } from 'react-responsive';
import { VideoBlock } from '../../shared/components/VideoBlock';
export const SolarEnergyPage: FC = () => {
  const { t } = useTranslation();
  const menuItems = getMenuItems();
  const solarEnergyInfo = menuItems[4];
  const [isLoading, setIsLoading] = useState<boolean>(true); // when video upload

  const isTablet = useMediaQuery({
    query: `(min-width: ${Breakpoints.md}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoints.xlg}px)`,
  });
  const isDesktopXXl = useMediaQuery({
    query: `(min-width: ${Breakpoints.xxl}px)`,
  });
  const handleVideoReady = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const images = [firstSlide, secondSlide, thirdSlide];

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);
  const navItems = [
    {
      title: t('solarEnergyPage.headerSection.benefits'),
      sectionId: 'benefits',
    },
    {
      title: t('solarEnergyPage.headerSection.partners'),
      sectionId: 'partners',
    },
    {
      title: t('interiorPage.interiorNavItems.services'),
      sectionId: 'services',
    },
    // {
    //   title: t('solarEnergyPage.headerSection.setups'),  For future features
    //   sectionId: 'setups',
    // },
  ];

  const solarEnergyBenefitsTexts = {
    label: t('solarEnergyPage.solarEnergyBenefitsTexts.label'),
    description: t('solarEnergyPage.solarEnergyBenefitsTexts.description'),
  };
  const benefitsList = [
    { label: t('solarEnergyPage.benefitsList.firstLabel'), number: 1 },
    { label: t('solarEnergyPage.benefitsList.secondLabel'), number: 2 },
    {
      label: t('solarEnergyPage.benefitsList.thirdLabel'),
      number: 3,
    },
    {
      label: t('solarEnergyPage.benefitsList.fourthLabel'),
      number: 4,
    },
  ];
  const slides = [
    { id: 1, img: firstSlide },
    { id: 2, img: secondSlide },
    { id: 3, img: thirdSlide },
    // { id: 4, img: testSlide },
  ];

  const servicesTexts = {
    label: t('solarEnergyPage.servicesTexts.label'),
    description: t('solarEnergyPage.servicesTexts.description'),
  };
  const servicesList = [
    {
      id: 1,
      text: t('solarEnergyPage.servicesList.textFirst'),
      img: firstServiceImg,
    },
    {
      id: 2,
      text: t('solarEnergyPage.servicesList.textSecond'),
      img: secondServiceImg,
    },
    {
      id: 3,
      text: t('solarEnergyPage.servicesList.textThird'),
      img: thirdServiceImg,
    },
    {
      id: 4,
      text: t('solarEnergyPage.servicesList.textFourth'),
      img: fourthServiceImg,
    },
    {
      id: 5,
      text: t('solarEnergyPage.servicesList.textFifth'),
      img: fifthServiceImg,
    },
    {
      id: 6,
      text: t('solarEnergyPage.servicesList.textSixth'),
      img: sixthServiceImg,
    },
  ];
  return (
    <>
      <SolarEnergyWrapper bgImage={solarEnergyInfo.backgroundImg}>
        <VideoBlock video={Video} />
        <PageHeaderSection
          title={solarEnergyInfo.label}
          logoImg={solarEnergyInfo.icon}
          navItems={navItems}
          description={t('solarEnergyPage.solarEnergyDescription')}
          backgroundColor={'rgba(0, 0, 0, 0.5)'}
          id={solarEnergyInfo.id}
        />
        <BenefitsSectionWrapper id="benefits">
          <ProcessBlockHeader
            currentTexts={solarEnergyBenefitsTexts}
            isTabletPadding
            isBackgroundTextColor
          />
          <ProcessListBlock processList={benefitsList} anotherStyle />
        </BenefitsSectionWrapper>

        <PartnersSectionWrapper id="partners">
          <PartnersSection />
        </PartnersSectionWrapper>

        <ServicesSectionWrapper id="services">
          <ProcessBlockHeader
            currentTexts={servicesTexts}
            mobileTitleSize={'30px'}
            isTextAlignCenter={false}
            descriptionColor={'rgba(255, 255, 255, 0.7)'}
            titlePadding={'35px'}
            titleWrapperWidth={isDesktopXXl ? '65%;' : isDesktop ? '50%;' : ''}
            titleSize={
              isDesktopXXl
                ? '40px'
                : isDesktop
                ? '27px;'
                : isTablet
                ? '15px'
                : ''
            }
          />
          <PageServicesSection servicesList={servicesList} />
        </ServicesSectionWrapper>
        {/* <SetupsSectionWrapper id="setups">
          <SetupsSection />     For future features
        </SetupsSectionWrapper> */}

        <SliderSection slides={slides} />
      </SolarEnergyWrapper>
    </>
  );
};
