export const currentTexts = {
  label: 'Nuestros materiales',
  description: `En nuestros servicios de diseño paisajístico, ofrecemos una amplia gama de servicios para ayudarle a transformar su espacio exterior. Nuestros servicios incluyen:`,
};

export const textsBenefits = [
  {
    id: 1,
    text: 'Amplia selección de materiales de construcción de alta calidad',
  },
  { id: 2, text: 'Precios competitivos' },
  {
    id: 3,
    text: 'Personal experto con amplios conocimientos de los productos',
  },
  { id: 4, text: 'Cómodas opciones de entrega' },
  {
    id: 5,
    text: 'Excepcional servicio de atención al cliente',
  },
];

export const benefitsTitle = '¿Por qué elegirnos?';
export const materialsList = {
  titleFirst: 'Hormigón y mampostería',
  textFirst:
    'Ofrecemos una amplia gama de materiales de hormigón y mampostería, como ladrillos, bloques, adoquines y cemento. Estos materiales son perfectos para construir cimientos, muros de contención, patios y mucho más.',

  titleSecond: 'Madera y estructuras',
  textSecond:
    'Nuestra madera y materiales de entramado incluyen madera dimensional, contrachapado, OSB y mucho más. Estos materiales son esenciales para enmarcar paredes, techos y suelos.',

  titleThird: 'Tejados y revestimientos',
  textThird:
    'Ofrecemos una amplia gama de materiales para tejados y revestimientos, como tejas, tejados metálicos, revestimientos de vinilo y mucho más. Estos materiales son cruciales para proteger su estructura de las inclemencias del tiempo',

  titleFourth: 'Ventanas y puertas',
  textFourth:
    'Nuestra selección de ventanas y puertas incluye una variedad de estilos y materiales, como madera, vinilo y fibra de vidrio. Estos materiales proporcionan tanto funcionalidad como estética a su estructura.',

  titleFifth: 'Ferretería y herramientas',
  textFifth:
    'También ofrecemos una gama de ferretería y herramientas, como fijaciones, adhesivos, sierras y taladros. Estas herramientas y accesorios son esenciales para completar su proyecto de construcción con eficiencia y eficacia.',
};