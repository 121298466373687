export const philosophyTextsList = [
  {
    id: 1,
    label: 'Управління ризиками',
    description:
      'Ми застосовуємо дисциплінований підхід до управління ризиками, зосереджуючись на збереженні капіталу та уникненні безповоротних втрат.',
  },

  {
    id: 2,
    label: 'Ціннісне інвестування',
    description:
      'Ми прагнемо інвестувати у високоякісні компанії з сильними фундаментальними показниками, які торгуються з дисконтом до їхньої внутрішньої вартості.',
  },

  {
    id: 3,
    label: 'Довгострокова перспектива',
    description:
      'Ми застосовуємо терплячий і довгостроковий підхід до інвестування, зосереджуючись на фундаментальних основах бізнесу, в який ми інвестуємо.',
  },
];

export const philosophyTitle =
  'Наша інвестиційна філософія базується на трьох ключових принципах:';