export const LandscapeDesign = [
  {
    id: 1,
    title: "L'aménagement paysager:élément important ou caprice ?",
    description:
      `
      Les maisons de style espagnol, en particulier dans le climat méditerranéen de la Catalogne, vont de pair avec la création de jardins uniques. C'est là que se sont formés certains traits de l'art paysager local, qui influencent encore la conception des jardins de la région. L'une de ces caractéristiques est l'utilisation de fleurs fraîches pour décorer la façade de la maison. Des plantes en pot ou grimpantes sont souvent utilisées, ce qui confère à la maison un charme et une beauté particuliers. Un élément important est la création d'un coin pour passer un agréable moment avec les amis et les parents. Il peut s'agir d'un endroit décoré pour une fête ou une détente, où l'on peut profiter de l'atmosphère et de la communication. Un élément traditionnel populaire est le patio - un patio confortable avec des sièges ombragés et des meubles confortables. C'est un endroit où vous pouvez profiter de l'air frais et de l'intimité de votre propre jardin. L'abondance et la variété des plantes de toutes sortes dans les jardins catalans, des mousses aux plantes grimpantes, sont les bienvenues. Ici, les plantes en pot et en conteneur ajoutent des accents lumineux et créent des compositions intéressantes. L'aménagement paysager fait partie intégrante du marché de l'immobilier en Espagne. L'extérieur contribue à augmenter le prix de la propriété et à démontrer l'individualité du propriétaire.

      `,
  },
  {
    id: 2,
    title: "Combien de temps faut-il généralement pour élaborer et mettre en œuvre un projet paysager ?",
    description:
      `
      La conception et la mise en œuvre d'un projet d'aménagement paysager est un processus créatif et multiforme qui demande du temps et de l'attention aux détails. Le délai de réalisation d'un projet peut varier en fonction de l'ampleur, de la complexité et des exigences spécifiques du client. Les petits projets d'aménagement paysager, tels qu'un petit jardin ou une terrasse, peuvent être conçus et mis en œuvre en quelques semaines ou quelques mois. Dans ce cas, le processus comprend
Élaboration d'un plan de conception.
Sélection des plantes et des matériaux.
Effectuer des travaux de plantation et de décoration. Les projets de taille moyenne, tels que l'aménagement d'une zone suburbaine ou d'une propriété communale, peuvent nécessiter plusieurs mois de travail. Dans ce cas, le processus comprend une planification plus détaillée, la consultation du client, l'élaboration du concept et la visualisation en 3D, ainsi que la mise en œuvre de l'aménagement paysager, des éléments architecturaux et de l'infrastructure. Les projets plus importants et plus complexes, tels que l'aménagement de parcs ou de complexes publics, peuvent nécessiter des années de travail. Dans ce cas, le processus comprend
Planification à long terme.
Conception du plan directeur.
Participation aux approbations avec les agences gouvernementales et les autres parties prenantes.
Mise en œuvre progressive du projet. Nos experts s'efforceront de réaliser pour vous l'intérieur parfait dans les plus brefs délais. Ne remettez donc pas cela à demain, commandez le service dès aujourd'hui et découvrez les détails des conditions de travail.

      
      `,
  },
  {
    id: 3,
    title: 'Aménagement paysager : le faire soi-même ou faire appel à des professionnels ?',
    description:
      `
      
      L'image que l'on a en tête correspond parfois à la réalité. L'aménagement paysager est l'art de créer des espaces beaux et fonctionnels qui apportent joie et confort. Lorsque vous rêvez d'un jardin ou d'un terrain idéal, la question se pose de savoir si vous devez tout faire vous-même ou faire appel à des professionnels. Réaliser soi-même son aménagement paysager peut être un processus passionnant et créatif qui permet de donner vie à ses propres idées et préférences. En faisant tout vous-même, vous pouvez économiser de l'argent et contrôler entièrement le processus de mise en œuvre. Cela peut être particulièrement intéressant pour les personnes ayant certaines compétences et connaissances en matière d'aménagement paysager et de conception. Mais l'aménagement paysager est un processus complexe et multiforme qui nécessite une connaissance approfondie des plantes, des sols, des conditions climatiques, ainsi qu'une expérience en matière de planification et de composition. Une mauvaise planification ou sélection des plantes peut conduire à des résultats insatisfaisants et à une perte de temps et d'argent. Nos professionnels disposent de l'expertise et de l'expérience nécessaires pour créer un aménagement unique et fonctionnel, en tenant compte de vos souhaits et des caractéristiques du site. Ils seront en mesure de multiplier les avantages et de masquer les inconvénients du territoire, d'utiliser chaque mètre carré de la manière la plus compétente possible et de se concentrer sur le confort des résidents. Ils peuvent également vous faire gagner du temps et des efforts en utilisant efficacement les ressources.

      `,
  },
  {
    id: 4,
    title: "S'occuper soi-même de l'extérieur ou faire appel à des spécialistes ?",
    description:
      `
      Une fois le projet achevé, nos paysagistes peuvent vous offrir leur aide et des conseils d'entretien pour vous aider à préserver la beauté et la santé de votre paysage pour les années à venir. Ils peuvent vous conseiller sur l'arrosage, la fertilisation, la taille et l'entretien général des plantes. Après tout, chaque plante nécessite une approche unique, et les professionnels vous aideront à déterminer le régime d'entretien optimal. En outre, les paysagistes peuvent vous conseiller sur l'entretien d'autres éléments de votre jardin ou de votre extérieur, tels que les allées, les fontaines et les étangs artificiels. Ils partageront avec vous leurs connaissances sur le nettoyage et l'entretien appropriés de ces éléments afin qu'ils soient toujours attrayants et sûrs. En outre, les paysagistes peuvent vous aider à déterminer les travaux et l'entretien saisonniers dont votre jardin a besoin. Il vous indiquera comment préparer votre jardin pour l'hiver ou l'été, enlever correctement le feuillage, tailler les plantes ou effectuer les travaux d'entretien nécessaires. Si vous souhaitez gagner du temps, vous pouvez continuer à collaborer avec nous et nous vous aiderons à entretenir votre jardin.

      
      `,
  },
  {
    id: 5,
    title: 'Gazon roulé ou semis, quelle est la meilleure solution pour le site ?',
    description:
      `
      Voici quelques questions qui pourraient vous aider à prendre votre décision :
Quelle est la taille du terrain ? Les pelouses en rouleau sont généralement préférées pour les petites et moyennes surfaces, car elles peuvent être installées rapidement et donnent immédiatement un aspect complet au jardin. Les pelouses ensemencées conviennent mieux aux grandes surfaces, où un processus d'ensemencement plus long peut être justifié.
Combien de temps êtes-vous prêt à consacrer à la création d'une pelouse ? Les pelouses à semer nécessitent plus de temps. Les pelouses en rouleau donnent des résultats rapides, prêts à l'emploi après la pose.
Quel est votre budget ? Les pelouses ensemencées peuvent être plus économiques, en particulier pour les grandes surfaces. Les pelouses en rouleau sont généralement plus chères que les pelouses en graines, mais elles donnent des résultats plus immédiats.
Quel est le climat de votre région ? Certains types de gazon peuvent être mieux adaptés à certaines conditions climatiques, comme les régions sèches ou humides. Les pelouses de semis peuvent être plus flexibles en sélectionnant des variétés de gazon mieux adaptées à votre climat.
Quel effet esthétique souhaitez-vous obtenir ? Les pelouses roulées ont généralement un aspect plus uniforme et instantané, tandis que les pelouses semées peuvent créer un paysage plus naturel et progressif.

      
      `,
  },

  {
    id: 6,
    title: "Que choisir, l'arrosage automatique ou l'arrosage manuel des plantes ?",
    description:
      `
     Arrosage automatique :
L'arrosage automatique est très pratique, surtout si vous avez un grand jardin ou de nombreuses plantes. Vous pouvez définir le programme d'arrosage et le système surveillera de manière autonome l'arrosage régulier et uniforme des plantes sans votre présence.
L'arrosage automatique permet d'économiser le temps que vous passeriez à arroser manuellement chaque plante.
Les systèmes d'arrosage automatique sont souvent équipés de capteurs d'humidité qui détectent le moment où les plantes ont besoin d'être arrosées et ne fournissent de l'eau que lorsque c'est nécessaire. Cela permet d'éviter le gaspillage et de conserver l'eau. L'arrosage manuel :
L'arrosage manuel vous permet de contrôler le processus d'arrosage et d'interagir directement avec les plantes. Vous pouvez ainsi mieux observer leur état et réagir en cas de problème ou de besoin.
L'arrosage manuel vous permet de choisir l'endroit et la quantité d'arrosage pour chaque plante séparément. Vous pouvez adapter l'arrosage aux caractéristiques de chaque plante, à ses besoins en humidité et aux conditions environnementales.
L'irrigation manuelle ne nécessite pas l'installation d'un équipement ou d'un système spécial, ce qui peut s'avérer plus rentable que l'irrigation automatique. Pour choisir entre l'arrosage automatique et l'arrosage manuel, il faut tenir compte de la taille du jardin, du nombre de plantes, de la disponibilité de l'eau et des préférences en matière d'entretien des plantes. Pour des consultations supplémentaires et une analyse détaillée, vous pouvez contacter nos spécialistes.

      
      `,
  },
];

export const Interior = [
  {
    id: 1,
    title: 'Pourquoi ne pas se passer de nos services intérieurs ?',
    description:
      `
      Un projet d'aménagement intérieur est un outil clé qui vous aide à créer un espace harmonieux et fonctionnel qui correspond à votre style de vie. Voici quelques raisons pour lesquelles un projet d'aménagement fait partie intégrante d'une rénovation et d'une organisation intérieures réussies.
Le projet d'aménagement permet d'utiliser au mieux l'espace disponible. Il propose un placement intelligent des meubles, des accessoires et des zones fonctionnelles afin de créer un espace confortable et pratique qui réponde à vos besoins.
Il est important que l'intérieur reflète votre style de vie et réponde à vos besoins. Les concepteurs tiennent compte de vos préférences, de vos loisirs et de vos habitudes quotidiennes pour créer un intérieur fonctionnel et agréable pour vous et votre famille.
Le projet d'aménagement vous permet d'évaluer à l'avance tous les éléments de l'intérieur et d'imaginer comment ils se présenteront dans la réalité. À l'aide de visualisations et de plans, vous pouvez visualiser les différentes options de couleurs, de matériaux, de mobilier et de décoration et prendre des décisions en connaissance de cause.
L'élaboration d'un projet d'aménagement vous permet de déterminer le coût des travaux et de planifier un budget de réparation de manière plus précise. Vous pourrez voir à l'avance tous les coûts liés aux matériaux, au mobilier, aux finitions et aux services du concepteur et contrôler les coûts pendant le projet.
Le projet de conception permet d'éviter les erreurs lors des réparations et permet aux professionnels de prendre en compte tous les aspects techniques et les détails. Cela minimise la possibilité de problèmes inattendus et de mauvaises décisions et réduit le temps et le coût de la réparation des erreurs. La coopération avec Moderna Casa rendra tous les processus plus confortables et plus agréables.

      `,
  },
  {
    id: 2,
    title: "La décoration d'intérieur est-elle coûteuse ?",
    description:
      `
      Bien entendu, lors de l'élaboration d'un projet d'aménagement, nous tenons compte de votre budget. Nous comprenons que chaque client a ses propres capacités et limites financières. Notre objectif est donc de créer un intérieur qui reflète vos goûts et votre style de vie et qui corresponde à vos capacités financières. Nous nous efforçons d'équilibrer le style, la fonctionnalité et l'accessibilité financière afin de créer un intérieur qui soit à la fois esthétiquement agréable et économiquement viable. Notre équipe a l'habitude de travailler avec différents budgets, et nous sommes donc prêts à proposer des options qui répondent à toutes les attentes financières. Pendant que nous travaillons sur l'intérieur et la visualisation, nous pouvons également vous fournir des informations sur le coût approximatif des matériaux, du mobilier et des travaux nécessaires pour vous donner une idée des coûts possibles.

      
      `,
  },
  {
    id: 3,
    title: "Qu'est-ce que le service de contrôle de la phase de conception ?",
    description:
      `
      La supervision architecturale est une étape essentielle de la mise en œuvre d'un projet de conception, qui garantit la conformité des travaux de réparation et de construction avec le projet approuvé. Dans le cadre de la supervision de l'auteur, le concepteur et l'architecte contrôlent activement l'exécution de tous les travaux et veillent à ce que chaque étape corresponde à l'idée du projet. Cela comprend Contrôler en permanence le processus de construction. Le contrôle de la qualité des matériaux utilisés. La précision de la mise en œuvre des solutions architecturales. Les experts de Moderna Casa fournissent également des réponses rapides à toutes les questions ou problèmes qui se posent et conseillent le client sur les questions liées au design et à l'architecture.

      
      `,
  },

  {
    id: 4,
    title: "Quels sont les trois principaux éléments du processus d'aménagement intérieur ?",
    description:
      `
     Le projet de conception comprend plusieurs éléments importants qui contribuent à créer un concept complet et détaillé.
L'un des éléments clés est la visualisation en 3D, qui vous permet de visualiser chaque détail de votre espace. Grâce à cette technique, nous pouvons choisir les meilleurs matériaux et couleurs et évaluer la fonctionnalité de l'emplacement des meubles et autres objets, en tenant compte de vos souhaits.
La documentation technique comprend de nombreux plans et schémas pour la mise en œuvre correcte du projet. Il s'agit d'un plan de mesure, d'un plan de disposition du mobilier et des équipements, d'un plan d'installation et de démontage, d'un plan d'étage indiquant le type de revêtement de sol, d'un plan de plafond, d'un plan d'emplacement des prises et des interrupteurs, d'un plan de liaison des appareils avec un schéma de commutation, d'un programme de finition des murs, d'une méthode de raccordement des équipements sanitaires, de balayages des murs dans les salles de bains et les cuisines, indiquant la disposition des carreaux, ainsi que de plans pour le chauffage par le sol et les radiateurs. Lors de la mise en œuvre de ce paragraphe, nous prenons également en compte le cadre législatif actuel en Espagne.
La sélection des matériaux est un aspect essentiel du projet de conception. Tous les produits présentés sur notre site web peuvent se prévaloir d'une qualité et d'une durabilité élevées. Nous fournissons des recommandations et indiquons des matériaux de finition spécifiques, des meubles, des appareils de plomberie et d'éclairage. En outre, nous vous invitons à utiliser notre boutique en ligne, où vous pouvez acheter ces articles et matériaux. Tous ces éléments du projet de conception se conjuguent pour créer un concept d'intérieur complet et détaillé, de la visualisation en 3D à la documentation technique et à la sélection des matériaux, afin de réaliser vos souhaits et de créer l'espace parfait qui reflète votre style et vos préférences.

      
      `,
  },

  {
    id: 5,
    title: "Intérieur de style espagnol : à quoi ressemble-t-il ?",
    description:
      `
   Meubles de conception simple en ébène ou en acajou, fauteuils et chaises en cuir. Le style espagnol dans l'intérieur se distingue par des couleurs claires et chaudes et l'utilisation de matériaux naturels et d'éléments en pierre, en bois et en céramique. Les coussins décoratifs et les bougies créent une ambiance chaleureuse. Les meubles d'époque, les antiquités, les photos de famille et les peintures murales font partie des éléments décoratifs. Il n'y a pas de papiers peints ni de tapis, mais des murs peints, du carrelage et des planchers en bois. Les poutres en bois au plafond et les armoires dans les niches sont très appréciées. Il y a une grande table à manger pour les dîners en famille. La décoration comprend des photos dans des cadres en bois, de la vaisselle en céramique, des figurines, des vases et des bougies. Des carreaux de céramique peints à la main sont utilisés. Vous pouvez nous consulter et nous vous en dirons plus sur les caractéristiques de l'intérieur des pays méditerranéens. Nous créons également des projets d'aménagement dans d'autres styles et sommes à l'écoute de vos conseils et de vos préférences.

      
      `,
  },

   {
    id: 6,
    title: "Combien de temps dure le processus d'élaboration d'un concept d'intérieur ?",
    description:
      `
  L'élaboration d'un projet de design pour une maison privée en Espagne prend environ 25 à 45 jours et s'effectue parallèlement à la construction. Le concepteur-rédacteur s'occupe non seulement de l'élaboration du concept d'intérieur, mais aussi de la résolution des problèmes techniques et d'ingénierie. Pendant ce temps, le spécialiste effectue les tâches suivantes :
Préparation de dessins dimensionnels et de diagrammes de systèmes d'ingénierie.
Création d'une visualisation intérieure sur ordinateur et réalisation de collages.
Élaboration de schémas de conception pour le plafond, les murs et le sol.
Schéma de l'emplacement des portes, des prises et des lampes.
Organisation du chauffage par le sol et de la climatisation.
Rechercher et acheter les matériaux spécifiés dans le projet de conception de la maison.
Enregistrement de la documentation technique et juridique.
Coordination de la construction, contrôle des conditions et de la qualité des travaux.

      
      `,
  },
];

export const Architect = [
  {
    id: 1,
    title: `Combien de temps dure un projet architectural ?`,
    description:
      `
      Les conditions optimales de conception d'un bâtiment résidentiel individuel dépendent de l'interaction entre l'architecte et le client. Plus le retour d'information et l'approbation des solutions proposées sont efficaces, plus la conception sera rapide. En général, le processus prend entre un et trois mois, mais dans le cas d'objets complexes, l'élaboration d'un projet complet peut prendre jusqu'à six mois.
      `,
  },
  {
    id: 2,
    title: "Projet de conception et projet de travail - quelles sont leurs caractéristiques ?",
    description:
      `
      Le projet de conception et le projet de travail diffèrent par leurs caractéristiques et leur fonctionnalité. Le projet de conception est une donnée abstraite qui donne une idée de l'apparence et du concept de la future maison. En même temps, le projet détaillé est constitué de dessins et de documents détaillés et élaborés nécessaires à la construction proprement dite. Ces documents servent de guide direct et de base pour tous les travaux de construction.

      `,
  },
  {
    id: 3,
    title: "Quel projet est le moins cher : le projet standard ou le projet individuel ?",
    description:
      `
      Les coûts sont différents selon qu'il s'agit d'un projet original ou d'un projet standard. Un projet typique est généralement plus abordable, car il a déjà été développé et revendu après que les coûts initiaux ont été couverts. Cependant, le projet standard présente un inconvénient important puisqu'il a été créé en tenant compte de la zone spécifique, des services publics, de l'orientation du site et des conditions climatiques. Une solution standard ne peut satisfaire que partiellement vos désirs et vos exigences, de sorte que les modifications peuvent entraîner des coûts supplémentaires et retarder la construction. C'est pourquoi, en vous adressant à nos spécialistes, vous pouvez être sûr qu'un projet individuel sera élaboré pour vous, en tenant compte des recommandations.

      
      `,
  },

  {
    id: 4,
    title: "Quelles sont les étapes de la conception et de la construction d'une maison ?",
    description:
      `
     La construction d'une maison individuelle se déroule généralement comme suit : Les travaux de terrassement, s'il y a un permis de construire et un projet de maison finie.
Construction de la fondation.
Construction de murs et de plafonds.
Réalisation de travaux de couverture.
Installation de fenêtres.
Mise en place des communications internes et des réseaux d'ingénierie externes.
Travaux de finition intérieure, y compris les plafonds et les sols.
Installation de portes et d'escaliers.
Installation et connexion des équipements techniques.
Réalisation des finitions extérieures. Cette séquence permet de construire une maison par étapes, des fondations à l'extérieur, ce qui constitue une approche systématique et structurée de la construction.

      `,
  },

  {
    id: 5,
    title: "Quel est le style architectural le plus courant dans l'immobilier en Espagne ?",
    description:
      `
     Le style méditerranéen des maisons est populaire dans les provinces du sud de l'Espagne. Les façades sont généralement recouvertes d'un enduit blanc, les fenêtres et les portes sont cintrées, et l'on trouve également des galeries ouvertes et des terrasses. Les toits sont recouverts de tuiles brunes ou orangées. Ce style est bien adapté au climat chaud du sud de l'Espagne, offrant une protection contre le soleil et créant une sensation incroyable. À mesure que l'on s'approche du nord, l'architecture des maisons commence à changer. Vous trouverez des maisons de style colonial ou dont la façade est en pierre naturelle. Dans le style colonial, les murs sont généralement recouverts de plâtre brut ou de matériaux bruts, les sols sont en bois et des stores en bois sont installés aux fenêtres. Les maisons modernes de haute technologie sont de plus en plus populaires. La prédominance du verre et du métal dans la conception caractérise les formes et les lignes rectilignes. Ces maisons, en particulier celles qui ont une vue sur la mer, offrent de l'espace, de la visibilité et beaucoup de lumière.

      `,
  },

  {
    id: 6,
    title: "Quels sont les facteurs à prendre en compte lors de l'aménagement de l'espace intérieur d'une maison ?",
    description:
      `Déterminez les principaux objectifs et besoins de votre maison. Disposez les pièces de manière à ce que chaque zone soit utilisable et qu'il y ait une séquence logique de déplacement entre elles.
Tenez compte de la circulation naturelle à l'intérieur de la maison. Créez des chemins pratiques d'une pièce à l'autre, en évitant les couloirs étroits et encombrés.
Maximisez l'utilisation de la lumière naturelle - placez les fenêtres de manière à maximiser la lumière naturelle et à créer une ambiance intérieure agréable.
Tenez compte du besoin d'intimité dans les différentes zones de la maison. Placez les chambres à coucher et les salles de bains dans des parties éloignées de la maison, et laissez les parties communes plus ouvertes.
Le rapport entre la taille et les proportions des pièces est essentiel pour créer une impression visuelle harmonieuse et agréable. Tenez compte des proportions correctes lorsque vous placez les meubles et les accessoires.
Pensez à créer des espaces multifonctionnels pouvant être utilisés à des fins diverses. Par exemple, un salon-salle à manger combiné ou un bureau, qui peut également servir de chambre d'amis.
Prévoyez suffisamment d'espace de rangement. Placez des armoires ou des penderies encastrées pour utiliser efficacement l'espace et garder les choses organisées.
Tenez compte de votre style et de vos préférences lors du choix des finitions, de la palette de couleurs et de la décoration. Créez une atmosphère qui reflète votre personnalité et crée une impression agréable. Les experts de Moderna Casa peuvent fournir des consultations plus détaillées et des recommandations sur l'élaboration d'un projet architectural. Nous ferons en sorte que votre maison soit confortable pour tous les membres de la famille.

      `,
  },
];

export const ModularHouses = [
  {
    id: 1,
    title: `Combien de temps faut-il pour assembler une maison modulaire ?`,
    description:
      `La construction rapide et pratique des maisons modulaires attire de nombreuses personnes. Ces maisons sont assemblées à partir de grandes parties de murs et de plafonds, qui sont livrées sur le chantier. L'assemblage dure de deux semaines à un mois et demi, après quoi commencent les finitions et l'installation des équipements techniques. Les modules prêts à l'emploi contenant une demi-pièce ou plusieurs pièces isolées sont livrés sur des plates-formes de chargement spéciales et installés sur des fondations préparées à l'avance. L'installation express prend de deux à dix jours, après quoi la maison est prête à être occupée. Les maisons modulaires sont idéales pour ceux qui veulent être ouverts à plusieurs endroits et préfèrent se déplacer. Par exemple, si vous devez vous déplacer fréquemment pour des raisons professionnelles, vous pouvez déménager sans vous soucier de trouver un nouveau logement. Votre maison sera toujours là, comme une coquille d'escargot. L'assemblage d'une maison modulaire commence généralement par la pose du premier module sur les fondations et sa fixation. Le module suivant est ensuite déposé et fixé au précédent, et ainsi de suite. Après l'assemblage, les joints sont scellés et l'on procède ensuite à l'installation du toit.`,
  },
  {
    id: 2,
    title: "Quelle est la solidité et la durabilité des maisons modulaires ?",
    description:
      `
      La durabilité des maisons modulaires dépend de divers facteurs tels que la technologie, les matériaux et le contrôle de la qualité utilisés. En nous choisissant, vous pouvez être sûr que nous n'utilisons que des matériaux de haute qualité et que nous travaillons avec intégrité. Les structures principales d'une maison peuvent durer plus de 50 ans, ce qui est impressionnant. La durée de vie de l'isolation est un peu plus difficile à déterminer, car il n'existe pas de pratique à long terme de l'utilisation de matériaux d'isolation thermique modernes. Toutefois, à condition qu'il n'y ait pas d'humidité, on peut supposer que l'isolation durera environ 20 à 25 ans. De telles statistiques peuvent être suivies dans les territoires espagnols, où il n'y a pas de forte humidité ni de pluie. Il est important de noter que l'usure physique rend rarement une maison inutilisable. Au contraire, une telle maison peut devenir moralement obsolète, et les propriétaires peuvent décider de la vendre ou de la reconstruire pour qu'elle réponde aux exigences et aux goûts modernes. Vous pouvez donc nous contacter à tout moment pour rafraîchir vos mètres carrés.

      `,
  },
  {
    id: 3,
    title: "Quelle est la popularité des maisons modulaires en Espagne ?",
    description:
      `
      Les maisons modulaires ont gagné en popularité en Europe ces dernières années, et leur attrait n'est pas seulement dû à la réduction des coûts, mais aussi à la possibilité d'offrir une solution pour surmonter la crise économique causée par la pandémie de Covid-19. De tous les pays européens, c'est l'Espagne qui utilise le plus activement les maisons modulaires. Cependant, l'histoire des maisons modulaires remonte à l'après-guerre. C'est à cette époque qu'est apparu ce type de logement, construit rapidement et à peu de frais pour répondre à l'énorme demande d'espace habitable. Le terme "modulaire" signifie que la maison est construite à partir de modules individuels. Chaque module est créé indépendamment. La majeure partie du travail se fait en usine, où les modules individuels sont fabriqués à partir de différents matériaux. Ces modules sont ensuite livrés sur le terrain de l'acheteur et sont finalement assemblés pour créer une maison sur place. Cette technologie est populaire en Espagne, car le climat loyal et les caractéristiques du sol, les conditions météorologiques et d'autres facteurs permettent de ne pas se préoccuper de la nécessité d'une fondation et de fortifications supplémentaires.

      
      `,
  },

  {
    id: 4,
    title: "Quels sont les mythes sur les maisons modulaires qu'il convient de démystifier ?",
    description:
      `
     "Les maisons modulaires ont l'air d'être des formules toutes faites". Lorsque l'on construit une nouvelle maison à partir de zéro, il est possible de la concevoir entièrement, en tenant compte de tous les souhaits du futur propriétaire. Toutefois, dans le cas des maisons modulaires, la réponse n'est pas aussi claire. La complexité de la mise en œuvre de l'idée, la qualité des finitions et la superficie influent sur le coût du logement. Bien que la finition selon les souhaits du client soit possible, les entreprises proposent souvent des modèles prêts à l'emploi. Si vous vous écartez de la conception classique, le prix peut augmenter, de même que le temps de création.
"Il est impossible d'agrandir la maison. Les maisons modulaires et traditionnelles peuvent être agrandies si la structure, les lois et le site le permettent. De nombreuses maisons modulaires sont conçues de manière à ce que les modules puissent être ajoutés et agrandis. Il est donc facile d'ajouter de l'espace. L'extension d'une maison traditionnelle est un processus plus complexe et plus long.
"Les maisons modulaires ne sont pas une question de solidité et de fiabilité. Avec toutes les exigences et un entretien régulier, les maisons modulaires peuvent durer de 50 à 70 ans, tout comme les maisons construites de manière traditionnelle.
"Une simple boîte sans caractéristiques distinctives". Auparavant, les maisons modulaires étaient considérées comme trop simples. Aujourd'hui, elles représentent un exemple de style moderne. Les maisons traditionnelles peuvent avoir n'importe quel aspect, selon les goûts et les préférences stylistiques de leurs propriétaires.

      
      `,
  },

  {
    id: 5,
    title: "Quels sont les principaux avantages des maisons modulaires en Espagne ?",
    description:
      `
     L'un des grands avantages des maisons modulaires est qu'elles ne nécessitent pas de travaux de terrassement. La plupart des travaux sont effectués en dehors du site de construction. Lorsque les modules sont livrés sur le site, ils sont simplement assemblés, ce qui n'implique pas beaucoup de débris ou de saletés. Cela signifie que vous n'avez pas à vous soucier des déchets de construction dispersés ni du nettoyage après la construction. Deuxièmement, les maisons modulaires sont souvent attirées par leur respect de l'environnement et leur efficacité énergétique. Cela est dû à l'optimisation de la production et de l'utilisation des matériaux, en tenant compte des facteurs environnementaux. Grâce à cela, les maisons modulaires peuvent être plus efficaces sur le plan énergétique et plus respectueuses de l'environnement. En outre, cette approche de la construction réduit les déchets et utilise les ressources de manière plus efficace. Les maisons modulaires offrent une grande variété et une haute qualité des matériaux utilisés. Par exemple, le métal et le béton sont les matériaux les plus populaires pour la construction de maisons modulaires en Espagne. Les maisons modulaires en Espagne sont considérées comme des structures temporaires qui n'affectent pas la géodésie du site. Un autre avantage des maisons modulaires est le minimum de bureaucratie pendant leur construction. Pour construire une telle maison, il n'est pas nécessaire d'obtenir des permis ou des licences, comme c'est le cas pour les maisons traditionnelles. Cela signifie que vous pouvez construire une maison modulaire sur presque n'importe quel site, même s'il n'y a pas de permis de construire. Cela vous donne plus de flexibilité et simplifie le choix de l'emplacement de votre future maison.

      
      `,
  },
];



export const SolarEnergy = [
  {
    id: 1,
    title: "Est-il rentable d'utiliser des panneaux solaires ?",
    description:
      `Oui, en Espagne, l'installation de panneaux solaires devient un choix de plus en plus attrayant et judicieux. Lors de l'évaluation de l'efficacité d'un système pour la consommation privée d'électricité, il faut tenir compte du fait que la durée de vie des panneaux solaires est d'environ 25 ans et que l'investissement est généralement amorti en 7 ans environ. Cela signifie que les propriétaires de systèmes solaires en Espagne ont non seulement accès à une énergie propre et renouvelable, mais qu'ils ont également la possibilité de réduire considérablement leurs coûts énergétiques sur une longue période. De plus, les panneaux solaires permettent de réduire l'exposition aux fluctuations des prix de l'énergie et d'atténuer l'impact des variations du coût de l'électricité.
Peut-on éviter les prix élevés de l'électricité ?
Le soleil est une ressource inestimable. En Espagne, les prix de l'énergie sont aujourd'hui presque trois fois plus élevés que la moyenne des cinq dernières années. Grâce à son ensoleillement abondant, l'Espagne mise sur les panneaux solaires comme source d'énergie alternative. L'amortissement est beaucoup plus rapide qu'au Royaume-Uni ou en Suède.
Quel sera l'avenir de l'énergie solaire en Espagne ? D'ici à 2030, l'Espagne prévoit d'assurer près de 75 % de sa production d'énergie à partir de sources alternatives, alors que le chiffre actuel est d'environ 47 %. L'Espagne s'est engagée en faveur d'un avenir durable basé sur des sources d'énergie propres.
`,
  },
  {
    id: 2,
    title: "L'entretien des panneaux solaires est-il difficile ?",
    description:
      `
      L'utilisation de panneaux solaires implique un processus simple et peu coûteux d'entretien des panneaux solaires. Bien sûr, c'est un peu plus difficile que de ne rien faire lorsque votre maison est connectée aux réseaux électriques publics, mais ce n'est pas plus difficile qu'avec n'importe quel autre appareil ménager - machine à laver, réfrigérateur - qui nécessite un entretien. Si votre installation a été achetée auprès de fournisseurs de confiance, vous bénéficiez, en tant que client, d'un service complet d'entretien et de réparation de votre installation solaire. Par vous-même, vous pouvez nettoyer les panneaux de la saleté et ainsi prolonger leur durée de vie.

`,
  },

  {
    id: 3,
    title: "Dans quels autres pays les panneaux solaires sont-ils demandés ?",
    description:
      `
     En Chine, plus de 40 millions de foyers sont déjà équipés de chauffe-eau solaires, ce qui témoigne d'une forte demande pour cette technologie. Pékin a également prévu d'installer 300 millions de mètres carrés de panneaux solaires d'ici à 2020. L'Espagne prend également des mesures actives en faveur du développement de l'énergie solaire. Depuis mars 2006, la loi exige que toutes les nouvelles maisons en Espagne soient équipées de panneaux solaires. Cependant, le leader mondial de l'utilisation de l'énergie solaire par habitant est Israël. Ce pays progresse en installant plus de panneaux solaires par personne que n'importe quel autre pays.

`,
  },
   {
    id: 4,
    title: "L'énergie solaire est-elle utile pour l'eau chaude et le chauffage ?",
    description:
      `L'énergie solaire permet de l'utiliser à la fois pour le chauffage des locaux et pour la production d'eau chaude. La production d'eau chaude par l'énergie solaire est l'une des façons les plus efficaces d'utiliser cette source d'énergie. Cependant, le chauffage des locaux nécessite une surface de panneaux solaires beaucoup plus importante que le chauffage de l'eau. Il est important de noter que le chauffage solaire est avantageux en hiver lorsqu'une chaleur supplémentaire est nécessaire. En revanche, pendant l'été, les grands panneaux solaires doivent être protégés contre la surchauffe. L'eau chaude chauffée par l'énergie solaire peut circuler dans des systèmes de chauffage par le sol ou alimenter des radiateurs pour maintenir une température intérieure confortable. Pour obtenir une efficacité maximale, le système doit être conçu pour fonctionner à des températures de fluide basses afin de maximiser l'énergie solaire disponible.
`,
  },
   
   {
    id: 5,
    title: "Quelle est la durée de vie des panneaux solaires ?",
    description:
      `
      Les panneaux solaires ont une durée de vie impressionnante, comparable à celle d'autres appareils électroniques. S'ils sont utilisés et entretenus correctement, ils peuvent continuer à fonctionner pendant des décennies. Le coefficient de dégradation des modules solaires photovoltaïques, qui dépend de la qualité et de la fabricabilité des produits, joue un rôle clé dans la détermination de la durée de vie. Le scénario optimal suppose que dans 20 à 25 ans, l'installation solaire produira environ 80 à 85 % de sa capacité d'origine. En d'autres termes, elle continuera à contribuer de manière significative à la production d'électricité. Il est important de noter qu'après cette période, les panneaux solaires ne tombent pas instantanément en panne. Ils peuvent continuer à fonctionner, mais avec une certaine dégradation des performances. Même après l'expiration de la durée de vie garantie, l'installation solaire peut continuer à fonctionner et à fournir des avantages pendant des années supplémentaires. Les garanties des fabricants jouent également un rôle important. Ils offrent généralement une garantie de 5 ans contre les dommages mécaniques pour les produits de moyenne gamme et de 10 à 12 ans pour les produits haut de gamme. Cela signifie que l'acheteur peut être sûr de la fiabilité et de la qualité des panneaux solaires pendant une certaine période.

`,
  },
];

export const Inmobiliaria = [
  {
    id: 1,
     title: "Pourquoi est-il important d'analyser le sol et le terrain ?",
    description:
      `
      Le terrain et le sol déterminent les propriétés fondamentales d'un site, telles que sa stabilité et sa capacité à supporter une structure.
Les études géologiques et géotechniques réalisées par des spécialistes permettent de déterminer si le sol est apte à la construction. Nos experts vérifient la densité du sol, sa portance et sa résistance à la déformation. Ceci est important pour s'assurer que votre bâtiment ne risque pas de s'enfoncer, de s'effondrer ou de se déplacer.
Selon les experts de Moderna Casa, la topographie et le sol jouent également un rôle dans le drainage et l'approvisionnement en eau du site. Une planification et un placement corrects de la maison sur le site permettent d'éviter les problèmes d'eau stagnante et de mauvaise évacuation des eaux usées. Il est donc possible de déterminer les emplacements optimaux pour placer les systèmes de drainage et d'assainissement.
Le terrain et le sol peuvent avoir un impact sur l'agencement et l'architecture d'un bâtiment. Les irrégularités du terrain peuvent nécessiter des travaux supplémentaires d'excavation et de terrassement afin de créer un terrain plat pour la construction. Ayant analysé le terrain à l'avance grâce à notre service immobilier, nous pouvons vous indiquer un coût plus précis des services.
La connaissance du sol permet de choisir les méthodes de fondation les mieux adaptées aux conditions spécifiques du site. Par exemple, une zone plane peut offrir des possibilités de construction ultérieure ou de création d'un espace de jardin.

      `,
  },

  {
    id: 2,
     title: "Comment effectuer une analyse comparative de plusieurs parcelles pour choisir la bonne ?",
    description:
      `
      Dresser une liste des critères qui seront utilisés pour comparer les parcelles. Ces critères peuvent inclure l'accessibilité, la valeur du terrain, l'infrastructure, les facteurs environnementaux, le potentiel de développement futur, etc. Déterminer l'importance de chaque critère afin de mettre en évidence les aspects les plus significatifs.
Recueillir toutes les données disponibles pour chaque site, y compris les plans, les cartes, l'infrastructure, l'évaluation environnementale, les prix des terrains et les permis.
Analyser chaque site à l'aide de critères d'évaluation prédéfinis. Évaluer chaque critère sur une échelle ou un système de notation. Vous pouvez utiliser des chiffres ou des classements pour rendre les comparaisons plus objectives.
Compte tenu de l'importance de chaque critère, attribuez-leur des coefficients de signification. Par exemple, si l'accessibilité est le critère le plus important, vous pouvez lui donner plus de poids qu'aux autres facteurs.
Analysez les scores de chaque site et tenez compte de la pondération des critères. Comparez les résultats et déterminez la section qui obtient la note globale la plus élevée. Il s'agira de l'endroit le plus approprié pour la construction.
Outre l'analyse comparative, le budget du projet, les restrictions légales, les réglementations locales et les permis doivent être pris en compte. Le service immobilier implique également ce type d'analyse. Nos experts sont spécialisés dans le marché de l'immobilier et vous aideront à réaliser des analyses et à collecter tous les documents nécessaires.

      `,
  },

  {
    id: 3,
     title: "Comment procéder à l'analyse de l'infrastructure ?",
    description:
      `
     Ce service comprend également l'analyse de l'infrastructure. Nous sommes attentifs à :
Les établissements d'enseignement pour enfants, tels que les écoles et les jardins d'enfants.
Pharmacie, de préférence ouverte 24 heures sur 24 et 7 jours sur 7.
Hôpital ou salle d'urgence, au moins dans les environs immédiats.
Clinique vétérinaire, indispensable pour les agriculteurs qui veulent une maison en Espagne pour développer les cultures et le bétail.
La présence d'une route asphaltée qui facilite l'accessibilité du site.
Distance par rapport à la localité la plus proche.
La proximité de gares automobiles et ferroviaires facilite les communications.
Des épiceries, des marchés et des centres commerciaux pour faciliter les achats quotidiens.
Installations de divertissement, parcs et places pour la récréation et les loisirs.
Disponibilité de structures de protection contre les inondations éventuelles en cas d'inondations.
Le nombre et le type de routes adjacentes au site et l'encombrement de la route selon les jours et les saisons. Par exemple, si l'hôpital le plus proche se trouve à 15 kilomètres du site et que vous devez emprunter une route non goudronnée, cela peut constituer un inconvénient désagréable. Le service "Immobilier" aide à analyser le niveau de confort possible qui peut être fourni à l'acheteur du site.

      `,
  },
  {
    id: 4,
     title: "Pourquoi l'analyse des eaux souterraines est-elle nécessaire ?",
    description:
      `
     L'eau est un danger potentiel pour les structures des bâtiments, en particulier les fondations. Les maisons construites sur des sites où le niveau de la nappe phréatique est élevé sont exposées à des dégâts d'eau quotidiens, contrairement aux maisons où le niveau de la nappe phréatique est bas. Cela peut entraîner un affaissement des fondations, des fissures dans les murs, l'inondation des sous-sols et la formation de moisissures. L'impact sur les fondations n'est pas seulement dû à l'eau souterraine elle-même. Les produits chimiques dissous dans l'eau (par exemple, les sulfates, les sels) peuvent également avoir de graves conséquences. Ils peuvent provoquer le détachement et la dissolution du béton, ce qui se manifeste par l'apparition d'une couche blanche et d'un décollement des fondations. Le drainage d'un site où le niveau de la nappe phréatique est élevé permet d'éviter toutes ces conséquences négatives. Et le service immobilier aidera à évaluer la situation avant même la fondation de manière exhaustive.

      `,
  },

  {
    id: 5,
     title: "Pourquoi est-il impossible de se passer d'une analyse de terrain ?",
    description:
      `
   Lors du choix d'un terrain pour la construction, il faut toujours tenir compte du relief. Les experts de Moderna Casa affirment qu'un terrain vallonné peut être magnifique dans l'aménagement paysager mais créer des difficultés lors de la construction d'une maison. Il est conseillé de choisir un terrain plat, sans virages ni falaises. Il est préférable d'opter pour un site surélevé, qui offre une protection contre les inondations et dont le prix est généralement plus élevé.


      `,
  },

  {
    id: 5,
     title: `Pourquoi ne pas se passer du service "Immobilier" ?`,
    description:
      `
  Les difficultés sont toujours nombreuses lors des phases préparatoires, et les pièges les plus dangereux apparaissent souvent au bout d'un certain temps. La tâche de nos experts est de vous aider à reconnaître ces risques. Si, à l'avenir, vous décidez de vendre le terrain, vous devez tenir compte des facteurs qui influencent sa valeur :
La présence d'immeubles de plusieurs étages dans un rayon de 150 mètres. La hauteur des bâtiments permet aux résidents d'observer la zone environnante, ce qui peut susciter des inquiétudes.
La proximité de hangars et de fermes. Cela peut entraîner des bruits inutiles et des odeurs désagréables liés à l'élevage.
Les bâtiments abandonnés. Ils peuvent devenir des lieux de résidence pour des personnes peu responsables socialement, ce qui crée des problèmes potentiels en matière de sécurité et d'environnement du quartier.
La présence de cimetières. Certains acheteurs peuvent les percevoir comme un élément indésirable affectant l'esthétique et l'écologie de la région environnante.
La proximité de lignes électriques (lignes à haute tension). Certaines recherches ont établi un lien entre ces lignes et d'éventuels effets sur la santé, ce qui peut dissuader les acheteurs.
La présence de coopératives de garages. Elles peuvent affecter l'ambiance générale et l'aspect visuel du développement environnant. Tenir compte de tous ces facteurs lors de l'achat d'un bien immobilier permet de réduire les risques de problèmes lors d'une vente ultérieure. Et nos experts vous aideront à parcourir ce chemin avec vous.


      `,
  },

  
];

export const Consulting = [
 {
    id: 1,
     title: `Quels sont les services de conseil sur le marché de l'immobilier en Espagne ?`,
    description:
      `
  C'est l'occasion de consulter des personnes compétentes et de confier à des experts une analyse de la situation actuelle du marché et d'autres questions directement ou indirectement liées à l'immobilier en Espagne. Cette analyse est une évaluation professionnelle de l'infrastructure d'un bâtiment existant ou en projet. Son objectif principal est d'identifier les vulnérabilités et le mauvais fonctionnement de l'installation qui peuvent conduire à une rentabilité insuffisante. Les experts analysent également les bâtiments qui n'en sont qu'au stade de la planification. Ce processus permet d'identifier les lacunes qui, une fois corrigées, rendront l'objet plus attrayant pour les résidents, les clients et les locataires.


      `,
  },

  {
    id: 2,
     title: `Qu'est-ce qu'un conseil global ?
`,
    description:
      `Le conseil en matière de projets comprend plusieurs éléments importants. Tout d'abord, il s'agit d'évaluer le marché, le public cible et le pouvoir d'achat des clients potentiels pour lesquels la construction de l'installation est prévue. Cela permet de déterminer la pertinence du projet et sa viabilité commerciale. Deuxièmement, il s'agit d'évaluer la qualité esthétique et urbanistique des locaux et d'analyser la demande de solutions architecturales de ce type parmi les clients potentiels. Cela est important pour créer un espace attrayant et confortable qui réponde aux besoins des futurs utilisateurs. Le troisième aspect comprend la vérification de la conformité de la documentation technique avec les normes et les standards afin de garantir la sécurité et la qualité de la construction. Des études sont également menées sur les attentes de la population vivant à proximité du projet afin de prendre en compte ses besoins et de lui fournir les services et équipements les plus appropriés. Une étape importante consiste à vérifier la conformité aux lois et règlements afin que le projet dispose de tous les permis nécessaires. Après avoir bénéficié de nos services de conseil, vous pouvez passer aux étapes de la conception et de la construction d'une maison, que nos experts assurent également. Enfin, le concept du bâtiment est élaboré et un plan de commercialisation est mis au point. Il s'agit d'identifier les caractéristiques et les avantages uniques du projet, ainsi que les stratégies de marketing et de promotion visant à attirer les clients intéressés et à mettre le bien sur le marché avec succès.


      `,
  },

  {
    id: 3,
     title: `Qu'est-ce que le conseil juridique ?

`,
    description:
      `L'immobilier est un domaine complexe et réglementé qui comporte de nombreuses lois, règles et procédures à respecter. Les conseils juridiques dans le domaine de l'immobilier sont d'une grande importance pour la protection des droits et des intérêts des clients, ainsi que pour la réussite des transactions et des opérations. Si, dans son pays d'origine, il est plus facile pour un acheteur ou un investisseur de comprendre les règles et les lois par lui-même, les investisseurs étrangers dans l'immobilier en Espagne devraient s'informer des pièges auprès d'experts. L'une des principales raisons de solliciter un conseil juridique est de garantir la sécurité juridique. Les avocats analysent les documents et les transactions pour s'assurer de leur légalité et mettre en garde contre les risques juridiques éventuels. Ils aident les clients à éviter les problèmes et les litiges inattendus liés à l'immobilier et fournissent des conseils et des solutions pour résoudre les problèmes juridiques. Des conseils juridiques sont nécessaires pour l'examen juridique des documents. Il s'agit notamment de vérifier les contrats de vente, les baux, les actes de propriété et d'autres documents juridiques. L'exactitude et l'exhaustivité des documents sont essentielles à la réussite d'une transaction immobilière, et les juristes aident leurs clients à éviter les erreurs et les problèmes éventuels liés à des documents mal exécutés. Nos experts expliquent les termes et procédures juridiques complexes, les règles et lois régissant l'immobilier, et aident les clients à comprendre leurs droits et obligations. Ils recommandent les meilleures pratiques et stratégies pour atteindre les résultats souhaités et mettent en garde contre les risques potentiels.

      `,
  },

  {
    id: 4,
     title: `Quelles sont les demandes les plus fréquentes que nous recevons ?


`,
    description:
      `
      Consultations orales à l'heure où nos experts répondent à vos questions et vous conseillent sur les différents aspects du bien.
Un avis consultatif en matière d'investissement pour vous aider à prendre des décisions éclairées en matière d'investissement immobilier et à évaluer le rendement potentiel de votre investissement.
L'étude marketing des marchés immobiliers comprend les segments résidentiels, de bureaux, de commerces et de loisirs, d'hôtels, d'industries et d'entrepôts. Nous analysons la demande, l'offre et la concurrence sur ces marchés, vous aidant ainsi à comprendre le potentiel et les perspectives de développement des différents segments de l'immobilier.
Services de planification conceptuelle qui aident à développer des concepts de construction et de développement immobilier efficaces et innovants, en tenant compte des facteurs du marché, de l'économie, des besoins des clients et des exigences légales.
Analyse express de la situation sous la forme d'un avis professionnel, qui fournit une évaluation rapide et objective de la situation actuelle et des recommandations sur les mesures à prendre.
Préparer un mémorandum d'investissement qui décrit le projet, son potentiel, ses risques et les résultats escomptés afin d'attirer des investisseurs potentiels.
Analyse de la documentation urbanistique et des perspectives de développement du projet, qui comprend l'étude des plans d'urbanisme, la coordination avec les exigences des organismes publics et l'évaluation des perspectives de mise en œuvre du projet.

      `,
  },
];

export const SmartHome = [
  {
    id: 1,
    title: 'Maison intelligente : installer le système ou non ?',
    description:
      `
      Une maison intelligente n'est pas seulement une nouveauté technologique, mais un mode de vie moderne qui est devenu la nouvelle norme dans le monde de l'immobilier en Espagne. La maison intelligente rend votre vie pratique, efficace et agréable et met en valeur la technologie de pointe dans votre maison. Le système de domotique peut réduire considérablement la consommation d'énergie dans l'appartement. Grâce à la vidéosurveillance, vous pouvez facilement contrôler la sécurité de vos enfants et de votre maison, même lorsque vous n'êtes pas chez vous. Et quels regards surpris attendent vos amis lorsque vous fermez les stores en prononçant une seule phrase, par exemple "Siri, séance de cinéma", puis que vous éteignez les lumières, et que le lecteur allume votre série télévisée préférée - c'est tout simplement magique. Une tendance salvatrice conçue pour aider à optimiser divers processus domestiques.

      `,
  },
  {
    id: 2,
    title: "Puis-je comprendre le système moi-même ?",
    description:
      `
      Oui, sans aucun doute. La "maison intelligente" a été créée pour simplifier la vie de chacun. Nos experts aideront le client à comprendre les principes de fonctionnement des différents systèmes lors de la phase de sélection. Après avoir testé l'équipement dans la salle d'exposition, tous les doutes concernant le propriétaire potentiel disparaissent complètement. En l'espace d'une semaine, vous vous habituerez à votre nouvel assistant et ne pourrez plus vous en passer.
      `,
  },
  {
    id: 3,
    title: "Y aura-t-il des problèmes de réparation si vous disposez d'un système domestique intelligent ?",
    description:
      `
      Tout dépend du traitement et du stade de la réparation. Il est conseillé de notifier la disponibilité du système avant le début des travaux de finition afin de tenir compte de la nécessité du câblage. Nos experts vous aideront à installer le système à la fois lors de la phase initiale de la construction d'une maison et après l'installation du système dans une maison achevée. Les spécialistes tiendront compte des caractéristiques de l'installation du système et éviteront d'installer des éléments inutiles dans la maison.
      `,
  },

  {
    id: 4,
    title: "Quelles sont les étapes de l'installation d'un système domestique intelligent ?",
    description:
      `
      L'installation se fait en deux étapes : la première est réalisée après l'achèvement des travaux de gros œuvre, y compris le câblage ; la deuxième étape a lieu après les travaux de finition et comprend la connexion et la configuration du système. En général, l'ensemble du processus d'installation prend environ une semaine, mais le temps peut varier en fonction de la complexité et de la quantité de fonctionnalités nécessaires.

      `,
  },

  {
    id: 5,
    title: "Combien de temps dure l'installation d'une maison intelligente ?",
    description:
      `
     Le processus d'installation du système ne prendra pas plus de deux semaines, sous réserve des exigences suivantes :
L'achèvement de la finition des locaux et l'absence d'autres travaux susceptibles d'interférer avec l'installation.
Fournir un système d'égout, d'électricité et d'approvisionnement en eau fonctionnant pleinement dans la pièce.
Libre accès aux panneaux électriques permettant de couper les lignes électriques auxquelles l'équipement est connecté.
Disponibilité d'un accès libre à tous les points d'installation des composants du système.
Le maintien d'une température stable dans la pièce entre +18° et +30°. Compte tenu de ces conditions, les spécialistes installeront le système dans les délais impartis, en garantissant l'efficacité et la qualité du travail.

      `,
  },

  {
    id: 6,
    title: "Qu'en sera-t-il de la consommation d'électricité ?",
    description:
      `
     À la grande surprise de nombre de nos clients, les systèmes domestiques intelligents consomment très peu d'électricité et contribuent même à réduire les factures (d'environ 30 %) en les avertissant des dépenses inutiles. Par exemple, les détecteurs de mouvement permettent d'éviter la consommation inutile d'électricité. Mais c'est au niveau de la gestion du système climatique que les économies les plus importantes peuvent être réalisées. En plus du système de maison intelligente, vous pouvez commander des panneaux solaires chez nous pour bénéficier d'avantages complets, être respectueux de l'environnement, économiser votre propre argent et maximiser le potentiel de votre maison.

      `,
  },

   {
    id: 7,
    title: "Que se passe-t-il dans la maison lorsque l'Internet est coupé ?",
    description:
      `
   Les maisons intelligentes sont dotées de systèmes câblés qui fonctionnent indépendamment d'une connexion à l'internet. Si la connexion au réseau est perdue, la "maison intelligente" continuera à fonctionner comme d'habitude grâce à l'ingénierie fournie, coordonnée avec le fonctionnement des appareils ménagers. Les systèmes sans fil sont les seuls à présenter certaines limites. Cependant, il vaut la peine d'envisager l'achat d'une alimentation sans interruption pour les cas de coupure de courant, ce qui est également utile pour tous les appareils ménagers.

      `,
  },
   
   {
    id: 8,
    title: "Le système peut-il être contrôlé manuellement ?",
    description:
      `
Oui, il est possible de laisser la commande du système en mode manuel, surtout si un enfant en bas âge ou une personne âgée a besoin d'assurer sa sécurité. Ainsi, il est garanti que le poêle de la cuisine ne sera pas allumé par erreur. Cependant, grâce à l'interface intuitive, vous pouvez facilement maîtriser les fonctionnalités de contrôle tout au long de la journée. Vous pouvez opter pour une utilisation plus simple du système et utiliser l'application sur votre téléphone.

      `,
  },
   
   {
    id: 9,
    title: "Maison intelligente : ce système convient-il uniquement aux logements ?",
    description:
      `Cette technologie innovante est idéale pour les immeubles résidentiels, les bureaux, les immeubles d'entreprise et les centres d'affaires. Le système peut offrir de nombreux avantages à votre entreprise, qu'il s'agisse d'un système de sécurité fiable ou de la commande à distance de l'éclairage, du chauffage, des rideaux et des stores. Il est préférable de planifier et d'installer ces systèmes avant le début des réparations ou le plus tôt possible. Cependant, qu'il s'agisse d'une nouvelle rénovation ou de locaux existants, les spécialistes expérimentés de Moderna Casa, qui sont versés dans l'installation de technologies intelligentes, feront de leur mieux pour minimiser les conséquences négatives du bâtiment et vous offrir la meilleure solution en tenant compte des spécificités de votre situation.

      `,
  },
];

export const SwimmingPools = [
  {
    id: 1,
    title: 'Que peut-on ajouter à la piscine ?',
    description:
      `
      Installation d'un "débit d'eau artificiel". Une excellente option pour ceux qui aiment l'activité physique. Même dans les petites piscines, cet appareil permet de pratiquer la natation sportive et l'hydromassage en même temps.
Le geyser de fond offre à chaque membre de la famille un massage à l'air qui crée une sensation d'apesanteur et enrichit l'eau en oxygène. Cet ajout aidera les plus jeunes membres de la famille à commencer rapidement et avec plus d'assurance à rester sur l'eau. Quant aux adultes, ils seront ravis de constater qu'après un tel massage, la fatigue, l'insomnie et la mauvaise humeur disparaissent.
La cascade n'est pas seulement une question d'esthétique et de beauté extérieure, c'est aussi un massage efficace pour la ceinture scapulaire. C'est aussi le complément idéal pour créer une atmosphère de détente et de plaisir dans la piscine. Et l'option de rétro-éclairage créera une sensation de confort au cœur de la nuit.
Le toboggan aquatique est le rêve de tous les enfants. Ils peuvent le parcourir inlassablement pendant des heures, en s'amusant et en se divertissant. Dans le catalogue de notre société, vous trouverez une large sélection d'accessoires de piscine, et nos spécialistes expérimentés vous aideront à choisir l'option parfaite qui répond à vos exigences et à vos souhaits. Nous ne proposons que des attractions fonctionnelles et de la plus haute qualité. Nos spécialistes savent comment faire de votre piscine une solution non triviale et fonctionnelle.

      `,
  },
  {
    id: 2,
    title: "Choisir le type de piscine : skimmer ou débordement ?",
    description:
      `
      Bassin à skimmer : l'eau est filtrée par des skimmers de surface et renvoyée par des buses murales.
Piscine à débordement : l'eau est versée dans le réservoir de stockage, passe par les systèmes de filtration, de chauffage et de désinfection, et revient par les buses de fond. Le choix du type de piscine vous appartient, mais si vous en avez la possibilité et le budget, une piscine à débordement est l'option à privilégier. Cependant, une piscine à skimmer peut également être de grande qualité si tous les calculs et technologies nécessaires sont respectés. Nous sommes prêts à vous aider à installer n'importe quelle piscine.

      `,
  },
  {
    id: 3,
    title: "Quelles sont les possibilités d'automatisation et de contrôle des piscines ?",
    description:
      `
      Si vous regardez de plus près nos services, vous verrez un service tel que la "maison intelligente". Il est conçu non seulement pour être utilisé à l'intérieur de la maison, mais aussi pour les éléments extérieurs.
Ce mécanisme permet d'automatiser les processus de filtrage et de maintien de la qualité de l'eau de la piscine. Vous pouvez contrôler et ajuster le fonctionnement des filtres, des pompes, de la désinfection et des systèmes de chauffage de l'eau par le biais d'une application sur votre smartphone ou d'un panneau de contrôle.
Le système "smart home" permet de gérer intelligemment la température de l'eau de la piscine. Vous pouvez régler la température souhaitée à l'aide de votre téléphone portable et le système la maintiendra automatiquement, y compris en activant et en désactivant les chauffe-eau.
Chaque personne a la possibilité de contrôler l'éclairage autour de la piscine, y compris les lumières sous-marines, à l'aide du système "smart home". Cela permet de créer différentes atmosphères et ambiances autour de la piscine.
La sécurité et l'alarme constituent un autre avantage. Vous pouvez recevoir des notifications sur l'état de la piscine, comme un changement de niveau d'eau ou la détection de mouvements autour de la piscine, afin d'éviter tout accident.
Établissez un calendrier pour le système de la piscine et créez des scripts automatiques. Par exemple, vous pouvez programmer la mise en marche et l'arrêt des systèmes de filtration et de chauffage, et créer des scènes qui allument les lumières et la musique de fond lorsque vous utilisez la piscine. Ce ne sont là que quelques exemples de ce que les systèmes domestiques intelligents peuvent faire pour automatiser et gérer votre piscine. Nous vous aiderons à tirer le meilleur parti de l'intégration du mécanisme d'automatisation en nous contactant.

      `,
  },

  {
    id: 4,
    title: "Quels sont les matériaux les plus appropriés pour la finition de la surface intérieure de la piscine ?",
    description:
      `
      Les matériaux les plus appropriés pour la décoration intérieure des piscines sont les suivants :
Les carreaux de céramique sont un matériau populaire et durable pour la décoration des piscines. Il présente une bonne résistance à l'eau, aux produits chimiques et aux UV et est facile à nettoyer.
La fibre de verre est considérée comme un matériau durable. Elle crée une surface lisse et offre une bonne protection contre la corrosion et les UV.
La résine époxy garantit un revêtement durable et imperméable pour les piscines. Elle présente une résistance chimique élevée et peut supporter de lourdes charges.
La membrane en PVC est un matériau souple qui peut recouvrir la surface intérieure de la piscine. Elles sont très résistantes aux produits chimiques et durent longtemps.
La peinture polyuréthane est largement utilisée pour la décoration des piscines. Elle offre une surface lisse et durable, résistante aux produits chimiques et aux rayons UV. Nous vous recommandons de consulter nos professionnels pour choisir le matériau adapté à vos besoins et à l'état de votre piscine.

      `,
  },

   {
    id: 5,
    title: "Quels sont les types d'entretien et de maintenance requis pour la piscine ?",
    description:
      `
      Les services et l'entretien suivants sont nécessaires pour assurer le bon fonctionnement et la qualité de la piscine :
Enlever les débris, les feuilles et autres saletés de la piscine à l'aide d'une épuisette ou d'un aspirateur de piscine.
Contrôler et corriger le niveau de chlore, l'équilibre du pH et d'autres indicateurs chimiques de l'eau. Assurer la sécurité de l'eau et empêcher le développement de bactéries et d'algues nocives.
Vérifier et nettoyer le filtre de la piscine. Le filtre élimine les particules fines et les impuretés de l'eau. Un nettoyage régulier permet de maintenir un fonctionnement efficace du système de filtration.
Entretien du système de chauffage de l'eau (si le système est installé). Il peut s'agir de vérifier et d'entretenir les équipements de chauffage tels que les chaudières, les pompes à chaleur ou les capteurs solaires.
Inspection et entretien de la pompe de la piscine. La pompe fait circuler l'eau de la piscine et doit être entretenue pour filtrer et traiter l'eau efficacement.
Contrôle de tous les systèmes de sécurité, y compris la présence et le fonctionnement du traceur (bouée de sauvetage), des interrupteurs et des dispositifs de signalisation. Ces services et cette maintenance sont importants pour que la piscine reste sûre, propre et fonctionne correctement. C'est pourquoi nous sommes prêts à être vos assistants fiables dans cette voie et à fournir des services systématiques de qualité.

      `,
  },
   
   {
    id: 6,
    title: "Comment assurer la sécurité dans la piscine, en particulier pour les enfants ?",
    description:
      `
      Il est essentiel pour nous de ne pas seulement vous vendre une piscine, mais aussi de garantir une grande sécurité pour chaque membre de la famille. Pour ce faire, les mesures suivantes doivent être prises :
Installez une clôture fiable autour de la piscine, qui empêchera les enfants d'accéder à l'eau sans autorisation.
S'il est impossible d'observer la piscine, utilisez des couvertures de protection telles que des bouées de sauvetage ou des couvertures de piscine pour empêcher les enfants de tomber accidentellement dans l'eau.
Les adultes doivent toujours être à proximité des enfants près de la piscine. Une surveillance régulière et vigilante de la part des adultes permet d'éviter les situations dangereuses.
Expliquez aux enfants les règles de sécurité à respecter dans la piscine. Il est important de leur expliquer qu'ils ne doivent pas jouer près de la piscine sans la présence d'un adulte ou sauter dans l'eau sans autorisation.
Les équipements de sauvetage tels que les traceurs, les bouées ou les bouées de sauvetage doivent toujours être disponibles à proximité de la piscine. En cas d'urgence, ils peuvent être utilisés pour aider. Écrivez-nous et nous vous montrerons comment rendre votre piscine pratique, belle et bien pensée, et sûre pour tout le monde.

      `,
  },
   
   {
    id: 7,
    title: "Puis-je choisir moi-même l'équipement de la piscine ?",
    description:
      `
      Il est possible de choisir soi-même l'équipement de sa piscine, mais de sérieux problèmes doivent être résolus. Il faut étudier de nombreux livres et documents, comprendre les principes du traitement de l'eau, calculer le diamètre des canalisations et la vitesse de circulation de l'eau, choisir l'équipement adéquat, et bien plus encore. Il ne s'agit pas d'une occasion d'économiser de l'argent, car il faut y consacrer beaucoup de temps. S'adresser à Moderna Casa, spécialisée dans l'amélioration des performances de votre maison et dans la vente d'équipements pour piscines, c'est la garantie de prix compétitifs et de produits de qualité.

      `,
  },
];


export const Investments = [
   {
    id: 1,
    title: `Cela vaut-il la peine d'investir dans une résidence secondaire ?`,
    description:
      `
      Les données sur les prix des résidences secondaires en Espagne pour janvier 2023, fournies par l'indice des prix immobiliers Idealista, nous permettent d'examiner la situation de plus près. En moyenne, les prix des logements secondaires ont augmenté de 5,4 %, atteignant le niveau de 1 922 euros par mètre carré. Malgré cela, la croissance des prix de l'immobilier au niveau national est restée inférieure au taux d'inflation de 5,8 % en janvier. Si l'on examine les capitales espagnoles, on constate que dans 16 villes, la hausse des prix des résidences secondaires a dépassé le taux d'inflation. La croissance la plus importante a été enregistrée à Santa Cruz de Tenerife (+18,7%), Alicante (+13,2%), Valence (+11,4%), Guadalajara (+11,3%), Cuenca (+10,8%), Palma (+9,9%), Séville (+8.1%), Malaga (+8%), Segovia (+9,9%), Pontevedra (+8,3%), Badajoz (+7,3%), Huelva (+7,2%), La Coruña (+6,8%), Tarragona (+6,6%), Granada (+6,3%) et Pamplona (+5,8%). En outre, 30 autres capitales ont connu une hausse des prix des logements, mais ceux-ci sont restés inférieurs au taux d'inflation. Parmi ces villes, les grands marchés de Madrid (+4,9%) et de Barcelone (+3%) se distinguent. Le mois de janvier 2023 a apporté des changements dans les prix des résidences secondaires en Espagne ; il y a une augmentation des prix. Par conséquent, décider d'acheter une propriété de revente aujourd'hui et de la revendre plus tard peut constituer un excellent coussin financier pour l'investisseur.

      `,
  },
  {
    id: 2,
    title: `Quels sont les avantages supplémentaires dont bénéficie un investisseur en Espagne ?`,
    description:
      `L'Espagne offre l'un des programmes les plus attrayants pour l'obtention d'un permis de séjour (permis de résidence) et, par la suite, de la citoyenneté. Pour participer à ce programme, vous devez acheter une propriété d'une valeur d'au moins 500 000 euros. Après avoir vécu dans le pays pendant 10 ans, vous pourrez demander la citoyenneté européenne avec tous les avantages qui y sont associés. L'Espagne est une destination attrayante pour l'investissement immobilier, en particulier pour les pays dont l'économie est instable. Ce pays occupe la quatrième place en termes d'économie dans la zone euro et la douzième dans l'économie mondiale. L'investissement dans l'immobilier y est rentable et fiable. Divers experts estiment que le rendement de ces investissements se situe dans une fourchette de 5 à 15 % par an. Les appartements ou les maisons de ville situés dans les stations balnéaires les plus populaires sont particulièrement attrayants pour les investisseurs. Cela s'explique par l'énorme popularité de l'Espagne auprès des touristes du monde entier. Ici, un excellent climat, une belle côte méditerranéenne, un développement économique et social élevé et un riche patrimoine culturel ont été synchronisés avec succès. Tous ces facteurs rendent l'Espagne très attrayante pour l'investissement immobilier.
      `,
  },

  {
    id: 3,
    title: `Pourquoi nous choisir pour investir ?`,
    description:
      `
      De nombreuses personnes choisissent d'investir de manière indépendante, mais ont besoin de plus de connaissances pour le faire de manière sûre et compétente. Un assistant fiable est donc indispensable. Nous sommes spécialisés dans le développement et la mise en œuvre de solutions optimales pour les investisseurs en Espagne. Notre objectif est de vous aider à obtenir des revenus passifs élevés. Nous analysons les perspectives économiques de l'investissement dans l'immobilier en Espagne et aidons nos clients à investir leur argent de manière rentable. Nous nous efforçons de créer un portefeuille d'investissement avec une grande précision stratégique, en tenant compte de tous les risques potentiels pour l'investisseur. Nous fournissons une gamme complète de services pour assurer un revenu élevé sur le marché de l'immobilier en Espagne. Plus de la moitié de nos clients nous confient la gestion et l'administration complexe après l'achèvement de la transaction. Nous suivons régulièrement les innovations législatives afin de garantir la sécurité de vos fonds et le respect des lois.

      `,
  },

  {
    id: 4,
    title: `Quelles sont les caractéristiques des biens immobiliers les plus rentables ?`,
    description:
      `
      Lors du choix d'une propriété, certains critères essentiels doivent être pris en compte. L'un de ces critères est la vue panoramique sur la mer ou la nature depuis la fenêtre, qui ne doit pas être sous-estimée. Une étude de TripAdvisor a révélé que pour 82 % des voyageurs, une vue inspirante depuis leur fenêtre est l'élément le plus important de leur voyage. Par conséquent, attirer les visiteurs et les acheteurs de maisons de vacances nécessite des efforts considérables. En outre, les appartements espagnols situés dans d'autres zones bénéficient de commodités et d'une excellente infrastructure de transport à proximité. Prenez en compte d'autres critères lorsque vous choisissez une résidence secondaire en tant qu'investissement immobilier. Que vous louiez une propriété ou que vous envisagiez de la vendre, il est essentiel de disposer des caractéristiques et des équipements adéquats. L'ajout d'équipements modernes tels qu'une connexion Wi-Fi rapide, des téléviseurs haute définition, des salles de bains et des espaces communs propres et élégants peut considérablement renforcer l'attrait des résidences de vacances. Dans les régions chaudes, les salles à manger en terrasse, l'éclairage d'ambiance ou les chambres supplémentaires ajoutent souvent de la valeur. En outre, il est important d'estimer avec précision le coût de l'entretien d'une maison pour garantir un retour sur investissement. Il faut notamment tenir compte des frais de nettoyage, d'entretien, de jardinage et de réparation.

      `,
  },
  {
    id: 5,
    title: `Investissements et Golden Visa : quelles perspectives ouvrent-ils ?`,
    description:
      `Le visa d'or est un visa qui permet aux investisseurs non ressortissants de l'Union européenne et à leur famille de devenir résidents en Espagne. Les membres de votre famille immédiate, y compris les conjoints et les enfants mineurs, se verront accorder la résidence en Espagne si vous obtenez un Golden Visa. Cela s'applique également aux enfants adultes à charge. L'obtention d'un visa d'or en Espagne vous permet de vivre dans le pays et d'accéder à de nombreux avantages dont les résidents espagnols sont les seuls à pouvoir bénéficier. Qui peut demander un Golden Visa en Espagne ? Toute personne qui achète une maison d'une valeur supérieure à 500 000 euros peut demander ce visa, à condition que le demandeur apporte au moins ce montant avec ses propres fonds, qu'il ait investi dans des obligations d'État ou qu'il possède des actions ou des dépôts bancaires d'une valeur supérieure à 1 000 000 d'euros. Peuvent également demander ce visa les personnes qui réalisent en Espagne des projets commerciaux d'intérêt public. Il s'agit de tous les projets qui créent des emplois et ont un impact socio-économique dans la zone géographique où ils sont mis en œuvre, ainsi que de tous les projets qui contribuent à l'innovation scientifique ou technologique dans le pays. Quelle est la durée de validité du Golden Visa en Espagne ? Ce visa vous permet de résider en Espagne pendant au moins un an. À l'issue de cette période, vous pouvez demander un permis de séjour d'une durée de deux ans. Sous réserve d'investissements et d'autres exigences, la durée de validité maximale d'un permis de séjour est de 5 ans.

      `,
  },
  
];
export const Construction = [
  {
    id: 1,
    title: `Quels sont les types de maisons privées qui nous sont le plus souvent commandés ?`,
    description: `
      En Espagne, les logements privés attirent l'attention par leurs caractéristiques, qui diffèrent des objets typiques de cette catégorie dans les autres pays de l'UE. Les types de maisons suivants sont très répandus dans ce pays :
Les villas sont des maisons spacieuses de plus de 100 m², avec de vastes terrains, des piscines et des espaces de détente.
Les chalets sont des maisons compactes d'une superficie maximale de 110 m² avec de petits espaces environnants. En Espagne, le style méditerranéen traditionnel ou le style high-tech sont souvent choisis pour la conception des maisons privées. Ces styles présentent des éléments de l'architecture espagnole, tels que des fenêtres cintrées, des voûtes, des mosaïques, des carreaux colorés, etc.

      
      `,
  },

  {
    id: 2,
    title: `Existe-t-il des restrictions en matière de construction ?`,
    description: `
     Lors de la construction de logements privés en Espagne, deux paramètres principaux sont pris en compte : la hauteur maximale autorisée du bâtiment et ses dimensions. Bien que la législation définisse les étapes de la construction, les normes sont souvent révisées. Un terrain destiné à la construction doit faire l'objet d'un document spécial - Ficha Urbanistica - qui contient les principales caractéristiques autorisées des objets. Chaque région possède également ses propres réglementations locales qui régissent des aspects tels que :
Hauteur maximale des objets privés ;
Conception des façades ;
Le style du toit et d'autres caractéristiques. Ces normes sont fixées dans le permis de construire, sans lequel toute construction est illégale.

      
      `,
  },

  {
    id: 3,
    title: `Quels sont les facteurs à prendre en compte lors de l'aménagement de l'espace intérieur d'une maison ?`,
    description: `
     Pour aménager parfaitement l'espace, il est important d'analyser toutes les caractéristiques potentielles de la future maison. Nos experts ont préparé pour vous une liste de recommandations :
Les locaux de la maison doivent être divisés en zones fonctionnelles (résidentielle, utilitaire, publique, privée).
Une disposition rationnelle des zones et des connexions pratiques contribueront à accroître le confort et à réduire l'utilisation des couloirs.
Les zones résidentielles et commerciales doivent être séparées les unes des autres.
Les locaux auxiliaires doivent être cachés mais accessibles à tout moment.
Il est recommandé d'isoler les zones privées, telles que les chambres à coucher, et de les dissimuler dans des coins éloignés de l'espace public.
Tenez compte de la composition de la famille, des groupes d'âge et du mode de vie lorsque vous planifiez votre logement.
Évitez de croiser des zones sales et des zones propres afin d'empêcher la propagation de la saleté. Par exemple, un garage doit avoir une sortie séparée.
Combinez des pièces aux fonctionnalités similaires et délimitez-les à l'aide d'objets d'intérieur.
Il est recommandé d'avoir deux entrées dans la maison - une principale et une supplémentaire.
Lors de l'élaboration d'un projet, il faut tenir compte des communications futures. L'aménagement comporte vraiment beaucoup de détails, et seuls des spécialistes expérimentés pourront aborder avec compétence le zonage des futurs logements. En vous adressant à nous, vous pouvez être sûr que nous tiendrons compte de vos détails et que nous rendrons chaque mètre carré de votre espace fonctionnel, réfléchi, sûr et ergonomique.

      
      `,
  },

  {
    id: 4,
    title: `Quelles sont les catégories de terres ?`,
    description: `
     Lorsque vous avez choisi un site qui correspond à votre emplacement, à votre taille et à vos préférences en matière de paysage, il est recommandé de contacter un architecte professionnel et de lui demander un rapport sur la catégorie et les caractéristiques du site sélectionné. Il convient de distinguer trois types de terrains :
Suelo Urbano : Ces sites sont situés à l'intérieur des limites de la ville et se prêtent à un développement résidentiel rapide. Il existe déjà des routes et les communications nécessaires (électricité, gaz, eau).
Suelo urbanisable : la construction sur ces sites n'est possible qu'après une préparation préalable et l'approbation d'un plan détaillé par la municipalité. Ces zones sont souvent soumises à des exigences particulières en matière de protection de l'environnement.
Suelo no urbanizable : cette île est située dans une zone protégée où la construction d'immeubles résidentiels est interdite et où une autorisation n'est délivrée que dans des cas exceptionnels. Lors de l'analyse, l'architecte doit décrire clairement toutes les restrictions qui s'appliquent à un site particulier. Par exemple, il peut y avoir des exigences strictes en matière de distance entre les maisons voisines, une interdiction d'abattre certains vieux arbres, des difficultés de raccordement à l'électricité, au gaz et à l'eau courante.

      
      `,
  },

  {
    id: 5,
    title: `Quelle est la durée du processus de construction ?`,
    description: `
     D'après l'expérience de nos experts, la construction d'une maison en Espagne prend généralement environ 18 mois et passe par plusieurs étapes clés. Au début du processus, il est nécessaire de sélectionner et d'acheter un terrain qui réponde aux exigences de la construction. Il faut ensuite élaborer le projet principal et préparer des devis détaillés pour l'exécution des travaux. L'obtention des autorisations nécessaires auprès des autorités locales et le paiement des taxes dans la commune sont également des étapes importantes. La conclusion d'un contrat avec une entreprise contractante et la construction directe comprennent les communications, la construction des fondations et la décoration intérieure. À la fin du processus, une inspection finale de la municipalité est effectuée pour obtenir un permis d'exploitation, l'enregistrement des documents de propriété et le raccordement à l'électricité, à l'eau et à l'éclairage dans le nouveau logement. Si vous vous adressez à nous, nous vous aiderons à rendre le processus aussi confortable et rapide que possible.

      
      `,
  },

  {
    id: 6,
    title: `Quels sont les documents nécessaires pour commencer la construction ?`,
    description: `
     Lorsque la municipalité approuve la construction de votre maison privée, votre architecte peut commencer à élaborer un plan détaillé et à estimer le coût des matériaux et des travaux (PEM - El Presupuesto de Ejecución Material). Vous devez ensuite trouver une entreprise de construction fiable avec laquelle conclure un contrat, ce qui nécessite les documents suivants :
Licencia de obra mayor (permis de construire) de l'Ayuntamiento (municipalité).
Documents confirmant que vous êtes propriétaire du terrain.
Vérifier la faisabilité technique de la connexion aux communications centralisées.
Conception détaillée du bâtiment.
Photocopies du plan général de développement de la zone.
Un acte indiquant les limites du site et les bâtiments existants. Après avoir reçu les devis officiels et les permis, vous pouvez envisager d'obtenir un financement auprès d'une banque. Toutefois, il convient de rappeler que l'obtention d'un prêt important couvrant l'ensemble des coûts de construction n'est parfois accessible qu'aux particuliers. Sur la base de leur expérience de la construction d'une maison en Espagne, de nombreuses personnes avertissent que les prêts accordés aux particuliers pour de tels projets peuvent être délivrés avec certaines restrictions.

      `,
  },
];


export const Projects = [
  {
    id: 1,
    title: 'Quels types de services proposez-vous?',
    description:
      "Nous proposons une large gamme de services de construction et de biens immobiliers, y compris mais sans s'y limiter: construction, design d'intérieur, logements modulaires, piscines, énergie solaire, maisons intelligentes, Inmobiliaria, investissements et conseils.",
  },
  {
    id: 2,
    title: "Qu'a fait votre entreprise",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec venenatis blandit ligula. Etiam a feugiat mauris. Duis et elit et enim blandssit volutpat. Sed et lobortis turpis. Curabitur ac neque sed massa ultricies tristique. Nunc faucibus vel justo quis accumsan. Maecenas gravida lacus eget faucibus mollis. Donec mattis nisl quis pretium facilisis. Praesent ultricies orci dignissim, tempor felis et, rhoncus dolor.',
  },
  {
    id: 3,
    title: 'Quel est votre modèle de tarification?',
    description:
      'Sed in tellus vestibulum, convallis nisl a, tincidunt lectus. Nulla auctor diam volutpat nisi luctus, sed dapibus augue laoreet. Mauris pretium nisi sed luctus gravida. Donec in vehicula urna. Phasellus metaus ex, faucibus vitae tortor vitae, malesuada varius neque. Donec vel metus ornare, iaculis nunc sed, tempor lectus. Morbi sagittis erat et arcu dictum, in aliquam risus lobortis. Aliquam pulvinar, ipsum porttitor lacinia fringilla, quam orci scelerisque neque, nec tempor neque leo tempus sem. Praesent eget nulla nec nulla fermentum ornare. Quisque porta fringilla ipsum vel vehicula. Duis vel mattis ligula, at interdum diam.',
  },
  {
    id: 4,
    title: 'Comment puis-je commencer un projet?',
    description:
      'Phasellus maximus porta fermentum. Vivamus ut imperdiet enim, non convallis arcu. Mauris blandit vel leo ut lacinia. Praesent et cursus quam.ft Maecenas interdum semper orci nec mattis. Integer in blandit lacus. Proin viverra erat condimentum dolor commodo, a iaculis risus tristique. Phasellus seddapibus nunc, accumsan sollicitudin enim.',
  },
  {
    id: 5,
    title: 'Offrez-vous des garanties ou des garanties sur votre travail?',
    description:
      'Suspendisse laoreet eros acq orci ornare, id eleifend mauris laoreet.',
  },
];
