import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
  transitions,
} from '../../../theme/Theme.constants';

export const StepsBlockWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: ${paddings.tabletPagePadding};
    margin: 5rem 0 2.5rem 0;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: ${paddings.desktopPagePadding};
    margin: 15rem 0 7.5rem 0;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  border-bottom: none;
`;

export const StepsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  @media only screen and (max-width: ${Breakpoints.md - 1}px) {
    width: fit-content;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    width: calc(100% / 1.5);
  }
`;

export const StepsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 2.25rem;
  text-transform: uppercase;
  border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  min-height: 90px;

  @media only screen and (max-width: ${Breakpoints.md - 1}px) {
    border-top: 1px solid ${colorVariables.processBlockHeaderBorderColor};
    border-bottom: none;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1.875rem;
    min-height: 110px;
    padding: 1.125rem 2.5rem;
  }
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StepRow = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  width: 100%;
  
  cursor: pointer;
`;

export const StepTitle = styled.div<{ isActiveStep: boolean }>`
  position: relative;
  padding: 0.875rem 1.875rem;
  border-right: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};
  width: 100%;
  color: ${props => props.isActiveStep && colorVariables.alternativeTextColor};
  background-color: ${props => props.isActiveStep && '#232325'};
  transition: ${transitions.backgroundHoverTransition};

  
  &:hover {
    background-color: ${props =>
      !props.isActiveStep && colorVariables.opacityWhiteBackgroundColor};
  }
`;

// export const StepTitleText = styled.div<{ isNotActive: boolean }>`
//   position: absolute;
//   top: 0;
//   left: 0;
//   padding: 0.875rem;
//   z-index: 10;
//   height: 100%;
//   width: 100%;
//   border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};
//   background-color: ${props =>
//     props.isNotActive && colorVariables.blackBackgroundColor};
// `;

export const StepNumber = styled.div<{ isActiveStep?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  @media only screen and (max-width: ${Breakpoints.md - 1}px) {
    color: ${props =>
      props.isActiveStep && colorVariables.alternativeTextColor};
    height: 3.125rem;
    border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};
    cursor: pointer;
  }
`;

export const Filler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #232325;
`;

export const DescriptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DescriptionsTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 2rem;
  padding: 1.125rem 2.5rem;
  min-height: 90px;
  border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  @media only screen and (max-width: ${Breakpoints.md - 1}px) {
    font-family: 'Montserrat';
    padding: 0.5rem 1rem;
    min-height: fit-content;
    border-bottom: none;
    color: ${colorVariables.mainTextColor};
    background: ${colorVariables.alternativeBackground};
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    font-size: 1.625rem;
    min-height: 110px;
  }
`;

export const Description = styled.div<{ stepsCount: number }>`
  overflow: scroll;
  padding: 0.5rem 0.875rem;
  background: ${colorVariables.alternativeBackground};
  font-family: 'Overpass';
  font-size: 1rem;
  line-height: 1.625rem;
  color: rgba(255, 255, 255, 0.7);
  // height: ${props => props.stepsCount && props.stepsCount * 50}px;
  height: 100%;
  border-bottom: 1px solid ${colorVariables.processBlockHeaderBorderColor};

  &::-webkit-scrollbar {
    width: 0;
  }

  @media only screen and (min-width: ${Breakpoints.md}px) {
    padding: 3rem 1.5rem;
  }

  @media only screen and (min-width: ${Breakpoints.lg}px) {
    padding: 4.25rem 3.75rem;
  }
`;
