import styled from 'styled-components';
import { Breakpoints, colorVariables } from '../../theme/Theme.constants';
import bgImage from '../../shared/assets/images/real-estate/about-bg.jpg';
import bgTabletImage from '../../shared/assets/images/real-estate/about-tablet-bg.jpg';
import bgDesktopImage from '../../shared/assets/images/real-estate/about-desktop.jpg';

export const AboutSectionWrapper = styled.div`
  background: ${colorVariables.alternativeBackground};
  padding-bottom: 5rem;

  @media (min-width: ${Breakpoints.xlg}px) {
    padding-bottom: 10rem;
  }
`;

export const AboutSectionBgWrapper = styled.div`
  position: relative;
  background-image: url(${bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      ${colorVariables.alternativeBackground} 0%,
      rgba(23, 23, 25, 0) 100%
    );
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${Breakpoints.md}px) {
    background-image: url(${bgTabletImage});
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    background-image: url(${bgDesktopImage});
  }
`;

export const AboutElWrapper = styled.div`
  position: relative;
  z-index: 15;
  padding: 3.125rem 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    padding: 3.75rem 0px 3.75rem 6.25rem;
  }

  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 10.625rem 0px 10.625rem 6.25rem;
  }
`;

export const AboutItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media (min-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 1.875rem;
    }
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
`;

export const AboutIcon = styled.img`
  margin-bottom: 0.625rem;
`;

export const AboutText = styled.p<{ isFourth: boolean }>`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;

  color: ${colorVariables.mainTextColorWitOpacity};
  max-width: 20rem;

  @media (min-width: ${Breakpoints.md}px) {
    max-width: ${props => (props.isFourth ? '37.5rem' : '25rem')};
  }
`;
