import styled from 'styled-components';
import {
  Breakpoints,
  colorVariables,
  paddings,
} from '../../../theme/Theme.constants';

export const CasePhotoWrapper = styled.div``;

export const HeadWrapper = styled.div`
  margin-bottom: 1.25rem;

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    align-items: center;
    border: 1px solid ${colorVariables.navBarBorderColor};
    margin-bottom: 2.5rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    margin-bottom: 3.75rem;
  }
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${Breakpoints.md}px) {
    padding: 1.25rem 0;
    border-width: 1px 0px;
    border-style: solid;
    border-color: ${colorVariables.navBarBorderColor};
  }

  @media (min-width: ${Breakpoints.md}px) {
    padding: 1.875rem 2.812rem;
    border-right: 1px solid ${colorVariables.navBarBorderColor};
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    padding: 3.75rem 0px;
    width: 100%;
    background: rgba(36, 36, 37, 0.6);
  }
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;

  text-shadow: 0px 0px 0.625rem rgba(0, 0, 0, 0.2);
  text-align: center;

  @media (min-width: ${Breakpoints.md}px) {
    font-size: 2.5rem;
    line-height: 49px;
  }
`;

export const TextWrapper = styled.div`
  @media (max-width: ${Breakpoints.md}px) {
    padding: 1rem 1.25rem;
    border-bottom: 1px solid ${colorVariables.navBarBorderColor};
  }
  @media (min-width: ${Breakpoints.md}px) {
    padding: 0px 1rem;
  }
  @media (min-width: ${Breakpoints.xlg}px) {
    width: 100%;
    padding: 0px 2.5rem;
  }
`;
export const Text = styled.p`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25rem;

  opacity: 0.7;

  @media (max-width: ${Breakpoints.md}px) {
    text-align: center;
  }
`;

export const ImgList = styled.ul`
  @media (max-width: ${Breakpoints.md}px) {
    margin: 0px 1.25rem;
  }
  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
  }
`;

export const ImgItemWrapper = styled.div<{ big: boolean }>`
  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
    display: flex;
    width: 100%;
    ${props =>
      props.big
        ? `
     & > li:nth-child(2){
          width: 80%;
          margin-left: 0.625rem;

      }
  
       & > li:nth-child(1){
          width: 120%;
      }
   `
        : `
       & > li:nth-child(2){
          width: 120%;
      }
  
       & > li:nth-child(1){
          width: 80%;
          margin-right: 0.625rem;
      }
      `}
  }
`;

export const ImgItem = styled.li`
  @media (max-width: ${Breakpoints.md}px) {
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  @media (min-width: ${Breakpoints.md}px) {
  }
`;

export const Img = styled.img`
  display: block;
  width: 100%;

  @media (min-width: ${Breakpoints.md}px) {
    height: 380px;
  }
`;
