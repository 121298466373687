export const ContactsTitles = {
  contacts: 'Contactes',
  telNumber: '+34 600 59 20 07',
  email: 'info@modernacasa.es',
  socialMedia: 'Xarxes socials',
  form: 'Formulari',
  name_placeholder: 'Nom',
  email_placeholder: 'Correu electrònic*',
  phone_placeholder: 'Telèfon de contacte',
  description_placeholder: 'Descripció',
  password_placeholder: 'Contrasenya',
  send: 'Enviar',
};