import React from 'react';

import { ReactComponent as InformationIcon } from '../../shared/assets/icons/lead-forms/information.svg';
import { ReactComponent as QuestionIcon } from '../../shared/assets/icons/lead-forms/question-icon.svg';
import { ReactComponent as ContactedIcon } from '../../shared/assets/icons/lead-forms/contacted-icon.svg';
import { ReactComponent as FauvoriteIcon } from '../../shared/assets/icons/lead-forms/fauvorite-icon.svg';
import i18next from 'i18next';

export interface ClientPartnerNavigationItemsProps {
  client: string;
  partner: string;
}

export interface MainNavbarItemsProps {
  favourite: string;
  questions: string;
  information: string;
  contacted: string;
}

export const getClientPartnerNavigationItems = () => {
  const navbarTitles = i18next.t('leadForm.clientPartnerNavbarItems', {
    returnObjects: true,
  }) as ClientPartnerNavigationItemsProps;

  const clientPartnerNavigationItems = [
    {
      id: 1,
      title: navbarTitles.client,
    },
    {
      id: 2,
      title: navbarTitles.partner,
    },
  ];

  return clientPartnerNavigationItems;
};

export const getMainNavbarItems = () => {
  const navbarTitles = i18next.t('leadForm.mainNavbarItems', {
    returnObjects: true,
  }) as MainNavbarItemsProps;

  const formsNavigationItems = [
    {
      id: 1,
      title: navbarTitles.favourite,
      icon: <FauvoriteIcon />,
    },
    {
      id: 2,
      title: navbarTitles.questions,
      icon: <QuestionIcon />,
    },
    {
      id: 3,
      title: navbarTitles.information,
      icon: <InformationIcon />,
    },
    {
      id: 4,
      title: navbarTitles.contacted,
      icon: <ContactedIcon />,
    },
  ];

  return formsNavigationItems;
};
