export const solarEnergySetupsTexts = {
  label:
    ' Solucions energètiques per satisfer les seves necessitats',
  description:
    `Els accessoris intel·ligents per a la llar OSmart estan dissenyats per fer que la seva llar sigui més convenient, eficient i agradable. Des de termòstats i sistemes d'il·luminació intel·ligents fins a assistents activats per veu i càmeres de seguretat, hi ha una varietat d'accessoris disponibles per ajudar-lo a automatitzar i controlar diferents aspectes de la seva llar.`,
};

export const setupList = {
  typeBasic: 'Bàsic',
  nameBasic: ' Configuració bàsica',
  descriptionBasic:
    ` La nostra configuració bàsica inclou panells solars, una bateria i un inversor per convertir l'energia recollida pels panells en electricitat utilitzable. Aquesta configuració és ideal per a llars més petites amb menors necessitats de consum d'energia.`,

  typeStandart: 'Estàndard',
  nameStandard: ' Configuració estàndard',

  descriptionStandard:
    `La nostra configuració estàndard inclou panells i bateries addicionals per augmentar l' emmagatzematge i la producció d' energia. Aquesta configuració és ideal per a llars mitjanes amb necessitats de consum d' energia mitjana.`,

  typePremium: ' Premium',
  namePremium: ' Configuració premium',

  descriptionPremium:
    ` La nostra configuració premium inclou panells, bateries i inversors de primer nivell amb sistemes avançats de monitoratge i control. Ideal per a llars més grans i propietats comercials amb alt consum d'energia.`,
};
